var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}}),(_vm.acceptCGU)?_c('v-card-text',[_c('img',{staticClass:"ml-3",staticStyle:{"float":"right","border-radius":"6px"},attrs:{"src":require("@/assets/logo-maison-ownily.png"),"width":"15%"}}),_c('h1',{staticClass:"mb-5"},[_vm._v("Avant de continuer...")]),_c('br'),_c('br'),_c('p',[_vm._v(" Les Conditions Générales d'Utilisation Ownily évoluent pour intégrer les conditions du nouveau service de \"Suivi des loyers\" disponible sur les abonnements PREMIUM. Nous vous invitons à en prendre connaissance et à les accepter avant de poursuivre votre navigation. ")]),_c('br'),_c('div',{style:(_vm.$vuetify.breakpoint.xsOnly
          ? 'display:flex; flex-direction: column; justify-items: center; justify-content: center;'
          : ' display: grid; grid-template-columns: 1fr repeat(1, auto) 1fr; grid-column-gap: 5px; justify-items: center;')},[_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'd-flex align-self-center flex: 1'
            : 'd-flex align-self-start flex: 1',style:(_vm.$vuetify.breakpoint.xsOnly ? 'display:flex' : 'grid-column-start: 2')},[_c('v-btn',{staticClass:"mr-1",attrs:{"id":"track-begin","color":"primary","outlined":"","x-large":"","dark":""},on:{"click":_vm.goToCgu}},[_vm._v(" Lire ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"id":"track-begin","color":"primary","x-large":"","dark":""},on:{"click":_vm.validateCgu}},[_vm._v(" J'ai lu et j'accepte ")])],1)])]):(_vm.firstModal)?_c('v-card-text',[_c('img',{staticClass:"ml-3",staticStyle:{"float":"right","border-radius":"6px"},attrs:{"src":require("@/assets/logo-maison-ownily.png"),"width":"15%"}}),_c('h1',{staticClass:"mb-5"},[_vm._v("Vous y êtes presque... !")]),_c('br'),_c('br'),_c('p',[_vm._v(" Plus qu'une étape pour finaliser votre inscription et profiter pleinement de votre essai gratuit de 14 jours : "),_c('b',[_vm._v("synchroniser le compte bancaire de la SCI...")])]),_c('br'),_c('v-alert',{attrs:{"outlined":"","text":"","type":"info","icon":"mdi-party-popper"}},[_c('p',[_vm._v("👉 Besoin d'en savoir plus avant de passer le pas ?")]),_c('p',[_c('a',{attrs:{"href":"https://ownily.crisp.help/fr/category/connexion-bancaire-134j95e/","target":"_blank"}},[_vm._v(" Consulter la rubrique \"Connexion bancaire\" du centre d'aide. ")])])]),_c('div',{style:(_vm.$vuetify.breakpoint.xsOnly
          ? 'display: flex; flex-direction: column; justify-items: center; justify-content: center;'
          : 'display: grid; grid-template-columns: 1fr repeat(1, auto) 1fr; grid-column-gap: 5px; justify-items: center;')},[_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
            ? 'd-flex align-self-center flex: 1'
            : 'd-flex align-self-start flex: 1',style:(_vm.$vuetify.breakpoint.xsOnly ? 'display:flex' : 'grid-column-start: 2')},[_c('v-btn',{attrs:{"id":"track-begin","color":"primary","x-large":"","dark":""},on:{"click":_vm.goToNavigate}},[_vm._v(" Finaliser l'inscription ")])],1),_c('a',{staticClass:"d-flex align-self-center flex: 1 mt-4",style:(_vm.$vuetify.breakpoint.xsOnly ? 'display: flex' : 'margin-left: auto'),on:{"click":_vm.stopSync}},[_c('u',[_vm._v("Je ne souhaite pas synchroniser")])])])],1):(_vm.secondModal)?_c('v-card-text',[_c('img',{staticClass:"ml-3",staticStyle:{"float":"right","border-radius":"6px"},attrs:{"src":require("@/assets/logo-maison-ownily.png"),"width":"15%"}}),_c('h1',{staticClass:"mb-5"},[_vm._v("Content de vous retrouver !")]),_c('br'),_c('br'),_c('p',[_vm._v(" Pensez à finaliser les actions de paramétrages (Biens, Associés, Soldes...) indispensables pour démarrer la tenue comptable de votre "+_vm._s(_vm.sciOrLmnpText)+" sur Ownily ")]),_c('br'),_c('v-alert',{attrs:{"outlined":"","text":"","type":"info","icon":"mdi-party-popper"}},[_c('p',[_vm._v("👉 Besoin d'en savoir plus sur le paramétrage Ownily ?")]),_c('p',[_c('a',{attrs:{"href":_vm.vAlertInfoLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.vAlertInfoText)+" ")])])]),_c('div',{staticClass:"btn-container"},[_c('v-btn',{attrs:{"id":"track-begin","color":"primary","x-large":"","dark":""},on:{"click":_vm.goToNavigate}},[_vm._v(" J'ai compris ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }