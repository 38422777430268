import { Env } from "@/store/modules/Core.store";
import { ConfigAppFeatureFlags, TosVersion } from "@edmp/api";
import axios from "axios";

class CoreService {
  async getVersion(): Promise<{
    ok: boolean;
    env: Env;
    version: { back: string; front: string; api: string };
    commit: string;
    webSocket: boolean;
    subscription: { retentionDatasDaysDefault: number };
    user: { tosVersion: TosVersion };
    featureFlags: ConfigAppFeatureFlags;
  }> {
    return axios.get(`/version`).then((res) => {
      return res.data;
    });
  }
}

// Export a singleton instance in the global namespace
export const coreService = new CoreService();
