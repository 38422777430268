import { accountingsService } from "@/services";
import store from "@/store/root";
import {
  AccountingResult,
  AccountingResultCreate,
  AccountingResultType,
  AccountingResultUpdate,
} from "@edmp/api";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { accountingPeriodsStore, productsStore } from "..";

export interface AccountingResultsState {
  accountingResults: AccountingResult[];
  loading: boolean;
}

@Module({
  name: "accounting-result-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class AccountingResultsStore
  extends VuexModule
  implements AccountingResultsState
{
  accountingResults: AccountingResultsState["accountingResults"] = [];
  loading = false;

  @Mutation
  reset(): void {
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Getters
  get getAccountingResult() {
    return (accountingResultId) =>
      this.accountingResults.find(({ id }) => id === accountingResultId);
  }

  get getAccountingResultByAccountingPeriod() {
    return (accountingPeriodId) =>
      this.accountingResults.find(
        (accountingResult) =>
          accountingResult.type === AccountingResultType.CLOSURE &&
          accountingResult.accountingPeriodId === accountingPeriodId
      ) as AccountingResult<AccountingResultType.CLOSURE>;
  }

  get getCurrentAccountingResult() {
    return this.accountingResults.find(
      (accountingResult) =>
        accountingResult.type === AccountingResultType.CLOSURE &&
        accountingResult.accountingPeriodId === accountingPeriodsStore.currentId
    );
  }

  // Mutations
  @Mutation
  setAccountingResults(
    accountingResults: AccountingResultsState["accountingResults"]
  ): void {
    this.accountingResults = accountingResults;
  }

  @Mutation
  setAccountingResult(accountingResult: AccountingResult): void {
    const accountingResultIndex = this.accountingResults.findIndex(
      ({ id }) => id === accountingResult.id
    );
    if (accountingResultIndex !== -1) {
      Vue.set(this.accountingResults, accountingResultIndex, accountingResult);
    } else {
      this.accountingResults.push(accountingResult);
    }
  }

  @Mutation
  delAccountingResult(accountingResultId: AccountingResult["id"]): void {
    const accountingResultIndex = this.accountingResults.findIndex(
      ({ id }) => id === accountingResultId
    );
    if (accountingResultIndex !== -1) {
      this.accountingResults.splice(accountingResultIndex, 1);
    }
  }

  // Actions
  @Action
  async createAccountingResult(
    accountingResultCreate: AccountingResultCreate
  ): Promise<AccountingResult> {
    const accountingResultCreated = await accountingsService.results.create(
      accountingResultCreate
    );
    this.setAccountingResult(accountingResultCreated);
    return accountingResultCreated;
  }

  @Action
  async fetchAccountingResults(): Promise<AccountingResult[]> {
    const accountingResults = await accountingsService.results.list({
      productId: productsStore.currentId,
    });
    this.setAccountingResults(accountingResults);
    return accountingResults;
  }

  @Action
  async updateAccountingResult(
    accountingResultUpdate: AccountingResultUpdate
  ): Promise<AccountingResult> {
    const accountingResultUpdated = await accountingsService.results.update(
      accountingResultUpdate
    );
    this.setAccountingResult(accountingResultUpdated);
    return accountingResultUpdated;
  }

  @Action
  async deleteAccountingResult(
    accountingResultId: AccountingResult["id"]
  ): Promise<boolean> {
    const isDeleted = await accountingsService.results.delete({
      id: accountingResultId,
    });
    if (isDeleted) {
      this.delAccountingResult(accountingResultId);
    }
    return isDeleted;
  }
}
