import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { Address, addressSchema, DeepPartial, DpeInformation, IR2072Deductions, IR2072NatureA, RequireField } from "..";

/**
 * `RentalBuilding` — A rental building
 */
export interface RentalBuilding {
  id: string;
  productId: string;
  accountingPeriodId: string;
  realEstateAssetIds: string[];
  name: string; // Name of the rental building
  address: Address & Required<Pick<Address, "street" | "zip" | "city">>;
  taxDeduction: IR2072Deductions;
  nature: IR2072NatureA;
  premises: number;
  newAcquisition: boolean;
  line9bAmount?: number;
  createdAt: string;
  updatedAt: string;
  dpe: DpeInformation;
}

export type NewRentalBuilding = Omit<RentalBuilding, "id" | "createdAt" | "updatedAt">;

export type RentalBuildingUpdate = RequireField<
  DeepPartial<Omit<RentalBuilding, "productId" | "createdAt" | "updatedAt">>,
  "id"
>;
export type RentalBuildingUpdateInternal = RequireField<
  DeepPartial<Omit<RentalBuilding, "createdAt" | "updatedAt">>,
  "id"
>;

const rentalBuildingSchema = new Schema<RentalBuildingDocument>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    realEstateAssetIds: [{ type: String, index: true, required: true }],
    name: { type: String, required: true, maxlength: 200 },
    address: { type: addressSchema, required: true },
    taxDeduction: { type: String, required: true, enum: Object.values(IR2072Deductions) },
    nature: { type: String, required: true, enum: Object.values(IR2072NatureA) },
    premises: { type: Number, required: true },
    newAcquisition: { type: Boolean, required: true },
    line9bAmount: { type: Number, required: false}
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentalBuildingDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type RentalBuildingDocument = RentalBuilding & Document<string>;

// Name of the collection in third argument
export const RentalBuildingModel = model<RentalBuildingDocument>(
  "RentalBuilding",
  rentalBuildingSchema,
  "RentalBuildings"
);

// API
export namespace RentalBuildingsService {
  export type CreateIn = NewRentalBuilding;
  export type CreateOut = RentalBuilding;

  export type ListIn = Partial<Pick<RentalBuilding, "productId" | "accountingPeriodId" | "realEstateAssetIds">>;
  export type ListOut = RentalBuilding[];

  export type GetIn = Pick<RentalBuilding, "id">;
  export type GetOut = RentalBuilding;

  export type UpdateIn = RentalBuildingUpdate;
  export type UpdateOut = RentalBuilding;

  export type DeleteIn = Pick<RentalBuilding, "id">;
  export type DeleteOut = boolean;
}
