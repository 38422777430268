















































































































































import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
import {
  AccountingPeriod,
  AccountingPeriodDefinition,
  ProductsModel,
  TransactionImportType,
} from "@edmp/api";
import Moment from "moment";

import { VConfirmDialog, VForm } from "@/models";
import router from "@/router";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { countDecimals } from "@/utils";

import Transaction from "../transaction/Transaction.vue";
import Next from "@/components/core/modals/Next.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import DatePicker from "@/components/atom/DatePicker.vue";

export default defineComponent({
  name: "TransactionManual",
  components: {
    Transaction,
    Next,
    Confirm,
    DatePicker,
  },
  setup(_, context) {
    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );
    const accountingPeriod = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod &
          AccountingPeriodDefinition
    );

    function resetForm() {
      (context.refs.form as VForm).resetValidation();
    }

    const transactionDate: Ref<string> = ref(
      router.currentRoute.query.addPreviousYearBalance === "true"
        ? Moment(accountingPeriod.value.startAt).format("YYYY-MM-DD")
        : Moment().isBetween(
            Moment(accountingPeriod.value.startAt),
            Moment(accountingPeriod.value.endAt)
          )
        ? Moment().format("YYYY-MM-DD")
        : Moment(accountingPeriod.value.startAt).format("YYYY-MM-DD")
    );
    const transactionAmount: Ref<number | undefined> = ref(undefined);
    const transactionSummary: Ref<string> = ref(
      router.currentRoute.query.addPreviousYearBalance === "true"
        ? "SOLDE DE L'EXERCICE PRECEDENT"
        : ""
    );
    const firstStep: Ref<boolean> = ref(true);
    const secondStep: Ref<boolean> = ref(false);
    const transactionId: Ref<string> = ref("");
    const currency: Ref<string | undefined> = ref();

    async function validateFirstStep() {
      if ((context.refs.form as VForm).validate()) {
        try {
          let transactionAmountFinal;
          if (transactionAmount.value) {
            transactionAmountFinal = transactionAmount.value;
          }
          if (currency.value === "débit" && transactionAmount.value) {
            transactionAmountFinal = transactionAmountFinal * -1;
          }
          const newTransaction = await transactionsStore.createTransaction({
            productId: product.value.id,
            date: transactionDate.value,
            amount: transactionAmountFinal ? transactionAmountFinal : 0,
            summary: transactionSummary.value,
            type: TransactionImportType.MANUAL,
          });
          transactionId.value = newTransaction.id;
          firstStep.value = false;
          resetForm();
          secondStep.value = true;
        } catch (error) {
          console.error(error);
        }
      }
    }

    async function cancel(): Promise<void> {
      if (transactionId.value) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Êtes-vous sûr de vouloir abandonner la création de cette transaction manuelle ?"
        );

        if (openConfirm) {
          await transactionsStore.deleteTransaction({
            id: transactionId.value,
          });
          context.root.$router.push({ query: {} });
          context.emit("finish");
        }
      } else {
        context.root.$router.push({ query: {} });
        context.emit("finish");
      }
    }

    function changeCurrency(a) {
      currency.value = a;
    }

    const isCategorized = ref(false);

    async function categorizeAndValidate() {
      if (isCategorized.value) {
        context.root.$router.push({ query: {} });
        context.emit("finish");
      } else {
        coreStore.displayFeedback({
          message: "Chaque opération comptable doit être catégorisée.",
          type: FeedbackTypeEnum.ERROR,
        });
      }
    }

    return {
      validateFirstStep,
      changeCurrency,
      cancel,
      transactionDate,
      transactionAmount,
      transactionSummary,
      firstStep,
      secondStep,
      transaction: computed(
        () => transactionsStore.transactions[transactionId.value]
      ),
      accountingPeriod,
      isCategorized,
      countDecimals,
      currency,
      categorizeAndValidate,
    };
  },
});
