



























































import { ROUTE_NAMES } from "@/router/routes";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ProductTour",
  setup(props, context) {
    function goProductTour() {
      context.root.$router.push({ name: ROUTE_NAMES.Dashboard });
      context.root.$tours["ownily-tour"].start();
    }
    return {
      goProductTour,
    };
  },
});
