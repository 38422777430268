










































































import { defineComponent, onBeforeMount, Ref, ref } from "@vue/composition-api";

import EventsGeneralAssemblyModal from "../modals/EventsGeneralAssemblyModal.vue";
import Title from "@/components/core/title/Title.vue";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import router from "@/router";

export default defineComponent({
  name: "EventsGeneralAssemblyHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    displayHelper: {
      type: Boolean,
      default: false,
    },
    displayFilter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Title,
    AccountingPeriodChoice,
    EventsGeneralAssemblyModal,
  },
  setup() {
    const showGeneralAssemblyModal: Ref<boolean> = ref(false);
    const step: Ref<"ordinary" | "extraordinary" | undefined> = ref("ordinary");
    const pattern: Ref<"yearEnd" | "other" | undefined> = ref("other");

    function closeModal() {
      showGeneralAssemblyModal.value = false;
    }

    function validate() {
      showGeneralAssemblyModal.value = false;
    }

    onBeforeMount(() => {
      if (router.currentRoute.query.newEvent) {
        step.value = router.currentRoute.query.newEvent as
          | "ordinary"
          | "extraordinary"
          | undefined;
        if (router.currentRoute.query.pattern) {
          pattern.value = router.currentRoute.query.pattern as
            | "yearEnd"
            | "other"
            | undefined;
          showGeneralAssemblyModal.value = true;
        }
      }
    });

    return {
      showGeneralAssemblyModal,
      step,
      pattern,
      closeModal,
      validate,
    };
  },
});
