




















































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import RealEstatesLMNP from "./RealEstatesLMNP.vue";
import RealEstateLoanModal from "./realEstateLoans/RealEstateLoanModal.vue";
import RealEstateLoans from "./realEstateLoans/RealEstateLoans.vue";
import Title from "../title/Title.vue";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { productsStore, coreStore, realEstateAssetsStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ROUTE_NAMES } from "@/router/routes";
import eventBus from "@/events/eventBus";

export default defineComponent({
  name: "RealEstateAssets",
  components: { RealEstatesLMNP, RealEstateLoans, RealEstateLoanModal, Title },
  props: {
    tabIndex: { type: String, required: false },
  },

  setup(props, context) {
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);
    const productId = productsStore.currentId;
    const isNew: ComputedRef<boolean> = computed(
      () => realEstateAssetsStore.realEstateAssets.length === 0
    );

    const realEstateNew = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        const productId = productsStore.currentId;
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstateLMNP,
          params: { productId, realEstateAssetId: "new" },
        });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    onBeforeMount(() => {
      if (realEstateAssetsStore.realEstateAssets.length === 0) {
        eventBus.$emit("menuDisabled", true);
      }
    });

    return {
      tabItemIndex,
      productId,
      realEstateNew,
      isNew,
    };
  },
});
