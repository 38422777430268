




























































import { VForm } from "@/models";
import { activitiesStore, productsStore } from "@/store";
import { Address, ProductsModel } from "@edmp/api";
import { defineComponent, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ActivityUpdate",
  props: {
    product: {
      type: Object as () => Partial<ProductsModel.Product>,
      required: true,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const street: Ref<string> = ref(
      props.product?.activity?.address?.street ?? ""
    );
    const zip: Ref<string> = ref(props.product?.activity?.address?.zip ?? "");
    const city: Ref<string> = ref(props.product?.activity?.address?.city ?? "");
    const newEmail: Ref<string> = ref(props.product?.newEmail ?? "");
    function resetForm() {
      street.value = "";
      zip.value = "";
      city.value = "";
      newEmail.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const address: Address = {
            street: street.value,
            zip: zip.value,
            city: city.value,
          };
          if (
            props.product?.id &&
            props.product?.name &&
            props.product?.activity
          ) {
            // Update productStore
            if (props.product) {
              await productsStore.updateProduct({
                id: props.product.id,
                name: props.product.name,
                newEmail: newEmail.value,
              });
              await activitiesStore.updateActivity({
                id: props.product.activity.id,
                siret: props.product.activity.siret,
                address,
              });
            }

            context.emit("validate", address);
          }

          resetForm();
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      street,
      zip,
      city,
      newEmail,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
    };
  },
});
