




































































































































import Vue from "vue";
import {
  defineComponent,
  ref,
  Ref,
  onBeforeMount,
  computed,
  PropType,
  watch,
} from "@vue/composition-api";
import {
  defaultAmortisationsFixedAsset,
  FixedAssetAmortisation,
  FixedAssetAmortisationCreate,
  calculateAmortisation,
  getMoment,
  defaultAmortisationsByFixedAssetType,
} from "@edmp/api";
import { cloneDeep, merge } from "lodash";

import {
  accountingPeriodsStore,
  coreStore,
  fixedAssetAmortisationsStore,
  fixedAssetsStore,
} from "@/store";
import { VForm } from "@/models";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

import { useFixedAssetAmortisation } from "@/components/core/fixedAssetAmortisation/fixedAssetAmortisation.usable";

import Tag from "@/components/atom/Tag.vue";
import SubmitButton from "@/components/atom/button/SubmitButton.vue";
import EditableTable, {
  EditableTableRef,
} from "@/components/atom/table/EditableTable.vue";

type FixedAssetAmortisationCreateLocal = Omit<
  FixedAssetAmortisationCreate,
  "type" | "accountingPeriodIdAllowedToEdit" | "fixedAssetId"
> & { id: null; type: FixedAssetAmortisationCreate["type"] | undefined };

export default defineComponent({
  name: "FixedAssetAmortisationTable",
  components: { Tag, SubmitButton, EditableTable },
  props: {
    fixedAssetId: { type: String, required: true },
    viewType: {
      type: String as PropType<"readOnly" | "editable">,
      default: "editable",
    },
    isEditing: { type: Boolean, default: false },
  },
  setup(props, context) {
    const { amortisationTypeText } = useFixedAssetAmortisation();
    const accountingPeriodLast = computed(
      () => accountingPeriodsStore.lastAccountingPeriod
    );
    const accountingPeriodFirst = computed(
      () => accountingPeriodsStore.firstAccountingPeriod
    );
    const fixedAsset = computed(() =>
      fixedAssetsStore.getFixedAsset(props.fixedAssetId)
    );
    const fixedAssetAmortisations = computed(() =>
      fixedAssetAmortisationsStore.getFixedAssetAmortisationsByFixedAssetId(
        props.fixedAssetId
      )
    );
    const isNew = computed(() => !fixedAssetAmortisations.value.length);
    const isEditingOnStartup = ref(false);
    const isLoadingValidate = ref(false);

    const accountingPeriodFromFixedAssetCommissioningDate = computed(() => {
      const currentCommissioningAt = getMoment(
        fixedAsset.value?.commissioningAt
      );
      let selectedAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find((accountingPeriod) => {
          const accountingPeriodStartAt = getMoment(accountingPeriod.startAt);
          const accountingPeriodEndAt = getMoment(accountingPeriod.endAt);
          return currentCommissioningAt.isBetween(
            accountingPeriodStartAt,
            accountingPeriodEndAt,
            undefined,
            "[]"
          );
        });

      // Return the accounting period when the real estate asset was commissioned
      if (selectedAccountingPeriod) return selectedAccountingPeriod;
      // Return the last accounting period
      return accountingPeriodFirst.value;
    });

    // Ground
    const fixedAssetBoughtPrice = computed(() =>
      fixedAsset.value?.boughtPrice ? fixedAsset.value.boughtPrice : 0
    );

    // Share
    const shareTotal = computed(() =>
      amortisations.value.reduce((prev, current) => prev + current.share, 0)
    );

    const amortizationValueStats = computed(() => {
      return amortisations.value.reduce(
        (prev, current) => {
          const { valueToAmortizedForCurrentAccountingPeriod, valueAmortized } =
            amortizationValueStatsForCurrentAccountingPeriod(
              props.fixedAssetId,
              current.durationInYear,
              current.value
            );

          return {
            valueToAmortizedForCurrentAccountingPeriod:
              prev.valueToAmortizedForCurrentAccountingPeriod +
              valueToAmortizedForCurrentAccountingPeriod,
            valueAmortized: prev.valueAmortized + valueAmortized,
          };
        },
        {
          valueToAmortizedForCurrentAccountingPeriod: 0,
          valueAmortized: 0,
        }
      );
    });

    // Rules
    const isValidForm = ref(false);
    const rules = computed(() => ({
      form: {
        isValid: isValidForm,
      },
      share: {
        isValid: shareTotal.value === 100,
        text: "le total des quotes parts n'est pas egal à 100% du prix d'achat",
      },
    }));

    // Amortisations
    const newAmortisations: FixedAssetAmortisationCreateLocal = {
      id: null,
      type: undefined,
      share: 0,
      value: 0,
      startAt: getMoment(
        fixedAsset.value?.commissioningAt || accountingPeriodLast.value?.startAt
      ).toISOString(),
      durationInYear: 0,
      rate: 0,
    };
    const amortisations: Ref<
      ((FixedAssetAmortisationCreateLocal | FixedAssetAmortisation) & {
        netBookValue?: number;
        durationLeftInYear?: number;
        dotationNValue?: number;
      })[]
    > = ref(
      isNew.value
        ? fixedAsset.value?.type
          ? cloneDeep(
              defaultAmortisationsByFixedAssetType[fixedAsset.value.type].map(
                (defaultAmortisation) =>
                  merge(cloneDeep(newAmortisations), defaultAmortisation)
              )
            )
          : []
        : cloneDeep(fixedAssetAmortisations.value)
    );

    // Table
    const viewTypeTable = ref(props.viewType);
    const headers = [
      { text: "Composant", value: "typeText" },
      { text: "Quote Part", value: "shareText", width: "95px" },
      { text: "Valeur", value: "valueText", width: "150px" },
      { text: "Durée", value: "durationInYearText", width: "105px" },
      { text: "Taux", value: "rateText", width: "80px" },
      { text: "VNC Composant", value: "netBookValueText", width: "150px" },
      {
        text: "Durée Restante",
        value: "durationLeftInYearText",
        width: "105px",
      },
      { text: "Dotation N", value: "dotationNValueText", width: "150px" },
    ] as const;
    const headerValueTextToOriginalKey: {
      [key in typeof headers[number]["value"]]: keyof (Pick<
        FixedAssetAmortisation,
        "type" | "share" | "value" | "durationInYear" | "rate"
      > & {
        netBookValue: number;
        durationLeftInYear: number;
        dotationNValue: number;
      });
    } = {
      typeText: "type",
      shareText: "share",
      valueText: "value",
      durationInYearText: "durationInYear",
      rateText: "rate",
      netBookValueText: "netBookValue",
      durationLeftInYearText: "durationLeftInYear",
      dotationNValueText: "dotationNValue",
    };

    const amortisationKeyValueSuffix: {
      [key in keyof Pick<
        FixedAssetAmortisation,
        "share" | "value" | "durationInYear" | "rate"
      >]: string;
    } = {
      share: "%",
      value: "€",
      durationInYear: "ans",
      rate: "%",
    };
    const {
      amortizationDaysStatsForCurrentAccountingPeriod,
      amortizationValueStatsForCurrentAccountingPeriod,
    } = useFixedAssetAmortisation();
    const durationLeftInYear = (
      fixedAssetId: string,
      durationInYear: number
    ) => {
      let res = 0;
      const days = amortizationDaysStatsForCurrentAccountingPeriod(
        fixedAssetId,
        durationInYear
      )?.remainingDaysToAmortize;
      if (days) {
        if (days < 365) {
          res = 1;
        } else {
          res = Math.round(days / 365);
        }
      }
      return res;
    };
    const amortisationItems = computed(() => {
      const amortisationItems = amortisations.value.map((amortisation) => {
        const currentAmortizationStats =
          amortizationValueStatsForCurrentAccountingPeriod(
            props.fixedAssetId,
            amortisation.durationInYear,
            amortisation.value
          );

        return merge(amortisation, {
          typeText: amortisationTypeText.find(
            (amortisationType) => amortisationType.value === amortisation.type
          )?.text,
          shareText: `${amortisation.share} ${amortisationKeyValueSuffix.share}`,
          valueText: `${amortisation.value} ${amortisationKeyValueSuffix.value}`,
          durationInYearText: `${amortisation.durationInYear} ${amortisationKeyValueSuffix.durationInYear}`,
          rateText: `${amortisation.rate} ${amortisationKeyValueSuffix.rate}`,
          netBookValueText: `${currentAmortizationStats.remainingValueToAmortize} ${amortisationKeyValueSuffix.value}`,
          durationLeftInYearText: `${durationLeftInYear(
            props.fixedAssetId,
            amortisation.durationInYear
          )} ${amortisationKeyValueSuffix.durationInYear}`,
          dotationNValueText: `${currentAmortizationStats.valueToAmortizedForCurrentAccountingPeriod} ${amortisationKeyValueSuffix.value}`,
        });
      });
      if (amortisationItems.length > 0) {
        amortisationItems.push(
          merge(cloneDeep(newAmortisations), {
            typeText: "Total :",
            share: shareTotal.value,
            shareText: `${shareTotal.value} ${amortisationKeyValueSuffix.share}`,
            valueText: "",
            durationInYearText: "",
            rateText: "",
            netBookValueText: "",
            durationLeftInYearText: "",
            dotationNValueText: `${amortizationValueStats.value.valueToAmortizedForCurrentAccountingPeriod} ${amortisationKeyValueSuffix.value}`,
          })
        );
      }
      return amortisationItems;
    });

    // Methods
    const updateCalculateAmortisation = ({
      keyUpdated,
      rowIndex,
    }: {
      keyUpdated: keyof Pick<
        FixedAssetAmortisation,
        "type" | "share" | "value" | "durationInYear"
      >;
      rowIndex: number;
    }) => {
      const value = amortisations.value[rowIndex][keyUpdated];

      if (keyUpdated === "type") {
        const typeDefaultAmortisations = cloneDeep(
          Object.values(defaultAmortisationsFixedAsset).find(
            ({ type }) => type === value
          )
        );
        if (typeDefaultAmortisations) {
          Vue.set(
            amortisations.value,
            rowIndex,
            merge(cloneDeep(newAmortisations), typeDefaultAmortisations)
          );
        }
        updateCalculateAmortisation({
          keyUpdated: "share",
          rowIndex,
        });
      }

      if (
        keyUpdated === "share" ||
        keyUpdated === "value" ||
        keyUpdated === "durationInYear"
      ) {
        amortisations.value[rowIndex][keyUpdated] = Number(value);

        Vue.set(
          amortisations.value,
          rowIndex,
          merge(
            amortisations.value[rowIndex],
            calculateAmortisation({
              amortisationType: "default",
              amortisationValues: {
                share: amortisations.value[rowIndex].share,
                value: amortisations.value[rowIndex].value,
                durationInYear: amortisations.value[rowIndex].durationInYear,
                rate: amortisations.value[rowIndex].rate,
              },
              boughtPrice: fixedAssetBoughtPrice.value,
              keyUpdated,
            })
          )
        );
      }
    };

    const addItem = () => {
      amortisations.value.push(cloneDeep(newAmortisations));
    };
    const cancelEditItem = ({ rowIndex }: { rowIndex: number }) => {
      if (amortisations.value[rowIndex].id !== null) {
        Vue.set(
          amortisations.value,
          rowIndex,
          cloneDeep(fixedAssetAmortisations.value[rowIndex])
        );
      } else if (fixedAsset.value?.type) {
        const defaultAmortisation = cloneDeep(
          defaultAmortisationsByFixedAssetType[fixedAsset.value.type].find(
            (defaultAmortisation) =>
              defaultAmortisation.type === amortisations.value[rowIndex].type
          )
        );
        if (defaultAmortisation) {
          Vue.set(
            amortisations.value,
            rowIndex,
            merge(cloneDeep(newAmortisations), defaultAmortisation)
          );
        }
      }
    };
    const deleteItem = async ({ rowIndex }: { rowIndex: number }) => {
      const amortisation = amortisations.value[rowIndex];
      if (amortisation.id === null) {
        amortisations.value.splice(rowIndex, 1);
      } else {
        try {
          await fixedAssetAmortisationsStore.deleteFixedAssetAmortisation({
            id: amortisation.id,
          });
          amortisations.value = cloneDeep(fixedAssetAmortisations.value);
        } catch (err) {
          console.log(err);
          (context.refs.editableTable as EditableTableRef).updateIsEditing(
            amortisations.value.length - 1,
            true
          );
          const message =
            "Une erreur est survenue lors de la suppression de votre amortissement";
          coreStore.displayFeedback({
            message,
            type: FeedbackTypeEnum.ERROR,
          });
        }
      }
    };
    const validate = async () => {
      if (
        rules.value.share.isValid &&
        (context.refs.form as VForm).validate()
      ) {
        isLoadingValidate.value = true;
        let isValidate = true;

        try {
          if (fixedAsset.value) {
            await fixedAssetsStore.updateFixedAsset(fixedAsset.value);
          }
        } catch (error) {
          console.log(error);
          isValidate = false;
          const message =
            "Une erreur est survenue lors de la création de votre amortissement";
          coreStore.displayFeedback({
            message,
            type: FeedbackTypeEnum.ERROR,
          });
        }

        for (let index = 0; index < amortisations.value.length; index++) {
          const amortisation = cloneDeep(amortisations.value[index]);
          try {
            let createdOrUpdatedAmortisation: FixedAssetAmortisation;
            if (amortisation.id) {
              createdOrUpdatedAmortisation =
                await fixedAssetAmortisationsStore.updateFixedAssetAmortisation(
                  amortisation as FixedAssetAmortisation
                );
            } else {
              createdOrUpdatedAmortisation =
                await fixedAssetAmortisationsStore.createFixedAssetAmortisation(
                  {
                    accountingPeriodIdAllowedToEdit:
                      accountingPeriodFromFixedAssetCommissioningDate.value.id,
                    fixedAssetId: props.fixedAssetId,
                    ...(amortisation as Omit<
                      FixedAssetAmortisationCreateLocal,
                      "type"
                    > & { type: FixedAssetAmortisationCreate["type"] }),
                  }
                );
            }
            amortisations.value[index] = createdOrUpdatedAmortisation;
            updateCalculateAmortisation({
              keyUpdated: "share",
              rowIndex: index,
            });
            (context.refs.editableTable as EditableTableRef).updateIsEditing(
              index,
              false
            );
            context.emit("validateAmortisation", {
              amortisationId: createdOrUpdatedAmortisation.id,
            });
          } catch (err) {
            console.log(err);
            isValidate = false;
            (context.refs.editableTable as EditableTableRef).updateIsEditing(
              index,
              true
            );
            const message =
              "Une erreur est survenue lors de la création de votre amortissement";
            coreStore.displayFeedback({
              message,
              type: FeedbackTypeEnum.ERROR,
            });
          }
        }
        isLoadingValidate.value = false;
        if (isValidate) {
          context.emit("validate");
        }
      }
    };

    // Init
    watch(
      () => [fixedAssetAmortisations, fixedAssetsStore.fixedAssets],
      () => {
        if (viewTypeTable.value === "readOnly") {
          amortisations.value = cloneDeep(fixedAssetAmortisations.value);
          for (let index = 0; index < amortisations.value.length; index++) {
            updateCalculateAmortisation({
              keyUpdated: "share",
              rowIndex: index,
            });
          }
        }
      },
      { deep: true }
    );

    onBeforeMount(() => {
      if (viewTypeTable.value === "readOnly") {
        if (isNew.value) {
          amortisations.value = [];
        }
      } else {
        if (!isNew.value && amortisations.value[0].startAt) {
          const accountingPeriod =
            accountingPeriodsStore.accountingPeriods.find(
              (accountingPeriod) =>
                accountingPeriod.id ===
                (amortisations.value[0] as FixedAssetAmortisation)
                  .accountingPeriodIdAllowedToEdit
            );
          if (accountingPeriod && accountingPeriod.closed) {
            viewTypeTable.value = "readOnly";
          }
        }
        if (
          (isNew.value || props.isEditing) &&
          viewTypeTable.value === "editable"
        ) {
          isEditingOnStartup.value = true;
        }
        for (let index = 0; index < amortisations.value.length; index++) {
          updateCalculateAmortisation({
            keyUpdated: "share",
            rowIndex: index,
          });
        }
      }
    });

    return {
      rules,
      isValidForm,
      isLoadingValidate,

      amortizationValueStats,

      amortisations,
      amortisationTypeText,
      amortisationKeyValueSuffix,

      isNew,
      isEditingOnStartup,

      viewTypeTable,
      headers,
      headerValueTextToOriginalKey,
      amortisationItems,

      updateCalculateAmortisation,
      addItem,
      cancelEditItem,
      deleteItem,
      validate,
    };
  },
});
