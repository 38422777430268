








































import { VForm } from "@/models";
import { rentalAgreementsStore } from "@/store";
import { RentalAgreement } from "@edmp/api";
import { defineComponent, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "rentalAgreementUpdate",
  props: {
    rentalAgreement: {
      type: Object as () => Partial<RentalAgreement>,
      required: true,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const name: Ref<string> = ref(props.rentalAgreement.name ?? "");

    function resetForm() {
      name.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const rentalAgreementToUpdate: Partial<RentalAgreement> = {
            ...props.rentalAgreement,
          };
          // Update
          await rentalAgreementsStore.updateRentalAgreement(
            rentalAgreementToUpdate as RentalAgreement
          );

          context.emit("validate", name.value);
          resetForm();
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      name,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
    };
  },
});
