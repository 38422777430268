

































































































import { dispatchSubscriptionEvent } from "@/events";
import { subscriptionsStore } from "@/store";
import { SubscriptionsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import moment from "moment";

/**
 * @Component Payment
 * @prop {String} subscriptionId, required
 * @prop {PaymentStatusParams.succeeded | PaymentStatusParams.canceled} stripeReturnStatus
 * @prop {Boolean} subscriptionPaymentRetryIsLoading, default: false
 */
export default defineComponent({
  name: "Payment",
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
    stripeReturnStatus: {
      type: String as PropType<
        | SubscriptionsModel.PaymentStatusParams.succeeded
        | SubscriptionsModel.PaymentStatusParams.canceled
      >,
      required: false,
    },
    subscriptionPaymentRetryIsLoading: { type: Boolean, default: false },
  },
  setup(props) {
    const subscription = computed(() =>
      subscriptionsStore.getSubscription(props.subscriptionId)
    );

    const subscriptionPlans = ref([
      {
        status: SubscriptionsModel.SubscriptionStatus.active,
        paymentStatus: SubscriptionsModel.PaymentStatus.succeeded,
        type: SubscriptionsModel.PlanType.Solo,
        title: "Félicitations, votre paiement a bien été effectué 🎉",
        subtitle: "Votre abonnement <b>SOLO est activé</b>.",
        text: `Merci d'avoir choisi Ownily pour la gestion de votre SCI. <br/>
          Votre prochaine échéance de paiement aura lieu le <b>${moment(
            subscription.value?.endAt
          ).format("DD/MM/YYYY")}</b>`,
        info: "",
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.active,
        paymentStatus: SubscriptionsModel.PaymentStatus.succeeded,
        type: SubscriptionsModel.PlanType.Basic,
        title: "Félicitations, votre paiement a bien été effectué 🎉",
        subtitle: "Votre abonnement <b>BASIC est activé</b>.",
        text: `Merci d'avoir choisi Ownily pour la gestion de votre SCI. <br/>
          Votre prochaine échéance de paiement aura lieu le <b>${moment(
            subscription.value?.endAt
          ).format("DD/MM/YYYY")}</b>`,
        info: "",
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.active,
        paymentStatus: SubscriptionsModel.PaymentStatus.succeeded,
        type: SubscriptionsModel.PlanType.Premium,
        title: "Félicitations, votre paiement a bien été effectué 🎉",
        subtitle: "Votre abonnement <b>PREMIUM est activé</b>.",
        text: `Merci d'avoir choisi Ownily pour la gestion de votre SCI. <br/>
          Votre prochaine échéance de paiement aura lieu le <b>${moment(
            subscription.value?.endAt
          ).format("DD/MM/YYYY")}</b>`,
        info: "",
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.active,
        paymentStatus: SubscriptionsModel.PaymentStatus.succeeded,
        type: SubscriptionsModel.PlanType.Optimum,
        title: "Félicitations, votre paiement a bien été effectué 🎉",
        subtitle: "Votre abonnement <b>OPTIMUM est activé</b>.",
        text: `Merci d'avoir choisi Ownily pour la gestion de votre SCI. <br/>
          Votre prochaine échéance de paiement aura lieu le <b>${moment(
            subscription.value?.endAt
          ).format("DD/MM/YYYY")}</b>`,
        info: "",
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.active,
        paymentStatus: SubscriptionsModel.PaymentStatus.succeeded,
        type: SubscriptionsModel.PlanType.LMNP,
        title: "Félicitations, votre paiement a bien été effectué 🎉",
        subtitle: "Votre abonnement <b>LMNP est activé</b>.",
        text: `Merci d'avoir choisi Ownily pour la gestion de votre LMNP. <br/>
          Votre prochaine échéance de paiement aura lieu le <b>${moment(
            subscription.value?.endAt
          ).format("DD/MM/YYYY")}</b>`,
        info: "",
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.error,
        paymentStatus: SubscriptionsModel.PaymentStatus.failed,
        type: SubscriptionsModel.PlanType.Solo,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Votre paiement pour la souscription à l’offre <b>SOLO a échoué</b>`,
        text: `Tout n'est pas perdu... Vous avez jusqu'au <b>${moment(
          subscription.value?.startAt
        )
          .add(30, "days")
          .format(
            "DD/MM/YYYY"
          )}</b> pour procéder au règlement de votre facture. A défaut de paiement avant cette date,
          cet abonnement sera automatiquement résilié conformément aux
          <a style="color: #000;" href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank">conditions d'utilisation</a>`,
        info: `💡 Besoin d’aide ? Contactez-nous sur le live-chat ou à l’adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.error,
        paymentStatus: SubscriptionsModel.PaymentStatus.failed,
        type: SubscriptionsModel.PlanType.Basic,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Votre paiement pour la souscription à l’offre <b>BASIC a échoué</b>`,
        text: `Tout n'est pas perdu... Vous avez jusqu'au <b>${moment(
          subscription.value?.startAt
        )
          .add(30, "days")
          .format(
            "DD/MM/YYYY"
          )}</b> pour procéder au règlement de votre facture. A défaut de paiement avant cette date,
          cet abonnement sera automatiquement résilié conformément aux
          <a style="color: #000;" href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank">conditions d'utilisation</a>`,
        info: `💡 Besoin d’aide ? Contactez-nous sur le live-chat ou à l’adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.error,
        paymentStatus: SubscriptionsModel.PaymentStatus.failed,
        type: SubscriptionsModel.PlanType.Premium,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Votre paiement pour la souscription à l’offre <b>PREMIUM a échoué</b>`,
        text: `Tout n'est pas perdu... Vous avez jusqu'au <b>${moment(
          subscription.value?.startAt
        )
          .add(30, "days")
          .format(
            "DD/MM/YYYY"
          )}</b> pour procéder au règlement de votre facture. A défaut de paiement avant cette date,
          cet abonnement sera automatiquement résilié conformément aux
          <a style="color: #000;" href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank">conditions d'utilisation</a>`,
        info: `💡 Besoin d’aide ? Contactez-nous sur le live-chat ou à l’adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.error,
        paymentStatus: SubscriptionsModel.PaymentStatus.failed,
        type: SubscriptionsModel.PlanType.Optimum,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Votre paiement pour la souscription à l’offre <b>OPTIMUM a échoué</b>`,
        text: `Tout n'est pas perdu... Vous avez jusqu'au <b>${moment(
          subscription.value?.startAt
        )
          .add(30, "days")
          .format(
            "DD/MM/YYYY"
          )}</b> pour procéder au règlement de votre facture. A défaut de paiement avant cette date,
          cet abonnement sera automatiquement résilié conformément aux
          <a style="color: #000;" href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank">conditions d'utilisation</a>`,
        info: `💡 Besoin d’aide ? Contactez-nous sur le live-chat ou à l’adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.error,
        paymentStatus: SubscriptionsModel.PaymentStatus.failed,
        type: SubscriptionsModel.PlanType.LMNP,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Votre paiement pour la souscription à l’offre <b>LMNP a échoué</b>`,
        text: `Tout n'est pas perdu... Vous avez jusqu'au <b>${moment(
          subscription.value?.startAt
        )
          .add(30, "days")
          .format(
            "DD/MM/YYYY"
          )}</b> pour procéder au règlement de votre facture. A défaut de paiement avant cette date,
          cet abonnement sera automatiquement résilié conformément aux
          <a style="color: #000;" href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank">conditions d'utilisation</a>`,
        info: `💡 Besoin d’aide ? Contactez-nous sur le live-chat ou à l’adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.end,
        paymentStatus: SubscriptionsModel.PaymentStatus.canceled,
        type: SubscriptionsModel.PlanType.Solo,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Vous n'avez pas finalisé la souscription à l'offre.`,
        text: `Si vous le souhaitez, vous pouvez recommencer l'opération et modifier vos choix en cliquant sur les boutons <b>"Crayon"</b> et <b>"Suivant"</b> dans le parcours de souscription.`,
        info: `💡 Besoin d'aide ? Contactez-nous sur le live-chat ou à l'adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.end,
        paymentStatus: SubscriptionsModel.PaymentStatus.canceled,
        type: SubscriptionsModel.PlanType.Basic,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Vous n'avez pas finalisé la souscription à l'offre.`,
        text: `Si vous le souhaitez, vous pouvez recommencer l'opération et modifier vos choix en cliquant sur les boutons <b>"Crayon"</b> et <b>"Suivant"</b> dans le parcours de souscription.`,
        info: `💡 Besoin d'aide ? Contactez-nous sur le live-chat ou à l'adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.end,
        paymentStatus: SubscriptionsModel.PaymentStatus.canceled,
        type: SubscriptionsModel.PlanType.Premium,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Vous n'avez pas finalisé la souscription à l'offre.`,
        text: `Si vous le souhaitez, vous pouvez recommencer l'opération et modifier vos choix en cliquant sur les boutons <b>"Crayon"</b> et <b>"Suivant"</b> dans le parcours de souscription.`,
        info: `💡 Besoin d'aide ? Contactez-nous sur le live-chat ou à l'adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.end,
        paymentStatus: SubscriptionsModel.PaymentStatus.canceled,
        type: SubscriptionsModel.PlanType.Optimum,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Vous n'avez pas finalisé la souscription à l'offre.`,
        text: `Si vous le souhaitez, vous pouvez recommencer l'opération et modifier vos choix en cliquant sur les boutons <b>"Crayon"</b> et <b>"Suivant"</b> dans le parcours de souscription.`,
        info: `💡 Besoin d'aide ? Contactez-nous sur le live-chat ou à l'adresse contact@ownily.fr`,
      },
      {
        status: SubscriptionsModel.SubscriptionStatus.end,
        paymentStatus: SubscriptionsModel.PaymentStatus.canceled,
        type: SubscriptionsModel.PlanType.LMNP,
        title: "Que s'est-il passé ? 🤔",
        subtitle: `Vous n'avez pas finalisé la souscription à l'offre.`,
        text: `Si vous le souhaitez, vous pouvez recommencer l'opération et modifier vos choix en cliquant sur les boutons <b>"Crayon"</b> et <b>"Suivant"</b> dans le parcours de souscription.`,
        info: `💡 Besoin d'aide ? Contactez-nous sur le live-chat ou à l'adresse contact@ownily.fr`,
      },
    ]);
    const subscriptionPlan = computed(() => {
      if (
        props.stripeReturnStatus ===
          SubscriptionsModel.PaymentStatusParams.succeeded ||
        subscription.value?.status ===
          SubscriptionsModel.SubscriptionStatus.error
      ) {
        return subscriptionPlans.value.find(
          (plan) =>
            plan.status == subscription.value?.status &&
            plan.type == subscription.value?.plan.type
        );
      } else {
        return subscriptionPlans.value.find(
          (plan) => plan.status == SubscriptionsModel.SubscriptionStatus.end
        );
      }
    });

    const eventAlreadySend = ref(false); // ! For not send two event (update store)
    watch(
      [subscription, props.stripeReturnStatus],
      () => {
        if (subscription.value && !eventAlreadySend.value) {
          if (props.stripeReturnStatus) {
            dispatchSubscriptionEvent({
              status: props.stripeReturnStatus,
              id: subscription.value.id,
              productId: subscription.value.productId,
              plan: subscription.value.plan,
            });
          } else {
            dispatchSubscriptionEvent({
              status: subscription.value.payment.status,
              id: subscription.value.id,
              productId: subscription.value.productId,
              plan: subscription.value.plan,
            });
          }
          eventAlreadySend.value = true;
        }
      },
      { deep: true }
    );

    /**
     * Return datas
     */
    return {
      subscription, // For test e2e
      subscriptionPlan,
      SubscriptionStatus: SubscriptionsModel.SubscriptionStatus,
      PaymentStatus: SubscriptionsModel.PaymentStatus,
    };
  },
});
