





































































import { SubscriptionsModel, TaxRegime } from "@edmp/api";
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { useSubscriptions } from "./subscriptions.usable";
import SubscriptionsPlanItem from "./SubscriptionsPlanItem.vue";
import SubscriptionDeductibility from "./SubscriptionDeductibility.vue";
import { productsStore } from "@/store";

export default defineComponent({
  name: "SubscriptionsPlan",
  components: { SubscriptionsPlanItem, SubscriptionDeductibility },
  props: {
    isEditable: { type: Boolean, default: false },
    planSelected: {
      type: Object as PropType<SubscriptionsModel.PlanParams>,
      required: false,
    },
  },

  setup(props, context) {
    const { isLoading, subscriptionPlanItems } = useSubscriptions();

    const selectedPlan: Ref<
      | SubscriptionsModel.PlanParams
      | (Omit<SubscriptionsModel.PlanParams, "type"> & { type: string })
    > = ref(
      productsStore.currentProduct?.lastTaxRegime === TaxRegime.IR_2072
        ? {
            type: SubscriptionsModel.PlanTypeParams.Premium,
            durationType: SubscriptionsModel.DurationTypeParams.year,
            couponUse: false,
          }
        : productsStore.currentProduct?.lastTaxRegime === TaxRegime.IS_2065
        ? {
            type: SubscriptionsModel.PlanTypeParams.Optimum,
            durationType: SubscriptionsModel.DurationTypeParams.year,
            couponUse: false,
          }
        : {
            type: SubscriptionsModel.PlanTypeParams.LMNP,
            durationType: SubscriptionsModel.DurationTypeParams.year,
            couponUse: false,
          }
    );

    const selectDurationType = (
      selectDurationType: SubscriptionsModel.DurationTypeParams
    ) => {
      selectedPlan.value.durationType = selectDurationType;
    };

    const selectPlan = (selectPlan: SubscriptionsModel.PlanParams) => {
      selectedPlan.value = selectPlan;
      context.emit("selectPlan", selectedPlan.value);
    };

    /**
     * Mutation
     */
    watch(
      () => props.planSelected,
      () =>
        (selectedPlan.value = Object.assign(
          selectedPlan.value,
          props.planSelected
        )),
      { deep: true }
    );

    return {
      isLoading,
      selectedPlan: selectedPlan,
      DurationTypeParams: SubscriptionsModel.DurationTypeParams,
      PlanTypeParams: SubscriptionsModel.PlanTypeParams,
      subscriptionPlanItems,
      selectDurationType,
      selectPlan,
    };
  },
});
