












































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
} from "@vue/composition-api";
import {
  TaxDeclaration,
  TaxDeclarationProviderTeledecModel,
  TaxDeclarationStatus,
  TaxRegime,
} from "@edmp/api";

import { usersStore } from "@/store";

import PageHeader from "@/components/atom/PageHeader.vue";

export default defineComponent({
  name: "TeletransmitFollowUpError",
  components: {
    PageHeader,
  },
  props: {
    taxDeclaration: {
      type: Object as PropType<
        TaxDeclaration<TaxRegime, TaxDeclarationStatus.Error, boolean, true> & {
          dgfip: {
            status: TaxDeclarationProviderTeledecModel.WebHook.STATUS.ERROR;
          };
        }
      >,
      required: true,
    },
  },
  setup() {
    const user = computed(() => usersStore.loggedInUser);
    const isOpenCalendly = ref(false);

    // Init
    const init = async () => {
      //
    };

    onBeforeMount(async () => {
      await init();
    });

    return { isOpenCalendly, user };
  },
});
