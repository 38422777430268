









































import LoginForm from "@/components/sign/signin/LoginForm.vue";
import SignTemplate from "@/components/sign/SignTemplate.vue";
import { ROUTE_NAMES } from "@/router/routes";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Login",
  components: {
    LoginForm,
    SignTemplate,
  },
  setup() {
    return { ROUTE_NAMES };
  },
});
