





















import {
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  accountingPeriodsStore,
  transactionsStore,
  productsStore,
} from "@/store";

import TransactionsOverviewAccPeriod from "./TransactionsOverviewAccPeriod.vue";
import { chain, Dictionary, head } from "lodash";
import { AccountingPeriod, Transaction } from "@edmp/api/";
import { useAnomalies } from "../../anomalies/anomalies.usable";
import { transactionsService } from "@/services/Transactions.service";

export default defineComponent({
  name: "TransactionsOverview",
  components: {
    TransactionsOverviewAccPeriod,
  },
  setup() {
    const transactionNumbersByAccPeriod: Ref<
      Dictionary<{
        acc: AccountingPeriod;
        loading: boolean;
        transactionsToBeCategorizedNumber: number;
        transactionsSuggestNumber: number;
        transactionsAnomalyNumber: number;
      }>
    > = ref({});
    const { checkDisableAnomalies } = useAnomalies();
    async function init(): Promise<void> {
      const init = {};
      for (const acc of accountingPeriodsStore.accountingPeriods) {
        init[acc.id] = { acc, loading: true };
      }
      transactionNumbersByAccPeriod.value = init;

      const result = {};
      if (productsStore.currentId) {
        for (const acc of accountingPeriodsStore.accountingPeriods) {
          const transactions: Transaction[] = Object.values(
            chain(
              await transactionsService.listPeriod({
                productId: productsStore.currentId,
                accountingPeriodId: acc.id,
              })
            )
              .groupBy("id")
              .omit()
              .mapValues(head)
              .value()
          );

          result[acc.id] = {
            acc,
            loading: false,
            transactionsToBeCategorizedNumber: transactions.filter(
              (t) =>
                !t.operations || t.operations?.journalEntry?.lines?.length == 0
            ).length,
            transactionsSuggestNumber: transactions.filter(
              (t) =>
                t.operations?.journalEntry?.lines?.length == 0 &&
                t.operations?.journalEntry.suggestedLines?.length != 0
            ).length,
            transactionsAnomalyNumber: transactions.filter(
              (t) =>
                !!t.operations?.journalEntry?.lines?.find(
                  (line) =>
                    !!line.anomalies?.filter((anomaly) =>
                      checkDisableAnomalies(anomaly.anomalyError)
                    ).length
                )
            ).length,
          };
        }
        transactionNumbersByAccPeriod.value = result;
      }
    }

    watch(
      () => [
        transactionsStore.numberOfTransactionsToBeCategorized,
        transactionsStore.numberOfTransactionsToBeSuggested,
      ],
      () => init()
    );

    onBeforeMount(() => init());

    return {
      transactionNumbersByAccPeriod,
    };
  },
});
