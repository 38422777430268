










































import {
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  computed,
} from "@vue/composition-api";
import TitleNew from "../../core/title/TitleNew.vue";
import BalanceLine512 from "./BalanceLine512.vue";
import {
  bankAccountsStore,
  productsStore,
  subscriptionsStore,
  accountingPeriodsStore,
  accountingBalanceSheetsStore,
} from "@/store";
import moment from "moment";
import { BankAccount } from "@edmp/api";

export default defineComponent({
  name: "Balance",
  components: { TitleNew, BalanceLine512 },
  setup() {
    const bankAccounts: Ref<BankAccount[]> = ref([]);
    const previousYearAccountingBalanceSheet = computed(() => {
      return accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet;
    });
    const currentYearAccountingBalanceSheet = computed(() => {
      return accountingBalanceSheetsStore.getCurrentAccountingBalanceSheet;
    });

    async function getBankAccounts() {
      bankAccounts.value = bankAccountsStore.bankAccounts.filter(
        (b) => b.bankId !== "Manual"
      );
      await bankAccountsStore.fetchBankAccounts(productsStore.currentId);
    }

    async function init() {
      await getBankAccounts();
    }

    onBeforeMount(async () => {
      await init();
    });

    return {
      bankAccounts,
      moment,
      subscriptionsStore,
      accountingPeriodsStore,
      accountingBalanceSheetsStore,
      previousYearAccountingBalanceSheet,
      currentYearAccountingBalanceSheet,
    };
  },
});
