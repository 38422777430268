







































import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  watch,
} from "@vue/composition-api";
import { ProductsModel, SubscriptionsModel, TaxRegime } from "@edmp/api";
import { productsStore, coreStore, subscriptionsStore } from "@/store";

import { HelpingMessage } from "@/components/atom/helping";
import Title from "../title/Title.vue";
import SubscriptionFlowHorizontal from "../subscriptions/update/SubscriptionFlowHorizontal.vue";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "ActivityCreate",
  components: {
    HelpingMessage,
    Title,
    SubscriptionFlowHorizontal,
  },
  props: {
    productId: { type: String, required: false },
    stripeReturnStatus: {
      type: String as PropType<SubscriptionsModel.PaymentStatusParams.canceled>,
      required: false,
    },
  },
  setup(props, context) {
    const activity = computed(() => {
      if (
        context.root.$route.name === ROUTE_NAMES.ActivityContinue ||
        context.root.$route.name === ROUTE_NAMES.ActivitySubscriptionCreate ||
        context.root.$route.name === ROUTE_NAMES.ActivityCreateCancel
      ) {
        return productsStore.currentProduct?.activity;
      }
      return undefined;
    });

    //Subscription
    const subscription = computed(() => {
      if (activity.value?.productId) {
        return subscriptionsStore.getSubscriptionByProduct(
          activity.value.productId
        );
      }
      return undefined;
    });

    // Init
    async function init() {
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      if (
        productsStore.currentProduct?.status ===
        ProductsModel.ProductStatus.completed
      ) {
        if (context.root.$route.name === ROUTE_NAMES.ActivityCreate) {
          const productTemp = productsStore.products.find(
            ({ status }) => status === ProductsModel.ProductStatus.pending
          );
          if (productTemp?.id) {
            await productsStore.switchProduct({ id: productTemp.id });
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message:
                "Vous avez déjà initié l'abonnement d'une activité, finalisez le parametrage avant d'ajouter une nouvelle activité.",
            });
            context.root.$router.push({
              name: ROUTE_NAMES.ActivityContinue,
            });
          }
        } else if (
          context.root.$route.name === ROUTE_NAMES.ActivitySubscriptionCreate
        ) {
          // nothing
        } else if (
          context.root.$route.name === ROUTE_NAMES.ActivityCreateCancel &&
          subscription.value?.id &&
          props.stripeReturnStatus ===
            SubscriptionsModel.PaymentStatusParams.canceled
        ) {
          context.root.$router.push({
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscription.value.id,
              stripeReturnStatus: props.stripeReturnStatus,
            },
          });
        } else {
          context.root.$router.push({
            name: ROUTE_NAMES.Activities,
          });
        }
      } else if (
        productsStore.currentProduct?.status ===
          ProductsModel.ProductStatus.pending &&
        !props.stripeReturnStatus
      ) {
        if (context.root.$route.name === ROUTE_NAMES.ActivityCreate) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: "Finalisez le parametrage pour accéder à ce service.",
          });
        }
        if (context.root.$route.name !== ROUTE_NAMES.ActivityContinue) {
          context.root.$router.push({
            name: ROUTE_NAMES.ActivityContinue,
          });
        }
      }
    }

    watch(
      () => [productsStore.currentProduct, context.root.$route.name],
      () => init(),
      {
        deep: true,
      }
    );

    onBeforeMount(() => init());

    return {
      ROUTE_NAMES,
      productStatus: productsStore.currentProduct?.status,
      taxRegime: productsStore.currentProduct?.lastTaxRegime,
      activity,
      subscription,
      TaxRegime,
      openArticleSubscriptionCondition: () =>
        useCrisp().openArticle(ArticleEnum.COMPANY_SETTING),
    };
  },
});
