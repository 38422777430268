import { accountingPeriodsStore } from "@/store";
import { AccountingPeriod, getMoment } from "@edmp/api";

export interface DisplayAccountingPeriod {
  startAt: string;
  endAt: string;
  id: string;
  text: string;
  acc: AccountingPeriod;
}

export function useAccountingPeriod(): {
  getAccountingYearText: (
    accountingPeriod: AccountingPeriod | undefined
  ) => string;
  getAccountingPeriodText: (
    accountingPeriod: AccountingPeriod | undefined
  ) => string;
  getAccountingPeriodTextById: (accountingPeriodId: string) => string;
  getAccountingPeriodList(): Promise<DisplayAccountingPeriod[]>;
} {
  const getAccountingYearText = (accountingPeriod?: AccountingPeriod) => {
    return `Année ${
      accountingPeriod && accountingPeriod.endAt
        ? new Date(accountingPeriod.endAt).getFullYear()
        : "--"
    }`;
  };

  const getAccountingPeriodText = (accountingPeriod?: AccountingPeriod) => {
    if (!accountingPeriod) return "--";
    return `${
      accountingPeriod.startAt
        ? getMoment(accountingPeriod.startAt).format("L")
        : "?"
    } - ${
      accountingPeriod.endAt
        ? getMoment(accountingPeriod.endAt).format("L")
        : "?"
    }`;
  };

  const getAccountingPeriodTextById = (accountingPeriodId: string) => {
    const accountingPeriod: AccountingPeriod | undefined =
      accountingPeriodsStore.accountingPeriods?.find(
        (acc: AccountingPeriod) => acc.id === accountingPeriodId
      );
    return getAccountingYearText(accountingPeriod);
  };

  async function getAccountingPeriodList(): Promise<DisplayAccountingPeriod[]> {
    return accountingPeriodsStore.accountingPeriods.map(
      (acc: AccountingPeriod) => {
        return {
          startAt: acc.startAt,
          endAt: acc.endAt,
          id: acc.id,
          text: getAccountingPeriodText(acc),
          acc: acc,
        };
      }
    );
  }

  return {
    getAccountingYearText,
    getAccountingPeriodText,
    getAccountingPeriodTextById,
    getAccountingPeriodList,
  };
}
