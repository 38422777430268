export function formatLabel(str: string, maxwidth: number) {
  const sections: Array<string> = [];
  const words = str.split(" ");
  let temp = "";

  words.forEach(function (item, index) {
    if (temp.length > 0) {
      const concat = temp + " " + item;

      if (concat.length > maxwidth) {
        sections.push(temp);
        temp = "";
      } else {
        if (index == words.length - 1) {
          sections.push(concat);
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }

    if (index == words.length - 1) {
      sections.push(item);
      return;
    }

    if (item.length < maxwidth) {
      temp = item;
    } else {
      sections.push(item);
    }
  });

  return sections;
}
