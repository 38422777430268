











































import { ROUTE_NAMES } from "@/router/routes";
import { defineComponent } from "@vue/composition-api";
import ItemsNavigation from "./ItemsNavigation.vue";

export default defineComponent({
  name: "Navigation",
  components: {
    ItemsNavigation,
  },

  setup(props, context) {
    function goProductTour() {
      context.root.$router.replace({
        name: ROUTE_NAMES.Dashboard,
        query: { welcome: "new" },
      });
      window.location.reload();
    }

    return { goProductTour };
  },
});
