import { Document, Schema, model } from "mongoose";
import { ulid } from "ulid";
import { BalanceCategory } from "./Accounting.model";
import { AccountingPeriod } from "./AccountingPeriod.model";
import { Address, PartialField, decimal2JSON } from "./Common.model";
import { Direction, LedgerAccountEnum } from "./JournalComposedEntry";
import { TaxRegime } from "./TaxRegime.enum";

/**
 * * AccountingResultLine
 */
export enum AccountingResultLineType {
  OPERATING_INCOME = "operating-income",
  OPERATING_EXPENSES = "operating-expenses",
  OPERATING_RESULT = "operating-result",
  OTHER_INCOME_AND_EXPENSES = "other-income-and-expenses",
  ACCOUNTING_RESULT = "accounting-result",
  DEPRECIATION_AND_AMORTISATION = "depreciation-and-amortization",
  DEDUCTIBLE_DEPRECIATION = "deductible-depreciation",
  NON_DEDUCTIBLE_DEPRECIATION = "non-deductible-depreciation",
  TAX_DEFICIT = "tax-deficit",
  TAX_RESULT_BEFORE_ALLOCATION = "tax-result-before-allocation",
  DEFICIT_CARRIED_FORWARD = "deficit-carried-forward",
  DEPRECIATION_CARRIED_FORWARD = "depreciation-carried-forward",
  TAX_RESULT_AFTER_ALLOCATION = "tax-result-after-allocation",
}

export type AccountingResultLine<Regime extends TaxRegime = TaxRegime> = {
  id?: string;
  amount: number;
  direction: Direction;
  transactionIds: string[];
  categories: Pick<BalanceCategory, "account" | "balance" | "balanceDirection" | "reference" | "transactionIds">[];
  createdAt: string;
  updatedAt: string;
} & (Regime extends TaxRegime.IR_2072
  ? { type: defaultLineTypeIR }
  : Regime extends TaxRegime.IS_2065
  ? { type: defaultLineTypeIS }
  : Regime extends TaxRegime.LMNP_2031
  ? { type: defaultLineTypeLMNP }
  : never);

export type defaultLineTypeIR =
  | AccountingResultLineType.OPERATING_INCOME
  | AccountingResultLineType.OPERATING_EXPENSES
  | AccountingResultLineType.OPERATING_RESULT
  | AccountingResultLineType.OTHER_INCOME_AND_EXPENSES
  | AccountingResultLineType.ACCOUNTING_RESULT
  | AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION;
export type defaultLineTypeIS =
  | AccountingResultLineType.OPERATING_INCOME
  | AccountingResultLineType.OPERATING_EXPENSES
  | AccountingResultLineType.OPERATING_RESULT
  | AccountingResultLineType.OTHER_INCOME_AND_EXPENSES
  | AccountingResultLineType.ACCOUNTING_RESULT
  | AccountingResultLineType.DEPRECIATION_AND_AMORTISATION
  | AccountingResultLineType.TAX_DEFICIT
  | AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
  | AccountingResultLineType.DEFICIT_CARRIED_FORWARD
  | AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION;
export type defaultLineTypeLMNP =
  | AccountingResultLineType.OPERATING_INCOME
  | AccountingResultLineType.OPERATING_EXPENSES
  | AccountingResultLineType.OPERATING_RESULT
  | AccountingResultLineType.OTHER_INCOME_AND_EXPENSES
  | AccountingResultLineType.ACCOUNTING_RESULT
  | AccountingResultLineType.DEPRECIATION_AND_AMORTISATION
  | AccountingResultLineType.DEDUCTIBLE_DEPRECIATION
  | AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION
  | AccountingResultLineType.TAX_DEFICIT
  | AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
  | AccountingResultLineType.DEFICIT_CARRIED_FORWARD
  | AccountingResultLineType.DEPRECIATION_CARRIED_FORWARD
  | AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION;

const defaultLineTypeIRValues: defaultLineTypeIR[] = [
  AccountingResultLineType.OPERATING_INCOME,
  AccountingResultLineType.OPERATING_EXPENSES,
  AccountingResultLineType.OPERATING_RESULT,
  AccountingResultLineType.OTHER_INCOME_AND_EXPENSES,
  AccountingResultLineType.ACCOUNTING_RESULT,
  AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION,
];
const defaultLineTypeISValues: defaultLineTypeIS[] = [
  AccountingResultLineType.OPERATING_INCOME,
  AccountingResultLineType.OPERATING_EXPENSES,
  AccountingResultLineType.OPERATING_RESULT,
  AccountingResultLineType.OTHER_INCOME_AND_EXPENSES,
  AccountingResultLineType.ACCOUNTING_RESULT,
  AccountingResultLineType.DEPRECIATION_AND_AMORTISATION,
  AccountingResultLineType.TAX_DEFICIT,
  AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION,
  AccountingResultLineType.DEFICIT_CARRIED_FORWARD,
  AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION,
];
const defaultLineTypeLMNPValues: defaultLineTypeLMNP[] = [
  AccountingResultLineType.OPERATING_INCOME,
  AccountingResultLineType.OPERATING_EXPENSES,
  AccountingResultLineType.OPERATING_RESULT,
  AccountingResultLineType.OTHER_INCOME_AND_EXPENSES,
  AccountingResultLineType.ACCOUNTING_RESULT,
  AccountingResultLineType.DEPRECIATION_AND_AMORTISATION,
  AccountingResultLineType.DEDUCTIBLE_DEPRECIATION,
  AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION,
  AccountingResultLineType.TAX_DEFICIT,
  AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION,
  AccountingResultLineType.DEFICIT_CARRIED_FORWARD,
  AccountingResultLineType.DEPRECIATION_CARRIED_FORWARD,
  AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION,
];

export const accountingResultDefaultLineTypes: {
  [key in TaxRegime]: AccountingResultLineType[];
} = {
  [TaxRegime.IR_2072]: defaultLineTypeIRValues,
  [TaxRegime.IS_2065]: defaultLineTypeISValues,
  [TaxRegime.LMNP_2031]: defaultLineTypeLMNPValues,
};

export type AccountingResultLineCreate<Regime extends TaxRegime = TaxRegime> = Omit<
  AccountingResultLine<Regime>,
  "id" | "createdAt" | "updatedAt"
>;
export type AccountingResultLineUpdate = Partial<Omit<AccountingResultLine, "createdAt" | "updatedAt">>;

// Mongo
export const accountingResultLineSchema = new Schema<AccountingResultLine>(
  {
    _id: { type: String, default: () => ulid() },
    amount: { type: Schema.Types.Decimal128, required: true, index: true },
    direction: { type: String, required: true },
    transactionIds: { type: Array, required: true },
    type: { type: String, enum: Object.values(AccountingResultLineType), required: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingResultLineDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);
export type AccountingResultLineDocument = AccountingResultLine & Document<string>;

/**
 * * AccountingResult
 */
export type AccountingResult<Type extends AccountingResultType = AccountingResultType> = {
  id: string;
  productId: string;
  type: Type;
  lines: AccountingResultLine[];
  createdAt: string;
  updatedAt: string;
} & (
  | {
      type: AccountingResultType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingResultType.CLOSURE;
      accountingPeriodId: string;
    }
);

export enum AccountingResultType {
  RECOVERY = "recovery",
  CLOSURE = "closure",
}

export type AccountingResultCreate<Type extends AccountingResultType = AccountingResultType> = {
  productId: string;
  type: Type;
  lines: AccountingResultLineCreate[];
} & (
  | {
      type: AccountingResultType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingResultType.CLOSURE;
      accountingPeriodId: string;
    }
);
export type AccountingResultUpdate<Type extends AccountingResultType = AccountingResultType> = {
  id: string;
  type: Type;
  lines: AccountingResultLineUpdate[];
} & (
  | {
      type: AccountingResultType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingResultType.CLOSURE;
      accountingPeriodId: string;
    }
);
export type AccountingResultUpdateInternal<Type extends AccountingResultType = AccountingResultType> = {
  id: string;
  productId: string;
  type: Type;
  lines: AccountingResultLineUpdate[];
} & (
  | {
      type: AccountingResultType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingResultType.CLOSURE;
      accountingPeriodId: string;
    }
);

// Mongo
const accountingResultSchema = new Schema<AccountingResult>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, required: true, index: true },
    lines: { type: [accountingResultLineSchema], required: true },
    type: { type: String, enum: Object.values(AccountingResultType), required: true },
    accountingPeriodId: { type: String, index: true },
    startAt: { type: String },
    endAt: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingResultDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
export type AccountingResultDocument = AccountingResult & Document<string>;
export const AccountingResultModel = model<AccountingResultDocument>(
  "AccountingResult",
  accountingResultSchema,
  "AccountingResults"
);

// API
export namespace AccountingResultsService {
  export type CreateIn = AccountingResultCreate;
  export type CreateOut = AccountingResult;

  export type ListIn = Partial<Pick<AccountingResult, "productId" | "type">>;
  export type ListOut = AccountingResult[];

  export type GetIn = Pick<AccountingResult, "id">;
  export type GetOut = AccountingResult;

  export type UpdateIn = AccountingResultUpdate;
  export type UpdateOut = AccountingResult;

  export type DeleteIn = Pick<AccountingResult, "id">;
  export type DeleteOut = boolean;
}

// Metadata
export type AccountingResultLinesMetadata = {
  name: string;
  description?: string;
  isTotal?: boolean;
  isResult?: boolean;
  accounts: LedgerAccountEnum[];
};

const accountingResultLinesMetadataLayer0: {
  [key in Extract<
    AccountingResultLineType,
    | AccountingResultLineType.OPERATING_INCOME
    | AccountingResultLineType.OPERATING_EXPENSES
    | AccountingResultLineType.OTHER_INCOME_AND_EXPENSES
  >]: AccountingResultLinesMetadata;
} = {
  "operating-income": {
    name: "Total des revenus d'exploitations",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N706000,
      LedgerAccountEnum.N708300,
      LedgerAccountEnum.N708399,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N781100,
    ],
  },
  "operating-expenses": {
    name: "Total des charges d'exploitations",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N606110,
      LedgerAccountEnum.N606300,
      LedgerAccountEnum.N614010,
      LedgerAccountEnum.N614020,
      LedgerAccountEnum.N622710,
      LedgerAccountEnum.N622700,
      LedgerAccountEnum.N606400,
      LedgerAccountEnum.N616600,
      LedgerAccountEnum.N627100,
      LedgerAccountEnum.N622610,
      LedgerAccountEnum.N671400,
      LedgerAccountEnum.N623700,
      LedgerAccountEnum.N627200,
      LedgerAccountEnum.N616100,
      LedgerAccountEnum.N615200,
      LedgerAccountEnum.N615210,
      LedgerAccountEnum.N615310,
      LedgerAccountEnum.N681120,
      LedgerAccountEnum.N681110,
      LedgerAccountEnum.N635121,
      LedgerAccountEnum.N635125,
      LedgerAccountEnum.N622000,
      LedgerAccountEnum.N625100,
      LedgerAccountEnum.N618000,
      LedgerAccountEnum.N635110,
      LedgerAccountEnum.N626000,
    ],
  },
  "other-income-and-expenses": {
    name: "Total des autres revenus et charges",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N775000,
      LedgerAccountEnum.N675000,
      LedgerAccountEnum.N661110,
      LedgerAccountEnum.N661600,
      LedgerAccountEnum.N695000,
      LedgerAccountEnum.N671000,
      LedgerAccountEnum.N761000,
    ],
  },
};

const accountingResultLinesMetadataLayer1: {
  [key in Extract<
    AccountingResultLineType,
    AccountingResultLineType.OPERATING_RESULT | AccountingResultLineType.DEPRECIATION_AND_AMORTISATION
  >]: AccountingResultLinesMetadata;
} = {
  "operating-result": {
    name: "Resultat d'exploitation",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer0["operating-income"].accounts,
      ...accountingResultLinesMetadataLayer0["operating-expenses"].accounts,
    ],
  },
  "depreciation-and-amortization": {
    name: "Dotations aux amortissements",
    accounts: [LedgerAccountEnum.N681120],
  },
};

const accountingResultLinesMetadataLayer2: {
  [key in Extract<AccountingResultLineType, AccountingResultLineType.ACCOUNTING_RESULT>]: AccountingResultLinesMetadata;
} = {
  "accounting-result": {
    name: "Résultat comptable",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer0["other-income-and-expenses"].accounts,
      ...accountingResultLinesMetadataLayer1["operating-result"].accounts,
    ],
  },
};

const accountingResultLinesMetadataLayer3: {
  [key in Extract<
    AccountingResultLineType,
    | AccountingResultLineType.DEDUCTIBLE_DEPRECIATION
    | AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION
    | AccountingResultLineType.TAX_DEFICIT
  >]: AccountingResultLinesMetadata;
} = {
  "deductible-depreciation": {
    name: "Amortissements déductibles",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer2["accounting-result"].accounts,
      ...accountingResultLinesMetadataLayer1["depreciation-and-amortization"].accounts,
    ],
  },
  "non-deductible-depreciation": {
    name: "Amortissements non déductibles",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer2["accounting-result"].accounts,
      ...accountingResultLinesMetadataLayer1["depreciation-and-amortization"].accounts,
    ],
  },
  "tax-deficit": {
    name: "Déficit",
    accounts: [...accountingResultLinesMetadataLayer2["accounting-result"].accounts],
  },
};

const accountingResultLinesMetadataLayer4: {
  [key in Extract<
    AccountingResultLineType,
    | AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
    | AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION
    | AccountingResultLineType.DEFICIT_CARRIED_FORWARD
    | AccountingResultLineType.DEPRECIATION_CARRIED_FORWARD
  >]: AccountingResultLinesMetadata;
} = {
  "tax-result-before-allocation": {
    name: "Résultat fiscal avant imputation des déficits antérieurs",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer2["accounting-result"].accounts,
      ...accountingResultLinesMetadataLayer3["tax-deficit"].accounts,
    ],
  },
  "tax-result-after-allocation": {
    name: "Résultat fiscal après imputation des déficits antérieurs",
    isResult: true,
    accounts: [
      ...accountingResultLinesMetadataLayer2["accounting-result"].accounts,
      ...accountingResultLinesMetadataLayer3["tax-deficit"].accounts,
    ],
  },
  "deficit-carried-forward": {
    name: "Déficit reporté",
    accounts: [],
  },
  "depreciation-carried-forward": {
    name: "Amortissements reportés",
    accounts: [],
  },
};

export const accountingResultLinesMetadata: {
  [key in AccountingResultLineType]: AccountingResultLinesMetadata;
} = {
  ...accountingResultLinesMetadataLayer0,
  ...accountingResultLinesMetadataLayer1,
  ...accountingResultLinesMetadataLayer2,
  ...accountingResultLinesMetadataLayer3,
  ...accountingResultLinesMetadataLayer4,
};

// /!\ This model is use to archive document. It should match SummaryTable and tax-declaration-v2021.csv
export interface AccountingResultTemplateData {
  layout: false;
  date: number;
  companyAddress?: Address;
  companyName: string;
  creationDate: string;
  creater: {
    firstname: string;
    lastname: string;
  };
  exerciceStartDate: string;
  exerciceEndDate: string;
  sections?: Section[];
}

//section's total is based on each of its categories's total
export interface Section {
  categories: Category[];
  total: Total;
}

//category's total is the sum of each of it's subcategories's list of line
export interface Category {
  title: string;
  subCategories: SubCategory[];
  total?: Total;
}

export interface SubCategory {
  title: string;
  lines: Line[];
}

// A line is a value of a ledgerAccount for the current and previous year
export interface Line {
  code: LedgerAccountEnum;
  libelle: string;
  values: [string, string]; //[currentYear, previousYear]
}

export interface Total {
  title: string;
  values: [string, string]; //[currentYear, previousYear]
}
