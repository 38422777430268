









































































































































































































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import { isPlanBankAccount, TransactionImportType } from "@edmp/api";
import { parse } from "date-fns";

import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
  transactionsStore,
  partnersStore,
  bankAccountsStore,
} from "@/store";
import { accountingsService } from "@/services";
import { FilterKeys, FiltersParameters } from "@/utils";
import i18n from "@/plugins/i18n";

import { useTransactions } from "./transactions.usable";
import DatePicker from "@/components/atom/DatePicker.vue";
import { flatMap } from "lodash";

export default defineComponent({
  name: "TransactionsFiltersDrawer",
  components: {
    DatePicker,
  },
  props: { isOpen: Boolean },
  setup(props, context) {
    const { addFilter, removeFilter } = useTransactions();
    function toStringDate(date?: Date) {
      return date
        ? `${date.getFullYear()}-${
            date.getMonth() < 10
              ? "0" + (Number(date.getMonth()) + 1)
              : Number(date.getMonth()) + 1
          }-${
            date.getDate() < 10
              ? "0" + Number(date.getDate())
              : Number(date.getDate())
          }`
        : "";
    }

    const startDate: Ref<string | undefined> = ref(
      toStringDate(transactionsStore.filters.DATE_RANGE?.startDate)
    );
    const endDate: Ref<string | undefined> = ref(
      toStringDate(transactionsStore.filters.DATE_RANGE?.endDate)
    );
    const title: Ref<string | undefined> = ref(
      transactionsStore.filters.SUMMARY_SEARCH?.search
    );
    const minimalAmount: Ref<number | undefined> = ref(
      transactionsStore.filters.AMOUNT_RANGE?.minimalAmount
    );
    const maximalAmount: Ref<number | undefined> = ref(
      transactionsStore.filters.AMOUNT_RANGE?.maximalAmount
    );
    const uncategorizedItem: Ref<object[]> = ref([
      { text: "Non catégorisée", value: true },
      { text: "Toutes les transactions", value: false },
    ]);
    const selectedUncategorizedOrNot: Ref<boolean | undefined> = ref(
      !!transactionsStore.filters.ONLY_UNCATEGORIZED
    );

    const suggestedCategories: Ref<{ name: string; number: string }[]> = ref(
      []
    );
    const selectedCategories: Ref<{ name: string; number: string }[]> = ref([]);

    async function getCategories() {
      try {
        if (productsStore.currentId && accountingPeriodsStore.currentId) {
          const res = await accountingsService.listCategories({
            accountingPeriodId: accountingPeriodsStore.currentId,
          });
          suggestedCategories.value = flatMap(res).filter((element) => {
            if (element.name) {
              if (!isPlanBankAccount(element.number)) {
                return true;
              }
              return false;
            }
            return false;
          }) as { name: string; number: string }[];

          selectedCategories.value = transactionsStore.filters.CATEGORIES
            ? transactionsStore.filters.CATEGORIES?.categories.map((n) => {
                const cat = suggestedCategories.value.find(
                  (sc) => sc.number === n
                );
                return {
                  number: n,
                  name: cat ? cat.name : "",
                };
              })
            : [];
        }
      } catch (error) {
        console.error(error);
      }
    }

    getCategories();

    const suggestedBankAccounts: Ref<{ id: string; name: string }[]> = ref([]);
    const selectedBankAccounts: Ref<{ id: string; name: string }[]> = ref([]);
    async function getBankAccounts() {
      try {
        if (productsStore.currentId && accountingPeriodsStore.currentId) {
          for (const bankAccount of bankAccountsStore.bankAccounts) {
            suggestedBankAccounts.value.push({
              id: bankAccount.id,
              name: bankAccount.name,
            });
          }
          selectedBankAccounts.value = transactionsStore.filters.BANK_ACCOUNTS
            ? transactionsStore.filters.BANK_ACCOUNTS.ids.map((id) => {
                const bankAccount = suggestedBankAccounts.value.find(
                  (sr) => sr.id === id
                );
                return {
                  id,
                  name: bankAccount ? bankAccount.name : "",
                };
              })
            : [];
        }
      } catch (error) {
        console.error(error);
      }
    }

    getBankAccounts();

    const suggestedRealEstateAssets: Ref<{ id: string; name: string }[]> = ref(
      []
    );

    const selectedRealEstateAssets: Ref<{ id: string; name: string }[]> = ref(
      []
    );
    async function getRealEstateAssets() {
      try {
        if (productsStore.currentId && accountingPeriodsStore.currentId) {
          for (const realEstateAsset of realEstateAssetsStore.realEstateAssets) {
            suggestedRealEstateAssets.value.push({
              id: realEstateAsset.id,
              name: realEstateAsset.name,
            });
          }
          selectedRealEstateAssets.value = transactionsStore.filters
            .REAL_ESTATE_ASSETS
            ? transactionsStore.filters.REAL_ESTATE_ASSETS.ids.map((id) => {
                const realEstateAsset = suggestedRealEstateAssets.value.find(
                  (sr) => sr.id === id
                );
                return {
                  id,
                  name: realEstateAsset ? realEstateAsset.name : "",
                };
              })
            : [];
        }
      } catch (error) {
        console.error(error);
      }
    }

    getRealEstateAssets();

    const suggestedPartners: Ref<{ id: string; name: string }[]> = ref([]);

    const selectedPartners: Ref<{ id: string; name: string }[]> = ref([]);
    async function getPartners() {
      try {
        if (productsStore.currentId && accountingPeriodsStore.currentId) {
          for (const partner of partnersStore.partners) {
            suggestedPartners.value.push({
              id: partner.id,
              name: partnersStore.getPartnerName(partner.id),
            });
          }
          selectedPartners.value = transactionsStore.filters.PARTNERS
            ? transactionsStore.filters.PARTNERS.ids.map((id) => {
                const partner = suggestedPartners.value.find(
                  (sr) => sr.id === id
                );

                return {
                  id,
                  name: partner ? partner.name : "",
                };
              })
            : [];
        }
      } catch (error) {
        console.error(error);
      }
    }

    getPartners();

    watch(
      () => props.isOpen,
      () => {
        if (props.isOpen) {
          getCategories();
          selectedCategories.value = [];
        }
      }
    );

    const suggestedTypes: Ref<{ name: string; type: string }[]> = ref([
      { name: "Synchronisée", type: "SYNC" },
      { name: "Ajout manuel", type: TransactionImportType.MANUAL },
      { name: "Fichier importé", type: TransactionImportType.IMPORT },
      {
        name: "Payées par les associés",
        type: TransactionImportType.EXPENSE_REPORT,
      },
    ]);
    const selectedTypes: Ref<{ name: string; type: string }[]> = ref([]);

    function closeDrawer() {
      context.emit("finish");
    }

    function applyFilters() {
      if (title.value && title.value !== "") {
        addFilter(FilterKeys.SUMMARY_SEARCH, {
          search: title.value,
          label: `Contient "${title.value}"`,
        });
      } else {
        removeFilter(FilterKeys.SUMMARY_SEARCH);
      }

      if (selectedCategories.value.length !== 0) {
        addFilter(FilterKeys.CATEGORIES, {
          categories: Object.values(selectedCategories.value).map(
            (e) => e.number
          ),
          label: `Catégories : ${Object.values(selectedCategories.value).map(
            (e) => e.name
          )}`,
        });
      } else {
        removeFilter(FilterKeys.CATEGORIES);
      }

      if (selectedBankAccounts.value.length !== 0) {
        addFilter(FilterKeys.BANK_ACCOUNTS, {
          ids: Object.values(selectedBankAccounts.value).map((e) => e.id),
          label: `Compte bancaires : ${Object.values(
            selectedBankAccounts.value
          ).map((e) => e.name)}`,
        });
      } else {
        removeFilter(FilterKeys.BANK_ACCOUNTS);
      }

      if (selectedRealEstateAssets.value.length !== 0) {
        addFilter(FilterKeys.REAL_ESTATE_ASSETS, {
          ids: Object.values(selectedRealEstateAssets.value).map((e) => e.id),
          label: `Biens : ${Object.values(selectedRealEstateAssets.value).map(
            (e) => e.name
          )}`,
        });
      } else {
        removeFilter(FilterKeys.REAL_ESTATE_ASSETS);
      }

      if (selectedPartners.value.length !== 0) {
        addFilter(FilterKeys.PARTNERS, {
          ids: Object.values(selectedPartners.value).map((e) => e.id),
          label: `Associés : ${Object.values(selectedPartners.value).map(
            (e) => e.name
          )}`,
        });
      } else {
        removeFilter(FilterKeys.PARTNERS);
      }

      if (selectedTypes.value.length !== 0) {
        addFilter(FilterKeys.TYPES, {
          types: Object.values(selectedTypes.value).map((e) => e.type),
          label: `Types : ${Object.values(selectedTypes.value).map(
            (e) => e.name
          )}`,
        });
      } else {
        removeFilter(FilterKeys.TYPES);
      }

      if (
        (startDate.value && startDate.value !== "") ||
        (endDate.value && endDate.value !== "")
      ) {
        const dateFilter: FiltersParameters[FilterKeys.DATE_RANGE] = {};
        if (startDate.value && startDate.value !== "") {
          dateFilter.startDate = parse(
            startDate.value,
            "yyyy-MM-dd",
            new Date()
          );
        }
        if (endDate.value && endDate.value !== "") {
          dateFilter.endDate = parse(endDate.value, "yyyy-MM-dd", new Date());
        }
        if (dateFilter.startDate && dateFilter.endDate) {
          dateFilter.label = `Du ${i18n.d(
            dateFilter.startDate,
            "short",
            "fr-FR"
          )} au ${i18n.d(dateFilter.endDate, "short", "fr-FR")}`;
        } else if (dateFilter.startDate) {
          dateFilter.label = `À partir du ${i18n.d(
            dateFilter.startDate,
            "short",
            "fr-FR"
          )}`;
        } else if (dateFilter.endDate) {
          dateFilter.label = `Jusqu'au ${i18n.d(
            dateFilter.endDate,
            "short",
            "fr-FR"
          )}`;
        }

        addFilter(FilterKeys.DATE_RANGE, dateFilter);
      } else {
        removeFilter(FilterKeys.DATE_RANGE);
      }

      if (minimalAmount.value || maximalAmount.value) {
        const amountFilter: FiltersParameters[FilterKeys.AMOUNT_RANGE] = {};
        if (minimalAmount.value) {
          amountFilter.minimalAmount = minimalAmount.value;
        }
        if (maximalAmount.value) {
          amountFilter.maximalAmount = maximalAmount.value;
        }
        if (minimalAmount.value && maximalAmount.value) {
          amountFilter.label = `${minimalAmount.value} € < Montant < ${maximalAmount.value} €`;
        } else if (minimalAmount.value) {
          amountFilter.label = `${minimalAmount.value} € < Montant`;
        } else {
          amountFilter.label = `Montant < ${maximalAmount.value} €`;
        }

        addFilter(FilterKeys.AMOUNT_RANGE, amountFilter);
      } else {
        removeFilter(FilterKeys.AMOUNT_RANGE);
      }

      if (selectedUncategorizedOrNot.value === true) {
        addFilter(FilterKeys.ONLY_UNCATEGORIZED);
      } else {
        removeFilter(FilterKeys.ONLY_UNCATEGORIZED);
      }
      closeDrawer();
    }

    function resetLibelle() {
      title.value = "";
    }

    function resetAmounts() {
      minimalAmount.value = undefined;
      maximalAmount.value = undefined;
    }

    function resetDates() {
      startDate.value = "";
      endDate.value = "";
    }

    function resetSuggestCategories() {
      selectedCategories.value = [];
    }

    function resetSuggestBankAccounts() {
      suggestedBankAccounts.value = [];
    }

    function resetSuggestRealEstateAssets() {
      suggestedRealEstateAssets.value = [];
    }

    function resetUncategorizedOrNot() {
      selectedUncategorizedOrNot.value = undefined;
    }

    function resetTypes() {
      selectedCategories.value = [];
    }

    function resetPartners() {
      suggestedPartners.value = [];
    }

    function resetAllFilters() {
      resetLibelle();
      resetAmounts();
      resetDates();
      resetSuggestCategories();
      resetUncategorizedOrNot();
      resetTypes();
      resetPartners();
      applyFilters();
    }

    return {
      startDate,
      endDate,
      title,
      suggestedCategories,
      selectedCategories,
      suggestedBankAccounts,
      selectedBankAccounts,
      suggestedRealEstateAssets,
      selectedRealEstateAssets,
      applyFilters,
      minimalAmount,
      maximalAmount,
      resetLibelle,
      resetAmounts,
      resetDates,
      resetSuggestCategories,
      resetSuggestBankAccounts,
      resetSuggestRealEstateAssets,
      closeDrawer,
      uncategorizedItem,
      selectedUncategorizedOrNot,
      resetUncategorizedOrNot,
      resetAllFilters,
      suggestedTypes,
      selectedTypes,
      resetTypes,
      suggestedPartners,
      selectedPartners,
      resetPartners,
    };
  },
});
