import { Document as DocumentMongoose, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { RequireField, TaskCode, TaskGroupCode, TaxRegime } from "..";
import { Address, addressSchema } from "./Common.model";
import { Document } from "./Document.model";

/**
 * * Types
 */
export interface GeneralAssemblyEvent {
  id: string;
  // Unique Id for a Company
  productId: string;
  // ID for an accounting period
  accountingPeriodId: string;
  // courrier simple", "recommandé avec AR
  convocationMedium?: "courrier simple" | "recommandé avec AR";
  invitation?: string[];
  inviteDownloaded: boolean;
  report?: {
    partnersPresent: string[];
    partnersRepresent: string[];
    manager: {
      firstName: string;
      lastName: string;
      email: string;
    };
    totalBilan: string;
    totalBenefice: string;
    totalBeneficeFiscal: string;
    agHourBegin: string;
    agHourEnd: string;
    agendas: string[];
    resolutions: string[];
  };
  reportDownloaded: boolean;
  type: "ordinary" | "extraordinary";
  pattern: "yearEnd" | "other";
  /**
   * Possible References:
   * - [RFC7265 - jCal](https://tools.ietf.org/html/rfc7265)
   * - [RFC5545 - iCal - VEVENT](https://tools.ietf.org/html/rfc5545#section-3.6.1)
   */
  // ISO8601 date and optional time of the start of the event, pattern: "^[0-9]{4}-[0-9]{2}-[0-9]{2}$|^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
  startAt: string;
  // Address for the event location
  address?: Address;
  // Textual description of the event
  summary?: string;
  document?: Document;
  createdAt: string;
  updatedAt: string;
}

export type GeneralAssemblyEventCreate = Omit<
  GeneralAssemblyEvent,
  "id" | "inviteDownloaded" | "invitation" | "reportDownloaded" | "report" | "document" | "createdAt" | "updatedAt"
>;
export type GeneralAssemblyEventCreateInternal = Omit<
  GeneralAssemblyEvent,
  "id" | "createdAt" | "updatedAt" | "invitation" | "report" | "document"
>;

export type GeneralAssemblyEventUpdate = RequireField<
  Partial<
    Omit<
      GeneralAssemblyEvent,
      | "productId"
      | "accountingPeriodId"
      | "inviteDownloaded"
      | "invitation"
      | "reportDownloaded"
      | "report"
      | "document"
      | "createdAt"
      | "updatedAt"
    >
  >,
  "id"
>;
export type GeneralAssemblyEventUpdateInternal = RequireField<
  Partial<Omit<GeneralAssemblyEvent, "createdAt" | "updatedAt">>,
  "id"
>;

// Response to add additional data in response (not store in Assembly schema)
export interface GeneralAssemblyEventResponse extends GeneralAssemblyEvent {
  // Invite or report document
  document: Document;
}

/**
 * * Mongo
 */
const AssemblySchema = new Schema<GeneralAssemblyEventDocument>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    convocationMedium: String,
    invitation: { type: Array },
    inviteDownloaded: { type: Boolean, index: true, required: true },
    report: {
      partnersPresent: { type: Array },
      partnersRepresent: { type: Array },
      manager: {
        firstName: String,
        lastName: String,
        email: String,
      },
      totalBilan: String,
      totalBenefice: String,
      totalBeneficeFiscal: String,
      agHourBegin: String,
      agHourEnd: String,
      agendas: { type: Array },
      resolutions: { type: Array },
    },
    reportDownloaded: { type: Boolean, index: true, required: true },
    type: { type: String, index: true, required: true },
    pattern: String,
    startAt: String,
    address: addressSchema,
    summary: String,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(_, ret: GeneralAssemblyEventDocument): unknown {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type GeneralAssemblyEventDocument = GeneralAssemblyEvent & DocumentMongoose<string>;

// Name of the collection in third argument
export const AssemblyModel = model<GeneralAssemblyEventDocument>("Assembly", AssemblySchema, "Assemblies");

/**
 * * API
 */
export namespace AssembliesService {
  export type CreateIn = GeneralAssemblyEventCreate;
  export type CreateOut = GeneralAssemblyEvent;

  export type ListIn = Partial<
    Pick<GeneralAssemblyEvent, "productId" | "accountingPeriodId" | "inviteDownloaded" | "reportDownloaded">
  >;
  export type ListOut = GeneralAssemblyEvent[];

  export type GetIn = Pick<GeneralAssemblyEvent, "id">;
  export type GetOut = GeneralAssemblyEvent;

  export type UpdateIn = GeneralAssemblyEventUpdate;
  export type UpdateOut = GeneralAssemblyEvent;

  export type InviteIn = Required<Pick<GeneralAssemblyEvent, "id" | "invitation">>;
  export type InviteOut = GeneralAssemblyEvent;

  export type ReportIn = Required<
    Pick<GeneralAssemblyEvent, "id"> & { report: Omit<NonNullable<GeneralAssemblyEvent["report"]>, "manager"> }
  >;
  export type ReportOut = GeneralAssemblyEvent;
}

/**
 * * Lib
 */
export namespace AssembliesLib {
  export const isOrdinaryGeneralAssembly = (res: GeneralAssemblyEvent): boolean => {
    return res.type === "ordinary" && res.pattern !== "yearEnd";
  };

  // get task group code to retrieve assembly general or yearend in task
  export const getTaskGroupCode = (res: GeneralAssemblyEvent): TaskGroupCode => {
    if (isOrdinaryGeneralAssembly(res)) {
      return TaskGroupCode.EventGeneralAssembly;
    } else {
      return TaskGroupCode.EventGeneralAssemblyYearEnd;
    }
  };

  // get task code to retrieve assembly general or yearend in task
  export const getTaskCodeInvite = (res: GeneralAssemblyEvent): TaskCode => {
    if (isOrdinaryGeneralAssembly(res)) {
      return TaskCode.Invite;
    } else {
      return TaskCode.InviteYearEnd;
    }
  };

  // get task code to retrieve assembly general or yearend in task
  export const getTaskCodeReport = (res: GeneralAssemblyEvent): TaskCode => {
    if (isOrdinaryGeneralAssembly(res)) {
      return TaskCode.Report;
    } else {
      return TaskCode.ReportYearEnd;
    }
  };
}

// default agendas and resolutions
export const getDefaultAgendas = (endPeriod: string): { [key in TaxRegime]?: string[] } => {
  return {
    [TaxRegime.IR_2072]: [
      `Approbation des comptes clos le ${endPeriod ? endPeriod : `__ / __ / ____`}`,
      "Affectation des résultats de l’exercice",
      "Quitus de sa gestion à l'associé(e) gérant(e)",
    ],
    [TaxRegime.IS_2065]: [
      `Approbation des comptes clos le ${endPeriod ? endPeriod : `__ / __ / ____`}`,
      "Affectation des résultats de l’exercice",
      "Quitus de sa gestion à l'associé(e) gérant(e)",
    ],
  };
};
export const getDefaultResolutions = (startPeriod: string, agHourEnd: string): { [key in TaxRegime]?: string[] } => {
  return {
    [TaxRegime.IR_2072]: [
      `L’associé(e) gérant(e) donne connaissance aux associés des comptes établis pour l’exercice ${
        startPeriod ? startPeriod.slice(-4) : "___________"
      } qui ont été transmis à tous les associés préalablement à ce jour.`,
      `Les associés approuvent à l’unanimité le report du solde bénéficiaire sur l’exercice ${
        startPeriod ? Number.parseInt(startPeriod.slice(-4)) + 1 : "___________"
      } après sa répartition effectué entre les associés.`,
      `Les associés donnent quitus à l’unanimité à l'associé(e) gérant(e) de sa gestion. L’ordre du jour étant épuisé et les discussions closes, la séance est levée à ${
        agHourEnd ? agHourEnd : "__ : __"
      }. Il a été dressé le présent Procès-verbal signé par tous les associés, après lecture faite.
      `,
    ],
    [TaxRegime.IS_2065]: [
      `L’associé(e) gérant(e) donne connaissance aux associés des comptes établis pour l’exercice ${
        startPeriod ? startPeriod.slice(-4) : "___________"
      } qui ont été transmis à tous les associés préalablement à ce jour.`,
      `Les associés approuvent à l’unanimité l’affectation du résultat comptable sur l’exercice ${
        startPeriod ? Number.parseInt(startPeriod.slice(-4)) + 1 : "___________"
      } en report à nouveau sur le prochain exercice comptable.`,
      `Les associés donnent quitus à l’unanimité à l'associé(e) gérant(e) de sa gestion.`,
    ],
  };
};
