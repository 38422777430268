import { TaskActivityGroupLocal, TaskActivityLocal } from "@/models";
import { taskActivitiesService } from "@/services/TaskActivities.service";
import store from "@/store/root";
import { transformGroupsLocal, transformLocal } from "@/utils";
import {
  OmitField,
  SubscriptionsModel,
  TaskActivityService,
  TaskActivityStatus,
  TaskActivityTypeReference,
  TaskCode,
  TaskGroupCode,
  TaxRegime,
  getMoment,
  getReferredIdByTypeWithReferences,
} from "@edmp/api";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  accountingPeriodsStore,
  activitiesStore,
  productsStore,
  subscriptionsStore,
} from "..";

export interface TaskActivityState {
  taskActivityGroups: Array<TaskActivityGroupLocal>;
  loading: boolean;
  showYearEndModal: boolean;
}

@Module({
  name: "task-activity-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class TaskActivityStore extends VuexModule implements TaskActivityState {
  taskActivityGroups: Array<TaskActivityGroupLocal> = [];
  loading = false;
  showYearEndModal = false;

  get currentAccountingPeriodTaskGroups(): TaskActivityGroupLocal[] {
    return this.taskActivityGroups
      .filter(
        (taskGroup) =>
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.product
          ) === productsStore.currentId &&
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId
      )
      .map((taskGroup) => {
        const filteredTaskActivities = taskGroup.taskActivitiesLocal.filter(
          (taskActivity) =>
            taskActivity.code !== TaskCode.Create &&
            taskActivity.code !== TaskCode.UploadCerfa2072
        );
        return {
          ...taskGroup,
          taskActivitiesLocal: filteredTaskActivities,
        };
      })
      .filter((taskGroup) => taskGroup.taskActivitiesLocal.length > 0);
  }

  get openAccountingPeriodTaskGroups(): TaskActivityGroupLocal[] {
    const openAccountingPeriodIds = accountingPeriodsStore.accountingPeriods
      .filter((accountingPeriod) => {
        if (
          !accountingPeriod.closed &&
          getMoment(accountingPeriod.endAt).isBefore(getMoment())
        ) {
          return accountingPeriod;
        }
      })
      .sort((acc1, acc2) =>
        getMoment(acc1.startAt).isBefore(getMoment(acc2.startAt)) ? -1 : 1
      )
      .map((accountingPeriod) => accountingPeriod.id);

    return this.taskActivityGroups
      .filter(
        (taskGroup) =>
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.product
          ) === productsStore.currentId
      )
      .map((taskGroup) => {
        const filteredTaskActivities = taskGroup.taskActivitiesLocal
          .filter((taskActivity) =>
            taskActivity.references?.some(
              (reference) =>
                reference.type === TaskActivityTypeReference.accountingPeriod &&
                openAccountingPeriodIds.includes(reference.referredId)
            )
          )
          .filter((taskActivity) =>
            subscriptionsStore.getCurrentSubscription?.plan.type !==
            SubscriptionsModel.PlanType.Premium
              ? taskActivity.code !== TaskCode.BalanceSimple
              : true
          )
          .filter(
            (taskActivity) =>
              taskActivity.code !== TaskCode.Create &&
              taskActivity.code !== TaskCode.UploadCerfa2072
          );
        return {
          ...taskGroup,
          taskActivitiesLocal: filteredTaskActivities,
        };
      })
      .filter((taskGroup) => taskGroup.taskActivitiesLocal.length > 0);
  }

  get accountingPeriodTaskGroup(): TaskActivityGroupLocal | undefined {
    return this.taskActivityGroups.find(
      (taskGroup) =>
        taskGroup.groupCode === TaskGroupCode.AccountingPeriod &&
        getReferredIdByTypeWithReferences(
          taskGroup.references,
          TaskActivityTypeReference.product
        ) === productsStore.currentId &&
        getReferredIdByTypeWithReferences(
          taskGroup.references,
          TaskActivityTypeReference.accountingPeriod
        ) === accountingPeriodsStore.currentId
    );
  }
  get startWellTaskGroup(): TaskActivityGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.taskActivityGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.StartWell &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskActivityTypeReference.product
            ) === productsStore.currentId
        );
      case TaxRegime.IS_2065:
        return this.taskActivityGroups.filter((taskGroup) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.accountingPeriod
          );
          return (
            (taskGroup.groupCode === TaskGroupCode.StartWellInformation ||
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting) &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskActivityTypeReference.product
            ) === productsStore.currentId &&
            (!accountingPeriodId ||
              accountingPeriodId === accountingPeriodsStore.currentId)
          );
        });
      case TaxRegime.LMNP_2031:
        return this.taskActivityGroups.filter((taskGroup) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.accountingPeriod
          );
          const activityId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskActivityTypeReference.activity
          );
          return (
            (taskGroup.groupCode === TaskGroupCode.StartWellLMNP ||
              taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP) &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskActivityTypeReference.product
            ) === productsStore.currentId &&
            (!accountingPeriodId ||
              accountingPeriodId === accountingPeriodsStore.currentId) &&
            (!activityId || activityId === activitiesStore.currentId)
          );
        });
      default:
        return [];
    }
  }

  get closureTaskGroup(): TaskActivityGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.TransactionsYearEnd,
            TaskGroupCode.TaxReturnPrepareClose,
            TaskGroupCode.EventGeneralAssemblyYearEnd,
            TaskGroupCode.TeletransmitTaxReturn,
            TaskGroupCode.BalanceSheet,
          ].includes(taskGroup.groupCode)
        );

      case TaxRegime.IS_2065:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.YearEndTransactionsCheck,
            TaskGroupCode.FinancialReporting,
            TaskGroupCode.GetTaxReturns,
            TaskGroupCode.EventGeneralAssemblyYearEnd,
            TaskGroupCode.TeletransmitTaxReturn,
          ].includes(taskGroup.groupCode)
        );

      case TaxRegime.LMNP_2031:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.YearEndTransactionsCheck,
            TaskGroupCode.FinancialReporting,
            TaskGroupCode.GetTaxReturns,
            TaskGroupCode.TeletransmitTaxReturn,
          ].includes(taskGroup.groupCode)
        );
      default:
        return [];
    }
  }

  get closureCustomTaskGroup(): TaskActivityGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.openAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.TransactionsYearEnd ||
            taskGroup.groupCode === TaskGroupCode.TaxReturnPrepareClose ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd
        );
      default:
        return [];
    }
  }

  get eventAccountingPeriodClosureTaskGroup(): TaskActivityGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventAccountingPeriodClosure
        );
      default:
        return [];
    }
  }

  get assemblyTaskGroup(): TaskActivityGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssembly
        );
      case TaxRegime.IS_2065:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssembly
        );
      default:
        return [];
    }
  }

  get completedTaskActivities(): TaskActivityLocal[] {
    return this.taskActivityGroups.flatMap((taskGroup) =>
      taskGroup.taskActivitiesLocal.filter(
        (taskActivity) => taskActivity.status === TaskActivityStatus.COMPLETED
      )
    );
  }

  get notCompletedTaskActivities(): TaskActivityLocal[] {
    return this.taskActivityGroups.flatMap((taskGroup) =>
      taskGroup.taskActivitiesLocal.filter(
        (taskActivity) => taskActivity.status !== TaskActivityStatus.COMPLETED
      )
    );
  }

  get notCompletedTaskGroupWithDueDate(): TaskActivityGroupLocal[] {
    const taskGroupsWithDueDate = this.currentAccountingPeriodTaskGroups.filter(
      (taskGroup) => {
        const hasDueDate = taskGroup.taskActivitiesLocal.some(
          (taskActivityLocal) => taskActivityLocal.dueDate
        );

        if (hasDueDate) {
          const firstDueDate = taskGroup.taskActivitiesLocal.filter(
            (taskActivityLocal) => taskActivityLocal.dueDate
          )[0];

          taskGroup.dueDate = firstDueDate.dueDate;
        }

        return hasDueDate;
      }
    );
    return taskGroupsWithDueDate.filter(
      (taskGroup) => taskGroup.status !== TaskActivityStatus.COMPLETED
    );
  }

  get notCompletedTaskGroupWithoutDueDate(): TaskActivityGroupLocal[] {
    const taskGroups: TaskActivityGroupLocal[] =
      this.currentAccountingPeriodTaskGroups.filter((taskGroup) => {
        taskGroup.taskActivitiesLocal.some(
          (taskActivityLocal) => !taskActivityLocal.dueDate
        );
      });
    return taskGroups.filter(
      (taskGroup) => taskGroup.status !== TaskActivityStatus.COMPLETED
    );
  }

  get userNotificationsTaskActivities(): TaskActivityLocal[] {
    return (
      this.taskActivityGroups
        .find(
          (taskGroup) => taskGroup.groupCode === TaskGroupCode.UserNotifications
        )
        ?.taskActivitiesLocal.filter((taskActivity) => {
          if (taskActivity.status !== TaskActivityStatus.PENDING) {
            return false;
          }
          if (taskActivity.code === TaskCode.EmailValidate) {
            return !!getReferredIdByTypeWithReferences(
              taskActivity.references,
              TaskActivityTypeReference.user
            );
          }
          return true;
        }) || []
    );
  }

  get isCurrentAccountingPeriodFillCerfa2072Completed(): boolean {
    return !!this.completedTaskActivities.find(
      (taskActivity) =>
        taskActivity.code === TaskCode.FillCerfa2072 &&
        getReferredIdByTypeWithReferences(
          taskActivity.references,
          TaskActivityTypeReference.accountingPeriod
        ) === accountingPeriodsStore.currentId
    );
  }

  @Mutation
  reset(): void {
    this.taskActivityGroups = [];
    this.loading = false;
  }

  @Mutation
  setTaskActivityGroups(taskActivityGroups: TaskActivityGroupLocal[]): void {
    this.taskActivityGroups = taskActivityGroups;
  }

  @Mutation updateTaskActivity(taskActivity: TaskActivityLocal): void {
    const taskActivityGroupToMutateIndex: number =
      this.taskActivityGroups.findIndex(
        (group) =>
          group.groupCode === taskActivity.groupCode &&
          !!group.taskActivitiesLocal.find((t) => t.id === taskActivity.id)
      );
    const taskActivityToMutateIndex: number = this.taskActivityGroups[
      taskActivityGroupToMutateIndex
    ].taskActivitiesLocal.findIndex((t) => t.id === taskActivity.id);
    this.taskActivityGroups[taskActivityGroupToMutateIndex].taskActivitiesLocal[
      taskActivityToMutateIndex
    ].status = taskActivity.status;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setYearEndModal(isClosing: boolean): void {
    this.showYearEndModal = isClosing;
  }

  @Action
  closeYearEndModal(): void {
    this.setYearEndModal(false);
  }

  @Action
  openYearEndModal(): void {
    const closureTaskGroups: TaskActivityGroupLocal[] = this.closureTaskGroup;
    const isYearEndClosed =
      !!closureTaskGroups.length &&
      closureTaskGroups.every(
        (taskGroup) => taskGroup.status === TaskActivityStatus.COMPLETED
      );

    if (isYearEndClosed) {
      this.setYearEndModal(true);
    }
  }

  @Action
  async fetchTaskActivityGroups(
    params: TaskActivityService.ListIn
  ): Promise<void> {
    this.setLoading(true);
    try {
      this.setTaskActivityGroups(
        transformGroupsLocal(await taskActivitiesService.list(params))
      );
    } catch (err) {
      // manage error
    }
    this.setLoading(false);
  }

  @Action
  async validateTaskActivity(params: {
    taskActivityLocal: TaskActivityLocal;
    data?: OmitField<TaskActivityService.ValidateIn, "id">;
  }): Promise<void> {
    const taskActivityUpdated: TaskActivityLocal | undefined = transformLocal(
      await taskActivitiesService.validate({
        id: params.taskActivityLocal.id,
        ...params.data,
      })
    );
    if (taskActivityUpdated) {
      const taskActivityLocalUpdated = {
        ...params.taskActivityLocal,
        ...taskActivityUpdated,
      };
      this.updateTaskActivity(taskActivityLocalUpdated);
    }
  }

  @Action
  async activateTaskActivity(
    taskActivityLocal: TaskActivityLocal
  ): Promise<void> {
    const taskActivityUpdated: TaskActivityLocal | undefined = transformLocal(
      await taskActivitiesService.activate({
        id: taskActivityLocal.id,
      })
    );
    if (taskActivityUpdated) {
      taskActivityLocal = { ...taskActivityLocal, ...taskActivityUpdated };
      this.updateTaskActivity(taskActivityLocal);
    }
  }
}
