


































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
  PropType,
} from "@vue/composition-api";

import FinancesChart from "@/components/core/charts/FinancesChart.vue";
import Title from "../title/Title.vue";
import { accountingPeriodsStore, transactionsStore } from "@/store";
import useAccounting from "./useAccounting";
import { cloneDeep, flatMap } from "lodash";
import { filteredTransactions, Filters } from "@/utils";
import {
  isPlanBankAccount,
  isReportExercise,
  LedgerAccountEnum,
  Transaction,
} from "@edmp/api";

export default defineComponent({
  name: "AccountingFinance",
  components: {
    FinancesChart,
    Title,
  },
  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: false,
    },
  },
  setup(props) {
    const transactionsFilter: Ref<Filters | undefined> = ref();
    const balance = computed(() => {
      const transactions = cloneDeep(props.transactions)?.map((transaction) => {
        if (transaction.operations?.journalEntry?.lines) {
          transaction.operations.journalEntry.lines =
            transaction.operations.journalEntry.lines.filter(
              (line) => !isReportExercise(line.account)
            );
        }
        return transaction;
      });

      if (accountingPeriodsStore.currentAccountingPeriod) {
        return useAccounting.getBalance(
          accountingPeriodsStore.currentAccountingPeriod,
          filteredTransactions(transactions, transactionsFilter.value),
          {
            account: transactionsFilter.value?.CATEGORIES?.categories[0],
            // realEstateAssetIds:
            //   transactionsStore.filters.REAL_ESTATE_ASSETS?.ids,
          }
        );
      }
    });

    /*
     * Creating a dropdown menu that allows the user to select either the product or one or more real  estate assets.
     */
    // A boolean value that is set to true.
    const viewAllSelected: Ref<boolean> = ref(true);
    // A reference to an array of numbers.
    const viewCategoriesSelected: Ref<number | undefined> = ref();
    // A computed property that returns an array of objects.
    const viewData = computed(() => {
      const transactionCategorizeName =
        useAccounting.getTransactionCategorizeName(
          props.transactions ?? flatMap(transactionsStore.transactions)
        );
      return transactionCategorizeName.filter(
        (element: { account: string }) => {
          if (
            !isPlanBankAccount(element.account) &&
            element.account !== LedgerAccountEnum.N110001
          )
            return element;
        }
      );
    });
    // A computed property that returns a string.
    const viewSelectedText = computed(() => {
      if (viewAllSelected.value) {
        return "Toutes les catégories";
      } else if (viewCategoriesSelected.value !== undefined) {
        return viewData.value[viewCategoriesSelected.value].name;
      } else {
        return ``;
      }
    });
    // Watching the viewAllSelected and if it changes it will set the transactionsFilter.value to
    // undefined and viewCategoriesSelected.value to an empty array.
    watch(
      viewAllSelected,
      () => {
        if (viewAllSelected.value) {
          viewCategoriesSelected.value = undefined;
          transactionsFilter.value = undefined;
        } else if (
          !viewAllSelected.value &&
          viewCategoriesSelected.value === undefined
        ) {
          viewAllSelected.value = true;
        }
      },
      { deep: true }
    );
    // Watching the viewCategoriesSelected and if it changes it will set the transactionsFilter.value
    // to undefined and viewCategoriesSelected.value to an empty array.
    watch(
      viewCategoriesSelected,
      () => {
        if (viewCategoriesSelected.value !== undefined) {
          viewAllSelected.value = false;
          const selectedCategories =
            viewData.value[viewCategoriesSelected.value];

          transactionsFilter.value = {
            CATEGORIES: {
              categories: [selectedCategories.account],
              label: `Catégories : ${selectedCategories.name}`,
            },
          };
        } else {
          viewAllSelected.value = true;
          transactionsFilter.value = undefined;
        }
      },
      { deep: true }
    );
    // Watching the props.transactions and if it changes it will set the transactionsFilter.value to
    // undefined and viewCategoriesSelected.value to an empty array.
    watch(
      () => props.transactions,
      () => {
        transactionsFilter.value = undefined;
        viewCategoriesSelected.value = undefined;
      },
      { deep: true }
    );

    return {
      balance,
      viewAllSelected,
      viewCategoriesSelected,
      viewData,
      viewSelectedText,
    };
  },
});
