

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CheckBoxCard",
  props: {
    cardImg: {
      type: String,
      required: true,
    },
    cardTextTitle: {
      type: String,
      required: true,
    },
    cardText: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    getPicture(value: string) {
      return require(`@/assets/agreement/${value}`);
    },
  },
  setup() {
    return {};
  },
});
