import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { RequireField } from "..";
import { Address, addressSchema, Email, IsoDate } from "./Common.model";
import { FirstName, LastName } from "./Person.model";

/**
 * `PartnerRole` — Roles for a partner
 */
export enum PartnerRole {
  GRANTEE = "grantee",
  OWNER = "owner",
}

export enum PartnerTypeEnum {
  NATURAL_PERSON = "natural_person",
  LEGAL_PERSON = "legal_person", // Personne morale
}

/**
 * `Partner` — An associate
 */
export type Partner = {
  id: string;
  userId: string;
  addedById: string;
  productId: string;
  legalStatus?: string;
  denomination?: string;
  siret?: string;
  lastName: LastName;
  firstName?: FirstName;
  birthDate?: IsoDate;
  email?: Email;
  address?: Address;
  role: PartnerRole;
  partsPP: number;
  partsUF: number;
  partsNP: number;
  pctDetention: number;
  partsSocial?: number;
  type: PartnerTypeEnum;
  status: string; // Computed in repository, not store in database
  createdAt: string;
  updatedAt: string;
};
export type NewPartner = RequireField<
  Partial<Omit<Partner, "id" | "addedById" | "status" | "createdAt" | "updatedAt">>,
  "productId" | "lastName"
>;
export type NewPartnerInternal = RequireField<
  Partial<Omit<Partner, "id" | "status" | "createdAt" | "updatedAt">>,
  "productId" | "lastName"
>;
export type PartnerUpdate = RequireField<
  Partial<Omit<Partner, "productId" | "addedById" | "status" | "createdAt" | "updatedAt">>,
  "id"
>;
export type PartnerUpdateInternal = RequireField<
  Partial<Omit<Partner, "addedById" | "status" | "createdAt" | "updatedAt">>,
  "id"
>;

const partnerSchema = new Schema<PartnerDocument>(
  {
    _id: { type: String, default: () => ulid() },
    userId: { type: String, index: true },
    addedById: { type: String, index: true },
    productId: String,
    legalStatus: String,
    denomination: String,
    siret: String,
    lastName: String, //{ type: String, required: true, maxlength: 200 },
    firstName: String, //{ type: String, required: true, maxlength: 200 },
    birthDate: String,
    email: { type: String },
    address: {
      type: addressSchema,
    },
    role: {
      type: String,
      enum: Object.values(PartnerRole),
    },
    partsPP: { type: Number, default: 0 },
    partsUF: { type: Number, default: 0 },
    partsNP: { type: Number, default: 0 },
    pctDetention: { type: Number, default: undefined },
    partsSocial: { type: Number, default: undefined },
    type: {
      type: String,
      enum: Object.values(PartnerTypeEnum), // Mongoose doesn't support Typescript ENUM
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: PartnerDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

partnerSchema.index(
  {
    productId: 1,
    email: 1,
  },
  {
    unique: true,
    partialFilterExpression: { email: { $type: "string" } },
  }
);

export type PartnerDocument = Partner & Document<string>;

// Name of the collection in third argument
export const PartnerModel = model<PartnerDocument>("Partner", partnerSchema, "Partners");

// API
export namespace PartnersService {
  export type CreateIn = NewPartner;
  export type CreateOut = Partner;

  export type ListIn = Partial<Pick<Partner, "productId">>;
  export type ListOut = Partner[];

  export type GetIn = Pick<Partner, "id">;
  export type GetOut = Partner;

  export type UpdateIn = PartnerUpdate;
  export type UpdateOut = Partner;

  export type InvitePartnerIn = Pick<Partner, "id">;
  export type InvitePartnerOut = void;

  export type InvitePartnersIn = { ids: string[] };
  export type InvitePartnersOut = void;
}
