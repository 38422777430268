var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accountingCarryForwardTable"}},[_c('v-form',{ref:"form"},[_c('EditableTable',{ref:"editableTable",attrs:{"headers":_vm.headers,"items":_vm.items,"itemsPerPage":-1,"viewType":_vm.isEditable ? 'editable' : 'readOnly',"disableSort":true,"hideAddButton":true,"isEnableDeleteItem":false,"hideFooter":""},on:{"validateItem":_vm.validateItem,"cancelEditItem":_vm.cancelEditItem},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,index){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
var rowIndex = ref.rowIndex;
var isEditing = ref.isEditing;
return [(header.value === 'type')?_c('div',{key:("header-" + index),style:([
              _vm.AccountingResultLineType.DEPRECIATION_AND_AMORTISATION,
              _vm.AccountingResultLineType.DEDUCTIBLE_DEPRECIATION,
              _vm.AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION ].includes(item.type)
              ? 'padding-left: 30px;'
              : '')},[_vm._v(" "+_vm._s(_vm.getLineTypeLabel(item.type))+" "),(
              item.type ===
              _vm.AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.onInformationIconClick(item.type)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Comprend la réintégration fiscale des amendes et pénalités.")])]):_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onInformationIconClick(item.type)}}},[_vm._v(" mdi-information-outline ")])],1):(header.value === 'recovery')?_c('div',{key:("header-" + index)},[(!isEditing)?[_vm._v(" "+_vm._s([ _vm.AccountingCarryForwardLineType.TOTAL_DEPRECIATION, _vm.AccountingCarryForwardLineType.TOTAL_DEFICIT ].includes(item.type) ? item.recoveryAmount + "€" : "-")+" ")]:[_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","placeholder":item.recoveryAmount === '' ? 'Renseignez un montant' : '',"hide-details":"auto","required":"","suffix":"€"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateItem({
                  rowIndex: rowIndex,
                })}},model:{value:(item.recoveryAmount),callback:function ($$v) {_vm.$set(item, "recoveryAmount", $$v)},expression:"item.recoveryAmount"}})]],2):_c('div',{key:("header-" + index)},[_vm._v(" "+_vm._s(item.amounts[index - (_vm.hasRecovery ? 2 : 1)] + "€")+" ")])]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }