var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"container",attrs:{"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('div',{staticClass:"header"},[_c('h2',{staticClass:"header-title"},[_vm._v("Adresse email de facturation")]),(!_vm.isEditing)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"header-action",attrs:{"id":"track-modify_email_sci","color":"primary","icon":""},on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,638183254)},[_c('span',[_vm._v("Modifier")])]):_vm._e()],1),(_vm.product)?_c('v-card-text',{staticClass:"section"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"section-body"},[_c('p',{staticClass:"d-flex align-center",attrs:{"id":"mail"}},[_vm._v(" Adresse email de "+_vm._s(_vm.headerTitleText)+" : "),(_vm.product.email)?[_vm._v(_vm._s(_vm.product.email))]:[_c('span',{staticClass:"ml-1",style:({ fontWeight: 'bold' })},[_vm._v(" Aucune adresse enregistrée ")])],_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.product.emailStatus === 'confirmed')?_c('span',[_c('v-icon',{staticClass:"ml-5",attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-check ")])],1):_vm._e()])]}}],null,false,3543876110)},[_vm._v(" "+_vm._s(_vm.$t(("email.status." + (_vm.product.emailStatus))))+" ")])],2),(_vm.product.newEmail || _vm.isEditing)?_c('v-text-field',{attrs:{"id":"newEmail","disabled":!_vm.isEditing,"rules":[
            function () { return !!_vm.product.newEmail || 'Le champ ne peut être vide'; },
            function () { return (!!_vm.product.newEmail && _vm.validEmailRule(_vm.product.newEmail)) ||
              _vm.getEmailRule(); } ],"label":"Nouvelle adresse email","name":"newEmail","outlined":"","persistent-hint":"","type":"text"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.product.emailStatus === 'invalid')?_c('span',[_c('v-icon',{staticClass:"ml-5",attrs:{"color":"red"}},[_vm._v("mdi-alert-outline")])],1):_vm._e(),(_vm.product.emailStatus === 'pending')?_c('span',[_c('v-icon',{staticClass:"ml-5",attrs:{"color":"blue"}},[_vm._v("mdi-sync")])],1):_vm._e()])]}}],null,false,4170998406)},[_vm._v(" "+_vm._s(_vm.$t(("email.status." + (_vm.product.emailStatus))))+" ")])]},proxy:true}],null,false,1094764177),model:{value:(_vm.product.newEmail),callback:function ($$v) {_vm.$set(_vm.product, "newEmail", $$v)},expression:"product.newEmail"}}):_vm._e()],1),_c('div',{staticClass:"section-action"},[(
            _vm.isEditing && (!_vm.actions || _vm.product.emailStatus === 'confirmed')
          )?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":"","outlined":""},on:{"click":_vm.cancelUpdate}},[_vm._v(" Annuler ")]):_vm._e(),(_vm.isEditing)?_c('v-btn',{attrs:{"id":"validate-email","color":"primary","depressed":"","type":"submit"}},[_vm._v(" Valider ")]):_vm._e()],1),(
          _vm.product.emailStatus === 'pending' ||
          _vm.product.emailStatus === 'invalid'
        )?_c('v-alert',{staticClass:"section-alert",attrs:{"color":"info","dark":"","outlined":"","text":"","transition":"scale-transition"}},[_c('div',{staticClass:"section-alert-body"},[(_vm.product.emailStatus === 'invalid')?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-outline")])],1):_vm._e(),(_vm.product.emailStatus === 'pending')?_c('span',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-sync")])],1):_vm._e(),_c('span',{staticClass:"ml-2",staticStyle:{"white-space":"pre-line"},attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t(("email.status-detail." + (_vm.product.emailStatus)), { msg: _vm.product.newEmail, })))])]),(
            _vm.product.emailStatus === 'pending' ||
            _vm.product.emailStatus === 'invalid'
          )?_c('div',{staticClass:"section-alert-action"},[_c('v-btn',{staticClass:"position-left",attrs:{"color":"blue","outlined":"","depressed":""},on:{"click":_vm.resetEmailStatus}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":"","left":""}},[_vm._v("mdi-mail")]),_vm._v(" Renvoyer l'email ")],1),(_vm.product.newEmail !== _vm.product.email)?_c('v-btn',{staticClass:"position-right",attrs:{"color":"blue","outlined":"","depressed":""},on:{"click":_vm.cancelEmailStatus}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","dense":""}},[_vm._v("mdi-cancel")]),_vm._v(" Annuler ")],1):_vm._e()],1):_vm._e()]):_vm._e()],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }