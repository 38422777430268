import { IR2072Deductions, IR2072NatureA, IR2072NatureB } from "@edmp/api";

export const TAX_DEDUCTIONS: { text: string; value: IR2072Deductions }[] = [
  { text: "Aucune", value: IR2072Deductions.NONE },
  { text: "Besson ancien", value: IR2072Deductions.BESSON_ANCIEN },
  { text: "Scellier ZRR", value: IR2072Deductions.SCELLIER_ZRR },
  {
    text: "Scellier intermédiaire",
    value: IR2072Deductions.SCELLIER_INTERMEDIAIRE,
  },
  {
    text: "Conventionnement ANAH (Borloo ancien) secteur intermédiaire",
    value: IR2072Deductions.BORLOO_ANCIEN_INTERMEDIAIRE,
  },
  {
    text: "Carrières et gisements minéraux productifs de revenus",
    value: IR2072Deductions.CARRIERES,
  },
  {
    text: "Conventionnement ANAH (Borloo ancien) secteur social ou très social",
    value: IR2072Deductions.BORLOO_ANCIEN_SOCIAL,
  },
  {
    text: "Conventionnement ANAH (Borloo ancien) secteur social/très social conclues à compter du 28 mars 2009 ",
    value: IR2072Deductions.BORLOO_ANCIEN_SOCIAL_2009,
  },
  {
    text: "Conventionnement ANAH (Borloo ancien) locations intermédiées, secteur intermédiaire, social ou très social (conventions conclues à compter du 28 mars 2009)",
    value: IR2072Deductions.BORLOO_ANCIEN_INTERMEDIAIRE_2009,
  },
  {
    text: "Conventionnement ANAH (Cosse) secteur intermédiaire en zone B2",
    value: IR2072Deductions.COSSE_INTERMEDIAIRE_B,
  },
  {
    text: "Conventionnement ANAH (Cosse) secteur intermédiaire » en zones A bis, A et B1 ",
    value: IR2072Deductions.COSSE_INTERMEDIAIRE_A,
  },
  {
    text: "Conventionnement ANAH (Cosse) secteur social/très social - zone B2 et zone C",
    value: IR2072Deductions.COSSE_SOCIAL_B,
  },
  {
    text: "Conventionnement ANAH (Cosse) secteur social ou très social - zones A bis, A et B1",
    value: IR2072Deductions.COSSE_SOCIAL_A,
  },
  {
    text: "Conventionnement ANAH (Cosse) secteur intermédiaire, social ou très social dans le cadre d’une intermédiation locative sociale",
    value: IR2072Deductions.COSSE_INTERMEDIATION,
  },
];

export const PROPERTY_TYPES: { text: string; value: IR2072NatureA }[] = [
  { text: "Immeuble Urbain", value: IR2072NatureA.IMMEUBLE_URBAIN },
  { text: "Immeuble Rural", value: IR2072NatureA.IMMEUBLE_RURAL },
  { text: "Immeuble de rapport", value: IR2072NatureA.IMMEUBLE_DE_RAPPORT },
  { text: "Appartement", value: IR2072NatureA.APPARTEMENT },
  { text: "Maison", value: IR2072NatureA.MAISON },
  { text: "Parking", value: IR2072NatureA.PARKING },
  { text: "Autre", value: IR2072NatureA.AUTRE },
];

export const PROPERTY_STATUS: { text: string; value: IR2072NatureB }[] = [
  { text: "Bâti", value: IR2072NatureB.BATI },
  { text: "Non Bâti", value: IR2072NatureB.NON_BATI },
];
