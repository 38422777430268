






























































import Confirm from "@/components/core/modals/Confirm.vue";
import { VForm } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { accountingCarryForwardsStore } from "@/store";
import { countDecimals } from "@/utils";
import {
  AccountingCarryForward,
  AccountingCarryForwardLineType,
  AccountingCarryForwardType,
  AccountingCarryForwardUpdate,
  Direction,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "AccountingCarryForwardAddModal",
  components: {
    Confirm,
  },
  setup(_, context) {
    function resetForm() {
      (context.refs.form as VForm).resetValidation();
    }
    const deferrableDeficitAmount: Ref<number | undefined> = ref(undefined);
    const accountingCarryForwards = computed(
      () => accountingCarryForwardsStore.accountingCarryForwards
    );
    const accountingCarryForwardRecovery: ComputedRef<
      AccountingCarryForward<AccountingCarryForwardType.RECOVERY> | undefined
    > = computed(() => {
      const accountingCarryForwardRecovery = accountingCarryForwards.value.find(
        (cf) => cf.type === AccountingCarryForwardType.RECOVERY
      );
      return accountingCarryForwardRecovery?.type ===
        AccountingCarryForwardType.RECOVERY
        ? accountingCarryForwardRecovery
        : undefined;
    });
    async function validate() {
      if (
        (context.refs.form as VForm).validate() &&
        accountingCarryForwardRecovery.value
      ) {
        const carryForwardToSave: AccountingCarryForwardUpdate = Object.assign(
          accountingCarryForwardRecovery.value,
          {
            lines: [
              ...cloneDeep(
                accountingCarryForwardRecovery.value.lines.filter(
                  (line) =>
                    line.type !== AccountingCarryForwardLineType.TOTAL_DEFICIT
                )
              ),
              {
                type: AccountingCarryForwardLineType.TOTAL_DEFICIT,
                amount: Number(
                  Number(deferrableDeficitAmount.value).toFixed(0)
                ),
                direction: Direction.debit,
              },
            ],
          }
        );
        await accountingCarryForwardsStore.updateAccountingCarryForward(
          carryForwardToSave
        );
        resetForm();
        router.push({ query: {} });
        context.emit("finish");
      }
    }

    async function cancel(): Promise<void> {
      resetForm();
      router.push({ name: ROUTE_NAMES.EventsYearEnd });
    }

    onBeforeMount(async () => {
      await accountingCarryForwardsStore.fetchAccountingCarryForwards();
    });

    return {
      validate,
      cancel,
      countDecimals,
      deferrableDeficitAmount,
    };
  },
});
