import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { ErrorInterface } from "./ErrorInterface";

export type EventMetadata<EventName extends string = string> = {
  eventName: EventName;
  sub: string; // Unique id of User
  date: string;
  hostname: string;
  env: string;
  pid: number;
};

export type Log = EventMetadata & {
  data: {
    error?: ErrorInterface;
    [key: string]: unknown;
  };
};

export type ElasticLog = EventMetadata & {
  data: {
    error?: ErrorInterface;
    row: string;
  };
};

const logSchema = new Schema<LogDocument>(
  {
    _id: { type: String, default: () => ulid() },
    eventName: { type: String, index: true, required: true },
    timestamp: { type: Date },
    data: {
      type: Object,
      required: true,
    },
  },
  {
    timestamps: { createdAt: true, updatedAt: false }, // Never Updated
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret, options) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
    strict: false,
  }
);

export type LogDocument = Log & Document<string>;

export const LogModel = model<LogDocument>("Log", logSchema, "Logs");
