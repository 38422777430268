import { render, staticRenderFns } from "./MyBusinessGeneralInfo.vue?vue&type=template&id=40b057a0&scoped=true&"
import script from "./MyBusinessGeneralInfo.vue?vue&type=script&lang=ts&"
export * from "./MyBusinessGeneralInfo.vue?vue&type=script&lang=ts&"
import style0 from "./MyBusinessGeneralInfo.vue?vue&type=style&index=0&id=40b057a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b057a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VForm,VIcon,VProgressLinear,VTextField,VTooltip})
