






























































































import { defineComponent, ref, Ref, onMounted } from "@vue/composition-api";
import { VForm } from "@/models";
import { AccountingPeriod, DocumentCreate, DocumentTag } from "@edmp/api";
import {
  accountingPeriodsStore,
  documentsStore,
  productsStore,
  realEstateAssetsStore,
} from "@/store";
import {
  DisplayAccountingPeriod,
  useAccountingPeriod,
} from "../accounting-period/AccountingPeriod.usable";
import { coreStore } from "@/store";

export default defineComponent({
  name: "AddDocumentsModal",
  setup(_, context) {
    const file: Ref<File | null> = ref(null);
    const accPeriodList: Ref<DisplayAccountingPeriod[]> = ref([]);
    const accPeriodSelected: Ref<AccountingPeriod> = ref(
      {} as AccountingPeriod
    );
    const tagSelected: Ref<{ text: string; values: DocumentTag[] }> = ref({
      text: "",
      values: [],
    });

    const realEstateList = ref(
      realEstateAssetsStore.realEstateAssets.map((e) => ({
        name: e.name,
        id: e.id,
      }))
    );
    const realEstateSelected: Ref<{ id: string; name: string }> = ref({
      id: "",
      name: "",
    });

    const { getAccountingPeriodList } = useAccountingPeriod();

    const TAGS_LIST = [
      {
        text: accountingPeriodsStore.isLMNP
          ? "Mon activité LMNP"
          : "Ma SCI et ses associés",
        values: ["product"],
      },
      { text: "Mes biens", values: ["real-estate-asset"] },
      { text: "Mes loyers", values: ["receipt", "rental-agreement"] },
      { text: "Ma comptabilité", values: ["accounting"] },
      { text: "Mes déclarations fiscales", values: ["tax"] },
      { text: "Mes événements", values: ["event"] },
    ];

    const validate = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        const infos: Ref<DocumentCreate> = ref({
          tags: [...tagSelected.value.values],
          product: {
            id: productsStore.currentId,
            accountingPeriod: { id: accPeriodSelected.value.id },
            realEstateAsset: {
              id: realEstateSelected.value.id,
            },
          },
        });
        try {
          if (file.value && productsStore.currentId)
            await documentsStore.createDocument({
              document: infos.value,
              file: file.value,
            });
          coreStore.displayFeedback({
            message: "Votre document a bien été enregistré sur Ownily.",
          });
          context.emit("documentAdded");
          context.emit("close");
        } catch (err) {
          console.error(err);
        }
      }
    };

    onMounted(async () => {
      accPeriodList.value = await getAccountingPeriodList();
    });

    return {
      file,
      TAGS_LIST,
      tagSelected,
      accPeriodList,
      accPeriodSelected,
      realEstateList,
      realEstateSelected,
      validate,
    };
  },
});
