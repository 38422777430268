







































































import DatePicker from "@/components/atom/DatePicker.vue";
import Tag from "@/components/atom/Tag.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import Next from "@/components/core/modals/Next.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { operationsService } from "@/services";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  productsStore,
  coreStore,
  realEstateAmortisationsStore,
  taskActivityStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { countDecimals, FilterKeys } from "@/utils";
import {
  AccountingBalanceSheet,
  AccountingPeriod,
  CategorizationEntry,
  ProductsModel,
  getMoment,
  getReferredIdByTypeWithReferences,
  isAmortisationAccount,
  LedgerAccountEnum,
  TaskActivityTypeReference,
  TaskCode,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "@vue/composition-api";
import { flatMap } from "lodash";
import { useFixedAssetAmortisation } from "../../fixedAssetAmortisation/fixedAssetAmortisation.usable";
import { useRealEstateAmortisation } from "../../realEstateAmortisation/realEstateAmortisation.usable";
import Transaction from "../transaction/Transaction.vue";

export default defineComponent({
  name: "TransactionAmortization",
  components: {
    Transaction,
    Next,
    Confirm,
    DatePicker,
    Tag,
  },
  setup(_, context) {
    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );
    const accountingPeriod: ComputedRef<AccountingPeriod> = computed(
      () => accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod
    );
    const accountingBalanceSheet: ComputedRef<
      AccountingBalanceSheet | undefined
    > = computed(
      () =>
        accountingBalanceSheetsStore.getFirstAccountingBalanceSheetByRecovery
    );
    const recoveryLinesAmortizations = computed(() => {
      if (!accountingBalanceSheet.value) {
        return [];
      }

      const amortizationLines = accountingBalanceSheet.value.lines.filter(
        (line) => isAmortisationAccount(line.account)
      );
      return amortizationLines;
    });
    const sumRecoveryAmortizationsValue = computed(() => {
      let sum = 0;
      for (const line of recoveryLinesAmortizations.value) {
        sum += line.amount;
      }
      return sum;
    });
    const usableAmortizationRealEstateAsset = useRealEstateAmortisation();

    const usableAmortizationFixedAsset = useFixedAssetAmortisation();

    const sumTheoreticalAmortizationsValue = computed(() =>
      (
        parseInt(
          usableAmortizationRealEstateAsset.totalAmortizedRealEstates.value
        ) +
        parseInt(usableAmortizationFixedAsset.totalAmortizedFixedAssets.value)
      ).toString()
    );

    const transactionAmount = computed(() => {
      return (
        parseInt(sumTheoreticalAmortizationsValue.value) -
        sumRecoveryAmortizationsValue.value
      );
    });
    const transactionAmountDisplay = computed(() => {
      return transactionAmount.value;
    });

    const helperDisplay = computed(() => {
      return transactionAmount.value > 0 ? "augmenter" : "réduire";
    });

    const transactionSummary: Ref<string> = ref("Reprise des amortissements");
    const transactionId: Ref<string> = ref("");
    const currency: Ref<string | undefined> = ref();
    const accountName = computed(() => {
      if (transactionAmount.value > 0) {
        return "Dotations d'amortissements Corporelles";
      } else {
        return "Reprise sur amortissements déjà constatés";
      }
    });
    const transactions = computed(() => {
      return flatMap(transactionsStore.transactions);
    });
    const transactionRecovery = computed(() => {
      if (transactions.value) {
        const transactionRecovery = transactions.value.find((transaction) => {
          if (
            transaction.operations?.journalEntry.lines &&
            transaction.operations.accountingPeriodId ===
              accountingPeriodsStore.currentId &&
            transaction.summary === transactionSummary.value
          ) {
            const line = transaction.operations?.journalEntry.lines.find(
              (line) => {
                if (line.account === LedgerAccountEnum.N281310) {
                  return line;
                }
              }
            );
            if (line) {
              return transaction;
            }
          }
        });
        return transactionRecovery;
      }
    });

    const redirectToAmortizationCheck = () => {
      router.push({ name: ROUTE_NAMES.Amortisations });
    };

    const goToTransactions = () => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({
        code: FilterKeys.CATEGORIES,
        filterParams: {
          label: "Reprise des amortissements",
          categories: [
            ...(transactionAmount.value > 0
              ? [LedgerAccountEnum.N681120]
              : [LedgerAccountEnum.N781100]),
          ],
        },
      });
      router.push({
        name: "Transactions",
      });
    };

    const isLoadingValidate = ref(false);
    const validate = async function () {
      if (!transactionRecovery.value) {
        try {
          isLoadingValidate.value = true;
          const newTransaction = await transactionsStore.createTransaction({
            productId: product.value.id,
            date: getMoment(accountingPeriod.value.endAt).format("YYYY-MM-DD"),
            amount: 0,
            summary: transactionSummary.value,
            type: "AMORTIZATION",
          });

          const isPositive = transactionAmount.value > 0;
          const lines: CategorizationEntry[] = [
            {
              account: LedgerAccountEnum.N281310,
              amount: transactionAmount.value,
            },
            {
              account: isPositive
                ? LedgerAccountEnum.N681120
                : LedgerAccountEnum.N781100,
              amount: isPositive
                ? -transactionAmount.value
                : Math.abs(transactionAmount.value),
            },
          ];

          await operationsService.create({
            productId: product.value.id,
            accountingPeriodId: accountingPeriodsStore.currentId,
            transactionId: newTransaction.id,
            entries: lines,
          });
          transactionsStore.fetchTransactions({
            productId: product.value.id,
            accountingPeriodId: accountingPeriodsStore.currentId,
          });

          const amortizationTaskActivity =
            taskActivityStore.notCompletedTaskActivities.find(
              (activity) =>
                getReferredIdByTypeWithReferences(
                  activity.references,
                  TaskActivityTypeReference.accountingPeriod
                ) === accountingPeriodsStore.currentId &&
                activity.code ===
                  TaskCode.AmortizationsResumptionPostAmortizationsUpdate
            );
          if (amortizationTaskActivity) {
            await taskActivityStore.validateTaskActivity({
              taskActivityLocal: amortizationTaskActivity,
            });
          }

          goToTransactions();
          context.emit("finish");
        } catch (err) {
          console.error(err);
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Un problème est survenu lors de l'enregistrement de la reprise des amortissements.",
          });
          return;
        } finally {
          isLoadingValidate.value = false;
        }
      } else {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message: "Une reprise des amortissements est déjà existante.",
        });
      }
    };

    async function cancel(): Promise<void> {
      context.root.$router.push({ name: ROUTE_NAMES.EventsYearEnd });
      context.emit("finish");
    }

    onBeforeMount(async () => {
      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      await realEstateAmortisationsStore.fetchRealEstateAmortisations(
        productsStore.currentId
      );
    });
    return {
      redirectToAmortizationCheck,
      validate,
      isLoadingValidate,

      cancel,
      transactionAmount,
      transactionAmountDisplay,
      transactionSummary,
      transaction: computed(
        () => transactionsStore.transactions[transactionId.value]
      ),
      accountingPeriod,

      countDecimals,
      currency,
      sumRecoveryAmortizationsValue,
      sumTheoreticalAmortizationsValue,
      accountName,
      helperDisplay,
    };
  },
});
