






























































































import { FixedAsset, RealEstateAsset } from "@edmp/api";
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  Ref,
} from "@vue/composition-api";

import FixedAssets from "../fixedAssets/FixedAssets.vue";
import FixedAssetForm from "../fixedAssets/FixedAssetForm.vue";
import FixedAssetAmortisationTable from "../fixedAssetAmortisation/FixedAssetAmortisationTable.vue";
import { fixedAssetsStore } from "@/store";

export default defineComponent({
  name: "RealEstateFixedAssetLMNP",
  components: {
    FixedAssets,
    FixedAssetForm,
    FixedAssetAmortisationTable,
  },
  props: {
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    isFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const fixedAssetList = computed(() => {
      return fixedAssetsStore.getFixedAssetsByRealEstateAssetId(
        props.realEstateAsset.id
      );
    });

    // Function
    const scrollToElement = (elementId) =>
      nextTick(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });

    // Fixed Asset
    const openFixedAssetForm: Ref<boolean> = ref(
      props.isFlow || fixedAssetList.value.length === 0
    );
    const selectedFixedAsset: Ref<Partial<FixedAsset> | undefined> =
      ref(undefined);
    const selectFixedAsset = (fixedAsset) => {
      selectedFixedAsset.value = fixedAsset;
      openFixedAssetForm.value = true;
      isEditableFixedAsset.value = true;
      scrollToElement("fixedAssetForm-component");
    };
    const closeFixedAssetForm = () => {
      openFixedAssetForm.value = false;
      selectedFixedAsset.value = undefined;
      context.emit("openFixedAsset", false);
    };
    const createAnotherFixedAsset = () => {
      selectedFixedAsset.value = undefined;
      openFixedAssetForm.value = true;
      isEditableFixedAsset.value = true;
      scrollToElement("fixedAssetForm-component");
    };

    const validateFixedAsset = (fixedAssetId: string) => {
      selectedFixedAsset.value = fixedAssetList.value.find(
        (fixedAsset) => fixedAsset.id === fixedAssetId
      );
      setEditingFixedAsset(false);
      scrollToElement("fixedAssetAmortisationTable-component");
    };
    const isEditableFixedAsset: Ref<boolean> = ref(
      props.isFlow || fixedAssetList.value.length === 0
    );
    const setEditingFixedAsset = (val: boolean) => {
      isEditableFixedAsset.value = val;
    };

    return {
      fixedAssetList,
      openFixedAssetForm,
      selectedFixedAsset,
      isEditableFixedAsset,
      selectFixedAsset,
      validateFixedAsset,
      closeFixedAssetForm,
      createAnotherFixedAsset,
    };
  },
});
