import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=452a1137&scoped=true&"
import script from "./Login.vue?vue&type=script&lang=ts&"
export * from "./Login.vue?vue&type=script&lang=ts&"
import style0 from "./Login.vue?vue&type=style&index=0&id=452a1137&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452a1137",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VBtn,VContainer,VMain})
