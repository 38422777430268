

























































































































import { defineComponent, ref, Ref, computed } from "@vue/composition-api";
import Decimal from "decimal.js-light";

import { VForm } from "@/models";
import { FixedAssetType, getMoment, getFixedAssetTypeTexts } from "@edmp/api";
import {
  productsStore,
  fixedAssetsStore,
  realEstateAssetsStore,
} from "@/store";
import { coreStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

import DatePicker from "@/components/atom/DatePicker.vue";

export default defineComponent({
  name: "FixedAssetCreateModal",
  components: {
    DatePicker,
  },
  props: {
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, context) {
    const name: Ref<string> = ref("");
    const realEstateAssetIds: Ref<string[]> = ref([]);
    const boughtPrice: Ref<number> = ref(0);
    const commissioningAt: Ref<string | undefined> = ref("");
    const boughtAt: Ref<string | undefined> = ref("");
    const today = computed(() => getMoment().format("YYYY-MM-DD"));
    const fixedAssetTypeText: {
      text: string;
      value: FixedAssetType;
    }[] = getFixedAssetTypeTexts();
    const realEstateAssets = computed(() =>
      realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => {
        const temp = {
          text: `${realEstateAsset.name}`,
          value: realEstateAsset.id,
          disabled: false,
          alert: false,
        };

        return temp;
      })
    );

    const validate = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        try {
          if (!productsStore.currentId) {
            throw new Error("Pas de SCI sélectionnée");
          }

          if (
            name.value &&
            realEstateAssetIds.value &&
            boughtPrice.value &&
            commissioningAt.value &&
            boughtAt.value
          ) {
            const newFixedAsset = await fixedAssetsStore.createFixedAsset({
              name: name.value,
              productId: productsStore.currentId,
              realEstateAssetIds: realEstateAssetIds.value,
              commissioningAt: commissioningAt.value,
              boughtAt: boughtAt.value,
              boughtPrice: boughtPrice.value,
              type: fixedAssetTypeText[0].value,
            });

            coreStore.displayFeedback({
              message: "Votre immobilisation a bien été enregistré sur Ownily.",
            });
            context.emit("fixedAssetCreated", newFixedAsset.id);
            context.emit("close");
          }
        } catch (err) {
          console.error(err);
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Un problème est survenu lors de l'ajout de l'immobilisation.",
          });
        }
      }
    };

    const amountValidate = (amount: number) => {
      return amount.toString().length <= new Decimal(amount).toFixed(2).length;
    };

    return {
      realEstateAssets,
      amountValidate,
      today,
      name,
      realEstateAssetIds,
      boughtPrice,
      commissioningAt,
      boughtAt,
      validate,
    };
  },
});
