





































































import { defineComponent } from "@vue/composition-api";
import {
  subscriptionPlans,
  subscriptionsFunctionalities,
} from "./Subscriptions.const";

export default defineComponent({
  name: "SubscriptionsTable",
  components: {},
  setup() {
    return {
      subscriptionPlans,
      subscriptionsFunctionalities,
    };
  },
});
