import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { realEstatesService } from "@/services";
import {
  RealEstateAmortisation,
  RealEstateAmortisationsService,
} from "@edmp/api";
import Vue from "vue";

export interface RealEstateAmortisationsState {
  realEstateAmortisations: Array<RealEstateAmortisation>;
  loading: boolean;
}

@Module({
  name: "real-estate-amortisations-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RealEstateAmortisationsStore
  extends VuexModule
  implements RealEstateAmortisationsState
{
  realEstateAmortisations: RealEstateAmortisation[] = [];
  loading = false;

  @Mutation
  reset(): void {
    this.realEstateAmortisations = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setRealEstateAmortisation(
    realEstateAmortisation: RealEstateAmortisation
  ): void {
    const index = this.realEstateAmortisations.findIndex(
      ({ id }) => id == realEstateAmortisation.id
    );
    if (index !== -1) {
      // Tips :  Vue cannot detect when you directly set a non-root state property with the index. For keeping reactivity use set() method
      Vue.set(this.realEstateAmortisations, index, realEstateAmortisation);
    } else {
      this.realEstateAmortisations.push(realEstateAmortisation);
    }
  }

  @Mutation
  setRealEstateAmortisations(
    realEstateAmortisations: RealEstateAmortisation[]
  ): void {
    this.realEstateAmortisations = realEstateAmortisations;
  }

  get getRealEstateAmortisationsByRealEstateAssetId() {
    return (realEstateAmortisationId: string | null) => {
      if (!realEstateAmortisationId) return [];
      return this.realEstateAmortisations.filter(
        (realEstateAmortisation) =>
          realEstateAmortisation.realEstateAssetId === realEstateAmortisationId
      );
    };
  }

  @Action
  async createRealEstateAmortisation(
    realEstateAmortisationCreate: RealEstateAmortisationsService.CreateIn
  ) {
    this.setLoading(true);
    const realEstateAmortisationCreated =
      await realEstatesService.amortisations.create(
        realEstateAmortisationCreate
      );
    this.setRealEstateAmortisation(realEstateAmortisationCreated);
    this.setLoading(false);
    return realEstateAmortisationCreated;
  }

  @Action
  async fetchRealEstateAmortisation(
    params: RealEstateAmortisationsService.GetIn
  ) {
    this.setLoading(true);
    const realEstateAmortisation = await realEstatesService.amortisations.get(
      params
    );
    this.setRealEstateAmortisation(realEstateAmortisation);
    this.setLoading(false);
    return realEstateAmortisation;
  }

  @Action
  async fetchRealEstateAmortisations(productId: string) {
    this.setLoading(true);
    const realEstateAmortisations = await realEstatesService.amortisations.list(
      { productId }
    );
    this.setRealEstateAmortisations(realEstateAmortisations);
    this.setLoading(false);
    return realEstateAmortisations;
  }

  get getRealEstateAmortisation() {
    return (realEstateAmortisationId?: string | null) =>
      this.realEstateAmortisations.find(
        ({ id }) => id === realEstateAmortisationId
      );
  }

  @Action
  async updateRealEstateAmortisation(
    realEstateAmortisationUpdate: RealEstateAmortisationsService.UpdateIn
  ) {
    this.setLoading(true);
    const realEstateAmortisationUpdated =
      await realEstatesService.amortisations.update(
        realEstateAmortisationUpdate
      );
    this.setRealEstateAmortisation(realEstateAmortisationUpdated);
    this.setLoading(false);
    return realEstateAmortisationUpdated;
  }

  @Mutation
  delRealEstateAmortisation(
    realEstateAmortisationId: RealEstateAmortisation["id"]
  ): void {
    const index = this.realEstateAmortisations.findIndex(
      ({ id }) => id == realEstateAmortisationId
    );
    if (index !== -1) {
      this.realEstateAmortisations.splice(index, 1);
    }
  }

  @Action
  async deleteRealEstateAmortisation(
    params: RealEstateAmortisationsService.DeleteIn
  ) {
    this.setLoading(true);
    const realEstateAmortisationDeleted =
      await realEstatesService.amortisations.remove(params);
    if (realEstateAmortisationDeleted) {
      this.delRealEstateAmortisation(params.id);
    }
    this.setLoading(false);
    return realEstateAmortisationDeleted;
  }
}
