var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-card-attachment mt-4 mt-sm-0 mx-md-6"},[(_vm.attachements)?_c('div',_vm._l((_vm.attachements),function(attach){return _c('div',{key:attach.id,staticClass:"transaction-attachment-wrapper"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"transaction-attachment mr-1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(attach.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(attach.name))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"href":attach.href,"target":attach.id,"id":"track-see_file","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-paperclip")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir la pièce-jointe")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"track-delete_file","disabled":_vm.readonly,"icon":"","x-small":""},on:{"click":function($event){return _vm.deleteAttachment(attach.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer la pièce-jointe")])])],1)}),0):_vm._e(),(_vm.isIR)?_c('div',{staticClass:"transaction-card-attachment-add"},[_c('v-btn',{staticClass:"transaction-card-attachment-btn mx-2",attrs:{"disabled":_vm.readonly,"id":"track-add_file","color":"#0051B6","small":"","text":""},on:{"click":function($event){_vm.showAttachmentsModal = true}}},[_vm._v(" Ajouter des pièces jointes ")])],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"750px","scrollable":""},model:{value:(_vm.showAttachmentsModal),callback:function ($$v) {_vm.showAttachmentsModal=$$v},expression:"showAttachmentsModal"}},[_c('TransactionAttachmentCreate',{attrs:{"transaction":_vm.transactionState.transaction},on:{"add":function($event){return _vm.transactionUse.refreshTransaction()},"finish":function($event){_vm.showAttachmentsModal = false}}})],1),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }