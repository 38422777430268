










































































import { productsStore } from "@/store";
import { ProductsModel } from "@edmp/api";
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  watch,
  Ref,
} from "@vue/composition-api";

export default defineComponent({
  name: "DedicatedBankAccount",
  components: {},
  props: {},
  setup(props, context) {
    const product = computed(
      () =>
        productsStore.currentProduct as ProductsModel.Product<ProductsModel.ProductTypes.LMNP>
    );

    const isDedicatedBankAccount: Ref<boolean | undefined> = ref();

    function updateDedicatedBankAccount(boolean: boolean | undefined) {
      const data = {
        ...product.value,
        id: productsStore.currentId,
        dedicatedBankAccount: boolean,
      };
      productsStore.updateProduct(data);

      isDedicatedBankAccount.value = boolean;
      context.emit("update:isDedicatedBankAccount", boolean);
    }

    const init = () => {
      if (product.value?.dedicatedBankAccount !== undefined) {
        isDedicatedBankAccount.value = product.value?.dedicatedBankAccount;
        if (
          product.value?.status === "completed" ||
          isDedicatedBankAccount.value !== undefined
        ) {
          context.emit(
            "update:isDedicatedBankAccount",
            isDedicatedBankAccount.value
          );
        }
      }
    };

    watch(
      () => [product],
      () => {
        init();
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      await init();
    });

    return {
      isDedicatedBankAccount,
      productStatus: product.value?.status,
      updateDedicatedBankAccount,
    };
  },
});
