import { computed, Ref, ref } from "@vue/composition-api";
import {
  RentalUnit,
  RentalState,
  RentalUsage,
  TaxRateTva,
  AnomalyCode,
  AnomalyOptions,
  TypeReference,
  RealEstateAsset,
} from "@edmp/api";
import { realEstateAssetsStore } from "@/store";

export function useRentalUnit() {
  const rentalUnit: Ref<Partial<RentalUnit>> = ref({});

  const rentalStateList: { text: string; value: RentalState }[] = [
    { text: "Non mis en location", value: RentalState.NOT_RENTAL },
    { text: "Location nue", value: RentalState.RENTAL },
    { text: "Location meublée", value: RentalState.FURNISHED_RENTAL },
  ];
  const rentalUsageList: { text: string; value: RentalUsage }[] = [
    { text: "Habitation", value: RentalUsage.RESIDENTIAL },
    { text: "Professionnel", value: RentalUsage.PROFESSIONAL },
    { text: "Agricole", value: RentalUsage.AGRICULTURAL },
    { text: "Parking", value: RentalUsage.PARKING },
  ];

  const rentalUsageLmnpList: { text: string; value: RentalUsage }[] = [
    { text: "Chambre d’hôtes/tourisme", value: RentalUsage.RESIDENTIAL },
    { text: "Habitation longue durée", value: RentalUsage.GUEST_HOUSE },
  ];

  const taxTvaRule = computed(() => {
    let taxTvaRule: {
      ruleResult: true | string;
      type: "optional" | "required";
    } = { ruleResult: true, type: "optional" };
    if (rentalUnit.value.rentalState === RentalState.NOT_RENTAL) {
      taxTvaRule = {
        ruleResult:
          "Vous ne pouvez pas appliquer la TVA sur ce type de location",
        type: "required",
      };

      rentalUnit.value.taxTvaEnable = false;
    } else {
      if (rentalUnit.value.rentalUsage === RentalUsage.RESIDENTIAL) {
        if (rentalUnit.value.rentalState === RentalState.FURNISHED_RENTAL) {
          taxTvaRule = { ruleResult: true, type: "optional" };
        }
        if (rentalUnit.value.rentalState === RentalState.RENTAL) {
          taxTvaRule = {
            ruleResult:
              "Vous ne pouvez pas appliquer la TVA sur ce type d'usage et de location",
            type: "required",
          };
          rentalUnit.value.taxTvaEnable = false;
        }
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.PROFESSIONAL) {
        if (rentalUnit.value.rentalState === RentalState.FURNISHED_RENTAL) {
          taxTvaRule = {
            ruleResult:
              "L'application de la TVA est obligatoire sur ce type d'usage et de location",
            type: "required",
          };

          rentalUnit.value.taxTvaEnable = true;
        }
        if (rentalUnit.value.rentalState === RentalState.RENTAL) {
          taxTvaRule = { ruleResult: true, type: "optional" };
        }
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.PARKING) {
        taxTvaRule = { ruleResult: true, type: "optional" };
      }
      if (rentalUnit.value.rentalUsage === RentalUsage.AGRICULTURAL) {
        taxTvaRule = { ruleResult: true, type: "optional" };
      }
    }
    return taxTvaRule;
  });

  const anomalyOptions = computed(() => {
    const anomalyOptions: AnomalyOptions = {
      [AnomalyCode.taxTva]: {},
      [AnomalyCode.referenceType]: {},
      [AnomalyCode.objectIdLink]: {},
    };
    if (rentalUnit.value?.id) {
      anomalyOptions[AnomalyCode.taxTva] = {
        referenceId: rentalUnit.value.id,
        params: {
          taxTvaEnable: rentalUnit.value.taxTvaEnable || false,
        },
      };
      anomalyOptions[AnomalyCode.referenceType] = {
        referenceId: rentalUnit.value.id,
        params: {
          rentalUnit: {
            deleted: rentalUnit.value.deleted || false,
          },
        },
      };
      anomalyOptions[AnomalyCode.objectIdLink] = {
        referenceId: rentalUnit.value.id,
        params: {
          rentalUnitDeleted: rentalUnit.value.deleted || false,
        },
      };
    }
    return anomalyOptions;
  });

  const initRentalUnit = async ({
    realEstateAsset,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
  }) => {
    if (realEstateAsset.id) {
      rentalUnit.value = realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
        realEstateAsset.id
      );
    } else {
      rentalUnit.value = {
        name: "",
        productId: realEstateAsset.productId,
      };
    }
  };

  const prepareRentalUnitForCreation = ({
    realEstateAsset,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
  }) => {
    if (!rentalUnit.value.name) rentalUnit.value.name = realEstateAsset.name;

    return {
      ...(rentalUnit.value as RentalUnit),
      productId: realEstateAsset.productId as string,
      realEstateAssetId: realEstateAsset.id as string,
      taxRateTVA: rentalUnit.value.taxTvaEnable
        ? TaxRateTva.Normal_20
        : undefined,
    };
  };

  const updateOrCreateRentalUnit = async ({
    realEstateAsset,
  }: {
    realEstateAsset: Partial<RealEstateAsset>;
  }) => {
    if (rentalUnit.value) {
      try {
        if (rentalUnit.value.id) {
          rentalUnit.value = await realEstateAssetsStore.updateRentalUnit(
            prepareRentalUnitForCreation({ realEstateAsset })
          );
        } else {
          rentalUnit.value = await realEstateAssetsStore.createRentalUnit(
            prepareRentalUnitForCreation({ realEstateAsset })
          );
        }
      } catch (error) {
        console.error("Error[updateOrCreateRentalUnit]", error);
        throw error;
      }
    }
  };

  const initialEquipment = {
    NonHabitable: [
      {
        name: "Cave",
        value: "cave",
        selected: false,
      },
      {
        name: "Parking",
        value: "parking",
        selected: false,
      },
      {
        name: "Garage",
        value: "garage",
        selected: false,
      },
      {
        name: "Balcon",
        value: "balcon",
        selected: false,
      },
      {
        name: "Jardin",
        value: "jardin",
        selected: false,
      },
      {
        name: "Terrasse",
        value: "terrasse",
        selected: false,
      },
      {
        name: "Grenier",
        value: "grenier",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
    HousingEquipment: [
      {
        name: "Cuisine équipée",
        value: "cuisine",
        selected: false,
      },
      {
        name: "Installation sanitaire",
        value: "sanitaire",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
    TechEquipment: [
      {
        name: "TV",
        value: "tv",
        selected: false,
      },
      {
        name: "Internet",
        value: "internet",
        selected: false,
      },
      {
        name: "Fibre optique",
        value: "fibre",
        selected: false,
      },
    ],
    CommonEquipment: [
      {
        name: "WC sur le pallier ",
        value: "wc",
        selected: false,
      },
      {
        name: "Douche sur le pallier",
        value: "douche",
        selected: false,
      },
      {
        name: "Service de gardiennage",
        value: "jardin_service",
        selected: false,
      },
      {
        name: "Ascenseur",
        value: "ascenseur",
        selected: false,
      },
      {
        name: "Local à vélo",
        value: "velo",
        selected: false,
      },
      {
        name: "Autres",
        value: "autres",
        selected: false,
      },
    ],
  };

  return {
    // states
    rentalUnit,
    // Methods
    initRentalUnit,
    updateOrCreateRentalUnit,
    prepareRentalUnitForCreation,
    // Constants
    rentalStateList,
    rentalUsageList,
    rentalUsageLmnpList,
    taxTvaRule,
    TaxRateTva,
    TypeReference,
    anomalyOptions,
    initialEquipment,
  };
}
