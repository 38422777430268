






































































































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import {
  isDeletedTransaction,
  isUserTransaction,
  TransactionImportTypeUser,
  Transaction,
  TypeReference,
} from "@edmp/api";

import { VConfirmDialog } from "@/models";
import { coreStore, partnersStore, transactionsStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

import { TransactionState, useTransaction } from "./transaction.usable";

import Confirm from "@/components/core/modals/Confirm.vue";
import { operationsService } from "@/services";

export default defineComponent({
  name: "TransactionHeader",
  components: {
    Confirm,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    isOnAddManualTransaction: {
      type: Boolean,
      default: false,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    isDisableRemove: {
      default: false,
      type: Boolean,
    },
    isDisableUpdateSummary: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const transactionUse = computed(() =>
      useTransaction(props.transactionState, context)
    );

    // Transaction label
    const newSummary = ref("");
    const summary = ref("");
    const showCreationSummary: Ref<boolean> = ref(false);

    const clickDeleteTransaction = async () => {
      const transactionId = props.transactionState.transaction.id;
      if (
        isUserTransaction(props.transactionState.transaction) ||
        isDeletedTransaction(props.transactionState.transaction)
      ) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open("Êtes-vous sûr de vouloir supprimer cette transaction ?");
        if (openConfirm) {
          try {
            await transactionsStore.deleteTransaction({
              id: transactionId,
            });
            coreStore.displayFeedback({
              message: "La transaction a bien été supprimée",
            });
          } catch (error) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.ERROR,
              message: "Une erreur est survenue lors de la suppression",
            });
            console.error(error);
          }
        }
      }
    };

    const clickAddSummary = () => {
      showCreationSummary.value = !showCreationSummary.value;
      newSummary.value = summary.value;
    };

    const clickDuplicateTransaction = async () => {
      props.transactionState.isOnDuplicateTransaction = true;
    };

    const validateNewSummary = async () => {
      if (
        newSummary.value !== "" &&
        transactionUse.value.transactionState.transaction.operations
      ) {
        try {
          await operationsService.update({
            id: transactionUse.value.transactionState.transaction.operations.id,
            summary: newSummary.value,
          });
          summary.value = newSummary.value;
          await transactionUse.value.refreshTransaction();
          showCreationSummary.value = false;
          newSummary.value = "";
          coreStore.displayFeedback({
            message: "La mise à jour du libellé a bien été prise en compte",
          });
        } catch (error) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour du libellé",
          });
          console.error(error);
        }
      }
    };

    const isErasable = computed(
      () =>
        isUserTransaction(props.transactionState.transaction) ||
        isDeletedTransaction(props.transactionState.transaction) ||
        props.transactionState.isManualTransaction ||
        props.transactionState.isImportTransaction ||
        props.transactionState.isExpenseTransaction
    );

    const getChipTitle = (transaction: Transaction) => {
      if (transaction.type === TransactionImportTypeUser.EXPENSE_REPORT) {
        let id = transaction.operations?.journalEntry?.lines
          ?.find((line) =>
            line?.refs?.find((ref) => ref.type === TypeReference.partner)
          )
          ?.refs?.find((ref) => ref.type === TypeReference.partner)?.referredId;
        if (id) {
          return "Note de frais : " + partnersStore.getPartnerName(id);
        } else {
          return "Note de frais";
        }
      }
      // Not Yet
      // else {
      //   return bankAccountsStore.bankAccounts.find(
      //     (value) => value.id === transaction.bankAccountId
      //   )?.name;
      // }
    };

    onMounted(() => {
      summary.value = transactionUse.value.getSummary();
    });

    return {
      summary,
      showCreationSummary,
      isErasable,
      newSummary,
      clickAddSummary,
      clickDuplicateTransaction,
      validateNewSummary,
      clickDeleteTransaction,
      getChipTitle,
    };
  },
});
