
































































































































































































































































































































































































import { defineComponent, ref, PropType } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { format } from "date-fns";
import { RentalAgreement, Tenant, TenantTypeEnum } from "@edmp/api";
import { useRents } from "./rents.usable";
import { VForm } from "@/models";
import { tenantsStore } from "@/store";
import { onlyNumber } from "@/utils";
import { useEmail } from "@/composables";

export default defineComponent({
  name: "RealEstateRentsTenants",
  components: { DatePicker, YesNoSwitch, SectionTitle },
  props: {
    rentalAgreement: {
      type: Object as PropType<RentalAgreement>,
      required: true,
    },
    tenants: {
      type: Array as PropType<Tenant[]>,
      required: true,
    },
  },
  setup(props, context) {
    const { addTenant, tenantType, typeRentalAgreementItems } = useRents();

    const { validEmailRule } = useEmail();

    const rules = ref({
      name: () =>
        !!props.rentalAgreement.name ||
        "Renseignez un nom pour ce contrat de location",
      startAt: () =>
        !!props.rentalAgreement.startAt || "Ce champ est obligatoire",
      type: () =>
        !!props.rentalAgreement.type ||
        "Renseignez le type de contrat de location",
      tenantType: (tenant: Tenant) =>
        !!tenant.type || "Ce champ est obligatoire",
      tenantfirstName: (tenant: Tenant) =>
        !!tenant.firstName || "Ce champ est obligatoire",
      tenantlastName: (tenant: Tenant) =>
        !!tenant.lastName || "Ce champ est obligatoire",
      tenantDenomination: (tenant: Tenant) =>
        !!tenant.denomination || "Ce champ est obligatoire",
      tenantNumSIREN: (tenant: Tenant) =>
        (!!tenant.numSIREN &&
          onlyNumber(tenant.numSIREN) &&
          tenant.numSIREN.length === 14) ||
        "Le format est incorrect",
      tenantEmail: (tenant: Tenant) =>
        (!!tenant.email && validEmailRule(tenant.email)) ||
        "Le format est incorrect",

      representativeFirstName: (tenant: Tenant) =>
        !!tenant.representative?.firstName || "Ce champ est obligatoire",
      representativeLastName: (tenant: Tenant) =>
        !!tenant.representative?.lastName || "Ce champ est obligatoire",
      representativeStreet: (tenant: Tenant) =>
        !!tenant.representative?.address?.street || "Ce champ est obligatoire",
      representativeZip: (tenant: Tenant) =>
        !!tenant.representative?.address?.zip || "Ce champ est obligatoire",
      representativeCity: (tenant: Tenant) =>
        !!tenant.representative?.address?.city || "Ce champ est obligatoire",
    });

    const goBack = () => {
      context.emit("goBack");
    };

    const goNext = () => {
      if ((context.refs.form as VForm).validate()) {
        context.emit("goNext");
      }
    };

    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const selectedTenantId = ref("");
    const selectedTenantIndex = ref("");
    const displayDeleteTenantModal = ref(false);

    const openDeleteTenantModal = (tenantId, index) => {
      selectedTenantIndex.value = index;
      if (tenantId) {
        selectedTenantId.value = tenantId;
        displayDeleteTenantModal.value = true;
      } else context.emit("deleteTenant", index);
    };

    const deleteTenant = () => {
      tenantsStore.deleteTenant(selectedTenantId.value);
      context.emit("deleteTenant", selectedTenantIndex);
      displayDeleteTenantModal.value = false;
    };

    return {
      today,
      goBack,
      goNext,
      typeRentalAgreementItems,
      tenantType,
      addTenant,
      TenantTypeEnum,
      openDeleteTenantModal,
      selectedTenantId,
      displayDeleteTenantModal,
      deleteTenant,
      rules,
    };
  },
});
