import { FixedAssetsService, FixedAssetAmortisationsService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/fixed-assets";

class FixedAssetService {
  async create(fixedAssetCreate: FixedAssetsService.CreateIn) {
    return axios
      .post<FixedAssetsService.CreateOut>(`${api}`, fixedAssetCreate)
      .then((res) => res.data);
  }

  async list(params: FixedAssetsService.ListIn) {
    return axios
      .get<FixedAssetsService.ListOut>(`${api}`, { params })
      .then((res) => res.data);
  }

  async get(params: FixedAssetsService.GetIn) {
    return axios
      .get<FixedAssetsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => res.data);
  }

  async update(fixedAssetUpdate: FixedAssetsService.UpdateIn) {
    return axios
      .put<FixedAssetsService.UpdateOut>(
        `${api}/${fixedAssetUpdate.id}`,
        fixedAssetUpdate
      )
      .then((res) => res.data);
  }

  amortisations = {
    async create(
      fixedAssetAmortisationCreate: FixedAssetAmortisationsService.CreateIn
    ) {
      return axios
        .post<FixedAssetAmortisationsService.CreateOut>(
          `${api}/amortisations`,
          fixedAssetAmortisationCreate
        )
        .then((res) => res.data);
    },

    async list(params: FixedAssetAmortisationsService.ListIn) {
      return axios
        .get<FixedAssetAmortisationsService.ListOut>(`${api}/amortisations`, {
          params,
        })
        .then((res) => res.data);
    },

    async get(params: FixedAssetAmortisationsService.GetIn) {
      return axios
        .get<FixedAssetAmortisationsService.GetOut>(
          `${api}/amortisations/${params.id}`,
          { params }
        )
        .then((res) => res.data);
    },

    async update(
      fixedAssetAmortisationUpdate: FixedAssetAmortisationsService.UpdateIn
    ) {
      return axios
        .put<FixedAssetAmortisationsService.UpdateOut>(
          `${api}/amortisations/${fixedAssetAmortisationUpdate.id}`,
          fixedAssetAmortisationUpdate
        )
        .then((res) => res.data);
    },

    async delete(params: FixedAssetAmortisationsService.DeleteIn) {
      return axios
        .delete<FixedAssetAmortisationsService.DeleteOut>(
          `${api}/amortisations/${params.id}`,
          { params }
        )
        .then((res) => res.data);
    },
  };
}

// Export a singleton instance in the global namespace
export const fixedAssetsService = new FixedAssetService();
