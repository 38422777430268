






import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "Tag",
  props: {
    color: { type: String as PropType<"primary" | "warning" | "success"> },
    size: { type: String as PropType<"small"> },
  },
});
