/* eslint-disable  @typescript-eslint/no-explicit-any */

import { UserEvent, UserEventType } from "@/events";

export default function initCrisp(): void {
  function init(): void {
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = "4ae182ab-76ad-4a8e-a524-364a57162566";

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  function reset(): void {
    delete (window as any).CRISP_TOKEN_ID;
    (window as any).$crisp.push(["do", "session:reset"]);
    (window as any).$crisp.push(["do", "chat:hide"]);
  }

  function manageChatBox(event: UserEvent) {
    // Detection of user login and not in iframe
    if (
      event.detail &&
      event.detail.id &&
      window.location === window.parent.location
    ) {
      // Association de la session CRISP pour récupérer l'historique des messages
      // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/session-continuity/#how-to-merge-messages-from-anonymous-sessions-to-token-sessions
      (window as any).CRISP_TOKEN_ID = event.detail.id;
      // Le reset est nécessaire pour prendre en compte des changement d'ID
      (window as any).$crisp.push(["do", "session:reset"]);

      // Chat is only display when user has logged in
      if (event.detail.email) {
        // Set again Mail, Lastname, Firstname to synchronize existing contact (asynchronous)
        (window as any).$crisp.push([
          "set",
          "user:email",
          [event.detail.email],
        ]);

        (window as any).$crisp.push([
          "set",
          "user:nickname",
          [event.detail.firstName + " " + event.detail.lastName],
        ]);

        (window as any).$crisp.push(["do", "chat:show"]);
      } else {
        (window as any).$crisp.push(["do", "chat:hide"]);
      }
    } else {
      // Full RESET
      reset();
    }
  }

  init();

  // We are using Crisp on a website where users are authenticated
  // We want to set their email so that they don't have to specify it when they start a chat with you.
  addEventListener(
    UserEventType,
    ((e: UserEvent) => {
      manageChatBox(e);
    }) as EventListener,
    false
  );
}
