
















































import { StripeCheckout } from "@vue-stripe/vue-stripe";

import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { SubscriptionsModel } from "@edmp/api";
import { productsStore, subscriptionsStore } from "@/store";

import Title from "@/components/core/title/Title.vue";
import SubscriptionInfos from "./SubscriptionInfos.vue";
import SubscriptionDocuments from "./SubscriptionDocuments.vue";
import SubscriptionFlowHorizontal from "../update/SubscriptionFlowHorizontal.vue";
import SubscriptionStripe, {
  VSubscriptionStripe,
} from "../update/SubscriptionStripe.vue";
import { useSubscription } from "../subscription.usable";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "SubscriptionDetails",
  components: {
    Title,
    SubscriptionInfos,
    SubscriptionDocuments,
    StripeCheckout,
    SubscriptionFlowHorizontal,
    SubscriptionStripe,
  },
  props: {
    subscriptionId: { type: String, required: true },
    stripeReturnStatus: {
      type: String as PropType<
        SubscriptionsModel.PaymentStatusParams.succeeded | "portal"
      >,
      required: false,
    },
  },
  setup(props, context) {
    const isLoading: Ref<boolean> = ref(false);
    const product = computed(() => productsStore.currentProduct);
    const subscription = computed(() =>
      subscriptionsStore.getSubscription(props.subscriptionId)
    );
    const useSub = useSubscription(props.subscriptionId);

    /**
     * Action
     */
    const subscriptionPaymentRetry = async () => {
      (
        context.refs.subscriptionStripe as unknown as VSubscriptionStripe
      ).retryPayment();
    };

    /**
     * Mutation
     */
    const switchProductSubscription = async () => {
      const subscriptionId = await subscriptionsStore.getSubscriptionByProduct(
        productsStore.currentId
      )?.id;
      if (subscriptionId) {
        if (context.root.$route.params.subscriptionId !== subscriptionId) {
          context.root.$router.push({
            name: ROUTE_NAMES.SubscriptionDetails,
            params: { subscriptionId },
          });
        }
      }
    };
    watch(
      () => productsStore.currentId,
      () => switchProductSubscription(),
      { deep: true }
    );

    /**
     * Init
     */
    const init = async () => {
      isLoading.value = true;

      if (subscription.value && subscription.value.productId) {
        await productsStore.switchProduct({
          id: subscription.value.productId,
        });
        if (!productsStore.currentProduct)
          context.root.$router.push({ name: ROUTE_NAMES.Subscriptions });
      } else {
        context.root.$router.push({ name: ROUTE_NAMES.Subscriptions });
      }

      // Process return from stripe checkout
      if (props.stripeReturnStatus) {
        // Refresh store
        await subscriptionsStore.fetchSubscriptions();
      }

      isLoading.value = false;
    };

    watch(
      () => [props.subscriptionId, props.stripeReturnStatus],
      async () => await init(),
      { deep: true }
    );

    onBeforeMount(async () => {
      await init();
    });

    return {
      ROUTE_NAMES,
      useSub,
      isLoading,
      product,
      subscription,
      subscriptionPaymentRetry,
    };
  },
});
