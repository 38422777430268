import { StripeService, SubscriptionsService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/subscriptions";

class SubscriptionsService {
  async list(params: SubscriptionsService.ListIn) {
    return axios
      .get<SubscriptionsService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async getInvoices(params: SubscriptionsService.ListInvoiceIn) {
    return axios
      .get<SubscriptionsService.ListInvoiceOut>(
        `${api}/${params.id}/invoices`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async get(params: SubscriptionsService.GetIn) {
    return axios
      .get<SubscriptionsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async getMultiProductsDiscount(
    multiProductsDiscount: SubscriptionsService.GetMultiProductsDiscountIn
  ) {
    return axios
      .post<SubscriptionsService.GetMultiProductsDiscountOut>(
        `${api}/discount`,
        multiProductsDiscount
      )
      .then((res) => {
        return res.data;
      });
  }

  async checkUpdatePermission(
    checkUpdatePermission: SubscriptionsService.CheckUpdatePermissionIn
  ) {
    return axios
      .post<SubscriptionsService.CheckUpdatePermissionOut>(
        `${api}/${checkUpdatePermission.id}/check-update-permission`,
        checkUpdatePermission
      )
      .then((res) => {
        return res.data;
      });
  }

  async update(subscriptionUpdate: SubscriptionsService.UpdateIn) {
    return axios
      .put<SubscriptionsService.UpdateOut>(
        `${api}/${subscriptionUpdate.id}`,
        subscriptionUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async cancel(cancel: SubscriptionsService.CancelIn) {
    return axios
      .post<SubscriptionsService.CancelOut>(
        `${api}/${cancel.id}/cancel`,
        cancel
      )
      .then((res) => {
        return res.data;
      });
  }

  stripe = {
    async listProducts(params: StripeService.ListProductsIn) {
      return axios
        .get<StripeService.ListProductsOut>(`${api}/products`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async createPortalSession(
      portalSessionCreate: StripeService.CreatePortalSessionIn
    ) {
      return axios
        .post<StripeService.CreatePortalSessionOut>(
          `${api}/${portalSessionCreate.id}/portal`,
          portalSessionCreate
        )
        .then((res) => {
          return res.data;
        });
    },
  };
}

// Export a singleton instance in the global namespace
export const subscriptionsService = new SubscriptionsService();
