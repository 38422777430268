import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { Amortisation } from "./Amortisation.model";
import { RequireField } from "./Common.model";

export interface RealEstateAmortisation extends Amortisation {
  realEstateAssetId: string;
  createdAt: string;
  updatedAt: string;
}

export type RealEstateAmortisationCreate = Omit<RealEstateAmortisation, "id" | "productId" | "createdAt" | "updatedAt">;
export type RealEstateAmortisationCreateInternal = Omit<RealEstateAmortisation, "id" | "createdAt" | "updatedAt">;
export type RealEstateAmortisationList = Partial<
  Pick<RealEstateAmortisation, "productId" | "realEstateAssetId" | "accountingPeriodIdAllowedToEdit">
>;
export type RealEstateAmortisationListInternal = Partial<
  Pick<RealEstateAmortisation, "productId" | "realEstateAssetId" | "accountingPeriodIdAllowedToEdit"> & {
    productIds: string[];
  }
>;
export type RealEstateAmortisationUpdate = RequireField<
  Partial<
    Omit<
      RealEstateAmortisation,
      "productId" | "realEstateAssetId" | "accountingPeriodIdAllowedToEdit" | "createdAt" | "updatedAt"
    >
  >,
  "id"
>;
export type RealEstateAmortisationUpdateInternal = RequireField<
  Partial<Omit<RealEstateAmortisation, "createdAt" | "updatedAt">>,
  "id"
>;

const realEstateAmortisationSchema = new Schema<RealEstateAmortisation>(
  {
    _id: { type: String, default: () => ulid() },
    realEstateAssetId: { type: String, index: true, required: true },
    productId: { type: String, index: true, required: true },
    accountingPeriodIdAllowedToEdit: { type: String, index: true, required: true },
    type: { type: String, required: true },
    share: { type: Number },
    value: { type: Number },
    startAt: { type: String },
    durationInYear: { type: Number },
    rate: { type: Number },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RealEstateAmortisationSchema) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
export type RealEstateAmortisationSchema = RealEstateAmortisation & Document<string>;

export type RealEstateAmortisationDocument = RealEstateAmortisation & Document<string>;
// Name of the collection in third argument
export const RealEstateAmortisationModel = model<RealEstateAmortisationDocument>(
  "RealEstateAmortisation",
  realEstateAmortisationSchema,
  "RealEstateAmortisation"
);

// API
export namespace RealEstateAmortisationsService {
  export type CreateIn = RealEstateAmortisationCreate;
  export type CreateOut = RealEstateAmortisation;

  export type ListIn = RealEstateAmortisationList;
  export type ListOut = RealEstateAmortisation[];

  export type GetIn = Pick<RealEstateAmortisation, "id">;
  export type GetOut = RealEstateAmortisation;

  export type UpdateIn = RealEstateAmortisationUpdate;
  export type UpdateOut = RealEstateAmortisation;

  export type DeleteIn = Pick<RealEstateAmortisation, "id">;
  export type DeleteOut = boolean;
}
