




























































































































































































































import {
  defineComponent,
  ref,
  Ref,
  computed,
  onBeforeMount,
  watch,
  PropType,
} from "@vue/composition-api";
import moment from "moment";
import { VForm } from "@/models";
import {
  AccountingPeriod,
  AccountingPeriodCreateInternal,
  ProductsModel,
  TaxRegime,
} from "@edmp/api";
import { accountingPeriodsStore, productsStore } from "@/store";

import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import DatePicker from "@/components/atom/DatePicker.vue";

const DEFAULT_ACCOUNTING_PERIOD = {
  taxRegime: TaxRegime.IR_2072,
  firstYear: false,
  startAt: `${new Date().getFullYear()}-01-01`,
  endAt: `${new Date().getFullYear()}-12-31`,
} as AccountingPeriod;

export default defineComponent({
  name: "AccountingIR",
  components: {
    AccountingPeriodChoice,
    DatePicker,
  },
  props: {
    actions: { type: String as PropType<"create" | "update"> },
    isEditing: { type: Boolean, default: false },
  },
  setup(props, context) {
    const accountingPeriod: Ref<AccountingPeriod | undefined> = ref();
    const accountingPeriodExist = computed(
      () => !!accountingPeriodsStore.accountingPeriods.length
    );
    const validateInProgress: Ref<boolean> = ref(false);
    const taxRegime: Ref<TaxRegime> = ref(DEFAULT_ACCOUNTING_PERIOD.taxRegime);

    const exerciceStartChoices = ref([
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
    ]);
    const dateFirstExercice = accountingPeriodsStore.firstAccountingPeriod;
    const isFirstExerciceShow = computed(
      () =>
        accountingPeriodsStore.currentId ===
        accountingPeriodsStore.firstAccountingPeriod.id
    );

    const exerciceStartSelect: Ref<number> = ref() as Ref<number>; // TODO add the parameter 2023 to force the 2023 value for one accounting period
    const isFirstExercice: Ref<boolean | undefined> = ref();
    const acceptationGerantStatutaire: Ref<boolean> = ref(false);

    // Fiscal exercice date
    const startExerciceDateValue: Ref<string | undefined> = ref();
    const endExerciceDateValue: Ref<string | undefined> = ref();

    const rules = ref({
      exerciceDateMin: (): string => {
        const [year] = startExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = DEFAULT_ACCOUNTING_PERIOD.startAt
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
      exerciceDateMax: (): string => {
        const [year] = endExerciceDateValue.value?.split("-") ?? [undefined];
        const [month, day] = DEFAULT_ACCOUNTING_PERIOD.endAt
          .split("-")
          .slice(1);
        return `${exerciceStartSelect.value ?? year}-${month}-${day}`;
      },
    });

    // Send
    function validate(e: Event): void {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        validateInProgress.value = true;

        const createAccountingPeriod = (
          data: AccountingPeriodCreateInternal
        ) => {
          try {
            accountingPeriodsStore.createAccountingPeriod(data).then(() => {
              context.emit("validate");
            });
          } catch (err) {
            validateInProgress.value = false;
          }
        };
        const updateAccountingPeriod = (data: AccountingPeriod) => {
          try {
            accountingPeriodsStore.updateAccountingPeriod(data).then(() => {
              context.emit("validate");
            });
          } catch (err) {
            validateInProgress.value = false;
          }
        };

        if (
          taxRegime.value &&
          startExerciceDateValue.value &&
          endExerciceDateValue.value
        ) {
          const data: AccountingPeriodCreateInternal = {
            productId: productsStore.currentId,
            taxRegime: taxRegime.value,
            firstYear: !!isFirstExercice.value,
            startAt: startExerciceDateValue.value,
            endAt: endExerciceDateValue.value,
          };
          if (props.actions === "create" && !accountingPeriodExist.value) {
            if (exerciceStartSelect.value == new Date().getFullYear() - 1) {
              // Create year-1
              createAccountingPeriod(data);

              // Create year
              data.startAt = DEFAULT_ACCOUNTING_PERIOD.startAt;
              data.endAt = DEFAULT_ACCOUNTING_PERIOD.endAt;
              data.firstYear = false;
              createAccountingPeriod(data);
            } else {
              // Create year
              createAccountingPeriod(data);
            }
            validateInProgress.value = false;
            context.emit("update:isEditing", false);
          } else {
            // Update existing accounting Period
            updateAccountingPeriod(
              Object.assign(data, {
                id: accountingPeriod.value?.id,
              } as AccountingPeriod)
            );
            validateInProgress.value = false;
            context.emit("update:isEditing", false);
            // Reload to change Menu IS <=> IR
            // location.reload();
          }
        }
      }
    }

    function cancelEditing() {
      accountingPeriod.value =
        accountingPeriodsStore.currentAccountingPeriod ??
        DEFAULT_ACCOUNTING_PERIOD;
      startExerciceDateValue.value = `${
        accountingPeriod.value?.startAt ?? DEFAULT_ACCOUNTING_PERIOD.startAt
      }`;
      endExerciceDateValue.value = `${
        accountingPeriod.value?.endAt ?? DEFAULT_ACCOUNTING_PERIOD.endAt
      }`;
      context.emit("update:isEditing", false);
    }

    watch(
      () => exerciceStartSelect.value,
      () => {
        if (startExerciceDateValue.value) {
          const [month, day] = startExerciceDateValue.value
            ?.split("-")
            .slice(1);
          startExerciceDateValue.value = `${exerciceStartSelect.value}-${month}-${day}`;
        }
        if (endExerciceDateValue.value) {
          const [month, day] = endExerciceDateValue.value?.split("-").slice(1);
          endExerciceDateValue.value = `${exerciceStartSelect.value}-${month}-${day}`;
        }
      }
    );

    /**
     * Init
     */
    async function init() {
      if (!props.actions || props.actions === "update") {
        accountingPeriod.value = accountingPeriodsStore.currentAccountingPeriod;
        startExerciceDateValue.value = `${accountingPeriod.value?.startAt}`;
        endExerciceDateValue.value = `${accountingPeriod.value?.endAt}`;
        isFirstExercice.value = accountingPeriod?.value?.firstYear;
      } else {
        const product = productsStore.products.find(
          ({ status }) => status === ProductsModel.ProductStatus.pending
        );
        accountingPeriod.value = accountingPeriodsStore.accountingPeriods.length
          ? accountingPeriodsStore.accountingPeriods
              .filter(({ productId }) => productId === product?.id)
              .reduce((prev, current) =>
                moment(prev.startAt).isBefore(current.startAt) ? prev : current
              )
          : DEFAULT_ACCOUNTING_PERIOD || DEFAULT_ACCOUNTING_PERIOD;
        startExerciceDateValue.value = `${
          accountingPeriod.value.startAt ?? DEFAULT_ACCOUNTING_PERIOD.startAt
        }`;
        endExerciceDateValue.value = `${
          accountingPeriod.value.endAt ?? DEFAULT_ACCOUNTING_PERIOD.endAt
        }`;
        if (!accountingPeriod.value.id) {
          context.emit("update:isEditing", true);
        } else {
          context.emit("update:isEditing", false);
        }
      }
    }

    watch(
      () => [
        accountingPeriodsStore.accountingPeriods,
        accountingPeriodsStore.currentAccountingPeriod,
        props.actions,
      ],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      cancelEditing,
      moment,
      accountingPeriod,
      accountingPeriodExist,
      isFirstExercice,
      exerciceStartChoices,
      exerciceStartSelect,
      taxRegime,
      startExerciceDateValue,
      endExerciceDateValue,
      acceptationGerantStatutaire,
      rules,
      validate,
      validateInProgress,
      dateFirstExercice,
      isFirstExerciceShow,
    };
  },
});
