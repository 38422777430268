



























































































































































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  ComputedRef,
  watch,
  onMounted,
  Ref,
} from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import DatePicker from "@/components/atom/DatePicker.vue";
import YesNoSwitch from "@/components/atom/switch/YesNoSwitch.vue";
import {
  DocumentModelType,
  FinancialConditions,
  getMoment,
  RentalAgreement,
  RentalAgreementsService,
  RentIndexation,
  RentIndexationTypeParamEnum,
  TenseArea,
} from "@edmp/api";
import { debounce } from "lodash";
import RentIndexationOut = RentalAgreementsService.RentIndexationOut;
import { rentalsService } from "@/services";
import { VForm } from "@/models";
import dayjs from "dayjs";
import Dialog from "@/components/atom/Dialog.vue";
import HelpingMessage from "@/components/atom/helping/HelpingMessage.vue";
import {
  RentalIndexationError,
  useRentalIndexationErrorUsable,
} from "@/components/core/realIndexation/rentalIndexationError.usable";
import { productsStore, rentalAgreementsStore, usersStore } from "@/store";
import format from "date-fns/format";
import { dispatchIndexationEvent, IndexationEventCode } from "@/events";

export default defineComponent({
  name: "RealIndexationResult",
  components: { Dialog, YesNoSwitch, DatePicker, SectionTitle, HelpingMessage },
  props: {
    rentIndexationOut: {
      type: Object as PropType<RentIndexationOut>,
      required: true,
    },
  },

  setup(props, context) {
    const rentIndexation = ref<RentIndexation>(props.rentIndexationOut);
    const tempNewRent = ref(rentIndexation.value.newRent);
    const tabItemIndex = ref(0);
    const isLoading = ref(false);
    const rentalAgreement: ComputedRef<RentalAgreement> = computed(
      () =>
        rentalAgreementsStore.getRentalAgreement(
          context.root.$route.params.rentalAgreementId
        ) as RentalAgreement
    );
    const today = ref(format(new Date(), "yyyy-MM-dd"));
    const indexData: Ref<String[] | undefined> = ref();
    const indexDataAfterReferenceDate: Ref<String[] | undefined> = ref();

    watch(
      () => [rentIndexation.value.newIrl, indexData.value],
      () => {
        // Compares current index year with other index years to get only the most recent indexes
        if (indexData.value) {
          const splitCurrentIrlData =
            rentIndexation.value.currentIrl.split(", ");
          const currentIrlYear = splitCurrentIrlData[0];
          const currentIrlTrimester = splitCurrentIrlData[1];

          indexDataAfterReferenceDate.value = indexData.value.filter((data) => {
            const splitReferenceData = data.split(", ");
            const referenceYear = splitReferenceData[0];
            const referenceTrimester = splitReferenceData[1];

            if (Number(referenceYear) === Number(currentIrlYear)) {
              return (
                Number(referenceTrimester[1]) > Number(currentIrlTrimester[1])
              );
            } else {
              return Number(referenceYear) >= Number(currentIrlYear);
            }
          });
        }
      },
      { immediate: true }
    );

    const openModal = ref(true);
    const indexationNoticeModal = ref(false);

    const modalErrorValue = ref<RentalIndexationError | undefined>();

    const beNotified = ref(false);

    const now = new Date();
    const nextRevisionMonth = rentIndexation.value.newIrl.includes("T1")
      ? 1
      : rentIndexation.value.newIrl.includes("T2")
      ? 4
      : rentIndexation.value.newIrl.includes("T3")
      ? 7
      : 10;
    const nextRevisionDate = ref(
      rentIndexation.value.status === "created"
        ? dayjs(rentIndexation.value.revisionDate).format("DD/MM/YYYY")
        : dayjs(
            new Date(now.getFullYear() + 1, nextRevisionMonth - 1, 1)
          ).format("DD/MM/YYYY")
    );

    if (props.rentIndexationOut.error) {
      modalErrorValue.value = useRentalIndexationErrorUsable(
        nextRevisionDate.value,
        props.rentIndexationOut.error
      );
    }

    const canBeRevised = ref(props.rentIndexationOut.status === "created");

    const newIrl = computed(() => rentIndexation.value.newIrl);

    watch(
      newIrl,
      () => {
        debounce(() => {
          if (newIrl.value !== rentIndexation.value.currentIrl) {
            getRentIndexationFromIrl();
          }
        }, 1000)();
      },
      { immediate: true }
    );

    const getRentIndexationFromIrl = async () => {
      isLoading.value = true;
      rentalsService.agreements
        .rentIndexation({
          id: props.rentIndexationOut.rentalAgreementId,
          currentIrl:
            rentalAgreement.value.financialConditions.indexation?.index,
          newIrl: rentIndexation.value.newIrl,
        })
        .then((response) => {
          isLoading.value = false;
          rentIndexation.value = response;
          tempNewRent.value = response.newRent;
        });
    };

    const validateRentIndexation = () => {
      if ((context.refs.form as VForm).validate()) {
        isLoading.value = true;
        rentalsService.agreements
          .revisedRentIndexation({
            id: props.rentIndexationOut.rentalAgreementId,
            rentAmount: tempNewRent.value,
            revisionDate: rentIndexation.value.revisionDate,
            hasConstruction: rentIndexation.value.hasConstruction,
          })
          .then(() => {
            canBeRevised.value = false;
            context.emit("validate");
          })
          .finally(() => {
            isLoading.value = false;
          });
        dispatchIndexationEvent({
          userId: usersStore.loggedInUser.id,
          productId: productsStore.currentId,
          date: getMoment().toISOString(),
          code: IndexationEventCode.SUBMIT_INDEXATION,
        });
      }
    };

    const onCloseModal = () => {
      openModal.value = false;
    };

    const validateNewRent = () => {
      const rentalAgreement = rentalAgreementsStore.getRentalAgreement(
        context.root.$route.params.rentalAgreementId
      );
      if (
        rentIndexation.value.hasConstruction &&
        !rentalAgreement?.financialConditions.tenseArea
      ) {
        return;
      } else if (
        rentIndexation.value.hasConstruction &&
        rentalAgreement &&
        rentalAgreement.financialConditions &&
        rentalAgreement.financialConditions.tenseArea &&
        rentalAgreement.financialConditions.tenseArea
          .monthlyRentReferenceIncreased
      ) {
        return [
          () =>
            tempNewRent.value <=
              (
                (rentalAgreement.financialConditions as FinancialConditions)
                  .tenseArea as TenseArea
              ).monthlyRentReferenceIncreased ||
            "Selon votre paramétrage, votre loyer ne peut pas être révisé au-delà du loyer de référence majoré",
        ];
      } else {
        return [
          () =>
            tempNewRent.value <= rentIndexation.value.newRent ||
            "Selon votre paramétrage, votre loyer ne peut être révisé qu'à la baisse",
        ];
      }
    };

    const downloadMailModel = (fileType) => {
      rentalsService.agreements.downloadDocumentModel({
        modelType: DocumentModelType.NOTICE_RENT_REVIEW,
        fileType,
      });
      dispatchIndexationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code:
          fileType === "docx"
            ? IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_WORD
            : IndexationEventCode.DOWNLOAD_INDEXATION_TEMPLATE_PDF,
      });
    };

    const openIndexationNoticeModal = () => {
      indexationNoticeModal.value = true;
      dispatchIndexationEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        date: getMoment().toISOString(),
        code: IndexationEventCode.OPEN_INDEXATION_NOTICE,
      });
    };

    onMounted(async () => {
      const arrayData = await rentalsService.agreements.getListIndexations(
        rentalAgreement.value.financialConditions.indexation?.type ??
          RentIndexationTypeParamEnum.IRL_metro
      );
      const indexes: String[] = [];
      for (const data of arrayData) {
        indexes.push(data.indexData);
      }
      indexData.value = indexes;
    });

    return {
      rentIndexation,
      tabItemIndex,
      isLoading,
      canBeRevised,
      nextRevisionDate,
      validateRentIndexation,
      beNotified,
      modalErrorValue,
      indexationNoticeModal,
      openModal,
      onCloseModal,
      validateNewRent,
      tempNewRent,
      downloadMailModel,
      rentalAgreement,
      indexData,
      indexDataAfterReferenceDate,
      today,
      openIndexationNoticeModal,
    };
  },
});
