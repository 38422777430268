























































































































import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
} from "@vue/composition-api";
import {
  AccountingBalanceSheet,
  AccountingBalanceSheetUpdate,
  getAmortisationAccountByAccount,
  LedgerAccountEnum,
  Suggestion,
  TypeReference,
  getBalanceSheetAmountDirectionByAccountBalance,
  ServerError,
  ACCOUNTING_BALANCE_SHEET_ERROR,
} from "@edmp/api";
import { flatMap } from "lodash";
import Decimal from "decimal.js-light";

import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  transactionsStore,
} from "@/store";
import { VForm } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";

import { SubmitButton, CustomLabelButton } from "@/components/atom/button";

export default defineComponent({
  name: "AccountingBalanceSheetAddModal",
  components: {
    SubmitButton,
    CustomLabelButton,
  },
  props: {
    accountBalanceType: {
      type: String as PropType<Suggestion["accountBalanceType"] | "all">,
      required: true,
    },
    balanceSheet: {
      type: Object as PropType<AccountingBalanceSheet>,
      required: true,
    },
  },
  setup(props, context) {
    const titleTextType = computed(() => {
      if (props.accountBalanceType === "fixed_assets") {
        return "- Actif immobilisé BRUT";
      }
      if (props.accountBalanceType === "current_assets") {
        return "- Actif circulant BRUT";
      }
      if (props.accountBalanceType === "shareholders_equity") {
        return "- Capitaux propres";
      }
      if (props.accountBalanceType === "debts") {
        return "- Total des dettes";
      }
    });

    const isIS = computed(() => accountingPeriodsStore.isIS);
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);

    const accountingBalanceSheet = computed(() =>
      accountingBalanceSheetsStore.getAccountingBalanceSheet(
        props.balanceSheet.id
      )
    );

    const filter = [
      LedgerAccountEnum.N280000,
      LedgerAccountEnum.N280100,
      LedgerAccountEnum.N281310,
      LedgerAccountEnum.N281350,
      LedgerAccountEnum.N281840,
      LedgerAccountEnum.N164100,
      LedgerAccountEnum.N164200,
      LedgerAccountEnum.N455000,
    ];

    const categories = computed(() => {
      return flatMap(transactionsStore.categoriesList)
        .filter((categorie) => {
          if (!categorie.displayBilan) {
            return false;
          }
          const categorieInFilter = filter.find(
            (account) => account === categorie.number
          );
          if (categorieInFilter) return false;
          if (props.accountBalanceType === "all") {
            return true;
          }
          return categorie.accountBalanceType === props.accountBalanceType;
        })
        .sort((a, b) => {
          if (a.number < b.number) {
            return -1;
          }
          if (a.number > b.number) {
            return 1;
          }
          return 0;
        });
    });
    const categorySelected: Ref<Suggestion | undefined> = ref();
    const accountingBalanceSheetLineAmount: Ref<number | undefined> =
      ref(undefined);
    const accountingBalanceSheetLineAmortisationAmount: Ref<
      number | undefined
    > = ref(undefined);

    const rules = computed(() => ({
      amount: [
        accountingBalanceSheetLineAmount.value !== undefined ||
          "Le champ ne peut pas être null",
        new Decimal(
          Number(accountingBalanceSheetLineAmount.value) || 0
        ).isPositive() ||
          new Decimal(
            Number(accountingBalanceSheetLineAmount.value) || 0
          ).isZero() ||
          categorySelected.value?.number === LedgerAccountEnum.N110001 ||
          categorySelected.value?.number === LedgerAccountEnum.N120001 ||
          categorySelected.value?.number === LedgerAccountEnum.N108000 ||
          categorySelected.value?.number.substring(0, 6) ===
            LedgerAccountEnum.N512000 ||
          "Le champ ne peut pas être négatif",
      ],
      amortisationAmount: [
        accountingBalanceSheetLineAmortisationAmount.value !== undefined ||
          "Le champ ne peut pas être null",
        (accountingBalanceSheetLineAmortisationAmount.value !== undefined &&
          accountingBalanceSheetLineAmount.value !== undefined &&
          Number(accountingBalanceSheetLineAmount.value) >
            Number(accountingBalanceSheetLineAmortisationAmount.value)) ||
          "Le montant de l'amortissement ne peut pas être supérieur au montant",
        new Decimal(
          Number(accountingBalanceSheetLineAmortisationAmount.value) || 0
        ).isPositive() ||
          new Decimal(
            Number(accountingBalanceSheetLineAmortisationAmount.value) || 0
          ).isZero() ||
          "Le champ ne peut pas être négatif",
      ],
    }));

    const amortisationAccount = computed(() =>
      isIS || isLMNP
        ? getAmortisationAccountByAccount(
            categorySelected.value?.number as LedgerAccountEnum
          )
        : undefined
    );

    const isAvailableAddHere = computed(() => {
      if (categorySelected.value?.referenceCounter) {
        return false;
      }
      return true;
    });

    // Methods
    const navigateTo = () => {
      if (
        categorySelected.value?.referenceCounter === TypeReference.bankAccount
      ) {
        router.push(
          isLMNP
            ? {
                name: ROUTE_NAMES.MyBusiness,
                query: {
                  tabIndex: "2",
                },
              }
            : {
                name: ROUTE_NAMES.Activities,
                query: { focus: "banks-section" },
              }
        );
      }
      if (categorySelected.value?.referenceCounter === TypeReference.partner) {
        router.push({
          name: ROUTE_NAMES.Activities,
          query: { focus: "partners-section" },
        });
      }
      if (
        categorySelected.value?.referenceCounter ===
        TypeReference.realEstateAsset
      ) {
        router.push(
          isLMNP
            ? { name: ROUTE_NAMES.RealEstateAssets }
            : {
                name: ROUTE_NAMES.Activities,
                query: { focus: "assets-section" },
              }
        );
      }
      if (
        categorySelected.value?.referenceCounter ===
        TypeReference.realEstateLoan
      ) {
        router.push(
          isLMNP
            ? { name: ROUTE_NAMES.RealEstateAssets }
            : {
                name: ROUTE_NAMES.Activities,
                query: { focus: "assets-section" },
              }
        );
      }
      if (
        categorySelected.value?.referenceCounter ===
        TypeReference.realEstateAmortisation
      ) {
        router.push({
          name: ROUTE_NAMES.Amortisations,
        });
      }
    };
    const closeDialog = () => {
      context.emit("close");
    };

    // Validate
    const isLoadingValidate = ref(false);
    const validate = async () => {
      if (
        (context.refs.form as VForm).validate() &&
        categorySelected.value &&
        accountingBalanceSheet.value
      ) {
        isLoadingValidate.value = true;
        try {
          const direction = getBalanceSheetAmountDirectionByAccountBalance(
            categorySelected.value.accountBalance
          );
          if (direction) {
            const balanceSheetToSave: AccountingBalanceSheetUpdate =
              Object.assign(props.balanceSheet, {
                lines: [
                  ...accountingBalanceSheet.value.lines,
                  {
                    account: categorySelected.value.number,
                    amount: Number(
                      Number(accountingBalanceSheetLineAmount.value).toFixed(0)
                    ),
                    direction,
                  },
                ],
              });
            if (amortisationAccount.value && balanceSheetToSave.lines) {
              balanceSheetToSave.lines.push({
                account: amortisationAccount.value,
                amount: Number(
                  accountingBalanceSheetLineAmortisationAmount.value
                ),
                direction,
                transactionIds: [],
              });
            }
            await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
              balanceSheetToSave
            );
            closeDialog();
          } else {
            throw new ServerError(
              "Line direction is undefined",
              undefined,
              ACCOUNTING_BALANCE_SHEET_ERROR,
              {
                balanceSheet: accountingBalanceSheet.value,
                direction,
                category: categorySelected.value,
              }
            );
          }
        } catch (error) {
          console.log(error);
        } finally {
          isLoadingValidate.value = false;
        }
      }
    };

    return {
      titleTextType,
      categories,
      categorySelected,
      amortisationAccount,
      accountingBalanceSheetLineAmount,
      accountingBalanceSheetLineAmortisationAmount,
      rules,
      isAvailableAddHere,
      LedgerAccountEnum,
      TypeReference,
      // Methods
      navigateTo,
      closeDialog,
      // Validate
      isLoadingValidate,
      validate,
      isLMNP,
    };
  },
});
