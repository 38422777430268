var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("Importer des transactions")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"#000","icon":""},on:{"click":function($event){return _vm.openArticle()}}},[_c('v-icon',[_vm._v(" mdi-help-circle-outline")])],1),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.finish()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.firstStep)?_c('div',[_c('v-form',{ref:"form",staticClass:"import-file-card mr-4",on:{"submit":function($event){$event.preventDefault();return _vm.validateFirstStep($event)}}},[(!_vm.isLMNP)?_c('span',[_vm._v("Pour rappel, vous êtes sur le point d'importer des transactions pour la SCI "+_vm._s(_vm.product.name)+" ")]):_c('span',[_vm._v("Pour rappel, vous êtes sur le point d'importer des transactions pour votre activité LMNP "+_vm._s(_vm.product.name)+" ")]),_c('v-file-input',{staticClass:"flex-grow-0 mb-4 mt-4",attrs:{"id":"inputFileName","rules":[
            function (value) { return !!value || 'Sélectionner un fichier au format francais'; },
            function (value) { return !value ||
              value.size < 1000000 ||
              'La taille du fichier doit être inférieure à 1 Mo!'; } ],"accept":".qif,.csv","label":"Sélectionnez votre fichier","small-chips":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',[_vm._v("Formats supportés: QIF, CSV")]),_c('v-select',{staticClass:"mt-4",attrs:{"item-text":_vm.bankAccountLabel,"items":_vm.bankAccounts,"rules":[
            function (value) { return !!value ||
              'Vous devez ajouter un compte bancaire afin de pouvoir importer des transactions.'; } ],"item-value":"id","label":"Compte bancaire","outlined":""},model:{value:(_vm.bankAccount),callback:function ($$v) {_vm.bankAccount=$$v},expression:"bankAccount"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","depressed":"","type":"submit"}},[_vm._v(" Valider ")])],1)],1)],1):_vm._e(),(_vm.secondStep && _vm.importResponse && _vm.importResponse.status)?_c('div',{staticClass:"mt-3"},[_c('v-card-text',{staticClass:"importReportCard pt-3"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.importResponse.transactionIds.length))]),_vm._v(" transactions ont bien été importées pour la SCI "+_vm._s(_vm.product.name)+" sur Ownily. "),_c('br'),_c('br'),_vm._v(" Pour les retrouver et commencer à les catégoriser dès aujourd’hui, rendez-vous sur la page “Mes transactions” et activez le filtre “Fichier importé”. ")]),_c('a',{on:{"click":function($event){return _vm.openArticle()}}},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Besoin d’aide ? Consulter le centre d’aide ici. ")])],1),_c('v-btn',{staticClass:"importReportCard-btn",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.finish()}}},[_vm._v(" J'ai compris ")])],1)],1):_vm._e()]),_c('Confirm',{ref:"confirmDialog"}),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"1000px","persistent":""},model:{value:(_vm.isOpenImportTransactionDuplication),callback:function ($$v) {_vm.isOpenImportTransactionDuplication=$$v},expression:"isOpenImportTransactionDuplication"}},[(
        _vm.isOpenImportTransactionDuplication &&
        _vm.importResponse &&
        !_vm.importResponse.status
      )?_c('TransactionImportCreateDuplication',{attrs:{"transactionIdsAlreadyImported":_vm.importResponse.transactionIds,"transactionsDuplicate":_vm.importResponse.transactionsDuplicate,"transactionsIgnored":_vm.importResponse.transactionsIgnored,"bankAccountId":_vm.bankAccount,"file":_vm.file},on:{"finish":function () {
          _vm.isOpenImportTransactionDuplication = false;
          _vm.finish();
        }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }