

























































import SignTemplate from "@/components/sign/SignTemplate.vue";
import RegisterBankAccount from "@/components/sign/signup/steps/RegisterBankAccount.vue";
import { computed, defineComponent, ref, Ref } from "@vue/composition-api";
import { productsStore } from "@/store";

export default defineComponent({
  name: "Registration",
  components: {
    SignTemplate,
    RegisterBankAccount,
  },
  setup() {
    const bi: Ref<boolean> = ref(false);

    return {
      product: computed(() => productsStore.currentProduct),
      bi,
    };
  },
});
