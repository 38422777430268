import { PartnersService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/partners";

class PartnerService {
  async create(partnerCreate: PartnersService.CreateIn) {
    return axios
      .post<PartnersService.CreateOut>(`${api}`, partnerCreate)
      .then((res) => {
        return res.data;
      });
  }

  async list(params: PartnersService.ListIn) {
    return axios
      .get<PartnersService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: PartnersService.GetIn) {
    return axios
      .get<PartnersService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(partnerUpdate: PartnersService.UpdateIn) {
    return axios
      .put<PartnersService.UpdateOut>(
        `${api}/${partnerUpdate.id}`,
        partnerUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async invites(invites: PartnersService.InvitePartnersIn) {
    return axios.post<PartnersService.InvitePartnersOut>(
      `${api}/invites`,
      invites
    );
  }

  async invitePartner(invite: PartnersService.InvitePartnerIn) {
    return axios.post<PartnersService.InvitePartnerOut>(
      `${api}/${invite.id}/invite`,
      invite
    );
  }
}

// Export a singleton instance in the global namespace
export const partnersService = new PartnerService();
