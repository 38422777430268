import { NewCompanyTransactionRepository, Transaction } from "./Transaction.model";
import levenshtein from "fast-levenshtein";
import Decimal from "decimal.js-light";
import { LedgerAccountEnum, isObsoleteCategories } from "./JournalComposedEntry";

export const SUGGESTION_PRECISION = {
  date: 6,
  summary: 6,
  amountStrict: 0,
  amountPercent: 0.01,
};
export const isVariableAmountCategories = (accountNumber: string): boolean =>
  accountNumber === LedgerAccountEnum.N661110 ||
  accountNumber === LedgerAccountEnum.N616600 ||
  accountNumber === LedgerAccountEnum.N164100 ||
  accountNumber === LedgerAccountEnum.N606110;

export const matchDateInMonth = (
  d1: string | Date,
  d2: string | Date,
  distance = SUGGESTION_PRECISION.date
): boolean => {
  // Comparaison des dates en retenant le jour dans le mois et en calculant la distance
  // 22/05/21 -> 22   et le 26/06/21 -> 26
  // 26 - 22 = 4 <5 => true
  return Math.abs(new Date(d1).getDate() - new Date(d2).getDate()) < distance;
};

export const matchDate = (d1: string | Date, d2: string | Date, distance = SUGGESTION_PRECISION.date): boolean => {
  // Comparaison des dates en comparant année en mois équivalent puis jour approximatif
  return (
    new Date(d1).getFullYear() === new Date(d2).getFullYear() &&
    new Date(d1).getMonth() === new Date(d2).getMonth() &&
    matchDateInMonth(d1, d2, distance)
  );
};

export const matchTransactionDate = (
  t1: Transaction | NewCompanyTransactionRepository,
  t2: Transaction,
  distance = SUGGESTION_PRECISION.date
): boolean => {
  return matchDateInMonth(t1.date.operation, t2.date.operation, distance);
};

export const matchTransactionDateStrict = (
  t1: Transaction | NewCompanyTransactionRepository,
  t2: Transaction,
  distance = SUGGESTION_PRECISION.date
): boolean => {
  return matchDate(t1.date.operation, t2.date.operation, distance);
};

function getLevenshteinInPercent(t1: Transaction | NewCompanyTransactionRepository, t2: Transaction): number {
  // On pourrait rajouter un poids sur le fait que les 10 premiers caractères soit les memes..

  // Calcul la distance entre deux chaines de caractères selon un algo levenshtein2
  // COUCOU et couCoi la distance est de 1 caractères
  const distance = levenshtein.get(t1.summary, t2.summary, { useCollator: true });
  const max = Math.max(t1.summary.length, t2.summary.length);

  // Calcul d'un pourcentage de similitude
  // (6-1) / 6 = 83% de similitude
  // 70 % de similitude
  return (max - distance) / max;
}

export const matchSummary = (t1: Transaction | NewCompanyTransactionRepository, t2: Transaction): boolean => {
  if (!t1.summary || !t2.summary) {
    return false;
  }

  // Sur les chaines de caractères dont la longueur est petit il faut une similitude de 100%
  if (t1.summary.length < SUGGESTION_PRECISION.summary || t2.summary.length < SUGGESTION_PRECISION.summary) {
    return t1.summary === t2.summary;
  }

  return getLevenshteinInPercent(t1, t2) > 0.7;
};

export const distanceAmount = (amount1: number, amount2: number): number =>
  new Decimal(amount1).sub(new Decimal(amount2)).abs().toNumber();

export const matchAmount = (amount1: number, amount2: number, precision = 0): boolean =>
  new Decimal(distanceAmount(amount1, amount2)).lte(precision);

export const matchAmountStrict = (t1: Transaction | NewCompanyTransactionRepository, t2: Transaction): boolean =>
  new Decimal(t1.value.amount).eq(new Decimal(t2.value.amount));

export const matchAmountVariable = (tUncategorized: Transaction, tCategorized: Transaction): boolean => {
  const lines = tCategorized?.operations?.journalEntry?.lines;
  // Search for category with amount variable in the time (interest, etc)
  if (
    lines?.length === 2 && // No subdivision
    lines?.some((value) => isVariableAmountCategories(value.account))
  ) {
    const distance = new Decimal(distanceAmount(tUncategorized.value.amount, tCategorized.value.amount));
    const ratio = new Decimal(distance).div(new Decimal(tUncategorized.value.amount)).mul(100).abs();
    // Compute max ratio to find suggestion between 5% and 10%
    // If distance between label is weak, then we accept up to 10%
    const maxRatio = Math.max(new Decimal(10).mul(getLevenshteinInPercent(tUncategorized, tCategorized)).toNumber(), 5);
    return ratio.lte(maxRatio);
  }

  return false;
};

/*
 Return True if Transaction is categorized without obsolete category
 */
const isCategorized = (tCategorized: Transaction): boolean => {
  const lines = tCategorized?.operations?.journalEntry?.lines;
  return !!lines && !lines?.some((value) => isObsoleteCategories(value.account));
};

export const predicateSuggestion = (
  tUncategorized: Transaction | undefined,
  tCategorized: Transaction | undefined
): boolean => {
  if (
    !tUncategorized ||
    !tCategorized ||
    tUncategorized.value === null ||
    tCategorized.value === null ||
    tUncategorized?.value?.amount === null ||
    tCategorized?.value?.amount === null
  ) {
    // We ignore transaction undefined or with value or amount undefined
    return false;
  }
  try {
    return (
      matchTransactionDate(tUncategorized, tCategorized) &&
      matchSummary(tUncategorized, tCategorized) &&
      (matchAmountStrict(tUncategorized, tCategorized) || matchAmountVariable(tUncategorized, tCategorized)) &&
      isCategorized(tCategorized) &&
      !tUncategorized?.deleted && // Check not deleted
      !(tUncategorized?.operations?.journalEntry?.lines && tUncategorized.operations.journalEntry.lines.length > 0)
    ); // Check Not already categorized
  } catch (e) {
    // Sometimes we have incorrect decimal in POWENS
    return false;
  }
};
