import { VConfirmDialog } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { ability, bankAccountsService } from "@/services";
import { coreStore, productsStore, subscriptionsStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ForbiddenError, subject } from "@casl/ability";
import { SubscriptionsModel } from "@edmp/api";
import { computed, ComputedRef, reactive, Ref } from "@vue/composition-api";

export interface StripeStates {
  publishableKey: string;
  sessionId: Ref<string | undefined>;
  redirectUri: ComputedRef<{ success: string; cancel: string } | undefined>;
  paymentReturnIsOpen: boolean;
  subscriptionPaymentRetryIsLoading: Ref<boolean>;
}

export function useSubscription(subscriptionId: string) {
  const subscription = computed(() =>
    subscriptionsStore.getSubscription(subscriptionId)
  );

  /**
   * It returns an object with three properties, each of which is a string
   * @returns An object with three properties: success, cancel, and portal.
   */
  const getRedirectUri = () =>
    computed(() => {
      if (!subscription.value) {
        throw new Error("Cannot find subscription");
      }

      return {
        success: `subscriptions/${subscription.value.id}`,
        cancel: `subscriptions/${subscription.value.id}`,
        portal: `subscriptions/${subscription.value.id}`,
      };
    });

  /* Creating a reactive object with the properties of the interface `StripeStates` */
  const stripeStates = reactive<{
    publishableKey: string;
    sessionId: string | undefined;
    redirectUri: ComputedRef<{ success: string; cancel: string } | undefined>;
    paymentReturnIsOpen: boolean;
    subscriptionPaymentRetryIsLoading: boolean;
  }>({
    publishableKey: coreStore.config.subscription.publishableKey,
    sessionId: undefined,
    redirectUri: computed(() => {
      return getRedirectUri().value;
    }),
    paymentReturnIsOpen: false,
    subscriptionPaymentRetryIsLoading: false,
  });

  const openPaymentReturn = (isOpen: boolean) =>
    (stripeStates.paymentReturnIsOpen = isOpen);

  /**
   * "If the user has the ability to portal, then get the portal session and redirect the user to the
   * portal."
   *
   * The first line of the function is a call to the `throwUnlessCan` method on the `ForbiddenError`
   * class. This method will throw a `ForbiddenError` if the user does not have the ability to portal
   */
  const goStripePortal = async (): Promise<void> => {
    if (!subscription.value) {
      throw new Error("Cannot find subscription");
    }

    ForbiddenError.from(ability).throwUnlessCan(
      "portal",
      subject("Subscription", {
        productId: subscription.value.productId,
      })
    );
    const portalSession = await subscriptionsStore.getPortal({
      id: subscription.value.id,
      redirectUri: getRedirectUri().value.portal,
      productId: subscription.value.productId,
    });
    window.location.href = portalSession.url;
  };

  /**
   * "When the user clicks on the subscription card, go to the subscription details page."
   * The first line of the function is a TypeScript type annotation. It tells the TypeScript compiler
   * that the function returns nothing
   */
  const goDetail = () => {
    if (!subscription.value) {
      throw new Error("Cannot find subscription");
    }

    router.push({
      name: ROUTE_NAMES.SubscriptionDetails,
      params: { subscriptionId: subscription.value.id },
    });
  };

  /**
   * It checks if the current user has the right to update a subscription
   * @returns A boolean
   */
  function isAllowSubscriptionUpdate() {
    if (!subscription.value) {
      throw new Error("Cannot find subscription");
    }

    try {
      ForbiddenError.from(ability).throwUnlessCan(
        "add",
        subject("Subscription", {
          productId: subscription.value.productId,
        })
      );
    } catch (error) {
      if (error instanceof ForbiddenError) {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message:
            "Vous n'avez pas les droits nécessaires pour accéder à ce service",
        });
      }
      return false;
    }
    return true;
  }

  /**
   * If the user is allowed to update their subscription, then fetch the subscriptions from the server
   * and navigate to the subscription update page
   */
  const goSubscriptionUpdate = async () => {
    if (isAllowSubscriptionUpdate()) {
      await subscriptionsStore.fetchSubscriptions();
      router.push({
        name: ROUTE_NAMES.ActivitySubscriptionCreate,
      });
    }
  };

  /**
   * It checks if the user has the permission to update the subscription plan
   * @param {VConfirmDialog} confirmDialogRef - VConfirmDialog - this is the reference to the confirm dialog component
   * @param {PlanTypeParams} planType - PlanTypeParams
   */
  const checkUpdatePermission = async (
    confirmDialogRef: VConfirmDialog,
    planType: SubscriptionsModel.PlanTypeParams
  ) => {
    if (!subscription.value) {
      throw new Error("Cannot find subscription");
    }

    try {
      const { allow } = await subscriptionsStore.checkUpdatePermission({
        id: subscription.value.id,
        planType,
      });
      const bankAccounts = (
        await bankAccountsService.list({
          productId: productsStore.currentId,
        })
      ).filter((b) => b.name !== "Manuel");
      if (
        allow &&
        planType === SubscriptionsModel.PlanTypeParams.Solo &&
        bankAccounts.length
      ) {
        return await confirmDialogRef.open(
          `Le service de connexion bancaire nécessite un abonnement supérieur. Le choix de cette formule désactivera la synchronisation et supprimera les transactions déjà récupérées. Voulez-vous continuer ?`,
          { width: 700 }
        );
      }
      return allow;
    } catch (error) {
      return false;
    }
  };

  return {
    subscription,
    stripeStates,
    openPaymentReturn,
    goSubscriptionUpdate,
    getRedirectUri,
    goStripePortal,
    goDetail,
    checkUpdatePermission,
  };
}
