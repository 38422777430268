// Functions $crisp API
// @ see https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/

export enum ArticleEnum {
  REGISTER_USER_ACCOUNT = "REGISTER_USER_ACCOUNT",
  WHO_IS_OWNILY = "WHO_IS_OWNILY",
  PASSWORD = "PASSWORD",
  CATEGORIZE_TRANSACTION = "CATEGORIZE_TRANSACTION",
  CATEGORIZE_AVAILABLE = "CATEGORIZE_AVAILABLE",
  DOCUMENT_SAVING = "DOCUMENT_SAVING",
  SUBSCRIPTION_CONDITION = "SUBSCRIPTION_CONDITION",
  COMPANY_SETTING = "COMPANY_SETTING",
  TAX_RETURN_2072 = "TAX_RETURN_2072",
  EVENTS_YEAR_END = "EVENTS_YEAR_END",
  EVENTS_YEAR_END_IS = "EVENTS_YEAR_END_IS",
  IMPORT_TRANSACTION = "IMPORT_TRANSACTION",
  RENTAL_BUILDINGS = "RENTAL_BUILDINGS",
  PRODUCT_UPDATE_IR = "PRODUCT_UPDATE_IR",
  PRODUCT_UPDATE_IS = "PRODUCT_UPDATE_IS",
  PRODUCT_UPDATE_LMNP = "PRODUCT_UPDATE_LMNP",
  SUPPORTING_DOCUMENTS = "SUPPORTING_DOCUMENTS",
  CATEGORIZE_TRANSACTION_IS = "CATEGORIZE_TRANSACTION_IS",
  ACCOUNTING_TRANSACTION_IS = "ACCOUNTING_TRANSACTION_IS",
  REAL_ESTATE_AMORTISATION_RECOMMENDATION = "REAL_ESTATE_AMORTISATION_RECOMMENDATION",
  REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE = "REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE",
  TAX_RETURN_2065 = "TAX_RETURN_2065",
  DOCUMENT_SAVING_IS = "DOCUMENT_SAVING_IS",
  ACCOUNTING_BALANCE_SHEET_RECOVERY = "ACCOUNTING_BALANCE_SHEET_RECOVERY",
  FIXED_ASSETS_RECOMMENDATION = "FIXED_ASSETS_RECOMMENDATION",
  TAX_DECLARATION_2033 = "TAX_DECLARATION_2033",
  TAX_DECLARATION_2065 = "TAX_DECLARATION_2065",
  TAX_DECLARATION_2031 = "TAX_DECLARATION_2031",
}

const articleMap: Record<ArticleEnum, string> = {
  REGISTER_USER_ACCOUNT: "puzdlh",
  WHO_IS_OWNILY: "1xqfkvq",
  PASSWORD: "i8rf45",
  CATEGORIZE_TRANSACTION: "dgmvt6",
  CATEGORIZE_AVAILABLE: "18g9oro",
  DOCUMENT_SAVING: "1qputj4",
  SUBSCRIPTION_CONDITION: "1efe8vn",
  COMPANY_SETTING: "1rpqx5w",
  TAX_RETURN_2072: "128mh69",
  EVENTS_YEAR_END: "19hq6rv",
  EVENTS_YEAR_END_IS: "1hli79i",
  IMPORT_TRANSACTION: "2vqr3f",
  RENTAL_BUILDINGS: "10irrri",
  PRODUCT_UPDATE_IR: "zhu7ej",
  PRODUCT_UPDATE_IS: "kqk4cl",
  PRODUCT_UPDATE_LMNP: "70mnz1",
  SUPPORTING_DOCUMENTS: "htaswa",
  CATEGORIZE_TRANSACTION_IS: "eas8wz",
  ACCOUNTING_TRANSACTION_IS: "1afzo26",
  REAL_ESTATE_AMORTISATION_RECOMMENDATION: "1vvurvb",
  REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE: "82vshx",
  TAX_RETURN_2065: "nn90te",
  DOCUMENT_SAVING_IS: "10fshx9",
  ACCOUNTING_BALANCE_SHEET_RECOVERY: "2pnp0y",
  FIXED_ASSETS_RECOMMENDATION: "kzkjkw",
  TAX_DECLARATION_2033: "ovbpk1",
  TAX_DECLARATION_2065: "",
  TAX_DECLARATION_2031: "",
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function useCrisp() {
  // Open helpdesk search
  const displayHelpSearch = () => {
    (window as any).$crisp.do("helpdesk:search");
  };

  //  This function allow to view helpdesk article
  const displayHelpArticle = (articleCrisp: string | unknown) => {
    if ((window as any).$crisp?.is("chat:visible")) {
      if (typeof articleCrisp === "string") {
        // View helpdesk article
        (window as any).$crisp.do("helpdesk:article:open", [
          "fr",
          articleCrisp,
        ]);
      } else {
        displayHelpSearch();
      }
    } else {
      if (typeof articleCrisp === "string") {
        window.open(
          `https://ownily.crisp.help/fr/article/${articleCrisp}`,
          "_blank"
        );
      } else {
        window.open(`https://ownily.crisp.help/fr`, "_blank");
      }
    }
  };

  const openArticle = (article: ArticleEnum) => {
    const articleCrisp = articleMap[article];
    displayHelpArticle(articleCrisp);
  };

  // Close CRISP app
  const closeApp = (): void => {
    const intervalID = setInterval(function () {
      const crispOpen: NodeListOf<HTMLElement> = document.querySelectorAll(
        "div[data-visible='true']"
      );
      const emailInvalid = document.querySelectorAll(
        "a[data-type='email_invalid']"
      );

      if (crispOpen.length != 0 && emailInvalid.length != 0) {
        const closeElements: NodeListOf<HTMLElement> =
          document.querySelectorAll("div[data-tile='shapes']");
        if (closeElements.length != 0) {
          closeElements.forEach((element) => {
            element.click();
          });
          clearInterval(intervalID);
        }
      }
    }, 1000); //run this thang every seconds
  };

  return {
    displayHelpSearch,
    displayHelpArticle,
    openArticle,
    closeApp,
  };
}
