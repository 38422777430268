


















































import { defineComponent, computed } from "@vue/composition-api";
import { coreStore } from "@/store";
import { Feedback } from "@/store/modules/Core.store";

export default defineComponent({
  name: "App",
  setup() {
    // if ((window as any).Cypress) {
    //   console.log("Add core store for cypress");
    //   (window as any).store = coreStore;
    // }
    const feedbackType = {
      INFO: "info",
      FEEDBACK: "success",
      WARNING: "warning",
      ERROR: "error",
    };

    const getFeedbackType = (feedback: Feedback) =>
      feedback?.type ? feedbackType[feedback.type] : "";
    return {
      feedback: computed(() => coreStore.feedback),
      utm: computed(() => coreStore.utm),
      getFeedbackType,
    };
  },
});
