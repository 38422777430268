































































import { AmortisationType, getMoment, getFixedAssetTypeTexts } from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";

import { fixedAssetsStore } from "@/store";
import { numberWithSpaces } from "@/utils";

import Tag from "@/components/atom/Tag.vue";
import { HelpingMessage } from "@/components/atom/helping";
import FixedAssetAmortisationTable from "./FixedAssetAmortisationTable.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "FixedAssetAmortisationModal",
  components: { Tag, HelpingMessage, FixedAssetAmortisationTable },
  props: {
    fixedAssetId: { type: String, required: true },
  },

  setup(props, context) {
    const amortisationTypeText: {
      text: string;
      value: AmortisationType;
    }[] = getFixedAssetTypeTexts();
    const fixedAsset = computed(() =>
      fixedAssetsStore.getFixedAsset(props.fixedAssetId)
    );
    const fixedAssetType = computed(
      () =>
        amortisationTypeText.find(
          (amortisationType) =>
            amortisationType.value === fixedAsset.value?.type
        )?.text
    );
    const fixedAssetBoughtPriceText = computed(() =>
      fixedAsset.value?.boughtPrice !== undefined
        ? `${numberWithSpaces(fixedAsset.value.boughtPrice)} €`
        : "inconnue"
    );
    const fixedAssetCommissioningAt = computed(() =>
      fixedAsset.value?.commissioningAt !== undefined
        ? getMoment(fixedAsset.value.commissioningAt).format("LL")
        : "inconnue"
    );

    function closeDialog() {
      context.emit("close");
    }

    return {
      fixedAssetType,
      fixedAssetBoughtPriceText,
      fixedAssetCommissioningAt,
      closeDialog,

      openArticleAmortisationRecommendationAddLine: () =>
        useCrisp().openArticle(
          ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
        ),
    };
  },
});
