import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { OneOf, TaxRateTva } from "..";

export enum RentalState {
  NOT_RENTAL = "not_rental",
  RENTAL = "rental",
  FURNISHED_RENTAL = "furnished_rental",
}

export enum RentalUsage {
  RESIDENTIAL = "residential",
  PROFESSIONAL = "professional",
  AGRICULTURAL = "agricultural",
  PARKING = "parking",
  GUEST_HOUSE = "guest_house",
}

/**
 * `NewRentalUnit` — Data to create a rental unit
 */
export type NewRentalUnit = Omit<RentalUnit, "id" | "deleted" | "createdAt" | "updatedAt">;

/**
 * `RentalUnit` — A rental unit inside a real estate asset
 */
export interface RentalUnit {
  id: string;
  productId: string;
  realEstateAssetId: string;
  name: string; // Name of the rental unit
  rentalState?: RentalState; // State  of the rental unit
  rentalUsage?: RentalUsage; // Usage  of the rental unit, use for determinate activate TVA
  taxTvaEnable?: boolean;
  taxRateTVA?: TaxRateTva;
  crlEnable?: boolean;
  deleted?: boolean;
  createdAt: string;
  updatedAt: string;
}

export type RentalUnitUpdate = Omit<RentalUnit, "productId" | "createdAt" | "updatedAt">;
export type RentalUnitUpdateInternal = Omit<RentalUnit, "createdAt" | "updatedAt">;

const rentalUnitSchema = new Schema<RentalUnitDocument>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String, required: true, maxlength: 200 },
    productId: { type: String, index: true, required: true },
    realEstateAssetId: { type: String, index: true, required: true },
    rentalState: { type: RentalState },
    rentalUsage: { type: RentalUsage },
    taxTvaEnable: { type: Boolean },
    taxRateTVA: { type: TaxRateTva },
    crlEnable: { type: Boolean },
    deleted: { type: Boolean, default: false },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentalUnitDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type RentalUnitDocument = RentalUnit & Document<string>;
// Name of the collection in third argument
export const RentalUnitModel = model<RentalUnitDocument>("RentalUnit", rentalUnitSchema, "RentalUnits");

// API
export namespace RentalUnitsService {
  export type CreateIn = NewRentalUnit;
  export type CreateOut = RentalUnit;

  export type ListIn = Partial<Pick<RentalUnit, "productId" | "realEstateAssetId">> & { withDeleted?: boolean };
  export type ListOut = RentalUnit[];

  export type GetIn = Pick<RentalUnit, "id">;
  export type GetOut = RentalUnit;

  export type UpdateIn = RentalUnitUpdate;
  export type UpdateOut = RentalUnit;

  export type DeleteIn = OneOf<Pick<RentalUnit, "id">, { realEstateAssetId: string }>;
  export type DeleteOut = boolean;

  // export type MoveIn = {
  //   productId: string;
  //   realEstateAssetId: string;
  //   rentalUnitId: string;
  // };
  // export type MoveOut = RentalUnit;
}
