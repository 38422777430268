



















import { ROUTE_NAMES } from "@/router/routes";
import { productsStore } from "@/store";
import { defineComponent, onMounted, watch } from "@vue/composition-api";
import DocumentCard from "../../documents/DocumentCard.vue";
import { useDocuments } from "../../documents/useDocuments";

export default defineComponent({
  name: "DocumentsOverview",
  components: {
    DocumentCard,
  },
  setup() {
    const { documents, getProductDocuments } = useDocuments();

    const getThirdDocuments = async () => {
      await getProductDocuments();
      if (documents.value.length > 3)
        documents.value = documents.value.slice(0, 3);
    };

    onMounted(getThirdDocuments);
    watch(() => productsStore.currentProduct, getThirdDocuments);

    return { documents, ROUTE_NAMES };
  },
});
