var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"accounting-datatable pa-2",attrs:{"show-expand":"","disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":"","item-key":"name","mobile-breakpoint":"200","headers":_vm.headers,"items":_vm.bodyAnnuals,"id":"track-table"},on:{"click:row":function (item, slot) {
      if (item.canExpand) { slot.expand(!slot.isExpanded); }
    }},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
    var item = ref.item;
    var isExpanded = ref.isExpanded;
return [(item.canExpand)?_c('button',{staticClass:"v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-down theme--light",class:isExpanded ? 'v-data-table__expand-icon--active' : '',attrs:{"type":"button"}}):_vm._e()]}},_vm._l((_vm.headers.slice(1, _vm.headers.length - 1)),function(h){return {key:("item." + (h.value)),fn:function(ref){
    var item = ref.item;
return [(new Date(((h.value) + "-01")) <= new Date() || _vm.get(item, h.value))?_c('span',{key:h.value},[_vm._v(" "+_vm._s(_vm.$n(_vm.get(item, h.value, "0"), "currency", "fr-FR"))+" ")]):_vm._e()]}}}),{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{staticClass:"accounting-expanded-td"},_vm._l((item.categories),function(c){return _c('span',{key:c.name},[_vm._v(" "+_vm._s(_vm.get(c, headers[0].value, ""))+" "),_c('br')])}),0),_vm._l((headers.slice(1, headers.length - 1)),function(h){return _c('td',{key:h.value,staticClass:"text-center accounting-expanded-td"},_vm._l((item.categories),function(c){return _c('span',{key:c.name},[(new Date(((h.value) + "-01")) <= new Date() || _vm.get(c, h.value))?_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.get(c, h.value, "0"), "currency", "fr-FR"))+" ")]):_vm._e(),_c('br')])}),0)}),_c('td',_vm._l((item.categories),function(c){return _c('tr',{key:c.name})}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }