




























































































































































import { BankAccountState } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  ability,
  bankAccountsService,
  banksService,
  productsService,
} from "@/services";
import {
  accountingPeriodsStore,
  bankAccountsStore,
  productsStore,
  coreStore,
  subscriptionsStore,
  taskActivityStore,
  transactionsStore,
  usersStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ForbiddenError, subject } from "@casl/ability";
import {
  BankAccount,
  ProductsModel,
  SubscriptionsModel,
  stateIncomplete,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import ManageBankAccountModal from "./ManageBankAccountModal.vue";
import BankAccountsModal from "./RegisterBankAccountsModal.vue";

export default defineComponent({
  name: "BankAccounts",
  components: {
    BankAccountsModal,
    ManageBankAccountModal,
  },
  props: { productId: { type: String } },

  setup(props, context) {
    const isLoading: Ref<boolean> = ref(false);
    const product: Ref<ProductsModel.Product | undefined> = ref();
    const subscription = computed(() => {
      if (product.value) {
        return subscriptionsStore.getSubscriptionByProduct(product.value.id);
      }
    });
    const bankAccounts: Ref<BankAccount[]> = ref([]);
    const selectedBankAccountsId: Ref<string[]> = ref([]);
    const openDialogAccount: Ref<boolean> = ref(false);
    const openDialogManage: Ref<boolean> = ref(false);
    const currentBank: Ref<string> = ref("");
    const isDedicatedBankAccount = ref(
      productsStore.currentProduct &&
        productsStore.currentProduct?.type === ProductsModel.ProductTypes.LMNP
        ? productsStore.currentProduct.dedicatedBankAccount
        : false
    );
    const headerTitleText = computed(() => {
      return productsStore.currentProduct?.type ===
        ProductsModel.ProductTypes.SCI
        ? "Comptes bancaires de ma SCI"
        : "Comptes bancaires de mon LMNP";
    });

    const taxRegime = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
        TaxRegime.IR_2072
    );
    const taxRegimeText = computed(() =>
      taxRegime.value === TaxRegime.LMNP_2031
        ? "votre activité LMNP"
        : "votre SCI"
    );

    const panel = 0;
    const selectedBankAccounts = computed(() => {
      const bankAccountsLinked = bankAccounts.value
        .filter((acc) => selectedBankAccountsId.value.includes(acc.id))
        .map((acc) => {
          const bank = usersStore.loggedInUser.banks?.find(
            (a) => a.id === acc.bankId
          );
          const bankState = bank?.bi_state ?? stateIncomplete;

          return {
            ...acc,
            state: bankState,
          } as BankAccountState;
        });

      // Retrieve Bank synchronized without BankAccount
      const bankAccountsOrphan =
        usersStore.loggedInUser.banks
          ?.filter(
            (bank) =>
              !bankAccounts.value.some((value) => value.bankId === bank.id) &&
              usersStore.loggedInUser.bankReferences?.some(
                (bankReference) =>
                  bankReference.bi_connection === bank.bi_connection &&
                  product.value?.id === bankReference.productId
              )
          )
          .map((bank) => {
            return {
              id: "", // No BankAccount id
              name: "Aucun compte",
              bankName: bank.name,
              bankId: bank.id,
              bankLogoUuid: bank.bi_connector_uuid,
              lastSeen: bank.lastSync,
              state: stateIncomplete,
            };
          }) ?? [];

      return [...bankAccountsLinked, ...bankAccountsOrphan];
    });
    const urlLogo: Ref<string> = ref("");

    // Retrieve Banks already synchronized for new SCI to manage new bank account
    const selectedBanks = computed(
      () =>
        usersStore.loggedInUser.banks?.map((bank) => {
          return {
            id: "", // No BankAccount id
            name: "",
            bankName: bank.name,
            bankId: bank.id,
            bankLogoUuid: bank.bi_connector_uuid,
            lastSeen: bank.lastSync,
            state: bank.bi_state,
          };
        }) ?? []
    );

    function testOpenDialog() {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addForOwner",
          subject("BankAccountOwner", {
            productId: product.value?.id,
          })
        );
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Vous n'avez pas les droits nécessaires pour accéder à ce service",
          });
        }
        return false;
      }
      try {
        // TEMP
        ForbiddenError.from(ability).throwUnlessCan(
          "add",
          subject("BankAccount", {
            size: selectedBankAccountsId.value.length,
          })
        );
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
        return false;
      }
      return true;
    }
    function openDialog() {
      if (testOpenDialog()) {
        router.push({ query: { modal: "add-account" } });
        openDialogAccount.value = true;
      }
    }

    function openModalManage(id) {
      if (testOpenDialog()) {
        router.push({ query: { modal: "add-account" } });
        currentBank.value = id;
        openDialogManage.value = true;
      }
    }

    function closeDialog() {
      const query = Object.assign({}, context.root.$route.query);
      delete query.modal;
      router.push({ query });
      openDialogAccount.value = false;
      openDialogManage.value = false;
    }

    async function getBankAccounts() {
      bankAccounts.value = (await bankAccountsService.list({})).filter(
        ({ bankId }) => bankId !== "Manual"
      );
      await bankAccountsStore.fetchBankAccounts(productsStore.currentId);
    }

    async function refreshProductsBankAccountSelected(): Promise<void> {
      selectedBankAccountsId.value = (
        await productsService.get({
          id: productsStore.currentId,
        })
      ).bankAccounts;
    }

    async function accountsChanged() {
      closeDialog();
      coreStore.displayFeedback({
        message: `Bravo votre compte bancaire est bien lié à ${taxRegimeText.value} sur Ownily. La synchronisation des transactions peut prendre un peu de temps avant de s'afficher.`,
        timeout: 8000,
      });
      await usersStore.fetchLoggedInUser();
      // FIXME Gestion des comptes à faire via le store
      await getBankAccounts();
      await refreshProductsBankAccountSelected();
      if (accountingPeriodsStore.currentId)
        await transactionsStore.fetchTransactions({
          productId: productsStore.currentId,
          accountingPeriodId: accountingPeriodsStore.currentId,
        });
      await taskActivityStore.fetchTaskActivityGroups({});
    }

    watch(
      () => context.root.$route.query,
      (val) => {
        if (Object.keys(val).includes("modal")) {
          openDialogAccount.value = true;
        } else {
          openDialogAccount.value = false;
        }
      }
    );

    // Init
    async function init() {
      urlLogo.value = (await banksService.getConfig()).logoUrl;
      isLoading.value = true;
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      product.value = productsStore.currentProduct;
      await getBankAccounts();
      await refreshProductsBankAccountSelected();
      isLoading.value = false;
    }

    watch(
      () => productsStore.currentProduct,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      ROUTE_NAMES,
      isLoading,
      product,
      subscription,
      PlanType: SubscriptionsModel.PlanType,
      bankAccounts,
      selectedBankAccounts,
      selectedBanks,
      openDialog,
      closeDialog,
      openDialogAccount,
      accountsChanged,
      openModalManage,
      openDialogManage,
      currentBank,
      urlLogo,
      taxRegime,
      TaxRegime,
      isDedicatedBankAccount,
      headerTitleText,
      panel,
    };
  },
});
