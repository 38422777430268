import { BankStats } from "./BankStats.model";
import { IsoDateAndTime } from "./Common.model";
import { Schema } from "mongoose";

// -- List of bi_state
// Reconnect Webview errors
export const statesReconnect = [
  "wrongpass",
  "decoupled",
  "additionalInformationNeeded",
  "webauthRequired",
  "SCARequired",
];

// Other errors
export const statesOther = ["validating", "actionNeeded", "passwordExpired", "websiteUnavailable", "bug"];
// Bank state is Verified
export const stateVerified = "verified";
// Bank is not completed (missing bankaccount)
export const stateIncomplete = "incomplete";

// An interface to specification provider
export interface SpecProvider {
  id: string; // BI, Mock
  repository: string;
  redirect_id: string;
  name: string;
  logo?: string | null;
  iban?: string;
  bi_state?: string;
}

/**
 * `Bank` — A bank
 */
export interface Bank {
  id: string; // Short Id court (CMB)
  name: string; // Name of bank
  provider: string; // Provider is BI or mock
  lastSync?: IsoDateAndTime;
  bi_connection?: string;
  bi_connections?: string[];
  bi_created?: string;
  bi_state: string;
  bi_last_update?: string;
  bi_next_try?: string;
  bi_connector_uuid?: string;
  errorMessage?: string;
  months_to_fetch?: string;
}

// Data used to connect with provider in Front
export interface ProviderOperation {
  type: string;
  url: string;
}

// Data used to identify user connection with provider
export interface ProviderUser {
  bi_user_id?: string;
  bi_user_token?: string;
  mock_connection?: boolean;
}

// Data used to connect with Provider
export interface ProviderConnection {
  user: ProviderUser;
  operation: ProviderOperation;
}

export enum JWTRedirectionBIUserMethods {
  CREATE = "create",
  MANAGE = "manage",
  RECONNECT = "reconnect",
}
export type JWTRedirectionBIUser<Method extends JWTRedirectionBIUserMethods = JWTRedirectionBIUserMethods> = {
  sub: string;
  provider: string;
  method: Method;
  iat: number;
  exp: number;
} & (
  | (Method extends JWTRedirectionBIUserMethods.CREATE
      ? {
          method: JWTRedirectionBIUserMethods.CREATE;
          productId: string; // Parameter to synchronize BankAccount with Company in webview
        }
      : never)
  | (Method extends JWTRedirectionBIUserMethods.MANAGE
      ? {
          method: JWTRedirectionBIUserMethods.MANAGE;
          productId: string; // Parameter to synchronize BankAccount with Company in webview
          bank: string;
        }
      : never)
  | (Method extends JWTRedirectionBIUserMethods.RECONNECT
      ? {
          method: JWTRedirectionBIUserMethods.RECONNECT;
          bank: string;
        }
      : never)
);

export const bankSchema = new Schema<Bank>({
  id: { type: String, index: true },
  name: String,
  provider: { type: String, index: true },
  lastSync: Date,
  bi_connection: String,
  bi_connections: [{ type: String }],
  bi_created: Date,
  bi_state: String,
  bi_last_update: Date,
  bi_next_try: Date,
  bi_connector_uuid: { type: String, index: true },
  errorMessage: String,
});

// API
export namespace BanksService {
  export type ConfigIn = never;
  export type ConfigOut = { logoUrl: string };

  export type ListProvidersIn = never;
  export type ListProvidersOut = SpecProvider[];

  export type CreateIn = { id: string; productId: string };
  export type CreateOut = ProviderOperation;

  export type UpdateIn = { userId: string; bankId: string; bankName: string };
  export type UpdateOut = Bank;

  export type ListIn = never;
  export type ListOut = Bank[];

  export type GetLandingsIn = { redirectId: string; state: string };
  export type GetLandingsOut = void;

  export type ReconnectIn = { bankId: string };
  export type ReconnectOut = ProviderOperation;

  export type ManageIn = { bankId: string; productId: string };
  export type ManageOut = ProviderOperation;

  export type CreateSynchronizationsIn = { userId: string; forceSync?: boolean; providerId?: "mock" | "BI" };
  export type CreateSynchronizationsOut = number;

  export type ListStatsIn = { userId: string };
  export type ListStatsOut = BankStats;

  export type DeleteTestConnectorIn = never;
  export type DeleteTestConnectorOut = boolean;
}
