var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("Duplication de transaction")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.cancel($event)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.firstStep)?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.validateFirstStep($event)}}},[_c('v-row',[_c('div',{staticClass:"mb-4",style:({ width: '100%' })},[_c('DatePicker',{attrs:{"refId":"addTransactionDate","max":_vm.accountingPeriod.endAt,"min":_vm.accountingPeriod.startAt,"label":"Date","required":"","rules":[
                function () { return !!_vm.transactionDate || 'Le champ ne peut pas être vide'; } ]},model:{value:(_vm.transactionDate),callback:function ($$v) {_vm.transactionDate=$$v},expression:"transactionDate"}})],1)]),_c('v-row',[_c('v-text-field',{attrs:{"hide-details":"auto","id":"addTransactionAmount","rules":[
              function () { return (_vm.transactionAmount &&
                  /^-?[0-9]\d*(\.\d+)?$/.test(
                    _vm.transactionAmount.toString()
                  )) ||
                'Le champ ne peut contenir que des nombres positifs'; },
              function () { return !(_vm.transactionAmount == 0) ||
                'La transaction ne peut pas être égale à 0'; },
              function () { return (_vm.transactionAmount && _vm.transactionAmount > 0) ||
                'Le nombre ne peut pas être négatif'; },

              function () { return (_vm.transactionAmount &&
                  _vm.countDecimals(_vm.transactionAmount) <= 2) ||
                'Le montant doit contenir au maximum deux décimales'; } ],"label":"Montant","outlined":"","required":"","type":"number"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-select',{attrs:{"id":"selectionType","items":[
                  { label: 'Crédit', val: 'crédit' },
                  { label: 'Débit', val: 'débit' } ],"label":"Choisir","outlined":"","item-text":"label","item-value":"val","rules":[function (v) { return !!v || 'Crédit ou Débit'; }],"required":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})]},proxy:true}],null,false,3793199768),model:{value:(_vm.transactionAmount),callback:function ($$v) {_vm.transactionAmount=$$v},expression:"transactionAmount"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"id":"addTransactionLabel","rules":[
              function () { return !!_vm.transactionSummary || 'Le champ ne peut pas être vide'; } ],"label":"Libellé","outlined":"","required":"","type":"text"},model:{value:(_vm.transactionSummary),callback:function ($$v) {_vm.transactionSummary=$$v},expression:"transactionSummary"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"primary","depressed":"","type":"submit"}},[_vm._v(" Valider ")])],1)],1)],1):_vm._e(),(_vm.secondStep && _vm.transaction)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"duplication-transaction mx-2 mx-md-6 my-4"},[_c('h4',{staticClass:"duplication-transaction-date mb-5"},[_vm._v(" "+_vm._s(_vm.$d( new Date(_vm.transaction.date.operation), "day-month-year-long", "fr-FR" ))+" ")]),_c('Transaction',{attrs:{"transactionId":_vm.transaction.id,"transactionSource":_vm.transactionSource,"isOnAddManualTransaction":"","isCategorized":_vm.isCategorized},on:{"update:isCategorized":function($event){_vm.isCategorized=$event},"update:is-categorized":function($event){_vm.isCategorized=$event}}}),_c('div',{staticClass:"duplication-transaction-btns mt-4"},[_c('v-btn',{staticClass:"mt-1 mr-2",attrs:{"outlined":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Annuler ")]),_c('v-btn',{staticClass:"mt-1 ml-2",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.categorizeAndValidate()}}},[_vm._v(" Valider ")])],1),_c('Next',{ref:"confirmDialog"})],1)]):_vm._e()]),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }