






























































































































































































































































































































































































































import Tag from "@/components/atom/Tag.vue";
import { TaskActivityLocal } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  taskActivityStore,
  transactionsStore,
} from "@/store";
import { FilterKeys, isSmallestAccountingPeriod } from "@/utils";
import {
  getMoment,
  getReferredIdByTypeWithReferences,
  TaskActivityBehavior,
  TaskActivityService,
  TaskActivityStatus,
  TaskActivityTypeReference,
  TaskCode,
  TaskGroupCode,
  TypeTaskActivity,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
  ref,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";

import TaxDeclarationValidate from "@/components/core/events/yearEnd/TaxDeclarationValidate.vue";
import { OmitField } from "@edmp/api/src";

export default defineComponent({
  name: "TaskActivityAction",
  methods: {},
  components: { Tag, TaxDeclarationValidate },
  props: {
    taskActivityLocal: {
      type: Object as PropType<TaskActivityLocal>,
      required: true,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivity: TaskActivityLocal,
          data?: OmitField<TaskActivityService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    const generateActionMessage = (taskActivity: TaskActivityLocal) => {
      return taskActivity.status === TaskActivityStatus.COMPLETED
        ? "Voir"
        : "À faire";
    };
    const navigateToTransactions = (filterCode: FilterKeys) => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({ code: filterCode });
      router.push({ name: ROUTE_NAMES.Transactions });
    };
    const isIR = computed(() => accountingPeriodsStore.isIR);
    const isIS = computed(() => accountingPeriodsStore.isIS);
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);
    const isFirstAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.firstYear
    );
    const isPremium = computed(() => subscriptionsStore.isPremium);
    const isOptimum = computed(() => subscriptionsStore.isOptimum);

    const transactions = reactive({
      transactionsToBeCategorizedNumber: computed(
        () => transactionsStore.numberOfTransactionsToBeCategorized
      ),
      transactionsAnomalyNumber: computed(
        () => transactionsStore.numberOfTransactionsToBeAnomalized
      ),
    });

    const realEstateAssetsNotAssign = computed(() =>
      realEstateAssetsStore.realEstateAssets.filter((realEstateAsset) => {
        for (const rentalBuilding of rentalBuildingsStore.getRentalBuildingByAccountingPeriodId(
          accountingPeriodsStore.currentId
        )) {
          if (rentalBuilding.realEstateAssetIds.includes(realEstateAsset.id)) {
            return false;
          }
        }
        return true;
      })
    );

    const startWellTaskGroup = computed(() => {
      return taskActivityStore.startWellTaskGroup;
    });
    const accountingBalanceSheetRecoveryNotOpen = computed(() =>
      isIS.value
        ? !!startWellTaskGroup.value
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccounting
            )
            ?.taskActivitiesLocal.find(
              (taskActivityLocal) =>
                (taskActivityLocal.code === TaskCode.PartnerCreate ||
                  taskActivityLocal.code === TaskCode.AssetUpdate ||
                  taskActivityLocal.code === TaskCode.BankAccountLink ||
                  taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                taskActivityLocal.status === TaskActivityStatus.PENDING
            )
        : isLMNP.value
        ? !!startWellTaskGroup.value
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP ||
                taskGroup.groupCode === TaskGroupCode.StartWellLMNP
            )
            ?.taskActivitiesLocal.find(
              (taskActivityLocal) =>
                (taskActivityLocal.code ===
                  TaskCode.DeferrableAmortisationsAdd ||
                  taskActivityLocal.code === TaskCode.DeferrableDeficitsAdd ||
                  taskActivityLocal.code === TaskCode.AssetUpdate ||
                  taskActivityLocal.code === TaskCode.AmortisationUpdate ||
                  taskActivityLocal.code === TaskCode.LoanAdd) &&
                taskActivityLocal.status === TaskActivityStatus.PENDING
            )
        : true
    );
    const accountingBalanceSheetRecoveryNotValidate = computed(
      () =>
        !!startWellTaskGroup.value
          .find((taskGroup) =>
            isIS.value
              ? taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              : isLMNP.value
              ? taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP
              : false
          )
          ?.taskActivitiesLocal.find(
            (taskActivityLocal) =>
              taskActivityLocal.code === TaskCode.BalanceSheetRecovery &&
              taskActivityLocal.status === TaskActivityStatus.PENDING
          )
    );
    const navigateToBalanceSheetRecovery = () => {
      if (accountingBalanceSheetRecoveryNotOpen.value) {
        router.push({ name: ROUTE_NAMES.TaskActivity });
        return;
      }
      if (accountingBalanceSheetRecoveryNotValidate.value) {
        router.push({ name: ROUTE_NAMES.AccountingBalanceSheetRecovery });
        return;
      }
    };

    const accountingBalanceSheetRecovery = computed(() =>
      cloneDeep(
        accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("recovery")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "recovery" &&
              getMoment(accountingBalanceSheet.endAt).isSame(
                getMoment(
                  accountingPeriodsStore.firstAccountingPeriodNotClosed.endAt
                ).subtract(1, "year")
              )
          )
      )
    );
    const isValidateAccountingBalanceSheetRecovery = computed(
      () =>
        !taskActivityStore.notCompletedTaskActivities.find((taskActivity) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            taskActivity.references,
            TaskActivityTypeReference.accountingPeriod
          );
          return (
            taskActivity.groupCode === TaskGroupCode.AccountingPeriod &&
            (taskActivity.code === TaskCode.BalanceSheetRecovery ||
              taskActivity.code === TaskCode.InjectBalance) &&
            accountingPeriodId &&
            isSmallestAccountingPeriod(accountingPeriodId)
          );
        })
    );
    const skipAccountingBalanceSheetRecovery = async () => {
      if (accountingBalanceSheetRecovery.value?.type === "recovery") {
        accountingBalanceSheetRecovery.value.isSkipped =
          !accountingBalanceSheetRecovery.value.isSkipped;
        await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
          accountingBalanceSheetRecovery.value
        );
      }
    };

    const accountingBalanceSheet = computed(() =>
      cloneDeep(
        accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("closure")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "closure" &&
              accountingBalanceSheet.accountingPeriodId ===
                accountingPeriodsStore.currentId
          )
      )
    );
    const isValidateAccountingBalanceSheet = computed(
      () => accountingBalanceSheet.value?.isValidated
    );

    const previousTaskFlow = computed(() => {
      const previousTask = [...taskActivityStore.taskActivityGroups]
        .flatMap((t) => t.taskActivitiesLocal)
        .find(
          (t) =>
            t.type === TypeTaskActivity.FLOW &&
            t.references?.every((r) =>
              props.taskActivityLocal.references
                ?.flatMap((r) => r.referredId)
                .includes(r.referredId)
            ) &&
            !!(
              props.taskActivityLocal
                .behaviors as TaskActivityBehavior<TypeTaskActivity.FLOW>[]
            ).find(
              (b) =>
                accountingPeriodsStore.currentAccountingPeriod &&
                b.taxRegime ===
                  accountingPeriodsStore.currentAccountingPeriod?.taxRegime &&
                b.isBlockedByTaskCodes?.includes(t.code)
            )
        );

      return previousTask;
    });

    const isOpenTaxDeclarationValidateModal = ref(false);

    onBeforeMount(async () => {
      // await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
    });

    return {
      generateActionMessage,
      navigateToTransactions,
      navigateToBalanceSheetRecovery,
      isValidateAccountingBalanceSheetRecovery,
      skipAccountingBalanceSheetRecovery,
      accountingBalanceSheetRecoveryNotOpen,
      accountingBalanceSheetRecoveryNotValidate,
      FilterKeys,
      TaskCode,
      TaskGroupCode,
      isIR,
      isIS,
      isLMNP,
      isFirstAccountingPeriod,
      isPremium,
      isOptimum,
      transactions,
      realEstateAssetsNotAssign,
      ROUTE_NAMES,
      isValidateAccountingBalanceSheet,
      isOpenTaxDeclarationValidateModal,
      TaskActivityStatus,
      previousTaskFlow,
    };
  },
});
