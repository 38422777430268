var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.subscription &&
      _vm.subscription.plan.type === 'Free' &&
      _vm.stripeReturnStatus !== 'canceled'
    )?_c('v-card',{staticClass:"d-flex align-center flex-column mb-6",attrs:{"flat":""}},[_c('v-progress-circular',{staticClass:"mx-md-5 mt-8",attrs:{"size":80,"width":8,"color":"primary","indeterminate":""}}),_c('div',{staticClass:"mx-md-5 mt-8"},[_c('p',{staticClass:"font-text-Attente"},[_vm._v(" L'activation de votre abonnement est en cours de traitement, ")]),_c('p')]),_c('div',{staticClass:"mx-md-5"},[_c('p',{staticClass:"font-text-Attente"},[_vm._v("elle peut prendre quelques minutes.")])])],1):_vm._e(),(
      _vm.subscriptionPlan &&
      _vm.subscriptionPlan.status !== _vm.SubscriptionStatus.error &&
      _vm.subscriptionPlan.paymentStatus !== _vm.PaymentStatus.failed &&
      _vm.subscription &&
      (_vm.subscription.plan.type !== 'Free' || _vm.stripeReturnStatus === 'canceled')
    )?_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-row',{staticClass:"mx-md-n5",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"mx-md-5 my-md-0"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.subscriptionPlan.title))]),_c('p',{staticClass:"my-2",attrs:{"id":"subscription-payment-subtitle"},domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.subtitle)}}),_c('p',{staticClass:"my-2",domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.text)}}),_c('p',{staticClass:"my-2",domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.info)}})])])],1)],1):_vm._e(),(
      (_vm.subscriptionPlan &&
        _vm.subscriptionPlan.status === _vm.SubscriptionStatus.error) ||
      _vm.subscriptionPaymentRetryIsLoading
    )?_c('v-alert',{staticClass:"my-7",attrs:{"outlined":"","prominent":"","text":"","type":"error"}},[_c('div',{staticStyle:{"color":"#000"}},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.subscriptionPlan.title))]),_c('p',{staticClass:"my-2",attrs:{"id":"subscription-payment-subtitle"},domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.subtitle)}}),_c('p',{staticClass:"my-2 mb-4",domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.text)}}),_c('v-btn',{staticClass:"mb-2 mb-md-0 btn-return",attrs:{"loading":_vm.subscriptionPaymentRetryIsLoading,"color":"secondary","elevation":"0"},on:{"click":function($event){return _vm.$emit('subscriptionPaymentRetry')}}},[_vm._v(" Régler la facture ")]),_c('p',{staticClass:"my-2 mt-4",domProps:{"innerHTML":_vm._s(_vm.subscriptionPlan.info)}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }