import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import store from "@/store/root";
import { User, UserAccount } from "@edmp/api";
import { userAccountService, usersService } from "@/services";
import { dispatchUserEvent } from "@/events";
import { productsStore, usersStore } from "..";

export interface UsersState {
  loggedInUser: User;
  loggedInUserAccount: UserAccount;
  loading: boolean;
}

@Module({
  name: "users-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class UsersStore extends VuexModule implements UsersState {
  loggedInUser: User = {} as User;
  loggedInUserAccount: UserAccount = {} as UserAccount;
  loading = false;

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  reset(): void {
    this.loggedInUser = {} as User;
    this.loggedInUserAccount = {} as UserAccount;
    this.loading = false;
  }

  // User
  @Mutation
  setLoggedInUser(user: User): void {
    this.loggedInUser = user;
    try {
      dispatchUserEvent(user);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchLoggedInUser(): Promise<void> {
    this.setLoading(true);

    try {
      const user = await usersService.getLoggedIn();
      this.setLoggedInUser(user);
      usersStore.fetchLoggedInMetaUser(user.email);
      productsStore.fetchProducts({});
    } catch (err) {
      // Reset User in case of error
      this.setLoggedInUser({} as User);
    }
    this.setLoading(false);
  }

  get isLogged(): boolean {
    return !!this.loggedInUser.id;
  }

  // MetaUser
  @Mutation
  setLoggedInUserAccount(userAccount: UserAccount): void {
    this.loggedInUserAccount = userAccount;
  }

  @Action
  async fetchLoggedInMetaUser(username: string): Promise<void> {
    this.setLoading(true);

    try {
      this.setLoggedInUserAccount(await userAccountService.get({ username }));
    } catch (err) {
      // Reset User in case of error
      this.setLoggedInUserAccount({} as UserAccount);
    }
    this.setLoading(false);
  }
}
