import axios from "axios";
import {
  BankAccountsService,
  BankAccountsTransactionsService,
} from "@edmp/api";

const api = "/api/v1/bank-accounts";

class BankAccountsService {
  async list(params: BankAccountsService.ListIn) {
    const res = await axios.get<BankAccountsService.ListOut>(`${api}`, {
      params,
    });
    return res.data;
  }

  async get(params: BankAccountsService.GetIn) {
    return axios
      .get<BankAccountsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async importTransactions({
    document,
    file,
  }: BankAccountsTransactionsService.ImportIn) {
    const formData = new FormData();
    // @see https://moleculer.services/docs/0.14/moleculer-web.html#File-upload-aliases
    // - In Moleculer-web : ctx.meta.$multipart contains the additional text form-data fields
    // - Additional text form-data fields must be sent before other files fields.
    if (document.accountingPeriodId)
      formData.append("accountingPeriodId", document.accountingPeriodId);
    if (document.startAt) formData.append("startAt", document.startAt);
    if (document.endAt) formData.append("endAt", document.endAt);
    if (document.duplicationResolves)
      formData.append(
        "duplicationResolves",
        JSON.stringify(document.duplicationResolves)
      );
    // - Then set files fields (Stream)
    formData.append("file", file);

    return axios
      .post<BankAccountsTransactionsService.ImportOut>(
        `${api}/${document.bankAccountId}/transactions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const bankAccountsService = new BankAccountsService();
