// Definition of constants for Model
//
// Can't be include in Model because error in export (circular)

export class TransactionModelSpec {
  public static readonly NAME = "Transaction";
  public static readonly COLLECTION = "Transactions";
}

export class OperationModelSpec {
  public static readonly NAME = "JournalComposedEntry";
  public static readonly COLLECTION = "JournalComposedEntries";
}
