














































import { ref, Ref, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ValidateBudgetInsight",
  setup() {
    const tooLongTime: Ref<boolean> = ref(false);

    setTimeout(() => {
      tooLongTime.value = true;
    }, 30000);

    function postMessageToParent() {
      window.parent.postMessage("too_long_time", "*");
    }

    return {
      tooLongTime,
      postMessageToParent,
    };
  },
});
