import { OneOf } from "@edmp/api";

export const OnBoardingEventType = "OnBoardingEvent" as const;

export enum OnBoardingEventSteps {
  USER_DETAILS = "userDetails",
  ACTIVITY_DETAILS = "activityDetails",
  BANK_ACCOUNT = "bankAccount",
}

export type OnBoardingEventData<
  OnBoardingEventStep extends OnBoardingEventSteps = OnBoardingEventSteps
> = {
  step: OnBoardingEventStep;
  userId: string;
} & (OnBoardingEventStep extends OnBoardingEventSteps.USER_DETAILS
  ? {
      step: OnBoardingEventSteps.USER_DETAILS;
      taxRegime: "IR" | "IS" | "LMNP";
      activityRegistered: boolean;
    }
  : OnBoardingEventStep extends OnBoardingEventSteps.ACTIVITY_DETAILS
  ? {
      step: OnBoardingEventSteps.ACTIVITY_DETAILS;
      productId: string;
    } & (
      | {
          taxRegime: "LMNP";
          declarationType: "MICRO" | "REAL_SIMPLIFIED" | "UNKNOWN";
        }
      | {
          taxRegime: Exclude<"IR" | "IS" | "LMNP", "LMNP">;
        }
    )
  : OnBoardingEventStep extends OnBoardingEventSteps.BANK_ACCOUNT
  ? {
      step: OnBoardingEventSteps.BANK_ACCOUNT;
      productId: string;
      requestForAccompanied: boolean;
    } & OneOf<{ isCanceled: boolean }, { isAdded: boolean }>
  : never);

export type OnBoardingEvent = CustomEvent<OnBoardingEventData>;

export function dispatchOnBoardingEvent(data: OnBoardingEventData) {
  dispatchEvent(
    new CustomEvent<OnBoardingEventData>(OnBoardingEventType, { detail: data })
  );
}
