import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";

export enum Role {
  OWNER = "owner",
  GRANT = "grant",
}

export type ResourceType = "Company" | "BankAccount" | "Subscription";

// Allow to give a permission for a User in an specific collection (Company, etc)
export interface UserPermission {
  id: string;
  role: Role;
  isCreator?: boolean; // Used to retrieve who has created a resource in case of many Owner
  addedAt?: Date;
  addedById?: string; // Creator
}

export type UserPermissionDocument = UserPermission & Document<string>;

export const userPermissionSchema = new Schema<UserPermissionDocument>(
  {
    _id: { type: String, default: () => ulid() },
    role: { type: String, required: true, enum: Object.values(Role), default: Role.OWNER },
    isCreator: { type: Boolean, default: false },
    addedAt: { type: Date, default: Date.now },
    addedById: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

// Allow to give a permission for a given resource in a generic collection (Matrix)
export interface Permission {
  id: string;
  userId: string;
  role: Role;
  isCreator?: boolean; // Used to retrieve who has created a resource in case of many Owner
  resourceType: ResourceType; // Kind of resource is Company..
  resourceId: string; // Reference of resource
  addedById?: string;
  addedAt?: string;
  createdAt: string;
  updatedAt: string;
}
export type PermissionCreate = Omit<Permission, "id" | "createdAt" | "updatedAt">;

export type PermissionDocument = Permission & Document<string>;

const permissionSchema = new Schema<PermissionDocument>(
  {
    _id: { type: String, default: () => ulid() },
    userId: { type: String, required: true, index: true },
    role: { type: String, required: true, enum: Object.values(Role), default: Role.OWNER },
    resourceType: { type: String },
    resourceId: { type: String },
    isCreator: { type: Boolean, default: false },
    addedAt: { type: Date, default: Date.now },
    addedById: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
// Name of the collection in third argument
export const PermissionModel = model<PermissionDocument>("Permission", permissionSchema, "Permissions");
