import {
  TaskActivity,
  TaskActivityGroup,
  TaskActivityService,
} from "@edmp/api";
import axios from "axios";

const api = "/api/v1/task-activity";

class TaskActivitiesService {
  async list(params: TaskActivityService.ListIn) {
    return axios
      .get<TaskActivityService.ListOut>(api, { params })
      .then((res) => {
        return res.data;
      });
  }

  async getGroup(
    params: TaskActivityService.GetIn
  ): Promise<TaskActivityGroup> {
    return axios
      .get<TaskActivityService.GetOut>(`${api}/${params.groupCode}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async validate(
    validate: TaskActivityService.ValidateIn
  ): Promise<TaskActivity> {
    return axios
      .post<TaskActivityService.ValidateOut>(`${api}/${validate.id}`, validate)
      .then((res) => {
        return res.data;
      });
  }

  async activate(
    activate: TaskActivityService.ActivateIn
  ): Promise<TaskActivity> {
    return axios
      .post<TaskActivityService.ActivateOut>(`${api}/${activate.id}/activate`)
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const taskActivitiesService = new TaskActivitiesService();
