

















































































































































































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AccountingPeriod,
  AccountingPeriodDefinition,
  BankAccountsTransactionsService,
  getMoment,
  ProductsModel,
} from "@edmp/api";

import Confirm from "@/components/core/modals/Confirm.vue";
import CustomLabelButton from "@/components/atom/button/CustomLabelButton.vue";
import CancelButton from "@/components/atom/button/CancelButton.vue";
import SubmitButton from "@/components/atom/button/SubmitButton.vue";
import Transaction from "../transaction/Transaction.vue";
import { VConfirmDialog, VForm } from "@/models";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  transactionsStore,
} from "@/store";
import { transactionsService } from "@/services/Transactions.service";
import { useTransactions } from "../transactions.usable";
import { FilterKeys } from "@/utils";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "TransactionImportCreateDuplication",
  components: {
    Confirm,
    CustomLabelButton,
    CancelButton,
    SubmitButton,
    Transaction,
  },
  props: {
    bankAccountId: { type: String, required: true },
    file: { type: Object as PropType<File>, required: true },
    transactionIdsAlreadyImported: {
      type: Array as PropType<string[]>,
      required: true,
    },
    transactionsDuplicate: {
      type: Array as PropType<
        BankAccountsTransactionsService.ImportOut<false>["transactionsDuplicate"]
      >,
      required: false,
    },
    transactionsIgnored: {
      type: Array as PropType<
        BankAccountsTransactionsService.ImportOut<false>["transactionsIgnored"]
      >,
      required: false,
    },
  },
  setup(props, context) {
    const { addFilter } = useTransactions();

    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );

    const transactionsDuplicateLocal = ref(props.transactionsDuplicate || []);
    const transactionsIgnoredLocal = ref(props.transactionsIgnored || []);
    const duplicationResolves = ref<
      NonNullable<
        BankAccountsTransactionsService.ImportIn["document"]["duplicationResolves"]
      >
    >(
      transactionsDuplicateLocal.value.map((t) => ({
        transactionToImportId: t.transactionToImport.id,
        resolveOption: null as unknown as "import" | "noImport",
      })) || []
    );
    const importResponse = ref<
      BankAccountsTransactionsService.ImportOut | undefined
    >();
    const step = ref<number>(1);

    const duplicatePanels = ref<number[]>([]);
    const expandAllDuplicate = (isExpand: boolean) => {
      duplicatePanels.value = [];
      if (isExpand) {
        for (let index = 0; index < duplicationResolves.value.length; index++) {
          duplicatePanels.value.push(index);
        }
      }
    };
    const ignoredPanels = ref<number[]>([]);
    const expandAllIgnored = (isExpand: boolean) => {
      ignoredPanels.value = [];
      if (isExpand && transactionsIgnoredLocal.value) {
        for (
          let index = 0;
          index < transactionsIgnoredLocal.value.length;
          index++
        ) {
          ignoredPanels.value.push(index);
        }
      }
    };

    const importAll = () => {
      duplicationResolves.value = duplicationResolves.value.map((d) => ({
        ...d,
        resolveOption: "import",
      }));
    };
    const importNothing = () => {
      duplicationResolves.value = duplicationResolves.value.map((d) => ({
        ...d,
        resolveOption: "noImport",
      }));
    };

    function resetForm() {
      (context.refs.form as VForm).resetValidation();
    }
    async function finish(validateStep: boolean): Promise<void> {
      if (
        validateStep &&
        (props.transactionIdsAlreadyImported.length ||
          importResponse.value?.transactionIds.length)
      ) {
        importResponse.value = {
          status: true,
          transactionIds:
            importResponse.value?.transactionIds ||
            props.transactionIdsAlreadyImported,
        };

        // Display second step component
        step.value = 2;

        // Refresh Transaction
        transactionsStore.fetchTransactions({
          productId: productsStore.currentId,
          accountingPeriodId: accountingPeriodsStore.currentId,
        });
        addFilter(FilterKeys.ONLY_IMPORTED, {
          label: `Sources : Import`,
        });

        resetForm();
      } else {
        context.emit("finish");
      }
    }

    // ! Custom validation because if expansion panel is close `(context.refs.form as VForm).validate()` does not work
    const validateForm = async () => {
      const isValid = duplicationResolves.value.every(
        (d) => d.resolveOption !== null
      );
      if (!isValid) {
        for (let index = 0; index < duplicationResolves.value.length; index++) {
          const duplicationResolve = duplicationResolves.value[index];
          if (duplicationResolve.resolveOption === null) {
            duplicatePanels.value.push(index);
          }
        }

        const checkPanelsOpen = (timeout = 25) => {
          return new Promise<void>((resolve) => {
            let isPanelsOpen = duplicatePanels.value.every(
              (_, index) =>
                (
                  context.refs[`panel-${index}`] as unknown as {
                    isActive: boolean;
                  }
                ).isActive
            );
            if (!isPanelsOpen) {
              let timerEnd = false;
              const timer = setTimeout(() => (timerEnd = true), timeout);
              const retry = new Promise<void>((resFound) => {
                (function retryLoop(res) {
                  isPanelsOpen = duplicatePanels.value.every(
                    (_, index) =>
                      (
                        context.refs[`panel-${index}`] as unknown as {
                          isActive: boolean;
                        }
                      ).isActive
                  );
                  setTimeout(() => {
                    if (!timerEnd && !isPanelsOpen) retryLoop(res);
                    else res();
                  }, 500);
                })(resFound);
              });
              retry.then(() => {
                clearTimeout(timer);
                resolve();
              });
            } else {
              resolve();
            }
          });
        };

        await checkPanelsOpen();
        if ((context.refs.form as VForm).validate()) {
          return true;
        }
        return false;
      }
      return true;
    };

    async function validateFirstStep() {
      if (
        (context.refs.form as VForm).validate() &&
        duplicationResolves.value &&
        (await validateForm())
      ) {
        const accountingPeriod =
          accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod &
            AccountingPeriodDefinition;

        let openConfirm: boolean;
        if (
          duplicationResolves.value.some(
            (duplicationResolve) =>
              duplicationResolve.resolveOption === "import"
          )
        ) {
          openConfirm = await (
            context.refs.confirmDialog as VConfirmDialog
          ).open(
            `Êtes-vous sûr de vouloir importer toutes ces transactions ? `
          );
        } else {
          openConfirm = true;
        }

        if (openConfirm) {
          try {
            const res = await transactionsService.import({
              document: {
                accountingPeriodId: accountingPeriodsStore.currentId,
                bankAccountId: props.bankAccountId,
                startAt: accountingPeriod.startAt,
                endAt: accountingPeriod.endAt,
                duplicationResolves: duplicationResolves.value,
              },
              file: props.file,
            });
            importResponse.value = {
              ...res,
              transactionIds: [
                ...props.transactionIdsAlreadyImported,
                ...(importResponse.value?.transactionIds || []),
                ...res.transactionIds,
              ],
            };

            if (importResponse.value.status) {
              finish(true);
            } else {
              const duplicationResolvesToSet: {
                transactionToImportId: string;
                resolveOption: "import" | "noImport";
              }[] = [];
              for (const transactionDuplicate of importResponse.value
                .transactionsDuplicate) {
                const duplicationResolveIndex =
                  duplicationResolves.value.findIndex(
                    (d) =>
                      d.transactionToImportId ===
                      transactionDuplicate.transactionToImport.id
                  );
                if (duplicationResolveIndex === -1) {
                  duplicationResolvesToSet.push({
                    transactionToImportId:
                      transactionDuplicate.transactionToImport.id,
                    resolveOption: null as unknown as "import" | "noImport",
                  });
                } else {
                  if (transactionsDuplicateLocal.value) {
                    duplicationResolvesToSet.push({
                      ...duplicationResolves.value[duplicationResolveIndex],
                      resolveOption:
                        transactionDuplicate.transactionsConflict.length ===
                        transactionsDuplicateLocal.value[
                          duplicationResolveIndex
                        ].transactionsConflict.length
                          ? duplicationResolves.value[duplicationResolveIndex]
                              .resolveOption
                          : (null as unknown as "import" | "noImport"),
                    });
                  }
                }
              }
              transactionsDuplicateLocal.value =
                importResponse.value.transactionsDuplicate || [];
              duplicationResolves.value = duplicationResolvesToSet;

              transactionsIgnoredLocal.value =
                importResponse.value.transactionsIgnored.filter(
                  (t) =>
                    !transactionsIgnoredLocal.value.every(
                      (tLocal) => t.id === tLocal.id
                    )
                );

              duplicatePanels.value = [];
              ignoredPanels.value = [];

              if (
                !transactionsDuplicateLocal.value.length &&
                !transactionsIgnoredLocal.value.length
              ) {
                finish(true);
              } else {
                await validateForm();
                coreStore.displayFeedback({
                  type: FeedbackTypeEnum.WARNING,
                  timeout: 20000,
                  message:
                    "De nouveaux problèmes de transactions ont été détectés.",
                });
              }
            }
          } catch (e) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              timeout: 20000,
              message:
                "Ownily n'a pas trouvé de transactions dans votre fichier. Vérifiez son contenu ou contactez-nous via le tchat en bas à gauche ou via l'adresse contact@ownily.fr.",
            });
          }
        }
      }
    }

    return {
      step,
      transactionsDuplicateLocal,
      transactionsIgnoredLocal,
      duplicationResolves,
      importResponse,
      product,
      duplicatePanels,
      ignoredPanels,
      importAll,
      importNothing,
      expandAllDuplicate,
      expandAllIgnored,
      finish,
      getMoment,
      validateFirstStep,
      openArticle: () => useCrisp().openArticle(ArticleEnum.IMPORT_TRANSACTION),
    };
  },
});
