





























































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "NavigationSubItem",
  props: {
    name: String,
    icon: String,
    children: Array,
  },
  setup(props, context) {
    const currentRoute = computed(() => context.root.$route.name);

    function navigateTo(routeName: string) {
      context.emit("navigate", routeName);
    }

    return {
      currentRoute,
      navigateTo,
    };
  },
});
