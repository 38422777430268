




















import { TaskActivityGroupLocal, TaskActivityLocal } from "@/models";
import { OmitField, TaskActivityService, TaskActivityStatus } from "@edmp/api";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import TaskActivityGroup from "./group/TaskActivityGroup.vue";

export default defineComponent({
  name: "TaskActivityGroups",
  components: { TaskActivityGroup },
  props: {
    taskActivityGroups: {
      type: Array as PropType<TaskActivityGroupLocal[]>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivity: TaskActivityLocal,
          data?: OmitField<TaskActivityService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    const displayTaskActivityForFirstTaskGroupNotCompleted = computed(() =>
      props.taskActivityGroups.map((taskGroup, index, taskGroups) => {
        if (
          taskGroups.every(
            (taskGroup) => taskGroup.status === TaskActivityStatus.WAITING
          )
        ) {
          return false;
        }
        if (taskGroup.status !== TaskActivityStatus.COMPLETED) {
          if (index !== 0) {
            return (
              taskGroups[index - 1].status === TaskActivityStatus.COMPLETED &&
              !taskGroup.isDisabled
            );
          }
          return true;
        }
        return false;
      })
    );

    return {
      displayTaskActivityForFirstTaskGroupNotCompleted,
    };
  },
});
