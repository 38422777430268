



































import { defineComponent } from "@vue/composition-api";

/**
 * @Component GenericButton
 * @prop {Boolean} v-model, default: false, required, bind and update `value` prop
 * @prop {Boolean} disabled, default: false
 * @prop {String} onText, required
 * @prop {String} offText, required
 */
export default defineComponent({
  name: "GenericButton",
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    onText: {
      type: String,
      required: true,
    },
    offText: {
      type: String,
      required: true,
    },
  },
  model: {
    prop: "value",
    event: "click",
  },
});
