


































































import { usersService } from "@/services";
import { coreStore, usersStore } from "@/store";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "AcceptCgu",
  setup() {
    const goToCgu = () => {
      window.open(
        `https://www.ownily.fr/legal/conditions-generales-utilisation`,
        "_blank"
      );
    };

    const isLoading = ref(false);
    const validateCgu = async () => {
      try {
        isLoading.value = true;
        await usersService.acceptTos({
          id: "me",
          tos: { version: coreStore.config.user.tosVersion, accepted: true },
        });
        await usersStore.fetchLoggedInUser();
        isLoading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      goToCgu,
      validateCgu,
    };
  },
});
