import { Schema } from "mongoose";
import { Address, addressSchema } from "./Common.model";

/**
 * `Cga` — Contains CGA info
 */
export interface Cga {
  name?: string;
  agreementNumber?: number;
  address?: Address;
}

export const cgaSchema = new Schema({
    name: {type: String, required: false},
    agreementNumber: {type: Number, required: false},
    address: addressSchema,
})