











































import { computed, defineComponent, ref } from "@vue/composition-api";
import { ROUTE_NAMES } from "@/router/routes";
import { realEstateAssetsStore, rentalAgreementsStore } from "@/store";
import RentalAgreementRentCard from "@/components/core/rentalAgreements/RentalAgreementRentCard.vue";

export default defineComponent({
  name: "RealEstateRentsTabs",
  components: {
    RentalAgreementRentCard,
  },
  setup(props, context) {
    const openDialog = ref(false);

    const addOptions = [
      { title: "Nouveau bail", value: "new" },
      { title: "Bail existant", value: "exist" },
    ];

    const redirectToRents = () => {
      context.root.$router.push({
        name: ROUTE_NAMES.RealEstateRents,
        params: {
          rentalAgreementId: "new",
        },
      });
    };

    const onCreateTypeChange = () => {
      redirectToRents();
    };

    const filledFromOwnily = () => {
      openDialog.value = false;
      redirectToRents();
    };

    const notFilledFromOwnily = () => {
      openDialog.value = false;
      window.location.href = "https://www.ownily.fr";
    };

    const realEstateAsset = computed(() =>
      realEstateAssetsStore.getRealEstateAsset(
        context.root.$route.params.realEstateAssetId
      )
    );

    const rentalAgreements = computed(() =>
      rentalAgreementsStore.getRentalAgreementsByRealEstateAssetId(
        context.root.$route.params.realEstateAssetId
      )
    );

    return {
      addOptions,
      onCreateTypeChange,
      openDialog,
      filledFromOwnily,
      notFilledFromOwnily,
      realEstateAsset,
      rentalAgreements,
    };
  },
});
