<script>
import { Pie } from "vue-chartjs";
import * as chroma from "chroma-js";
import i18n from "@/plugins/i18n";
import { formatLabel } from "@/utils";

export default {
  name: "BalanceDetailsChart",
  extends: Pie,
  props: {
    categories: {
      type: Object,
    },
    baseColor: {
      type: Array,
      default: () => ["#F3B414", "#FFEB58"],
    },
  },
  mounted() {
    this.render();
  },
  watch: {
    categories: {
      handler: function () {
        this.render();
      },
      deep: true,
    },
  },
  methods: {
    render: function () {
      const chartData = Object.entries(this.categories).sort((c1, c2) =>
        c1[1] > c2[1] ? -1 : 1
      );
      this.renderChart(
        {
          labels: [
            ...chartData
              .filter((c) => c[0] !== "Non catégorisé")
              .map((c) => c[0]),
            "Non catégorisé",
          ],
          datasets: [
            {
              data: [
                ...chartData
                  .filter((c) => c[0] !== "Non catégorisé")
                  .map((c) => c[1]),
                ...chartData
                  .filter((c) => c[0] === "Non catégorisé")
                  .map((c) => c[1]),
              ],
              backgroundColor:
                Object.keys(this.categories).filter(
                  (c) => c !== "Non catégorisé"
                ).length > 0
                  ? [
                      ...chroma
                        .scale(this.baseColor)
                        .mode("lch")
                        .colors(
                          Object.keys(this.categories).filter(
                            (c) => c !== "Non catégorisé"
                          ).length
                        ),
                      "#EDEDED",
                    ]
                  : ["#EDEDED"],
              borderColor: [
                ...Object.keys(this.categories).map(
                  () => "rgba(255, 255, 255 ,8)"
                ),
              ],
              borderWidth: 2,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label(tooltipItem, data) {
                const label = data.labels[tooltipItem.index];
                const value = i18n.n(
                  data.datasets[0].data[tooltipItem.index],
                  "currency-no-decimal",
                  "fr-FR"
                );
                return formatLabel(`${label}: ${value}`, 40);
              },
            },
          },
        }
      );
    },
  },
};
</script>
