




































import { subscriptionsStore } from "@/store";
import { computed, defineComponent } from "@vue/composition-api";
import { useSubscription } from "../subscriptions/subscription.usable";

export default defineComponent({
  name: "DialogSubscribe",
  components: {},
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const subscription = computed(
      () => subscriptionsStore.getCurrentSubscription
    );

    const goSubscribe = async () => {
      if (subscription.value) {
        const { goSubscriptionUpdate } = useSubscription(subscription.value.id);
        await goSubscriptionUpdate();
      }
      context.emit("update:openModal", false);
    };

    return { goSubscribe };
  },
});
