import { mimeTypes } from "@edmp/api";
import { DocumentFileType } from "@edmp/api";

export function downloadFileFromUrl(url: string, fileName: string) {
  // create a download anchor tag
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  link.setAttribute("download", fileName);
  link.target = "_blank";
  // append the anchor to document body
  document.body.appendChild(link);

  // fire a click event on the anchor
  link.click();
  // window.open(url, "_blank");

  // cleanup: remove element and revoke object URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function downloadFile(
  data: BlobPart,
  fileName: string,
  fileType: DocumentFileType
) {
  // convert downloaded data to a Blob
  const blob = new Blob([data], { type: mimeTypes[fileType] });
  // if (browser() === 'IE') {
  //  window.navigator.msSaveOrOpenBlob(blob, fileName);
  // }
  // create an object URL from the Blob
  const URL = window.URL || window.webkitURL;
  const url = URL.createObjectURL(blob);

  downloadFileFromUrl(url, fileName);
}
