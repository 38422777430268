





































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
} from "@vue/composition-api";
import {
  accountingBalanceSheetsStore,
  subscriptionsStore,
  accountingPeriodsStore,
} from "@/store";
import eventBus from "@/events/eventBus";
import moment from "moment";
import { cloneDeep } from "lodash";
import { AccountingBalanceSheet } from "@edmp/api";

export default defineComponent({
  name: "BalanceLine512",
  props: {
    targetedAccountingBalanceSheet: {
      type: Object,
      required: true,
    },
    previousYear: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const injectBalanceField: Ref<HTMLElement | null> = ref(null);
    const isEditing = ref(false);
    const isLoading = ref(false);
    const tempTotalSum = ref("");
    const targetedYear = computed(() => {
      if (accountingPeriodsStore.currentAccountingPeriod) {
        if (props.previousYear) {
          return (
            new Date(
              accountingPeriodsStore.currentAccountingPeriod?.endAt
            ).getFullYear() - 1
          );
        } else {
          return new Date(
            accountingPeriodsStore.currentAccountingPeriod?.endAt
          ).getFullYear();
        }
      }
    });

    const totalSum = computed(() => {
      return props.targetedAccountingBalanceSheet.lines
        .find((line) => {
          return line.account.startsWith("512000");
        })
        .amount.toString();
    });

    function cancelUpdate() {
      isEditing.value = false;
      tempTotalSum.value = "";
    }

    async function confirmUpdate() {
      isEditing.value = false;

      const tempTargetedAccountingBalanceSheet = cloneDeep(
        props.targetedAccountingBalanceSheet
      );
      tempTargetedAccountingBalanceSheet.lines.forEach((line) => {
        // For some customers we must target account 512000, for others it's account 5120001
        if (line.account === ("512000" || "5120001")) {
          line.amount = parseInt(tempTotalSum.value);
        }
      });
      isLoading.value = true;
      await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
        tempTargetedAccountingBalanceSheet as AccountingBalanceSheet
      );
      isLoading.value = false;
    }

    async function init() {
      eventBus.$on("inject-balance", () => {
        if (props.targetedAccountingBalanceSheet.type === "recovery")
          isEditing.value = true;
      });
    }

    onBeforeMount(async () => {
      await init();
    });

    return {
      isEditing,
      cancelUpdate,
      confirmUpdate,
      moment,
      totalSum,
      tempTotalSum,
      isLoading,
      subscriptionsStore,
      accountingPeriodsStore,
      injectBalanceField,
      targetedYear,
    };
  },
});
