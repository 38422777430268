


































import { defineComponent, onBeforeMount, watch } from "@vue/composition-api";
import DocumentsHeader from "./DocumentsHeader.vue";
import DocumentCard from "./DocumentCard.vue";
import { useDocuments } from "./useDocuments";
import { accountingPeriodsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "NewDocuments",
  components: {
    DocumentsHeader,
    DocumentCard,
  },
  setup() {
    const { documents, getProductDocuments } = useDocuments();

    async function init() {
      documents.value = [];
      await getProductDocuments();
    }
    onBeforeMount(() => init());

    watch(
      () => accountingPeriodsStore.currentId,
      () => init(),
      { deep: true }
    );

    return { ROUTE_NAMES, documents, getProductDocuments };
  },
});
