




































































import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import { SubscriptionsModel } from "@edmp/api";
import SubscriptionCard from "./list/SubscriptionCard.vue";
import SubscriptionsPlan from "./SubscriptionsPlan.vue";
import Title from "../title/Title.vue";
import {
  HelpingView,
  HelpingMessage,
  HelpingItem,
} from "@/components/atom/helping";
import ActivityCreateInfosModal from "../activities/ActivityCreateInfosModal.vue";
import SubscriptionDeductibility from "./SubscriptionDeductibility.vue";
import { coreStore, subscriptionsStore } from "@/store";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

export default defineComponent({
  name: "Subscriptions",
  components: {
    Title,
    SubscriptionCard,
    SubscriptionsPlan,
    HelpingMessage,
    HelpingView,
    ActivityCreateInfosModal,
    SubscriptionDeductibility,
  },
  props: {
    subscriptionId: { type: String, required: false },
    stripeReturnStatus: {
      type: String as PropType<
        | SubscriptionsModel.PaymentStatusParams.succeeded
        | SubscriptionsModel.PaymentStatusParams.canceled
        | "portal"
      >,
      required: false,
    },
  },

  setup() {
    /**
     * Data
     */
    const isLoading: Ref<boolean> = ref(false);
    const subscriptions = computed(() =>
      subscriptionsStore.subscriptions.filter(
        ({ status }) => status !== SubscriptionsModel.SubscriptionStatus.pending
      )
    );

    const openActivityCreateInfosModal = ref(false);
    const testPermissionCreate = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "add",
          subject("Product", {})
        );
        return true;
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
        return false;
      }
    };
    const goCreate = () => {
      if (testPermissionCreate()) {
        openActivityCreateInfosModal.value = true;
      }
    };

    const helpingItems: HelpingItem[] = [
      {
        title: `Comment souscrire à un abonnement Ownily ?`,
        icon: "mdi-cart-plus",
        url: `https://ownily.crisp.help/fr/article/comment-souscrire-a-un-abonnement-ownily-bxsnkq/`,
      },
      {
        title: `Comment modifier mon abonnement ?`,
        icon: "mdi-circle-edit-outline",
        url: `https://ownily.crisp.help/fr/article/comment-modifier-mon-abonnement-10zkwd5/`,
      },
      {
        title: `L'abonnement Ownily est-il déductible des charges de la SCI ?`,
        icon: "mdi-currency-eur",
        url: `https://ownily.crisp.help/fr/article/labonnement-ownily-est-il-deductible-des-charges-de-la-sci-1qab7yd/`,
      },
    ];

    return {
      isLoading,
      subscriptions,
      goCreate,
      openActivityCreateInfosModal,
      openArticleSubscriptionCondition: () =>
        useCrisp().openArticle(ArticleEnum.SUBSCRIPTION_CONDITION),
      helpingItems,
    };
  },
});
