









import { TaskActivityLocal } from "@/models";
import { accountingBalanceSheetsStore } from "@/store";
import { tasksNeedingBalanceSheetFetch } from "@/utils";
import { OmitField, TaskActivityService } from "@edmp/api";
import { defineComponent, onBeforeMount, PropType } from "@vue/composition-api";
import TaskActivity from "./TaskActivity.vue";

export default defineComponent({
  name: "TaskActivityCard",
  components: {
    TaskActivity,
  },
  props: {
    taskActivityLocal: {
      type: Object as PropType<TaskActivityLocal>,
      required: true,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivity: TaskActivityLocal,
          data?: OmitField<TaskActivityService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      if (
        tasksNeedingBalanceSheetFetch.includes(props.taskActivityLocal.code)
      ) {
        await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      }
    });
    return {};
  },
});
