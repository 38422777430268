import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { TypeReference } from "./JournalComposedEntry";

export type ReferenceCounter = {
  type: TypeReference;
  referredId: string;
  counter: number;
};

export type ReferenceCounters = {
  id: string;
  productId: string;
  counters: ReferenceCounter[];
};

const referenceCounterSchema = new Schema<ReferenceCounterDocument>(
  {
    type: {
      type: String,
      required: true,
      enum: Object.values(TypeReference), // Mongoose doesn't support Typescript ENUM
    },
    referredId: { type: String, required: true },
    counter: { type: String, required: true },
  },
  {
    timestamps: true,
    _id: false,
  }
);
const referenceCountersSchema = new Schema<ReferenceCountersDocument>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, index: true },
    counters: [referenceCounterSchema],
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: ReferenceCountersDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
// TODO Key company + type + referredID
export type ReferenceCounterDocument = ReferenceCounter & Document<string>;
export type ReferenceCountersDocument = ReferenceCounters & Document<string>;
// Name of the collection in third argument
export const ReferenceCountersModel = model<ReferenceCountersDocument>(
  "ReferenceCounters",
  referenceCountersSchema,
  "ReferenceCounters"
);
