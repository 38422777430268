import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { Amortisation } from "./Amortisation.model";
import { RequireField } from "./Common.model";

export interface FixedAssetAmortisation extends Amortisation {
  fixedAssetId: string;
  createdAt: string;
  updatedAt: string;
}
export type FixedAssetAmortisationCreate = Omit<FixedAssetAmortisation, "id" | "productId" | "createdAt" | "updatedAt">;
export type FixedAssetAmortisationCreateInternal = Omit<FixedAssetAmortisation, "id" | "createdAt" | "updatedAt">;
export type FixedAssetAmortisationList = Partial<
  Pick<FixedAssetAmortisation, "productId" | "fixedAssetId" | "accountingPeriodIdAllowedToEdit">
>;
export type FixedAssetAmortisationListInternal = FixedAssetAmortisationList & {
  productIds?: string[];
};
export type FixedAssetAmortisationUpdate = RequireField<
  Partial<
    Omit<
      FixedAssetAmortisation,
      "productId" | "fixedAssetId" | "accountingPeriodIdAllowedToEdit" | "createdAt" | "updatedAt"
    >
  >,
  "id"
>;
export type FixedAssetAmortisationUpdateInternal = RequireField<
  Partial<Omit<FixedAssetAmortisation, "createdAt" | "updatedAt">>,
  "id"
>;

const fixedAssetAmortisationSchema = new Schema<FixedAssetAmortisation>(
  {
    _id: { type: String, default: () => ulid() },
    fixedAssetId: { type: String, index: true, required: true },
    productId: { type: String, index: true, required: true },
    accountingPeriodIdAllowedToEdit: { type: String, index: true, required: true },
    type: { type: String, required: true },
    share: { type: Number },
    value: { type: Number },
    startAt: { type: String },
    durationInYear: { type: Number },
    rate: { type: Number },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: FixedAssetAmortisationSchema) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
export type FixedAssetAmortisationSchema = FixedAssetAmortisation & Document<string>;

export type FixedAssetAmortisationDocument = FixedAssetAmortisation & Document<string>;
// Name of the collection in third argument
export const FixedAssetAmortisationModel = model<FixedAssetAmortisationDocument>(
  "FixedAssetAmortisation",
  fixedAssetAmortisationSchema,
  "FixedAssetAmortisation"
);

// API
export namespace FixedAssetAmortisationsService {
  export type CreateIn = FixedAssetAmortisationCreate;
  export type CreateOut = FixedAssetAmortisation;

  export type ListIn = FixedAssetAmortisationList;
  export type ListOut = FixedAssetAmortisation[];

  export type GetIn = Pick<FixedAssetAmortisation, "id">;
  export type GetOut = FixedAssetAmortisation;

  export type UpdateIn = FixedAssetAmortisationUpdate;
  export type UpdateOut = FixedAssetAmortisation;

  export type DeleteIn = Pick<FixedAssetAmortisation, "id">;
  export type DeleteOut = boolean;
}
