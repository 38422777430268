import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { fixedAssetsService } from "@/services";
import { FixedAsset, FixedAssetsService } from "@edmp/api";
import Vue from "vue";

export interface FixedAssetsState {
  fixedAssets: FixedAsset[];
  loading: boolean;
}

@Module({
  name: "fixed-assets-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class FixedAssetsStore extends VuexModule implements FixedAssetsState {
  fixedAssets: FixedAsset[] = [];
  loading = false;

  @Mutation
  reset(): void {
    this.fixedAssets = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Mutation
  setFixedAsset(fixedAsset: FixedAsset) {
    const index = this.fixedAssets.findIndex(({ id }) => id == fixedAsset.id);
    if (index !== -1) {
      Vue.set(this.fixedAssets, index, fixedAsset);
    } else {
      this.fixedAssets.push(fixedAsset);
    }
  }

  @Action
  async fetchFixedAssets(productId: string) {
    this.setLoading(true);
    const fixedAssets = await fixedAssetsService.list({ productId });
    for (const fixedAsset of fixedAssets) {
      this.setFixedAsset(fixedAsset);
    }
    this.setLoading(false);
    return fixedAssets;
  }

  @Action
  async createFixedAsset(fixedAssetCreate: FixedAssetsService.CreateIn) {
    this.setLoading(true);
    const newFixedAsset = await fixedAssetsService.create(fixedAssetCreate);
    this.setFixedAsset(newFixedAsset);
    this.setLoading(false);
    return newFixedAsset;
  }

  @Action
  async fetchFixedAsset(params: FixedAssetsService.GetIn) {
    this.setLoading(true);
    const fixedAsset = await fixedAssetsService.get(params);
    this.setFixedAsset(fixedAsset);
    this.setLoading(false);
    return fixedAsset;
  }

  get getFixedAsset() {
    return (fixedAssetId?: string | null) =>
      this.fixedAssets.find(({ id }) => id === fixedAssetId);
  }

  get getFixedAssetsByRealEstateAssetId() {
    return (realEstateAssetId: string | null) => {
      if (!realEstateAssetId) return [];
      return this.fixedAssets.filter((fixedAsset) =>
        fixedAsset.realEstateAssetIds.includes(realEstateAssetId)
      );
    };
  }

  @Action
  async updateFixedAsset(fixedAssetUpdate: FixedAssetsService.UpdateIn) {
    this.setLoading(true);
    const updatedFixedAsset = await fixedAssetsService.update(fixedAssetUpdate);
    this.setFixedAsset(updatedFixedAsset);
    this.setLoading(false);
    return updatedFixedAsset;
  }
}
