




























































































import { ProductsModel, SubscriptionsModel, TaxRegime } from "@edmp/api";

import {
  defineComponent,
  computed,
  WritableComputedRef,
  ref,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import { ForbiddenError, subject } from "@casl/ability";

import {
  productsStore,
  coreStore,
  subscriptionsStore,
  usersStore,
  realEstateAssetsStore,
} from "@/store";
import { ability } from "@/services";
import { useNavigationItems } from "./navigation.usable";
import { accountingPeriodsStore } from "@/store";

import NavigationItem from "./NavigationItem.vue";
import NavigationSubItem from "./NavigationSubItem.vue";
import ActivityCreateInfosModal from "../activities/ActivityCreateInfosModal.vue";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ROUTE_NAMES } from "@/router/routes";
import eventBus from "@/events/eventBus";

export default defineComponent({
  name: "ItemsNavigation",
  components: {
    NavigationItem,
    NavigationSubItem,
    ActivityCreateInfosModal,
  },
  props: {
    mobileDisplay: {
      type: Boolean,
    },
  },
  // `Item2 ${id.length > 8 ? id.substring(0, 6) + "..." : id}`,
  setup(_, context) {
    const product: WritableComputedRef<ProductsModel.Product | undefined> =
      computed({
        get: () => productsStore.currentProduct,
        set: async (product) => {
          if (product) {
            await productsStore.switchProduct({ id: product.id });
            if (
              productsStore.currentProduct?.lastTaxRegime ===
              TaxRegime.LMNP_2031
            ) {
              context.root.$router.push({ name: ROUTE_NAMES.MyBusiness });
            }

            if (
              productsStore.currentProduct?.status ===
              ProductsModel.ProductStatus.pending
            ) {
              context.root.$router.push({ name: ROUTE_NAMES.ActivityContinue });
            }
          }
        },
      });

    const { NAVIGATION_TITLES, taxRegimeItems } = useNavigationItems();

    const menuDisabled = ref(
      accountingPeriodsStore.isLMNP &&
        realEstateAssetsStore.realEstateAssets.length === 0
    );
    const handleMenuDisabled = (value) => {
      menuDisabled.value = value;
    };

    function navigate(routeName: string): void {
      context.root.$router.push({ name: routeName });
      context.emit("close");
    }

    const openActivityCreateInfosModal = ref(false);

    function testPermissionCreate() {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "add",
          subject("Product", {})
        );
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
        return false;
      }
      return true;
    }

    const goCreate = () => {
      if (testPermissionCreate()) {
        openActivityCreateInfosModal.value = true;
      }
    };

    const filteredNavigationItems = computed(() => {
      const tabTemp = taxRegimeItems.value;
      let stock = false;
      let whereStart = false;
      if (
        subscriptionsStore.getSubscriptionByProduct(productsStore.currentId)
          ?.plan.type === SubscriptionsModel.PlanType.Solo
      ) {
        tabTemp.forEach((item, index) => {
          if (item.name === NAVIGATION_TITLES.value.RentalAgreements) {
            tabTemp.splice(index, 1);
          }
        });
      } else {
        tabTemp.forEach((item) => {
          if (item.name === NAVIGATION_TITLES.value.TaskActivity) {
            whereStart = true;
          }
          if (item.name === NAVIGATION_TITLES.value.RentalAgreements) {
            stock = true;
          }
        });
        if (!currentAccountingPeriodIsLMNP) {
          if (!stock && whereStart) {
            tabTemp.splice(4, 0, {
              name: NAVIGATION_TITLES.value.RentalAgreements,
              icon: "mdi-account-key-outline theme--light",
              routeName: "RentalAgreements",
            });
          } else if (!stock) {
            tabTemp.splice(3, 0, {
              name: NAVIGATION_TITLES.value.RentalAgreements,
              icon: "mdi-account-key-outline theme--light",
              routeName: "RentalAgreements",
            });
          }
        }
      }

      return tabTemp;
    });

    const currentAccountingPeriodIsLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    onMounted(() => {
      eventBus.$on("menuDisabled", handleMenuDisabled);
    });

    onUnmounted(() => {
      eventBus.$off("menuDisabled", handleMenuDisabled);
    });
    return {
      ROUTE_NAMES,
      filteredNavigationItems,
      loggedInUser: usersStore.loggedInUser,
      userAccount: usersStore.loggedInUserAccount,
      currentRoute: computed(() => context.root.$route.name),
      products: computed(() => productsStore.products),
      product,
      navigate,
      goCreate,
      openActivityCreateInfosModal,
      currentAccountingPeriodIsLMNP,
      menuDisabled,
    };
  },
});
