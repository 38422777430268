import { Document, model, Schema, ToObjectOptions } from "mongoose";
import { ulid } from "ulid";
import { Stripe } from "stripe";
import { PartialField, RequireField, TaxRegime } from "..";

export namespace SubscriptionsModel {
  /**
   * Stripe
   */
  // In
  export type CheckoutSessionOptions<Mode extends "subscription" | "payment" = "subscription" | "payment"> =
    Stripe.Checkout.SessionCreateParams & {
      customer: Stripe.Customer["id"] | string;
      mode: Mode;
      line_items: [{ price: Stripe.Price["id"]; quantity: 1 }];
      discounts?: [{ coupon?: Stripe.Checkout.SessionCreateParams.Discount["coupon"] }];
      allow_promotion_codes?: true;
      automatic_tax: { enabled: true };
      expires_at: Stripe.Checkout.SessionCreateParams["expires_at"];
      success_url: Stripe.Checkout.SessionCreateParams["success_url"];
      cancel_url: Stripe.Checkout.SessionCreateParams["cancel_url"];
    } & (Mode extends "subscription"
        ? {
            subscription_data: {
              metadata: { userId: string; productId: string; subscriptionId: string };
            };
            metadata: { userId: string; productId: string; subscriptionId: string };
            payment_method_types: [PaymentMethodTypeParams];
          }
        : Mode extends "payment"
        ? {
            payment_method_types?: [PaymentMethodTypeParams];
            metadata: { createdByApp: string };
          }
        : never);

  //Out
  export interface ProductOut {
    id: string;
    type: PlanTypeParams;
    name: string;
    description: string | null;
    taxRate: {
      id?: string;
      percentage: number;
    };
    prices: PriceOut[];
  }

  export interface PriceOut {
    id: string;
    name: string;
    durationType: DurationTypeParams;
    amount: number;
  }

  export interface MultiProductsDiscountOut {
    id: string;
    name: string | null;
    metadata: { multiCompanies: boolean };
    percent_off: number;
    available: number;
    applies_to: {
      products: { id: string; planType: PlanTypeParams }[];
    };
    created: number; 
  }

  // Internal
  interface Prices extends Stripe.Price {
    coupon: Partial<Stripe.Coupon> & { available: number };
    metadata: {
      coupon: Stripe.Coupon["id"];
    };
  }

  export interface Product extends Stripe.Product {
    taxRate: Stripe.TaxRate;
    prices: Prices[];
    metadata: {
      type: PlanTypeParams;
      taxRegime: TaxRegime;
      taxRate: Stripe.TaxRate["id"];
    };
  }

  export interface PortalSessionConfigCreateParams extends Stripe.BillingPortal.ConfigurationCreateParams {
    metadata: { taxRegime: TaxRegime };
  }
  export interface PortalSessionConfig extends Stripe.BillingPortal.Configuration {
    metadata: { taxRegime: TaxRegime };
  }

  // Expand Stripe
  export interface SubscriptionStripe extends Stripe.Subscription {
    latest_invoice: Stripe.Invoice & {
      payment_intent: Stripe.PaymentIntent;
    };
    plan: Stripe.Plan & {
      product: Stripe.Product;
    };
    discount:
      | (Stripe.Discount & {
          coupon: Stripe.Coupon & {
            metadata: (Stripe.Metadata & { multiCompanies?: "true" }) | null;
          };
        })
      | null;
    metadata: {
      userId: string;
      productId: string;
      subscriptionId: string;
    };
  }

  /**
   * Subscription
   */
  // In
  export interface SubscriptionUpdateBody {
    id: string;
    plan: PlanParams;
    payment: PaymentParams;
  }

  export interface SubscriptionCancelBody {
    id: string;
    cancelAtPeriodEnd: boolean; // True to Cancel at end of period / False to ReActivate a previous cancel
    cancelReason?: string;
    retentionPeriod?: number; // number of days of retention (else CNIL compliant duration)
  }

  export interface PlanParams {
    type: PlanTypeParams;
    durationType: DurationTypeParams;
    couponUse: boolean;
  }

  export interface PaymentParams {
    paidByUserId?: string;
    paymentMethod: PaymentMethodTypeParams;
  }

  export enum PlanTypeParams {
    Solo = "Solo",
    Basic = "Basic",
    Premium = "Premium",
    Optimum = "Optimum",
    LMNP = "LMNP"
  }

  export enum DurationTypeParams {
    month = "month",
    year = "year",
  }

  export enum PaymentMethodTypeParams {
    sepaDebit = "sepa_debit",
    card = "card",
  }

  export enum PaymentStatusParams {
    processing = "processing",
    succeeded = "succeeded",
    canceled = "canceled",
  }

  // Response
  export interface SubscriptionCheckUpdatePermissionResponse {
    allow: boolean;
  }

  // Internal
  export interface Subscription {
    id: string;
    productId: string;
    status: SubscriptionStatus;
    statusStripe?: SubscriptionStatusStripe;
    stripeId?: string;
    plan: Plan;
    payment: Payment;
    invoiceUpcoming: Invoice;

    firstStartAt: string; // Date when the subscription was first created in Stripe.
    startAt: string; // Start of the current period that the subscription has been invoiced for.
    endAt: string; // End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created.

    suspend: boolean;
    suspendAt?: string;
    // Cancel a subscription (https://stripe.com/docs/billing/subscriptions/cancel)

    cancelAtPeriodEnd?: boolean; // Cancel at end of subscription period
    cancelAt?: string; // Date of cancellation
    canceledAt?: string; // If the subscription has been cancelled, the date of this cancellation. Else it will reflect the time of the most recent update request of cancel
    cancelReason?: string;

    retentionPeriod?: number; // number of days of retention
    waiverFreePeriod?: boolean; // if <code>true</code>, then user waiver Free period to subscribe

    createdAt: string; // Time at which the object was created (with company)
    updatedAt: string;
  }

  export type SubscriptionCreate = Omit<
    Subscription,
    | "id"
    | "statusStripe"
    | "stripeId"
    | "firstStartAt"
    | "suspendAt"
    | "cancelAt"
    | "canceledAt"
    | "cancelReason"
    | "retentionPeriod"
    | "waiverFreePeriod"
    | "createdAt"
    | "updatedAt"
  >;

  export type SubscriptionUpdateMock = Pick<Subscription, "id" | "status"> & {
    plan: Omit<Subscription["plan"], "type" | "durationType"> & {
      type: PlanType;
      durationType: DurationType;
    };
    payment: PartialField<Pick<Subscription["payment"], "paidByUserId">, "paidByUserId"> & {
      paymentMethod: PaymentMethodTypeParams;
    };
  };

  export interface Plan {
    type: PlanType;
    durationType: DurationType;
    couponUse: boolean;
  }

  export interface Payment {
    paidByUserId: string;
    status: PaymentStatus;
    statusStripe?: PaymentStatusStripe;
    statusInvoice?: InvoiceStatus;
    paymentMethod: PaymentMethodType;
    sessionCheckoutId?: Stripe.Checkout.Session["id"];
  }

  export interface Invoice {
    invoiceNo: string | null;
    status: InvoiceStatus | null;
    currency: string;
    amount: number;
    amountTax: number;
    amountDiscount: number;
    amountTotal: number;
    nextPaymentAt: string | null;
    createdAt: string | null;
    uncollectibleAt: string | null;
    paidAt: string | null;
    voidAt: string | null;

    /**
     * The link to download the PDF for the invoice. If the invoice has not been finalized yet, this will be null.
     */
    invoicePdf?: string | null;
  }

  // List of invoices
  export interface Invoices {
    data: Invoice[];
  }

  export type OldSubscription = { planType: PlanType; status: SubscriptionStatus };

  export enum SubscriptionStatus {
    pending = "pending", // For 2nd subscription
    active = "active", // For active subscription
    suspend = "suspend", // For suspend subscription
    error = "error", // For error subscription
    end = "end", // For terminate subscription
  }

  // Stripe.Subscription.Status
  export enum SubscriptionStatusStripe {
    active = "active",
    canceled = "canceled",
    incomplete = "incomplete",
    incomplete_expired = "incomplete_expired",
    past_due = "past_due",
    trialing = "trialing",
    unpaid = "unpaid",
  }

  export enum PlanType {
    Free = "Free",
    Solo = "Solo",
    Basic = "Basic",
    Premium = "Premium",
    Optimum = "Optimum",
    Test = "Test",
    LMNP = "LMNP",
  }

  export enum DurationType {
    day = "day",
    month = "month",
    year = "year",
  }

  export enum PaymentStatus {
    noSend = "noSend",
    toSend = "toSend",
    sent = "sent",
    processing = "processing",
    succeeded = "succeeded",
    canceled = "canceled",
    failed = "failed",
  }

  export enum PaymentMethodType {
    sepaDebit = "sepa_debit",
    card = "card",
    unknown = "unknown",
  }

  export enum InvoiceStatus {
    deleted = "deleted",
    draft = "draft",
    open = "open",
    paid = "paid",
    uncollectible = "uncollectible",
    void = "void",
  }

  export enum PaymentStatusStripe {
    canceled = "canceled",
    processing = "processing",
    requires_action = "requires_action",
    requires_capture = "requires_capture",
    requires_confirmation = "requires_confirmation",
    requires_payment_method = "requires_payment_method",
    succeeded = "succeeded",
  }

  // BDD
  const subscriptionSchema = new Schema<SubscriptionDocument>(
    {
      _id: { type: String, default: (): string => ulid() },
      productId: { type: String },
      stripeId: { type: String, index: true },
      status: { type: String, enum: Object.values(SubscriptionStatus) },
      statusStripe: { type: String, enum: Object.values(SubscriptionStatusStripe) },
      plan: {
        type: { type: String, enum: Object.values(PlanType) },
        description: { type: String },
        durationType: { type: String },
        couponUse: { type: Boolean },
      },
      payment: {
        paidByUserId: { type: String },
        amountPaid: { type: Schema.Types.Decimal128 },
        status: { type: String },
        statusStripe: { type: String, enum: Object.values(PaymentStatusStripe) },
        statusInvoice: { type: String, enum: Object.values(InvoiceStatus) },
        paymentMethod: { type: String, enum: Object.values(PaymentMethodType) },
        sessionCheckoutId: { type: String },
      },
      invoiceUpcoming: {
        currency: { type: String },
        amount: { type: Number },
        amountTax: { type: Number },
        amountDiscount: { type: Number },
        amountTotal: { type: Number },
        nextPaymentAt: { type: Date },
      },
      suspend: { type: Boolean },
      firstStartAt: { type: Date },
      startAt: { type: Date },
      endAt: { type: Date },
      suspendAt: { type: Date },
      cancelAtPeriodEnd: { type: Boolean },
      cancelAt: { type: Date, default: null },
      canceledAt: { type: Date, default: null },
      cancelReason: { type: String },
      retentionPeriod: { type: Number }, // number of days of retention
      waiverFreePeriod: { type: Boolean },
    },
    {
      timestamps: true,
      toJSON: {
        versionKey: false,
        virtuals: true,
        transform(
          doc: Omit<SubscriptionDocument, "createdAt" | "updatedAt"> & {
            _id: string;
            createdAt: Date;
            updatedAt: Date;
          },
          ret: Subscription & { _id?: string },
          options: ToObjectOptions
        ): Omit<Subscription, "_id"> {
          ret.id = doc._id;
          delete ret._id;
          return ret;
        },
      },
    }
  );

  export type SubscriptionDocument = Subscription & Document<string>;

  export const SubscriptionModel = model<SubscriptionDocument>("Subscription", subscriptionSchema, "Subscriptions");
}

/**
 * * API
 */
export namespace StripeService {
  export type WebhookIn = {
    stripeEvent: Stripe.Event;
  };
  export type WebhookOut = { received: true } | { error: unknown };

  export type ListProductsIn = { taxRegime: TaxRegime };
  export type ListProductsOut = SubscriptionsModel.ProductOut[];

  export type CreatePortalSessionIn = { id: string; redirectUri: string; productId: string };
  export type CreatePortalSessionOut = Stripe.Response<Stripe.BillingPortal.Session>;

  export type CreateCustomerIn = RequireField<
    Pick<
      Stripe.CustomerCreateParams,
      "name" | "email" | "phone" | "address" | "description" | "metadata" | "payment_method"
    >,
    "name" | "email" | "address"
  > & { address: RequireField<Stripe.AddressParam, "line1" | "postal_code" | "city"> };
  export type CreateCustomerOut = Stripe.Response<Stripe.Customer>["id"];

  export type GetCustomerIn = { email: string };
  export type GetCustomerOut = Stripe.Customer["id"];

  export type CreateCheckoutSessionPaymentIn = {
    stripeCustomerId: string;
    priceId: Stripe.Price["id"];
    couponId?: string;
    paymentMethod?: SubscriptionsModel.PaymentMethodTypeParams;
    metadata?: { [key: string]: string };
    successRedirectUri: string;
    cancelRedirectUri: string;
  };
  export type CreateCheckoutSessionPaymentOut = Stripe.Response<Stripe.Checkout.Session>["url"];

  export type CreateSetupIntentIn = { customerId: string };
  export type CreateSetupIntentOut = Stripe.SetupIntent;
}

export namespace SubscriptionsService {
  export type ListIn = Partial<Pick<SubscriptionsModel.Subscription, "productId"> & { page?: number; limit?: number }>;
  export type ListOut = SubscriptionsModel.Subscription[];

  export type ListInvoiceIn = Pick<SubscriptionsModel.Subscription, "id">;
  export type ListInvoiceOut = SubscriptionsModel.Invoices;

  export type GetIn = Pick<SubscriptionsModel.Subscription, "id">;
  export type GetOut = SubscriptionsModel.Subscription;

  export type GetMultiProductsDiscountIn = {
    productId?: string;
    userId?: string;
    planType?: SubscriptionsModel.PlanTypeParams;
  };
  export type GetMultiProductsDiscountOut = SubscriptionsModel.MultiProductsDiscountOut[];

  export type CheckUpdatePermissionIn = Pick<SubscriptionsModel.Subscription, "id"> & {
    planType: SubscriptionsModel.PlanTypeParams;
  };
  export type CheckUpdatePermissionOut = SubscriptionsModel.SubscriptionCheckUpdatePermissionResponse;

  export type UpdateIn = SubscriptionsModel.SubscriptionUpdateBody & {
    successRedirectUri: string;
    cancelRedirectUri: string;
  };
  export type UpdateOut = SubscriptionsModel.Subscription;

  export type UpdateMockIn = SubscriptionsModel.SubscriptionUpdateMock;
  export type UpdateMockOut = SubscriptionsModel.Subscription;

  export type ExpandIn = Pick<SubscriptionsModel.Subscription, "id"> & { addDays: number };
  export type ExpandOut = SubscriptionsModel.Subscription;

  export type CancelIn = SubscriptionsModel.SubscriptionCancelBody;
  export type CancelOut = SubscriptionsModel.Subscription;

  export type SuspendIn = Pick<SubscriptionsModel.Subscription, "id"> & { suspend: boolean };
  export type SuspendOut = SubscriptionsModel.Subscription;

  export type UpdateStatusIn = { stripeIds?: string[] };
  export type UpdateStatusOut = void;

  export type ChangeStateIn = never;
  export type ChangeStateOut = void;

  export type CountIn = never;
  export type CountOut = { count: number };
}
