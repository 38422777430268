var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accountingBalanceSheetTable"}},[_c('v-form',{ref:"form"},[_c('EditableTable',{ref:"editableTable",attrs:{"headers":_vm.headers,"items":_vm.accountingBalanceSheetItems,"viewType":_vm.viewType,"itemsPerPage":-1,"hideFooter":"","hideAddButton":_vm.hideAddButton,"addItemText":"Ajouter une ligne"},on:{"addItem":_vm.addItem,"cancelEditItem":_vm.cancelEditItem,"deleteItem":_vm.deleteItem,"validateItem":_vm.validateItem},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var value = ref.value;
var rowIndex = ref.rowIndex;
var isEditing = ref.isEditing;
return [_c('div',{key:headerValue,staticClass:"d-flex"},[(headerValue === 'account')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):(headerValue === 'accountName')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):(headerValue === 'attribute')?_c('div',[(value)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(value.icon)+" ")]),_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(value.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value.name))])])],1):_vm._e()],1):(headerValue === 'amountText')?_c('div',[(!isEditing)?[_vm._v(" "+_vm._s(value)+" ")]:[_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","suffix":_vm.itemsKeyValueSuffix[
                    _vm.headerValueTextToOriginalKey[headerValue]
                  ],"rules":_vm.rules(rowIndex).amount,"hide-details":"auto","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateItem({ rowIndex: rowIndex })}},model:{value:(
                  _vm.accountingBalanceSheetLines[rowIndex][
                    _vm.headerValueTextToOriginalKey[headerValue]
                  ]
                ),callback:function ($$v) {_vm.$set(_vm.accountingBalanceSheetLines[rowIndex], 
                    _vm.headerValueTextToOriginalKey[headerValue]
                  , $$v)},expression:"\n                  accountingBalanceSheetLines[rowIndex][\n                    headerValueTextToOriginalKey[headerValue]\n                  ]\n                "}})]],2):(
              headerValue === 'amountAmortisationText' &&
              _vm.hasAmortisationAccount(_vm.accountingBalanceSheetLines[rowIndex])
            )?_c('div',[(
                !isEditing ||
                _vm.accountingBalanceSheetLines[rowIndex].account.startsWith(
                  _vm.LedgerAccountEnum.N512000
                )
              )?[_vm._v(" "+_vm._s(value)+" ")]:[_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","suffix":_vm.itemsKeyValueSuffix[
                    _vm.headerValueTextToOriginalKey[headerValue]
                  ],"rules":_vm.rules(rowIndex).amortisationAmount,"hide-details":"auto","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateItem({ rowIndex: rowIndex })}},model:{value:(
                  _vm.accountingBalanceSheetLines[rowIndex][
                    _vm.headerValueTextToOriginalKey[headerValue]
                  ]
                ),callback:function ($$v) {_vm.$set(_vm.accountingBalanceSheetLines[rowIndex], 
                    _vm.headerValueTextToOriginalKey[headerValue]
                  , $$v)},expression:"\n                  accountingBalanceSheetLines[rowIndex][\n                    headerValueTextToOriginalKey[headerValue]\n                  ]\n                "}})]],2):(headerValue === 'amountNetText')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):(headerValue === 'goToTransactions')?_c('div',[(
                _vm.accountingBalanceSheetLines[rowIndex].transactionIds &&
                _vm.accountingBalanceSheetLines[rowIndex].transactionIds.length
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.accountingBalanceSheetLines[rowIndex].transactionIds &&
                    !_vm.accountingBalanceSheetLines[rowIndex].transactionIds
                      .length,"icon":"","small":""},on:{"click":function($event){return _vm.goToTransactions(_vm.accountingBalanceSheetLines[rowIndex])}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"id":"productTour-eye","color":"#000","small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Voir le détail des transactions ")])]):_vm._e()],1):_vm._e()])]}}})],null,true)})],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '30%' : '50%'},model:{value:(_vm.showAddItemModal),callback:function ($$v) {_vm.showAddItemModal=$$v},expression:"showAddItemModal"}},[(_vm.showAddItemModal)?_c('AccountingBalanceSheetAddModal',{attrs:{"accountBalanceType":_vm.accountBalanceType,"balanceSheet":_vm.balanceSheet},on:{"close":function($event){_vm.showAddItemModal = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }