






































































import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { defineComponent, Ref, ref } from "@vue/composition-api";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import AddDocumentsModal from "./AddDocumentsModal.vue";
import Title from "../title/Title.vue";

export default defineComponent({
  name: "DocumentsHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    displayHelper: {
      type: Boolean,
      default: false,
    },
    displayFilter: {
      type: Boolean,
      default: false,
    },
    displayAccountingPeriod: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountingPeriodChoice,
    Title,
    AddDocumentsModal,
  },
  setup() {
    const showDocumentsModal: Ref<boolean> = ref(false);

    return {
      showDocumentsModal,
      openArticleDocumentsSaving: () =>
        useCrisp().openArticle(ArticleEnum.DOCUMENT_SAVING),
    };
  },
});
