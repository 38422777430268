export function usePassword() {
  function validPasswordRule(password: string): boolean {
    if (password) {
      return password.length >= 6;
    } else {
      return false;
    }
  }

  function getPasswordRule(): string {
    return "Le mot de passe doit faire au minimum 6 caractères";
  }

  return {
    validPasswordRule,
    getPasswordRule,
  };
}
