




































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  Ref,
  ref,
} from "@vue/composition-api";
import {
  BankAccount,
  ProductsModel,
  BankAccountsTransactionsService,
  AccountingPeriod,
  AccountingPeriodDefinition,
  TaxRegime,
} from "@edmp/api";

import { VForm } from "@/models";
import {
  accountingPeriodsStore,
  bankAccountsStore,
  productsStore,
  coreStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { FilterKeys } from "@/utils";

import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { useTransactions } from "../transactions.usable";

import Confirm from "@/components/core/modals/Confirm.vue";
import TransactionImportCreateDuplication from "./TransactionImportCreateDuplication.vue";
import { transactionsService } from "@/services/Transactions.service";

export default defineComponent({
  name: "TransactionImportCreate",
  components: {
    Confirm,
    TransactionImportCreateDuplication,
  },
  setup(_, context) {
    const { addFilter } = useTransactions();
    const bankAccount: Ref<string> = ref("");

    const isOpenImportTransactionDuplication = ref(false);

    productsStore.fetchProducts({});
    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );

    // Refresh and retrieve list of bank account
    bankAccountsStore.fetchBankAccounts(product.value.id);
    const bankAccounts: ComputedRef<BankAccount[]> = computed(() => {
      const bankAccounts = bankAccountsStore.bankAccounts.filter(
        (bankAccount) =>
          product.value.bankAccounts &&
          product.value.bankAccounts.includes(bankAccount.id)
      );
      // Init first value of list of bank account in v-select
      bankAccount.value = bankAccounts.find((b) => b.name)?.id ?? "";
      return bankAccounts ?? [];
    });

    context.root.$router.push({ query: { importFile: "open" } });

    function resetForm() {
      (context.refs.form as VForm).resetValidation();
    }

    const firstStep: Ref<boolean> = ref(true);
    const secondStep: Ref<boolean> = ref(false);
    const importResponse: Ref<
      BankAccountsTransactionsService.ImportOut | undefined
    > = ref();
    const file: Ref<File | undefined> = ref();

    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );
    async function validateFirstStep() {
      if ((context.refs.form as VForm).validate() && file.value) {
        const accountingPeriod =
          accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod &
            AccountingPeriodDefinition;

        try {
          importResponse.value = await transactionsService.import({
            document: {
              accountingPeriodId: accountingPeriodsStore.currentId,
              bankAccountId: bankAccount.value,
              startAt: accountingPeriod.startAt,
              endAt: accountingPeriod.endAt,
            },
            file: file.value,
          });

          if (importResponse.value.status) {
            // Display second step component
            firstStep.value = false;
            secondStep.value = true;

            // Refresh Transaction
            transactionsStore.fetchTransactions({
              productId: productsStore.currentId,
              accountingPeriodId: accountingPeriodsStore.currentId,
            });
            addFilter(FilterKeys.ONLY_IMPORTED, {
              label: `Sources : Import`,
            });

            resetForm();
          } else {
            isOpenImportTransactionDuplication.value = true;
            if (importResponse.value.transactionIds.length) {
              transactionsStore.fetchTransactions({
                productId: productsStore.currentId,
                accountingPeriodId: accountingPeriodsStore.currentId,
              });
              addFilter(FilterKeys.ONLY_IMPORTED, {
                label: `Sources : Import`,
              });
            }
          }
        } catch (e) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            timeout: 20000,
            message:
              "Ownily n'a pas trouvé de transactions dans votre fichier. Vérifiez son contenu ou contactez-nous via le tchat en bas à gauche ou via l'adresse contact@ownily.fr.",
          });
        }
      }
    }

    async function finish(): Promise<void> {
      //context.root.$router.push({ query: {} });
      context.emit("finish");
    }

    return {
      product,
      bankAccounts,
      bankAccount,
      bankAccountLabel: (account: BankAccount) =>
        `${account.bankName} - ${account.name}`,
      file,
      finish,
      validateFirstStep,
      importResponse,
      firstStep,
      secondStep,
      openArticle: () => useCrisp().openArticle(ArticleEnum.IMPORT_TRANSACTION),
      isLMNP,
      isOpenImportTransactionDuplication,
    };
  },
});
