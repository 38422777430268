

























































import { SubscriptionsModel } from "@edmp/api/";
import { defineComponent, PropType } from "@vue/composition-api";
import { subscriptionPayments } from "../Subscriptions.const";

/**
 * @Component PaymentChoice
 * @prop {PaymentParams} paymentSelected
 * @emit {PaymentParams} selectPayment
 */
export default defineComponent({
  name: "PaymentChoice",
  props: {
    paymentSelected: {
      type: Object as PropType<SubscriptionsModel.PaymentParams>,
      required: false,
    },
    loading: { type: Boolean, default: false },
  },
  setup(props, context) {
    const selectPayment = (selectPayment) => {
      const paymentSelected: SubscriptionsModel.PaymentParams = {
        paymentMethod: selectPayment,
      };

      context.emit("selectPayment", paymentSelected);
    };

    return {
      subscriptionPayments,
      selectPayment,
    };
  },
});
