



















































import { TaskActivityGroupLocal } from "@/models";
import {
  accountingPeriodsStore,
  productsStore,
  assembliesStore,
  taskActivityStore,
} from "@/store";
import {
  GeneralAssemblyEvent,
  getReferredIdByTypeWithReferences,
  TaskActivityStatus,
  TaskActivityTypeReference,
} from "@edmp/api/";
import { computed, ComputedRef, defineComponent } from "@vue/composition-api";
import EventsGeneralAssemblyCard from "./list/EventsGeneralAssemblyCard.vue";
import EventsGeneralAssemblyHeader from "./list/EventsGeneralAssemblyHeader.vue";

export default defineComponent({
  name: "EventsGeneralAssemblyList",
  components: {
    EventsGeneralAssemblyHeader,
    EventsGeneralAssemblyCard,
  },
  setup() {
    const getTaskActivityGroupByEvent = function (gae: GeneralAssemblyEvent) {
      const taskActivityGroup: ComputedRef<TaskActivityGroupLocal | undefined> =
        computed(() =>
          taskActivityStore.assemblyTaskGroup.find((taskGroup) => {
            return (
              taskGroup.groupCode ===
                `event.general-assembly${
                  gae.pattern === "yearEnd" ? ".year-end" : ""
                }` &&
              taskGroup.taskActivitiesLocal.some(
                (taskActivity) =>
                  getReferredIdByTypeWithReferences(
                    taskActivity.references,
                    TaskActivityTypeReference.product
                  ) === productsStore.currentId &&
                  getReferredIdByTypeWithReferences(
                    taskActivity.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodsStore.currentId &&
                  (gae.pattern === "yearEnd" ||
                    getReferredIdByTypeWithReferences(
                      taskActivity.references,
                      TaskActivityTypeReference.assembly
                    ) === gae.id)
              )
            );
          })
        );
      return taskActivityGroup;
    };

    const generalAssemblyEventNotCompleted: ComputedRef<
      GeneralAssemblyEvent[] | undefined
    > = computed(() =>
      assembliesStore.generalAssemblyEvents
        .filter(
          (gae) => gae.accountingPeriodId === accountingPeriodsStore.currentId
        )
        .filter((gae) => {
          const taskActivityGroup: ComputedRef<
            TaskActivityGroupLocal | undefined
          > = getTaskActivityGroupByEvent(gae);
          if (taskActivityGroup?.value?.status != TaskActivityStatus.COMPLETED)
            return true;
          else return false;
        })
    );

    const generalAssemblyEventCompleted: ComputedRef<
      GeneralAssemblyEvent[] | undefined
    > = computed(() =>
      assembliesStore.generalAssemblyEvents
        .filter(
          (gae) => gae.accountingPeriodId === accountingPeriodsStore.currentId
        )
        .filter((gae) => {
          const taskActivityGroup: ComputedRef<
            TaskActivityGroupLocal | undefined
          > = getTaskActivityGroupByEvent(gae);
          if (taskActivityGroup?.value?.status == TaskActivityStatus.COMPLETED)
            return true;
          else return false;
        })
    );

    return {
      generalAssemblyEventNotCompleted,
      generalAssemblyEventCompleted,
    };
  },
});
