




























































import { VForm } from "@/models";
import { realEstateAssetsStore } from "@/store";
import { Address, RealEstateAsset, RealEstateAssetUpdate } from "@edmp/api";
import { defineComponent, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "RealEstateAssetUpdate",
  props: {
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const street: Ref<string> = ref(
      props.realEstateAsset?.address?.street ?? ""
    );
    const zip: Ref<string> = ref(props.realEstateAsset?.address?.zip ?? "");
    const city: Ref<string> = ref(props.realEstateAsset?.address?.city ?? "");

    function resetForm() {
      street.value = "";
      zip.value = "";
      city.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const address: Address = {
            street: street.value,
            zip: zip.value,
            city: city.value,
          };
          // Udpate Adress in Tenant
          const realEstateAssetToUpdate: RealEstateAssetUpdate = {
            ...props.realEstateAsset,
            address: address,
          };
          // Update productStore
          await realEstateAssetsStore.updateRealEstateAsset(
            realEstateAssetToUpdate
          );

          context.emit("validate", address);

          resetForm();
        } catch (error) {
          console.error(Error);
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      street,
      zip,
      city,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
    };
  },
});
