




































import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import { HelpingMessage } from "@/components/atom/helping";
import { NatureLabel, getMoment, getFixedAssetTypeText } from "@edmp/api";
import PageHeader from "@/components/atom/PageHeader.vue";
import FixedAssetCreateModal from "@/components/core/fixedAssets/FixedAssetCreateModal.vue";
import FixedAssets from "./FixedAssets.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { ArticleEnum } from "@/composables/crisp.usable";
import {
  productsStore,
  fixedAssetsStore,
  realEstateAssetsStore,
} from "@/store";

export default defineComponent({
  name: "FixedAssetsPage",
  components: {
    PageHeader,
    HelpingMessage,
    FixedAssetCreateModal,
    FixedAssets,
  },
  setup() {
    const gotoFixedAsset = (fixedAsset) => {
      router.push({
        name: ROUTE_NAMES.FixedAsset,
        params: {
          productId: fixedAsset.productId,
          fixedAssetId: fixedAsset.id,
        },
      });
    };
    const fixedAssets = computed(() => fixedAssetsStore.fixedAssets);

    onBeforeMount(async () => {
      await fixedAssetsStore.fetchFixedAssets(productsStore.currentId);
    });

    const formatRealEstateAssets = (realEstateAssetIds) => {
      return realEstateAssetIds
        .map((realEstateAssetId) => {
          let asset =
            realEstateAssetsStore.getRealEstateAsset(realEstateAssetId);
          return asset?.name;
        })
        .filter((_) => _)
        .join(", ");
    };
    const createFixedAsset = () => {
      router.push({
        name: ROUTE_NAMES.FixedAsset,
        params: {
          productId: productsStore.currentId,
          fixedAssetId: "new",
        },
      });
    };

    return {
      getFixedAssetTypeText,
      fixedAssets,
      NatureLabel,
      getMoment,
      gotoFixedAsset,
      ArticleEnum,
      formatRealEstateAssets,
      createFixedAsset,
    };
  },
});
