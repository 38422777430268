import {
  accountingPeriodsStore,
  productsStore,
  subscriptionsStore,
} from "@/store";
import { SubscriptionsModel } from "@edmp/api";
import { computed, onBeforeMount, ref, watch } from "@vue/composition-api";
import { subscriptionPlans } from "./Subscriptions.const";

export function useSubscriptions() {
  const isLoading = ref(false);

  /* It creates a reactive reference to an array of subscription plans (`subscriptionPlans`). It then
creates a computed property (`subscriptionPlanItems`) that filters the `plansItems` array based on
the current accounting period's tax regime. If the tax regime is `TaxRegime.IS_2065`, it only
includes plans with a type of `PlanTypeParams.Premium`. Otherwise, it includes all plans. The `set`
function of the computed property updates the `plansItems` array with the new value of
`subscriptionPlanItems`. */
  const plansItems = ref(subscriptionPlans);
  const subscriptionPlanItems = computed({
    get: () => plansItems.value,
    set: (subscriptionPlanItems) => {
      plansItems.value = subscriptionPlanItems;
    },
  });

  watch(
    () => [productsStore.currentId, accountingPeriodsStore.currentId],
    () => init(),
    { deep: true }
  );

  const init = async () => {
    isLoading.value = true;
    plansItems.value = subscriptionPlans;
    if (accountingPeriodsStore.currentAccountingPeriod) {
      const products: SubscriptionsModel.ProductOut[] =
        await subscriptionsStore.getProducts({
          taxRegime: accountingPeriodsStore.currentAccountingPeriod.taxRegime,
        });
      const discounts: SubscriptionsModel.MultiProductsDiscountOut[] =
        await subscriptionsStore.getMultiProductsDiscount({
          productId: productsStore.currentId,
        });

      if (products.length) {
        products.forEach((product) => {
          // Sort price recurring by month then year
          product.prices.sort((priceA, priceB) => {
            if (priceA.durationType < priceB.durationType) {
              return -1;
            }
            if (priceA.durationType > priceB.durationType) {
              return 1;
            }
            return 0;
          });

          // Filter plans by plans retrieve from back
          const plans = subscriptionPlanItems.value.filter((plan) =>
            products.some((product) => product.type === plan.type)
          );

          // Add plan Standard in first position in array
          for (let index = 0; index < plans.length; index++) {
            if (product.type == plans[index].type) {
              plans[index].title = product.name;
              plans[index].product = product;

              for (const discount of discounts) {
                if (
                  discount.applies_to.products.some(
                    ({ planType }) => planType === plans[index].type
                  )
                ) {
                  plans[index].product = Object.assign(plans[index].product, {
                    coupon: discount,
                  });
                  // Breaking out of the loop after assigning the first applicable discount
                  // because the discounts list received from the backend is already sorted
                  // by the 'created' date in descending order. Therefore, the first matching
                  // discount is the most recent one
                  break;
                }
              }
            }
          }
          subscriptionPlanItems.value = plans;
        });
      } else {
        plansItems.value = [];
      }
    }
    isLoading.value = false;
  };

  onBeforeMount(async () => {
    await init();
  });

  return { isLoading, subscriptionPlanItems };
}
