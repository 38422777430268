import {
  AccountingCompute,
  AccountingPeriod,
  getBalance,
  JournalEntryLine,
  Transaction,
} from "@edmp/api";

import { uniqBy } from "lodash";

const useAccounting = {
  getBalance: (
    accountingPeriod: AccountingPeriod,
    transactions: Transaction[],
    filter?: {
      account?: JournalEntryLine["account"];
      realEstateAssetIds?: string[];
    }
  ): AccountingCompute => {
    return getBalance(accountingPeriod, transactions, filter);
  },

  getTransactionCategorizeName: (
    transactions: Transaction[]
  ): { account: string; name: string }[] => {
    const result: { account: string; name: string }[] = [];
    for (const transaction of transactions) {
      const journalEntry = transaction.operations?.journalEntry;

      journalEntry?.lines?.forEach((journalEntryLine: JournalEntryLine) => {
        result.push({
          account: journalEntryLine.account,
          name: journalEntryLine.accountName,
        });
      });
    }

    return uniqBy(result, "account");
  },
};

export default useAccounting;
