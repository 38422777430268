




























































import { ref, defineComponent, watch, Ref } from "@vue/composition-api";

import { userAccountService } from "@/services";
import { VForm } from "@/models";
import { UserAccountsService } from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "LoginForm",
  setup(props, context) {
    const username: Ref<string> = ref("");
    const password: Ref<string> = ref("");
    const showPassword: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);

    function connect(e: Event): void {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        const credentials: UserAccountsService.LoginIn = {
          username: username.value,
          password: password.value,
        };
        userAccountService
          .login(credentials)
          .then(() => context.root.$router.push("/"))
          .catch(() => (error.value = true));
      }
    }

    watch([username, password], () => {
      error.value = false;
    });

    return { ROUTE_NAMES, username, password, showPassword, error, connect };
  },
});
