
















import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "DialogRight",
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },
});
