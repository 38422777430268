













import {
  defineComponent,
  computed,
  ref,
  Ref,
  watch,
  onMounted,
} from "@vue/composition-api";

import { fixedAssetAmortisationsStore } from "@/store";
import { useFixedAssetAmortisation } from "@/components/core/fixedAssetAmortisation/fixedAssetAmortisation.usable";
import { FixedAssetAmortisation } from "@edmp/api";

import Tag from "@/components/atom/Tag.vue";
import FixedAssetAmortisationTable from "./FixedAssetAmortisationTable.vue";

export default defineComponent({
  name: "FixedAssetAmortisations",
  components: { Tag, FixedAssetAmortisationTable },
  props: { fixedAssetId: { type: String, required: true } },
  setup(props) {
    const { totalAmortisationByFixedAssets, amortisationTypeText } =
      useFixedAssetAmortisation();

    const fixedAssetAmortisationsList: Ref<
      (FixedAssetAmortisation & {
        component: string;
        textValue: string;
      })[]
    > = ref([]);
    const getFixedAssetAmortisationsList = () => {
      const amortisations =
        fixedAssetAmortisationsStore.getFixedAssetAmortisationsByFixedAssetId(
          props.fixedAssetId
        );

      const amortisationData: (FixedAssetAmortisation & {
        component: string;
        textValue: string;
      })[] = [];

      for (const line of amortisations) {
        const component = amortisationTypeText[line.type];
        amortisationData.push({
          ...line,
          component,
          textValue: line.value + " €",
        });
      }

      fixedAssetAmortisationsList.value = amortisationData;
    };
    const isNew = computed(
      () => fixedAssetAmortisationsList.value.length === 0
    );

    const headers = [
      { text: "Composant", value: "component" }, // component field is added in fixedAssetAmortisationsList in computed
      { text: "Valeur", value: "textValue" },
      { text: "Durée (an)", value: "durationInYear" },
      { text: "Taux (%)", value: "rate" },
    ];
    watch(
      fixedAssetAmortisationsStore.fixedAssetAmortisations,
      () => {
        getFixedAssetAmortisationsList();
      },
      { deep: true }
    );
    onMounted(() => {
      getFixedAssetAmortisationsList();
    });
    return {
      headers,
      fixedAssetAmortisationsList,
      isNew,
      totalAmortisation: computed(
        () => totalAmortisationByFixedAssets.value[props.fixedAssetId] || 0
      ),
    };
  },
});
