






























































import { defineComponent, PropType } from "@vue/composition-api";
import { extension } from "mime-types";
import { Document } from "@edmp/api";
import { documentsStore } from "@/store";
import { VConfirmDialog } from "@/models";
import Confirm from "@/components/core/modals/Confirm.vue";

export default defineComponent({
  name: "DocumentCard",
  components: { Confirm },
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
    isEnableDelete: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    function showDocument() {
      const doc = props.document;
      window.open(doc.href, "_blank");
    }

    const deleteDocument = async () => {
      if (props.document) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          `Êtes-vous sûr de vouloir supprimer le document ${props.document.name} ?`
        );
        if (openConfirm) {
          try {
            await documentsStore.deleteDocument(props.document.id);
          } catch (error) {
            console.error(error);
          }
        }
      }
    };

    return { showDocument, deleteDocument, extension };
  },
});
