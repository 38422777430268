var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rentalAgreements-view"},[_c('div',{staticClass:"rentalAgreements-view-header"},[_c('div',{staticClass:"rentalAgreements-view-header-title"},[_c('TitleNew',{attrs:{"hLevel":"h1"}},[_vm._v("Suivi des loyers - Mes locations")]),_c('v-spacer')],1),_c('div',{staticClass:"rentalAgreements-view-header-action"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button px-6 py-2 mt-2 ml-2 mr-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune-vertical")]),_c('h5',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.filterSelected.name))]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.selectFilter),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeFilterSelected(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button px-6 py-2 mt-2 ml-2 mr-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-sort")]),_c('h5',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.displaySelected.name))]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.selectDisplay),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeDisplaySelected(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('div',[_c('v-btn',{staticClass:"buttonNewRA mt-2 ml-2 mr-2",attrs:{"depressed":"","color":"primary","id":"track-add_rental-agreement"},on:{"click":function($event){_vm.displayRentalAgreementModalWithoutRealEstateAsset = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"mr-2 ml-2"},[_vm._v("Ajouter une location")])],1)],1)],1)]),_c('div',[(_vm.rentalAgreementsFiltered.length === 0)?_c('p',{staticClass:"grey--text text-center mt-5"},[_vm._v(" Aucune location ")]):_vm._e(),(
        _vm.rentalAgreementsFiltered.length > 0 &&
        _vm.rentalAgreementsFilterByRealEstateAsset === false
      )?_c('v-row',{attrs:{"justify":"start"}},_vm._l((_vm.rentalAgreementsFiltered),function(ra){return _c('v-col',{key:ra.id,attrs:{"sm":"6","md":"3","cols":_vm.$vuetify.breakpoint.lgAndDown ? '12' : '1'}},[_c('RentalAgreementRentCard',{attrs:{"rentalAgreementId":ra.id}})],1)}),1):_vm._e(),(
        _vm.rentalAgreementsFiltered.length > 0 &&
        _vm.rentalAgreementsFilterByRealEstateAsset === true
      )?_c('div',_vm._l((_vm.realEstateAssets),function(rea,index){return _c('div',{key:rea.id,attrs:{"justify":"start"}},[_c('Title',{staticClass:"mt-5",attrs:{"text":("Bien: " + (rea.name))}}),_c('v-spacer'),_c('v-row',_vm._l((_vm.rentalAgreementsByRealEstateAsset[index]),function(ra){return _c('v-col',{key:ra.id,attrs:{"sm":"6","md":"4","cols":_vm.$vuetify.breakpoint.lgAndDown ? '12' : '1'}},[_c('RentalAgreementRentCard',{attrs:{"rentalAgreementId":ra.id,"realEstateAssetId":rea.id}})],1)}),1)],1)}),0):_vm._e()],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '55%' : '80%',"persistent":""},model:{value:(_vm.displayRentalAgreementModalWithoutRealEstateAsset),callback:function ($$v) {_vm.displayRentalAgreementModalWithoutRealEstateAsset=$$v},expression:"displayRentalAgreementModalWithoutRealEstateAsset"}},[(_vm.displayRentalAgreementModalWithoutRealEstateAsset)?_c('RentalAgreementWithoutRealEstateAssetModal',{attrs:{"productId":_vm.productId,"editable":!_vm.selectedRentalAgreement,"displayRentalAgreementModalWithoutRealEstateAsset":_vm.displayRentalAgreementModalWithoutRealEstateAsset},on:{"validate":function($event){_vm.init();
        _vm.displayRentalAgreementModal = false;
        _vm.selectedRentalAgreement = undefined;},"close":function($event){_vm.displayRentalAgreementModalWithoutRealEstateAsset = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }