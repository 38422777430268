import { UsersService } from "@edmp/api";
import axios from "axios";
import { permissionsService } from "@/services/Permissions.service";

const api = "/api/v1/users";

class UsersService {
  async getLoggedIn() {
    return axios.get<UsersService.GetOut>(`${api}/me`).then((res) => {
      permissionsService.updateUserAbility(res.data);
      return res.data;
    });
  }

  async get(params: UsersService.GetIn) {
    return axios
      .get<UsersService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        permissionsService.updateUserAbility(res.data);
        return res.data;
      });
  }

  async listProducts(params: UsersService.ListProductIn) {
    return axios
      .get<UsersService.ListProductOut>(`${api}/${params.id}/products`, {
        params,
      })
      .then((res) => res.data);
  }

  async listDocuments(params: UsersService.ListDocumentIn) {
    return axios
      .get<UsersService.ListDocumentOut>(`${api}/${params.id}/documents`, {
        params,
      })
      .then((res) => res.data);
  }

  async updateUser(userUpdate: UsersService.UpdateIn) {
    return axios
      .put<UsersService.UpdateOut>(`${api}/${userUpdate.id}`, userUpdate)
      .then((res) => res.data);
  }

  async acceptTos({ id, ...acceptTos }: UsersService.AcceptTosIn) {
    return axios
      .post<UsersService.AcceptTosOut>(`${api}/${id}/accept-tos`, acceptTos)
      .then((res) => res.data);
  }

  async validateEmail(data: UsersService.ValidateEmailIn) {
    return axios
      .post<UsersService.AcceptTosOut>(`${api}/validate-email`, data)
      .then((res) => res.data);
  }
}

// Export a singleton instance in the global namespace
export const usersService = new UsersService();
