import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";
import { PartialField, ProductsModel } from "..";

export type ProductStatsStockProduct = ProductsModel.Product;

export interface ProductStats {
  id: string;
  stockProducts: {
    total: number;
  };
  date: string;
}
export type ProductStatsUpsert = PartialField<ProductStats, "id">;

const companyStatsSchema = new Schema<ProductStatsDocument>(
  {
    _id: { type: String, default: () => ulid() },
    stockCompanies: {
      total: { type: Number },
    },
    date: { type: String, require: true, index: true, unique: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: ProductStatsDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type ProductStatsDocument = ProductStats & Document<string>;

export const ProductStatsModel = model<ProductStatsDocument>("ProductStats", companyStatsSchema, "ProductsStats");
