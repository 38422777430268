import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { realEstatesService } from "@/services";
import { RealEstateLoan, NewRealEstateLoan } from "@edmp/api";
import Vue from "vue";
import { RealEstateLoanUpdate } from "@edmp/api";

export interface RealEstateLoansState {
  realEstateLoans: Array<RealEstateLoan>;
  loading: boolean;
}

@Module({
  name: "real-estate-loans-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RealEstateLoansStore
  extends VuexModule
  implements RealEstateLoansState
{
  realEstateLoans: Array<RealEstateLoan> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.realEstateLoans = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setRealEstateLoan(realEstateLoan: RealEstateLoan): void {
    if (Object.keys(realEstateLoan).length !== 0) {
      const index = this.realEstateLoans.findIndex(
        ({ id }) => id == realEstateLoan.id
      );
      if (index !== -1) {
        // Tips :  Vue cannot detect when you directly set a non-root state property with the index. For keeping reactivity use set() method
        Vue.set(this.realEstateLoans, index, realEstateLoan);
      } else {
        this.realEstateLoans.push(realEstateLoan);
      }
    }
  }

  @Mutation
  setRealEstateLoans(realEstateLoans: RealEstateLoan[]): void {
    this.realEstateLoans = realEstateLoans;
  }

  @Action
  async fetchRealEstateLoans(productId: string): Promise<RealEstateLoan[]> {
    this.setLoading(true);
    const realEstateLoans = await realEstatesService.loans.list({ productId });
    this.setRealEstateLoans(realEstateLoans);
    this.setLoading(false);
    return realEstateLoans;
  }

  get getRealEstateLoansByRealEstateAssetId() {
    return (realEstateLoanId: string | null) => {
      if (!realEstateLoanId) return [];
      return this.realEstateLoans.filter(
        (realEstateLoan) =>
          realEstateLoan.realEstateAssetId === realEstateLoanId
      );
    };
  }

  get getRealEstateLoansByProductId() {
    return (productId: string | null) => {
      if (!productId) return [];
      return this.realEstateLoans.filter(
        (realEstateLoan) => realEstateLoan.productId === productId
      );
    };
  }

  get getRealEstateLoan() {
    return (realEstateLoanId: string): RealEstateLoan | undefined => {
      return this.realEstateLoans.find(({ id }) => id === realEstateLoanId);
    };
  }

  // Create
  @Action
  async createRealEstateLoan(
    realEstateLoanCreate: NewRealEstateLoan
  ): Promise<RealEstateLoan> {
    this.setLoading(true);
    const newRealEstateLoan = await realEstatesService.loans.create(
      realEstateLoanCreate
    );
    this.setRealEstateLoan(newRealEstateLoan);
    this.setLoading(false);
    return newRealEstateLoan;
  }

  // Update
  @Action
  async updateRealEstateLoan(
    realEstateLoanUpdate: RealEstateLoanUpdate
  ): Promise<RealEstateLoan> {
    this.setLoading(true);
    const updatedRealEstateLoan = await realEstatesService.loans.update(
      realEstateLoanUpdate
    );
    this.setRealEstateLoan(updatedRealEstateLoan);
    this.setLoading(false);
    return updatedRealEstateLoan;
  }

  @Action
  async fetchRealEstateLoan(id: string): Promise<RealEstateLoan> {
    this.setLoading(true);
    const realEstateLoan = await realEstatesService.loans.get({ id });
    this.setRealEstateLoan(realEstateLoan);

    this.setLoading(false);
    return realEstateLoan;
  }
}
