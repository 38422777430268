


















































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  AccountingType,
  getMoment,
  OperationLine,
  TaxRegime,
  TypeReference,
} from "@edmp/api";

import { accountingsStore, accountingPeriodsStore } from "@/store";
import { ArticleEnum } from "@/composables/crisp.usable";
import { ROUTE_NAMES } from "@/router/routes";

import { useSupportingDocuments } from "@/components/core/supportingDocuments/supportingDocuments.usable";

import PageHeader from "@/components/atom/PageHeader.vue";
import Title from "@/components/core/title/Title.vue";
import EditableTable, {
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";
import { cloneDeep } from "lodash";
import { useTransactions } from "../transactions/transactions.usable";
import { accountingsService } from "@/services";

export default defineComponent({
  name: "AccountingOperations",
  components: {
    Title,
    PageHeader,
    EditableTable,
  },

  setup() {
    const { getSupportingDocuments } = useSupportingDocuments();
    const { getAttribute } = useTransactions();

    const isLoading: Ref<boolean> = ref(false);
    const taxRegime = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );
    const operations = computed(() => accountingsStore.operations);

    const getAccountName = (line: OperationLine): string | undefined => {
      if (line?.documentWording) {
        return "Facture";
      } else {
        if (
          line.accountName === "BANQUE - " ||
          line.accountName === "BANQUE - Manual Transactions"
        )
          return "Banque Manuelle";
      }
      return line.accountName;
    };

    // Table
    const headers: ComputedRef<TableHeaderItems> = computed(() => {
      const headersOut = [
        { text: "Date", value: "date" },
        { text: "N° du compte", value: "account" },
        { text: "Libellé", value: "summary" },
        { text: "Nom du compte", value: "accountName" },
        { text: "Attribut", value: "attribute" },
        { text: "Débit", value: "debitText", width: "10rem" },
        { text: "Crédit", value: "creditText", width: "10rem" },
      ];
      return headersOut;
    });
    const headerValueTextToOriginalKey: {
      [key in typeof headers.value[number]["value"]]: keyof Pick<
        OperationLine,
        "debit" | "credit"
      >;
    } = { debitText: "debit", creditText: "credit" };
    const itemsKeyValueSuffix: {
      [key in keyof Pick<OperationLine, "debit" | "credit">]: string;
    } = {
      debit: "€",
      credit: "€",
    };
    const operationItems = computed(() => {
      const operationItemsOut = cloneDeep(operations.value)
        .map((line) => {
          // We want to ignore account 512100 since it is only a technical account
          // if (!line.account.startsWith("512100")) {
          return {
            ...line,
            date: getMoment(line.date).format("LL"),
            account: line.account,
            accountName: getAccountName(line),
            attribute: line.reference
              ? {
                  name: getAttribute(
                    line.reference.type,
                    line.reference.referredId
                  ),
                  icon:
                    line.reference.type === TypeReference.realEstateAsset
                      ? "mdi-home-outline"
                      : line.reference.type === TypeReference.rentalAgreement
                      ? "mdi-account-key-outline"
                      : line.reference.type === TypeReference.partner
                      ? "mdi-account-outline"
                      : line.reference.type === TypeReference.realEstateLoan
                      ? "mdi-home-percent-outline"
                      : line.reference.type === TypeReference.supportingDocument
                      ? "mdi-folder-outline"
                      : line.reference.type === TypeReference.bankAccount
                      ? "mdi-bank-outline"
                      : line.reference.type === TypeReference.beneficiary
                      ? "mdi-hand-extended"
                      : undefined,
                }
              : undefined,
            debitText: line.debit
              ? `${line.debit}${itemsKeyValueSuffix.debit}`
              : "",
            creditText: line.credit
              ? `${line.credit}${itemsKeyValueSuffix.credit}`
              : "",
          };
          // }
        })
        .filter((value) => value !== undefined);
      return operationItemsOut;
    });

    const reportings = [
      {
        name: "FEC",
        download: () =>
          accountingsService.getReportingFec({
            accountingPeriodId: accountingPeriodsStore.currentId,
            type: AccountingType.COMPLETE,
          }),
      },
      {
        name: "FEC normé",
        download: () =>
          accountingsService.getReportingFec({
            accountingPeriodId: accountingPeriodsStore.currentId,
            type: AccountingType.STANDARD,
          }),
      },
    ];
    const downloadAccountingReportFec = () =>
      accountingsService.getReportingFec({
        accountingPeriodId: accountingPeriodsStore.currentId,
        type: AccountingType.COMPLETE,
      });
    const downloadAccountingReportFecStandard = () =>
      accountingsService.getReportingFec({
        accountingPeriodId: accountingPeriodsStore.currentId,
        type: AccountingType.STANDARD,
      });

    // Init
    watch(
      () => accountingPeriodsStore.currentId,
      () => init(),
      { deep: true }
    );

    async function init() {
      isLoading.value = true;
      await accountingsStore.fetchOperations(accountingPeriodsStore.currentId);
      await getSupportingDocuments();
      isLoading.value = false;
    }

    onBeforeMount(() => init());

    return {
      isLoading,
      operations,
      getAccountName,
      ArticleEnum,
      headerValueTextToOriginalKey,
      itemsKeyValueSuffix,
      headers,
      operationItems,
      ROUTE_NAMES,
      taxRegime,
      TaxRegime,
      reportings,
      downloadAccountingReportFec,
      downloadAccountingReportFecStandard,
    };
  },
});
