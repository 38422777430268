import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { rentalsService } from "@/services";
import { Tenant, NewTenant, TenantUpdate } from "@edmp/api";
import Vue from "vue";
import { productsStore } from "..";

export interface TenantsState {
  tenants: Array<Tenant>;
  loading: boolean;
}

@Module({
  name: "tenants-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class TenantsStore extends VuexModule implements TenantsState {
  tenants: Array<Tenant> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.tenants = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // Tenants
  @Action
  async fetchTenants(value: { productId: string }): Promise<Tenant[]> {
    this.setLoading(true);
    const tenants = await rentalsService.tenants.list({
      productId: value.productId,
    });
    this.setTenants(tenants);
    this.setLoading(false);
    return tenants;
  }

  get getTenantsByRealEstateAssetId() {
    return (realEstateAssetId: string) => {
      return this.tenants.filter(
        (tenant) => tenant.realEstateAssetId === realEstateAssetId
      );
    };
  }

  get getTenantsByRentalAgreementId() {
    return (rentalAgreementId: string) => {
      return this.tenants.filter(
        (tenant) => tenant.rentalAgreementId === rentalAgreementId
      );
    };
  }

  // Tenant
  @Mutation
  setTenant(tenant: Tenant): void {
    const index = this.tenants.findIndex(({ id }) => id == tenant.id);
    if (index !== -1) {
      Vue.set(this.tenants, index, tenant);
    } else {
      this.tenants.push(tenant);
    }
  }

  @Mutation
  setTenants(tenants: Tenant[]): void {
    this.tenants = tenants;
  }

  @Mutation
  removeTenant(tenantId: string): void {
    const index = this.tenants.findIndex(({ id }) => id == tenantId);
    if (index !== -1) {
      this.tenants.splice(index, 1);
    }
  }

  get getTenant() {
    return (tenantId: string) => {
      return this.tenants.find((tenant) => tenant.id === tenantId);
    };
  }

  // Create
  @Action
  async createTenant(tenantCreate: NewTenant): Promise<Tenant> {
    this.setLoading(true);
    if (!tenantCreate.realEstateAssetId) {
      throw new Error(
        "Cannot find real estate asset id in `tenant.realEstateAssetId`"
      );
    }
    tenantCreate.productId = productsStore.currentId;
    const newTenant = await rentalsService.tenants.create(tenantCreate);
    this.setTenant(newTenant);
    this.setLoading(false);
    return newTenant;
  }

  @Action
  async updateTenant(tenantUpdate: TenantUpdate): Promise<Tenant> {
    this.setLoading(true);
    const updatedTenant = await rentalsService.tenants.update(tenantUpdate);
    this.setTenant(updatedTenant);
    this.setLoading(false);
    return updatedTenant;
  }

  @Action
  async deleteTenant(tenantId: string): Promise<void> {
    this.setLoading(true);
    await rentalsService.tenants.remove({ id: tenantId });
    this.removeTenant(tenantId);
    this.setLoading(false);
  }
}
