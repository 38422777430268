
















































import { defineComponent, ref, Ref } from "@vue/composition-api";

import { VForm } from "@/models";
import { DocumentTag, DocumentCreate } from "@edmp/api";
import { accountingPeriodsStore, documentsStore, productsStore } from "@/store";

export default defineComponent({
  name: "EventsGeneralAssemblyAddDocumentsModal",
  props: {
    event: { type: Object, required: true },
  },
  setup(props, context) {
    const infos: Ref<DocumentCreate> = ref({
      tags: ["event"] as DocumentTag[],
      product: {
        id: productsStore.currentId,
      },
      generaleAssembly: {
        id: props.event.id,
      },
    });
    const file: Ref<File | null> = ref(null);

    const validate = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        try {
          if (
            productsStore.currentId &&
            accountingPeriodsStore.currentId &&
            file.value
          )
            await documentsStore.createDocument({
              document: infos.value,
              file: file.value,
            });
          context.emit("documentAdded");
          context.emit("close");
        } catch (err) {
          console.error(err);
        }
      }
    };

    return {
      file,
      validate,
    };
  },
});
