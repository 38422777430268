























































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";

import { documentsStore, accountingPeriodsStore } from "@/store";
import { VConfirmDialog } from "@/models";

import { TransactionState, useTransaction } from "./transaction.usable";

import TransactionAttachmentCreate from "../create/TransactionAttachmentCreate.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import { TaxRegime } from "@edmp/api";

export default defineComponent({
  name: "TransactionAttachments",
  components: {
    TransactionAttachmentCreate,
    Confirm,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    const transactionUse = computed(() =>
      useTransaction(props.transactionState, context)
    );

    const attachements = computed(() =>
      documentsStore.getDocumentByTransaction(
        props.transactionState.transaction.id
      )
    );
    const showAttachmentsModal: Ref<boolean> = ref(false);

    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    const isIR = taxRegime === TaxRegime.IR_2072;

    async function deleteAttachment(attachId: string): Promise<void> {
      const attachement = attachements.value?.find(
        (attachement) => attachement.id === attachId
      );
      if (attachement) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          `Êtes-vous sûr de vouloir supprimer le document ${attachement.name} ?`,
          {
            color: "white",
          }
        );
        if (openConfirm) {
          try {
            await documentsStore.deleteDocument(attachId);
          } catch (error) {
            console.error(error);
          } finally {
            transactionUse.value.refreshTransaction();
          }
        }
      }
    }

    return {
      isIR,
      transactionUse,
      attachements,
      showAttachmentsModal,
      deleteAttachment,
    };
  },
});
