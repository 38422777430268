











































































































































import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { accountingPeriodsStore, transactionsStore } from "@/store";
import { FilterKeys } from "@/utils";
import { TaxRegime } from "@edmp/api";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Title from "../title/Title.vue";
import { useTransactions } from "./transactions.usable";

export default defineComponent({
  name: "TransactionsHeader",
  components: {
    Title,
    AccountingPeriodChoice,
  },
  props: {
    readonly: {
      default: false,
      type: Boolean,
    },
    isOpenAddManualTransaction: {
      type: Boolean,
      require: true,
    },
    isOpenAddExpenseReport: {
      type: Boolean,
      require: true,
    },
    isOpenImportManualTransactions: {
      type: Boolean,
      require: true,
    },
    isOpenFilterTransactions: {
      type: Boolean,
      require: true,
    },
  },
  setup(props, context) {
    const { addFilter } = useTransactions();
    // Admin mode to do
    const displayDetails = ref(false);
    const isIS = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
    );
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    const numberOfTransactionsToBeCategorized = computed(
      () => transactionsStore.numberOfTransactionsToBeCategorized
    );
    const numberOfTransactionsToBeSuggested = computed(
      () => transactionsStore.numberOfTransactionsToBeSuggested
    );
    const numberOfTransactionsToBeAnomalized = computed(
      () => transactionsStore.numberOfTransactionsToBeAnomalized
    );

    const sumOfFilteredTransactions = computed(
      () => transactionsStore.sumOfFilteredTransactions
    );

    const openArticle = () =>
      useCrisp().openArticle(
        isIS || isLMNP
          ? ArticleEnum.CATEGORIZE_TRANSACTION_IS
          : ArticleEnum.CATEGORIZE_TRANSACTION
      );

    const $isOpenAddManualTransaction = computed({
      get: () => props.isOpenAddManualTransaction,
      set: (isOpen: boolean) =>
        context.emit("update:isOpenAddManualTransaction", isOpen),
    });
    const $isOpenAddExpenseReport = computed({
      get: () => props.isOpenAddExpenseReport,
      set: (isOpen: boolean) =>
        context.emit("update:isOpenAddExpenseReport", isOpen),
    });
    const $isOpenImportManualTransactions = computed({
      get: () => props.isOpenImportManualTransactions,
      set: (isOpen: boolean) =>
        context.emit("update:isOpenImportManualTransactions", isOpen),
    });
    const $isOpenFilterTransactions = computed({
      get: () => props.isOpenFilterTransactions,
      set: (isOpen: boolean) =>
        context.emit("update:isOpenFilterTransactions", isOpen),
    });

    return {
      numberOfTransactionsToBeCategorized,
      numberOfTransactionsToBeSuggested,
      numberOfTransactionsToBeAnomalized,
      $isOpenAddManualTransaction,
      $isOpenAddExpenseReport,
      $isOpenImportManualTransactions,
      $isOpenFilterTransactions,
      FilterKeys,
      addFilter,
      openArticle,
      isIS,
      isLMNP,
      sumOfFilteredTransactions,
      displayDetails,
    };
  },
});
