import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { Amount, CurrencyAndAmount, decimal2JSON, IsoDateAndTime, PartialField, RequireField } from "./Common.model";
import { BankAccountStats } from "./BankAccountStats.model";
import { AccessModel } from "./Access.model";
import { ArchivedFields } from "./Archive.model";

export type BankAccountId = string;

/**
 * `BankAccountType` — The type of bank account
 */
export type BankAccountType =
  | "checking"
  | "savings"
  | "deposit"
  | "loan"
  | "market"
  | "joint"
  | "card"
  | "lifeinsurance"
  | "pee"
  | "perco"
  | "article83"
  | "rsp"
  | "pea"
  | "capitalisation"
  | "perp"
  | "madelin"
  | "unknown";

/**
 * `BankAccount` — A bank account
 */
/* The `export` keyword is used to make functions, variables, types, or interfaces available for use in
other files or modules. It allows you to expose the defined entities to be imported and used in
other parts of your codebase. In the given code, `export` is used to make the types, interfaces, and
the `BankAccountModel` available for use in other files. */
export type BankAccount<
  AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  IsArchived extends boolean = boolean
> = {
  id: string;
  accessUsers: AccessModel.AccessUser<AccessType>[];
  name: string; // Name of BankAccount
  number?: string;
  balance: CurrencyAndAmount;
  accountType: BankAccountType;
  firstSeen?: IsoDateAndTime;
  lastSeen?: IsoDateAndTime;
  lastSync: IsoDateAndTime;
  usage?: string;
  bankId: string | "Manual";
  bankName?: string;
  bankLogoUuid?: string;
  bankConnection?: string;

  createdAt: string;
  updatedAt: string;
} & ArchivedFields<IsArchived>;
export type BankAccountRepository<
  AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  IsArchived extends boolean = boolean
> = BankAccount<AccessType, IsArchived>;

export type BankAccountCreate = Omit<
  BankAccountCreateInternal,
  "id" | "accessUsers" | "balance" | "accountType" | "firstSeen" | "lastSeen" | "lastSync" | "usage" | "bankConnection"
>;
export type BankAccountCreateInternal = PartialField<
  Omit<BankAccount, "isArchived" | "isArchivedAt" | "createdAt" | "updatedAt">,
  "id"
>;

export type BankAccountUpdate = Omit<BankAccountUpdateInternal, "accessUsers" | "isArchived" | "isArchivedAt">;
export type BankAccountUpdateInternal<
  AccessType extends AccessModel.AccessTypes = AccessModel.AccessTypes,
  IsArchived extends boolean = boolean
> = RequireField<Omit<Partial<BankAccount<AccessType, IsArchived>>, "createdAt" | "updatedAt">, "id">;

/**
 * `InternalBankAccount` — A Budget Insight bank account
 */
export interface InternalBankAccount {
  // Budget Insight Data
  id: string;
  id_connection?: string;
  name: string;
  currency: string;
  amount: Amount;
  number?: string;
  balance: Amount;
  type: BankAccountType;
  last_update: string;
  usage: string;
  iban?: string; // no save..
  original_name?: string;
}

const bankAccountSchema = new Schema<BankAccountDocument>(
  {
    _id: { type: String, default: () => ulid() },
    accessUsers: { type: [AccessModel.accessUserSchema], required: true },
    name: { type: String, index: true },
    number: { type: String, index: true },
    balance: { amount: Schema.Types.Decimal128, currency: String },
    accountType: String,
    firstSeen: Date,
    lastSeen: Date,
    lastSync: Date,
    usage: String,
    bankId: String,
    bankConnection: String,
    bankName: String,
    bankLogoUuid: String,
    isArchived: { type: Boolean },
    isArchivedAt: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      transform(doc, ret: BankAccountDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);

export type BankAccountDocument = BankAccountRepository & Document<string>;

// Name of the collection in third argument
export const BankAccountModel = model<BankAccountDocument>("BankAccount", bankAccountSchema, "BankAccounts");

// API
export namespace BankAccountsService {
  export type CreateIn = BankAccountCreate;
  export type CreateOut = BankAccount;

  export type ListIn = { productId?: string };
  export type ListOut = BankAccount[];

  export type ListPaginateIn = { page: number; limit: number };
  export type ListPaginateOut = BankAccount[];

  export type GetIn = Pick<BankAccount, "id">;
  export type GetOut = BankAccount;

  export type CountIn = never;
  export type CountOut = { count: number };

  export type StockIn = { date?: string };
  export type StockOut = BankAccountStats;
}

/**
 * * Lib
 */
export namespace BankAccountsLib {
  export const isUserAccessType =
    <AccessType extends AccessModel.AccessTypes>(accessUserType: AccessType, userId: string) =>
    (bankAccount: BankAccount): bankAccount is BankAccount<AccessType> =>
      bankAccount.accessUsers.some((a) => a.accessType === accessUserType && a.userId === userId);

  export const isArchivedProduct = (
    bankAccount: BankAccount
  ): bankAccount is BankAccount<AccessModel.AccessTypes, true> => !!bankAccount.isArchived;
  export const isNotArchivedProduct = (
    bankAccount: BankAccount
  ): bankAccount is BankAccount<AccessModel.AccessTypes, false> => !bankAccount.isArchived;

  export const getMyUserAccessType = (bankAccount: BankAccount, userId: string) =>
    bankAccount.accessUsers.find((p) => p.userId === userId)!.accessType;
}
