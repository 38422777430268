















































import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { defineComponent } from "@vue/composition-api";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";

import { TitleH1 } from "./title";

export default defineComponent({
  name: "PageHeader",
  props: {
    displayHelperWithId: {
      type: String,
      default: null,
      validator: function (value) {
        return (Object.values(ArticleEnum) as string[]).includes(
          value as string
        );
      },
    },
    displayAccountingPeriod: {
      type: Boolean,
      default: false,
    },
    addElementText: {
      type: String,
      default: null,
    },
  },
  components: {
    AccountingPeriodChoice,
    TitleH1,
  },
  setup(props) {
    return {
      openArticleDocumentsSaving: () =>
        props.displayHelperWithId &&
        useCrisp().openArticle(props.displayHelperWithId as ArticleEnum),
    };
  },
});
