






















































































import { ROUTE_NAMES } from "@/router/routes";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SignTemplate",
  props: {
    homeLinkActive: {
      type: Boolean,
      default: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { ROUTE_NAMES };
  },
});
