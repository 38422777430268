<script>
import { Doughnut } from "vue-chartjs";

export default {
  name: "LoansChart",
  extends: Doughnut,
  props: {
    realEstateLoan: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.render();
  },
  watch: {
    realEstateLoan: {
      handler: function () {
        this.render();
      },
      deep: true,
    },
  },
  methods: {
    render: function () {
      const loanAmountRefunded = Math.round(
        this.realEstateLoan.loanAmount -
          (this.realEstateLoan?.currentAmortisation?.endingBalance ?? 0)
      );
      const loanEndingBalance = Math.round(
        this.realEstateLoan?.currentAmortisation?.endingBalance ?? 0
      );
      this.renderChart(
        {
          labels: ["Capital Restant dû", "Montant remboursé"],
          datasets: [
            {
              data: [loanEndingBalance, loanAmountRefunded],
              backgroundColor: ["#DFEEFF", "#1C75C9"],
              borderWidth: 0,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: "bottom",
          },
          tooltip: {
            enabled: true,
          },
          cutoutPercentage: 87,
        }
      );
    },
  },
};
</script>
