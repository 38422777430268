import axios from "axios";
import { BanksService } from "@edmp/api";

const api = "/api/v1/banks";

class BanksService {
  async getConfig(params?: BanksService.ConfigIn) {
    return axios
      .get<BanksService.ConfigOut>(`${api}/config`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async list(params: BanksService.ListIn) {
    return axios.get<BanksService.ListOut>(`${api}`, { params }).then((res) => {
      return res.data;
    });
  }

  async listProviders(params?: BanksService.ListProvidersIn) {
    return axios
      .get<BanksService.ListProvidersOut>(`${api}/providers`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async create(bankCreate: BanksService.CreateIn) {
    const res = await axios.post<BanksService.CreateOut>(`${api}`, bankCreate);
    return res.data;
  }

  async reconnect(params: BanksService.ReconnectIn) {
    const res = await axios.get<BanksService.CreateOut>(
      `${api}/${params.bankId}/reconnect`,
      { params }
    );
    return res.data;
  }

  async manage(params: BanksService.ManageIn) {
    const res = await axios.get<BanksService.ManageOut>(
      `${api}/${params.bankId}/manage`,
      { params }
    );
    return res.data;
  }

  async synchronize(params: BanksService.CreateSynchronizationsIn) {
    const res = await axios.get<BanksService.CreateSynchronizationsOut>(
      `${api}/synchronizations`,
      { params }
    );
    return res.data;
  }
}

// Export a singleton instance in the global namespace
export const banksService = new BanksService();
