import { Schema } from "mongoose";

export namespace AccessModel {
  /**
   * * Types
   */
  export enum AccessTypes {
    OWNED = "owned",
    GRANTED = "granted",
    NONE = "none",
  }
  export type AccessUser<AccessType extends AccessTypes = AccessTypes> = {
    accessType: AccessType;
    userId: string;
  };

  export const accessUserSchema = new Schema<AccessUser>(
    {
      accessType: { type: String, enum: Object.values(AccessTypes), required: true, index: true },
      userId: { type: String, required: true, index: true },
    },
    {
      _id: false,
      timestamps: false,
    }
  );
}
