


























































import { defineComponent, PropType, ref } from "@vue/composition-api";
import { upperFirst } from "lodash";

export type TableHeaderItem = {
  text: string;
  value: string;
  sortable?: boolean;
  width?: string;
  align?: "start" | "center" | "end";
};
export type TableHeaderItems = TableHeaderItem[];

/**
 * @Component GenericTable
 * @exports TableHeaderItem
 * @exports TableHeaderItems
 * @slot {Object<{headerValue: string, item: TableHeaderItem, value: string, rowIndex: number }> item.`${headers[].value}`
 * @slot {Object<{props: Object<https://v2.vuetifyjs.com/en/api/v-data-table/#slots-footer>, on: object, headers: TableHeaderItems, widths: [] }> footer
 * @slot actions
 * @prop {TableHeaderItems} headers, required
 * @prop {Array} items, required
 * @prop {Number} itemsPerPage
 * @prop {String} groupBy, default: undefined, groupe item by header key
 * @prop {Boolean} hideGroupByCloseButton
 * @prop {Boolean} hideHeader
 * @prop {Boolean} hideFooter
 * @prop {Boolean} disableSort
 * @prop {String} noDataTextHtml
 * @prop {Boolean} isEnableCustomFooter, Activate footer slot
 */
export default defineComponent({
  name: "GenericTable",
  props: {
    headers: { type: Array as PropType<TableHeaderItems>, required: true },
    items: { type: Array, require: true },
    itemsPerPage: { type: Number, default: 10 },
    groupBy: { type: String, default: undefined },
    hideGroupByCloseButton: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    disableSort: { type: Boolean, default: false },
    noDataTextHtml: { type: String },
    isEnableCustomFooter: { type: Boolean, default: false },
  },
  setup(props) {
    const expanded = ref([]);
    const groupBySummary = (headerValue: string, groupValue) => {
      const headerItem = props.headers.find(
        (header) => header.value === headerValue
      );
      if (headerItem) {
        return `${upperFirst(headerItem.text)}: ${groupValue}`;
      }
      return "";
    };

    return { groupBySummary, expanded };
  },
});
