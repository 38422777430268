var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EventsGeneralAssemblyHeader',{attrs:{"title":"Mes événements - Assemblée Générale","displayHelper":"","displayFilter":true}}),(
      _vm.generalAssemblyEventNotCompleted &&
      _vm.generalAssemblyEventNotCompleted.length != 0
    )?_c('div',{staticClass:"d-flex flex-row mt-4 flex-wrap"},_vm._l((_vm.generalAssemblyEventNotCompleted),function(event){return _c('EventsGeneralAssemblyCard',{key:event.id,attrs:{"event":event}})}),1):_vm._e(),(
      _vm.generalAssemblyEventCompleted &&
      _vm.generalAssemblyEventCompleted.length != 0
    )?_c('div',{staticClass:"d-flex flex-row mt-4 flex-wrap"},_vm._l((_vm.generalAssemblyEventCompleted),function(event){return _c('EventsGeneralAssemblyCard',{key:event.id,attrs:{"event":event}})}),1):_vm._e(),(
      _vm.generalAssemblyEventNotCompleted.length == 0 &&
      _vm.generalAssemblyEventCompleted.length == 0
    )?_c('div',{staticClass:"grey--text text-center"},[_vm._v(" Aucune donnée disponible: aucune Assemblée Générale réalisée avec Ownily ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }