





































































































import { productsStore, coreStore, transactionsStore } from "@/store";
import {
  ProductTourStep,
  useProductTourSteps,
} from "./ProductTourSteps.usable";
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
} from "@vue/composition-api";

export default defineComponent({
  name: "ProductTour",
  setup(props, context) {
    const overlay: Ref<boolean> = ref(false);

    const { steps } = useProductTourSteps(context, coreStore, overlay);

    function addConditionnalStep(
      steps: ProductTourStep[],
      step: ProductTourStep,
      condition: boolean,
      index: number
    ): ProductTourStep[] {
      if (condition) steps.splice(index, 0, step);
      return steps;
    }

    const DEFAULT_CALLBACKS = {
      onStart: () => {
        overlay.value = true;
      },
      onSkip: () => {
        overlay.value = false;
        context.root.$router.push({
          query: { welcome: "skip" },
        });
      },
      onFinish: () => {
        overlay.value = false;
        coreStore.closeMobileMenu();
        context.root.$router.push({
          query: { welcome: "end" },
        });
      },
    };

    // -- Global Product Tour --
    const globalSteps: ComputedRef<ProductTourStep[]> = computed(() => {
      let st = [
        steps.stepTasks,
        steps.stepSettingsSCI,
        steps.stepActivities,
        steps.stepSubscription,
        steps.stepBank,
        steps.stepTransactions,
        steps.stepAddTransaction,
        steps.stepCerfa2072,
        steps.stepAccounting,
        steps.stepAgo,
        steps.stepDocuments,
        steps.stepEventsYearEnd,
        steps.stepProfile,
        steps.stepHelpCenter,
        steps.stepHelpers,
        steps.stepEnd,
      ];
      const initalNbSteps = st.length;

      st = addConditionnalStep(
        st,
        steps.stepShowTransactionsCerfa2072,
        !!productsStore.currentProduct,
        8 + (st.length - initalNbSteps)
      );
      return st;
    });
    // -- End Global Product Tour --

    // -- Dashboard Product Tour --
    const dashboardSteps: ComputedRef<ProductTourStep[]> = computed(() => {
      const st = [
        steps.stepTasks,
        steps.stepSettingSCI,
        steps.stepActivities,
        steps.stepProfile,
        steps.stepHelpCenter,
      ];
      return st;
    });
    // -- End Dashboard Product Tour --

    // -- Product Product Tour --
    const productSteps: ComputedRef<ProductTourStep[]> = computed(() => {
      const st = [steps.subscription, steps.bank];
      return st;
    });
    // -- End Product Product Tour --

    // -- Transaction Product Tour --
    const transactionSteps: ComputedRef<ProductTourStep[]> = computed(() => {
      let st = [
        steps.stepTransactions,
        steps.stepAddTransaction,
        steps.stepFiltersTransactions,
      ];
      st = addConditionnalStep(
        st,
        steps.stepTransaction,
        Object.keys(transactionsStore.filteredTransactionsGroupByMonth).length >
          0,
        1
      );
      return st;
    });
    // -- End Transaction Product Tour --

    // -- Cerfa 2072 Product Tour --
    const cerfa2072Steps: Ref<ProductTourStep[]> = computed(() => {
      let st = [steps.stepCerfa2072];
      st = addConditionnalStep(
        st,
        steps.stepShowTransactionsCerfa2072,
        !!productsStore.currentProduct,
        1
      );
      return st;
    });
    // -- End Cerfa 2072 Product Tour --

    // -- AGO Product Tour --
    const agoSteps: Ref<ProductTourStep[]> = ref([steps.stepAgo]);
    // -- End AGO Product Tour --

    // -- Accounting Product Tour --
    const accountingSteps: Ref<ProductTourStep[]> = ref([steps.stepAccounting]);
    // -- Accounting Product Tour --

    // -- Event year end Product Tour --
    const eventsYearEndSteps: Ref<ProductTourStep[]> = ref([
      steps.stepEventsYearEnd,
    ]);
    // -- End Event year end Product Tour --

    // -- Documents Product Tour --
    const documentsSteps: Ref<ProductTourStep[]> = ref([steps.stepDocuments]);
    // -- End Documents Product Tour --

    const unexpectedStopProductTour = (event: Event, name: string) => {
      console.error("Target Product Tour not found", event.target);
      overlay.value = false;
      context.root.$tours[name].stop();
    };

    return {
      options: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: "Passer le guide",
          buttonPrevious: "< Précédent",
          buttonNext: "Suivant >",
          buttonStop: "Ok, j'ai compris",
        },
        highlight: true,
        stopOnTargetNotFound: false,
      },
      overlay,
      globalSteps,
      dashboardSteps,
      productSteps,
      transactionSteps,
      accountingSteps,
      cerfa2072Steps,
      agoSteps,
      eventsYearEndSteps,
      documentsSteps,
      DEFAULT_CALLBACKS,
      unexpectedStopProductTour,
    };
  },
});
