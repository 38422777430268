





















import { defineComponent } from "@vue/composition-api";
import { addAlphaTo6DigitsHexColor } from "@/utils/color";

export default defineComponent({
  name: "ToggleButton",
  model: {
    prop: "isEnabled",
    event: "toggle",
  },
  props: {
    isEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "#222291",
    },
    secondaryColor: {
      type: String,
      required: false,
      default: "#E0E0E0",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    return {
      lightenColor: props.disabled
        ? addAlphaTo6DigitsHexColor("#bdbdbd", 0.4)
        : addAlphaTo6DigitsHexColor(props.color, 0.4),
      enhancedColor: props.disabled ? "#bdbdbd" : props.color,
    };
  },
  watch: {
    disabled: function () {
      this.lightenColor = this.disabled
        ? addAlphaTo6DigitsHexColor("#bdbdbd", 0.4)
        : addAlphaTo6DigitsHexColor(this.color, 0.4);
      this.enhancedColor = this.disabled ? "#bdbdbd" : this.color;
    },
  },
  methods: {
    toggle: function () {
      if (!this.disabled) {
        this.$emit("toggle", !this.isEnabled);
      }
    },
  },
});
