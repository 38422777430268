


































import {
  defineComponent,
  reactive,
  toRefs,
  UnwrapRef,
} from "@vue/composition-api";

interface ConfirmModalState {
  dialog: boolean;
  resolve?: (value: boolean) => void;
  reject?: (value: boolean) => void;
  message?: string;
  options: {
    color: string;
    width: number;
    zIndex: number;
  };
}

export default defineComponent({
  setup() {
    const state: UnwrapRef<ConfirmModalState> = reactive({
      dialog: false,
      resolve: undefined,
      reject: undefined,
      message: undefined,
      options: {
        color: "primary",
        width: 300,
        zIndex: 200,
      },
    } as ConfirmModalState);

    const open = (message, options) => {
      state.dialog = true;
      state.message = message;
      state.options = Object.assign(state.options, options);
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const agree = () => {
      if (state.resolve) state.resolve(true);
      state.dialog = false;
    };

    const cancel = () => {
      if (state.resolve) state.resolve(false);
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      open,
      agree,
      cancel,
    };
  },
});
