















import GroupCard from "@/components/core/taskActivity/group/GroupCard.vue";
import { taskActivityStore } from "@/store";
import { TaskGroupCode, TaskActivityStatus } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { uniqBy } from "lodash";

export default defineComponent({
  name: "TaskActivityGroupResumeNavigateTo",
  components: {
    GroupCard,
  },
  props: {
    taskGroupCodes: {
      type: Array as PropType<TaskGroupCode[]>,
      required: true,
    },
  },
  setup(props) {
    const startWellTaskGroups = computed(() => {
      return taskActivityStore.startWellTaskGroup;
    });
    const closureCustomTaskGroups = computed(() => {
      return taskActivityStore.closureCustomTaskGroup;
    });
    const eventAccountingPeriodClosureTaskGroup = computed(() => {
      return taskActivityStore.eventAccountingPeriodClosureTaskGroup;
    });

    const taskGroups = computed(() =>
      uniqBy(
        [
          ...startWellTaskGroups.value,
          ...closureCustomTaskGroups.value,
          ...eventAccountingPeriodClosureTaskGroup.value,
        ],
        "groupCode"
      ).filter(({ groupCode }) =>
        props.taskGroupCodes.includes(groupCode as TaskGroupCode)
      )
    );

    return { taskGroups, TaskActivityStatus };
  },
});
