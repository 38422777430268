import { Address, addressSchema, Amount } from "./Common.model";
import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";

// /!\ This model is use to archive document. It should match SummaryTable and tax-declaration-v2021.csv
export interface TaxDeclaration2072RentalBuilding {
  rentalBuildingId: string;
  name: string;
  address?: Address;
  rentalUnitTotal: string;
  taxDeduction: string;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  line6: string;
  line7: string;
  line8: string;
  line9: string;
  line9b: string;
  line10: string;
  line11: string;
  line12: string;
  line13: string;
  line14: string;
  line15: string;
  line16: string;
  line17: string;
  line18: string;
  line19: string;
  line20: string;
}

// /!\ This model is use to archive document. It should match SummaryTable and tax-declaration-v2021.csv
export interface TaxDeclaration2072 {
  id?: string;
  productId: string;
  accountingPeriodId: string;
  userId: string;
  activityName: string;
  activityAddress: Address;
  activitySiret: string;
  yearPeriod: string;
  userLastName: string;
  userFirstName: string;
  taxRegime: string;
  r1?: Amount;
  r2?: Amount;
  r3?: Amount;
  r4?: Amount;
  r5?: Amount;
  r6?: Amount;
  rentalBuildings: TaxDeclaration2072RentalBuilding[];
}

const rentalBuildingsSchema = new Schema<TaxDeclaration2072RentalBuilding>(
  {
    rentalBuildingId: { type: String, index: true },
    name: String,
    address: addressSchema,
    rentalUnitTotal: String,
    taxDeduction: String,
    line1: String,
    line2: String,
    line3: String,
    line4: String,
    line5: String,
    line6: String,
    line7: String,
    line8: String,
    line9: String,
    line9b: String,
    line10: String,
    line11: String,
    line12: String,
    line13: String,
    line14: String,
    line15: String,
    line16: String,
    line17: String,
    line18: String,
    line19: String,
    line20: String,
    line21: String,
    line22: String,
  },
  { _id: false }
);

const TaxDeclaration2072Schema = new Schema<TaxDeclaration2072 & Document<string>>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true },
    accountingPeriodId: { type: String, index: true },
    userId: { type: String, index: true },
    activityName: String,
    activityAddress: addressSchema,
    activitySiret: String,
    yearPeriod: String,
    userLastName: String,
    userFirstName: String,
    taxRegime: String,
    r1: String,
    r2: String,
    r3: String,
    r4: String,
    r5: String,
    r6: String,
    rentalBuildings: [rentalBuildingsSchema],
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(_, ret: TaxDeclaration2072Document): unknown {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type TaxDeclaration2072Document = TaxDeclaration2072 & Document<string>;
// Name of the collection in third argument
export const TaxDeclaration2072Model = model<TaxDeclaration2072Document>(
  "TaxDeclaration2072",
  TaxDeclaration2072Schema,
  "TaxDeclarations2072"
);
