import { ref, Ref, watch, onBeforeMount } from "@vue/composition-api";
import {
  productsStore,
  fixedAssetsStore,
  accountingPeriodsStore,
  fixedAssetAmortisationsStore,
} from "@/store";
import {
  FixedAsset,
  sumAmortizationValueStatsForAFixedAsset,
  sumAllAmortizationValueStatsForAnAccountingPeriodFixedAsset,
  getFixedAssetTypeTexts,
} from "@edmp/api";
import { getMoment, AmortisationType } from "@edmp/api";

type CollectionStringToString = { [key: string]: string };
type amortizationDaysStatsForCurrentAccountingPeriod = {
  amortizedDays: number;
  daysInCurrentAccountingPeriod: number;
  remainingDaysToAmortize: number;
};
type amortizationValuesStatsForCurrentAccountingPeriod = {
  valueAmortized: number;
  valueToAmortizedForCurrentAccountingPeriod: number;
  remainingValueToAmortize: number;
};
export function useFixedAssetAmortisation() {
  const fixedAssets: Ref<FixedAsset[]> = ref([]);
  const isLoadingFixedAssets: Ref<boolean> = ref(true);
  const totalAmortisationFixedAssets: Ref<string> = ref("");
  const totalAmortizedFixedAssets: Ref<string> = ref("");
  const totalAmortisationByFixedAssets: Ref<CollectionStringToString> = ref({});
  const totalAmortizedByFixedAssets: Ref<CollectionStringToString> = ref({});

  const amortisationTypeText: {
    text: string;
    value: AmortisationType;
  }[] = getFixedAssetTypeTexts();

  const init = async () => {
    isLoadingFixedAssets.value = true;

    // Reset values
    fixedAssets.value = [];
    totalAmortisationFixedAssets.value = "";
    totalAmortizedFixedAssets.value = "";
    // Fetch data
    fixedAssets.value = fixedAssetsStore.fixedAssets;

    // Calculate new values
    const totalStats =
      sumAllAmortizationValueStatsForAnAccountingPeriodFixedAsset(
        accountingPeriodsStore.accountingPeriods,
        accountingPeriodsStore.currentId,
        fixedAssets.value,
        fixedAssetAmortisationsStore.fixedAssetAmortisations
      );
    totalAmortisationFixedAssets.value =
      totalStats.valueToAmortizedForThisAccountingPeriod.toFixed(2);
    totalAmortizedFixedAssets.value = totalStats.valueAmortized.toFixed(2);

    const tempTotalAmortisationByfixedAssets: CollectionStringToString = {};
    const tempTotalAmortizedByfixedAssets: CollectionStringToString = {};
    for (const fixedAsset of fixedAssets.value) {
      const amortisations =
        fixedAssetAmortisationsStore.fixedAssetAmortisations.filter(
          ({ fixedAssetId }) => fixedAssetId === fixedAsset.id
        );
      if (amortisations.length > 0) {
        const totalStatsByFixedAssets = sumAmortizationValueStatsForAFixedAsset(
          {
            fixedAssetAmortisations:
              fixedAssetAmortisationsStore.fixedAssetAmortisations,
            accountingPeriods: accountingPeriodsStore.accountingPeriods,
            accountingPeriodIdToSum: accountingPeriodsStore.currentId,
            fixedAssetIdToSum: fixedAsset.id,
            fixedAssets: fixedAssetsStore.fixedAssets,
          }
        );
        tempTotalAmortisationByfixedAssets[fixedAsset.id] =
          totalStatsByFixedAssets.valueToAmortizedForThisAccountingPeriod.toFixed(
            2
          );
        tempTotalAmortizedByfixedAssets[fixedAsset.id] =
          totalStatsByFixedAssets.valueAmortized.toFixed(2);
      }
    }
    totalAmortisationByFixedAssets.value = tempTotalAmortisationByfixedAssets;
    totalAmortizedByFixedAssets.value = tempTotalAmortizedByfixedAssets;

    isLoadingFixedAssets.value = false;
  };
  const amortizationDaysStatsForCurrentAccountingPeriod = (
    fixedAssetId: string,
    yearsAmortization: number
  ) => {
    const res: amortizationDaysStatsForCurrentAccountingPeriod = {
      amortizedDays: 0,
      daysInCurrentAccountingPeriod: 0,
      remainingDaysToAmortize: 0,
    };
    const fixedAsset = fixedAssetsStore.getFixedAsset(fixedAssetId);
    if (fixedAsset?.commissioningAt) {
      if (
        getMoment(fixedAsset.commissioningAt) <
        getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt)
      ) {
        res.daysInCurrentAccountingPeriod =
          getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt).diff(
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt),
            "days"
          ) + 1;
      } else {
        res.daysInCurrentAccountingPeriod =
          getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt).diff(
            getMoment(fixedAsset.commissioningAt),
            "days"
          ) + 1;
      }

      res.amortizedDays = getMoment(
        accountingPeriodsStore.currentAccountingPeriod?.startAt
      ).diff(getMoment(fixedAsset.commissioningAt), "days");
      if (res.amortizedDays < 0) {
        res.amortizedDays = 0;
      }
      res.remainingDaysToAmortize = getMoment(fixedAsset.commissioningAt)
        .add(yearsAmortization, "years")
        .diff(
          getMoment(fixedAsset.commissioningAt).add(res.amortizedDays, "days"),
          "days"
        );
      if (res.remainingDaysToAmortize < 0) {
        res.remainingDaysToAmortize = 0;
      }
      if (res.daysInCurrentAccountingPeriod < 0) {
        res.daysInCurrentAccountingPeriod = 0;
      }
      return res;
    }
  };
  const amortizationValueStatsForCurrentAccountingPeriod = (
    fixedAssetId: string,
    yearsAmortization: number,
    valueComponent: number
  ) => {
    const res: amortizationValuesStatsForCurrentAccountingPeriod = {
      valueAmortized: 0,
      valueToAmortizedForCurrentAccountingPeriod: 0,
      remainingValueToAmortize: 0,
    };
    const daysStats = amortizationDaysStatsForCurrentAccountingPeriod(
      fixedAssetId,
      yearsAmortization
    );
    if (daysStats) {
      const allDays =
        daysStats.amortizedDays + daysStats.remainingDaysToAmortize;
      res.valueAmortized = Math.round(
        (valueComponent / allDays) * daysStats.amortizedDays
      );
      if (
        daysStats.daysInCurrentAccountingPeriod <
        daysStats.remainingDaysToAmortize
      ) {
        res.valueToAmortizedForCurrentAccountingPeriod = Math.round(
          (valueComponent / allDays) * daysStats.daysInCurrentAccountingPeriod
        );
      } else {
        res.valueToAmortizedForCurrentAccountingPeriod = Math.round(
          (valueComponent / allDays) * daysStats.remainingDaysToAmortize
        );
      }
      res.remainingValueToAmortize = Math.round(
        (valueComponent / allDays) * daysStats.remainingDaysToAmortize
      );
    }
    return res;
  };
  watch(
    () => [fixedAssetAmortisationsStore.fixedAssetAmortisations],
    async () => await init(),
    {
      deep: true,
    }
  );
  watch(
    () => [fixedAssetsStore.fixedAssets],
    async () => await init(),
    {
      deep: true,
    }
  );

  watch(
    () => [productsStore.currentId, accountingPeriodsStore.currentId],
    async () => {
      await productsStore.switchProduct({ id: productsStore.currentId });
      await fixedAssetAmortisationsStore.fetchFixedAssetAmortisations(
        productsStore.currentId
      );
      await init();
    },
    {
      deep: true,
    }
  );

  onBeforeMount(async () => await init());

  return {
    amortizationDaysStatsForCurrentAccountingPeriod,
    amortizationValueStatsForCurrentAccountingPeriod,
    fixedAssets,
    isLoadingFixedAssets,
    totalAmortisationFixedAssets,
    totalAmortisationByFixedAssets,
    totalAmortizedFixedAssets,
    totalAmortizedByFixedAssets,
    amortisationTypeText,
  };
}
