





































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "@vue/composition-api";
import {
  TaxDeclaration2031,
  TaxDeclaration2031LineNumber,
  taxDeclaration2031LinesMetadata,
  Direction,
  TaxDeclaration2031LineNumberSummaryOfTaxElements,
  isTaxDeclaration2031LineNumberSummaryOfTaxElements,
  SubscriptionsModel,
} from "@edmp/api";
import { isArray } from "lodash";

import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  documentsStore,
  subscriptionsStore,
} from "@/store";
import { ArticleEnum } from "@/composables/crisp.usable";
import {
  TaxDeclaration2031Compute,
  TaxDeclaration2031LineCompute,
} from "@/models";

import TitleNew from "@/components/core/title/TitleNew.vue";
import PageHeader from "@/components/atom/PageHeader.vue";
import { DevButton } from "@/components/atom/button";
import { HelpingMessage } from "@/components/atom/helping";
import TaxDeclaration2031Lines from "./TaxDeclaration2031Lines.vue";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";
import TaxDeclarationValidate from "../../events/yearEnd/TaxDeclarationValidate.vue";
import TeletransmitFollowUp from "../TeletransmitFollowUp.vue";
import DialogSubscribe from "../DialogSubscribe.vue";

export default defineComponent({
  name: "TaxDeclaration2031",
  components: {
    TitleNew,
    PageHeader,
    DevButton,
    HelpingMessage,
    TaxDeclaration2031Lines,
    TeletransmitFollowUp,
    TaxDeclarationValidate,
    DialogSubscribe,
  },
  setup() {
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);

    const taxDeclaration2031: Ref<TaxDeclaration2031 | undefined> = ref();
    const subscription: Ref<SubscriptionsModel.Subscription | undefined> =
      ref();

    const isOpenDialogSubscribe = ref(false);

    const taxDeclaration2031Compute = computed(() => {
      const sectionTitles = {
        "Récapitulation des éléments d'imposition": {
          "dont BIC non professionnels (2031 Bis-SD)": [
            TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_BENEFIT,
            TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
          ],
        },
        // "BIC non professionnels": {
        //   "Détermination du résultat de l'exercice": [
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //     TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT,
        //   ],
        // },
      };

      const formatLines = (linesNumber: TaxDeclaration2031LineNumber[]) =>
        linesNumber
          .map((lineNumber) => {
            if (
              isTaxDeclaration2031LineNumberSummaryOfTaxElements(lineNumber)
            ) {
              return {
                lineNumber,
                title: `Ligne ${lineNumber}`,
                ...taxDeclaration2031LinesMetadata[lineNumber],
                ...taxDeclaration2031.value?.lines[lineNumber],
                ...(subscription.value
                  ? { subscriptionPlanType: subscription.value.plan.type }
                  : {}),
                value: taxDeclaration2031.value?.lines[lineNumber]
                  ? subscription.value
                    ? subscription.value.plan.type ===
                      SubscriptionsModel.PlanType.Free
                      ? "€"
                      : `${
                          taxDeclaration2031.value?.lines[
                            lineNumber as TaxDeclaration2031LineNumberSummaryOfTaxElements
                          ].amountDirection === Direction.debit
                            ? "-"
                            : ""
                        }${taxDeclaration2031.value?.lines[
                          lineNumber as TaxDeclaration2031LineNumberSummaryOfTaxElements
                        ].amount.toLocaleString()}€`
                    : "0 €"
                  : "0 €",
              };
            }
          })
          .filter(
            (taxDeclaration2031Compute) =>
              taxDeclaration2031Compute !== undefined
          ) as TaxDeclaration2031LineCompute;

      const taxDeclaration2031Compute = Object.entries(sectionTitles).reduce(
        (taxDeclaration2031Compute, [title, subTitleOrLinesNumber]) => {
          if (isArray(subTitleOrLinesNumber)) {
            const linesNumber: TaxDeclaration2031LineNumber[] =
              subTitleOrLinesNumber;
            taxDeclaration2031Compute[title] = formatLines(linesNumber);
          } else {
            const taxDeclaration2031SubCompute = Object.entries(
              subTitleOrLinesNumber
            ).reduce(
              (taxDeclaration2031SubCompute, [subTitle, linesNumber]) => {
                taxDeclaration2031SubCompute[subTitle] =
                  formatLines(linesNumber);
                return taxDeclaration2031SubCompute;
              },
              {} as { [sectionSubTitle: string]: TaxDeclaration2031LineCompute }
            );

            taxDeclaration2031Compute[title] = taxDeclaration2031SubCompute;
          }
          return taxDeclaration2031Compute;
        },
        {} as TaxDeclaration2031Compute
      );

      return taxDeclaration2031Compute;
    });

    // Methods
    const showDev = computed(() => coreStore.isNotProduction);
    const isOpnTaxDeclarationValidate = ref(false);
    const openTaxDeclarationValidate = async () => {
      isOpnTaxDeclarationValidate.value = true;
    };

    // Init
    const init = async () => {
      taxDeclaration2031.value = await taxDeclarationsService.get2031({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      documentsStore.fetchDocuments(productsStore.currentId);
      subscription.value = subscriptionsStore.getSubscriptionByProduct(
        taxDeclaration2031.value.productId
      );
      if (subscription.value?.plan.type === SubscriptionsModel.PlanType.Free) {
        isOpenDialogSubscribe.value = true;
      }
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      isOpenDialogSubscribe,
      isLMNP,
      init,
      showDev,
      openTaxDeclarationValidate,
      isOpnTaxDeclarationValidate,
      taxDeclaration2031Compute,
      isArray,
      ArticleEnum,
    };
  },
});
