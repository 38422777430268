import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { bankAccountsService } from "@/services";
import { BankAccount } from "@edmp/api";

export interface BankAccountsState {
  bankAccounts: Array<BankAccount>;
  loading: boolean;
}

@Module({
  name: "bank-accounts-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class BankAccountsStore extends VuexModule implements BankAccountsState {
  // List of bank accounts for current product selected
  bankAccounts: Array<BankAccount> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.bankAccounts = [];
    this.loading = false;
  }

  @Mutation
  setBankAccounts(bankAccounts: BankAccount[]): void {
    this.bankAccounts = bankAccounts;
  }

  @Mutation
  addBankAccount(bankAccount: BankAccount): void {
    this.bankAccounts = [...this.bankAccounts, bankAccount];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async fetchBankAccounts(productId: string): Promise<void> {
    this.setLoading(true);
    this.setBankAccounts(await bankAccountsService.list({ productId }));
    this.setLoading(false);
  }
}
