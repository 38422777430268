import { ulid } from "ulid";
import { model, Schema, Document } from "mongoose";
import { ApiList, decimal2JSON, IsoDate } from "./Common.model";
import Decimal from "decimal.js-light";
import { RequireField } from "..";

// Why IsoDate :
// - We use IsoDate to save date with format  ISO8601 as YYYY-MM-DD.
// - To avoid time zones error  and day shifts at midnight

export enum InsuranceAmountType {
  FIXED = "FIXED", // Insurance amount is constant during real estate loan
  PERCENT_BORROWED = "PERCENT_BORROWED", // Percentage of total amount borrowed
  PERCENT_BALANCE = "PERCENT_BALANCE", // Percentage of total amount remaining
}

// Object to create a Real Estate Loan
export interface NewRealEstateLoan {
  name: string; // Name of the real estate loan
  productId: string;
  realEstateAssetId: string;
  loanType: LoanType;
  loanStartAt: IsoDate; // Date de 1ère mensualité ou échéance (1er jour du paiement)
  loanAmount: number; // Capital emprunté
  loanPeriodInMonths: number; // Durée du prêt en mois
  rateExcludedInsurance: number;
  insuranceAmount: number; // Amount in euro or in percent
  insuranceAmountType: InsuranceAmountType;
  insuranceIncludedInLoan?: boolean;
  installmentAmount?: number; // Mensualité
}
export type NewRealEstateLoanInternal = Pick<
  RealEstateLoan,
  | "name"
  | "productId"
  | "realEstateAssetId"
  | "loanType"
  | "loanStartAt"
  | "loanEndAt"
  | "loanAmount"
  | "loanPeriodInMonths"
  | "rateExcludedInsurance"
  | "insuranceAmount"
  | "insuranceAmountType"
  | "insuranceIncludedInLoan"
  | "installmentAmount"
  | "amortisationLines"
  | "totalPayment"
  | "totalPrincipal"
  | "totalInterest"
  | "totalInsurance"
>;
// Loan type enum
export enum LoanType {
  DEPRECIABLE = "depreciable", // Pret Amortissable
  REFUNDABLE = "refundable", // IN FINE
  OTHER = "other",
}

// Return True if loan type is automatized in Categorization & Suggestion
export const isLoanTypeAutomatized = (loanType: LoanType): boolean => LoanType.DEPRECIABLE === loanType;

// Loan type label for PDF
const loanTypeText = new Map<LoanType, string>([
  [LoanType.DEPRECIABLE, "Amortissable"],
  [LoanType.REFUNDABLE, "In fine"],
  [LoanType.OTHER, "Autre"],
]);

export const getLoanTypeText = (loanType: LoanType): string => loanTypeText.get(loanType) ?? "Autre";

const insuranceTypeText = new Map<InsuranceAmountType, string>([
  [InsuranceAmountType.FIXED, "Montant fixe"],
  [InsuranceAmountType.PERCENT_BALANCE, "Pourcentage du capital restant dû"],
  [InsuranceAmountType.PERCENT_BORROWED, "Pourcentage du capital emprunté"],
]);

export const getInsuranceTypeText = (insuranceType: InsuranceAmountType): string =>
  insuranceTypeText.get(insuranceType) ?? "Autre";
/**
 * `AmortisationLine` — A loan amortization line
 *
 * Is is the amount payable every month to the bank until the loan amount is fully paid off
 *
 * - It consists of the interest on loan as well as part of the principal amount to be repaid.
 * - The sum of principal amount and interest is divided by the tenure, i.e., number of months, in which the loan has to be repaid.
 */
export interface AmortisationLine {
  number: number; // Numéro d'échéance
  paymentAt: IsoDate; // Date d'échéance
  beginningBalance?: number; // Total restant du à la date de payement de la dernière échéance
  totalPayment: number; // Total à payer = Principal + Interest
  principal: number; // Amortissement
  interest: number; // Interet
  differedInterest?: number; // Interet différé?
  insurance: number;
  endingBalance?: number;
  cumulativeInterest?: number;
}

// RealEstateLoan extends NewRealEstateLoan
export interface RealEstateLoan extends NewRealEstateLoan {
  id: string;
  // Computed data bellow
  loanEndAt?: IsoDate; // last month of payment is computed  : startAt + period in months
  amortisationLines?: AmortisationLine[];
  totalPayment: number; // Total cost of loan
  totalPrincipal: number; // Amortissement
  totalInterest: number;
  totalInsurance: number;
  // Computed data variable each day... because it depends on the day
  currentAmortisation: AmortisationLine;
  nextAmortisation: AmortisationLine;

  createdAt: string;
  updatedAt: string;
}

export type RealEstateLoanUpdate = Omit<RealEstateLoanUpdateInternal, "productId">;
export type RealEstateLoanUpdateInternal = Omit<
  RealEstateLoanUpdateRepository,
  | "realEstateAssetId"
  | "loanEndAt"
  | "amortisationLines"
  | "totalPayment"
  | "totalPrincipal"
  | "totalInterest"
  | "totalInsurance"
  | "currentAmortisation"
  | "nextAmortisation"
>;
export type RealEstateLoanUpdateRepository = RequireField<
  Partial<Omit<RealEstateLoan, "createdAt" | "updatedAt">>,
  "id"
>;

// List of RealEstateAssets with Pagination
export type RealEstateLoans = ApiList<RealEstateLoan>;

const amortisationLineSchema = new Schema<AmortisationLine>(
  {
    number: Number,
    paymentAt: { type: String, required: true, index: true },
    beginningBalance: { type: Schema.Types.Decimal128 },
    totalPayment: { type: Schema.Types.Decimal128 },
    principal: { type: Schema.Types.Decimal128 },
    interest: { type: Schema.Types.Decimal128 },
    differedInterest: { type: Schema.Types.Decimal128 },
    insurance: { type: Schema.Types.Decimal128 },
    endingBalance: { type: Schema.Types.Decimal128 },
    cumulativeInterest: { type: Schema.Types.Decimal128 },
  },
  {
    _id: false,
  }
);
const realEstateLoanSchema = new Schema<RealEstateLoan>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String, required: true, maxlength: 200 },
    productId: { type: String, required: true, index: true },
    realEstateAssetId: { type: String, index: true, required: true },
    loanType: { type: String, required: true, enum: Object.values(LoanType) },
    loanStartAt: { type: String, required: false },
    loanEndAt: { type: String, required: false },
    loanAmount: { type: Schema.Types.Decimal128 },
    loanPeriodInMonths: { type: Number, required: false },
    installmentAmount: { type: Schema.Types.Decimal128, default: 0 },
    rateExcludedInsurance: { type: Number, required: false },
    insuranceAmount: { type: Schema.Types.Decimal128, default: 0 },
    insuranceAmountType: { type: String, required: false, enum: Object.values(InsuranceAmountType) },
    insuranceIncludedInLoan: { type: Boolean, required: false },
    amortisationLines: [amortisationLineSchema],
    totalPayment: { type: Number, required: false, default: 0 },
    totalPrincipal: { type: Number, required: false, default: 0 },
    totalInterest: { type: Number, required: false, default: 0 },
    totalInsurance: { type: Number, required: false, default: 0 },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RealEstateLoanDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);

export type RealEstateLoanDocument = RealEstateLoan & Document<string>;
// Name of the collection in third argument
export const RealEstateLoanModel = model<RealEstateLoanDocument>(
  "RealEstateLoan",
  realEstateLoanSchema,
  "RealEstateLoans"
);

// API
export namespace RealEstateLoansService {
  export type CreateIn = NewRealEstateLoan;
  export type CreateOut = RealEstateLoan;

  export type ListIn = Partial<Pick<RealEstateLoan, "productId" | "realEstateAssetId">>;
  export type ListOut = RealEstateLoan[];

  export type GetIn = Pick<RealEstateLoan, "id">;
  export type GetOut = RealEstateLoan;

  export type GetPdfIn = Pick<RealEstateLoan, "id">;
  export type GetPdfOut = Buffer;

  export type UpdateIn = RealEstateLoanUpdate;
  export type UpdateOut = RealEstateLoan;

  export type DeleteIn = Pick<RealEstateLoan, "id">;
  export type DeleteOut = boolean;
}

// Compute round for amount in loan with two decimals towards nearest neighbour
export const round2decimals = (value: number | Decimal): number => {
  // If need we could use decimal-js lib to try another round methods
  return new Decimal(value).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
  //return Math.round(value * 100) / 100; // Rounds towards nearest neighbour.
};

/**
 * Monthly rate = Annual Rate divide by 12
 * Annual rate is a percentage, we need to divide also by 100
 * @param annualRatePercent Annual rate in percentage
 */
export const rateByMonth = (annualRatePercent: Decimal | number): Decimal => {
  return new Decimal(annualRatePercent).div(1200);
};

/**
 * Return monthly payment for a realEstateLoan included insurance
 *
 * @param realEstateLoan Loan
 */
export const getMonthlyPayment = <Loan extends NewRealEstateLoan | Omit<RealEstateLoan, "createdAt" | "updatedAt">>(
  realEstateLoan: Loan
): Decimal => {
  if (realEstateLoan.loanType === LoanType.DEPRECIABLE) {
    if (
      realEstateLoan?.loanPeriodInMonths >= 1 &&
      realEstateLoan?.loanAmount > 0 &&
      realEstateLoan?.rateExcludedInsurance >= 0
    ) {
      const periods = new Decimal(realEstateLoan.loanPeriodInMonths);
      const balance = new Decimal(realEstateLoan.loanAmount);
      const insurancePayment = getInsurancePaymentInLoan(realEstateLoan);

      if (realEstateLoan?.rateExcludedInsurance == 0) {
        // Rate is equal to zero for PTZ
        return balance.div(periods).plus(insurancePayment);
      } else if (
        realEstateLoan.insuranceAmountType === InsuranceAmountType.PERCENT_BALANCE &&
        realEstateLoan.insuranceIncludedInLoan
      ) {
        // When Insurance is included in loan AND is a percent of balance we need to compute payment with 2 rates !
        const monthlyRate = rateByMonth(
          new Decimal(realEstateLoan.rateExcludedInsurance).plus(realEstateLoan.insuranceAmount)
        );
        //  (monthlyRate / (1 - Math.pow(1 + monthlyRate, -periods))) * balance;

        return monthlyRate.div(new Decimal(1).sub(new Decimal(1).plus(monthlyRate).toPower(-periods))).mul(balance);
      } else {
        const monthlyRate = rateByMonth(new Decimal(realEstateLoan.rateExcludedInsurance));
        // Return payment plus insurance
        return monthlyRate
          .div(new Decimal(1).sub(new Decimal(1).plus(monthlyRate).toPower(-periods)))
          .mul(balance)
          .plus(insurancePayment);
      }
    }
  }
  // Else
  return new Decimal(0);
};

/*
 * Compute insurance part of payment. It depends of loan type and insurance type
 *
 * Balance parameter is needed to compute month by month insurance of type "Percent of balance"
 */
export const getInsurancePayment = <Loan extends NewRealEstateLoan | Omit<RealEstateLoan, "createdAt" | "updatedAt">>(
  realEstateLoan: Loan,
  balance = new Decimal(realEstateLoan.loanAmount)
): Decimal => {
  if (realEstateLoan.loanType === LoanType.DEPRECIABLE) {
    let assuranceForMonth = new Decimal(0);
    // Convert in number in case of format error (string)
    const insuranceAmount = new Decimal(realEstateLoan.insuranceAmount ?? 0);
    const loanAmount = new Decimal(realEstateLoan.loanAmount ?? 0);
    const type = realEstateLoan.insuranceAmountType;
    if (InsuranceAmountType.FIXED === type) {
      assuranceForMonth = insuranceAmount;
    } else if (InsuranceAmountType.PERCENT_BORROWED === type) {
      const monthlyAssuranceRate = rateByMonth(insuranceAmount);
      assuranceForMonth = loanAmount.mul(monthlyAssuranceRate);
    } else if (InsuranceAmountType.PERCENT_BALANCE === realEstateLoan.insuranceAmountType) {
      const monthlyAssuranceRate = rateByMonth(insuranceAmount);
      assuranceForMonth = new Decimal(balance).mul(monthlyAssuranceRate);
    }

    return assuranceForMonth;
  } else {
    return new Decimal(0);
  }
};

export const getInsurancePaymentInLoan = <
  Loan extends NewRealEstateLoan | Omit<RealEstateLoan, "createdAt" | "updatedAt">
>(
  realEstateLoan: Loan,
  balance = new Decimal(realEstateLoan.loanAmount)
): Decimal => {
  if (realEstateLoan.insuranceIncludedInLoan) {
    return getInsurancePayment(realEstateLoan, balance);
  } else {
    return new Decimal(0);
  }
};
