










































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { AccountingResultLine, AccountingResultLineType } from "@edmp/api";

import { transactionsStore } from "@/store";
import { FilterKeys } from "@/utils";
import { AccountingResultLinesCompute } from "@/models";

import { default as AccountingResultLineView } from "./AccountingResultLine.vue";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "AccountingResultLines",
  components: { AccountingResultLineView },
  props: {
    lines: {
      type: Array as PropType<AccountingResultLinesCompute>,
      required: true,
    },
  },
  setup(props, context) {
    const isAccountingResultLineNumber = computed(
      () => (lineType: string) =>
        Object.values(AccountingResultLineType).includes(
          lineType as AccountingResultLineType
        )
    );

    // Methods
    const goToTransactions = (line: AccountingResultLinesCompute[number]) => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({
        code: FilterKeys.CERFA,
        filterParams: {
          ids: (line as unknown as AccountingResultLine).transactionIds,
          label: `2033 - ${line.title}`,
        },
      });
      context.root.$router.push({
        name: ROUTE_NAMES.Transactions,
      });
    };

    return { isAccountingResultLineNumber, goToTransactions };
  },
});
