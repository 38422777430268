import { SubscriptionsModel } from "@edmp/api";

export const SubscriptionEventType = "SubscriptionEvent" as const;

// A décommenter en es6 :
// export class SubscriptionEvent extends CustomEvent<Subscription> {
//   constructor(detail: Subscription) {
//     super(SubscriptionEventType, {detail});
//   }
// }

export class SubscriptionEvent extends CustomEvent<
  Pick<SubscriptionsModel.Subscription, "id" | "productId" | "plan"> & {
    status:
      | SubscriptionsModel.PaymentStatusParams
      | SubscriptionsModel.PaymentStatus;
  }
> {}

// Methode pour créer un SubscriptionEvent (car l'héritage et le super ne fonctionne pas en es5)
export function dispatchSubscriptionEvent(
  detail: Pick<SubscriptionsModel.Subscription, "id" | "productId" | "plan"> & {
    status:
      | SubscriptionsModel.PaymentStatusParams
      | SubscriptionsModel.PaymentStatus;
  }
) {
  dispatchEvent(
    new CustomEvent<
      Pick<SubscriptionsModel.Subscription, "id" | "productId" | "plan">
    >(SubscriptionEventType, { detail })
  );
}
