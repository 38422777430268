






















































































































import { VForm } from "@/models";
import { defineComponent, Ref, ref } from "@vue/composition-api";
import { userAccountService } from "@/services";

import SignTemplate from "@/components/sign/SignTemplate.vue";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "ForgetPassword",
  components: { SignTemplate },
  setup(props, context) {
    const username: Ref<string> = ref("");
    const step: Ref<number> = ref(0);

    async function resetPassword(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        await userAccountService.resetPassword({ username: username.value });
        step.value = 1;
      }
    }

    return { ROUTE_NAMES, step, username, resetPassword };
  },
});
