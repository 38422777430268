import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";
import { JournalComposedEntry, PartialField, Transaction } from "..";

export interface TransactionStatsStockTransactions extends Transaction {
  journalComposedEntry: JournalComposedEntry[];
}

export interface TransactionStats {
  id: string;
  stockTransactions: {
    total: number;
    valueAbsoluteDevise: number;
    categorizeTotal: number;
    categorizeValueAbsoluteDevise: number;
  };
  date: string;
}
export type TransactionStatsUpsert = PartialField<TransactionStats, "id">;

const transactionStatsSchema = new Schema<TransactionStatsDocument>(
  {
    _id: { type: String, default: () => ulid() },
    stockTransactions: {
      total: { type: Number },
      valueAbsoluteDevise: { type: Number },
      categorizeTotal: { type: Number },
      categorizeValueAbsoluteDevise: { type: Number },
    },
    date: { type: String, require: true, index: true, unique: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret, options) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type TransactionStatsDocument = TransactionStats & Document<string>;

export const TransactionStatsModel = model<TransactionStatsDocument>(
  "TransactionStats",
  transactionStatsSchema,
  "TransactionsStats"
);
