























































































import { computed, defineComponent } from "@vue/composition-api";
import { AccountingPeriod } from "@edmp/api";
import { accountingPeriodsStore, transactionsStore } from "@/store";
import { FilterKeys } from "@/utils";
import router from "@/router";
import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "TransactionsOverviewAccPeriod",
  props: {
    accountingPeriod: {
      type: Object as () => AccountingPeriod,
      required: true,
    },
    loading: Boolean,
    transactionsToBeCategorizedNumber: Number,
    transactionsSuggestNumber: Number,
    transactionsAnomalyNumber: Number,
  },
  setup(props) {
    function navigateToTransactions(filterCode: FilterKeys): void {
      accountingPeriodsStore.switchAccountingPeriod(props.accountingPeriod.id);
      transactionsStore.resetFilter();
      transactionsStore.addFilter({ code: filterCode });
      router.push({ name: ROUTE_NAMES.Transactions });
    }

    const suggestPermission = computed(() => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "suggest",
          subject("Transaction", {})
        );
        return { allow: true };
      } catch (error) {
        if (error instanceof ForbiddenError) {
          return { allow: false, message: error.message };
        }
      }
      return { allow: false };
    });

    return {
      FilterKeys,
      navigateToTransactions,
      accPeriodText: computed(() => {
        const year = props.accountingPeriod.endAt
          ? new Date(props.accountingPeriod.endAt).getFullYear()
          : "--";
        return `Année ${year}`;
      }),
      suggestPermission,
    };
  },
});
