
































































import { realEstateAssetsStore } from "@/store";
import { FixedAsset, getFixedAssetTypeText, getMoment } from "@edmp/api";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "FixedAssets",
  props: {
    fixedAssets: {
      type: Array as PropType<FixedAsset[]>,
      required: true,
    },
  },
  setup() {
    const formatRealEstateAssets = (realEstateAssetIds) => {
      return realEstateAssetIds
        .map((realEstateAssetId) => {
          let asset =
            realEstateAssetsStore.getRealEstateAsset(realEstateAssetId);
          return asset?.name;
        })
        .filter((_) => _)
        .join(", ");
    };

    return {
      getFixedAssetTypeText,
      getMoment,
      formatRealEstateAssets,
    };
  },
});
