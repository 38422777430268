













































import { TaskActivityGroupLocal, TaskActivityLocal } from "@/models";
import { defineComponent, onBeforeMount, PropType } from "@vue/composition-api";
import Group from "./Group.vue";
import TaskActivity from "../task/TaskActivity.vue";
import { tasksNeedingBalanceSheetFetch } from "@/utils";
import { accountingBalanceSheetsStore } from "@/store";
import { OmitField, TaskActivityService } from "@edmp/api";

export default defineComponent({
  name: "TaskActivityGroup",
  components: { Group, TaskActivity },
  props: {
    taskActivityGroupLocal: {
      type: Object as PropType<TaskActivityGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    displayTaskActivities: {
      type: Boolean,
      default: false,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivityLocal: TaskActivityLocal,
          data?: OmitField<Required<TaskActivityService.ValidateIn>, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      if (
        props.taskActivityGroupLocal.taskActivitiesLocal.find((task) =>
          tasksNeedingBalanceSheetFetch.includes(task.code)
        )
      ) {
        await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      }
    });
    return {
      disabledGroup: props.taskActivityGroupLocal.isDisabled || props.isDisable,
    };
  },
});
