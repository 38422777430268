import { ref, Ref, watch, onBeforeMount } from "@vue/composition-api";
import {
  productsStore,
  realEstateAssetsStore,
  accountingPeriodsStore,
  realEstateAmortisationsStore,
} from "@/store";
import {
  RealEstateAsset,
  sumAmortizationValueStatsForARealEstateAsset,
  sumAllAmortizationValueStatsForAnAccountingPeriodRealEstate,
  getAmortisationTypeTexts,
  TaxRegime,
} from "@edmp/api";
import { getMoment, AmortisationType } from "@edmp/api";

type CollectionStringToString = { [key: string]: string };
type amortizationDaysStatsForCurrentAccountingPeriod = {
  amortizedDays: number;
  daysInCurrentAccountingPeriod: number;
  remainingDaysToAmortize: number;
};
type amortizationValuesStatsForCurrentAccountingPeriod = {
  valueAmortized: number;
  valueToAmortizedForCurrentAccountingPeriod: number;
  remainingValueToAmortize: number;
};
export function useRealEstateAmortisation() {
  const realEstateAssets: Ref<RealEstateAsset[]> = ref([]);
  const isLoadingRealEstates: Ref<boolean> = ref(true);
  const totalAmortisationRealEstates: Ref<string> = ref("");
  const totalAmortizedRealEstates: Ref<string> = ref("");
  const totalAmortisationByRealEstateAssets: Ref<CollectionStringToString> =
    ref({});
  const totalAmortizedByRealEstateAssets: Ref<CollectionStringToString> = ref(
    {}
  );

  const amortisationTypeText: {
    text: string;
    value: AmortisationType;
  }[] = getAmortisationTypeTexts();

  const init = async () => {
    isLoadingRealEstates.value = true;

    // Reset values
    realEstateAssets.value = [];
    totalAmortisationRealEstates.value = "";
    totalAmortizedRealEstates.value = "";
    // Fetch data
    realEstateAssets.value = realEstateAssetsStore.realEstateAssets;

    // Calculate new values
    const totalStats =
      sumAllAmortizationValueStatsForAnAccountingPeriodRealEstate(
        accountingPeriodsStore.accountingPeriods,
        accountingPeriodsStore.currentId,
        realEstateAssets.value,
        realEstateAmortisationsStore.realEstateAmortisations
      );
    totalAmortisationRealEstates.value =
      totalStats.valueToAmortizedForThisAccountingPeriod.toFixed(2);
    totalAmortizedRealEstates.value = totalStats.valueAmortized.toFixed(2);

    const tempTotalAmortisationByRealEstateAssets: CollectionStringToString =
      {};
    const tempTotalAmortizedByRealEstateAssets: CollectionStringToString = {};
    for (const realEstateAsset of realEstateAssets.value) {
      const amortisations =
        realEstateAmortisationsStore.realEstateAmortisations.filter(
          ({ realEstateAssetId }) => realEstateAssetId === realEstateAsset.id
        );
      if (amortisations.length > 0) {
        const totalStatsByRealEstateAssets =
          sumAmortizationValueStatsForARealEstateAsset({
            realEstateAmortisations:
              realEstateAmortisationsStore.realEstateAmortisations,
            accountingPeriods: accountingPeriodsStore.accountingPeriods,
            accountingPeriodIdToSum: accountingPeriodsStore.currentId,
            realEstateAssetIdToSum: realEstateAsset.id,
            realEstateAssets: realEstateAssetsStore.realEstateAssets,
          });
        tempTotalAmortisationByRealEstateAssets[realEstateAsset.id] =
          totalStatsByRealEstateAssets.valueToAmortizedForThisAccountingPeriod.toFixed(
            2
          );
        tempTotalAmortizedByRealEstateAssets[realEstateAsset.id] =
          totalStatsByRealEstateAssets.valueAmortized.toFixed(2);
      }
    }
    totalAmortisationByRealEstateAssets.value =
      tempTotalAmortisationByRealEstateAssets;
    totalAmortizedByRealEstateAssets.value =
      tempTotalAmortizedByRealEstateAssets;

    isLoadingRealEstates.value = false;
  };
  const amortizationDaysStatsForCurrentAccountingPeriod = (
    realEstateAssetId: string,
    yearsAmortization: number
  ) => {
    const res: amortizationDaysStatsForCurrentAccountingPeriod = {
      amortizedDays: 0,
      daysInCurrentAccountingPeriod: 0,
      remainingDaysToAmortize: 0,
    };
    const realEstate =
      realEstateAssetsStore.getRealEstateAsset(realEstateAssetId);
    const referenceDate =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.LMNP_2031
        ? realEstate?.entryDateActivityLmnp
        : realEstate?.commissioningAt;
    if (referenceDate) {
      if (
        getMoment(referenceDate) <
        getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt)
      ) {
        res.daysInCurrentAccountingPeriod =
          getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt).diff(
            getMoment(accountingPeriodsStore.currentAccountingPeriod?.startAt),
            "days"
          ) + 1;
      } else {
        res.daysInCurrentAccountingPeriod =
          getMoment(accountingPeriodsStore.currentAccountingPeriod?.endAt).diff(
            getMoment(referenceDate),
            "days"
          ) + 1;
      }

      res.amortizedDays = getMoment(
        accountingPeriodsStore.currentAccountingPeriod?.startAt
      ).diff(getMoment(referenceDate), "days");
      if (res.amortizedDays < 0) {
        res.amortizedDays = 0;
      }
      res.remainingDaysToAmortize = getMoment(referenceDate)
        .add(yearsAmortization, "years")
        .diff(getMoment(referenceDate).add(res.amortizedDays, "days"), "days");
      if (res.remainingDaysToAmortize < 0) {
        res.remainingDaysToAmortize = 0;
      }
      if (res.daysInCurrentAccountingPeriod < 0) {
        res.daysInCurrentAccountingPeriod = 0;
      }
      return res;
    }
  };
  const amortizationValueStatsForCurrentAccountingPeriod = (
    realEstateAssetId: string,
    yearsAmortization: number,
    valueComponent: number
  ) => {
    const res: amortizationValuesStatsForCurrentAccountingPeriod = {
      valueAmortized: 0,
      valueToAmortizedForCurrentAccountingPeriod: 0,
      remainingValueToAmortize: 0,
    };
    const daysStats = amortizationDaysStatsForCurrentAccountingPeriod(
      realEstateAssetId,
      yearsAmortization
    );
    if (daysStats) {
      const allDays =
        daysStats.amortizedDays + daysStats.remainingDaysToAmortize;
      res.valueAmortized = Math.round(
        (valueComponent / allDays) * daysStats.amortizedDays
      );
      if (
        daysStats.daysInCurrentAccountingPeriod <
        daysStats.remainingDaysToAmortize
      ) {
        res.valueToAmortizedForCurrentAccountingPeriod = Math.round(
          (valueComponent / allDays) * daysStats.daysInCurrentAccountingPeriod
        );
      } else {
        res.valueToAmortizedForCurrentAccountingPeriod = Math.round(
          (valueComponent / allDays) * daysStats.remainingDaysToAmortize
        );
      }
      res.remainingValueToAmortize = Math.round(
        (valueComponent / allDays) * daysStats.remainingDaysToAmortize
      );
    }
    return res;
  };
  watch(
    () => [realEstateAmortisationsStore.realEstateAmortisations],
    async () => await init(),
    {
      deep: true,
    }
  );
  watch(
    () => [realEstateAssetsStore.realEstateAssets],
    async () => await init(),
    {
      deep: true,
    }
  );

  watch(
    () => [productsStore.currentId, accountingPeriodsStore.currentId],
    async () => {
      await productsStore.switchProduct({ id: productsStore.currentId });
      await realEstateAmortisationsStore.fetchRealEstateAmortisations(
        productsStore.currentId
      );
      await init();
    },
    {
      deep: true,
    }
  );

  onBeforeMount(async () => await init());

  return {
    amortizationDaysStatsForCurrentAccountingPeriod,
    amortizationValueStatsForCurrentAccountingPeriod,
    realEstateAssets,
    isLoadingRealEstates,
    totalAmortisationRealEstates,
    totalAmortisationByRealEstateAssets,
    totalAmortizedRealEstates,
    totalAmortizedByRealEstateAssets,
    amortisationTypeText,
  };
}
