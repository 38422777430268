















































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import Confirm from "@/components/core/modals/Confirm.vue";
import Next from "@/components/core/modals/Next.vue";
import { VConfirmDialog, VForm } from "@/models";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  transactionsStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { countDecimals } from "@/utils";
import {
  AccountingPeriod,
  AccountingPeriodDefinition,
  ProductsModel,
  TransactionImportType,
} from "@edmp/api";
import Transaction from "../transaction/Transaction.vue";
import { computed, defineComponent, Ref, ref } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import Moment from "moment";

export default defineComponent({
  name: "TransactionDuplicateCreate",
  components: {
    Next,
    Confirm,
    DatePicker,
    Transaction,
  },
  props: {
    transactionSourceId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const product = computed(
      () => productsStore.currentProduct as ProductsModel.Product
    );
    const accountingPeriod = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod &
          AccountingPeriodDefinition
    );
    const transactionSource = computed(
      () => transactionsStore.transactions[props.transactionSourceId]
    );

    const transactionDate: Ref<string> = ref(
      transactionSource.value
        ? Moment(transactionSource.value.date.operation).format("YYYY-MM-DD")
        : Moment().format("YYYY-MM-DD")
    );
    const transactionAmount: Ref<number | undefined> = ref(
      transactionSource.value
        ? new Decimal(transactionSource.value.value.amount).abs().toNumber()
        : undefined
    );
    const transactionSummary: Ref<string> = ref(
      transactionSource.value.summary
    );
    const currency: Ref<string | undefined> = ref(
      new Decimal(transactionSource.value.value.amount).isNegative()
        ? "débit"
        : "crédit"
    );

    const firstStep: Ref<boolean> = ref(true);
    const secondStep: Ref<boolean> = ref(false);
    const transactionId: Ref<string> = ref("");

    function resetForm() {
      (context.refs.form as VForm).resetValidation();
    }

    async function validateFirstStep() {
      if ((context.refs.form as VForm).validate()) {
        try {
          let transactionAmountFinal;
          if (transactionAmount.value) {
            transactionAmountFinal = transactionAmount.value;
          }
          if (currency.value === "débit" && transactionAmount.value) {
            transactionAmountFinal = transactionAmountFinal * -1;
          }
          const newTransaction = await transactionsStore.createTransaction({
            productId: product.value.id,
            date: transactionDate.value,
            amount: transactionAmountFinal ? transactionAmountFinal : 0,
            summary: transactionSummary.value,
            type: TransactionImportType.MANUAL,
          });
          transactionId.value = newTransaction.id;
          firstStep.value = false;
          resetForm();
          secondStep.value = true;
        } catch (error) {
          console.error(error);
        }
      }
    }

    const isCategorized = ref(false);

    async function categorizeAndValidate() {
      if (isCategorized.value) {
        context.root.$router.push({ query: {} });
        context.emit("finish");
      } else {
        coreStore.displayFeedback({
          message: "Chaque opération comptable doit être catégorisée.",
          type: FeedbackTypeEnum.ERROR,
        });
      }
    }

    async function cancel(): Promise<void> {
      if (transactionId.value) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          "Êtes-vous sûr de vouloir abandonner la duplication de la transaction ?"
        );

        if (openConfirm) {
          await transactionsStore.deleteTransaction({
            id: transactionId.value,
          });
          context.root.$router.push({ query: {} });
          context.emit("finish");
        }
      } else {
        context.root.$router.push({ query: {} });
        context.emit("finish");
      }
    }

    return {
      validateFirstStep,
      categorizeAndValidate,
      cancel,
      transactionDate,
      transactionAmount,
      transactionSummary,
      transactionSource,
      isCategorized,
      firstStep,
      secondStep,
      transaction: computed(
        () => transactionsStore.transactions[transactionId.value]
      ),
      accountingPeriod,
      countDecimals,
      currency,
    };
  },
});
