



















































import { TaskActivityGroupLocal } from "@/models";
import { TaskActivityStatus } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import GroupAction from "./GroupAction.vue";

export default defineComponent({
  name: "Group",
  components: { GroupAction },
  props: {
    taskActivityGroupLocal: {
      type: Object as PropType<TaskActivityGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      nbTaskActivities: computed(
        () =>
          props.taskActivityGroupLocal.taskActivitiesLocal.filter(
            (taskActivity) => !taskActivity.isComingSoon
          ).length
      ),
      nbTaskActivitiesDone: computed(
        () =>
          props.taskActivityGroupLocal.taskActivitiesLocal.filter(
            (taskActivity) =>
              !taskActivity.isComingSoon &&
              taskActivity.status === TaskActivityStatus.COMPLETED
          ).length
      ),
      dueDate: computed(() => {
        if (props.taskActivityGroupLocal.dueDateLabel) {
          return props.taskActivityGroupLocal.dueDateLabel;
        }
      }),
    };
  },
});
