import { ProductsService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/products";

class ProductService {
  async create(productCreate: ProductsService.CreateIn) {
    return axios
      .post<ProductsService.CreateOut>(`${api}`, productCreate)
      .then((res) => {
        return res.data;
      });
  }

  async list(params: ProductsService.ListIn) {
    return axios
      .get<ProductsService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: ProductsService.GetIn) {
    return axios
      .get<ProductsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(productUpdate: ProductsService.UpdateIn) {
    return axios
      .post<ProductsService.UpdateOut>(
        `${api}/${productUpdate.id}`,
        productUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async validateEmail(params: ProductsService.ValidateEmailIn) {
    return axios
      .post<ProductsService.ValidateEmailOut>(`${api}/validate-email`, params, {
        headers: {
          ERROR_HANDLER_STRATEGY: "IGNORE",
        },
      })
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const productsService = new ProductService();
