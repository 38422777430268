

















































































































































import { BadgeDev } from "@/components/atom/badge";
import { TaxDeclaration2033LineCompute } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingCarryForwardsStore,
  accountingPeriodsStore,
  accountingResultsStore,
  coreStore,
  transactionsStore,
} from "@/store";
import { Env } from "@/store/modules/Core.store";
import { FilterKeys } from "@/utils";
import {
  AccountingCarryForwardLineType,
  AccountingResultLineType,
  Direction,
  SubscriptionsModel,
  TaxDeclaration2033LineAmount,
  TaxDeclaration2033LineNumber,
  TaxDeclaration2033LineNumberSimplifiedIncomeStatement,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
} from "@vue/composition-api";

export default defineComponent({
  name: "TaxDeclaration2033Lines",
  components: { BadgeDev },
  props: {
    lines: {
      type: Array as PropType<TaxDeclaration2033LineCompute>,
      required: true,
    },
  },
  setup(props, context) {
    // Methods
    const goToTransactions = (line: TaxDeclaration2033LineCompute[number]) => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({
        code: FilterKeys.CERFA,
        filterParams: {
          ids: (line as unknown as TaxDeclaration2033LineAmount<true>)
            .transactionIds,
          label: `2033 - ${line.title}`,
        },
      });
      context.root.$router.push({
        name: ROUTE_NAMES.Transactions,
      });
    };

    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);
    const showBadgeDev = computed(() => coreStore.isNotProduction);
    const accountingResult = computed(() =>
      accountingResultsStore.getAccountingResultByAccountingPeriod(
        accountingPeriodsStore.currentId
      )
    );
    const previousYearCarryForwardDepreciation = computed(() => {
      const previousCarryForward =
        accountingCarryForwardsStore.getPreviousAccountingCarryForward;
      const totalDepreciationLine = previousCarryForward?.lines.find(
        (line) =>
          line.type === AccountingCarryForwardLineType.TOTAL_DEPRECIATION
      );
      return totalDepreciationLine?.amount &&
        totalDepreciationLine.amount !== -1
        ? totalDepreciationLine.amount
        : 0;
    });
    const isEqualAccountingResult = computed(() => {
      const resultOfExercise = props.lines.find(
        (line) =>
          line.lineNumber ===
          TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310
      ) as TaxDeclaration2033LineCompute[number] & TaxDeclaration2033LineAmount;
      if (resultOfExercise) {
        return (
          resultOfExercise.amount ===
            accountingResult.value?.lines.find(
              (line) => line.type === AccountingResultLineType.ACCOUNTING_RESULT
            )?.amount &&
          resultOfExercise.amountDirection ===
            accountingResult.value?.lines.find(
              (line) => line.type === AccountingResultLineType.ACCOUNTING_RESULT
            )?.direction
        );
      }
    });

    const isEqualTaxResultBeforeAllocation = computed(() => {
      const taxResultBeforeAllocation = props.lines.find((line) =>
        accountingResult.value?.lines.find(
          (line) =>
            line.type === AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
        )?.direction === Direction.credit
          ? line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352
          : line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354
      ) as TaxDeclaration2033LineCompute[number] & TaxDeclaration2033LineAmount;
      if (taxResultBeforeAllocation) {
        return (
          taxResultBeforeAllocation.amount ===
            accountingResult.value?.lines.find(
              (line) =>
                line.type ===
                AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
            )?.amount &&
          taxResultBeforeAllocation.amountDirection ===
            accountingResult.value?.lines.find(
              (line) =>
                line.type ===
                AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION
            )?.direction
        );
      }
    });

    const isEqualTaxResultAfterAllocation = computed(() => {
      const taxResultAfterAllocation = props.lines.find((line) =>
        accountingResult.value?.lines.find(
          (line) =>
            line.type === AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION
        )?.direction === Direction.credit
          ? line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L370
          : line.lineNumber ===
            TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L372
      ) as TaxDeclaration2033LineCompute[number] & TaxDeclaration2033LineAmount;
      if (taxResultAfterAllocation) {
        return (
          taxResultAfterAllocation.amount ===
            accountingResult.value?.lines.find(
              (line) =>
                line.type ===
                AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION
            )?.amount &&
          taxResultAfterAllocation.amountDirection ===
            accountingResult.value?.lines.find(
              (line) =>
                line.type ===
                AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION
            )?.direction
        );
      }
    });

    onBeforeMount(async () => {
      if (
        coreStore.config.env !== Env.production &&
        props.lines.find(
          (line) =>
            line.lineNumber ===
              TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310 ||
            line.lineNumber ===
              TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352 ||
            line.lineNumber ===
              TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354
        )
      ) {
        await accountingResultsStore.fetchAccountingResults();
      }
      if (isLMNP) {
        accountingCarryForwardsStore.fetchAccountingCarryForwards();
      }
    });

    return {
      goToTransactions,
      TaxDeclaration2033LineNumberSimplifiedIncomeStatement,
      showBadgeDev,
      isEqualAccountingResult,
      isEqualTaxResultBeforeAllocation,
      isEqualTaxResultAfterAllocation,
      TaxDeclaration2033LineNumber,
      ROUTE_NAMES,
      previousYearCarryForwardDepreciation,
      isLMNP,
      SubscriptionPlanType: SubscriptionsModel.PlanType,
    };
  },
});
