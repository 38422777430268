var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nowrap"},[_c('TitleNew',{staticClass:"my-3 mt-md-0 mb-md-8 mx-5 pt-5",attrs:{"hLevel":"h2"}},[_vm._v("Solde de vos comptes")]),(
      _vm.bankAccounts.length > 0 &&
      _vm.accountingPeriodsStore.lastAccountingPeriod.id ===
        _vm.accountingPeriodsStore.currentAccountingPeriod.id
    )?[_vm._l((_vm.bankAccounts),function(bankAccount){return [_c('div',{key:bankAccount.id,staticClass:"flex-row margins mb-2"},[_c('div',{staticClass:"column gap-3"},[_c('b',[_vm._v(_vm._s(bankAccount.name))]),_c('div',[_vm._v(_vm._s(bankAccount.balance.amount)+" €")])]),_c('div',{staticClass:"column gap-3"},[_c('b',[_vm._v("Dernière synchronisation")]),_vm._v(" "+_vm._s(_vm.moment(bankAccount.lastSync).format("DD/MM/YYYY"))+" ")])])]}),_c('v-divider',{staticClass:"divider my-5 mx-15"})]:_vm._e(),(
      _vm.accountingPeriodsStore.currentAccountingPeriod.id !==
      _vm.accountingPeriodsStore.lastAccountingPeriod.id
    )?_c('BalanceLine512',{staticClass:"pb-1",attrs:{"targetedAccountingBalanceSheet":_vm.currentYearAccountingBalanceSheet}}):_vm._e(),(_vm.previousYearAccountingBalanceSheet)?_c('BalanceLine512',{attrs:{"targetedAccountingBalanceSheet":_vm.previousYearAccountingBalanceSheet,"previousYear":""}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }