var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"container",attrs:{"id":"BankAccounts","loading":_vm.isLoading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('div',{staticClass:"header"},[_c('h2',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.headerTitleText))])]),_c('v-card-text',{staticClass:"section"},[(_vm.subscription && _vm.subscription.plan.type === _vm.PlanType.Solo)?_c('span',[_vm._v(" Ce service nécessite un abonnement supérieur. "),_c('v-btn',{attrs:{"color":"primary","text":"","to":{
          name: 'SubscriptionDetails',
          params: { subscriptionId: _vm.subscription.id },
        }}},[_vm._v(" Voir mon abonnement ")])],1):(_vm.selectedBankAccounts.length === 0)?_c('span',[_vm._v(" Aucun compte disponible."),_c('br'),_vm._v(" Cliquez sur le bouton « Ajouter un compte bancaire » pour synchroniser un ou plusieurs compte(s) à Ownily. ")]):_vm._e(),_c('div',{staticClass:"section-bank-accounts",attrs:{"id":"bank_account-list"}},_vm._l((_vm.selectedBankAccounts),function(account){return _c('v-btn',{key:account.id,staticClass:"section-bank-accounts-btn",attrs:{"depressed":"","outlined":""},on:{"click":function($event){return _vm.$router.push({
            name: _vm.ROUTE_NAMES.BankAccountDetails,
            params: { productId: _vm.product.id, id: account.id },
          })}}},[(account.state === 'verified')?_c('v-icon',{staticClass:"mr-4",attrs:{"color":"primary lighten-1","left":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"mr-4",attrs:{"color":"red","left":""}},[_vm._v(" mdi-alert-outline ")]),_c('span',[_vm._v(_vm._s(account.name)+" - "+_vm._s(account.bankName)+" ")])],1)}),1),_c('br'),_c('div',{staticClass:"section-banks",attrs:{"id":"bank-list"}},[_vm._l((_vm.selectedBanks),function(bank){return _c('v-btn',{key:bank.bankId,staticClass:"section-banks-btn",attrs:{"depressed":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalManage(bank.bankId)}}},[(bank.bankLogoUuid)?_c('img',{attrs:{"src":("" + _vm.urlLogo + (bank.bankLogoUuid) + "-thumbnail.webp"),"alt":("" + (bank.name)),"width":"32px"},on:{"click":function($event){$event.stopPropagation();return _vm.openModalManage(bank.bankId)}}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(bank.bankName)+" ")])])}),_c('div',[_c('v-btn',{staticClass:"section-banks-btn",attrs:{"depressed":"","outlined":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"primary lighten-1","left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Ajouter un compte bancaire ")])],1)],1)],2),_c('div',{staticClass:"section-action"},[(_vm.selectedBankAccounts.length !== 0)?_c('v-btn',{staticClass:"link-btn",attrs:{"text":"","id":"track-details_bank_account"},on:{"click":function($event){return _vm.$router.push({
            name: _vm.ROUTE_NAMES.BankAccountDetailsActivity,
            params: { productId: _vm.product.id },
          })}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)]):_vm._e()],1)]),_c('v-dialog',{attrs:{"fullscreen":true,"max-width":"800px","persistent":""},model:{value:(_vm.openDialogAccount),callback:function ($$v) {_vm.openDialogAccount=$$v},expression:"openDialogAccount"}},[(_vm.openDialogAccount)?_c('BankAccountsModal',{on:{"accountsChange":function($event){return _vm.accountsChanged()},"cancel":_vm.closeDialog}}):_vm._e()],1),_c('v-dialog',{attrs:{"fullscreen":true,"persistent":""},model:{value:(_vm.openDialogManage),callback:function ($$v) {_vm.openDialogManage=$$v},expression:"openDialogManage"}},[(_vm.openDialogManage)?_c('ManageBankAccountModal',{attrs:{"bankId":_vm.currentBank,"productId":_vm.product.id},on:{"accountsChange":function($event){return _vm.accountsChanged()},"cancel":_vm.closeDialog}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }