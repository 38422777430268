import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { realEstatesService, rentalsService } from "@/services";
import {
  RealEstateAsset,
  NewRealEstateAsset,
  NewRentalUnit,
  RentalUnit,
  RealEstateAssetUpdate,
  RentalUnitUpdate,
  DpeInformation,
  Asbestos,
} from "@edmp/api";
import {
  rentalAgreementsStore,
  realEstateLoansStore,
  realEstateAmortisationsStore,
  documentsStore,
  productsStore,
} from "@/store";
import Vue from "vue";

export interface RealEstateAssetsState {
  realEstateAssets: RealEstateAsset[];
  rentalUnits: RentalUnit[];
  rentalUnitsDeleted: RentalUnit[];
  loading: boolean;
}

@Module({
  name: "real-estate-assets-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RealEstateAssetsStore
  extends VuexModule
  implements RealEstateAssetsState
{
  realEstateAssets: RealEstateAsset[] = [];
  rentalUnits: RentalUnit[] = [];
  rentalUnitsDeleted: RentalUnit[] = [];
  selectedRealEstateAssetId = "";
  loading = false;

  @Mutation
  reset(): void {
    this.realEstateAssets = [];
    this.rentalUnits = [];
    this.rentalUnitsDeleted = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setSelectedRealEstateAssetId(selectedRealEstateAssetId: string): void {
    this.selectedRealEstateAssetId = selectedRealEstateAssetId;
  }

  // RealEstateAssets
  @Action
  async fetchRealEstateAssets(productId: string): Promise<RealEstateAsset[]> {
    this.setLoading(true);
    const realEstateAssets = await realEstatesService.assets.list({
      productId,
    });
    for (const realEstateAsset of realEstateAssets) {
      this.setRealEstateAsset(realEstateAsset);
      await Promise.all([
        this.fetchRentalUnits({
          productId: realEstateAsset.productId,
          realEstateAssetId: realEstateAsset.id,
        }),
        rentalAgreementsStore.fetchRentalAgreements({
          productId: realEstateAsset.productId,
          realEstateAssetId: realEstateAsset.id,
        }),
        realEstateLoansStore.fetchRealEstateLoans(realEstateAsset.productId),
      ]);
    }
    this.setLoading(false);
    return realEstateAssets;
  }

  // RentalUnits
  @Action
  async fetchRentalUnits(value: {
    productId: string;
    realEstateAssetId: string;
  }): Promise<RentalUnit[]> {
    this.setLoading(true);
    const rentalUnits = await rentalsService.units.list({
      productId: value.productId,
      realEstateAssetId: value.realEstateAssetId,
      withDeleted: true,
    });
    for (const rentalUnit of rentalUnits) {
      this.setRentalUnit(rentalUnit);
    }
    this.setLoading(false);
    return rentalUnits;
  }

  get getRentalUnitByRealEstateAssetId() {
    return (realEstateAssetId: string) => {
      const rentalUnits = this.rentalUnits.filter(
        (rentalUnit) => rentalUnit.realEstateAssetId === realEstateAssetId
      );

      if (rentalUnits.length > 1)
        throw new Error(
          "getRentalUnitByRealEstateAssetId: Too many rental units, must be only one"
        );

      if (rentalUnits.length === 0)
        throw new Error("No rental unit found for this real estate asset");

      return rentalUnits[0];
    };
  }

  get getRentalUnitsDeletedByRealEstateAssetId() {
    return (realEstateAssetId: string) => {
      return this.rentalUnitsDeleted.filter(
        (rentalUnit) => rentalUnit.realEstateAssetId === realEstateAssetId
      );
    };
  }

  // Common between RealEstateAsset and RentalUnits
  @Action
  async createRealEstateAssetAndRentalUnits({
    realEstateAssetCreate,
    rentalUnitCreate,
  }: {
    realEstateAssetCreate: NewRealEstateAsset;
    rentalUnitCreate: NewRentalUnit;
  }): Promise<{
    realEstateAsset: RealEstateAsset;
    rentalUnit: NewRentalUnit;
  }> {
    this.setLoading(true);
    const realEstateAsset = await realEstatesService.assets.create(
      realEstateAssetCreate
    );

    rentalUnitCreate.realEstateAssetId = realEstateAsset.id;
    const rentalUnit = await rentalsService.units.create(rentalUnitCreate);

    // Have to set first in store the RentalUnit because it's needed in the RealEstateAsset
    this.setRentalUnit(rentalUnit);
    this.setRealEstateAsset(realEstateAsset);
    this.setLoading(false);
    return {
      realEstateAsset,
      rentalUnit,
    };
  }

  // RealEstateAsset
  @Mutation
  setRealEstateAsset(realEstateAsset: RealEstateAsset): void {
    const index = this.realEstateAssets.findIndex(
      ({ id }) => id == realEstateAsset.id
    );
    if (index !== -1) {
      Vue.set(this.realEstateAssets, index, realEstateAsset);
    } else {
      this.realEstateAssets.push(realEstateAsset);
    }
  }

  @Action
  async fetchRealEstateAsset(value: {
    productId: string;
    realEstateAssetId: string;
  }): Promise<RealEstateAsset> {
    this.setLoading(true);
    const realEstateAsset = await realEstatesService.assets.get({
      id: value.realEstateAssetId,
    });
    this.setRealEstateAsset(realEstateAsset);
    await Promise.all([
      this.fetchRentalUnits({
        productId: realEstateAsset.productId,
        realEstateAssetId: realEstateAsset.id,
      }),
      rentalAgreementsStore.fetchRentalAgreements({
        productId: realEstateAsset.productId,
        realEstateAssetId: realEstateAsset.id,
      }),
      realEstateLoansStore.fetchRealEstateLoans(realEstateAsset.productId),
      realEstateAmortisationsStore.fetchRealEstateAmortisations(
        realEstateAsset.productId
      ),
    ]);
    this.setLoading(false);
    return realEstateAsset;
  }

  get getRealEstateAsset() {
    return (realEstateAssetId: string | null): RealEstateAsset | undefined =>
      realEstateAssetId
        ? this.realEstateAssets.find(({ id }) => id === realEstateAssetId)
        : undefined;
  }

  // Update
  @Action
  async updateRealEstateAsset(
    realEstateAssetUpdate: RealEstateAssetUpdate
  ): Promise<RealEstateAsset> {
    this.setLoading(true);
    const updatedRealEstateAsset = await realEstatesService.assets.update(
      realEstateAssetUpdate
    );
    this.setRealEstateAsset(updatedRealEstateAsset);
    this.setLoading(false);
    return updatedRealEstateAsset;
  }

  // Delete
  @Action
  async deleteRealEstateAsset(realEstateAssetId: string): Promise<void> {
    this.setLoading(true);
    await realEstatesService.assets.remove({ id: realEstateAssetId });
    this.removeRealEstateAsset(realEstateAssetId);
    realEstateLoansStore.fetchRealEstateLoans(productsStore.currentId);
    rentalAgreementsStore.fetchRentalAgreements({
      productId: productsStore.currentId,
      realEstateAssetId,
    });
    this.setLoading(false);
  }

  // Delete
  @Mutation
  removeRealEstateAsset(realEstateAssetId: string): void {
    const index = this.realEstateAssets.findIndex(
      ({ id }) => id == realEstateAssetId
    );
    if (index !== -1) {
      this.realEstateAssets.splice(index, 1);
    }
  }

  @Action
  async addDocuments(params: {
    id: string;
    productId: string;
    asbestos?: Object;
    dpe: DpeInformation;
    accountingPeriodId: string;
    files: Object;
  }): Promise<RealEstateAsset> {
    this.setLoading(true);
    const updatedRealEstateAsset = await realEstatesService.assets.addDocuments(
      params
    );
    if (updatedRealEstateAsset.documentId) {
      documentsStore.addDocuments(updatedRealEstateAsset.documentId);
    }
    if (updatedRealEstateAsset.asbestos?.documentAsbestosId) {
      documentsStore.addDocuments(
        updatedRealEstateAsset.asbestos?.documentAsbestosId
      );
    }
    if (updatedRealEstateAsset.dpe?.documentDpeId) {
      documentsStore.addDocuments(updatedRealEstateAsset.dpe.documentDpeId);
    }
    this.setRealEstateAsset(updatedRealEstateAsset);
    this.setLoading(false);
    return updatedRealEstateAsset;
  }

  @Action
  async deleteDocument(params: {
    id: string;
    documentId?: string;
    asbestos?: Asbestos;
    dpe: DpeInformation;
  }): Promise<RealEstateAsset> {
    this.setLoading(true);

    const updatedRealEstateAsset =
      await realEstatesService.assets.deleteDocument(params);
    if (params.documentId) {
      documentsStore.removeDocument(params.documentId);
    } else if (params.asbestos?.documentAsbestosId) {
      documentsStore.removeDocument(params.asbestos.documentAsbestosId);
    }
    this.setRealEstateAsset(updatedRealEstateAsset);
    this.setLoading(false);
    return updatedRealEstateAsset;
  }

  // RentalUnit
  @Mutation
  setRentalUnit(rentalUnit: RentalUnit): void {
    const index = this.rentalUnits.findIndex(({ id }) => id == rentalUnit.id);
    const indexDelete = this.rentalUnitsDeleted.findIndex(
      ({ id }) => id == rentalUnit.id
    );
    if (rentalUnit.deleted) {
      if (indexDelete !== -1) {
        Vue.set(this.rentalUnitsDeleted, indexDelete, rentalUnit);
      } else {
        this.rentalUnitsDeleted.push(rentalUnit);
      }
      if (index != -1) {
        this.rentalUnits.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        Vue.set(this.rentalUnits, index, rentalUnit);
      } else {
        this.rentalUnits.push(rentalUnit);
      }
      if (indexDelete != -1) {
        this.rentalUnitsDeleted.splice(index, 1);
      }
    }
  }

  @Mutation
  setRentalUnits(rentalUnits: RentalUnit[]): void {
    this.rentalUnits = rentalUnits;
  }

  get getRentalUnit() {
    return (rentalUnitId: string): RentalUnit | undefined => {
      const rentalUnits = [...this.rentalUnits, ...this.rentalUnitsDeleted];
      return rentalUnits.find(({ id }) => id === rentalUnitId);
    };
  }

  // Create
  @Action
  async createRentalUnit(rentalUnit: NewRentalUnit): Promise<RentalUnit> {
    this.setLoading(true);
    const newRentalUnit = await rentalsService.units.create(rentalUnit);
    this.setRentalUnit(newRentalUnit);
    this.setLoading(false);
    return newRentalUnit;
  }

  // Update
  @Action
  async updateRentalUnit(
    rentalUnitUpdate: RentalUnitUpdate
  ): Promise<RentalUnit> {
    this.setLoading(true);
    const updatedRentalUnit = await rentalsService.units.update(
      rentalUnitUpdate
    );
    this.setRentalUnit(updatedRentalUnit);
    this.setLoading(false);
    return updatedRentalUnit;
  }

  // Update
  @Action
  async selectRealEstateAssetId(realEstateAssetId: string) {
    this.setSelectedRealEstateAssetId(realEstateAssetId);
  }
}
