



























































































import { BadgeOptional } from "@/components/atom/badge";
import { HelpingMessage } from "@/components/atom/helping";
import { TaskActivityLocal } from "@/models";
import { taskActivityStore } from "@/store";
import {
  OmitField,
  TaskActivityService,
  TaskActivityStatus,
  TypeTaskActivity,
} from "@edmp/api";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import TaskActivityAction from "./TaskActivityAction.vue";

export default defineComponent({
  name: "TaskActivity",
  components: {
    HelpingMessage,
    TaskActivityAction,
    BadgeOptional,
  },
  props: {
    taskActivityLocal: {
      type: Object as PropType<TaskActivityLocal>,
      required: true,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivity: TaskActivityLocal,
          data?: OmitField<TaskActivityService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    const validationInProgress = ref(false);

    async function validateTaskActivity() {
      if (
        validationInProgress.value ||
        props.taskActivityLocal.status !== TaskActivityStatus.PENDING
      )
        return;

      try {
        if (
          !validationInProgress.value &&
          props.taskActivityLocal.status === TaskActivityStatus.PENDING
        )
          validationInProgress.value = true;
        if (props.resolveTaskActivity) {
          await props.resolveTaskActivity(props.taskActivityLocal);
        } else {
          await taskActivityStore.validateTaskActivity({
            taskActivityLocal: props.taskActivityLocal,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        validationInProgress.value = false;
      }
    }

    return {
      validationInProgress,
      validateTaskActivity,
      TaskActivityStatus,
      TypeTaskActivity,
    };
  },
});
