export const dev = {
  subscription: {
    publishableKey:
      "pk_test_51K8nQOKnk5fWpl5kdIqSQNx2xWvet4C7AZq0ANdJGCYA36bJZX91Z2AQShCKlcIYpmdfSSMRKIK82Io900uwRpl200U8ier5bz",
  },
  sirene: {
    sireneApiKey: "1a850bfa-cede-31fe-9b5a-bb21b7408d4f",
  },
  services: {
    gtm: false,
    axios: false,
    crisp: false,
  },
};
