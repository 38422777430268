var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"partners-income-view"},[_c('div',{staticClass:"partners-income-header mb-5"},[_c('Title',{attrs:{"text":"Mes déclarations fiscales - Revenus des associés (CERFA 2042/2044)"}}),_c('AccountingPeriodChoice')],1),_c('div',{staticClass:"mb-10"},[_c('HelpingMessage',{attrs:{"title":"En savoir plus","opened":_vm.readonly}},[_c('p',{staticClass:"mb-5"},[_vm._v(" Les données restituées sur cette page sont issues des transactions affectées sur la plateforme Ownily sur la période et des informations renseignées dans les paramètres du compte de la SCI. Pensez à les vérifier "),_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: _vm.ROUTE_NAMES.Activities,
            })}}},[_vm._v(" ici ")]),_vm._v(". ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Il incombe au gérant.e de transmettre à chaque associé : ")]),_c('ul',{staticClass:"mb-5"},[_c('li',[_vm._v(" la liasse fiscale de la SCI : Cerfa 2072 pour les revenus fonciers d'une SCI à l'IR ")]),_c('li',[_vm._v(" les éléments à reporter par chaque associé sur sa déclaration personnelle : selon sa situation, Cerfa 2042 ou Cerfa 2044 de déclarations des revenus fonciers. ")])])])],1),_c('div',{staticClass:"partner-table-zone"},[_c('h4',{staticClass:"partner-subtitle mb-6"},[_vm._v(" Revenus fonciers (Cerfa 2042 ou 2044) "),(_vm.readonly)?_c('span',[_vm._v(" - Archives ")]):_vm._e()]),(!_vm.readonly)?_c('v-row',{staticClass:"partner-table-header px-2 px-md-12 mx-0 mb-1"},[_c('v-col',{staticClass:"text-left"},[_c('span',[_vm._v("Nom de l'associé")])]),_c('v-col',{staticClass:"text-center"},[_c('span',[_c('span',[_vm._v("Nombre de parts fiscales")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,1125719655)},[_c('div',{staticStyle:{"max-width":"320px"}},[_vm._v(" Le nombre de parts fiscales (PF) est celui qui est utilisé pour répartir les revenus entre les associés. "),_c('br'),_c('br'),_vm._v("Il s'obtient en additionnant les parts détenues en Pleine Propriété (PP) et celles détenues en Usufruit (UF) : PF = PP + UF. ")])])],1)]),_c('v-col',{staticClass:"text-center"},[(!_vm.readonly)?_c('span',[_vm._v("Montant à déclarer")]):_vm._e()]),_c('v-col',{staticClass:"text-center flex-grow-0"},[_c('div',{staticStyle:{"min-width":"36px"}})])],1):_vm._e(),(!_vm.readonly)?_c('div',{staticClass:"partner-card px-2 px-md-12 py-2"},_vm._l((_vm.cerfa2044Parts),function(partner){return _c('v-row',{key:partner.id,staticClass:"partner-row mx-0"},[_c('v-col',{staticClass:"d-flex partner-name py-2 py-md-7",staticStyle:{"max-width":"300px"}},[(!partner.denomination)?_c('div',{staticClass:"pr-md-5 text-left"},[_vm._v(" "+_vm._s(partner.name)+" ")]):_vm._e(),_c('div',{staticClass:"pr-md-5 text-left",attrs:{"id":"legal-partner-name","else":""}},[_vm._v(" "+_vm._s(partner.denomination)+" ")]),_c('div',{staticClass:"mr-0 ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"track-details_2044","small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
                      name: _vm.ROUTE_NAMES.Activities,
                      query: { focus: 'partners-section' },
                    })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir")])])],1)]),(!_vm.readonly)?_c('v-col',{staticClass:"py-2 py-md-7 text-center"},[_c('span',[_vm._v(" "+_vm._s(partner.parts)+" ")])]):_vm._e(),(!_vm.readonly)?_c('v-col',{staticClass:"py-2 py-md-7 text-center"},[(partner.type !== 'legal_person')?_c('span',[(
                _vm.subscription &&
                _vm.subscription.plan.type === _vm.SubscriptionPlanType.Free
              )?[_c('div',{staticClass:"d-flex align-end justify-center"},[_c('v-skeleton-loader',{staticClass:"mr-1",attrs:{"boilerplate":"","type":"text","min-width":"1.5rem"}}),_vm._v("€ ")],1)]:[_vm._v(" "+_vm._s(partner.value)+" € ")]],2):_c('span',[_vm._v("Non disponible")])]):_vm._e(),_c('v-col',{staticClass:"py-1 py-md-6 text-right flex-grow-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',[(!_vm.readonly)?_c('v-btn',_vm._g(_vm._b({attrs:{"id":"track-download_2044","icon":""},on:{"click":function($event){/*downloadPDF(partner.id, partner.type)*/}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-download-outline")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Non disponible - exercice en cours")])])],1)],1)}),1):_vm._e(),(_vm.readonly)?_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"600px","width":"100%"}},_vm._l((_vm.cerfa2044Parts),function(partner){return _c('v-row',{key:partner.id,staticClass:"partner-row mx-0"},[_c('v-col',[(_vm.get(_vm.cerfa2044Archive, partner.id))?_c('span',[_c('DocumentCard',{attrs:{"document":_vm.get(_vm.cerfa2044Archive, partner.id)}})],1):_vm._e()])],1)}),1):_vm._e(),(!_vm.readonly)?_c('v-btn',{staticClass:"partner-btn my-10",attrs:{"id":"track-send_2044","depressed":"","color":"primary"}},[_vm._v(" Envoyer aux associés "),_c('v-badge',{attrs:{"content":"A venir","inline":"","color":"success"}})],1):_vm._e()],1),_c('DialogSubscribe',{attrs:{"openModal":_vm.isOpenDialogSubscribe},on:{"update:openModal":function($event){_vm.isOpenDialogSubscribe=$event},"update:open-modal":function($event){_vm.isOpenDialogSubscribe=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }