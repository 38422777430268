import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { rentalsService } from "@/services";
import {
  RentalAgreement,
  NewRentalAgreement,
  RentalAgreementUpdate,
} from "@edmp/api";
import Vue from "vue";

export interface RentalAgreementsState {
  rentalAgreements: Array<RentalAgreement>;
  loading: boolean;
}

@Module({
  name: "rental-agreements-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class RentalAgreementsStore
  extends VuexModule
  implements RentalAgreementsState
{
  rentalAgreements: Array<RentalAgreement> = [];
  rentalAgreementsFiltered: Array<RentalAgreement> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.rentalAgreements = [];
    this.rentalAgreementsFiltered = [];
    this.loading = false;
  }
  @Mutation
  resetFiltered(): void {
    this.rentalAgreementsFiltered = [];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  // RentalAgreements
  @Action
  async fetchRentalAgreements(value: {
    productId: string;
    realEstateAssetId?: string;
  }): Promise<RentalAgreement[]> {
    this.setLoading(true);
    const rentalAgreements = await rentalsService.agreements.list({
      productId: value.productId,
    });
    this.setRentalAgreements(rentalAgreements);
    this.setLoading(false);
    return rentalAgreements;
  }

  get getRentalAgreementsByRealEstateAssetId() {
    return (realEstateAssetId: string) => {
      return this.rentalAgreements.filter(
        (rentalAgreement) =>
          rentalAgreement.product.realEstateAsset?.id === realEstateAssetId
      );
    };
  }

  get getRentalAgreementsByRentalUnitId() {
    return (rentalUnitId: string) => {
      return this.rentalAgreements.filter(
        (rentalAgreement) =>
          rentalAgreement.product.realEstateAsset?.rentalUnit?.id ===
          rentalUnitId
      );
    };
  }

  @Action
  async fetchRentalAgreementsWithFilter(value: {
    productId: string;
    realEstateAssetId?: string;
    rentalAgreementActive?: boolean;
  }): Promise<RentalAgreement[]> {
    this.resetFiltered();
    this.setLoading(true);
    const rentalAgreementsFilter = await rentalsService.agreements.list({
      productId: value.productId,
      realEstateAssetId: value.realEstateAssetId,
      rentalAgreementActive: value.rentalAgreementActive,
    });

    for (const rentalAgreement of rentalAgreementsFilter) {
      this.setRentalAgreementFilter(rentalAgreement);
    }
    this.setLoading(false);
    return rentalAgreementsFilter;
  }
  // RentalAgreement
  @Mutation
  setRentalAgreement(rentalAgreement: RentalAgreement): void {
    const index = this.rentalAgreements.findIndex(
      ({ id }) => id == rentalAgreement.id
    );
    if (index !== -1) {
      Vue.set(this.rentalAgreements, index, rentalAgreement);
    } else {
      this.rentalAgreements.push(rentalAgreement);
    }
  }

  @Mutation
  setRentalAgreements(rentalAgreements: RentalAgreement[]): void {
    this.rentalAgreements = rentalAgreements;
  }

  @Mutation
  removeRentalAgreement(rentalAgreementId: string): void {
    const index = this.rentalAgreements.findIndex(
      ({ id }) => id == rentalAgreementId
    );
    if (index !== -1) {
      this.rentalAgreements.splice(index, 1);
    }
  }

  @Mutation
  setRentalAgreementFilter(rentalAgreement: RentalAgreement): void {
    const index = this.rentalAgreementsFiltered.findIndex(
      ({ id }) => id == rentalAgreement.id
    );
    if (index !== -1) {
      Vue.set(this.rentalAgreementsFiltered, index, rentalAgreement);
    } else {
      this.rentalAgreementsFiltered.push(rentalAgreement);
    }
  }

  get getRentalAgreement() {
    return (rentalAgreementId: string) => {
      return this.rentalAgreements.find(
        (rentalAgreement) => rentalAgreement.id === rentalAgreementId
      );
    };
  }

  // Create
  @Action
  async createRentalAgreement(
    rentalAgreementCreate: NewRentalAgreement
  ): Promise<RentalAgreement> {
    this.setLoading(true);
    if (!rentalAgreementCreate.product.realEstateAsset?.id) {
      throw new Error(
        "Cannot find real estate asset id in `rentalAgreement.product.realEstateAsset.id`"
      );
    }
    const newRentalAgreement = await rentalsService.agreements.create(
      rentalAgreementCreate
    );
    this.setRentalAgreement(newRentalAgreement);
    this.setLoading(false);
    return newRentalAgreement;
  }

  @Action
  async updateRentalAgreement(
    rentalAgreementUpdate: RentalAgreementUpdate
  ): Promise<RentalAgreement> {
    this.setLoading(true);
    const updatedRentalAgreement = await rentalsService.agreements.update(
      rentalAgreementUpdate
    );
    this.setRentalAgreement(updatedRentalAgreement);
    this.setLoading(false);
    return updatedRentalAgreement;
  }

  @Action
  async deleteRentalAgreement(rentalAgreementId: string): Promise<void> {
    this.setLoading(true);
    await rentalsService.agreements.remove({ id: rentalAgreementId });
    this.removeRentalAgreement(rentalAgreementId);
    this.setLoading(false);
  }
}
