import {
  AccountingCarryForwardLineType,
  AccountingResultLineType,
} from "@edmp/api";

const lineTypeLabels = new Map<
  AccountingCarryForwardLineType | AccountingResultLineType,
  string
>([
  [AccountingResultLineType.ACCOUNTING_RESULT, "Résultat comptable"],
  [
    AccountingResultLineType.DEPRECIATION_AND_AMORTISATION,
    "Dotations aux amortissements",
  ],
  [AccountingResultLineType.OPERATING_RESULT, "Résultat d'exploitation"],
  [
    AccountingResultLineType.DEDUCTIBLE_DEPRECIATION,
    "Amortissements déductibles",
  ],
  [
    AccountingResultLineType.NON_DEDUCTIBLE_DEPRECIATION,
    "Amortissements non déductibles",
  ],
  [
    AccountingCarryForwardLineType.TOTAL_DEPRECIATION,
    "Amortissements reportables n+1",
  ],
  [
    AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION,
    "Résultat fiscal avant imputation des déficits antérieurs",
  ],
  [AccountingCarryForwardLineType.TOTAL_DEFICIT, "Déficit reportable n+1"],
  [
    AccountingResultLineType.TAX_RESULT_AFTER_ALLOCATION,
    "Résultat fiscal après imputation des déficits antérieurs",
  ],
]);

export const accountingResultLinesToDisplay = new Set([
  // "operating-result",
  "accounting-result",
  "depreciation-and-amortization",
  "deductible-depreciation",
  "non-deductible-depreciation",
  // "tax-deficit",
  "tax-result-before-allocation",
  "tax-result-after-allocation",
]);

export const getLineTypeLabel = (
  type: AccountingCarryForwardLineType | AccountingResultLineType
) => lineTypeLabels.get(type);

export type AccountingCarryForwardItem = {
  type: AccountingCarryForwardLineType | AccountingResultLineType;
  recoveryAmount?: string;
  amounts: number[];
};

export const getIndexByType = (
  type: AccountingCarryForwardLineType | AccountingResultLineType
) => {
  let index = 0;
  for (const key of Array.from(lineTypeLabels.keys())) {
    if (key === type) {
      return index;
    }
    index++;
  }
  return -1;
};
