export const production = {
  subscription: {
    publishableKey:
      "pk_live_51K8nQOKnk5fWpl5k0SEuTY5PRbtiMwTw03E1EXgI1FHtOFfcmj8Ow0OKdpMMuNy2gKRSwqoaj7ONYsfAlLeVGI3i00GmEJmlP1",
  },
  sirene: {
    sireneApiKey: "1a850bfa-cede-31fe-9b5a-bb21b7408d4f",
  },
  services: {
    gtm: true,
    axios: true,
    crisp: true,
  },
};
