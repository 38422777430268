import { model, Schema, Document } from "mongoose";
import { ulid } from "ulid";
import { PartialField, User } from "..";

export interface UserStatsNewAccount extends User {
  isOwner: boolean;
  isGranter: boolean;
  device: [
    {
      data: {
        device: {
          client: {
            type: string;
            name: string;
            version: string;
            engine: string;
            engineVersion: string;
          };
          os: {
            name: string;
            version: string;
            platform: string;
          };
          device: {
            type: string;
            brand: string;
            model: string;
          };
          bot: string | null;
        };
        createdAt: string;
      };
    }
  ];
}

export interface UserStatsStockAccount extends User {
  isOwner: boolean;
}

export interface UserStats {
  id: string;
  newAccount?: {
    total: number;
    owner: number;
    granter: number;
    biSync: number;
    device: {
      types: { desktop: number; tablet: number; mobile: number; other: number };
      // clients: { [key: string]: number };
    };
  };
  stockAccount?: {
    total: number;
    completed: number;
    biSyncError: number;
    biSyncSuspend: number;
    emailValide: number;
    phoneAdd: number;
  };
  date: string;
}
export type UserStatsUpsert = PartialField<UserStats, "id">;

const userStatsSchema = new Schema<UserStatsDocument>(
  {
    _id: { type: String, default: () => ulid() },
    newAccount: {
      total: { type: Number },
      owner: { type: Number },
      granter: { type: Number },
      biSync: { type: Number },
      device: {
        types: {
          desktop: { type: Number },
          tablet: { type: Number },
          mobile: { type: Number },
          other: { type: Number },
        },
        // clients: { type: Object },
      },
    },
    stockAccount: {
      total: { type: Number },
      completed: { type: Number },
      biSyncError: { type: Number },
      biSyncSuspend: { type: Number },
      emailValide: { type: Number },
      phoneAdd: { type: Number },
    },
    date: { type: String, require: true, index: true, unique: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: UserStatsDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type UserStatsDocument = UserStats & Document<string>;

export const UserStatsModel = model<UserStatsDocument>("UserStats", userStatsSchema, "UsersStats");
