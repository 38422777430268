























































import { TaskActivityGroupLocal } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  productsStore,
  subscriptionsStore,
  taskActivityStore,
} from "@/store";
import {
  getReferredIdByTypeWithReferences,
  SubscriptionsModel,
  TaskActivityTypeReference,
  TaskCode,
  TaskGroupCode,
} from "@edmp/api";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "GroupAction",
  props: {
    taskActivityGroupLocal: {
      type: Object as PropType<TaskActivityGroupLocal>,
      required: true,
    },
  },
  setup() {
    const validateEventGeneralAssemblyYearEndCreateStep = async () => {
      const taskActivityCreate = taskActivityStore.taskActivityGroups
        .find(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskActivityTypeReference.product
            ) === productsStore.currentId &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskActivityTypeReference.accountingPeriod
            ) === accountingPeriodsStore.currentId
        )
        ?.taskActivitiesLocal.find((taskActivity) => {
          return taskActivity.code === TaskCode.Create;
        });
      if (taskActivityCreate) {
        await taskActivityStore.validateTaskActivity({
          taskActivityLocal: taskActivityCreate,
        });
      }
    };

    return {
      validateEventGeneralAssemblyYearEndCreateStep,
      TaskGroupCode,
      ROUTE_NAMES,
      isIR: accountingPeriodsStore.isIR,
      isPremium:
        subscriptionsStore.getCurrentSubscription?.plan.type ===
        SubscriptionsModel.PlanType.Premium,
    };
  },
});
