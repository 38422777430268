import { Document, Schema, ToObjectOptions, model } from "mongoose";
import { ulid } from "ulid";
import { Partner, TaxDeclarationHeaderData, TaxRegime } from "..";
import { accountingResultLinesMetadata } from "./AccountingResult.model";
import { Address, RequireField, addressSchema } from "./Common.model";
import { Direction, LedgerAccountEnum } from "./JournalComposedEntry";

// Types
export enum TaxDeclaration2033LineNumberSimplifiedBalanceSheet {
  "L014" = "014", // Assets - Fixed assets - Intangible assets - Other - Gross
  "L016" = "016", // Assets - Fixed assets - Intangible assets - Other - Depreciation Provisions
  "L014_016_NET" = "014-016-Net", // Assets - Fixed Assets - Intangible assets - Other - Net
  "L028" = "028", // Assets - Fixed assets - Fixed assets - Gross
  "L030" = "030", // Assets - Fixed assets - Tangible fixed assets - Depreciation Provisions
  "L028_030_NET" = "028-030-Net", // Assets - Fixed Assets - Fixed Assets - Net
  "L040" = "040", // Assets - Fixed assets - Financial fixed assets - Gross
  "L044" = "044", // Assets - Fixed Assets - Total I - Gross
  "L048" = "048", // Assets - Fixed Assets - Total I - Depreciation Provisions
  "L044_048_NET" = "044-048-Net", // Assets - Fixed assets - Total I - Net
  "L068" = "068", // Assets - Current assets - Receivables - Clients and related accounts - Gross
  "L072" = "072", // Assets - Current assets - Receivables - Others
  "L080" = "080", // Assets - Current assets - Marketable securities - Gross
  "L084" = "084", // Assets - Current assets - Cash - Gross
  "L096" = "096", // Assets - Current assets - Total II - Gross
  "L098" = "098", // Assets - Current assets - Total II - Depreciation Provisions
  "L096_098_NET" = "096-098-Net", // Assets - Current assets - Total II - Net
  "L110" = "110", // Active - Grand total (I + II) - Gross
  "L112" = "112", // Assets - Grand total (I + II) - Depreciation Provisions
  "L110_112_NET" = "110-112-Net", // Active - Grand Total (I + II) - Net
  "L120" = "120", // Liabilities - Equity - Share or individual capital
  "L134" = "134", // Liabilities - Equity - Retained earnings
  "L136" = "136", // Liabilities - Equity - Result for the financial year
  "L142" = "142", // Liabilities - Equity - Total I
  "L154" = "154", // Liabilities - Provisions for risks and charges (Total II)
  "L156" = "156", // Liabilities - Debts - Loans and similar debts
  "L166" = "166", // Liabilities - Debts - Suppliers and related accounts
  "L169" = "169", // Liabilities - Debts - Other debts - Of which partners' current accounts for year N
  "L172" = "172", // Liabilities - Debts - Other debts
  "L173" = "173", // Liabilities - Associate current accounts
  "L176" = "176", // Passive - Total III
  "L180" = "180", // Passive - Grand total (I + II + III)
}
export enum TaxDeclaration2033LineNumberSimplifiedIncomeStatement {
  "L218" = "218", // Production sold - Services
  "L226" = "226", // Operating subsidies received
  "L230" = "230", // Others products
  "L232" = "232", // Total operating income excluding VAT (I)
  "L242" = "242", // Other external charges
  "L243" = "243", // Taxes other and payments - Including CFE and CVAE
  "L244" = "244", // Taxes other and payments
  "L254" = "254", // Depreciation and amortization
  "L264" = "264", // Total operating expenses (II)
  "L270" = "270", // 1 – OPERATING RESULT (I – II)
  "L280" = "280", // Financial Products (III)
  "L290" = "290", // Outstanding Products (IV)
  "L294" = "294", // Financial charges (V)
  "L300" = "300", // Extraordinary charges
  "L306" = "306", // Income tax (VII)
  "L310" = "310", // Benefit or loss from exercise ((I + III + IV) – (II + V + VI + VII))
  "L312" = "312", // B – Tax result 1 before reinstatement - Tax benefit
  "L314" = "314", // B – Tax result 1 before reinstatement - Tax deficit
  "L318" = "318", // B – Tax result 1 - Reinstatement - Excess depreciation and other non-deductible depreciation
  "L324" = "324", // B – Tax result 1 - Reinstatement - Taxes and non-deductible taxes
  "L352" = "352", // Tax result 2 before allocation of previous deficits - benefit
  "L354" = "354", // Tax result 2 before allocation of previous deficits - deficit
  "L356" = "356", // Fiscal year deficit carried back
  "L360_TOTAL" = "360-Total", // Previous deficits carried forward
  "L360" = "360", // Previous deficits carried forward, of which allocated to the result
  "L370" = "370", // Tax result after allocation of deficits - benefit
  "L372" = "372", // Tax result after allocation of deficits - deficit
}
export enum TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses {
  // "L400" = "400", // I - Fixed assets - Intangible assets - Goodwill - Start of exercise
  // "L402" = "402", // I - Fixed assets - Intangible assets - Goodwill - Increases
  // "L404" = "404", // I - Fixed assets - Intangible assets - Goodwill - Decreases
  // "L406" = "406", // I - Fixed assets - Intangible assets - Goodwill - End of exercise
  "L410" = "410", // I - Fixed assets - Intangible assets - Other - Start of exercise
  "L412" = "412", // I - Fixed assets - Intangible assets - Other - Increases
  "L414" = "414", // I - Fixed assets - Intangible assets - Other - Decreases
  "L416" = "416", // I - Fixed assets - Intangible assets - Other - End of exercise
  "L420" = "420", // I - Fixed assets - Fixed assets - Land - Start of exercise
  "L422" = "422", // I - Fixed assets - Fixed assets - Land - Increases
  "L424" = "424", // I - Fixed assets - Fixed assets - Land - Decreases
  "L426" = "426", // I - Fixed assets - Fixed assets - Land - End of exercise
  "L430" = "430", // I - Fixed assets - Fixed assets - Buildings - Start of exercise
  "L432" = "432", // I - Fixed assets - Fixed assets - Buildings - Increases
  "L434" = "434", // I - Fixed assets - Fixed assets - Buildings - Decreases
  "L436" = "436", // I - Fixed assets - Fixed assets - Buildings - End of exercise
  "L440" = "440", // I - Fixed assets - Fixed assets - Small work - Start of exercise
  "L442" = "442", // I - Fixed assets - Fixed assets - Small work - Increases
  "L444" = "444", // I - Fixed assets - Fixed assets - Small work - Decreases
  "L446" = "446", // I - Fixed assets - Fixed assets - Small work - End of exercise
  "L450" = "450", // I - Fixed assets - Fixed assets - Furniture - Start of exercise
  "L452" = "452", // I - Fixed assets - Fixed assets - Furniture - Increases
  "L454" = "454", // I - Fixed assets - Fixed assets - Furniture - Decreases
  "L456" = "456", // I - Fixed assets - Fixed assets - Furniture - End of exercise
  // "L460" = "460", // I - Fixed assets - Fixed assets - Transportation equipment - Start of exercise
  // "L462" = "462", // I - Fixed assets - Fixed assets - Transportation equipment - Increases
  // "L464" = "464", // I - Fixed assets - Fixed assets - Transportation equipment - Decreases
  // "L466" = "466", // I - Fixed assets - Fixed assets - Transportation equipment - End of exercise
  // "L470" = "470", // I - Fixed assets - Fixed assets - Other property, plant and equipment - Start of exercise
  // "L472" = "472", // I - Fixed assets - Fixed assets - Other property, plant and equipment - Increases
  // "L474" = "474", // I - Fixed assets - Fixed assets - Other property, plant and equipment - Decreases
  // "L476" = "476", // I - Fixed assets - Fixed assets - Other property, plant and equipment - End of exercise
  // "L480" = "480", // I - Fixed assets - Financial fixed assets - Start of exercise
  // "L482" = "482", // I - Fixed assets - Financial fixed assets - Increases
  // "L484" = "484", // I - Fixed assets - Financial fixed assets - Decreases
  // "L486" = "486", // I - Fixed assets - Financial fixed assets - End of exercise
  "L490" = "490", // I - Fixed assets - Total - Start of exercise
  "L492" = "492", // I - Fixed assets - Total - Increases
  "L494" = "494", // I - Fixed assets - Total - Decreases
  "L496" = "496", // I - Fixed assets - Total - End of exercise
  // "L495" = "495", // II - Amortisations - Intangible assets - Goodwill - Start of exercise
  // "L497" = "497", // II - Amortisations - Intangible assets - Goodwill - Increases
  // "L498" = "498", // II - Amortisations - Intangible assets - Goodwill - Decreases
  // "L499" = "499", // II - Amortisations - Intangible assets - Goodwill - End of exercise
  "L500" = "500", // II - Amortisations - Intangible assets - Other - Start of exercise
  "L502" = "502", // II - Amortisations - Intangible assets - Other - Increases
  "L504" = "504", // II - Amortisations - Intangible assets - Other - Decreases
  "L506" = "506", // II - Amortisations - Intangible assets - Other - End of exercise
  "L510" = "510", // II - Amortisations - Fixed assets - Land - Start of exercise
  "L512" = "512", // II - Amortisations - Fixed assets - Land - Increases
  "L514" = "514", // II - Amortisations - Fixed assets - Land - Decreases
  "L516" = "516", // II - Amortisations - Fixed assets - Land - End of exercise
  "L520" = "520", // II - Amortisations - Fixed assets - Buildings - Start of exercise
  "L522" = "522", // II - Amortisations - Fixed assets - Buildings - Increases
  "L524" = "524", // II - Amortisations - Fixed assets - Buildings - Decreases
  "L526" = "526", // II - Amortisations - Fixed assets - Buildings - End of exercise
  "L530" = "530", // II - Amortisations - Fixed assets - Small work - Start of exercise
  "L532" = "532", // II - Amortisations - Fixed assets - Small work - Increases
  "L534" = "534", // II - Amortisations - Fixed assets - Small work - Decreases
  "L536" = "536", // II - Amortisations - Fixed assets - Small work - End of exercise
  "L540" = "540", // II - Amortisations - Fixed assets - Furniture - Start of exercise
  "L542" = "542", // II - Amortisations - Fixed assets - Furniture - Increases
  "L544" = "544", // II - Amortisations - Fixed assets - Furniture - Decreases
  "L546" = "546", // II - Amortisations - Fixed assets - Furniture - End of exercise
  // "L550" = "550", // II - Amortisations - Fixed assets - Transportation equipment - Start of exercise
  // "L552" = "552", // II - Amortisations - Fixed assets - Transportation equipment - Increases
  // "L554" = "554", // II - Amortisations - Fixed assets - Transportation equipment - Decreases
  // "L556" = "556", // II - Amortisations - Fixed assets - Transportation equipment - End of exercise
  // "L560" = "560", // II - Amortisations - Fixed assets - Other property, plant and equipment - Start of exercise
  // "L562" = "562", // II - Amortisations - Fixed assets - Other property, plant and equipment - Increases
  // "L564" = "564", // II - Amortisations - Fixed assets - Other property, plant and equipment - Decreases
  // "L566" = "566", // II - Amortisations - Fixed assets - Other property, plant and equipment - End of exercise
  "L570" = "570", // II - Amortisations - Total - Start of exercise
  "L572" = "572", // II - Amortisations - Total - Increases
  "L574" = "574", // II - Amortisations - Total - Decreases
  "L576" = "576", // II - Amortisations - Total - End of exercise
}
export enum TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit {
  "L982" = "982", // II - Carry-forward deficits - Deficits remaining to be carried forward from the previous year
  "L983" = "983", // II - Carry-forward deficits - Carried-forward losses
  "L984" = "984", // II - Carry-forward deficits - Deficits carried forward
  "L860" = "860", // II - Carry-forward deficits - Deficits for the year
  "L870" = "870", // II - Carry-forward deficits - Total deficits remaining to be carried forward
}
export enum TaxDeclaration2033LineNumberCompositionOfShareCapital {
  "L901" = "901", // Total number of partners or shareholders legal persons of the company
  "L902" = "902", // Total number of units or corresponding shares (line 901)
  "L903" = "903", // Total number of partners or individual shareholders in the company
  "L904" = "904", // Total number of units or corresponding shares (line 903)
}
export enum TaxDeclaration2033LineNumberExtraDeduction {
  "CARRY_FORWARD_DEPRECIATION_FROM_PREVIOUS" = "CARRY_FORWARD_DEPRECIATION_FROM_PREVIOUS",
  "CARRY_FORWARD_DEPRECIATION_ALLOCATED" = "CARRY_FORWARD_DEPRECIATION_ALLOCATED",
  "REMAINING_CARRY_FORWARD_DEPRECIATION" = "REMAINING_CARRY_FORWARD_DEPRECIATION",
}
export const TaxDeclaration2033LineNumber = {
  ...TaxDeclaration2033LineNumberSimplifiedBalanceSheet,
  ...TaxDeclaration2033LineNumberSimplifiedIncomeStatement,
  ...TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses,
  ...TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit,
  ...TaxDeclaration2033LineNumberCompositionOfShareCapital,
  ...TaxDeclaration2033LineNumberExtraDeduction,
};
export type TaxDeclaration2033LineNumber =
  typeof TaxDeclaration2033LineNumber[keyof typeof TaxDeclaration2033LineNumber];

export type TaxDeclaration2033LineAmount<WithTransactions extends boolean = false> = {
  amount: number;
  amountDirection: Direction;
} & (WithTransactions extends true
  ? { transactionIds: string[] }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    object);
export type TaxDeclaration2033LineText = {
  text: string;
};

export type TaxDeclaration2033LinesAmountWithTransactions = {
  [key in
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit]: TaxDeclaration2033LineAmount<true>;
};
export type TaxDeclaration2033LinesAmountWithoutTransactions = {
  [key in
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses
    | TaxDeclaration2033LineNumberExtraDeduction]: TaxDeclaration2033LineAmount<false>;
};
export type TaxDeclaration2033LinesText = {
  [key in TaxDeclaration2033LineNumberCompositionOfShareCapital]: TaxDeclaration2033LineText;
};
export type TaxDeclaration2033Lines = TaxDeclaration2033LinesAmountWithTransactions &
  TaxDeclaration2033LinesAmountWithoutTransactions &
  TaxDeclaration2033LinesText;

export type TaxDeclaration2033DataArchive<
  Regime extends TaxRegime.IS_2065 | TaxRegime.LMNP_2031 = TaxRegime.IS_2065 | TaxRegime.LMNP_2031
> = TaxDeclarationHeaderData & {
  partners: (Pick<
    Partner,
    | "id"
    | "type"
    | "firstName"
    | "lastName"
    | "denomination"
    | "legalStatus"
    | "siret"
    | "birthDate"
    | "pctDetention"
    | "partsSocial"
  > & {
    address: RequireField<Address, "street" | "city" | "zip">;
  } & (Regime extends TaxRegime.IS_2065
      ? { birthAddress: { place: string; department: number /* 99: autre */ } & Required<Pick<Address, "country">> }
      : {
          birthAddress?: { place: string; department: number /* 99: autre */ } & Required<Pick<Address, "country">>;
        }))[];
};
export type TaxDeclaration2033WithDataArchive<
  WithDataArchive extends boolean = true,
  Regime extends TaxRegime.IS_2065 | TaxRegime.LMNP_2031 = TaxRegime.IS_2065 | TaxRegime.LMNP_2031
> = true extends WithDataArchive ? { dataArchived: TaxDeclaration2033DataArchive<Regime> } : Record<never, never>;
export type TaxDeclaration2033<
  IsValidate extends boolean = boolean,
  WithDataArchive extends boolean = boolean,
  Regime extends TaxRegime.IS_2065 | TaxRegime.LMNP_2031 = TaxRegime.IS_2065 | TaxRegime.LMNP_2031
> = {
  productId: string;
  accountingPeriodId: string;
  taxRegime: Regime;
  isValidate: IsValidate;
  lines: TaxDeclaration2033Lines;
} & (
  | { isValidate: false }
  | ({
      id: string;
      isValidate: true;
      isValidateByUserId: string;
      createdAt: string;
      updatedAt: string;
    } & TaxDeclaration2033WithDataArchive<WithDataArchive, Regime>)
);

export type TaxDeclaration2033Create = Omit<TaxDeclaration2033<true, true>, "id" | "createdAt" | "updatedAt">;

export type TaxDeclaration2033Update<> = Omit<
  RequireField<Partial<TaxDeclaration2033<true, false>>, "id">,
  "productId" | "accountingPeriodId" | "isValidateByUserId" | "createdAt" | "updatedAt"
>;

export type TaxDeclaration2033Generated<
  Regime extends TaxRegime.IS_2065 | TaxRegime.LMNP_2031 = TaxRegime.IS_2065 | TaxRegime.LMNP_2031
> = TaxDeclaration2033<false, boolean, Regime> & {
  dataArchive: TaxDeclaration2033DataArchive<Regime>;
};

export type TaxDeclaration2033LinesMetadata = {
  name: string;
  description?: string;
  isTotal?: boolean;
  isResult?: boolean;
  accounts: LedgerAccountEnum[];
};

// Mongo
export type TaxDeclaration2033Document = TaxDeclaration2033<true, true> & Document<string>;

const TaxDeclaration2033Schema = new Schema<TaxDeclaration2033Document>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    isValidate: { type: Boolean },
    isValidateByUserId: { type: String },
    lines: {
      type: Map,
      of: {
        amount: { type: Number },
        amountDirection: { type: String, enum: Object.values(Direction) },
        transactionIds: [{ type: String }],
        text: { type: String },
      },
    },
    dataArchived: {
      user: {
        id: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        email: { type: String },
      },
      product: {
        id: { type: String },
        email: { type: String },
      },
      activity: {
        id: { type: String },
        type: { type: String },
        address: addressSchema,
        addressPrevious: addressSchema,
        siret: { type: String },
        name: { type: String },
        operatorType: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        legalStatus: { type: String },
      },
      accountingPeriod: {
        id: { type: String },
        startAt: { type: String },
        endAt: { type: String },
        taxRegime: { type: String },
        firstYear: { type: Boolean },
      },
      accountingPeriodPrevious: {
        startAt: { type: String },
        endAt: { type: String },
      },
      partnerOwned: {
        id: { type: String },
        type: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        role: { type: String },
        createdAt: { type: String },
      },
      partners: [
        {
          id: { type: String },
          type: { type: String },
          firstName: { type: String },
          lastName: { type: String },
          denomination: { type: String },
          legalStatus: { type: String },
          siret: { type: String },
          birthDate: { type: String },
          address: addressSchema,
          birthAddress: {
            place: { type: String },
            department: { type: Number },
            country: { type: String },
          },
          pctDetention: { type: String },
          partsSocial: { type: String },
        },
      ],
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(
        doc: Omit<TaxDeclaration2033Document, "createdAt" | "updatedAt"> & {
          _id: string;
          createdAt: Date;
          updatedAt: Date;
        },
        ret: TaxDeclaration2033<true> & { _id?: string },
        options: ToObjectOptions
      ) {
        delete ret._id;
        ret.id = doc._id;

        ret.lines = (
          doc.lines as unknown as Map<TaxDeclaration2033LineNumber, typeof doc.lines[TaxDeclaration2033LineNumber]> &
            Document
        ).toJSON<typeof ret.lines>();
        for (const [lineName, line] of Object.entries(ret.lines)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          ret.lines[lineName as unknown as TaxDeclaration2033LineNumber] = (
            line as typeof ret.lines[TaxDeclaration2033LineNumber] & Document
          ).toJSON<typeof ret.lines[TaxDeclaration2033LineNumber]>() as any;
        }

        return ret;
      },
    },
  }
);

export const TaxDeclaration2033Model = model<TaxDeclaration2033Document>(
  "TaxDeclaration2033",
  TaxDeclaration2033Schema,
  "TaxDeclarations2033"
);

// Function
export const isTaxDeclaration2033LineNumberSimplifiedBalanceSheet = (lineNumber: TaxDeclaration2033LineNumber) =>
  (Object.values(TaxDeclaration2033LineNumberSimplifiedBalanceSheet) as TaxDeclaration2033LineNumber[]).includes(
    lineNumber
  );

export const isTaxDeclaration2033LineNumberSimplifiedIncomeStatement = (lineNumber: TaxDeclaration2033LineNumber) =>
  (Object.values(TaxDeclaration2033LineNumberSimplifiedIncomeStatement) as TaxDeclaration2033LineNumber[]).includes(
    lineNumber
  );

export const isTaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses = (
  lineNumber: TaxDeclaration2033LineNumber
) =>
  (
    Object.values(
      TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses
    ) as TaxDeclaration2033LineNumber[]
  ).includes(lineNumber);

export const isTaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit = (
  lineNumber: TaxDeclaration2033LineNumber
) =>
  (
    Object.values(
      TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit
    ) as TaxDeclaration2033LineNumber[]
  ).includes(lineNumber);

export const isTaxDeclaration2033LineNumberCompositionOfShareCapital = (lineNumber: TaxDeclaration2033LineNumber) =>
  (Object.values(TaxDeclaration2033LineNumberCompositionOfShareCapital) as TaxDeclaration2033LineNumber[]).includes(
    lineNumber
  );

export const isTaxDeclaration2033LineNumberExtraDeduction = (lineNUmber: TaxDeclaration2033LineNumber) =>
  (Object.values(TaxDeclaration2033LineNumberExtraDeduction) as TaxDeclaration2033LineNumber[]).includes(lineNUmber);

// Const
/**
 * IS
 */
const taxDeclaration2033LinesMetadataISLayer0: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L016
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L030
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L040
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L068
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L072
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L080
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L084
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L120
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L134
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L154
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L156
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L166
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L169
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L172
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L173
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L218
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L226
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L230
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L232
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L242
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L244
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L254
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L264
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L280
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L290
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L294
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L300
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L306
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L324
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L410
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L412
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L414
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L416
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L420
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L422
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L424
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L426
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L430
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L432
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L434
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L436
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L440
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L442
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L444
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L446
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L450
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L452
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L454
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L456
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L500
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L502
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L504
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L506
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L510
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L512
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L514
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L516
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L520
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L522
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L524
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L526
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L530
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L532
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L534
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L536
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L540
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L542
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L544
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L546
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L982
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "014": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Brut",
    accounts: [LedgerAccountEnum.N201100],
  },
  "016": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Amortissements Provisions",
    accounts: [LedgerAccountEnum.N280000],
  },
  "028": {
    name: "Actif immobilisé - Immobilisations corporelles - Brut",
    accounts: [
      LedgerAccountEnum.N201000,
      LedgerAccountEnum.N211100,
      LedgerAccountEnum.N213100,
      LedgerAccountEnum.N213500,
      LedgerAccountEnum.N215700,
      LedgerAccountEnum.N218400,
    ],
  },
  "030": {
    name: "Actif immobilisé - Immobilisations corporelles - Amortissements Provisions",
    accounts: [
      LedgerAccountEnum.N280100,
      LedgerAccountEnum.N281310,
      LedgerAccountEnum.N281350,
      LedgerAccountEnum.N281840,
    ],
  },
  "040": {
    name: "Actif immobilisé - Immobilisations financières - Brut",
    accounts: [LedgerAccountEnum.N271000, LedgerAccountEnum.N272000],
  },
  "068": {
    name: "Actif circulant - Clients et comptes rattachés - Brut",
    accounts: [LedgerAccountEnum.N411000],
  },
  "072": {
    name: "Actif circulant - Autres - Brut",
    accounts: [LedgerAccountEnum.N467000, LedgerAccountEnum.N445670],
  },
  "080": {
    name: "Actif circulant - Valeurs mobilières de placement - Brut",
    accounts: [LedgerAccountEnum.N503000, LedgerAccountEnum.N508000],
  },
  "084": {
    name: "Actif circulant - Disponibilités - Brut",
    accounts: [LedgerAccountEnum.N512000],
  },
  "120": {
    name: "Capitaux propres - Capital social ou individuel",
    accounts: [LedgerAccountEnum.N101100],
  },
  "134": {
    name: "Capitaux propres - Report à nouveau",
    accounts: [LedgerAccountEnum.N110001],
  },
  "154": {
    name: "Provisions pour risques et charges (Total II)",
    isTotal: true,
    accounts: [LedgerAccountEnum.N491000],
  },
  "156": {
    name: "Dettes - Emprunts et dettes assimilées",
    accounts: [
      LedgerAccountEnum.N164000,
      LedgerAccountEnum.N164100,
      LedgerAccountEnum.N164200,
      LedgerAccountEnum.N165000,
    ],
  },
  "166": {
    name: "Dettes - Fournisseurs et comptes rattachés",
    accounts: [LedgerAccountEnum.N401000],
  },
  "169": {
    name: "Montant de la TVA",
    accounts: [LedgerAccountEnum.N445800],
  },
  "172": {
    name: "Dettes fiscales et sociales (dont montant de la TVA indiqué dans la case 169)",
    accounts: [LedgerAccountEnum.N444000, LedgerAccountEnum.N445800, LedgerAccountEnum.N447000],
  },
  "173": {
    name: "Comptes courants d'associés",
    accounts: [LedgerAccountEnum.N455000, LedgerAccountEnum.N455010],
  },
  "218": {
    name: "Production vendue - Services",
    accounts: [LedgerAccountEnum.N706000],
  },
  "226": {
    name: "Subventions d'exploitations reçues",
    accounts: [LedgerAccountEnum.N708300],
  },
  "230": {
    name: "Autres produits",
    accounts: [
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N708399,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N781100,
    ],
  },
  "232": {
    name: "Total des produits d'exploitation hors TVA",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N706000,
      LedgerAccountEnum.N708300,
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N708399,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N781100,
    ],
  },
  "242": {
    name: "Autres charges externes",
    accounts: [
      LedgerAccountEnum.N616600,
      LedgerAccountEnum.N622710,
      LedgerAccountEnum.N627100,
      LedgerAccountEnum.N606110,
      LedgerAccountEnum.N606300,
      LedgerAccountEnum.N606400,
      LedgerAccountEnum.N614020,
      LedgerAccountEnum.N614010,
      LedgerAccountEnum.N622610,
      LedgerAccountEnum.N615200,
      LedgerAccountEnum.N615210,
      LedgerAccountEnum.N661100,
      LedgerAccountEnum.N616100,
      LedgerAccountEnum.N671400,
      LedgerAccountEnum.N671000,
      LedgerAccountEnum.N622700,
      LedgerAccountEnum.N623700,
      LedgerAccountEnum.N627200,
      LedgerAccountEnum.N625100,
    ],
  },
  "244": {
    name: "Impôts, taxes et versements assimilés",
    accounts: [LedgerAccountEnum.N622000, LedgerAccountEnum.N635121, LedgerAccountEnum.N635125],
  },
  "254": {
    name: "Dotations aux amortissements",
    accounts: [LedgerAccountEnum.N681120, LedgerAccountEnum.N681110],
  },
  "264": {
    name: "Total des charges d'exploitation",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N616600,
      LedgerAccountEnum.N622710,
      LedgerAccountEnum.N627100,
      LedgerAccountEnum.N606110,
      LedgerAccountEnum.N606300,
      LedgerAccountEnum.N606400,
      LedgerAccountEnum.N614020,
      LedgerAccountEnum.N614010,
      LedgerAccountEnum.N622610,
      LedgerAccountEnum.N615200,
      LedgerAccountEnum.N615210,
      LedgerAccountEnum.N661100,
      LedgerAccountEnum.N616100,
      LedgerAccountEnum.N671400,
      LedgerAccountEnum.N622700,
      LedgerAccountEnum.N623700,
      LedgerAccountEnum.N627200,
      LedgerAccountEnum.N625100,
    ],
  },
  "280": { name: "Produits financiers", accounts: [LedgerAccountEnum.N761000] },
  "290": { name: "Produits exceptionnels", accounts: [LedgerAccountEnum.N775000] },
  "294": { name: "Charges financières", accounts: [LedgerAccountEnum.N661110, LedgerAccountEnum.N661600] },
  "300": { name: "Charges exceptionnelles", accounts: [LedgerAccountEnum.N675000] },
  "306": { name: "Impôt sur les bénéfices", accounts: [LedgerAccountEnum.N671000, LedgerAccountEnum.N695000] },
  "324": { name: "Impôts et taxes non déductibles", accounts: [LedgerAccountEnum.N671000, LedgerAccountEnum.N695000] },
  "410": {
    name: "Immobilisations incorporelles - Autres - Valeur en début d'exercice",
    accounts: [LedgerAccountEnum.N201100],
  },
  "412": {
    name: "Immobilisations incorporelles - Autres - Augmentations",
    accounts: [LedgerAccountEnum.N201100],
  },
  "414": {
    name: "Immobilisations incorporelles - Autres - Diminutions",
    accounts: [LedgerAccountEnum.N201100],
  },
  "416": {
    name: "Immobilisations incorporelles - Autres - Valeur en fin d'exercice",
    accounts: [LedgerAccountEnum.N201100],
  },
  "420": { name: "Terrains - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N211100] },
  "422": { name: "Terrains - Augmentations", accounts: [LedgerAccountEnum.N211100] },
  "424": { name: "Terrains - Diminutions", accounts: [LedgerAccountEnum.N211100] },
  "426": { name: "Terrains - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N211100] },
  "430": { name: "Constructions - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N213100] },
  "432": { name: "Constructions - Augmentations", accounts: [LedgerAccountEnum.N213100] },
  "434": { name: "Constructions - Diminutions", accounts: [LedgerAccountEnum.N213100] },
  "436": { name: "Constructions - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N213100] },
  "440": { name: "Installation techniques - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N213500] },
  "442": { name: "Installation techniques - Augmentations", accounts: [LedgerAccountEnum.N213500] },
  "444": { name: "Installation techniques - Diminutions", accounts: [LedgerAccountEnum.N213500] },
  "446": { name: "Installation techniques - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N213500] },
  "450": { name: "Installation générales - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N218400] },
  "452": { name: "Installation générales - Augmentations", accounts: [LedgerAccountEnum.N218400] },
  "454": { name: "Installation générales - Diminutions", accounts: [LedgerAccountEnum.N218400] },
  "456": { name: "Installation générales - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N218400] },
  "500": {
    name: "Autres immobilisations incorporelles - Valeur en début d'exercice",
    accounts: [LedgerAccountEnum.N280000],
  },
  "502": {
    name: "Autres immobilisations incorporelles - Augmentations",
    accounts: [LedgerAccountEnum.N280000],
  },
  "504": {
    name: "Autres immobilisations incorporelles - Diminutions",
    accounts: [LedgerAccountEnum.N280000],
  },
  "506": {
    name: "Autres immobilisations incorporelles - Valeur en fin d'exercice",
    accounts: [LedgerAccountEnum.N280000],
  },
  "510": { name: "Terrains - Valeur en début d'exercice", accounts: [] },
  "512": { name: "Terrains - Augmentations", accounts: [] },
  "514": { name: "Terrains - Diminutions", accounts: [] },
  "516": { name: "Terrains - Valeur en fin d'exercice", accounts: [] },
  "520": { name: "Constructions - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281310] },
  "522": { name: "Constructions - Augmentations", accounts: [LedgerAccountEnum.N281310] },
  "524": { name: "Constructions - Diminutions", accounts: [LedgerAccountEnum.N281310] },
  "526": { name: "Constructions - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281310] },
  "530": { name: "Installation techniques - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281350] },
  "532": { name: "Installation techniques - Augmentations", accounts: [LedgerAccountEnum.N281350] },
  "534": { name: "Installation techniques - Diminutions", accounts: [LedgerAccountEnum.N281350] },
  "536": { name: "Installation techniques - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281350] },
  "540": { name: "Installation générales - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281840] },
  "542": { name: "Installation générales - Augmentations", accounts: [LedgerAccountEnum.N281840] },
  "544": { name: "Installation générales - Diminutions", accounts: [LedgerAccountEnum.N281840] },
  "546": { name: "Installation générales - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281840] },
  "982": {
    name: "Déficits restant à reporter au titre de l'exercice précédent",
    accounts: [LedgerAccountEnum.N110001],
  },
};
const taxDeclaration2033LinesMetadataISLayer1: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014_016_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028_030_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L048
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L098
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L176
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L490
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L492
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L494
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L496
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L570
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L572
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L574
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L576
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L983
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L984
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L860
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "014-016-Net": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Net",
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["014"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["016"].accounts,
    ],
  },
  "028-030-Net": {
    name: "Actif immobilisé - Immobilisations corporelles - Net",
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["028"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["030"].accounts,
    ],
  },
  "044": {
    name: "Actif immobilisé - Total I - Brut",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["014"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["028"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["040"].accounts,
    ],
  },
  "048": {
    name: "Actif immobilisé - Total I - Amortissements Provisions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["016"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["030"].accounts,
    ],
  },
  "096": {
    name: "Actif circulant - Total II - Brut",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["068"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["072"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["080"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["084"].accounts,
    ],
  },
  "098": {
    name: "Actif circulant - Total II - Amortissements Provisions",
    isTotal: true,
    accounts: [],
  },
  "176": {
    name: "Total III",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["156"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["166"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["172"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["173"].accounts,
    ],
  },
  "310": {
    name: "Benefice ou perte de l'exercice",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["232"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["280"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["290"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["264"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["294"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["300"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["306"].accounts,
    ],
  },
  "490": {
    name: "Total - Valeur en début d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["410"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["420"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["430"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["440"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["450"].accounts,
    ],
  },
  "492": {
    name: "Total - Augmentations",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["412"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["422"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["432"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["442"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["452"].accounts,
    ],
  },
  "494": {
    name: "Total - Diminutions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["414"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["424"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["434"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["444"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["454"].accounts,
    ],
  },
  "496": {
    name: "Total - Valeur en fin d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["416"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["426"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["436"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["446"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["456"].accounts,
    ],
  },
  "570": {
    name: "Total - Valeur en début d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["500"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["510"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["520"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["530"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["540"].accounts,
    ],
  },
  "572": {
    name: "Total - Augmentations",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["502"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["512"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["522"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["532"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["542"].accounts,
    ],
  },
  "574": {
    name: "Total - Diminutions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["504"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["514"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["524"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["534"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["544"].accounts,
    ],
  },
  "576": {
    name: "Total - Valeur en fin d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["506"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["516"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["526"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["536"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["546"].accounts,
    ],
  },
  "983": { name: "Déficits imputés", accounts: [] },
  "984": { name: "Déficits reportables", accounts: [] },
  "860": { name: "Déficits de l’exercice", accounts: [] },
};
const taxDeclaration2033LinesMetadataISLayer2: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044_048_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096_098_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L112
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L136
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L360
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "044-048-Net": {
    name: "Actif immobilisé - Total I - Net",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["044"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["048"].accounts,
    ],
  },
  "096-098-Net": {
    name: "Actif circulant - Total II - Net",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["096"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["098"].accounts,
    ],
  },
  "110": {
    name: "Total général (I + II) - Brut",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["096"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["044"].accounts,
    ],
  },
  "112": {
    name: "Total général (I + II) - Amortissements Provisions",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataISLayer1["048"].accounts],
  },
  "136": {
    name: "Capitaux propres - Résultat de l'exercice",
    accounts: [...taxDeclaration2033LinesMetadataISLayer1["310"].accounts],
  },
  352: {
    name: "Résultat fiscal avant imputation des Déficits antérieurs - Bénéfice",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["310"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["324"].accounts,
    ],
  },
  354: {
    name: "Résultat fiscal avant imputation des Déficits antérieurs - Déficit",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["310"].accounts,
      ...accountingResultLinesMetadata["deductible-depreciation"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["324"].accounts,
    ],
  },
  360: {
    name: "Déficit antérieurs reportables, dont imputés sur le résultat",
    accounts: [],
  },
};
const taxDeclaration2033LinesMetadataISLayer3: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110_112_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L142
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "110-112-Net": {
    name: "Total général (I + II) - Net",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer2["110"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer2["112"].accounts,
    ],
  },
  "142": {
    name: "Capitaux propres - Total I",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["120"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["134"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer2["136"].accounts,
    ],
  },
};
const taxDeclaration2033LinesMetadataISLayer4: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L180
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "180": {
    name: "Total général (I + II + III)",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer3["142"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["154"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["176"].accounts,
    ],
  },
};
const taxDeclaration2033LinesMetadataIS: {
  [key in Exclude<
    TaxDeclaration2033LineNumber,
    TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L243 | TaxDeclaration2033LineNumberExtraDeduction
  >]: TaxDeclaration2033LinesMetadata;
} = {
  ...taxDeclaration2033LinesMetadataISLayer0,
  ...taxDeclaration2033LinesMetadataISLayer1,
  ...taxDeclaration2033LinesMetadataISLayer2,
  ...taxDeclaration2033LinesMetadataISLayer3,
  ...taxDeclaration2033LinesMetadataISLayer4,
  270: {
    name: "Résultat d'exploitation",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["232"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer0["264"].accounts,
    ],
  },
  312: {
    name: "Résultat comptable - Bénéfice",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataISLayer1["310"].accounts],
  },
  314: {
    name: "Résultat comptable - Déficit",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataISLayer1["310"].accounts],
  },
  318: {
    name: "Amortissements excédentaires et autres amortissements non déductibles",
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer0["254"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["310"].accounts,
    ],
  },
  356: {
    name: "Déficit de l'exercice reporté en arrière",
    accounts: [],
  },
  "360-Total": {
    name: "Déficit antérieurs reportables",
    accounts: [],
  },
  370: {
    name: "Résultat fiscal après imputation des déficit - Bénéfice",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataISLayer2["352"].accounts],
  },
  372: {
    name: "Résultat fiscal après imputation des déficit - Déficit",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer2["354"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer2["360"].accounts,
    ],
  },
  901: {
    name: "Total d'associés ou actionnaires personnes morals de l'entreprise",
    isTotal: true,
    accounts: [],
  },
  902: {
    name: "Total de parts ou d'actions correspondantes (ligne 901)",
    isTotal: true,
    accounts: [],
  },
  903: {
    name: "Total d'associés ou actionnaires personnes physiques de l'entreprise",
    isTotal: true,
    accounts: [],
  },
  904: {
    name: "Total de parts ou d'actions correspondantes (ligne 903)",
    isTotal: true,
    accounts: [],
  },
  870: {
    name: "Total des déficits restant à reporter",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataISLayer1["860"].accounts,
      ...taxDeclaration2033LinesMetadataISLayer1["984"].accounts,
    ],
  },
};

/**
 * LMNP
 */
const taxDeclaration2033LinesMetadataLMNPLayer0: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L016
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L030
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L040
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L068
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L072
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L080
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L084
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L120
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L134
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L154
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L156
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L166
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L169
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L172
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L218
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L226
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L230
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L232
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L242
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L243
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L244
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L254
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "014": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Brut",
    accounts: [LedgerAccountEnum.N201100],
  },
  "016": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Amortissements Provisions",
    accounts: [LedgerAccountEnum.N280000],
  },
  "028": {
    name: "Actif immobilisé - Immobilisations corporelles - Brut",
    accounts: [
      LedgerAccountEnum.N211100,
      LedgerAccountEnum.N213100,
      LedgerAccountEnum.N213500,
      LedgerAccountEnum.N215700,
      LedgerAccountEnum.N218400,
    ],
  },
  "030": {
    name: "Actif immobilisé - Immobilisations corporelles - Amortissements Provisions",
    accounts: [
      LedgerAccountEnum.N280100,
      LedgerAccountEnum.N281310,
      LedgerAccountEnum.N281350,
      LedgerAccountEnum.N281840,
    ],
  },
  "040": {
    name: "Actif immobilisé - Immobilisations financières - Brut",
    accounts: [LedgerAccountEnum.N272000],
  },
  "068": {
    name: "Actif circulant - Clients et comptes rattachés - Brut",
    accounts: [LedgerAccountEnum.N411000],
  },
  "072": {
    name: "Actif circulant - Autres - Brut",
    accounts: [LedgerAccountEnum.N467000, LedgerAccountEnum.N445670],
  },
  "080": {
    name: "Actif circulant - Valeurs mobilières de placement - Brut",
    accounts: [LedgerAccountEnum.N503000],
  },
  "084": {
    name: "Actif circulant - Disponibilités - Brut",
    accounts: [LedgerAccountEnum.N512000],
  },
  "120": {
    name: "Capitaux propres - Capital social ou individuel",
    accounts: [LedgerAccountEnum.N101100, LedgerAccountEnum.N108000],
  },
  "134": {
    name: "Capitaux propres - Report à nouveau",
    accounts: [LedgerAccountEnum.N110001],
  },
  "154": {
    name: "Provisions pour risques et charges (Total II)",
    isTotal: true,
    accounts: [],
  },
  "156": {
    name: "Dettes - Emprunts et dettes assimilées",
    accounts: [
      LedgerAccountEnum.N164000,
      LedgerAccountEnum.N164100,
      LedgerAccountEnum.N164200,
      LedgerAccountEnum.N165000,
    ],
  },
  "166": {
    name: "Dettes - Fournisseurs et comptes rattachés",
    accounts: [LedgerAccountEnum.N401000],
  },
  "169": {
    name: "Montant de la TVA",
    accounts: [LedgerAccountEnum.N445800],
  },
  "172": {
    name: "Dettes fiscales et sociales (dont montant de la TVA indiqué dans la case 169)",
    accounts: [LedgerAccountEnum.N444000, LedgerAccountEnum.N445800, LedgerAccountEnum.N447000],
  },
  "218": {
    name: "Production vendue - Services",
    accounts: [LedgerAccountEnum.N706000],
  },
  "226": {
    name: "Subventions d'exploitations reçues",
    accounts: [LedgerAccountEnum.N708300],
  },
  "230": {
    name: "Autres produits",
    accounts: [
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N708399,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N781100,
    ],
  },
  "232": {
    name: "Total des produits d'exploitation hors TVA",
    isTotal: true,
    accounts: [
      LedgerAccountEnum.N706000,
      LedgerAccountEnum.N708300,
      LedgerAccountEnum.N791400,
      LedgerAccountEnum.N708399,
      LedgerAccountEnum.N758000,
      LedgerAccountEnum.N781100,
    ],
  },
  "242": {
    name: "Autres charges externes",
    accounts: [
      LedgerAccountEnum.N616600,
      LedgerAccountEnum.N622710,
      LedgerAccountEnum.N627100,
      LedgerAccountEnum.N606110,
      LedgerAccountEnum.N606300,
      LedgerAccountEnum.N606400,
      LedgerAccountEnum.N614020,
      LedgerAccountEnum.N614010,
      LedgerAccountEnum.N622610,
      LedgerAccountEnum.N615200,
      LedgerAccountEnum.N615210,
      LedgerAccountEnum.N661100,
      LedgerAccountEnum.N616100,
      LedgerAccountEnum.N671400,
      LedgerAccountEnum.N671000,
      LedgerAccountEnum.N622700,
      LedgerAccountEnum.N623700,
      LedgerAccountEnum.N627200,
      LedgerAccountEnum.N625100,
      LedgerAccountEnum.N626000,
    ],
  },
  "243": {
    name: "Impôts, taxes et versements assimilés - Dont CFE et CVAE",
    accounts: [LedgerAccountEnum.N635110],
  },
  "244": {
    name: "Impôts, taxes et versements assimilés",
    accounts: [
      LedgerAccountEnum.N622000,
      LedgerAccountEnum.N635121,
      LedgerAccountEnum.N635125,
      LedgerAccountEnum.N635110,
    ],
  },
  "254": {
    name: "Dotations aux amortissements",
    accounts: [LedgerAccountEnum.N681120, LedgerAccountEnum.N681110],
  },
};
const taxDeclaration2033LinesMetadataLMNPLayer1: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L264
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L280
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L290
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L294
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L300
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L306
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L324
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L410
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L412
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L414
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L416
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L420
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L422
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L424
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L426
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L430
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L432
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L434
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L436
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L440
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L442
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L444
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L446
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L450
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L452
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L454
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L456
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L500
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L502
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L504
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L506
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L510
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L512
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L514
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L516
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L520
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L522
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L524
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L526
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L530
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L532
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L534
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L536
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L540
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L542
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L544
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L546
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L982
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "264": {
    name: "Total des charges d'exploitation",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["242"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["244"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["254"].accounts,
    ],
  },
  "280": { name: "Produits financiers", accounts: [LedgerAccountEnum.N761000] },
  "290": { name: "Produits exceptionnels", accounts: [LedgerAccountEnum.N775000] },
  "294": { name: "Charges financières", accounts: [LedgerAccountEnum.N661110, LedgerAccountEnum.N661600] },
  "300": { name: "Charges exceptionnelles", accounts: [LedgerAccountEnum.N675000] },
  "306": { name: "Impôt sur les bénéfices", accounts: [LedgerAccountEnum.N695000] },
  "324": { name: "Impôts et taxes non déductibles", accounts: [LedgerAccountEnum.N671000, LedgerAccountEnum.N695000] },
  "410": {
    name: "Immobilisations incorporelles - Autres - Valeur en début d'exercice",
    accounts: [LedgerAccountEnum.N201100],
  },
  "412": {
    name: "Immobilisations incorporelles - Autres - Augmentations",
    accounts: [LedgerAccountEnum.N201100],
  },
  "414": {
    name: "Immobilisations incorporelles - Autres - Diminutions",
    accounts: [LedgerAccountEnum.N201100],
  },
  "416": {
    name: "Immobilisations incorporelles - Autres - Valeur en fin d'exercice",
    accounts: [LedgerAccountEnum.N201100],
  },
  "420": { name: "Terrains - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N211100] },
  "422": { name: "Terrains - Augmentations", accounts: [LedgerAccountEnum.N211100] },
  "424": { name: "Terrains - Diminutions", accounts: [LedgerAccountEnum.N211100] },
  "426": { name: "Terrains - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N211100] },
  "430": { name: "Constructions - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N213100] },
  "432": { name: "Constructions - Augmentations", accounts: [LedgerAccountEnum.N213100] },
  "434": { name: "Constructions - Diminutions", accounts: [LedgerAccountEnum.N213100] },
  "436": { name: "Constructions - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N213100] },
  "440": { name: "Installation techniques - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N213500] },
  "442": { name: "Installation techniques - Augmentations", accounts: [LedgerAccountEnum.N213500] },
  "444": { name: "Installation techniques - Diminutions", accounts: [LedgerAccountEnum.N213500] },
  "446": { name: "Installation techniques - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N213500] },
  "450": { name: "Installation générales - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N218400] },
  "452": { name: "Installation générales - Augmentations", accounts: [LedgerAccountEnum.N218400] },
  "454": { name: "Installation générales - Diminutions", accounts: [LedgerAccountEnum.N218400] },
  "456": { name: "Installation générales - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N218400] },
  "500": {
    name: "Autres immobilisations incorporelles - Valeur en début d'exercice",
    accounts: [LedgerAccountEnum.N280000],
  },
  "502": {
    name: "Autres immobilisations incorporelles - Augmentations",
    accounts: [LedgerAccountEnum.N280000],
  },
  "504": {
    name: "Autres immobilisations incorporelles - Diminutions",
    accounts: [LedgerAccountEnum.N280000],
  },
  "506": {
    name: "Autres immobilisations incorporelles - Valeur en fin d'exercice",
    accounts: [LedgerAccountEnum.N280000],
  },
  "510": { name: "Terrains - Valeur en début d'exercice", accounts: [] },
  "512": { name: "Terrains - Augmentations", accounts: [] },
  "514": { name: "Terrains - Diminutions", accounts: [] },
  "516": { name: "Terrains - Valeur en fin d'exercice", accounts: [] },
  "520": { name: "Constructions - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281310] },
  "522": { name: "Constructions - Augmentations", accounts: [LedgerAccountEnum.N281310] },
  "524": { name: "Constructions - Diminutions", accounts: [LedgerAccountEnum.N281310] },
  "526": { name: "Constructions - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281310] },
  "530": { name: "Installation techniques - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281350] },
  "532": { name: "Installation techniques - Augmentations", accounts: [LedgerAccountEnum.N281350] },
  "534": { name: "Installation techniques - Diminutions", accounts: [LedgerAccountEnum.N281350] },
  "536": { name: "Installation techniques - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281350] },
  "540": { name: "Installation générales - Valeur en début d'exercice", accounts: [LedgerAccountEnum.N281840] },
  "542": { name: "Installation générales - Augmentations", accounts: [LedgerAccountEnum.N281840] },
  "544": { name: "Installation générales - Diminutions", accounts: [LedgerAccountEnum.N281840] },
  "546": { name: "Installation générales - Valeur en fin d'exercice", accounts: [LedgerAccountEnum.N281840] },
  "982": {
    name: "Déficits restant à reporter au titre de l'exercice précédent",
    accounts: [LedgerAccountEnum.N110001],
  },
};
const taxDeclaration2033LinesMetadataLMNPLayer2: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L014_016_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L028_030_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L048
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L098
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L176
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L490
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L492
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L494
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L496
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L570
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L572
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L574
    | TaxDeclaration2033LineNumberFixedAssetsDepreciationCapitalGainsCapitalLosses.L576
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L983
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L984
    | TaxDeclaration2033LineNumberStatementOfProvisionsExceptionalDepreciationDeficit.L860
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "014-016-Net": {
    name: "Actif immobilisé - Immobilisations incorporelles - Autres - Net",
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["014"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["016"].accounts,
    ],
  },
  "028-030-Net": {
    name: "Actif immobilisé - Immobilisations corporelles - Net",
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["028"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["030"].accounts,
    ],
  },
  "044": {
    name: "Actif immobilisé - Total I - Brut",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["014"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["028"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["040"].accounts,
    ],
  },
  "048": {
    name: "Actif immobilisé - Total I - Amortissements Provisions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["016"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["030"].accounts,
    ],
  },
  "096": {
    name: "Actif circulant - Total II - Brut",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["068"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["072"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["080"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["084"].accounts,
    ],
  },
  "098": {
    name: "Actif circulant - Total II - Amortissements Provisions",
    isTotal: true,
    accounts: [],
  },
  "176": {
    name: "Total III",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["156"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["166"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["172"].accounts,
    ],
  },
  "310": {
    name: "Benefice ou perte de l'exercice",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["232"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["280"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["290"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["264"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["294"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["300"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["306"].accounts,
    ],
  },
  "490": {
    name: "Total - Valeur en début d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["410"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["420"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["430"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["440"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["450"].accounts,
    ],
  },
  "492": {
    name: "Total - Augmentations",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["412"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["422"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["432"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["442"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["452"].accounts,
    ],
  },
  "494": {
    name: "Total - Diminutions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["414"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["424"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["434"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["444"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["454"].accounts,
    ],
  },
  "496": {
    name: "Total - Valeur en fin d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["416"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["426"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["436"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["446"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["456"].accounts,
    ],
  },
  "570": {
    name: "Total - Valeur en début d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["500"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["510"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["520"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["530"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["540"].accounts,
    ],
  },
  "572": {
    name: "Total - Augmentations",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["502"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["512"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["522"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["532"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["542"].accounts,
    ],
  },
  "574": {
    name: "Total - Diminutions",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["504"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["514"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["524"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["534"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["544"].accounts,
    ],
  },
  "576": {
    name: "Total - Valeur en fin d'exercice",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer1["506"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["516"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["526"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["536"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["546"].accounts,
    ],
  },
  "983": { name: "Déficits imputés", accounts: [] },
  "984": { name: "Déficits reportables", accounts: [] },
  "860": { name: "Déficits de l’exercice", accounts: [] },
};
const taxDeclaration2033LinesMetadataLMNPLayer3: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L044_048_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L096_098_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L112
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L136
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354
    | TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L360
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "044-048-Net": {
    name: "Actif immobilisé - Total I - Net",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["044"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["048"].accounts,
    ],
  },
  "096-098-Net": {
    name: "Actif circulant - Total II - Net",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["096"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["098"].accounts,
    ],
  },
  "110": {
    name: "Total général (I + II) - Brut",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["096"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["044"].accounts,
    ],
  },
  "112": {
    name: "Total général (I + II) - Amortissements Provisions",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataLMNPLayer2["048"].accounts],
  },
  "136": {
    name: "Capitaux propres - Résultat de l'exercice",
    accounts: [...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts],
  },
  352: {
    name: "Résultat fiscal avant imputation des Déficits antérieurs - Bénéfice",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["324"].accounts,
    ],
  },
  354: {
    name: "Résultat fiscal avant imputation des Déficits antérieurs - Déficit",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts,
      ...accountingResultLinesMetadata["deductible-depreciation"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["324"].accounts,
    ],
  },
  360: {
    name: "Déficit antérieurs reportables, dont imputés sur le résultat",
    accounts: [],
  },
};
const taxDeclaration2033LinesMetadataLMNPLayer4: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L110_112_NET
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L142
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "110-112-Net": {
    name: "Total général (I + II) - Net",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer3["110"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer3["112"].accounts,
    ],
  },
  "142": {
    name: "Capitaux propres - Total I",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["120"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["134"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer3["136"].accounts,
    ],
  },
};
const taxDeclaration2033LinesMetadataLMNPLayer5: {
  [key in Extract<
    TaxDeclaration2033LineNumber,
    TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L180
  >]: TaxDeclaration2033LinesMetadata;
} = {
  "180": {
    name: "Total général (I + II + III)",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer4["142"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer0["154"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["176"].accounts,
    ],
  },
};
const taxDeclaration2033LinesMetadataLMNP: {
  [key in Exclude<
    TaxDeclaration2033LineNumber,
    | TaxDeclaration2033LineNumberCompositionOfShareCapital
    | TaxDeclaration2033LineNumberSimplifiedBalanceSheet.L173
    | TaxDeclaration2033LineNumberExtraDeduction
  >]: TaxDeclaration2033LinesMetadata;
} = {
  ...taxDeclaration2033LinesMetadataLMNPLayer0,
  ...taxDeclaration2033LinesMetadataLMNPLayer1,
  ...taxDeclaration2033LinesMetadataLMNPLayer2,
  ...taxDeclaration2033LinesMetadataLMNPLayer3,
  ...taxDeclaration2033LinesMetadataLMNPLayer4,
  ...taxDeclaration2033LinesMetadataLMNPLayer5,
  270: {
    name: "Résultat d'exploitation",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["232"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer1["264"].accounts,
    ],
  },
  312: {
    name: "Résultat comptable - Bénéfice",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts],
  },
  314: {
    name: "Résultat comptable - Déficit",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts],
  },
  318: {
    name: "Amortissements excédentaires et autres amortissements non déductibles",
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer0["254"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["310"].accounts,
    ],
  },
  356: {
    name: "Déficit de l'exercice reporté en arrière",
    accounts: [],
  },
  "360-Total": {
    name: "Déficit antérieurs reportables",
    accounts: [],
  },
  370: {
    name: "Résultat fiscal après imputation des déficit - Bénéfice",
    isResult: true,
    accounts: [...taxDeclaration2033LinesMetadataLMNPLayer3["352"].accounts],
  },
  372: {
    name: "Résultat fiscal après imputation des déficit - Déficit",
    isResult: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer3["354"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer3["360"].accounts,
    ],
  },
  870: {
    name: "Total des déficits restant à reporter",
    isTotal: true,
    accounts: [
      ...taxDeclaration2033LinesMetadataLMNPLayer2["860"].accounts,
      ...taxDeclaration2033LinesMetadataLMNPLayer2["984"].accounts,
    ],
  },
};

export const taxDeclaration2033LinesMetadata = {
  is_2065: taxDeclaration2033LinesMetadataIS,
  lmnp_2031: taxDeclaration2033LinesMetadataLMNP,
};
