















































































































































import { defineComponent } from "@vue/composition-api";
import { extension } from "mime-types";
import {
  accountingPeriodsStore,
  documentsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
} from "@/store";
import { AccountingPeriod, Document } from "@edmp/api";
import { VConfirmDialog } from "@/models";

import Confirm from "@/components/core/modals/Confirm.vue";

export default defineComponent({
  name: "DocumentsPanel",
  components: {
    Confirm,
  },
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    opened: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const getAccountingPeriodYear = (accountingPeriod: AccountingPeriod) => {
      const accountingPeriods = accountingPeriodsStore.accountingPeriods;
      let accPeriod: AccountingPeriod | undefined;
      if (accountingPeriod && accountingPeriods)
        accPeriod = accountingPeriods.find(
          (elt) => elt.id === accountingPeriod.id
        );
      return accPeriod ? new Date(accPeriod.startAt).getFullYear() : "";
    };

    const getRealEstateAsset = (id: string) => {
      const realEstate = realEstateAssetsStore.realEstateAssets.find(
        (elt) => elt.id === id
      );
      return realEstate ? realEstate.name : "";
    };

    const getRentalAgreement = (id: string) => {
      const rentalAgreement = rentalAgreementsStore.rentalAgreements.find(
        (elt) => elt.id === id
      );
      return rentalAgreement ? rentalAgreement.name : "";
    };

    async function deleteDocument(id: string, name: string) {
      const openConfirm: boolean = await (
        context.refs.confirmDialog as VConfirmDialog
      ).open(`Êtes-vous sûr de vouloir supprimer le document ${name} ?`);

      if (openConfirm) {
        await documentsStore.deleteDocument(id);
        context.emit("documentDeleted");
      }
    }

    async function deleteDocumentRealEstateAsset(id: string, name: string) {
      const openConfirm: boolean = await (
        context.refs.confirmDialog as VConfirmDialog
      ).open(`Êtes-vous sûr de vouloir supprimer le document ${name} ?`);

      const realEstateAsset = await realEstateAssetsStore.getRealEstateAsset(
        id
      );

      if (realEstateAsset) {
        if (openConfirm) {
          await realEstateAssetsStore.deleteDocument({
            id: realEstateAsset.id,
            asbestos: realEstateAsset.asbestos,
            dpe: realEstateAsset.dpe,
          });
          context.emit("documentDeleted");
        }
      }
    }
    function showDocument(item: Document) {
      window.open(item.href, "_blank");
    }
    return {
      getAccountingPeriodYear,
      getRealEstateAsset,
      getRentalAgreement,
      deleteDocument,
      extension,
      showDocument,
      deleteDocumentRealEstateAsset,
    };
  },
});
