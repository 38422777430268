import {
  AccountingCompute,
  AccountingComputeCategories,
  AccountingComputeMonth,
  AccountingPeriod,
  BalanceCategories,
  Direction,
  isPlanBankAccount,
  JournalEntryLine,
  JournalEntryReference,
  LedgerAccountEnum,
  ReferenceCounter,
  Suggestion,
  Transaction,
  TransactionImportType,
  TransactionType,
} from "..";

import Decimal from "decimal.js-light";
import { Dictionary } from "lodash";
import {
  compareByKey,
  getAccountWithReferenceCounterFromGlobalAccount,
  getGlobalAccountFromAccountWithReferenceCounter,
} from ".";
import { getMoment } from "./moment";
import { getMonth } from "./month";

function completeCategory(
  categories: AccountingComputeCategories[],
  cName: string,
  cDirection: Direction | undefined,
  tm: string,
  cValue: Decimal
) {
  const c = categories.find((c) => c.name === cName && c.direction === cDirection);
  if (c && c.monthly) {
    c.monthly[tm] = new Decimal(c.monthly[tm] ?? 0).add(cValue).toNumber();
  } else {
    const newC = {
      name: cName,
      direction: cDirection,
      monthly: {} as { [key: string]: number },
    };
    newC.monthly[tm] = cValue.toNumber();
    categories.push(newC);
  }
}

export const getBalanceCategories = (
  lines: {
    type: TransactionType;
    account: string;
    date: string;
    amount: number;
    direction: Direction;
    transactionId?: string;
    refs?: JournalEntryReference[];
  }[],
  categories: Suggestion[],
  referenceCounters: ReferenceCounter[],
  options?: { includeBankAccountCategory?: boolean }
): BalanceCategories => {
  const calculateBalanceCategory = <
    T extends
      | BalanceCategories
      | BalanceCategories["categories"][number]
      | BalanceCategories["categories"][number]["yearly"][number]
      | BalanceCategories["categories"][number]["yearly"][number]["monthly"][number]
      | BalanceCategories["categories"][number]["yearly"][number]["monthly"][number]["daily"][number]
  >(
    balanceCategoryAllOrYearOrMonthOrDay: T,
    line: {
      type: TransactionType;
      account: string;
      date: string;
      amount: number;
      direction: Direction;
      transactionId?: string;
      refs?: JournalEntryReference[];
    },
    balanceCategoryAmounts: {
      creditAmount: Decimal;
      debitAmount: Decimal;
      balanceAmount: Decimal;
      balanceDirection: Direction;
    }
  ): T => {
    const yearOrMonthOrDayBalance = new Decimal(balanceCategoryAllOrYearOrMonthOrDay.balance);
    let balance = 0;
    let balanceDirection = balanceCategoryAmounts.balanceDirection;
    if (balanceCategoryAllOrYearOrMonthOrDay.balanceDirection === balanceCategoryAmounts.balanceDirection) {
      balance = Number(
        balanceCategoryAmounts.balanceAmount.plus(balanceCategoryAllOrYearOrMonthOrDay.balance).abs().toFixed(2)
      );
    }
    if (
      balanceCategoryAllOrYearOrMonthOrDay.balanceDirection === Direction.credit &&
      balanceCategoryAmounts.balanceDirection === Direction.debit
    ) {
      const newBalance = yearOrMonthOrDayBalance.sub(balanceCategoryAmounts.balanceAmount);
      balanceDirection = getDirectionFromAmount(newBalance) || balanceCategoryAmounts.balanceDirection;
      balance = Number(newBalance.abs().toFixed(2));
    }
    if (
      balanceCategoryAllOrYearOrMonthOrDay.balanceDirection === Direction.debit &&
      balanceCategoryAmounts.balanceDirection === Direction.credit
    ) {
      const newBalance = balanceCategoryAmounts.balanceAmount.sub(yearOrMonthOrDayBalance);
      balanceDirection = getDirectionFromAmount(newBalance) || balanceCategoryAmounts.balanceDirection;
      balance = Number(newBalance.abs().toFixed(2));
    }

    const resultBalanceCategoryAllOrYearOrMonthOrDay = {
      ...balanceCategoryAllOrYearOrMonthOrDay,
      types: [...balanceCategoryAllOrYearOrMonthOrDay.types, line.type],
      credit: Number(
        balanceCategoryAmounts.creditAmount.plus(balanceCategoryAllOrYearOrMonthOrDay.credit).abs().toFixed(2)
      ),
      debit: Number(
        balanceCategoryAmounts.debitAmount.plus(balanceCategoryAllOrYearOrMonthOrDay.debit).abs().toFixed(2)
      ),
      balance,
      balanceDirection,
      transactionIds: line.transactionId
        ? [...balanceCategoryAllOrYearOrMonthOrDay.transactionIds, line.transactionId]
        : [...balanceCategoryAllOrYearOrMonthOrDay.transactionIds],
    };

    if (
      (
        resultBalanceCategoryAllOrYearOrMonthOrDay as unknown as BalanceCategories["categories"][number]["yearly"][number]["monthly"][number]["daily"][number]
      ).categorizations
    ) {
      (
        resultBalanceCategoryAllOrYearOrMonthOrDay as unknown as BalanceCategories["categories"][number]["yearly"][number]["monthly"][number]["daily"][number]
      ).categorizations.push(line);
    }

    return resultBalanceCategoryAllOrYearOrMonthOrDay;
  };

  const processBalanceCategory = (
    balanceCategories: BalanceCategories,
    account: string,
    line: {
      type: TransactionType;
      account: string;
      date: string;
      amount: number;
      direction: Direction;
      transactionId?: string;
      refs?: JournalEntryReference[];
    },
    balanceCategoryAmounts: {
      creditAmount: Decimal;
      debitAmount: Decimal;
      balanceAmount: Decimal;
      balanceDirection: Direction;
    },
    reference?: ReferenceCounter
  ) => {
    const { creditAmount, debitAmount, balanceAmount, balanceDirection } = balanceCategoryAmounts;
    // Define data
    const yearDate = getMoment(line.date).format("YYYY");
    const monthDate = getMoment(line.date).format("MM");
    const dayDate = getMoment(line.date).format("DD");

    const balanceCategoryDayCreate: BalanceCategories["categories"][number]["yearly"][number]["monthly"][number]["daily"][number] =
      {
        dayDate,
        types: [line.type],
        credit: Number(creditAmount.toNumber().toFixed(2)),
        debit: Number(debitAmount.toNumber().toFixed(2)),
        balance: Number(balanceAmount.toNumber().toFixed(2)),
        balanceDirection,
        transactionIds: line.transactionId ? [line.transactionId] : [],
        categorizations: [line],
      };
    const balanceCategoryMonthCreate: BalanceCategories["categories"][number]["yearly"][number]["monthly"][number] = {
      monthDate,
      types: [line.type],
      credit: Number(creditAmount.toNumber().toFixed(2)),
      debit: Number(debitAmount.toNumber().toFixed(2)),
      balance: Number(balanceAmount.toNumber().toFixed(2)),
      balanceDirection,
      transactionIds: line.transactionId ? [line.transactionId] : [],
      daily: [balanceCategoryDayCreate],
    };
    const balanceCategoryYearCreate: BalanceCategories["categories"][number]["yearly"][number] = {
      yearDate,
      types: [line.type],
      credit: Number(creditAmount.toNumber().toFixed(2)),
      debit: Number(debitAmount.toNumber().toFixed(2)),
      balance: Number(balanceAmount.toNumber().toFixed(2)),
      balanceDirection,
      transactionIds: line.transactionId ? [line.transactionId] : [],
      monthly: [balanceCategoryMonthCreate],
    };
    const balanceCategoryCreate: BalanceCategories["categories"][number] = {
      account,
      types: [line.type],
      credit: Number(creditAmount.toNumber().toFixed(2)),
      debit: Number(debitAmount.toNumber().toFixed(2)),
      balance: Number(balanceAmount.toNumber().toFixed(2)),
      balanceDirection,
      transactionIds: line.transactionId ? [line.transactionId] : [],
      yearly: [balanceCategoryYearCreate],
    };
    if (reference) {
      balanceCategoryCreate.reference = reference;
    }

    // Process balance category
    const balanceCategoryIndex = balanceCategories.categories.findIndex(
      (balanceCategory) => balanceCategory.account === account
    );
    if (balanceCategoryIndex !== -1) {
      // Process balance category year
      const balanceCategoryYearIndex = balanceCategories.categories[balanceCategoryIndex].yearly.findIndex(
        (balanceCategory) => balanceCategory.yearDate === yearDate
      );
      if (balanceCategoryYearIndex !== -1) {
        // Process balance category month
        const balanceCategoryMonthIndex = balanceCategories.categories[balanceCategoryIndex].yearly[
          balanceCategoryYearIndex
        ].monthly.findIndex((balanceMonth) => balanceMonth.monthDate === monthDate);
        if (balanceCategoryMonthIndex !== -1) {
          // Process balance category day
          const balanceCategoryDayIndex = balanceCategories.categories[balanceCategoryIndex].yearly[
            balanceCategoryYearIndex
          ].monthly[balanceCategoryMonthIndex].daily.findIndex((balanceDay) => balanceDay.dayDate === dayDate);
          if (balanceCategoryDayIndex !== -1) {
            // Calculate balance category day
            balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
              balanceCategoryMonthIndex
            ].daily[balanceCategoryDayIndex] = calculateBalanceCategory(
              balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
                balanceCategoryMonthIndex
              ].daily[balanceCategoryDayIndex],
              line,
              { creditAmount, debitAmount, balanceAmount, balanceDirection }
            );
          } else {
            balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
              balanceCategoryMonthIndex
            ].daily.push(balanceCategoryDayCreate);
          }
          balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
            balanceCategoryMonthIndex
          ].daily = balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
            balanceCategoryMonthIndex
          ].daily.sort(compareByKey("dayDate", "asc"));

          // Calculate balance category month
          balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
            balanceCategoryMonthIndex
          ] = calculateBalanceCategory(
            balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly[
              balanceCategoryMonthIndex
            ],
            line,
            { creditAmount, debitAmount, balanceAmount, balanceDirection }
          );
        } else {
          balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly.push(
            balanceCategoryMonthCreate
          );
        }
        balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly =
          balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex].monthly.sort(
            compareByKey("monthDate", "asc")
          );

        // Calculate balance category year
        balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex] = calculateBalanceCategory(
          balanceCategories.categories[balanceCategoryIndex].yearly[balanceCategoryYearIndex],
          line,
          {
            creditAmount,
            debitAmount,
            balanceAmount,
            balanceDirection,
          }
        );
      } else {
        balanceCategories.categories[balanceCategoryIndex].yearly.push(balanceCategoryYearCreate);
      }
      balanceCategories.categories[balanceCategoryIndex].yearly = balanceCategories.categories[
        balanceCategoryIndex
      ].yearly.sort(compareByKey("yearDate", "asc"));

      // Calculate balance category
      balanceCategories.categories[balanceCategoryIndex] = calculateBalanceCategory(
        balanceCategories.categories[balanceCategoryIndex],
        line,
        {
          creditAmount,
          debitAmount,
          balanceAmount,
          balanceDirection,
        }
      );
    } else {
      balanceCategories.categories.push(balanceCategoryCreate);
    }

    if (!reference) {
      balanceCategories = calculateBalanceCategory(balanceCategories, line, {
        creditAmount,
        debitAmount,
        balanceAmount,
        balanceDirection,
      });
    }

    balanceCategories.categories = balanceCategories.categories.sort(compareByKey("account", "asc"));

    return balanceCategories;
  };

  let balanceCategories: BalanceCategories = {
    types: [],
    credit: 0,
    debit: 0,
    balance: 0,
    balanceDirection: Direction.credit,
    transactionIds: [],
    categories: [],
  };
  const Zero = new Decimal(0);

  for (const line of lines) {
    const category = categories.find((category) => category.number === line.account);
    if (!category) {
      continue;
    }
    const reference = referenceCounters.find(
      (referenceCounter) =>
        referenceCounter.type === category.referenceCounter &&
        !!line.refs?.find((ref) => ref.type === referenceCounter.type && ref.referredId === referenceCounter.referredId)
    );
    if (
      !options?.includeBankAccountCategory &&
      isPlanBankAccount(line.account)
      // reference
      //   ? isPlanBankAccount(getAccountWithReferenceCounterFromGlobalAccount(line.account, reference))
      //   : isPlanBankAccount(line.account)
    ) {
      continue;
    }

    // Calculate amount
    const balanceDirection = line.direction;
    let balanceAmount = Zero;
    let creditAmount = Zero;
    if (balanceDirection === Direction.credit) {
      creditAmount = creditAmount.plus(line.amount).abs();
      balanceAmount = creditAmount;
    }
    let debitAmount = Zero;
    if (balanceDirection === Direction.debit) {
      debitAmount = debitAmount.plus(line.amount).abs();
      balanceAmount = debitAmount;
    }

    // Process balance for global account
    balanceCategories = processBalanceCategory(balanceCategories, line.account, line, {
      creditAmount,
      debitAmount,
      balanceAmount,
      balanceDirection,
    });

    // Process balance for account with counter if exist
    if (reference) {
      const accountWithReferenceCounter = getAccountWithReferenceCounterFromGlobalAccount(line.account, reference);

      balanceCategories = processBalanceCategory(
        balanceCategories,
        accountWithReferenceCounter,
        line,
        {
          creditAmount,
          debitAmount,
          balanceAmount,
          balanceDirection,
        },
        reference
      );
    }
  }

  return balanceCategories;
};

export function getBalance(
  accountingPeriod: AccountingPeriod,
  transactions: Transaction[],
  filter?: {
    account?: JournalEntryLine["account"];
    realEstateAssetIds?: string[];
  }
): AccountingCompute {
  const Zero = new Decimal("0");
  let transactionsDebits = Zero;
  let transactionsCredits = Zero;
  let transactionsCurrency = "EUR";
  const monthly: Dictionary<AccountingComputeMonth> = {};
  const categories: AccountingComputeCategories[] = [];
  const endAt = getMoment(accountingPeriod.endAt).add(1, "days").format("YYYY-MM-DD");

  // List of transactions
  for (const transaction of transactions) {
    // We do not take into account Deleted Transaction or Expense Reports (Associated Account)
    if (transaction.deleted || transaction.type === TransactionImportType.EXPENSE_REPORT) {
      continue;
    }

    transactionsCurrency = transaction.value.currency;

    // Return Month
    const transactionMonth = getMonth(transaction.date.operation, endAt);
    const tm = transactionMonth.format("YYYY-MM");
    const m =
      monthly[tm] != null
        ? monthly[tm]
        : (monthly[tm] = {
            month: tm,
            startAt: transactionMonth.format("YYYY-MM-DD"),
            endAt: transactionMonth.add(1, "month").format("YYYY-MM-DD"),
            transactionsDebits: 0,
            transactionsCredits: 0,
            transactionsBalance: 0,
            transactionsCurrency: transactionsCurrency,
          });

    // Compute categories
    const lines = transaction?.operations?.journalEntry?.lines;
    if (lines) {
      const lineOperations = lines.filter((e: JournalEntryLine) => {
        let result = true;
        if (filter?.account && filter.account !== e.account) {
          result = false;
        } else if (isPlanBankAccount(e.account)) {
          result = false;
        }

        if (
          filter?.realEstateAssetIds &&
          filter?.realEstateAssetIds.length &&
          !e.refs?.filter((ref) => filter.realEstateAssetIds?.includes(ref.referredId)).length
        ) {
          result = false;
        }
        return result;
      });

      for (const lineOperation of lineOperations) {
        const cName = lineOperation?.accountName ?? "Non catégorisé";
        const cValue = new Decimal(lineOperation?.amount ?? Zero);
        const cDirection = lineOperation?.direction;
        completeCategory(categories, cName, cDirection, tm, cValue);
        // THEN, We compute total Credit and Debit with amount value of operation
        if (cDirection === Direction.credit) {
          transactionsCredits = transactionsCredits.add(cValue.toNumber());
          m.transactionsCredits = new Decimal(m.transactionsCredits).add(cValue).toNumber();
        } else if (cDirection === Direction.debit) {
          transactionsDebits = transactionsDebits.add(cValue.toNumber());
          m.transactionsDebits = new Decimal(m.transactionsDebits).add(cValue).toNumber();
        }

        // THEN, We compute Balance
        m.transactionsBalance = new Decimal(m.transactionsCredits).sub(m.transactionsDebits).toNumber();
      }
    } else {
      // Not yet categorized we take Transaction amount to compute Totals Debits/Credits and Balance
      const cValue = new Decimal(transaction.value.amount ?? Zero);
      const commission = new Decimal(transaction?.commission?.amount ?? Zero);
      const cName = "Reste à catégoriser";
      let cDirection;

      // A commission is always an expense.
      transactionsDebits = transactionsDebits.add(commission.abs());
      m.transactionsDebits = new Decimal(m.transactionsDebits).add(commission.abs()).toNumber();
      // If the transaction amount is negative, treat it as an expense.
      if (cValue.isneg()) {
        cDirection = Direction.debit;
        transactionsDebits = transactionsDebits.add(cValue.abs());
        m.transactionsDebits = new Decimal(m.transactionsDebits).add(cValue.abs()).toNumber();
      } else {
        // If it is positive, treat it as a gain.
        cDirection = Direction.credit;
        transactionsCredits = transactionsCredits.add(cValue.abs());
        m.transactionsCredits = new Decimal(m.transactionsCredits).add(cValue.abs()).toNumber();
      }
      m.transactionsBalance = new Decimal(m.transactionsCredits).sub(m.transactionsDebits).toNumber();

      completeCategory(categories, cName, cDirection, tm, cValue.abs());
    }
  }
  return {
    period: accountingPeriod.id,
    startAt: accountingPeriod.startAt,
    endAt: accountingPeriod.endAt,
    transactionsDebits: transactionsDebits.toNumber(),
    transactionsCredits: transactionsCredits.toNumber(),
    transactionsCurrency,
    transactionsBalance: transactionsCredits.sub(transactionsDebits).toNumber(),
    monthly,
    categories,
  };
}

export const getBalancePartners = (balanceCategories: BalanceCategories): BalanceCategories["categories"] => {
  return balanceCategories.categories.filter((category) => {
    const account = category.reference
      ? getGlobalAccountFromAccountWithReferenceCounter(category.account, category.reference)
      : category.account;
    return account === LedgerAccountEnum.N455000 || account === LedgerAccountEnum.N455010;
  });
};

export const getTotalBalancePartner = (balancePartners: BalanceCategories["categories"], partnerId: string): number => {
  const filteredBalancePartners = balancePartners.filter((category) => category.reference?.referredId === partnerId);

  const totalBalance = filteredBalancePartners.reduce((total, balancePartner) => {
    if (balancePartner.balanceDirection === Direction.credit) {
      return total + balancePartner.balance;
    } else {
      return total - balancePartner.balance;
    }
  }, 0);

  return totalBalance;
};

export const getDirectionFromAmount = (amount: Decimal) => {
  if (amount.isNegative()) {
    return Direction.debit;
  } else {
    return Direction.credit;
  }
};