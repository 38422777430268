import { render, staticRenderFns } from "./CustomLabelButton.vue?vue&type=template&id=7632fd8d&"
import script from "./CustomLabelButton.vue?vue&type=script&lang=ts&"
export * from "./CustomLabelButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports