import { OperationsService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/operations";

class OperationService {
  async create(operationCreate: OperationsService.CreateIn) {
    return axios
      .post<OperationsService.CreateOut>(`${api}`, operationCreate)
      .then((res) => res.data);
  }

  async listCategories(params: OperationsService.ListCategoriesIn) {
    return axios
      .get<OperationsService.ListCategoriesOut>(`${api}`, { params })
      .then((res) => res.data);
  }

  async update(operationUpdate: OperationsService.PatchIn) {
    return axios
      .patch<OperationsService.PatchOut>(
        `${api}/${operationUpdate.id}`,
        operationUpdate
      )
      .then((res) => res.data);
  }
}

// Export a singleton instance in the global namespace
export const operationsService = new OperationService();
