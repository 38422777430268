









































import { productsStore, subscriptionsStore } from "@/store";
import {
  defineComponent,
  ref,
  watch,
  computed,
  Ref,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";
import SubscriptionCancelFlow from "./SubscriptionCancelFlow.vue";

export default defineComponent({
  name: "SubscriptionCancel",
  components: { SubscriptionCancelFlow },
  props: {
    subscriptionCancelIsOpen: { type: Boolean, default: false },
    subscriptionId: {
      type: String,
      required: true,
    },
    persistent: { type: Boolean, default: true },
  },

  setup(props, context) {
    const stepSelected: Ref<number> = ref(1);
    const subscriptionModalIsOpen = ref(false);
    const subscription = computed(() =>
      cloneDeep(subscriptionsStore.getSubscription(props.subscriptionId))
    );
    const product = computed(() =>
      subscription.value?.productId
        ? productsStore.getProduct({ id: subscription.value?.productId })
        : undefined
    );

    const cancelSubscriptionClose = async () => {
      stepSelected.value = 1;
      await subscriptionsStore.fetchSubscriptions();
      context.emit("update:subscriptionCancelIsOpen", false);
    };

    const cancelSubscriptionBack = async () => {
      if (stepSelected.value == 2) {
        stepSelected.value = 1;
      }
    };

    watch(
      () => props.subscriptionCancelIsOpen,
      () => (subscriptionModalIsOpen.value = props.subscriptionCancelIsOpen),
      { deep: true }
    );

    return {
      stepSelected,
      product,
      subscriptionModalIsOpen,
      subscription,
      cancelSubscriptionClose,
      cancelSubscriptionBack,
    };
  },
});
