













































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
} from "@vue/composition-api";
import CategorizationStep1 from "./CategorizationStep1.vue";
import CategorizationStep2 from "./CategorizationStep2.vue";
import CategorizationDuplication from "./CategorizationDuplication.vue";
import {
  TransactionState,
  useTransaction,
} from "../transaction/transaction.usable";
import { useCategorization } from "./categorization.usable";

export default defineComponent({
  name: "Categorization",
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
    isOpenRentReceiptStep: { type: Number, required: true },
  },
  components: {
    CategorizationStep1,
    CategorizationStep2,
    CategorizationDuplication,
  },
  setup(props, context) {
    const transactionUse = computed(() =>
      useTransaction(props.transactionState, context)
    );
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );

    const rentReceiptStepOpened = computed({
      get: () => props.isOpenRentReceiptStep,
      set: (isOpen) => context.emit("update:isOpenRentReceiptStep", isOpen),
    });

    const summary: ComputedRef = computed(() => {
      return transactionUse.value.getSummary();
    });

    const closeCategorizationDetails = () => {
      categorizationUse.value.resetCategories();
      categorizationUse.value.isOpenCategorizationDetailStep.value = false;
      categorizationUse.value.isOpenCategorizationList.value = false;
      categorizationUse.value.isOpenCategorizationDuplicate.value = false;
    };

    return {
      summary,
      rentReceiptStepOpened,
      closeCategorizationDetails,
    };
  },
});
