import { TransactionsService } from "@edmp/api";
import axios from "axios";
import { bankAccountsService } from "./BankAccounts.service";

const api = "/api/v1/transactions";

class TransactionsService {
  async create(transactionCreate: TransactionsService.CreateIn) {
    return axios
      .post<TransactionsService.CreateOut>(`${api}`, transactionCreate)
      .then((res) => {
        return res.data;
      });
  }

  async import(transactionImport: TransactionsService.ImportIn) {
    return bankAccountsService.importTransactions(transactionImport);
    // return axios
    //   .post<TransactionsService.ImportOut>(`${api}`, transactionImport)
    //   .then((res) => {
    //     return res.data;
    //   });
  }

  async list(params: TransactionsService.ListIn) {
    return axios
      .get<TransactionsService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async listPeriod(params: TransactionsService.ListPeriodIn) {
    return axios
      .get<TransactionsService.ListPeriodOut>(`${api}/period`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: TransactionsService.GetIn) {
    return axios
      .get<TransactionsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(transactionUpdate: TransactionsService.UpdateIn) {
    return axios
      .put<TransactionsService.UpdateOut>(
        `${api}/${transactionUpdate.id}`,
        transactionUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async delete(params: TransactionsService.DeleteIn) {
    return axios
      .delete<TransactionsService.DeleteOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const transactionsService = new TransactionsService();
