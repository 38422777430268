import { FixedAssetType, IR2072NatureA, LedgerAccountEnum } from "..";
import Decimal from "decimal.js-light";

export const amortisationType = [
  "purchase-costs", // Frais d'achat (à immobiliser)
  "roof", // Toiture
  "small-work", // Installations générales techniques (électricité, eau, ascenseur, (petit oeuvre))
  "facade", // Façade/Étanchéité
  "elevators", // Ascenseurs
  "furniture", // Agencements intérieurs/ Meubles
  "structural-work", // Structure (gros œuvre)
] as const;
export type AmortisationType = typeof amortisationType[number];

/**
 * Amortisation
 *
 * Specify an Amortisation for a type and on a period
 */
export interface Amortisation {
  id: string;
  productId: string;
  accountingPeriodIdAllowedToEdit: string;
  type: AmortisationType; // Ce qui est amortie (Toiture, bien, ...) limité par la liste proposé dans la cerfa
  share: number; // Quote part
  value: number; // valeur
  startAt: string; // Date de mise en service de l'amortissement
  durationInYear: number; // Durée en année
  rate: number; // Taux
}

export const calculateAmortisation = <
  Type extends "default" | "purchaseCosts",
  T extends Pick<Amortisation, "share" | "value" | "durationInYear" | "rate">,
  KeyUpdated extends Type extends "default"
    ? keyof Pick<Amortisation, "share" | "value" | "durationInYear">
    : Type extends "purchaseCosts"
    ? keyof Pick<Amortisation, "value" | "durationInYear">
    : never
>(
  params: Type extends "default"
    ? {
        amortisationType: Type;
        amortisationValues: T;
        boughtPrice: number;
        keyUpdated: KeyUpdated;
      }
    : Type extends "purchaseCosts"
    ? {
        amortisationType: Type;
        amortisationValues: T;
        keyUpdated: KeyUpdated;
      }
    : never
): T => {
  const value = params.amortisationValues[params.keyUpdated];

  if (params.amortisationType === "default" && params.keyUpdated === "share") {
    params.amortisationValues.value = Number(new Decimal(params.boughtPrice).mul(value).dividedBy(100).toFixed(0));
    params.amortisationValues = calculateAmortisation(
      Object.assign(params, {
        keyUpdated: "value",
      })
    );
  }

  if (params.keyUpdated === "value" || params.keyUpdated === "durationInYear") {
    params.amortisationValues.rate = Number(
      new Decimal(params.amortisationValues.value)
        .dividedBy(params.amortisationValues.durationInYear)
        .dividedBy(100)
        .toFixed(0)
    );
  }

  return params.amortisationValues;
};

export const getAccountByAmortisationType = (type: Amortisation["type"]) => {
  if (type === "purchase-costs") {
    return LedgerAccountEnum.N201100;
  }
  if (type === "roof" || type === "structural-work" || type === "facade") {
    return LedgerAccountEnum.N213100;
  }
  if (type === "furniture") {
    return LedgerAccountEnum.N218400;
  }
  if (type === "small-work" || type === "elevators") {
    return LedgerAccountEnum.N213500;
  }
};
export const getAmortisationAccountByAmortisationType = (type: Amortisation["type"]) => {
  if (type === "purchase-costs") {
    return LedgerAccountEnum.N280000;
  }
  if (type === "roof" || type === "structural-work" || type === "facade") {
    return LedgerAccountEnum.N281310;
  }
  if (type === "furniture") {
    return LedgerAccountEnum.N281840;
  }
  if (type === "small-work" || type === "elevators") {
    return LedgerAccountEnum.N281350;
  }
};

export const getDefaultGroundShareByRealEstateAssetNature = (natureA: IR2072NatureA): number => {
  switch (natureA) {
    case IR2072NatureA.IMMEUBLE_URBAIN:
    case IR2072NatureA.IMMEUBLE_RURAL:
    case IR2072NatureA.IMMEUBLE_DE_RAPPORT:
      return 10;
    default:
      return 20;
  }
};

const amortisationTypeText = new Map<FixedAssetType, string>([
  ["purchase-costs", "Frais d'achat (à immobiliser)"],
  ["roof", "Toiture"],
  ["small-work", "Installation générale technique (élec, pompes chaleur, etc..)"],
  ["facade", "Façade/Étanchéité"],
  ["elevators", "Ascenseur"],
  ["furniture", "Agencements intérieurs/Meubles"],
  ["structural-work", "Structure (gros œuvre)"],
]);

export const getAmortisationTypeText = (fixedAssetType: FixedAssetType): string =>
  amortisationTypeText.get(fixedAssetType) ?? "Autre";
export const getAmortisationTypeTexts = (): {
  text: string;
  value: FixedAssetType;
}[] => [
  { text: getAmortisationTypeText("purchase-costs"), value: "purchase-costs" },
  { text: getAmortisationTypeText("roof"), value: "roof" },
  {
    text: getAmortisationTypeText("small-work"),
    value: "small-work",
  },
  { text: getAmortisationTypeText("facade"), value: "facade" },
  { text: getAmortisationTypeText("elevators"), value: "elevators" },
  { text: getAmortisationTypeText("furniture"), value: "furniture" },
  {
    text: getAmortisationTypeText("structural-work"),
    value: "structural-work",
  },
];

export const defaultAmortisations: {
  [key in Exclude<Amortisation["type"], "purchase-costs">]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >;
} = {
  roof: {
    type: "roof",
    share: 10,
    value: 0,
    durationInYear: 25,
    rate: 0,
  },
  "small-work": {
    type: "small-work",
    share: 15,
    value: 0,
    durationInYear: 15,
    rate: 0,
  },
  facade: {
    type: "facade",
    share: 10,
    value: 0,
    durationInYear: 20,
    rate: 0,
  },
  elevators: {
    type: "elevators",
    share: 5,
    value: 0,
    durationInYear: 15,
    rate: 0,
  },
  furniture: {
    type: "furniture",
    share: 15,
    value: 0,
    durationInYear: 5,
    rate: 0,
  },
  "structural-work": {
    type: "structural-work",
    share: 40,
    value: 0,
    durationInYear: 50,
    rate: 0,
  },
};
export const defaultAmortisationsPurchaseCosts: {
  [key in Extract<Amortisation["type"], "purchase-costs">]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >;
} = {
  "purchase-costs": {
    type: "purchase-costs",
    share: 100,
    value: 0,
    durationInYear: 5,
    rate: 0,
  },
};

export const defaultAmortisationsByRealEstateAssetNature: {
  [key in IR2072NatureA]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >[];
} = {
  U: [
    { ...defaultAmortisations.roof, share: 10 },
    { ...defaultAmortisations["small-work"], share: 15 },
    { ...defaultAmortisations.facade, share: 10 },
    { ...defaultAmortisations.elevators, share: 5 },
    { ...defaultAmortisations.furniture, share: 10 },
    { ...defaultAmortisations["structural-work"], share: 40 },
  ],
  R: [
    { ...defaultAmortisations.roof, share: 10 },
    { ...defaultAmortisations["small-work"], share: 15 },
    { ...defaultAmortisations.facade, share: 10 },
    { ...defaultAmortisations.elevators, share: 5 },
    { ...defaultAmortisations.furniture, share: 10 },
    { ...defaultAmortisations["structural-work"], share: 40 },
  ],
  IR: [
    { ...defaultAmortisations.roof, share: 10 },
    { ...defaultAmortisations["small-work"], share: 15 },
    { ...defaultAmortisations.facade, share: 10 },
    { ...defaultAmortisations.elevators, share: 5 },
    { ...defaultAmortisations.furniture, share: 10 },
    { ...defaultAmortisations["structural-work"], share: 40 },
  ],
  AP: [
    { ...defaultAmortisations["small-work"], share: 15 },
    { ...defaultAmortisations.facade, share: 10 },
    { ...defaultAmortisations.furniture, share: 15 },
    { ...defaultAmortisations["structural-work"], share: 40 },
  ],
  M: [
    { ...defaultAmortisations.roof, share: 10 },
    { ...defaultAmortisations["small-work"], share: 15 },
    { ...defaultAmortisations.facade, share: 10 },
    { ...defaultAmortisations["structural-work"], share: 45 },
  ],
  P: [{ ...defaultAmortisations.furniture, share: 80 }],
  AU: [],
};

export const defaultAmortisationsPurchaseCostsByRealEstateAssetNature: {
  [key in IR2072NatureA]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >[];
} = {
  U: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  R: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  IR: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  AP: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  M: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  P: [{ ...defaultAmortisationsPurchaseCosts["purchase-costs"] }],
  AU: [],
};

export const defaultAmortisationsFixedAsset: {
  [key in Exclude<Amortisation["type"], "purchase-costs">]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >;
} = {
  roof: {
    type: "roof",
    share: 100,
    value: 0,
    durationInYear: 25,
    rate: 0,
  },
  "small-work": {
    type: "small-work",
    share: 100,
    value: 0,
    durationInYear: 15,
    rate: 0,
  },
  facade: {
    type: "facade",
    share: 100,
    value: 0,
    durationInYear: 20,
    rate: 0,
  },
  elevators: {
    type: "elevators",
    share: 100,
    value: 0,
    durationInYear: 15,
    rate: 0,
  },
  furniture: {
    type: "furniture",
    share: 100,
    value: 0,
    durationInYear: 5,
    rate: 0,
  },
  "structural-work": {
    type: "structural-work",
    share: 100,
    value: 0,
    durationInYear: 50,
    rate: 0,
  },
};

export const defaultAmortisationsByFixedAssetType: {
  [key in Exclude<FixedAssetType, "purchase-costs">]: Omit<
    Amortisation,
    "id" | "startAt" | "productId" | "accountingPeriodIdAllowedToEdit" | "assetType" | "accountingLineNumber"
  >[];
} = {
  roof: [defaultAmortisationsFixedAsset.roof],
  "small-work": [defaultAmortisationsFixedAsset["small-work"]],
  facade: [defaultAmortisationsFixedAsset.facade],
  elevators: [defaultAmortisationsFixedAsset.elevators],
  "structural-work": [defaultAmortisationsFixedAsset["structural-work"]],
  furniture: [defaultAmortisationsFixedAsset.furniture],
};
