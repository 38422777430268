var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"id":"realEstateAsset-list"}},_vm._l((_vm.realEstateAssetList),function(realEstateAsset){return _c('v-col',{key:realEstateAsset.id,attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
        ? '12'
        : _vm.$vuetify.breakpoint.smOnly
        ? '4'
        : _vm.$vuetify.breakpoint.mdOnly
        ? '6'
        : _vm.$vuetify.breakpoint.lgOnly
        ? '3'
        : '2'}},[_c('v-card',{staticClass:"container",attrs:{"loading":_vm.isLoading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),(
          !_vm.isNew &&
          realEstateAsset.name &&
          realEstateAsset.address &&
          realEstateAsset.rentalUnitCount !== undefined
        )?_c('v-card-text',{staticClass:"section"},[(realEstateAsset.documentId)?_c('div',{staticClass:"section-img-display"},[_c('img',{staticClass:"img-real-estate",attrs:{"src":_vm.getImage(realEstateAsset.documentId)}})]):_c('div',{staticClass:"section-img"},[_c('v-icon',[_vm._v("mdi-image-frame")])],1),_c('p',{attrs:{"id":"realEstateAsset-card-name"}},[_c('b',[_vm._v(_vm._s(realEstateAsset.name))])]),_c('p',{attrs:{"id":"realEstateAsset-card-adress"}},[_vm._v(" "+_vm._s(realEstateAsset.address.street)+","),_c('br'),_vm._v(" "+_vm._s(realEstateAsset.address.zip)+" "+_vm._s(realEstateAsset.address.city)+" ")]),_c('div',{staticClass:"section-action"},[_c('v-btn',{staticClass:"btn-link",attrs:{"id":"realEstateAsset-card-detail","text":"","color":""},on:{"click":function($event){return _vm.clickRealEstate(realEstateAsset.id)}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1)]):_vm._e()],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }