

























































































































import Notifications from "@/components/core/notifs/Notifications.vue";
import { useCrisp } from "@/composables/crisp.usable";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  coreStore,
  realEstateAssetsStore,
  taskActivityStore,
} from "@/store";
import { TaxRegime } from "@edmp/api";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from "@vue/composition-api";
import ItemsNavigation from "../navigation/ItemsNavigation.vue";
import Profile from "./Profile.vue";
import eventBus from "@/events/eventBus";

export default defineComponent({
  name: "Header",
  components: {
    Notifications,
    Profile,
    ItemsNavigation,
  },
  setup(props, context) {
    function openMenuMobile() {
      coreStore.openMobileMenu();
    }

    function closeMenuMobile() {
      coreStore.closeMobileMenu();
    }

    function goProductTour() {
      context.root.$router.push({ name: ROUTE_NAMES.Dashboard });
      context.root.$tours["ownily-tour"].start();
    }

    function goSubscriptions() {
      context.root.$router.push({ name: ROUTE_NAMES.Subscriptions });
    }

    const currentAccountingPeriodIsIR =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.IR_2072;
    const currentAccountingPeriodIsLMNP =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.LMNP_2031;

    const menuDisabled = ref(
      accountingPeriodsStore.isLMNP &&
        realEstateAssetsStore.realEstateAssets.length === 0
    );
    const handleMenuDisabled = (value) => {
      menuDisabled.value = value;
    };

    const { displayHelpSearch } = useCrisp();

    onMounted(() => {
      eventBus.$on("menuDisabled", handleMenuDisabled);
    });

    onUnmounted(() => {
      eventBus.$off("menuDisabled", handleMenuDisabled);
    });

    return {
      openMenuMobile,
      closeMenuMobile,
      goProductTour,
      goSubscriptions,
      menuDisabled,
      isMenuMobileOpened: computed(() => coreStore.isOpenMobileMenu),
      nbNotifications: computed(
        () => taskActivityStore.userNotificationsTaskActivities.length
      ),
      displayHelpSearch,
      currentAccountingPeriodIsIR,
      currentAccountingPeriodIsLMNP,
    };
  },
});
