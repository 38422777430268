// Compute a month based on the date of the start of the next period.
import { getMoment } from "./moment";
import assert from "assert";
import { Moment } from "moment-timezone";

function getMonth(date: string, ref: string): Moment {
  const diff = Math.floor(getMoment(date).diff(ref, "months", true));
  const result = getMoment(ref);
  return result.add(diff, "months");
}

export { getMonth };

function Test(): void {
  try {
    assert.deepStrictEqual(getMonth("2020-05-06", "2020-01-01").format("YYYY-MM"), "2020-05", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2021-01-01").format("YYYY-MM"), "2020-05", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2021-01-15").format("YYYY-MM"), "2020-04", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2020-01-15").format("YYYY-MM"), "2020-04", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-01-01", "2020-01-01").format("YYYY-MM"), "2020-01", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-01-01", "2020-04-15").format("YYYY-MM"), "2019-12", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2020-01-01").format("YYYY-MM-DD"), "2020-05-01", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2021-01-01").format("YYYY-MM-DD"), "2020-05-01", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2021-01-15").format("YYYY-MM-DD"), "2020-04-15", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-05-06", "2020-01-15").format("YYYY-MM-DD"), "2020-04-15", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-01-01", "2020-01-01").format("YYYY-MM-DD"), "2020-01-01", "incorrect date");

    assert.deepStrictEqual(getMonth("2020-01-01", "2020-04-15").format("YYYY-MM-DD"), "2019-12-15", "incorrect date");
  } catch (error) {
    console.error(error);
    return process.exit(1);
  }
}

Test();
