import { externalAxios } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { ResponseSiren, SirenePagination, Etablissement } from "@edmp/api";
import { onlyNumber } from "@/utils";
import { coreStore } from "@/store";

const API = "https://api.insee.fr/entreprises/sirene/V3/";
const ENDPOINT_SIRET = "siret?q=siret:";
const ENDPOINT_SIREN = "siret?q=siren:";
const ENDPOINT_FULLTEXT = "siret?q=denominationUniteLegale:";
const SIREN_LENGTH = 9;
const SIRET_LENGTH = 14;

export async function searchEnterprise(
  search: string
): Promise<Etablissement[]> {
  const searchBySiretSiren = onlyNumber(search);
  let endpoint = ENDPOINT_FULLTEXT;
  if (searchBySiretSiren) {
    endpoint = search.length === SIREN_LENGTH ? ENDPOINT_SIREN : endpoint;
    endpoint = search.length === SIRET_LENGTH ? ENDPOINT_SIRET : endpoint;
  } else {
    // Literal search
    search = `"${search}"`;
  }
  return externalAxios
    .get(API + endpoint + search, {
      headers: {
        Authorization: `Bearer ${coreStore.config.sirene.sireneApiKey}`,
      },
    })
    .then((res: AxiosResponse<ResponseSiren>) => {
      return (res.data as SirenePagination).etablissements;
    });
}
