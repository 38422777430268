























































































import { computed, defineComponent } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";

export default defineComponent({
  name: "RealEstateDetailForm",
  components: { SectionTitle },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    realEstateAssetId: {
      type: String,
      required: true,
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const modalTitle = computed(
      () => `${props.realEstateAssetId ? "Éditer" : "Ajouter"} un bien`
    );

    const onBack = () => {
      context.emit("previous");
    };

    const validate = () => {
      if (props.realEstateAssetId === "new") {
        context.emit("emptyRealEstate");
        return;
      }
      context.emit("validate");
    };

    return { validate, modalTitle, onBack };
  },
});
