import { downloadFile } from "@/utils";
import {
  AccountingsService,
  AccountingBalanceSheetsService,
  AccountingCarryForwardsService,
  AccountingResultsService,
  AccountingPeriodsService,
} from "@edmp/api";
import { DocumentFileType } from "@edmp/api/src";

import axios from "axios";
import { groupBy } from "lodash";

const api = "/api/v1/accountings";

class AccountingsService {
  async getBalances(
    params: AccountingsService.GetBalanceIn & { accountingPeriodId: string }
  ) {
    return axios
      .get<AccountingsService.GetBalanceOut>(`${api}/balance`, { params })
      .then((res) => {
        return Object.values(res.data).find(
          (e) => e.period === params.accountingPeriodId
        );
      });
  }

  async getBalanceCategories(
    params: AccountingsService.GetBalanceCategoriesIn
  ) {
    const res = await axios.get<AccountingsService.GetBalanceCategoriesOut>(
      `${api}/balance/categories`,
      { params }
    );
    return res.data;
  }

  async listCategories(params: AccountingsService.ListCategoriesIn) {
    return axios
      .get<AccountingsService.ListCategoriesOut>(`${api}/categories`, {
        params,
      })
      .then((res) => {
        return groupBy(res.data.suggest, "parent");
      });
  }

  async listOperations(params: AccountingsService.ListOperationsIn) {
    return axios
      .get<AccountingsService.ListOperationsOut>(`${api}/operations`, {
        params,
      })
      .then((res) => {
        return res.data;
      });
  }

  async getReportingSimple(params: AccountingsService.GetReportingSimpleIn) {
    return axios
      .get<AccountingsService.GetReportingSimpleOut>(
        `${api}/reporting-simple`,
        {
          params,
          responseType: "blob", // Important : Format Blob !
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((res) => {
        const fileName = (res.headers["content-disposition"] || "").split(
          '"'
        )[1];
        downloadFile(res.data, fileName, DocumentFileType.PDF);
      });
  }

  async getReportingComplete(
    params: AccountingsService.GetReportingCompleteIn
  ) {
    return axios
      .get<AccountingsService.GetReportingCompleteOut>(
        `${api}/reporting-complete`,
        {
          params,
          responseType: "blob", // Important : Format Blob !
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((res) => {
        const fileName = (res.headers["content-disposition"] || "").split(
          '"'
        )[1];
        downloadFile(res.data, fileName, DocumentFileType.PDF);
      });
  }

  async getReportingFec(params: AccountingsService.GetReportingFecIn) {
    return axios
      .get<AccountingsService.GetReportingFecOut>(`${api}/reporting-fec`, {
        params,
        responseType: "blob", // Important : Format Blob !
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((res) => {
        const fileName = (res.headers["content-disposition"] || "").split(
          '"'
        )[1];
        downloadFile(res.data, fileName, DocumentFileType.PDF);
      });
  }

  balanceSheets = {
    async create(
      accountingBalanceSheetCreate: AccountingBalanceSheetsService.CreateIn
    ) {
      const res = await axios.post<AccountingBalanceSheetsService.CreateOut>(
        `${api}/balance-sheets`,
        accountingBalanceSheetCreate
      );
      return res.data;
    },

    async list(params: AccountingBalanceSheetsService.ListIn) {
      const res = await axios.get<AccountingBalanceSheetsService.ListOut>(
        `${api}/balance-sheets`,
        { params }
      );
      return res.data;
    },

    async get(params: AccountingBalanceSheetsService.GetIn) {
      const res = await axios.get<AccountingBalanceSheetsService.GetOut>(
        `${api}/balance-sheets/${params.id}`,
        { params }
      );
      return res.data;
    },

    async reporting(params: AccountingBalanceSheetsService.ReportingIn) {
      const res = await axios.get<AccountingBalanceSheetsService.ReportingOut>(
        `${api}/balance-sheets/${params.id}/reporting`,
        {
          params,
          responseType: "blob", // Important : Format Blob !
          headers: {
            Accept: "application/pdf",
          },
        }
      );
      const fileName = (res.headers["content-disposition"] || "").split('"')[1];
      downloadFile(res.data, fileName, DocumentFileType.PDF);
    },

    async update(
      accountingBalanceSheetUpdate: AccountingBalanceSheetsService.UpdateIn
    ) {
      const res = await axios.put<AccountingBalanceSheetsService.UpdateOut>(
        `${api}/balance-sheets/${accountingBalanceSheetUpdate.id}`,
        accountingBalanceSheetUpdate
      );
      return res.data;
    },

    async remove(params: AccountingBalanceSheetsService.RemoveIn) {
      const res = await axios.delete<AccountingBalanceSheetsService.RemoveOut>(
        `${api}/balance-sheets/${params.id}`,
        { params }
      );
      return res.data;
    },
  };

  carryForwards = {
    async create(
      accountingCarryForwardCreate: AccountingCarryForwardsService.CreateIn
    ) {
      const res = await axios.post<AccountingCarryForwardsService.CreateOut>(
        `${api}/carry-forwards`,
        accountingCarryForwardCreate
      );
      return res.data;
    },

    async list(params: AccountingCarryForwardsService.ListIn) {
      const res = await axios.get<AccountingCarryForwardsService.ListOut>(
        `${api}/carry-forwards`,
        { params }
      );
      return res.data;
    },

    async get(params: AccountingCarryForwardsService.GetIn) {
      const res = await axios.get<AccountingCarryForwardsService.GetOut>(
        `${api}/carry-forwards/${params.id}`,
        { params }
      );
      return res.data;
    },

    async update(
      accountingCarryForwardUpdate: AccountingCarryForwardsService.UpdateIn
    ) {
      const res = await axios.put<AccountingCarryForwardsService.UpdateOut>(
        `${api}/carry-forwards/${accountingCarryForwardUpdate.id}`,
        accountingCarryForwardUpdate
      );
      return res.data;
    },

    async delete(params: AccountingCarryForwardsService.DeleteIn) {
      const res = await axios.delete<AccountingCarryForwardsService.DeleteOut>(
        `${api}/carry-forwards/${params.id}`,
        { params }
      );
      return res.data;
    },
  };

  periods = {
    async create(accountingPeriodCreate: AccountingPeriodsService.CreateIn) {
      return axios
        .post<AccountingPeriodsService.CreateOut>(
          `${api}/periods`,
          accountingPeriodCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: AccountingPeriodsService.ListIn) {
      return axios
        .get<AccountingPeriodsService.ListOut>(`${api}/periods`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: AccountingPeriodsService.GetIn) {
      return axios
        .get<AccountingPeriodsService.GetOut>(`${api}/periods/${params.id}`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async update(accountingPeriodUpdate: AccountingPeriodsService.UpdateIn) {
      return axios
        .put<AccountingPeriodsService.UpdateOut>(
          `${api}/periods/${accountingPeriodUpdate.id}`,
          accountingPeriodUpdate
        )
        .then((res) => {
          return res.data;
        });
    },
  };

  results = {
    async create(accountingResultCreate: AccountingResultsService.CreateIn) {
      const res = await axios.post<AccountingResultsService.CreateOut>(
        `${api}/results`,
        accountingResultCreate
      );
      return res.data;
    },

    async list(params: AccountingResultsService.ListIn) {
      const res = await axios.get<AccountingResultsService.ListOut>(
        `${api}/results`,
        { params }
      );
      return res.data;
    },

    async get(params: AccountingResultsService.GetIn) {
      const res = await axios.get<AccountingResultsService.GetOut>(
        `${api}/results/${params.id}`,
        { params }
      );
      return res.data;
    },

    async update(accountingResultUpdate: AccountingResultsService.UpdateIn) {
      const res = await axios.put<AccountingResultsService.UpdateOut>(
        `${api}/results/${accountingResultUpdate.id}`,
        accountingResultUpdate
      );
      return res.data;
    },

    async delete(params: AccountingResultsService.DeleteIn) {
      const res = await axios.delete<AccountingResultsService.DeleteOut>(
        `${api}/results/${params.id}`,
        { params }
      );
      return res.data;
    },
  };
}

// Export a singleton instance in the global namespace
export const accountingsService = new AccountingsService();
