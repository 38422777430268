





















































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { RentalAgreementsFilter } from "@edmp/api";
export default defineComponent({
  name: "RentalAgreementsFilter",
  components: {},
  props: {
    isOpenFilterRentalAgreements: {
      type: Boolean,
      require: true,
    },
    rentalAgreementsFilter: {
      type: Object as () => RentalAgreementsFilter,
      require: true,
    },
    rentalAgreementsFilterByRealEstateAsset: {
      type: Boolean,
      require: true,
    },
  },
  setup(props, context) {
    const $rentalAgreementsFilter = computed(() => {
      return props.rentalAgreementsFilter;
    });
    const $rentalAgreementsFilterByRealEstateAsset = computed(() => {
      return props.rentalAgreementsFilterByRealEstateAsset;
    });
    const rentalAgreementsFilterLocal: Ref<RentalAgreementsFilter | undefined> =
      ref();

    const rentalAgreementActiveTrue: Ref<Boolean | undefined> = ref();
    const rentalAgreementsFilterByRealEstateAssetLocal: Ref<
      Boolean | undefined
    > = ref();
    const rentalAgreementNActiveTrue: Ref<Boolean | undefined> = ref();
    const init = async () => {
      rentalAgreementsFilterLocal.value = $rentalAgreementsFilter.value;
      rentalAgreementsFilterByRealEstateAssetLocal.value =
        $rentalAgreementsFilterByRealEstateAsset.value;
      if (
        typeof rentalAgreementsFilterLocal.value?.rentalAgreementActive ===
        "boolean"
      ) {
        if (rentalAgreementsFilterLocal.value.rentalAgreementActive) {
          rentalAgreementActiveTrue.value = true;
        } else {
          rentalAgreementNActiveTrue.value = true;
        }
      } else {
        rentalAgreementActiveTrue.value = false;
        rentalAgreementNActiveTrue.value = false;
      }
    };
    const validate = () => {
      if (
        (rentalAgreementActiveTrue.value && rentalAgreementNActiveTrue.value) ||
        (!rentalAgreementActiveTrue.value && !rentalAgreementNActiveTrue.value)
      ) {
        Object.assign(rentalAgreementsFilterLocal.value, {
          rentalAgreementActive: undefined,
        });
      } else if (rentalAgreementActiveTrue.value) {
        Object.assign(rentalAgreementsFilterLocal.value, {
          rentalAgreementActive: true,
        });
      } else if (rentalAgreementNActiveTrue) {
        Object.assign(rentalAgreementsFilterLocal.value, {
          rentalAgreementActive: false,
        });
      }
      context.emit(
        "update:rentalAgreementsFilter",
        rentalAgreementsFilterLocal.value
      );
      context.emit(
        "update:rentalAgreementsFilterByRealEstateAsset",
        rentalAgreementsFilterByRealEstateAssetLocal.value
      );
      context.emit("update:isOpenFilterRentalAgreements", false);
    };
    onMounted(async () => {
      await init();
    });

    watch(
      () => $rentalAgreementsFilter.value,
      async () => await init(),
      { deep: true }
    );
    return {
      rentalAgreementsFilterByRealEstateAssetLocal,
      rentalAgreementActiveTrue,
      rentalAgreementNActiveTrue,
      validate,
      rentalAgreementsFilterLocal,
    };
  },
});
