





































import { PropType } from "@vue/composition-api";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export interface HelpingItem {
  title: string;
  icon: string;
  url?: string;
  crispArticle?: ArticleEnum;
}

export default {
  name: "HelpingView",
  props: {
    items: {
      type: Array as PropType<HelpingItem[]>,
      validator: (items) => {
        for (const item of items) {
          if (!item.url && !item.crispArticle) {
            console.error(
              new Error(`Key url or crispArticle for ${item.title} is required`)
            );
            return false;
          }
          if (item.url && item.crispArticle) {
            console.error(
              new Error(`Only key url or crispArticle ${item.title}`)
            );
            return false;
          }
        }
        return true;
      },
      required: true,
    },
  },
  setup() {
    const { openArticle } = useCrisp();

    return { openArticle };
  },
};
