














import {
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import DocumentsPanel from "@/components/core/documents/DocumentsPanel.vue";
import { subscriptionsStore } from "@/store";
import { SubscriptionsModel } from "@edmp/api";
import moment from "moment";

export default defineComponent({
  name: "SubscriptionDocuments",
  props: {
    subscriptionId: { type: String, required: true },
  },
  components: {
    DocumentsPanel,
  },
  setup(props) {
    const headers = [
      { text: "N° Facture", value: "invoiceNo" },
      { text: "Statut", value: "status" },
      { text: "Montant", value: "priceTTC" },
      { text: "Format", value: "type" },
    ];
    const invoiceDocs: Ref<
      | {
          invoiceNo: SubscriptionsModel.Invoice["invoiceNo"];
          status: string;
          priceTTC: string;
          type: string;
          href: SubscriptionsModel.Invoice["invoicePdf"];
        }[]
      | Array<undefined>
    > = ref([]);

    /**
     * Init
     */
    const init = async () => {
      const invoices = await subscriptionsStore.getInvoices(
        props.subscriptionId
      );

      const invoicesTemp: {
        invoiceNo: SubscriptionsModel.Invoice["invoiceNo"];
        status: string;
        priceTTC: string;
        type: string;
        href: SubscriptionsModel.Invoice["invoicePdf"];
      }[] = [];
      for (const invoice of invoices.data) {
        if (
          invoice.status === SubscriptionsModel.InvoiceStatus.draft ||
          invoice.status === SubscriptionsModel.InvoiceStatus.deleted
        ) {
          break;
        }

        let status = "Inconue";
        switch (invoice.status) {
          case SubscriptionsModel.InvoiceStatus.open:
            status = `Créé le ${moment(invoice.createdAt).format(
              "DD/MM/YYYY"
            )}`;
            break;
          case SubscriptionsModel.InvoiceStatus.paid:
            status = `Payé le ${moment(invoice.paidAt).format("DD/MM/YYYY")}`;
            break;
          case SubscriptionsModel.InvoiceStatus.uncollectible:
            status = `Non recouvré le ${moment(invoice.uncollectibleAt).format(
              "DD/MM/YYYY"
            )}`;
            break;
          case SubscriptionsModel.InvoiceStatus.void:
            status = `Annulé le ${moment(invoice.uncollectibleAt).format(
              "DD/MM/YYYY"
            )}`;
            break;
          default:
            break;
        }

        let priceTTC = "Inconue";
        if (invoice.amount === 0) {
          priceTTC = "Gratuit";
        } else {
          priceTTC = `${(invoice.amountTotal / 100).toFixed(2)}€ TTC`;
        }

        invoicesTemp.push({
          invoiceNo: invoice.invoiceNo,
          status,
          priceTTC,
          type: "application/pdf",
          href: invoice.invoicePdf,
        });
      }

      invoiceDocs.value = invoicesTemp;
    };

    watch(
      () => props.subscriptionId,
      () => init(),
      { deep: true }
    );

    onBeforeMount(async () => await init());

    return {
      headers,
      invoiceDocs,
      init,
    };
  },
});
