




































































































import { defineComponent, ref, Ref } from "@vue/composition-api";
import { searchEnterprise } from "@/services/Sirene.service";
import { Etablissement } from "@edmp/api";

export default defineComponent({
  name: "SCISearch",
  setup(_, context) {
    const searchWord: Ref<string> = ref("");
    const isLoading: Ref<boolean> = ref(false);
    const items: Ref<Etablissement[]> = ref([]);
    const selectedItem: Ref<number | undefined> = ref(undefined);
    const error: Ref<boolean> = ref(false);

    function search(): void {
      if (!searchWord.value || isLoading.value) return;

      isLoading.value = true;
      selectedItem.value = Number(undefined);
      items.value = [];

      searchEnterprise(searchWord.value)
        .then((res) => {
          error.value = false;
          items.value = res;
        })
        .catch(() => (error.value = true))
        .finally(() => (isLoading.value = false));
    }

    const getEstablishmentName = (establishment: Etablissement) =>
      `${establishment?.uniteLegale?.denominationUniteLegale || ""} - SIRET ${
        establishment.siret
      }`;

    const getAddress = (establishment: Etablissement) =>
      `${establishment.adresseEtablissement?.numeroVoieEtablissement || ""} ${
        establishment.adresseEtablissement?.indiceRepetitionEtablissement || ""
      } ${establishment.adresseEtablissement?.typeVoieEtablissement || ""} ${
        establishment.adresseEtablissement?.libelleVoieEtablissement || ""
      }${establishment.adresseEtablissement?.codePostalEtablissement || ""}${
        establishment.adresseEtablissement?.libelleCommuneEtablissement || ""
      }`;

    function select(): void {
      if (selectedItem.value !== undefined) {
        context.emit("select", items.value[Number(selectedItem.value)]);
      }
    }
    return {
      searchWord,
      isLoading,
      items,
      selectedItem,
      error,
      search,
      select,
      getEstablishmentName,
      getAddress,
    };
  },
});
