



















































































import { RealEstateAsset, RealEstateLoan } from "@edmp/api";
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  Ref,
} from "@vue/composition-api";

import RealEstateLoanModal, {
  defaultLoan,
} from "@/components/core/activities/realEstateLoans/RealEstateLoanModal.vue";
import RealEstateLoans from "@/components/core/activities/realEstateLoans/RealEstateLoans.vue";
import { realEstateLoansStore } from "@/store";

export default defineComponent({
  name: "RealEstateLoanLMNP",
  components: {
    RealEstateLoans,
    RealEstateLoanModal,
  },
  props: {
    realEstateAsset: {
      type: Object as () => RealEstateAsset,
      required: true,
    },
    isFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const realEstateLoansList = computed(() =>
      realEstateLoansStore.getRealEstateLoansByRealEstateAssetId(
        props.realEstateAsset.id
      )
    );

    // Function
    const scrollToElement = (elementId) =>
      nextTick(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });

    // Loan
    const openLoanModal: Ref<boolean> = ref(
      props.isFlow || realEstateLoansList.value.length === 0
    );
    const selectedLoan: Ref<Partial<RealEstateLoan> | undefined> =
      ref(undefined);
    const selectLoan = (loan) => {
      selectedLoan.value = loan;
      openLoanModal.value = true;
      scrollToElement("loanModal-component");
    };
    const closeLoanModal = () => {
      openLoanModal.value = false;
      selectedLoan.value = undefined;
      context.emit("openLoan", false);
    };
    const createAnotherLoan = () => {
      selectedLoan.value = defaultLoan;
      openLoanModal.value = true;
      scrollToElement("loanModal-component");
    };
    const validateLoanModal = () => {
      closeLoanModal();
    };

    return {
      realEstateLoansList,
      openLoanModal,
      selectedLoan,
      selectLoan,
      validateLoanModal,
      closeLoanModal,
      createAnotherLoan,
    };
  },
});
