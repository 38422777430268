import { RentIndexationError } from "@edmp/api";

export type RentalIndexationError = {
  title: string;
  message: string;
  hasAlert: boolean;
};

export function useRentalIndexationErrorUsable(
  value: string,
  error: RentIndexationError
): RentalIndexationError {
  const InseeError = {
    title: "Erreur de connexion à l'INSEE",
    message:
      "Impossible de récupérer automatiquement le nouvel IRL. Veuillez saisir manuellement le nouvel IRL en utilisant le lien d'accès au site de l'INSEE mis à votre disposition.",
    hasAlert: false,
  };

  const UnavailableError = {
    title:
      "Votre indice n'est pas disponible pour le moment. Veuillez attendre que l'INSEE le publie pour réviser votre loyer.",
    message: "",
    hasAlert: true,
  };

  const LimitDateNotReachedError = {
    title:
      "Vous tentez de réviser votre loyer avant la date de référence indiquée dans votre bail. Merci de patienter jusqu'à cette date pour procéder à la révision de votre loyer.",
    message: `La prochaine révision pourra avoir lieu maximum à partir du ${value}`,
    hasAlert: true,
  };

  const ForgotDueDateError = {
    title:
      "La période de révision de loyer pour cette année a été dépassée. Vous ne pouvez plus réviser le loyer pour l'année en cours. Veuillez attendre la prochaine période de révision selon la date convenue dans votre bail.",
    message: "",
    hasAlert: false,
  };

  switch (error) {
    case RentIndexationError.InseeError:
      return InseeError;
    case RentIndexationError.Unavailable:
      return UnavailableError;
    case RentIndexationError.LimitDateNotReached:
      return LimitDateNotReachedError;
    case RentIndexationError.ForgotDueDate:
      return ForgotDueDateError;
    default:
      return InseeError;
  }
}
