import axios from "axios";
import { DocumentsService } from "@edmp/api";

const api = "/api/v1/documents";

class DocumentsService {
  async create({
    document,
    file,
  }: Omit<DocumentsService.CreateIn, "file"> & { file: File }) {
    const blobInfos = new Blob([JSON.stringify(document)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("infos", blobInfos);
    formData.append("file", file);

    return axios
      .post<DocumentsService.CreateOut>(`${api}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  async list(params: DocumentsService.ListIn) {
    return axios
      .get<DocumentsService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: Required<DocumentsService.GetIn>) {
    return axios
      .get<DocumentsService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async addTransactionAttachment({
    document,
    file,
  }: Omit<DocumentsService.AddAttachmentTransactionIn, "file"> & {
    file: File;
  }) {
    const blobInfos = new Blob([JSON.stringify(document)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("infos", blobInfos);
    formData.append("file", file);

    return axios
      .post<DocumentsService.AddAttachmentTransactionOut>(
        `${api}/addTransactionAttachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  async remove(params: DocumentsService.DeleteIn) {
    return axios
      .delete<DocumentsService.DeleteOut>(`${api}/${params.id}`, {
        params,
      })
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const documentsService = new DocumentsService();
