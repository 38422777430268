import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { RequireField } from "..";
import { Address, addressSchema } from "./Common.model";

export enum TenantTypeEnum {
  NATURAL_PERSON = "natural_person",
  ASSOCIATION = "association",
  COMPANY = "company",
}

// TODO Uniformiser avec la personne morale ou physique ?
export interface Representative {
  legalStatus?: string;
  denomination?: string;
  firstName?: string;
  lastName?: string;
  siret?: string;
  email?: string;
  phone?: string;
  address?: Address;
}

const representativeSchema = new Schema<Representative>({
  legalStatus: String,
  denomination: String,
  firstName: String,
  lastName: String,
  siret: String,
  email: String,
  phone: String,
  address: addressSchema,
});

export interface NewTenant {
  productId: string;
  realEstateAssetId: string;
  rentalAgreementId: string;
  denomination?: string;
  numSIREN?: string;
  numTVA?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  type?: TenantTypeEnum;
  representative?: Representative;
}

export const TenantSchema = new Schema<TenantDocument>({
  _id: { type: String, default: () => ulid() },
  productId: String,
  rentalAgreementId: String,
  realEstateAssetId: String,
  name: String,
  denomination: String,
  numSIREN: String,
  numTVA: String,
  firstName: String,
  lastName: String,
  email: String,
  phone: String,
  type: {
    type: String,
    enum: Object.values(TenantTypeEnum), // Mongoose doesn't support Typescript ENUM
  },
  representative: representativeSchema,
},
{
  timestamps: true,
  toJSON: {
    versionKey: false,
    virtuals: true,
    transform(doc, ret: TenantDocument) {
      ret.id = ret._id;
      return ret;
    },
  },
});

export interface Tenant extends NewTenant {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export type TenantUpdate = RequireField<
  Partial<Omit<Tenant, | "createdAt" | "updatedAt">>,
  "id"
>;
export type TenantUpdateInternal = RequireField<
  Partial<Omit<Tenant, | "createdAt" | "updatedAt">>,
  "id"
>;

export type TenantDocument = Tenant & Document<string>;

// Name of the collection in third argument
export const TenantModel = model<TenantDocument>(
  "Tenant",
  TenantSchema,
  "Tenants"
);

export namespace TenantsService {
  export type CreateIn = NewTenant;
  export type CreateOut = Tenant;

  export type ListIn = { productId?: string, realEstateAssetId?: string, rentalAgreementId?: string };
  export type ListOut = Tenant[];

  export type GetIn = Pick<Tenant, "id">;
  export type GetOut = Tenant;

  export type UpdateIn = TenantUpdate;
  export type UpdateOut = Tenant;

  export type DeleteIn = Pick<Tenant, "id">;
  export type DeleteOut = boolean;
}