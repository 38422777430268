




















import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  Ref,
} from "@vue/composition-api";
import { accountingPeriodsStore, productsStore } from "@/store";
import { AccountingPeriod } from "@edmp/api";

import {
  DisplayAccountingPeriod,
  useAccountingPeriod,
} from "./AccountingPeriod.usable";

export default defineComponent({
  name: "AccountingPeriodChoice",
  setup(_, context) {
    const accountingPeriodList: Ref<DisplayAccountingPeriod[]> = ref([]);

    const { getAccountingPeriodList, getAccountingPeriodText } =
      useAccountingPeriod();

    function changeAccountingPeriod(item: { acc: AccountingPeriod }) {
      accountingPeriodsStore.switchAccountingPeriod(item.acc.id);
      context.emit("change");
    }

    const setAccountingPeriodList = async () => {
      accountingPeriodList.value = await getAccountingPeriodList();
    };

    onMounted(() => setAccountingPeriodList());

    watch(
      () => [productsStore.currentId, accountingPeriodsStore.accountingPeriods],
      () => setAccountingPeriodList()
    );

    return {
      accountingPeriod: computed(
        () => accountingPeriodsStore.currentAccountingPeriod
      ),
      accountingPeriodList,
      accountingPeriodText: computed(() =>
        getAccountingPeriodText(accountingPeriodsStore.currentAccountingPeriod)
      ),
      changeAccountingPeriod,
    };
  },
});
