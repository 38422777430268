var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row"},[(
      _vm.taskActivityGroupLocal.groupCode ===
        _vm.TaskGroupCode.EventGeneralAssemblyYearEnd &&
      _vm.taskActivityGroupLocal.isEnableCustomAction
    )?[_c('div',{staticClass:"d-flex position"},[_c('v-btn',{staticClass:"position-left =",attrs:{"depressed":"","color":"primary"},on:{"click":function () { return _vm.$router.push({
              name: _vm.ROUTE_NAMES.EventsGeneralAssembly,
              query: { newEvent: 'ordinary', pattern: 'yearEnd' },
            }); }}},[_c('span',[_vm._v("Faire avec Ownily")])]),_c('v-btn',{staticClass:"position-right",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.validateEventGeneralAssemblyYearEndCreateStep()}}},[_c('span',[_vm._v("Faire sans Ownily ")])])],1)]:_vm._e(),(
      _vm.isIR &&
      !_vm.isPremium &&
      (_vm.taskActivityGroupLocal.groupCode === _vm.TaskGroupCode.BalanceSheet ||
        _vm.taskActivityGroupLocal.groupCode ===
          _vm.TaskGroupCode.TeletransmitTaxReturn) &&
      _vm.taskActivityGroupLocal.isDisabled
    )?[_c('div',{staticClass:"d-flex position"},[_c('Tag',{staticClass:"premium-only position-left",attrs:{"color":"warning","size":"small"}},[_vm._v(" Disponible en premium uniquement ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }