








































































































import { HelpingMessage } from "@/components/atom/helping";
import { ROUTE_NAMES } from "@/router/routes";
import { rentalsService } from "@/services";
import { extension } from "mime-types";
import { DocumentFileType, DocumentModelType } from "@edmp/api";
import { defineComponent } from "@vue/composition-api";
import PageHeader from "../../atom/PageHeader.vue";

export default defineComponent({
  name: "ModelsLibrary",
  components: {
    HelpingMessage,
    PageHeader,
  },
  setup() {
    const headers = [
      { text: "Libellé", value: "name" },
      { text: "options", value: "options" },
      { text: "Format de téléchargement", value: "format" },
    ];
    const headersWithoutOptions = [
      { text: "Libellé", value: "name" },
      { text: "Format de téléchargement", value: "format" },
    ];
    const sections: {
      name: string;

      models: {
        name: string;
        modelType?: DocumentModelType;
        hasOptions?: boolean;
        hasList?: boolean;
        withVat?: boolean;
        withApl?: boolean;
      }[];
    }[] = [
      {
        name: "Modèles de gestion locative",
        models: [
          {
            name: "Quittance de loyer",
            modelType: DocumentModelType.RENT_RECEIPT,
            hasOptions: true,
            withVat: false,
            withApl: false,
          },
          {
            name: "Appel de loyer",
            modelType: DocumentModelType.RENT_CALL,
            hasOptions: true,
            withVat: false,
            withApl: false,
          },
          {
            name: "Relance de loyer impayé",
            modelType: DocumentModelType.REMINDER_OF_UNPAID_RENT,
          },
          {
            name: "Relance attestation d'assurance habitation",
            modelType: DocumentModelType.REMINDER_INSURANCE_CERTIFICATE,
          },
        ],
      },
      {
        name: "Préavis d'indexation et de régularisation",
        models: [
          {
            name: "Préavis de régularisation des charges - En faveur du bailleur",
            modelType: DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_LESSOR,
          },
          {
            name: "Préavis de régularisation des charges - En faveur du locataire",
            modelType: DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_TENANT,
          },
          {
            name: "Préavis de révision du loyer",
            modelType: DocumentModelType.NOTICE_RENT_REVIEW,
          },
        ],
      },
      // {
      //   name: "Procédures de recouvrement",
      //   models: [
      //     {
      //       name: "Mise en demeure AR",
      //       modelType: DocumentModelType.FORMAL_NOTICE,
      //     },
      //   ],
      // },
      {
        name: "Documents de garantie locative",
        models: [
          {
            name: "Acte de cautionnement solidaire à durée déterminée",
            modelType:
              DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_A_FIXED_TERM,
          },
          {
            name: "Acte de cautionnement solidaire à durée indéterminée",
            modelType:
              DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_AN_INDEFINITE_TERM,
          },
          {
            name: "Acte de cautionnement simple à durée déterminée",
            modelType: DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_A_FIXED_TERM,
          },
          {
            name: "Acte de cautionnement simple à durée indéterminée",
            modelType:
              DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_AN_INDEFINITE_TERM,
          },
        ],
      },
      // {
      //   name: "Modèles de contrats de location",
      //   models: [
      //     {
      //       name: "Bail location nue",
      //       modelType: DocumentModelType.UNFURNISHED_LEASE,
      //     },
      //     {
      //       name: "Bail location meublé",
      //       modelType: DocumentModelType.FURNISHED_LEASE,
      //     },
      //     {
      //       name: "Bail mobilité",
      //       modelType: DocumentModelType.MOBILITY_LEASE,
      //     },
      //     {
      //       name: "Avenant au bail",
      //       hasList: true,
      //     },
      //   ],
      // },
    ];

    const downloadModel = async (
      item,
      modelType: DocumentModelType,
      fileType: DocumentFileType
    ) => {
      switch (item.modelType) {
        case "Quittance de loyer":
          if (item.withApl && !item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_RECEIPT_WITH_APL,
              fileType,
            });
          } else if (!item.withApl && item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_RECEIPT_WITH_VAT,
              fileType,
            });
          } else if (item.withApl && item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_RECEIPT_WITH_VAT_AND_APL,
              fileType,
            });
          } else {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_RECEIPT,
              fileType,
            });
          }
          break;
        case "Appel de loyer":
          if (item.withApl && !item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_CALL_WITH_APL,
              fileType,
            });
          } else if (!item.withApl && item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_CALL_WITH_VAT,
              fileType,
            });
          } else if (item.withApl && item.withVat) {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_CALL_WITH_VAT_AND_APL,
              fileType,
            });
          } else {
            await rentalsService.agreements.downloadDocumentModel({
              modelType: DocumentModelType.RENT_CALL,
              fileType,
            });
          }
          break;
        default:
          await rentalsService.agreements.downloadDocumentModel({
            modelType,
            fileType,
          });
          break;
      }
    };

    return {
      headers,
      headersWithoutOptions,
      ROUTE_NAMES,
      sections,
      downloadModel,
      extension,
      DocumentFileType,
    };
  },
});
