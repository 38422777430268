







































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "NavigationItem",
  props: {
    routeName: String,
    name: String,
    icon: String,
    new: Boolean,
    isButton: Boolean,
  },
  setup(props, context) {
    function navigateTo(routeName: string) {
      context.emit("navigate", routeName);
    }

    return {
      currentRoute: computed(() => context.root.$route.name),
      navigateTo,
    };
  },
});
