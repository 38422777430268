





















































import { defineComponent, PropType } from "@vue/composition-api";
import { SubscriptionsModel, TaxDeclaration2031LineAmount } from "@edmp/api";

import { transactionsStore } from "@/store";
import { FilterKeys } from "@/utils";
import { TaxDeclaration2031LineCompute } from "@/models";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "TaxDeclaration2031Lines",
  props: {
    lines: {
      type: Array as PropType<TaxDeclaration2031LineCompute>,
      required: true,
    },
  },
  setup(props, context) {
    // Methods
    const goToTransactions = (line: TaxDeclaration2031LineCompute[number]) => {
      transactionsStore.resetFilter();
      transactionsStore.addFilter({
        code: FilterKeys.CERFA,
        filterParams: {
          ids: (line as unknown as TaxDeclaration2031LineAmount<true>)
            .transactionIds,
          label: `2031 - ${line.title}`,
        },
      });
      context.root.$router.push({
        name: ROUTE_NAMES.Transactions,
      });
    };

    return {
      goToTransactions,
      SubscriptionPlanType: SubscriptionsModel.PlanType,
    };
  },
});
