




import { defineComponent } from "@vue/composition-api";
import GenericBadge from "./GenericBadge.vue";

export default defineComponent({
  name: "BadgeDev",
  components: {
    GenericBadge,
  },
  props: {
    text: { type: String, required: true },
  },
});
