/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Schema } from "mongoose";

type Nullable<T> = T | null;
export type RequireField<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type PartialField<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type NullableField<T, K extends keyof T> = Omit<T, K> & { [P in K]+?: Nullable<T[P]> };
export type OmitField<T, K extends keyof T> = {
  [P in keyof T as P extends K ? never : P]: T[P];
};
export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
export type ValueOf<T> = T[keyof T];

/**
 * `Email` — An email address
 */
export type Email = string;

/**
 * `isoDate` — An ISO8601 date as YYYY-MM-DD
 */
export type IsoDate = string;
/**
 * `isoDateAndTime` — An ISO8601 date and time
 */
export type IsoDateAndTime = string;

/**
 * `Currency` — Currency of amount
 */
export type Currency = string;

/**
 * `Amount` — Amount in the given currency, as a decimal number
 *
 * This interface was referenced by `AccountMap`'s JSON-Schema definition
 * via the `patternProperty` "^d+$".
 *
 * This interface was referenced by `undefined`'s JSON-Schema definition
 * via the `patternProperty` "^(bankAccount|realEstateAsset|rentalUnit|partner|rentalAgreement)/.+$".
 */
export type Amount = number;

/**
 * `CurrencyAndAmount` — Monetary amount
 * Monetary amount of the operation, in the account's currency
 */
export interface CurrencyAndAmount {
  amount: Amount;
  currency: Currency;
}

/**
 * `Verified` — Degree of validity of an information
 */
export type Verified = "waiting" | "confirmed" | "pending" | "invalid";

export type Timestamps = {
  createdAt: string;
  updatedAt: string;
};

export type Address = {
  additional?: string;
  street?: string;
  city?: string;
  zip?: string;
  country?: string;
};

export const countryISOcode = {
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AN: "Antilles néerlandaises",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BR: "Brésil",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  EA: "Ceuta et Melilla",
  CL: "Chili",
  CN: "Chine",
  CY: "Chypre",
  CO: "Colombie",
  KM: "Comores",
  CG: "Congo-Brazzaville",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  CR: "Costa Rica",
  CI: "Côte d’Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  DK: "Danemark",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DM: "Dominique",
  EG: "Égypte",
  SV: "El Salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  VA: "État de la Cité du Vatican",
  FM: "États fédérés de Micronésie",
  US: "États-Unis",
  ET: "Éthiopie",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du Sud et les îles Sandwich du Sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GQ: "Guinée équatoriale",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  GF: "Guyane française",
  HT: "Haïti",
  HN: "Honduras",
  HU: "Hongrie",
  BV: "Île Bouvet",
  CX: "Île Christmas",
  CP: "Île Clipperton",
  AC: "Île de l'Ascension",
  IM: "Île de Man",
  NF: "Île Norfolk",
  AX: "Îles Åland",
  KY: "Îles Caïmans",
  IC: "Îles Canaries",
  CC: "Îles Cocos - Keeling",
  CK: "Îles Cook",
  FO: "Îles Féroé",
  HM: "Îles Heard et MacDonald",
  FK: "Îles Malouines",
  MP: "Îles Mariannes du Nord",
  MH: "Îles Marshall",
  UM: "Îles Mineures Éloignées des États-Unis",
  SB: "Îles Salomon",
  TC: "Îles Turks et Caïques",
  VG: "Îles Vierges britanniques",
  VI: "Îles Vierges des États-Unis",
  IN: "Inde",
  ID: "Indonésie",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  KW: "Koweït",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MK: "Macédoine",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MA: "Maroc",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  NL: "Pays-Bas",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto Rico",
  PT: "Portugal",
  QA: "Qatar",
  HK: "R.A.S. chinoise de Hong Kong",
  MO: "R.A.S. chinoise de Macao",
  QO: "régions éloignées de l’Océanie",
  CF: "République centrafricaine",
  CD: "République démocratique du Congo",
  DO: "République dominicaine",
  CZ: "République tchèque",
  RE: "Réunion",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  EH: "Sahara occidental",
  BL: "Saint-Barthélémy",
  KN: "Saint-Kitts-et-Nevis",
  SM: "Saint-Marin",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  SH: "Sainte-Hélène",
  LC: "Sainte-Lucie",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  CS: "Serbie-et-Monténégro",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et Île Jan Mayen",
  SZ: "Swaziland",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TD: "Tchad",
  TF: "Terres australes françaises",
  IO: "Territoire britannique de l'océan Indien",
  PS: "Territoire palestinien",
  TH: "Thaïlande",
  TL: "Timor oriental",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TA: "Tristan da Cunha",
  TN: "Tunisie",
  TM: "Turkménistan",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  EU: "Union européenne",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viêt Nam",
  WF: "Wallis-et-Futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};

/**
 * Si besoin de modifier pour une collection en particulier, on peut faire addressSchema.clone().set(...)
 */
export const addressSchema = new Schema({
  additional: String,
  street: { type: String, required: false },
  city: { type: String, required: false },
  zip: { type: String, required: false },
  country: String,
});

export const decimal2JSON = (v: any, i?: any, prev?: any) => {
  if (v !== null && typeof v === "object") {
    if (v.constructor.name === "Decimal128") {
      prev[i] = Number(v.toString());
    } else {
      Object.entries(v).forEach(([key, value]) => decimal2JSON(value, key, prev ? prev[i] : v));
    }
  }
};

/**
 * A container for paginated lists of objects.
 * The array of objects is on the `.data` property,
 * and `.has_more` indicates whether there are additional objects beyond the end of this list.
 *
 */
export interface ApiList<T> {
  // object: string;

  data: Array<T>;

  //True if this list has another page of items after this one that can be fetched.
  hasMore: boolean;

  totalDocs: number;

  limit: number; // Maximum of objects that server can return
}

type ExcludeNeverKeys<T> = {
  [K in keyof T]: T[K] extends never ? never : K;
};
export type UnOneOf<
  T extends OneOf<any, any, any, any, any, any, any, any, any, any, any, any, any, any, any, any, any, any, any, any>
> = T extends OneOf<
  infer T1,
  infer T2,
  infer T3,
  infer T4,
  infer T5,
  infer T6,
  infer T7,
  infer T8,
  infer T9,
  infer T10,
  infer T11,
  infer T12,
  infer T13,
  infer T14,
  infer T15,
  infer T16,
  infer T17,
  infer T18,
  infer T19,
  infer T20
>
  ? T1 extends Record<any, any>
    ? Partial<
        ExcludeNeverKeys<T1> &
          ExcludeNeverKeys<T2> &
          ExcludeNeverKeys<T3> &
          ExcludeNeverKeys<T4> &
          ExcludeNeverKeys<T5> &
          ExcludeNeverKeys<T6> &
          ExcludeNeverKeys<T7> &
          ExcludeNeverKeys<T8> &
          ExcludeNeverKeys<T9> &
          ExcludeNeverKeys<T10> &
          ExcludeNeverKeys<T11> &
          ExcludeNeverKeys<T12> &
          ExcludeNeverKeys<T13> &
          ExcludeNeverKeys<T14> &
          ExcludeNeverKeys<T15> &
          ExcludeNeverKeys<T16> &
          ExcludeNeverKeys<T17> &
          ExcludeNeverKeys<T18> &
          ExcludeNeverKeys<T19> &
          ExcludeNeverKeys<T20>
      >
    : T1 | T2 | T3 | T4 | T5 | T6 | T7 | T8 | T9 | T10 | T11 | T12 | T13 | T14 | T15 | T16 | T17 | T18 | T19 | T20
  : never;
export type OneOf<
  T1,
  T2,
  T3 = undefined,
  T4 = undefined,
  T5 = undefined,
  T6 = undefined,
  T7 = undefined,
  T8 = undefined,
  T9 = undefined,
  T10 = undefined,
  T11 = undefined,
  T12 = undefined,
  T13 = undefined,
  T14 = undefined,
  T15 = undefined,
  T16 = undefined,
  T17 = undefined,
  T18 = undefined,
  T19 = undefined,
  T20 = undefined
> =
  | (T1 &
      Partial<
        Record<
          Exclude<
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T1
          >,
          never
        >
      >)
  | (T2 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T2
          >,
          never
        >
      >)
  | (T3 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T3
          >,
          never
        >
      >)
  | (T4 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T4
          >,
          never
        >
      >)
  | (T5 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T5
          >,
          never
        >
      >)
  | (T6 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T6
          >,
          never
        >
      >)
  | (T7 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T7
          >,
          never
        >
      >)
  | (T8 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T8
          >,
          never
        >
      >)
  | (T9 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T9
          >,
          never
        >
      >)
  | (T10 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T10
          >,
          never
        >
      >)
  | (T11 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T11
          >,
          never
        >
      >)
  | (T12 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T12
          >,
          never
        >
      >)
  | (T13 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T13
          >,
          never
        >
      >)
  | (T14 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T14
          >,
          never
        >
      >)
  | (T15 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T15
          >,
          never
        >
      >)
  | (T16 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T17
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T16
          >,
          never
        >
      >)
  | (T17 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T18
            | keyof T19
            | keyof T20,
            keyof T17
          >,
          never
        >
      >)
  | (T18 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T19
            | keyof T20,
            keyof T18
          >,
          never
        >
      >)
  | (T19 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T20,
            keyof T19
          >,
          never
        >
      >)
  | (T20 &
      Partial<
        Record<
          Exclude<
            | keyof T1
            | keyof T2
            | keyof T3
            | keyof T4
            | keyof T5
            | keyof T6
            | keyof T7
            | keyof T8
            | keyof T9
            | keyof T10
            | keyof T11
            | keyof T12
            | keyof T13
            | keyof T14
            | keyof T15
            | keyof T16
            | keyof T17
            | keyof T18
            | keyof T19,
            keyof T20
          >,
          never
        >
      >);
