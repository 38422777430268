







































































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AccountingBalanceSheet,
  AccountingBalanceSheetCreate,
  AccountingBalanceSheetUpdate,
  DocumentsService,
  DocumentTag,
  getMoment,
} from "@edmp/api";
import { cloneDeep } from "lodash";

import { VConfirmDialog, VForm } from "@/models";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  productsStore,
  coreStore,
  documentsStore,
} from "@/store";
import { SubmitButton } from "@/components/atom/button";
import DatePicker from "@/components/atom/DatePicker.vue";
import { ROUTE_NAMES } from "@/router/routes";
import router from "@/router";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import DocumentCard from "../documents/DocumentCard.vue";

export default defineComponent({
  name: "AccountingBalanceSheetRecoveryData",
  components: {
    SubmitButton,
    DatePicker,
    DocumentCard,
  },
  props: {
    balanceSheet: {
      type: Object as PropType<AccountingBalanceSheet>,
    },
    isReadOnly: { type: Boolean, default: false },
  },

  setup(props, context) {
    const isIs = computed(() => accountingPeriodsStore.isIS);
    const isLmnp = computed(() => accountingPeriodsStore.isLMNP);

    // Accounting period
    const isStartDateEditable = computed(
      () =>
        accountingPeriodsStore.firstAccountingPeriodNotClosed.id ===
        accountingPeriodsStore.currentId
    );

    const startExerciseDateValue: Ref<string | undefined> = ref();
    const endExerciseDateValue: Ref<string | undefined> = ref();

    const accountingPeriodFirst = computed(
      () =>
        cloneDeep(accountingPeriodsStore.accountingPeriods).sort((acc1, acc2) =>
          getMoment(acc1.endAt).diff(acc2.endAt)
        )[0]
    );

    // Suporting document
    const file: Ref<File | undefined> = ref();
    const supportingDocument = computed(() =>
      balanceSheet.value?.supportingDocumentId
        ? documentsStore.getDocument(balanceSheet.value.supportingDocumentId)
        : null
    );
    const deleteSupportingDocument = async () => {
      if (supportingDocument.value) {
        const openConfirm: boolean = await (
          context.refs.confirmDialog as VConfirmDialog
        ).open(
          `Êtes-vous sûr de vouloir supprimer le document ${supportingDocument.value.name} ?`,
          {
            color: "white",
          }
        );
        if (openConfirm) {
          try {
            await documentsStore.deleteDocument(supportingDocument.value.id);
          } catch (error) {
            console.error(error);
          }
        }
      }
    };

    // Accounting balance sheet
    const newAccountingBalanceSheet: ComputedRef<AccountingBalanceSheetCreate> =
      computed(() =>
        router.currentRoute.name === ROUTE_NAMES.AccountingBalanceSheetRecovery
          ? {
              productId: productsStore.currentId,
              type: "recovery",
              isValidated: false,
              isSkipped: false,
              startAt: getMoment(accountingPeriodFirst.value.startAt)
                .subtract(1, "year")
                .format("YYYY-MM-DD"),
              endAt: getMoment(accountingPeriodFirst.value.endAt)
                .subtract(1, "year")
                .format("YYYY-MM-DD"),
              lines: [],
            }
          : {
              productId: productsStore.currentId,
              type: "closure",
              isValidated: false,
              accountingPeriodId: accountingPeriodsStore.currentId,
              lines: [],
            }
      );
    const accountingBalanceSheet = computed(() =>
      props.balanceSheet
        ? accountingBalanceSheetsStore.getAccountingBalanceSheet(
            props.balanceSheet.id
          )
        : cloneDeep(newAccountingBalanceSheet.value)
    );
    const balanceSheet = ref(cloneDeep(accountingBalanceSheet.value));
    watch(accountingBalanceSheet, () => {
      if (accountingBalanceSheet.value) {
        balanceSheet.value = cloneDeep(accountingBalanceSheet.value);
      }
    });

    // Rules
    const minDate = computed(() =>
      getMoment(endExerciseDateValue.value)
        .add("day", -1)
        .add("year", -2)
        .format("YYYY-MM-DD")
    );
    const maxDate = computed(() =>
      getMoment(endExerciseDateValue.value).add("day", -1).format("YYYY-MM-DD")
    );

    // Methods
    const setExerciseDate = () => {
      if (balanceSheet.value?.type === "recovery") {
        startExerciseDateValue.value = balanceSheet.value?.startAt;
        endExerciseDateValue.value = balanceSheet.value?.endAt;
      }
    };

    // Validate
    const isLoadingValidate = ref(false);
    const validate = async () => {
      if (
        balanceSheet.value &&
        (context.refs.form as VForm).validate() &&
        startExerciseDateValue.value &&
        endExerciseDateValue.value
      ) {
        if (balanceSheet.value.type === "recovery") {
          balanceSheet.value.startAt = startExerciseDateValue.value;
          balanceSheet.value.endAt = endExerciseDateValue.value;
        }

        if ((isIs.value || isLmnp.value) && file.value) {
          const infos: DocumentsService.CreateIn["document"] = {
            tags: ["supportingDocument" as DocumentTag],
            metadata: {
              wording: "Reprise bilan",
              issuanceDate: getMoment(
                accountingPeriodFirst.value.startAt
              ).format("YYYY-MM-DD"),
              amountTotal: 0,
              amount: 0,
              amountTax: 0,
            },
            product: { id: productsStore.currentId },
          };
          try {
            isLoadingValidate.value = true;
            const document = await documentsStore.createDocument({
              document: infos,
              file: file.value,
            });
            balanceSheet.value.supportingDocumentId = document.id;

            coreStore.displayFeedback({
              message: "Votre justificatif a bien été enregistré sur Ownily.",
            });
          } catch (err) {
            console.error(err);
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.ERROR,
              message: "Un problème est survenu lors de l'ajout du document.",
            });
            return;
          } finally {
            isLoadingValidate.value = false;
          }
        }

        try {
          isLoadingValidate.value = true;
          if (balanceSheet.value.id) {
            await accountingBalanceSheetsStore.updateAccountingBalanceSheet(
              balanceSheet.value as AccountingBalanceSheetUpdate
            );
          } else {
            await accountingBalanceSheetsStore.createAccountingBalanceSheet(
              balanceSheet.value as AccountingBalanceSheetCreate
            );
          }
        } catch (error) {
          console.log(error);
        } finally {
          isLoadingValidate.value = false;
        }
      }
    };

    watch(
      () => props.balanceSheet,
      () => {
        setExerciseDate();
      },
      { deep: true }
    );

    onBeforeMount(() => {
      setExerciseDate();
    });

    return {
      isIs,
      isLmnp,
      isStartDateEditable,
      startExerciseDateValue,
      endExerciseDateValue,

      file,
      supportingDocument,
      deleteSupportingDocument,
      // Validate
      isLoadingValidate,
      validate,
      minDate,
      maxDate,
    };
  },
});
