import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { documentsService } from "@/services";
import { Document, DocumentsService } from "@edmp/api";

export interface DocumentsState {
  documents: Array<Document>;
  loading: boolean;
}

@Module({
  name: "documents-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class DocumentsStore extends VuexModule implements DocumentsState {
  documents: Array<Document> = [];
  loading = false;

  @Mutation
  reset(): void {
    this.documents = [];
    this.loading = false;
  }

  get getDocument() {
    return (documentId: string): Document | undefined => {
      return this.documents.find(({ id }) => id === documentId);
    };
  }

  get getDocumentByTransaction() {
    return (transactionId: string): Document[] | undefined => {
      return this.documents.filter(
        (document) => document.bankAccount?.transaction?.id === transactionId
      );
    };
  }

  @Mutation
  setDocuments(documents: Document[]): void {
    this.documents = documents;
  }

  @Mutation
  addDocument(document: Document): void {
    this.documents = [...this.documents, document];
  }

  @Mutation
  removeDocument(documentId: string) {
    const documentIndex = this.documents.findIndex(
      (document) => document.id === documentId
    );
    if (documentIndex !== -1) {
      this.documents.splice(documentIndex, 1);
    }
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async fetchDocuments(productId: string): Promise<void> {
    this.setLoading(true);
    this.setDocuments(await documentsService.list({ productId }));
    this.setLoading(false);
  }

  @Action
  async createDocument(
    documentCreate: Omit<DocumentsService.CreateIn, "file"> & { file: File }
  ): Promise<Document> {
    const newDocument = await documentsService.create(documentCreate);
    this.addDocument(newDocument);
    return newDocument;
  }

  @Action
  async addTransactionAttachment(
    documentCreate: Omit<
      DocumentsService.AddAttachmentTransactionIn,
      "file"
    > & {
      file: File;
    }
  ): Promise<Document> {
    const attachment = await documentsService.addTransactionAttachment(
      documentCreate
    );
    this.addDocument(attachment);
    return attachment;
  }
  // Add Documents from Real Estate Asset
  @Action
  async addDocuments(documentId: string) {
    const document = await documentsService.get({ id: documentId });
    this.addDocument(document);
  }
  @Action
  async deleteDocument(id: string) {
    const isDeleted = await documentsService.remove({ id });
    if (isDeleted) {
      this.removeDocument(id);
    }
  }
}
