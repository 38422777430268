// export default function initGTM(): void {
//   (function (l, i) {
//      window["dataLayer"] =  window["dataLayer"] || [];
//      window["dataLayer"].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
//     const f = document.getElementsByTagName("script")[0],
//       j = document.createElement("script"),
//       dl = l != "dataLayer" ? "&l=" + l : "";
//     j.async = true;
//     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
//     const n = document.querySelector("[nonce]");
//     n &&
//       j.setAttribute(
//         "nonce",
//         (n as Element & { nonce: never }).nonce || n.getAttribute("nonce")
//       );
//     if (f.parentNode) {
//       f.parentNode.insertBefore(j, f);
//     }
//   })("dataLayer", "GTM-TH5L8GW");
// }

import {
  OnBoardingEvent,
  OnBoardingEventType,
  SubscriptionEvent,
  SubscriptionEventType,
  PartnersAeraEvent,
  PartnersAreaEventType,
  IndexationEventType,
  IndexationEvent,
} from "@/events";

export default function initGTM(): void {
  const GtmId = "GTM-TH5L8GW";

  const init = (id: string) => {
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = document.getElementsByTagName("script")[0],
      j = document.createElement("script"),
      dl = "&l=dataLayer";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + id + dl;
    const n = document.querySelector("[nonce]");
    n &&
      j.setAttribute(
        "nonce",
        (n as Element & { nonce: never }).nonce || n.getAttribute("nonce")
      );
    if (f.parentNode) {
      f.parentNode.insertBefore(j, f);
    }
  };

  init(GtmId);

  const pushEvent = (eventName: string, eventData: CustomEvent["detail"]) => {
    console.debug(`gtm.pushEvent: ${eventName}`, { eventData });
    window["dataLayer"].push({
      event: eventName,
      value: eventData,
    });
  };

  addEventListener(
    SubscriptionEventType,
    ((e: SubscriptionEvent) => {
      const { status, ...detail } = e.detail;
      pushEvent(`abonnement-${status}`, detail);
    }) as EventListener,
    false
  );

  addEventListener(
    OnBoardingEventType,
    ((e: OnBoardingEvent) => {
      pushEvent(`onBoarding`, e.detail);
    }) as EventListener,
    false
  );

  addEventListener(
    PartnersAreaEventType,
    ((e: PartnersAeraEvent) => {
      pushEvent(`partnersArea`, e.detail);
    }) as EventListener,
    false
  );

  addEventListener(
    IndexationEventType,
    ((e: IndexationEvent) => {
      pushEvent(`indexation`, e.detail);
    }) as EventListener,
    false
  );
}
