import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { IsoDate } from "./Common.model";
import { TaxRegime } from "./TaxRegime.enum";
import moment from "moment";
import { RequireField } from "..";

/**
 * `AccountingPeriodDefinition` — Contain definition of an accounting period year
 */
export interface AccountingPeriodDefinition {
  periodYear: string;
  defaultClosureDuration?: number;
  endOfYearStartsAt?: string;
  openAt: string; 
  closeAt: string; 
}

/**
 * `NewAccountingPeriod` — Data to create an accounting period
 */
export type AccountingPeriodCreate = Omit<AccountingPeriodCreateInternal, "productId">;
export interface AccountingPeriodCreateInternal {
  productId: string;
  firstYear: boolean; // If true, this is the first accounting period for this company.
  startAt: IsoDate;
  endAt: IsoDate;
  taxRegime: TaxRegime;
}

/**
 * `AccountingPeriod` — An accounting period for a company and a fiscal year
 */
export type AccountingPeriod = {
  id: string;
  productId: string;
  startAt: string;
  endAt: string;
  closedAt?: string;
  /**
   * When an accounting period is closed, no changes can be made to operations inside it.
   * Conversely, once a period is closed, final reports can be
   * generated (all reports generated until them would be provisional).
   */
  closed?: boolean;
  firstYear: boolean; // If true, this is the first accounting period for this company.
  taxRegime: TaxRegime;
} & AccountingPeriodDefinition;
export type AccountingPeriodRepository = Omit<AccountingPeriod, "periodYear" | "openAt" | "closeAt">;

export type AccountingPeriodUpdate = RequireField<Partial<Omit<AccountingPeriodUpdateInternal, "productId">>, "id">;
export type AccountingPeriodUpdateInternal = RequireField<
  Partial<Omit<AccountingPeriod, "periodYear" | "openAt" | "closeAt">>,
  "id"
>;

const accountingPeriodSchema = new Schema<AccountingPeriodDocument>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, required: true, indexed: true },
    closed: Boolean,
    startAt: String,
    endAt: String,
    closedAt: String,
    firstYear: Boolean,
    taxRegime: { type: String, required: true, enum: Object.values(TaxRegime) },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingPeriodDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export type AccountingPeriodDocument = AccountingPeriodRepository & Document<string>;

// Name of the collection in third argument
export const AccountingPeriodModel = model<AccountingPeriodDocument>(
  "AccountingPeriod",
  accountingPeriodSchema,
  "AccountingPeriods"
);

export const getFirstAccountingPeriod = (accountingPeriods: AccountingPeriod[]) => {
  const accountingPeriodsSorted = accountingPeriods.sort((acc1, acc2) => moment(acc1.endAt).diff(acc2.endAt));
  return accountingPeriodsSorted[0];
};

export const getLastAccountingPeriod = (accountingPeriods: AccountingPeriod[]) => {
  const accountingPeriodsSorted = accountingPeriods.sort((acc1, acc2) => moment(acc2.endAt).diff(acc1.endAt));
  return accountingPeriodsSorted[0];
};

export const getFirstAccountingPeriodNotClosed = (accountingPeriods: AccountingPeriod[]) => {
  const active = accountingPeriods.filter((acc) => !acc.closed);
  active.sort((acc1, acc2) => moment(acc1.endAt).diff(acc2.endAt));
  return active[0];
};

export const getLastAccountingPeriodNotClosed = (accountingPeriods: AccountingPeriod[]) => {
  const active = accountingPeriods.filter((acc) => !acc.closed);
  active.sort((acc1, acc2) => moment(acc2.endAt).diff(acc1.endAt));
  return active[0];
};

// API
export namespace AccountingPeriodsService {
  export type ListIn = Partial<Pick<AccountingPeriod, "productId">>;
  export type ListOut = AccountingPeriod[];

  export type GetIn = Pick<AccountingPeriod, "id">;
  export type GetOut = AccountingPeriod;

  export type CreateIn = AccountingPeriodCreateInternal;
  export type CreateOut = AccountingPeriod;

  export type UpdateIn = AccountingPeriodUpdate;
  export type UpdateOut = AccountingPeriod;
}
