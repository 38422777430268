import { Schema } from "mongoose";

export type SupportingDocumentTags = "supportingDocument";

export const SupportingDocumentsJsonSchema = {
  issuanceDate: {
    id: { type: "string" },
  },
  wording: {
    id: { type: "string" },
  },
  amount: {
    id: { type: "number" },
  },
  amountTax: {
    id: { type: "number" },
  },
  amountTotal: {
    id: { type: "number" },
  },
};

export const SupportingDocumentsSchema = new Schema<SupportingDocuments>(
  {
    issuanceDate: String,
    wording: String,
    amount: Number,
    amountTax: Number,
    amountTotal: Number,
  },
  { _id: false } // _Id already exist in Document (This document is to add Metadata in document)
);

export interface SupportingDocuments {
  issuanceDate: string; // YYYY-MM-DD
  wording: string; // name
  amount?: number;
  amountTax?: number;
  amountTotal?: number;
}
