var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"taxDeclaration2065"}},[_c('PageHeader',{attrs:{"displayAccountingPeriod":"","displayHelperWithId":_vm.ArticleEnum.TAX_DECLARATION_2065},on:{"onAccountingPeriodChanged":function($event){return _vm.init()}}},[_vm._v(" Déclarations fiscales de la SCI (CERFA 2065) ")]),(_vm.showDev)?_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"section-header-action ml-auto"},[_c('DevButton',{staticClass:"position-right",on:{"click":function($event){return _vm.openTaxDeclarationValidate()}}},[_vm._v(" Voir PDF ")])],1)])]):_vm._e(),_c('div',{staticClass:"section"},[_c('HelpingMessage',{attrs:{"title":"En savoir plus"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" En tant que gérant d'une SCI au régime de l'impôt sur les sociétés, vous devez compléter et transmettre chaque année à l'administration fiscale le formulaire CERFA 2065 et CERFA 2033 permettant de calculer l'imposition sur le bénéfice de votre SCI. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Ownily complète pour vous automatiquement le CERFA 2065 qui reprend votre résultat fiscal imposable et les informations légales de la sCI. Ce cerfa est la base de calcul de l'administration pur établir le montant de votre impôt sur les sociétés (IS) pour l'exercice clôturé. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Retrouvez ici tout au long de l'exercice les montants provisoires sur la base des opérations que vous avez traitées à date sur Ownily. ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Le moment venu, Ownily fera automatiquement la télétransmission de la liasse quand vous aurez dûment enregistré la clôture de l'exercice sur Ownily (service de télétransmission applicable à partir de 2024 pour déclarer l'exercice 2023). ")])])],1),_vm._l((Object.entries(
      _vm.taxDeclaration2065Compute
    )),function(ref){
    var sectionTitle = ref[0];
    var subTitleOrLines = ref[1];
return _c('div',{key:sectionTitle,staticClass:"section"},[_c('h3',{staticClass:"section-header section-header-title"},[_vm._v(_vm._s(sectionTitle))]),(_vm.isArray(subTitleOrLines))?[_c('TaxDeclaration2065Lines',{attrs:{"lines":subTitleOrLines}})]:_vm._l((Object.entries(subTitleOrLines)),function(ref){
    var sectionSubTitle = ref[0];
    var lines = ref[1];
return _c('div',{key:sectionSubTitle,staticClass:"section"},[_c('h4',{staticClass:"section-header section-header-subTitle"},[_vm._v(" "+_vm._s(sectionSubTitle)+" ")]),_c('TaxDeclaration2065Lines',{attrs:{"lines":lines}})],1)})],2)}),_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_c('p',[_vm._v(" Vous devez déclarer le montant de votre impôt IS à payer ainsi que réaliser le paiement vous même par voie électronique depuis votre compte professionnel sur "),_c('a',{attrs:{"href":"https://www.impots.gouv.fr/professionnel","target":"_blank"}},[_vm._v("https://www.impots.gouv.fr/professionnel ")]),_vm._v(". Vous devez avoir créé un compte auparavant. ")]),_c('p',[_vm._v(" Sinon, vous devez en créer un dès maintenant. Si votre montant d'IS à payer est inférieur ou égal à 3000€ vous devez réaliser le paiement en une fois dans la limite du 4ème mois après la date de clôture de l'exercice ou au 15 mai de l'année suivant la clôture pour les exercices qui terminent au 31 décembre. ")])]),_c('div',{staticClass:"section"},[_c('TeletransmitFollowUp')],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '70%' : '70%'},model:{value:(_vm.isOpnTaxDeclarationValidate),callback:function ($$v) {_vm.isOpnTaxDeclarationValidate=$$v},expression:"isOpnTaxDeclarationValidate"}},[(_vm.isOpnTaxDeclarationValidate)?_c('TaxDeclarationValidate',{on:{"close":function($event){_vm.isOpnTaxDeclarationValidate = false}}}):_vm._e()],1),_c('DialogSubscribe',{attrs:{"openModal":_vm.isOpenDialogSubscribe},on:{"update:openModal":function($event){_vm.isOpenDialogSubscribe=$event},"update:open-modal":function($event){_vm.isOpenDialogSubscribe=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }