import { AssembliesService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/assemblies";

class AssembliesService {
  async create(assemblyCreate: AssembliesService.CreateIn) {
    return axios
      .post<AssembliesService.CreateOut>(`${api}`, assemblyCreate)
      .then((res) => {
        return res.data;
      });
  }

  async list(params: AssembliesService.ListIn) {
    return axios
      .get<AssembliesService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: AssembliesService.GetIn) {
    return axios
      .get<AssembliesService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(assemblyUpdate: AssembliesService.UpdateIn) {
    return axios
      .put<AssembliesService.UpdateOut>(
        `${api}/${assemblyUpdate.id}`,
        assemblyUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async invite(assemblyInvite: AssembliesService.InviteIn) {
    return axios
      .post<AssembliesService.InviteOut>(
        `${api}/${assemblyInvite.id}/invite`,
        assemblyInvite
      )
      .then((res) => {
        return res.data;
      });
  }

  async report(assemblyReport: AssembliesService.ReportIn) {
    return axios
      .post<AssembliesService.ReportOut>(
        `${api}/${assemblyReport.id}/report`,
        assemblyReport
      )
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const assembliesService = new AssembliesService();
