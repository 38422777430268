











































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "@vue/composition-api";
import {
  AccountingResult,
  AccountingResultType,
  AccountingResultLine,
  AccountingResultLineType,
  accountingResultLinesMetadata,
  Direction,
  getGlobalAccountFromAccountWithReferenceCounter,
  LedgerAccountEnum,
  TypeReference,
  getMoment,
} from "@edmp/api";
import { flatMap, isArray } from "lodash";

import {
  accountingPeriodsStore,
  accountingResultsStore,
  transactionsStore,
} from "@/store";
import {
  AccountingResultCompute,
  AccountingResultLinesCompute,
} from "@/models";

import PageHeader from "@/components/atom/PageHeader.vue";
import { DevButton } from "@/components/atom/button";
import { HelpingMessage } from "@/components/atom/helping";
import AccountingResultLines from "./AccountingResultLines.vue";
import { useTransactions } from "../transactions/transactions.usable";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "AccountingResult",
  components: {
    PageHeader,
    DevButton,
    HelpingMessage,
    AccountingResultLines,
  },
  setup() {
    const { getAttribute } = useTransactions();

    const isIS = computed(() => accountingPeriodsStore.isIS);
    const accountingResult: Ref<
      AccountingResult<AccountingResultType.CLOSURE> | undefined
    > = ref();
    const categories = computed(() =>
      flatMap(transactionsStore.categoriesList)
    );

    const accountingResultCompute = computed(() => {
      const sectionTitles = {
        "Revenus d'exploitations": [
          LedgerAccountEnum.N706000,
          LedgerAccountEnum.N708300,
          LedgerAccountEnum.N708399,
          LedgerAccountEnum.N758000,
          LedgerAccountEnum.N791400,
          LedgerAccountEnum.N781100,
          AccountingResultLineType.OPERATING_INCOME,
        ],
        "Charges d'exploitations": {
          "Charges de fonctionnements": [
            LedgerAccountEnum.N626000,
            LedgerAccountEnum.N606110,
            LedgerAccountEnum.N606300,
            LedgerAccountEnum.N614010,
            LedgerAccountEnum.N614020,
            LedgerAccountEnum.N622700,
            LedgerAccountEnum.N622710,
            LedgerAccountEnum.N606400,
            LedgerAccountEnum.N625100,
          ],
          "Charges administratives": [
            LedgerAccountEnum.N616600,
            LedgerAccountEnum.N627100,
            LedgerAccountEnum.N622610,
            LedgerAccountEnum.N671000,
            LedgerAccountEnum.N671400,
            LedgerAccountEnum.N623700,
            LedgerAccountEnum.N627200,
            LedgerAccountEnum.N616100,
          ],
          "Charges sur les travaux et immobilisation": [
            LedgerAccountEnum.N615200,
            LedgerAccountEnum.N615210,
            LedgerAccountEnum.N681120,
            LedgerAccountEnum.N681110,
          ],
          "Impôts, taxes et versements assimilés": [
            LedgerAccountEnum.N622000,
            LedgerAccountEnum.N635121,
            LedgerAccountEnum.N635125,
            LedgerAccountEnum.N635110,
          ],
          "": [
            AccountingResultLineType.OPERATING_EXPENSES,
            AccountingResultLineType.OPERATING_RESULT,
          ],
        },
        "Autres revenus et charges": {
          "Autres revenus et charges de la SCIRevenus et perte sur la vente de biens":
            [LedgerAccountEnum.N775000, LedgerAccountEnum.N675000],
          "Produits financiers": [LedgerAccountEnum.N761000],
          "Charges financières": [
            LedgerAccountEnum.N661110,
            LedgerAccountEnum.N661600,
          ],
          "Impôt sur les bénéfices": [
            LedgerAccountEnum.N695000,
            LedgerAccountEnum.N671000,
          ],
          "": [AccountingResultLineType.OTHER_INCOME_AND_EXPENSES],
        },
        "Résultat de la SCI": [AccountingResultLineType.ACCOUNTING_RESULT],
        ...(isIS.value
          ? {
              "Réintégrations fiscales": [
                LedgerAccountEnum.N695000,
                LedgerAccountEnum.N671000,
              ],
              "": [AccountingResultLineType.TAX_RESULT_BEFORE_ALLOCATION],
            }
          : {}),
      };

      const formatLines = (
        linesType: AccountingResultLineType[] | LedgerAccountEnum[]
      ) => {
        return linesType
          .map((lineType) => {
            let isAccountingResultLineNumber = false;
            let isLedgerAccountEnum = false;

            if (
              Object.values(AccountingResultLineType).includes(
                lineType as AccountingResultLineType
              )
            ) {
              isAccountingResultLineNumber = true;
            }
            if (
              Object.values(LedgerAccountEnum).includes(
                lineType as LedgerAccountEnum
              )
            ) {
              isLedgerAccountEnum = true;
            }

            if (isAccountingResultLineNumber) {
              return {
                lineType,
                title: accountingResultLinesMetadata[lineType].name,
                ...accountingResultLinesMetadata[lineType],
                ...accountingResult.value?.lines.find(
                  (line) => line.type === lineType
                ),
                categories: [],
                value: accountingResult.value?.lines.find(
                  (line) => line.type === lineType
                )
                  ? `${
                      accountingResult.value?.lines.find(
                        (line) => line.type === lineType
                      )?.direction === Direction.debit
                        ? "-"
                        : ""
                    }${accountingResult.value?.lines
                      .find((line) => line.type === lineType)
                      ?.amount.toLocaleString()}€`
                  : "0 €",
              };
            }

            if (isLedgerAccountEnum) {
              const categoryMetadata = categories.value.find(
                (category) => category.number === lineType
              );
              if (categoryMetadata) {
                const accountingResultLine = accountingResult.value
                  ? Object.values(accountingResult.value.lines).reduce(
                      (accountingResultLine, line) => {
                        const lineCategory = line.categories.find(
                          (category) => category.account === lineType
                        );
                        if (lineCategory) {
                          accountingResultLine = {
                            type: lineType,
                            amount: lineCategory.balance,
                            direction: lineCategory.balanceDirection,
                            transactionIds: lineCategory.transactionIds,
                            categories: line.categories
                              .filter(
                                (category) =>
                                  (category.reference
                                    ? getGlobalAccountFromAccountWithReferenceCounter(
                                        category.account,
                                        category.reference
                                      )
                                    : undefined) === lineType
                              )
                              .filter((category) => category !== undefined),
                            createdAt: getMoment().toISOString(),
                            updatedAt: getMoment().toISOString(),
                          };
                        }
                        return accountingResultLine;
                      },
                      undefined as AccountingResultLine | undefined
                    )
                  : undefined;

                const accountingResultLineComputeCategories: AccountingResultLinesCompute[number]["categories"] =
                  accountingResultLine
                    ? accountingResultLine.categories
                        .map((category) => {
                          const test = {
                            lineType: category.account,
                            title: `${category.account}`,
                            name: categoryMetadata.name,
                            accounts: [lineType],
                            ...category,
                            value: `${
                              category.balanceDirection === Direction.debit
                                ? "-"
                                : ""
                            }${category.balance.toLocaleString()}€`,
                            attribute: category.reference
                              ? {
                                  name: getAttribute(
                                    category.reference.type,
                                    category.reference.referredId
                                  ),
                                  icon:
                                    category.reference.type ===
                                    TypeReference.realEstateAsset
                                      ? " mdi-home-outline"
                                      : category.reference.type ===
                                        TypeReference.rentalAgreement
                                      ? "mdi-account-key-outline"
                                      : category.reference.type ===
                                        TypeReference.partner
                                      ? "mdi-account-outline"
                                      : category.reference.type ===
                                        TypeReference.realEstateLoan
                                      ? "mdi-home-percent-outline"
                                      : category.reference.type ===
                                        TypeReference.supportingDocument
                                      ? "mdi-folder-outline"
                                      : category.reference.type ===
                                        TypeReference.bankAccount
                                      ? " mdi-bank-outline"
                                      : undefined,
                                }
                              : undefined,
                          };
                          return test;
                        })
                        .filter((category) => category !== undefined)
                    : [];
                const accountingResultLineCompute: AccountingResultLinesCompute[number] =
                  {
                    lineType,
                    title: `${lineType}`,
                    name: categoryMetadata.name,
                    accounts: [
                      lineType,
                      ...accountingResultLineComputeCategories.map(
                        (category) => category.account
                      ),
                    ],
                    ...accountingResultLine,
                    categories: accountingResultLineComputeCategories,
                    value: accountingResultLine
                      ? `${
                          accountingResultLine.direction === Direction.debit
                            ? "-"
                            : ""
                        }${accountingResultLine.amount.toLocaleString()}€`
                      : "0 €",
                  };
                return accountingResultLineCompute;
              }
            }
          })
          .filter(
            (accountingResultCompute) => accountingResultCompute !== undefined
          ) as AccountingResultLinesCompute;
      };

      const accountingResultCompute = Object.entries(sectionTitles).reduce(
        (accountingResultCompute, [title, subTitleOrLinesType]) => {
          if (isArray(subTitleOrLinesType)) {
            accountingResultCompute[title] = formatLines(
              subTitleOrLinesType as AccountingResultLineType[]
            );
          } else {
            const accountingResultSubCompute = Object.entries(
              subTitleOrLinesType
            ).reduce((accountingResultSubCompute, [subTitle, linesType]) => {
              accountingResultSubCompute[subTitle] = formatLines(
                linesType as AccountingResultLineType[]
              );
              return accountingResultSubCompute;
            }, {} as { [sectionSubTitle: string]: AccountingResultLinesCompute } | AccountingResultLinesCompute);

            accountingResultCompute[title] = accountingResultSubCompute;
          }
          return accountingResultCompute;
        },
        {} as AccountingResultCompute
      );

      return accountingResultCompute;
    });

    const beforeTaxesResult = computed(() =>
      (
        Object.values(accountingResultCompute.value).filter(
          (subTitleOrLinesNumber) => isArray(subTitleOrLinesNumber)
        ) as AccountingResultLinesCompute[]
      )
        .flatMap((line) => line)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ isResult, transactionIds, ...line }) => line)
        .find(
          (line) => line.lineType === AccountingResultLineType.ACCOUNTING_RESULT
        )
    );

    // Init
    const init = async () => {
      await transactionsStore.updateCategoriesList();
      await accountingResultsStore.fetchAccountingResults();
      accountingResult.value =
        accountingResultsStore.getAccountingResultByAccountingPeriod(
          accountingPeriodsStore.currentId
        );
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      init,
      isArray,
      accountingResultCompute,
      beforeTaxesResult,
      ROUTE_NAMES,
    };
  },
});
