

















































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Dialog",
  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
    acceptLabel: {
      type: String,
      required: false,
    },
    declineLabel: {
      type: String,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup: (_, context) => {
    const onAccept = () => {
      context.emit("accept");
    };

    const onDecline = () => {
      context.emit("decline");
    };

    const onClose = () => {
      context.emit("close");
    };

    return { onAccept, onDecline, onClose };
  },
});
