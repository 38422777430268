<script>
import { Bar } from "vue-chartjs";
import { get } from "lodash";
import i18n from "@/plugins/i18n";
import moment from "moment";

export default {
  name: "FinancesChart",
  extends: Bar,
  props: {
    balance: {
      type: Object,
      required: true,
    },
  },
  computed: {
    data() {
      const monthsCreditDebitBalance = {};

      const dateStart = moment(this.balance.startAt);
      const dateEnd = moment(this.balance.endAt);
      const monthValues = [];

      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        monthValues.push(dateStart.format("YYYY-MM"));
        dateStart.add(1, "month");
      }

      for (const monthValue of monthValues) {
        const monthlyCreditDebitBalanceKey = Object.keys(
          this.balance.monthly
        ).find((monthKey) => monthValue === monthKey);
        const monthlyCreditDebitBalance =
          this.balance.monthly[monthlyCreditDebitBalanceKey];
        monthsCreditDebitBalance[monthValue] = {
          credit: get(monthlyCreditDebitBalance, "transactionsCredits", 0),
          debit: get(monthlyCreditDebitBalance, "transactionsDebits", 0),
          balance: get(monthlyCreditDebitBalance, "transactionsBalance", 0),
        };
      }

      return {
        labels: Object.keys(monthsCreditDebitBalance).map((key) =>
          i18n
            .d(
              new Date(key),
              moment(this.balance.startAt).year() !== dateEnd.year()
                ? "month-year-short"
                : "month-only",
              "fr-FR"
            )
            .toUpperCase()
        ),
        credits: Object.values(monthsCreditDebitBalance).map(
          (val) => val.credit
        ),
        debits: Object.values(monthsCreditDebitBalance).map(
          (val) => -val.debit
        ),
        balances: Object.values(monthsCreditDebitBalance).map(
          (val) => val.balance
        ),
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
  },
  mounted() {
    this.render();
  },
  watch: {
    data: {
      handler: function () {
        this.render();
      },
      deep: true,
    },
  },
  methods: {
    render: function () {
      this.renderChart(
        {
          labels: this.data.labels,
          datasets: [
            {
              type: "line",
              label: "Solde",
              pointBackgroundColor: "#7EBCFF",
              backgroundColor: "transparent",
              borderColor: "#0084F4",
              borderWidth: 1,
              data: this.data.balances,
              order: 0,
            },
            {
              type: "bar",
              label: "Recettes",
              backgroundColor: "#DFEEFF",
              data: this.data.credits,
              order: 1,
            },
            {
              type: "bar",
              label: "Dépenses",
              backgroundColor: "#1C75C9",
              data: this.data.debits,
              order: 2,
            },
          ],
        },
        this.data.options
      );
    },
  },
};
</script>
