























import { computed, defineComponent } from "@vue/composition-api";

import { transactionsStore } from "@/store";

export default defineComponent({
  name: "TransactionsFiltersDisplay",
  setup() {
    const filters = computed(() => transactionsStore.filters);

    return { filters };
  },
});
