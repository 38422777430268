






















































































import { defineComponent, watch } from "@vue/composition-api";
import { useBudgetInsight } from "@/composables";

import SignTemplate from "@/components/sign/SignTemplate.vue";

export default defineComponent({
  name: "ReconnectBankAccounts",
  components: {
    SignTemplate,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    bankId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      url,
      getUrlReconnect,
      iframeLoadChange,
      biIframe,
      fetchSynchronizeBankAccount,
      changeAccounts,
      stopSynchro,
    } = useBudgetInsight(props.productId, true);

    //Init
    fetchSynchronizeBankAccount();
    getUrlReconnect(props.bankId);

    function cancelAddBankAccount() {
      stopSynchro();
      emit("cancel");
    }

    watch(changeAccounts, (newVal) => {
      if (newVal) {
        // Allow to close this modal in parent component
        emit("accountsChange");
      }
    });

    return {
      getUrlReconnect,
      url,
      iframeLoadChange,
      biIframe,
      cancelAddBankAccount,
    };
  },
});
