





























































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import moment from "moment";
import { SubscriptionsModel } from "@edmp/api";
import { productsStore, coreStore, subscriptionsStore } from "@/store";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "SubscriptionCancelFlow",
  components: {},
  props: {
    subscription: {
      type: Object as PropType<SubscriptionsModel.Subscription>,
      required: true,
    },
    stepSelected: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const product = computed(() => {
      productsStore.switchProduct({ id: props.subscription.productId });
      return productsStore.currentProduct;
    });

    const subscriptionCancelIsLoading: Ref<boolean> = ref(false);
    const cancelReason: Ref<string | undefined> = ref();
    const shortRetention: Ref<boolean> = ref(false);

    /**
     * Actions
     */
    const cancelSubscriptionClose = () => {
      context.emit("update:stepSelected", 1);
      context.emit("cancelSubscriptionClose");
    };
    // Cancel subscription
    const cancel = async () => {
      subscriptionCancelIsLoading.value = true;
      await subscriptionsStore.cancelSubscription({
        id: props.subscription.id,
        cancelAtPeriodEnd: true,
        cancelReason: cancelReason.value,
        retentionPeriod: shortRetention.value ? 30 : undefined,
      });
      coreStore.displayFeedback({
        message: "Votre demande est bien prise en compte.",
      });
      cancelSubscriptionClose();
      context.emit("update:stepSelected", 1);
      subscriptionCancelIsLoading.value = false;
    };

    /**
     * Init
     */
    const init = () => {
      return;
    };

    watch(
      () => props.subscription,
      () => init(),
      { deep: true }
    );
    onBeforeMount(() => init());

    /**
     * Return data
     */
    return {
      product,
      subscriptionCancelIsLoading,
      cancelReason,
      shortRetention,
      moment,
      cancelSubscriptionClose,
      cancel,
      openArticleSubscriptionCancel: () =>
        useCrisp().openArticle(ArticleEnum.SUBSCRIPTION_CONDITION),
      retentionDatasDaysDefault: Math.floor(
        coreStore.config.subscription.retentionDatasDaysDefault / 365
      ),
    };
  },
});
