import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { MetaUser, Scope } from "./User.model";

export enum Method {
  WEB = "web",
}

export type JwtToken = {
  id_token: string;
  token_type: string; // "Bearer"
};

// Authentication Method
export type Auth = {
  hash: string; // Password Hash
  algo: string; // Bcrypt
};

// Interface with fields required or available for Creation of a User Account
export type NewUserAccount = {
  username: string;
  scope: Scope;
  sub: string; // User Id
  method?: Method; // Not used for the moment but allow to connect same User on different platforms
  password?: string;
};

// Interface with fields required or available for Update of a User Account
export type UpdateUserAccount = {
  username?: string;
  password?: string;
};

// Interface with all fields of a User Account
export type UserAccount = Partial<Auth> &
  NewUserAccount & {
    id: string;
    act?: string;
    resetToken?: string;
    resetExpirationDate?: string;
    usernames?: string[]; // List of old username
    suspend?: boolean;
  };

export interface UserAccountResponse {
  username?: string;
  sub: string;
  scope: Scope;
  act?: string;
}
export type UserAccountDocument = UserAccount & Document<string>;

const userAccountSchema = new Schema<UserAccountDocument>(
  {
    _id: { type: String, default: () => ulid() },
    sub: { type: String, index: true },
    username: { type: String, required: true, index: true },
    scope: { type: String, required: true, enum: ["anonymous", "member", "support", "admin", "system"] },
    method: { type: String, enum: Object.values(Method), default: Method.WEB },
    algo: { type: String, required: true, enum: ["bcrypt"] },
    hash: { type: String, required: true },
    resetToken: { type: String },
    resetExpirationDate: { type: Date },
    usernames: [String],
    suspend: { type: Boolean },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

// Index unique on couple 'username'+'method'
userAccountSchema.index(
  {
    username: 1,
    method: 1,
  },
  {
    unique: true,
  }
);

// Name of the collection in third argument
export const UserAccountModel = model<UserAccountDocument>("UserAccount", userAccountSchema, "UserAccounts");

export namespace UserAccountsService {
  export type CreateIn = Required<Pick<UserAccount, "username" | "password">>; // Public
  export type CreateOut = Required<Omit<UserAccountResponse, "act">>;

  export type ListIn = never;
  export type ListOut = Omit<UserAccount, "password" | "resetToken" | "hash" | "algo">[];

  export type GetIn = Pick<UserAccount, "username">;
  export type GetOut = UserAccount;

  export type GetMeIn = never;
  export type GetMeOut = Omit<MetaUser, "extended" | "products" | "bankAccounts" | "subscriptions" | "iat" | "exp">;

  export type UpdateIn = Required<Pick<UserAccount, "username" | "password">> & { userAccount: UpdateUserAccount };
  export type UpdateOut = void;

  export type UpdatePasswordIn = Pick<UserAccount, "password"> & { token: string }; // Public
  export type UpdatePasswordOut = void;

  export type ResetPasswordIn = Pick<UserAccount, "username">; // Public
  export type ResetPasswordOut = void;

  export type LoginIn = Pick<UserAccount, "username" | "password">; // Public
  export type LoginOut = JwtToken;

  export type LogoutIn = never; // Public
  export type LogoutOut = void;

  export type RenewTokenIn = never;
  export type RenewTokenOut = void;

  export type ImpersonateIn = Pick<UserAccount, "sub">;
  export type ImpersonateOut = void;

  export type DepersonateIn = Pick<UserAccount, "sub">;
  export type DepersonateOut = void;

  export type DeleteIn = Pick<UserAccount, "username">;
  export type DeleteOut = void;

  export type DeleteRegisterIn = Pick<UserAccount, "sub">;
  export type DeleteRegisterOut = void;
}
