

































































































































































































































import { SubscriptionsModel } from "@edmp/api/";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { SubscriptionPlansItem } from "./Subscriptions.const";

export default defineComponent({
  name: "SubscriptionsPlanItem",
  props: {
    isLoading: { type: Boolean, required: true },
    isEditable: { type: Boolean, default: false },
    plan: {
      type: Object as PropType<SubscriptionPlansItem>,
      required: true,
    },
    planSelected: {
      type: Object as PropType<SubscriptionsModel.PlanParams>,
      required: true,
    },
  },
  setup(props, context) {
    /**
     * ProductData
     */
    const selectedPlan: Ref<SubscriptionsModel.PlanParams> = ref(
      props.planSelected
    );
    const availableDiscount: Ref<number> = ref(0);
    const price = computed(() => {
      const price: {
        priceHT: string;
        priceTTC: string;
        priceDiscountHT?: string;
        priceDiscountTTC?: string;
      } = {
        priceHT: "",
        priceTTC: "",
      };
      if (props.plan.product) {
        const taxRatePercentage = props.plan.product.taxRate.percentage;

        for (const productPrice of props.plan.product.prices) {
          if (productPrice.durationType == props.planSelected.durationType) {
            price.priceHT = (productPrice.amount / 100).toFixed(2);

            const TTC = (productPrice.amount * taxRatePercentage) / 100;
            price.priceTTC = ((TTC + productPrice.amount) / 100).toFixed(2);

            if (props.plan.product.coupon?.percent_off) {
              const discountPrice =
                productPrice.amount -
                (productPrice.amount * props.plan.product.coupon?.percent_off) /
                  100;

              price.priceDiscountHT = (discountPrice / 100).toFixed(2);
              const discountPriceTTC =
                (discountPrice * taxRatePercentage) / 100;
              price.priceDiscountTTC = (
                (discountPriceTTC + discountPrice) /
                100
              ).toFixed(2);
            }
          }
        }
      }
      return price;
    });

    /**
     * Action
     */
    const selectPlan = () => {
      selectedPlan.value.type = props.plan
        .type as SubscriptionsModel.PlanTypeParams;
      context.emit("selectPlan", selectedPlan.value);
    };

    /**
     * Mutations
     */
    // Recalculate coupon number if used or not
    watch(
      () => selectedPlan.value.couponUse,
      (val: boolean) => {
        val ? availableDiscount.value-- : availableDiscount.value++;
      }
    );
    watch(
      () => props.planSelected,
      () =>
        (selectedPlan.value = Object.assign(
          selectedPlan.value,
          props.planSelected
        )),
      { deep: true }
    );

    // Init
    const init = () => {
      if (props.plan.product && props.plan.product.coupon) {
        availableDiscount.value = props.plan.product.coupon.available;
        selectedPlan.value.couponUse =
          props.plan.product.coupon.available !== 0 ? true : false;
      }
    };

    watch(
      () => props.plan.product,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => {
      init();
    });

    /**
     * Return datas
     */
    return {
      price,
      availableDiscount,
      PlanTypeParams: SubscriptionsModel.PlanTypeParams,
      DurationTypeParams: SubscriptionsModel.DurationTypeParams,
      selectedPlan,
      selectPlan,
    };
  },
});
