import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ActivitiesModel, ActivitiesService } from "@edmp/api";
import { activitiesService } from "@/services";

export interface ActivitiesState {
  activities: Array<ActivitiesModel.Activity>;
  currentId: string;
  loading: boolean;
}

@Module({
  name: "activities-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class ActivitiesStore extends VuexModule implements ActivitiesState {
  activities: Array<ActivitiesModel.Activity> = [];
  currentId = "";
  loading = false;

  get currentActivity() {
    return this.activities.find(({ id }) => id === this.currentId);
  }

  @Mutation
  reset(): void {
    this.activities = [];
    this.currentId = "";
    this.loading = false;
  }

  @Mutation
  setActivities(activities: ActivitiesModel.Activity[]): void {
    this.activities = activities;
  }

  @Mutation
  setCurrentId(id: string): void {
    this.currentId = id;
  }

  @Mutation
  addActivity(activity: ActivitiesModel.Activity): void {
    this.activities = [...this.activities, activity];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async switchActivity(id: string) {
    this.setCurrentId(id);
  }

  @Action
  async fetchActivities(productId: string): Promise<void> {
    this.setLoading(true);

    const activity = await activitiesService.list({
      productId,
    });
    this.setActivities(activity);
    this.setLoading(false);
  }

  @Action
  async updateActivity(
    activityUpdate: ActivitiesService.UpdateIn
  ): Promise<void> {
    const activityUpdated = await activitiesService.update(activityUpdate);
    this.fetchActivities(activityUpdated.productId);
    await this.switchActivity(activityUpdated.id);
  }
}
