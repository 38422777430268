var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"registerActivityDetailsIR",on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',[_c('p',{staticClass:"activity-text"},[_vm._v(" Vous pouvez saisir librement les informations ci-dessous : ")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"id":"name","rules":[
          function () { return !!_vm.productForm.name || 'Renseignez le nom de votre activité'; } ],"name":"name","outlined":"","type":"text"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("Nom de votre activité "),_c('span',{staticClass:"required"},[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.productForm.name),callback:function ($$v) {_vm.$set(_vm.productForm, "name", $$v)},expression:"productForm.name"}}),(_vm.activityRegistered)?_c('v-text-field',{ref:"siretInput",staticClass:"ml-2",attrs:{"id":"siret","rules":[
          !_vm.productForm.activity.siret ||
            (!!_vm.productForm.activity.siret &&
              _vm.onlyNumberRule(_vm.productForm.activity.siret) &&
              _vm.productForm.activity.siret.length === 14) ||
            'Le format de votre SIRET est incorrect' ],"label":"SIRET","name":"siret","outlined":"","type":"text"},model:{value:(_vm.productForm.activity.siret),callback:function ($$v) {_vm.$set(_vm.productForm.activity, "siret", $$v)},expression:"productForm.activity.siret"}}):_vm._e()],1),_c('p',{staticClass:"activity-text"},[_vm._v("Avez-vous déclaré l'an passé ?")]),_c('div',{staticClass:"mb-6"},[_c('v-btn',{staticClass:"activity-btn",class:_vm.previousYearDeclared === true ? 'active' : '',attrs:{"id":"track-previousYearDeclared-yes","depressed":"","large":""},on:{"click":function($event){_vm.previousYearDeclared = true}}},[_vm._v(" Oui ")]),_c('v-btn',{staticClass:"activity-btn",class:_vm.previousYearDeclared === false ? 'active' : '',attrs:{"id":"track-previousYearDeclared-no","depressed":"","large":""},on:{"click":function($event){_vm.previousYearDeclared = false}}},[_vm._v(" Non ")])],1),(_vm.previousYearDeclared === true)?_c('div',[_c('p',{staticClass:"activity-text mb-1"},[_vm._v(" Choisissez votre première année sur Ownily : ")]),_c('v-radio-group',{staticClass:"mt-0",attrs:{"rules":[
          function () { return !!_vm.exerciceStartSelect || 'Sélectionnez une année d\'exercice'; } ],"row":""},model:{value:(_vm.exerciceStartSelect),callback:function ($$v) {_vm.exerciceStartSelect=$$v},expression:"exerciceStartSelect"}},_vm._l((_vm.exerciceStartChoices),function(exercice){return _c('v-radio',{key:exercice,attrs:{"label":("" + exercice),"value":exercice,"id":("exerciceStartSelect-" + exercice)}})}),1)],1):_vm._e(),(_vm.previousYearDeclared !== undefined)?_c('div',[(_vm.previousYearDeclared === false)?_c('p',{staticClass:"activity-text mb-6"},[_vm._v(" Choisissez votre date de début d'activité : ")]):_vm._e(),(_vm.exerciceStartSelect)?_c('DatePicker',{attrs:{"disabled":_vm.previousYearDeclared === true,"hint":"DD/MM/YYYY format","min":_vm.rules.exerciceDateMin(),"max":_vm.rules.exerciceDateMax(),"dense":"","label":"Date de début","required":""},model:{value:(_vm.startExerciceDateValue),callback:function ($$v) {_vm.startExerciceDateValue=$$v},expression:"startExerciceDateValue"}}):_vm._e()],1):_vm._e()]),(_vm.exerciceStartSelect)?_c('v-checkbox',{staticClass:"switchGerant mt-1",attrs:{"id":"track-accept_owner","rules":[
      function () { return !!_vm.acceptationGerantStatutaire ||
        'Cette information est obligatoire. Ownily veut s\'assurer que vous êtes autorisé(e) à agir pour le compte de la SCI précédemment renseignée.'; } ],"color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Je certifie être autorisé(e) à agir, au nom de la société désignée, en qualité de gérant statutaire. ")])]},proxy:true}],null,false,2869933540),model:{value:(_vm.acceptationGerantStatutaire),callback:function ($$v) {_vm.acceptationGerantStatutaire=$$v},expression:"acceptationGerantStatutaire"}}):_vm._e(),_c('v-scroll-x-transition',[(!!_vm.acceptationGerantStatutaire)?_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" En cochant cette case vous attestez être autorisé(e) à agir pour la SCI précédemment renseignée. ")]):_vm._e()],1),_c('v-row',{staticClass:"align-end ma-0 my-4"},[_c('v-spacer'),(_vm.exerciceStartSelect)?_c('v-btn',{staticClass:"px-6",attrs:{"depressed":"","color":"primary","type":"submit","id":"track-next_4","loading":_vm.validateInProgress},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_vm._v(" Suivant "),_c('v-progress-circular',{staticClass:"ml-3",attrs:{"size":"20","width":"2","indeterminate":""}})]},proxy:true}],null,false,3529263002)},[_vm._v(" Suivant ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }