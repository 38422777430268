




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Title",
  props: {
    text: String,
  },
});
