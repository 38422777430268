














import {
  accountingPeriodsStore,
  productsStore,
  taskActivityStore,
} from "@/store";
import { TaskActivityStatus } from "@edmp/api";
import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import TaskActivityGroup from "./group/TaskActivityGroup.vue";

export default defineComponent({
  name: "TaskActivityPage",
  components: { TaskActivityGroup },
  setup() {
    const taskActivityGroups = computed(() => {
      return taskActivityStore.startWellTaskGroup;
    });

    onBeforeMount(async () => {
      await accountingPeriodsStore.fetchAccountingPeriods(
        productsStore.currentId
      );
      await taskActivityStore.fetchTaskActivityGroups({});
    });

    const displayTaskActivityForFirstTaskGroupNotCompleted = computed(() =>
      taskActivityGroups.value.map((taskGroup, index, taskGroups) => {
        if (
          taskGroups.every(
            (taskGroup) => taskGroup.status === TaskActivityStatus.WAITING
          )
        ) {
          return false;
        }
        if (taskGroup.status !== TaskActivityStatus.COMPLETED) {
          if (index !== 0) {
            return (
              taskGroups[index - 1].status === TaskActivityStatus.COMPLETED
            );
          }
          return true;
        }
        return false;
      })
    );

    return {
      taskActivityGroups,
      displayTaskActivityForFirstTaskGroupNotCompleted,
    };
  },
});
