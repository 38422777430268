import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  SubscriptionsModel,
  SubscriptionsService,
  StripeService,
} from "@edmp/api";
import { subscriptionsService } from "@/services";
import { productsStore } from "..";

export interface SubscriptionsState {
  subscriptions: Array<SubscriptionsModel.Subscription>;
  loading: boolean;
}

@Module({
  name: "subscriptions-store",
  namespaced: true,
  dynamic: true,
  store,
})
export class SubscriptionsStore
  extends VuexModule
  implements SubscriptionsState
{
  subscriptions: Array<SubscriptionsModel.Subscription> = [];
  loading = false;

  get getSubscription() {
    return (
      subscriptionId: string
    ): SubscriptionsModel.Subscription | undefined => {
      return this.subscriptions.find(({ id }) => id === subscriptionId);
    };
  }

  get getSubscriptionByProduct() {
    return (productId: string): SubscriptionsModel.Subscription | undefined => {
      return this.subscriptions.find(({ productId: id }) => id === productId);
    };
  }

  get getCurrentSubscription(): SubscriptionsModel.Subscription | undefined {
    return this.getSubscriptionByProduct(productsStore.currentId);
  }

  get isFree(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Free
    );
  }

  get isSolo(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Solo
    );
  }

  get isBasic(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Basic
    );
  }

  get isPremium(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Premium
    );
  }

  get isOptimum(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Optimum
    );
  }

  get isLMNP(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.LMNP
    );
  }

  get isTest(): boolean | undefined {
    return (
      this.getCurrentSubscription?.plan.type ===
      SubscriptionsModel.PlanType.Test
    );
  }

  @Mutation
  reset(): void {
    this.subscriptions = [];
    this.loading = false;
  }

  @Mutation
  setSubscriptions(subscriptions: SubscriptionsModel.Subscription[]): void {
    this.subscriptions = subscriptions;
  }

  @Mutation
  addSubscription(subscription: SubscriptionsModel.Subscription): void {
    this.subscriptions = [...this.subscriptions, subscription];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  replaceSubscription(subscription: SubscriptionsModel.Subscription): void {
    const index = this.subscriptions.findIndex(
      ({ id }) => id == subscription.id
    );
    this.subscriptions.splice(index, 1);
    this.subscriptions.push(subscription);
  }

  @Action
  async fetchSubscriptions(): Promise<SubscriptionsModel.Subscription[]> {
    this.setLoading(true);
    const subscriptions = await subscriptionsService.list({});
    this.setSubscriptions(subscriptions);
    this.setLoading(false);
    return subscriptions;
  }

  @Action
  async updateSubscription(
    value: Omit<SubscriptionsService.UpdateIn, "subscriptionId">
  ) {
    return await subscriptionsService.update(value);
  }

  @Action({ rawError: true })
  async cancelSubscription(
    cancel: SubscriptionsService.CancelIn
  ): Promise<SubscriptionsModel.Subscription> {
    this.setLoading(true);
    const cancelSubscription = await subscriptionsService.cancel(cancel);
    this.replaceSubscription(cancelSubscription);
    this.setLoading(false);
    return cancelSubscription;
  }

  @Action
  async getInvoices(subscriptionId: string) {
    return await subscriptionsService.getInvoices({ id: subscriptionId });
  }

  @Action
  async getMultiProductsDiscount(
    multiProductsDiscount: SubscriptionsService.GetMultiProductsDiscountIn
  ) {
    return await subscriptionsService.getMultiProductsDiscount(
      multiProductsDiscount
    );
  }

  @Action
  async checkUpdatePermission(
    checkUpdatePermission: SubscriptionsService.CheckUpdatePermissionIn
  ) {
    return subscriptionsService.checkUpdatePermission(checkUpdatePermission);
  }

  @Action
  async getPortal(portalSessionCreate: StripeService.CreatePortalSessionIn) {
    return subscriptionsService.stripe.createPortalSession(portalSessionCreate);
  }

  @Action
  async getProducts(params: StripeService.ListProductsIn) {
    return subscriptionsService.stripe.listProducts(params);
  }
}
