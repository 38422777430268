import { render, staticRenderFns } from "./RentalAgreementWithoutRealEstateAssetModal.vue?vue&type=template&id=72023368&scoped=true&"
import script from "./RentalAgreementWithoutRealEstateAssetModal.vue?vue&type=script&lang=ts&"
export * from "./RentalAgreementWithoutRealEstateAssetModal.vue?vue&type=script&lang=ts&"
import style0 from "./RentalAgreementWithoutRealEstateAssetModal.vue?vue&type=style&index=0&id=72023368&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72023368",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardTitle,VCol,VIcon,VSelect,VSpacer})
