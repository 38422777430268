var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"partnersArea-view"},[_c('div',{staticClass:"partnersArea-view-header d-flex justify-space-between"},[_c('PageHeader',[_vm._v("Nos partenaires à votre service "),_c('BadgeNew')],1),_c('v-menu',{attrs:{"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button px-6 py-2 ml-2 mr-2",attrs:{"text":""},model:{value:(_vm.filterSelected),callback:function ($$v) {_vm.filterSelected=$$v},expression:"filterSelected"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-tune-vertical")]),_c('h5',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.filterSelected.name))]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.selectFilter),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeFilterSelected(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('div',{staticClass:"section"},[_c('HelpingMessage',{attrs:{"title":"A propos de l'Espace Partenaires","opened":true}},[_c('p',{staticClass:"mb-4"},[_c('b',[_vm._v("Découvrez notre Espace Partenaires : élargissez votre expérience Ownily ! ")]),_c('br'),_c('br'),_vm._v("Approfondissez votre expérience Ownily avec des partenaires de choix. "),_c('br'),_vm._v("Accédez à des conseils spécialisés en investissement, rénovation énergétique et comptabilité, tous disponibles pour enrichir votre stratégie patrimoniale. "),_c('br'),_vm._v(" Explorez et bénéficiez de services complémentaires directement sur notre plateforme. ")])])],1),_c('div',[_c('v-row',{staticClass:"mt-3",attrs:{"id":"partners-area-list"}},_vm._l((_vm.dataFilter),function(card,index){return _c('v-col',{key:index,attrs:{"cols":"12","xl":"3","lg":"4","md":"6","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"card d-flex flex-column rounded-lg height-100",class:{
              'on-hover': hover,
            },attrs:{"elevation":hover ? 8 : 1}},[_c('div',{staticClass:"container-img-tag"},[_c('img',{staticClass:"img-partners",attrs:{"src":hover
                    ? require(("@/assets/" + (card.image_hover)))
                    : require(("@/assets/" + (card.image)))}}),_vm._l((card.categories),function(category){return _c('div',{key:category,staticClass:"tag align-end"},[_c('div',{staticClass:"p-tag font-weight-medium mb-4",class:_vm.applyClass(category)},[_vm._v(" "+_vm._s(category)+" ")])])})],2),_c('v-card-title',[_vm._v(_vm._s(card.title))]),_c('v-card-text',{staticClass:"text--primary"},[_c('div',[_vm._v(" "+_vm._s(card.resume)+" ")])]),_c('div',{staticClass:"d-flex flex-grow justify-end align-end mt-auto"},[_c('v-btn',{staticClass:"text-xl mb-3 mr-3 v-size--large",attrs:{"outlined":"","color":"#1A4584","href":card.link,"target":"_blank"},on:{"click":function($event){return _vm.click(card.code)}}},[_vm._v(" "+_vm._s(card.btn)+" ")])],1)],1)]}}],null,true)})],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }