
































































import { HelpingMessage } from "@/components/atom/helping";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { ability } from "@/services";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  realEstateAssetsStore,
  realEstateLoansStore,
  usersStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { onlyNumber } from "@/utils";
import { ForbiddenError, subject } from "@casl/ability";
import { TaxRegime, ActivitiesModel, ProductsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import Accounting from "../activities/Accounting.vue";
import BankAccounts from "../activities/bankAccounts/BankAccounts.vue";
import BankAccountsLmnp from "../activities/bankAccounts/BankAccountsLmnp.vue";
import ActivityCreateInfosModal from "../activities/ActivityCreateInfosModal.vue";
import ProductMail from "../activities/ProductMail.vue";
import AccountingPeriod from "../activities/Accounting.vue";
import PartnersModal from "../activities/partners/modals/PartnerModal.vue";
import PartnersInvitationModal from "../activities/partners/modals/PartnersInvitationModal.vue";
import Partners from "../activities/partners/Partners.vue";
import {
  default as RealEstateLoans,
  default as RealEstateLoansTest,
} from "../activities/realEstateLoans/RealEstateLoans.vue";
import RealEstates from "../activities/RealEstates.vue";
import SubscriptionCard from "../subscriptions/list/SubscriptionCard.vue";
import Title from "../title/Title.vue";
import MyBusinessDeclarationActivity from "./MyBusinessDeclarationActivity.vue";
import MyBusinessGeneralInfo from "./MyBusinessGeneralInfo.vue";
import MyBusinessOperatorInfo from "./MyBusinessOperatorInfo.vue";

export default defineComponent({
  name: "MyBusiness",
  components: {
    Title,
    Accounting,
    BankAccounts,
    BankAccountsLmnp,
    RealEstates,
    RealEstateLoans,
    MyBusinessOperatorInfo,
    MyBusinessGeneralInfo,
    AccountingPeriod,
    MyBusinessDeclarationActivity,
    ProductMail,
    SubscriptionCard,
    Partners,
    PartnersModal,
    PartnersInvitationModal,
    ActivityCreateInfosModal,
    HelpingMessage,
    RealEstateLoansTest,
  },
  props: {
    productId: { type: String },
    realEstateLoanId: { type: String, required: false },
    tabIndex: { type: String, required: false },
  },

  setup(props, context) {
    const tabItemIndex = ref(props.tabIndex ? parseInt(props.tabIndex) : 0);
    const isLoading: Ref<boolean> = ref(false);
    const openActivityCreateInfosModal = ref(false);
    const goCreate = () => {
      ForbiddenError.from(ability).throwUnlessCan(
        "add",
        subject("Product", {})
      );
      openActivityCreateInfosModal.value = true;
    };

    const activity = computed(() => {
      // On affiche la page en lecture/modification
      return Object.assign(
        {
          address: { street: "", city: "", zip: "" },
        },
        {
          ...productsStore.currentProduct?.activity,
        } as ActivitiesModel.Activity
      );
    });

    // Subscription
    const redirectUri = computed(() => `products/${activity.value.productId}`);

    // Biens
    const realEstateNew = () => {
      try {
        ForbiddenError.from(ability).throwUnlessCan(
          "addRealEstateAsset",
          subject("RentalManagement", {
            size: realEstateAssetsStore.realEstateAssets.length,
          })
        );
        const productId = productsStore.currentId;
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          params: { productId, realEstateAssetId: "new" },
        });
      } catch (error) {
        if (error instanceof ForbiddenError) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.WARNING,
            message: error.message,
          });
        }
      }
    };

    // Partner
    const connectedUser = computed(() => usersStore.loggedInUser);

    //Loan
    const realEstateLoanList = computed(() =>
      realEstateLoansStore.getRealEstateLoansByProductId(
        props.productId as string
      )
    );
    const selectedLoan = ref(undefined);
    function selectLoan(loan) {
      selectedLoan.value = loan;
      openLoanModal.value = true;
    }

    function closeLoanModal() {
      openLoanModal.value = false;
      selectedLoan.value = undefined;
    }
    function validateLoanModal() {
      closeLoanModal();
    }
    const openLoanModal: Ref<boolean> = ref(false);
    const currentTaxRegime: Ref<string | undefined> = ref(
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );

    // Focus
    watch(
      () => context.root.$route.query,
      (newVal) => {
        try {
          context.root.$vuetify.goTo(`#${newVal["focus"].toString()}`, {
            offset: 60,
          });
          context.root.$route.query["focus"] = "";
        } catch (err) {
          // do nothing
        }
      },
      { deep: true }
    );
    watch(
      () => [realEstateLoanList, props.realEstateLoanId],
      () => {
        if (props.realEstateLoanId) {
          const realEstateLoan = realEstateLoanList.value.find(
            (realEstateLoan) => realEstateLoan.id === props.realEstateLoanId
          );
          if (realEstateLoan) {
            selectLoan(realEstateLoan);
          }
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      if (context.root.$route.query["focus"]) {
        setTimeout(() => {
          try {
            context.root.$vuetify.goTo(
              `#${context.root.$route.query["focus"].toString()}`,
              {
                offset: 60,
              }
            );
            context.root.$route.query["focus"] = "";
          } catch (err) {
            // do nothing
          }
        }, 500);
      }
    });

    // Init
    async function init() {
      isLoading.value = true;

      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      if (
        productsStore.currentProduct?.status ===
        ProductsModel.ProductStatus.pending
      ) {
        context.root.$router.push({
          name: ROUTE_NAMES.ActivityContinue,
        });
      }

      if (
        router.currentRoute.name === ROUTE_NAMES.MyBusiness &&
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          TaxRegime.LMNP_2031
      ) {
        router.replace({ name: ROUTE_NAMES.Activities });
      }

      isLoading.value = false;
    }

    watch(
      () => [
        productsStore.currentProduct,
        accountingPeriodsStore.currentAccountingPeriod,
      ],
      () => init(),
      { deep: true }
    );

    const validateCreation = (newRealEstateAssetId) => {
      if (props.productId) {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstate,
          params: {
            productId: props.productId,
            realEstateAssetId: newRealEstateAssetId,
          },
        });
      }
    };
    onBeforeMount(() => init());

    return {
      isLoading,
      goCreate,
      connectedUser,
      activity,
      redirectUri,
      onlyNumberRule: onlyNumber,
      openArticleSubscriptionCondition: () =>
        useCrisp().openArticle(ArticleEnum.COMPANY_SETTING),
      realEstateNew,
      openActivityCreateInfosModal,
      currentTaxRegime,
      TaxRegime,
      openLoanModal,
      closeLoanModal,
      validateLoanModal,
      selectedLoan,
      selectLoan,
      validateCreation,
      tabItemIndex,
    };
  },
});
