


































































import { computed, ComputedRef, defineComponent } from "@vue/composition-api";
import i18n from "@/plugins/i18n";
import { DataTableHeader } from "vuetify";
import { get } from "lodash";
import { Dictionary } from "vue-router/types/router";
import {
  AccountingCompute,
  AccountingComputeCategories,
  Direction,
} from "@edmp/api";
import Moment from "moment";

export default defineComponent({
  name: "AccountingMonthsTable",
  props: {
    balance: {
      type: Object as () => AccountingCompute,
      required: true,
    },
  },
  setup(props) {
    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      if (!props.balance) return [];
      const startDate = new Date(props.balance.startAt);
      const endDate = new Date(props.balance.endAt);
      const months: DataTableHeader[] = [];

      for (let i = startDate.getMonth(); i <= endDate.getMonth(); i++) {
        // Loop month by month between startAt and endAt
        const date = new Date(startDate);
        date.setMonth(i);

        // We Break if month is after current date (because transactions will be empty...)
        if (Moment().isBefore(Moment(date), "month")) {
          break;
        }
        // Retrieve value else set value to Zero
        months.push({
          text: i18n.d(date, "month-only", "fr-FR").toUpperCase(),
          align: "center" as "start" | "center" | "end",
          value: `monthly.${date.getFullYear()}-${
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : "0" + (date.getMonth() + 1)
          }`,
        });
      }
      return [
        {
          text: "",
          align: "start",
          value: "name",
        },
        ...months,
        { text: "", value: "data-table-expand" },
      ];
    });

    const bodyAnnuals: ComputedRef<
      {
        name: string;
        canExpand: boolean;
        monthly: Dictionary<number>;
        categories?: Array<AccountingComputeCategories>;
      }[]
    > = computed(() => {
      if (!props.balance) return [];
      return [
        {
          name: "Recettes",
          canExpand: true,
          monthly: Object.fromEntries(
            Object.entries<{ transactionsCredits: number }>(
              props.balance.monthly
            ).map(([key, value]) => [key, value.transactionsCredits])
          ),
          categories: props.balance.categories
            ? props.balance.categories.filter(
                (c) => c.direction === Direction.credit
              )
            : [],
        },
        {
          name: "Depenses",
          canExpand: true,
          monthly: Object.fromEntries(
            Object.entries<{ transactionsDebits: number }>(
              props.balance.monthly
            ).map(([key, value]) => [key, -value.transactionsDebits])
          ),
          categories: props.balance.categories.filter(
            (c) => c.direction === "debit"
          ),
        },
        {
          name: "Solde",
          canExpand: false,
          monthly: Object.fromEntries(
            Object.entries<{ transactionsBalance: number }>(
              props.balance.monthly
            ).map(([key, value]) => [key, value.transactionsBalance])
          ),
        },
      ];
    });

    return {
      get,
      headers,
      bodyAnnuals,
    };
  },
});
