import { getModule } from "vuex-module-decorators";
import "./config";

import { AccountingsState, AccountingsStore } from "./modules/Accounting.store";
import {
  AccountingBalanceSheetState,
  AccountingBalanceSheetStore,
} from "./modules/AccountingBalanceSheet.store";
import {
  AccountingCarryForwardsState,
  AccountingCarryForwardsStore,
} from "./modules/AccountingCarryForward.store";
import {
  AccountingPeriodsState,
  AccountingPeriodsStore,
} from "./modules/AccountingPeriods.store";
import {
  AccountingResultsState,
  AccountingResultsStore,
} from "./modules/AccountingResult.store";
import { ActivitiesState, ActivitiesStore } from "./modules/Activities.store";
import { AssembliesState, AssembliesStore } from "./modules/Assemblies.store";
import {
  BankAccountsState,
  BankAccountsStore,
} from "./modules/BankAccounts.store";
import { CoreState, CoreStore } from "./modules/Core.store";
import { DocumentsState, DocumentsStore } from "./modules/Documents.store";
import {
  FixedAssetsState,
  FixedAssetsStore,
} from "./modules/FixedAssets.store";
import {
  FixedAssetAmortisationsState,
  FixedAssetAmortisationsStore,
} from "./modules/FixedAssetAmortisations.store";
import { PartnersState, PartnersStore } from "./modules/Partners.store";
import { ProductsState, ProductsStore } from "./modules/Products.store";
import {
  RealEstateAmortisationsState,
  RealEstateAmortisationsStore,
} from "./modules/RealEstateAmortisations.store";
import {
  RealEstateAssetsState,
  RealEstateAssetsStore,
} from "./modules/RealEstateAssets.store";
import {
  RealEstateLoansState,
  RealEstateLoansStore,
} from "./modules/RealEstateLoans.store";
import {
  RentalAgreementsState,
  RentalAgreementsStore,
} from "./modules/RentalAgreements.store";
import { TenantsState, TenantsStore } from "./modules/Tenants.store";
import {
  RentalBuildingsState,
  RentalBuildingsStore,
} from "./modules/RentalBuildings.store";
import {
  SubscriptionsState,
  SubscriptionsStore,
} from "./modules/Subscriptions.store";
import {
  TransactionsState,
  TransactionsStore,
} from "./modules/Transactions.store";
import {
  TaskActivityState,
  TaskActivityStore,
} from "./modules/TaskActivity.store";
import { UsersState, UsersStore } from "./modules/User.store";

export interface RootState {
  accountings: AccountingsState;
  accountingBalanceSheets: AccountingBalanceSheetState;
  accountingCarryForwards: AccountingCarryForwardsState;
  accountingPeriods: AccountingPeriodsState;
  accountingResults: AccountingResultsState;
  activities: ActivitiesState;
  assemblies: AssembliesState;
  bankAccounts: BankAccountsState;
  core: CoreState;
  documents: DocumentsState;
  fixedAssets: FixedAssetsState;
  fixedAssetAmortisations: FixedAssetAmortisationsState;
  partners: PartnersState;
  products: ProductsState;
  realEstateAmortisations: RealEstateAmortisationsState;
  realEstateAssets: RealEstateAssetsState;
  realEstateLoans: RealEstateLoansState;
  rentalAgreements: RentalAgreementsState;
  tenants: TenantsState;
  rentalBuildings: RentalBuildingsState;
  subscriptions: SubscriptionsState;
  taskActivityState: TaskActivityState;
  transactions: TransactionsState;
  users: UsersState;
}

export const accountingsStore = getModule(AccountingsStore);
export const accountingBalanceSheetsStore = getModule(
  AccountingBalanceSheetStore
);
export const accountingCarryForwardsStore = getModule(
  AccountingCarryForwardsStore
);
export const accountingPeriodsStore = getModule(AccountingPeriodsStore);
export const accountingResultsStore = getModule(AccountingResultsStore);
export const activitiesStore = getModule(ActivitiesStore);
export const assembliesStore = getModule(AssembliesStore);
export const bankAccountsStore = getModule(BankAccountsStore);
export const coreStore = getModule(CoreStore);
export const documentsStore = getModule(DocumentsStore);
export const fixedAssetsStore = getModule(FixedAssetsStore);
export const fixedAssetAmortisationsStore = getModule(
  FixedAssetAmortisationsStore
);
export const partnersStore = getModule(PartnersStore);
export const productsStore = getModule(ProductsStore);
export const realEstateAmortisationsStore = getModule(
  RealEstateAmortisationsStore
);
export const realEstateAssetsStore = getModule(RealEstateAssetsStore);
export const realEstateLoansStore = getModule(RealEstateLoansStore);
export const rentalAgreementsStore = getModule(RentalAgreementsStore);
export const tenantsStore = getModule(TenantsStore);
export const rentalBuildingsStore = getModule(RentalBuildingsStore);
export const subscriptionsStore = getModule(SubscriptionsStore);
export const taskActivityStore = getModule(TaskActivityStore);
export const transactionsStore = getModule(TransactionsStore);
export const usersStore = getModule(UsersStore);
