

































































import { defineComponent, Ref, ref } from "@vue/composition-api";
import { VForm } from "@/models";
import { Partner, PartnerTypeEnum } from "@edmp/api";
import { partnersService } from "@/services";
import { coreStore, partnersStore, productsStore } from "@/store";

export default defineComponent({
  name: "PartnersInvitationModal",
  props: {
    partnersList: {
      type: Array as () => Array<Partner>,
    },
  },
  setup(props, context) {
    const partnersToInvite: Ref<Array<string>> = ref([]);

    function closeDialog() {
      context.emit("close");
    }

    const partnersItemSelect = (partner: Partner) => {
      if (partner.email != null || partner.email != undefined) {
        return partner.type === PartnerTypeEnum.LEGAL_PERSON
          ? `${partner.denomination} (ne peut pas être invité)`
          : `${partner.firstName} ${partner.lastName}`;
      } else {
        return partner.type === PartnerTypeEnum.LEGAL_PERSON
          ? `${partner.denomination} (ne peut pas être invité)`
          : `${partner.firstName}  ${partner.lastName} (email non renseigné)`;
      }
    };

    async function validate() {
      if (productsStore.currentId && (context.refs.form as VForm).validate()) {
        try {
          await partnersService.invites({
            ids: partnersToInvite.value,
          });
          // Mise à jour des Partner dont l'Id peut changer
          await partnersStore.fetchPartners(productsStore.currentId);

          // Ajout du Feedback
          coreStore.displayFeedback({
            message: "Les invitations ont bien été transmises",
          });
          context.emit("validate");
        } catch (error) {
          console.error(Error);
        }
      }
    }

    return {
      closeDialog,
      validate,
      partnersToInvite,
      PartnerTypeEnum,
      partnersItemSelect,
    };
  },
});
