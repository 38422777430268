var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accountingResult"}},[_c('PageHeader',{attrs:{"displayAccountingPeriod":""},on:{"onAccountingPeriodChanged":function($event){return _vm.init()}}},[_vm._v(" Mon compte de résultat ")]),_c('div',{staticClass:"accountingResult-header"},[_c('router-link',{staticClass:"accountingDocuments-back d-flex align-center",attrs:{"to":{ name: _vm.ROUTE_NAMES.AccountingDocuments }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Mes documents comptables ")],1)],1),_c('div',{staticClass:"section"},[_c('AccountingResultLines',{staticClass:"section-body",attrs:{"lines":[_vm.beforeTaxesResult]}})],1),_vm._l((Object.entries(
      _vm.accountingResultCompute
    )),function(ref){
    var sectionTitle = ref[0];
    var subTitleOrLines = ref[1];
return _c('div',{key:sectionTitle,staticClass:"section"},[_c('h3',{staticClass:"section-header section-header-title"},[_vm._v(_vm._s(sectionTitle))]),(_vm.isArray(subTitleOrLines))?[_c('AccountingResultLines',{attrs:{"lines":subTitleOrLines}})]:_vm._l((Object.entries(subTitleOrLines)),function(ref){
    var sectionSubTitle = ref[0];
    var lines = ref[1];
return _c('div',{key:sectionSubTitle,staticClass:"section"},[_c('h4',{staticClass:"section-header section-header-subTitle"},[_vm._v(" "+_vm._s(sectionSubTitle)+" ")]),_c('AccountingResultLines',{attrs:{"lines":lines}})],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }