import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { partnersService } from "@/services";
import { Partner, NewPartner, PartnerTypeEnum } from "@edmp/api";
import { partnersStore } from "@/store";

export interface PartnersState {
  partners: Array<Partner>;
  loading: boolean;
}

@Module({
  name: "partners-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class PartnersStore extends VuexModule implements PartnersState {
  partners: Array<Partner> = [];
  loading = false;

  get getPartnerName() {
    return (id: string) => {
      const partner = partnersStore.partners.find(
        (partner) => partner.id === id
      );
      return (
        (partner &&
          (partner.type === PartnerTypeEnum.LEGAL_PERSON
            ? partner.denomination
            : `${partner.firstName} ${partner.lastName}`)) ||
        "Ø"
      );
    };
  }
  @Mutation
  reset(): void {
    this.partners = [];
    this.loading = false;
  }

  @Mutation
  setPartners(partners: Partner[]): void {
    this.partners = partners;
  }

  @Mutation
  addPartner(partner: Partner): void {
    this.partners = [...this.partners, partner];
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Action
  async fetchPartners(productId: string): Promise<void> {
    this.setLoading(true);
    this.setPartners(await partnersService.list({ productId }));
    this.setLoading(false);
  }

  @Action
  async createPartner(partnerCreate: NewPartner): Promise<void> {
    const newPartner = await partnersService.create(partnerCreate);
    this.addPartner(newPartner);
  }

  @Action
  async updatePartner(partnerUpdate: Partner): Promise<void> {
    await partnersService.update(partnerUpdate);
    await this.fetchPartners(partnerUpdate.productId);
  }
}
