import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import eventBus from "@/events/eventBus";
import {
  TaskActivityGroupLocal,
  TaskActivityGroupLocalExtends,
  TaskActivityLocal,
  TaskActivityLocalExtends,
} from "@/models/TaskActivity.model";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  coreStore,
  productsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  taskActivityStore,
  transactionsStore,
  usersStore,
} from "@/store";
import {
  SubscriptionsModel,
  TaskActivity,
  TaskActivityGroup,
  TaskActivityReference,
  TaskActivityStatus,
  TaskActivityTypeReference,
  TaskCode,
  TaskGroupCode,
  TaxRegime,
  TypeReference,
  getMoment,
  getReferredIdByType,
  getReferredIdByTypeWithReferences,
  LedgerAccountEnum,
} from "@edmp/api";
import { flatMap, orderBy } from "lodash";
import moment from "moment";
import { RawLocation } from "vue-router";
import { FilterKeys, FiltersParameters } from "./transactions.utils";

export const transformLocal = (
  taskActivity: TaskActivity
): TaskActivityLocal | undefined => {
  return taskActivityLocalExtends(taskActivity);
};

export const transformGroupLocal = (
  taskActivityGroup: TaskActivityGroup
): TaskActivityGroupLocal | undefined => {
  const taskActivityGroupLocal =
    taskActivityGroupLocalExtends(taskActivityGroup);

  if (taskActivityGroupLocal) {
    taskActivityGroupLocal.taskActivitiesLocal =
      taskActivityGroup.taskActivities
        .map((taskActivity) => {
          const taskActivityLocal: TaskActivityLocal | undefined =
            transformLocal(taskActivity);
          if (taskActivityLocal) {
            taskActivityLocal.groupCode =
              taskActivityGroupLocal.groupCode as TaskGroupCode;
          }
          return taskActivityLocal;
        })
        .filter(
          (extendedTaskActivity): extendedTaskActivity is TaskActivityLocal =>
            extendedTaskActivity !== undefined
        );
  }
  return taskActivityGroupLocal;
};

export const transformGroupsLocal = (
  taskActivityGroups: TaskActivityGroup[]
): TaskActivityGroupLocal[] => {
  return taskActivityGroups
    .map((group) => transformGroupLocal(group))
    .filter(Boolean) as TaskActivityGroupLocal[]; //filtering by Boolean constructor removes all falsy values from the array (null, undefined, 0, ...)
};

export const isSmallestAccountingPeriod = (accountingPeriodId: string) => {
  const accountingPeriods = orderBy(
    accountingPeriodsStore.accountingPeriods.filter(
      (accountingPeriod) => !accountingPeriod.closed
    ),
    (accountingPeriod) => {
      return moment(accountingPeriod.startAt).format("YYYYMMDD");
    },
    ["asc"]
  );
  return (
    accountingPeriods.length && accountingPeriodId === accountingPeriods[0].id
  );
};

const taskActivityGroupLocalExtends = (
  taskActivityGroup: TaskActivityGroup
): TaskActivityGroupLocal | undefined => {
  const navigateToPage = (location: RawLocation) => {
    return async () => {
      await router.push(location);
    };
  };
  const navigateToTransactions = <Keys extends FilterKeys>(addFilter?: {
    code: Keys;
    filterParams?: FiltersParameters[Keys];
  }) => {
    return async () => {
      if (addFilter) {
        transactionsStore.resetFilter();
        transactionsStore.addFilter(addFilter);
        await router.push({ name: "Transactions" });
      }
    };
  };

  const taxRegime: TaxRegime | undefined =
    accountingPeriodsStore.currentAccountingPeriod?.taxRegime;
  if (!taxRegime) {
    return undefined;
  }

  const taskActivityGroupLocalExtends: TaskActivityGroupLocalExtends = {
    [TaskGroupCode.StartWell]: {
      [TaxRegime.IR_2072]: {
        title: "Complétez votre SCI",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
      [TaxRegime.IS_2065]: {
        title: "Complétez votre SCI",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
    },
    [TaskGroupCode.StartWellInformation]: {
      [TaxRegime.IR_2072]: {
        title: "Complétez votre SCI",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
      [TaxRegime.IS_2065]: {
        title: "Complétez votre SCI",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
    },
    [TaskGroupCode.StartWellAccounting]: {
      [TaxRegime.IR_2072]: {
        title: "Paramétrage de ma comptabilité",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
      [TaxRegime.IS_2065]: {
        title: "Paramétrage de ma comptabilité",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
    },
    [TaskGroupCode.StartWellLMNP]: {
      [TaxRegime.LMNP_2031]: {
        title: "Complétez votre dossier LMNP",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
    },
    [TaskGroupCode.StartWellAccountingLMNP]: {
      [TaxRegime.LMNP_2031]: {
        title: "Reprendre votre comptabilité LMNP",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.TaskActivity }),
      },
    },
    [TaskGroupCode.AccountingPeriod]: {
      [TaxRegime.IR_2072]: {
        title: subscriptionsStore.isPremium
          ? "Reprise du dernier bilan clôturé"
          : "Injecter le solde N-1",
        navigateTo: subscriptionsStore.isPremium
          ? navigateToPage({
              name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
            })
          : // : taskActivityStore.taskActivityGroups.find(
            //     (group) => group.groupCode === taskActivityGroup.groupCode
            //     // TODO Manage group status ?!
            //     /* task.refs.product === refs.product &&
            //     task.refs.accountingPeriod === refs.accountingPeriod &&
            //     group.status === TaskActivityStatus.PENDING,*/
            //   )
            navigateToPage({
              name: ROUTE_NAMES.AccountingDashboard,
              hash: "#inject-balance",
            }),
      },
      [TaxRegime.IS_2065]: {
        title: "Reprise du dernier bilan clôturé",
        navigateTo: navigateToPage({
          name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
        }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Reprise du dernier bilan clôturé",
        navigateTo: navigateToPage({
          name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
        }),
      },
    },
    // eslint-disable-next-line prettier/prettier
    [TaskGroupCode.Transactions]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajouter votre première transaction",
        navigateTo: (() => {
          if (taskActivityGroup.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.Transactions,
              query: {
                openManualTransaction: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.Transactions,
            });
          }
        })(),
      },
    },
    [TaskGroupCode.TransactionsYearEnd]: {
      [TaxRegime.IR_2072]: {
        title: "Établir les comptes de l'exercice",
        dueDateLabel: "18 mai",
        navigateTo: navigateToTransactions({
          code: FilterKeys.CATEGORIES,
          filterParams: {
            label: "Solde N-1",
            categories: [LedgerAccountEnum.N110001],
          },
        }),
      },
    },
    [TaskGroupCode.TaxReturnPrepareClose]: {
      [TaxRegime.IR_2072]: {
        title: "Préparer les Déclarations des revenus",
        dueDateLabel: "Du 18 mai au 07 juin selon le département",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsGeneralAssembly }),
      },
    },
    [TaskGroupCode.EventGeneralAssemblyYearEnd]: {
      [TaxRegime.IR_2072]: {
        title: "Approbation des comptes en AG",
        dueDateLabel: "18 mai",
        isEnableCustomAction:
          taskActivityStore.taskActivityGroups
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                  TaskGroupCode.EventGeneralAssemblyYearEnd &&
                getReferredIdByTypeWithReferences(
                  taskGroup.references,
                  TaskActivityTypeReference.product
                ) === productsStore.currentId &&
                getReferredIdByTypeWithReferences(
                  taskGroup.references,
                  TaskActivityTypeReference.accountingPeriod
                ) === accountingPeriodsStore.currentId
            )
            ?.taskActivitiesLocal.find(
              (taskActivity) => taskActivity?.code === TaskCode.Create
            )?.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.IS_2065]: {
        title: "Approbation des comptes en AG",
        isEnableCustomAction:
          taskActivityStore.taskActivityGroups
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                  TaskGroupCode.EventGeneralAssemblyYearEnd &&
                getReferredIdByTypeWithReferences(
                  taskGroup.references,
                  TaskActivityTypeReference.product
                ) === productsStore.currentId &&
                getReferredIdByTypeWithReferences(
                  taskGroup.references,
                  TaskActivityTypeReference.accountingPeriod
                ) === accountingPeriodsStore.currentId
            )
            ?.taskActivitiesLocal.find(
              (taskActivity) => taskActivity?.code === TaskCode.Create
            )?.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },
    [TaskGroupCode.YearEndTransactionsCheck]: {
      [TaxRegime.IS_2065]: {
        title: "Vérification des opérations",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Vérification des opérations",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },

    [TaskGroupCode.FinancialReporting]: {
      [TaxRegime.IS_2065]: {
        title: "Établir les comptes",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Établir les comptes",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },

    [TaskGroupCode.GetTaxReturns]: {
      [TaxRegime.IS_2065]: {
        title: "Obtenir les déclarations fiscales",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Obtenir les déclarations fiscales",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },
    [TaskGroupCode.EventGeneralAssembly]: {
      [TaxRegime.IR_2072]: {
        title: "Organisation d'une AG",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsGeneralAssembly }),
      },
      [TaxRegime.IS_2065]: {
        title: "Organisation d'une AG",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsGeneralAssembly }),
      },
    },
    [TaskGroupCode.UserNotifications]: {
      [TaxRegime.IR_2072]: {
        title: "Notifications",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.User }),
      },
      [TaxRegime.IS_2065]: {
        title: "Notifications",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.User }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Notifications",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.User }),
      },
    },
    [TaskGroupCode.EventAccountingPeriodClosure]: {
      [TaxRegime.IR_2072]: {
        title:
          "Clôturer mon exercice " +
          getMoment(
            accountingPeriodsStore.accountingPeriods.find((acc) => {
              if (acc.id === accountingPeriodsStore.currentId) {
                return acc;
              }
            })?.endAt
          ).format("YYYY"),
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.IS_2065]: {
        title:
          "Clôturer mon exercice " +
          getMoment(
            accountingPeriodsStore.accountingPeriods.find((acc) => {
              if (acc.id === accountingPeriodsStore.currentId) {
                return acc;
              }
            })?.endAt
          ).format("YYYY"),
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
      [TaxRegime.LMNP_2031]: {
        title:
          "Clôturer mon exercice " +
          getMoment(
            accountingPeriodsStore.accountingPeriods.find((acc) => {
              if (acc.id === accountingPeriodsStore.currentId) {
                return acc;
              }
            })?.endAt
          ).format("YYYY"),
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },
    [TaskGroupCode.TaxReturn2072]: {
      [TaxRegime.IR_2072]: {
        title: "Déclaration 2072",
        dueDateLabel: "18 mai",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },
    [TaskGroupCode.TaxReturn20422044]: {
      [TaxRegime.IR_2072]: {
        title: "Déclaration 2042 ou 2044",
        dueDateLabel: "Du 18 mai au 07 juin selon le département",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
      },
    },
    [TaskGroupCode.BalanceSheet]: {
      [TaxRegime.IR_2072]: {
        title: "Je veux obtenir mon bilan",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
        isDisabled:
          // subscriptionsStore.getCurrentSubscription?.plan.type !==
          // SubscriptionsModel.PlanType.Premium,
          true,
        isComingSoon: true,
      },
    },
    [TaskGroupCode.TeletransmitTaxReturn]: {
      [TaxRegime.IR_2072]: {
        title: "Télétransmission de ma liasse fiscale",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
        isDisabled: true,
        isComingSoon: true,
      },
      [TaxRegime.IS_2065]: {
        title: "Télétransmission de ma liasse fiscale",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
        isDisabled: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        isComingSoon: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Télétransmission de ma liasse fiscale",
        navigateTo: navigateToPage({ name: ROUTE_NAMES.EventsYearEnd }),
        isDisabled: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        isComingSoon: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
      },
    },
  };

  const taskActivityGroupExtends =
    taskActivityGroupLocalExtends[taskActivityGroup.groupCode]?.[taxRegime];

  if (taskActivityGroupExtends) {
    return {
      ...taskActivityGroup,
      ...taskActivityGroupExtends,
      taskActivitiesLocal: [],
    };
  } else {
    return undefined;
  }
};

const taskActivityLocalExtends = (
  taskActivity: TaskActivity
): TaskActivityLocal | undefined => {
  const navigateToPage = (
    location: RawLocation,
    productId?: string,
    accountingPeriodId?: string
  ) => {
    return async () => {
      if (productId) {
        await productsStore.switchProduct({ id: productId });
      }

      if (accountingPeriodId) {
        accountingPeriodsStore.switchAccountingPeriod(accountingPeriodId);
      }
      await router.push(location);
    };
  };
  const navigateToTransactions = <Keys extends FilterKeys>(
    productId?: string,
    accountingPeriodId?: string,
    addFilter?: {
      code: Keys;
      filterParams?: FiltersParameters[Keys];
    }
  ) => {
    return async () => {
      if (productId) {
        await productsStore.switchProduct({ id: productId });
      }
      if (accountingPeriodId) {
        accountingPeriodsStore.switchAccountingPeriod(accountingPeriodId);
      }
      if (addFilter) {
        transactionsStore.resetFilter();
        transactionsStore.addFilter(addFilter);
        await router.push({ name: "Transactions" });
      }
    };
  };

  const productId: string | undefined = getReferredIdByType(
    taskActivity,
    TaskActivityTypeReference.product
  );
  const accountingPeriodId: string | undefined = getReferredIdByType(
    taskActivity,
    TaskActivityTypeReference.accountingPeriod
  );
  const assemblyId: string | undefined = getReferredIdByType(
    taskActivity,
    TaskActivityTypeReference.assembly
  );
  const subscriptionId: string | undefined = getReferredIdByType(
    taskActivity,
    TaskActivityTypeReference.subscription
  );
  const taxRegime: TaxRegime | undefined =
    accountingPeriodsStore.currentAccountingPeriod?.taxRegime;
  if (!taxRegime) {
    return undefined;
  }

  const taskActivityLocalExtends: TaskActivityLocalExtends = {
    [TaskCode.EmailAdd]: {
      [TaxRegime.IR_2072]: {
        title: productId
          ? "Ajouter une adresse email à la SCI"
          : "Ajouter une adresse email",
        description: productId
          ? "L'adresse email nous permet d'identifier votre SCI et de vous envoyer des factures"
          : "L'adresse email nous permet de vous contacter et vous envoyer des rappels",
        learnMore: productId
          ? "<p>Renseigner une adresse email propre à la SCI permet à Ownily de vous envoyer tous les éléments se rattachant à la <b>facturation</b> de votre abonnement. </p><p><i >Cet email sera <b>obligatoire</b> lors de la souscription à un abonnement.</i > </p>"
          : "",
        navigateTo: navigateToPage(
          productId
            ? {
                name: ROUTE_NAMES.ActivityDetail,
                params: { productId: productId as string },
              }
            : { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: productId
          ? "Ajouter une adresse email à la SCI"
          : "Ajouter une adresse email",
        description: productId
          ? "L'adresse email nous permet d'identifier votre SCI et de vous envoyer des factures"
          : "L'adresse email nous permet de vous contacter et vous envoyer des rappels",
        learnMore: productId
          ? "<p>Renseigner une adresse email propre à la SCI permet à Ownily de vous envoyer tous les éléments se rattachant à la <b>facturation</b> de votre abonnement. </p><p><i >Cet email sera <b>obligatoire</b> lors de la souscription à un abonnement.</i > </p>"
          : "",
        navigateTo: navigateToPage(
          productId
            ? {
                name: ROUTE_NAMES.ActivityDetail,
                params: { productId: productId as string },
              }
            : { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.PhoneAdd]: {
      [TaxRegime.IR_2072]: {
        title: "Ajouter un numéro de téléphone",
        description:
          "Le numéro de téléphone nous permet de vous contacter et vous envoyer des rappels",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Ajouter un numéro de téléphone",
        description:
          "Le numéro de téléphone nous permet de vous contacter et vous envoyer des rappels",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.EmailValidate]: {
      [TaxRegime.IR_2072]: {
        title: productId
          ? "Valider l'adresse email de la SCI"
          : "Valider votre adresse email",
        description: productId
          ? "Votre adresse email n'a pas encore été validée"
          : "Votre adresse email n'a pas encore été validée",
        learnMore: productId
          ? "<p>La validation de l'adresse email de la SCI permet à Ownily d'<b >identifier votre SCI de manière unique et sécurisée</b> sur l'application.</p>"
          : "<p>La validation de votre adresse email permet à Ownily de vous identifier de manière unique sur l'application. Elle permet également de vous envoyer des <b>notifications ou rappels</b> importants en rapport avec votre SCI ou la gestion locative.</p>",
        navigateTo: navigateToPage(
          productId
            ? {
                name: ROUTE_NAMES.ActivityDetail,
                params: { productId: productId as string },
              }
            : { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: productId
          ? "Valider l'adresse email de la SCI"
          : "Valider votre adresse email",
        description: productId
          ? "Votre adresse email n'a pas encore été validée"
          : "Votre adresse email n'a pas encore été validée",
        learnMore: productId
          ? "<p>La validation de l'adresse email de la SCI permet à Ownily d'<b >identifier votre SCI de manière unique et sécurisée</b> sur l'application.</p>"
          : "<p>La validation de votre adresse email permet à Ownily de vous identifier de manière unique sur l'application. Elle permet également de vous envoyer des <b>notifications ou rappels</b> importants en rapport avec votre SCI ou la gestion locative.</p>",
        navigateTo: navigateToPage(
          productId
            ? {
                name: ROUTE_NAMES.ActivityDetail,
                params: { productId: productId as string },
              }
            : { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.PhoneValidate]: {
      [TaxRegime.IR_2072]: {
        title: "Valider votre numéro de téléphone",
        description: "Votre numéro de téléphone n'a pas encore été validé",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Valider votre numéro de téléphone",
        description: "Votre numéro de téléphone n'a pas encore été validé",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.User },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.ProductCreate]: {
      [TaxRegime.IR_2072]: {
        title: "Ajouter une SCI",
        description:
          "Créer votre première SCI pour pouvoir profiter de l'application Ownily",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Activities },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Ajouter une SCI",
        description:
          "Créer votre première SCI pour pouvoir profiter de l'application Ownily",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Activities },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BankCreate]: {
      [TaxRegime.IR_2072]: {
        title: "Ajouter un compte bancaire",
        description:
          "Au moins un compte bancaire doit être synchronisé pour utiliser les fonctionnalités d'Ownily",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.BankAccounts },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Ajouter un compte bancaire",
        description:
          "Au moins un compte bancaire doit être synchronisé pour utiliser les fonctionnalités d'Ownily",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.BankAccounts },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BankCorrect]: {
      [TaxRegime.IR_2072]: {
        title: "L'accès à votre compte bancaire est suspendu",
        description:
          "L'accès à votre compte est suspendu, renouvellez l'accès dans vos paramètres",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.BankAccounts },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "L'accès à votre compte bancaire est suspendu",
        description:
          "L'accès à votre compte est suspendu, renouvellez l'accès dans vos paramètres",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.BankAccounts },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.CreateStatus]: {
      [TaxRegime.IR_2072]: {
        title: "Terminer la création votre SCI",
        description: "La configuration de votre SCI n'est pas finalisé",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Terminer la création votre SCI",
        description: "La configuration de votre SCI n'est pas finalisé",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.SiretAdd]: {
      [TaxRegime.IR_2072]: {
        title: "Renseigner le SIRET de votre SCI",
        description: "Le SIRET de votre SCI n'a pas été renseigné",
        learnMore:
          "<p>Renseigner le nom de la société et le SIREN/SIRET permet de bien identifier votre SCI sur Ownily.</p><p>👉 <b>Vous n'avez pas le SIRET/SIREN de la SCI sous la main ? </b>Pas de panique, vous pouvez utiliser notre moteur de recherche pour retrouver votre SCI à partir de son nom, de son adresse... Ou retrouver le SIRET de la société depuis le site <a href='https://www.infogreffe.fr/' target='_blank'>https://www.infogreffe.fr/</a></p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Renseigner le SIRET de votre SCI",
        description: "Le SIRET de votre SCI n'a pas été renseigné",
        learnMore:
          "<p>Renseigner le nom de la société et le SIREN/SIRET permet de bien identifier votre SCI sur Ownily.</p><p>👉 <b>Vous n'avez pas le SIRET/SIREN de la SCI sous la main ? </b>Pas de panique, vous pouvez utiliser notre moteur de recherche pour retrouver votre SCI à partir de son nom, de son adresse... Ou retrouver le SIRET de la société depuis le site <a href='https://www.infogreffe.fr/' target='_blank'>https://www.infogreffe.fr/</a></p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.SiretConfirm]: {
      [TaxRegime.IR_2072]: {
        title: "Vérifier le SIRET de votre SCI",
        description: "Le SIRET de votre SCI ne semble pas valide",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Vérifier le SIRET de votre SCI",
        description: "Le SIRET de votre SCI ne semble pas valide",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.PartnerCreate]: {
      [TaxRegime.IR_2072]: {
        title: "Renseigner vos associés",
        description:
          "Les associés de votre SCI n'ont pas encore été renseignés",
        learnMore:
          "<p>Les associés et leurs parts doivent être renseignés pour qu'Ownily <b>calcule automatiquement la CERFA 2044</b> de chacun. </p><p>Une fois ajoutés, les associés seront utiles dans la <b>catégorisation</b> des transactions. Ils pourront également être invités à créer un compte sur Ownily pour <b>consulter directement le solde et les infos</b> de la SCI. </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Renseigner vos associés",
        description:
          "Les associés de votre SCI n'ont pas encore été renseignés",
        learnMore:
          "<p>Les associés et leurs parts doivent être renseignés pour qu'Ownily <b>calcule automatiquement la CERFA 2044</b> de chacun. </p><p>Une fois ajoutés, les associés seront utiles dans la <b>catégorisation</b> des transactions. Ils pourront également être invités à créer un compte sur Ownily pour <b>consulter directement le solde et les infos</b> de la SCI. </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.AssetUpdate]: {
      [TaxRegime.IR_2072]: {
        title: "Identifier vos biens",
        description: "Les données de votre immeuble doivent être renseignées",
        learnMore:
          "<p>Renseigner vos biens sur Ownily est essentiel pour :</p><p>👉 catégoriser vos transactions<br /> 👉 tenir à jour la comptabilité de la SCI<br /> 👉 gérer la gestion locative en ligne<br /> 👉 calculer et générer la CERFA 2072 / 2044 </p><p>Besoin d'aide ? rendez-vous sur l'article <a href='https://ownily.crisp.help/fr/article/parametrer-ma-sci-1rpqx5w/' target='_blank'>Paramétrer ma SCI </a> </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
            hash: "#assets-section",
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Identifier vos biens",
        description: "Les données de votre immeuble doivent être renseignées",
        learnMore:
          "<p>Renseigner vos biens sur Ownily est essentiel pour :</p><p>👉 catégoriser vos transactions<br /> 👉 tenir à jour la comptabilité de la SCI<br /> 👉 gérer la gestion locative en ligne<br /> 👉 calculer et générer la CERFA 2072 / 2044 </p><p>Besoin d'aide ? rendez-vous sur l'article <a href='https://ownily.crisp.help/fr/article/parametrer-ma-sci-1rpqx5w/' target='_blank'>Paramétrer ma SCI </a> </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
            hash: "#assets-section",
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Identifier vos biens",
        description: "Les données de votre immeuble doivent être renseignées",
        learnMore:
          "<p>Renseigner vos biens sur Ownily est essentiel pour :</p><p>👉 catégoriser vos transactions<br /> 👉 tenir à jour la comptabilité de votre activité<br /> 👉 gérer la gestion locative en ligne<br /> 👉 calculer et générer la CERFA 2031 / 2033 </p><p>Besoin d'aide ? rendez-vous sur l'article <a href='https://ownily.crisp.help/fr/article/parametrer-ma-sci-1rpqx5w/' target='_blank'>Paramétrer ma SCI </a> </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.RealEstateAssets,
            params: { productId: productId as string },
            query: {
              tabIndex: "0",
            },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.AmortisationUpdate]: {
      [TaxRegime.IS_2065]: {
        title:
          "Paramétrage des amortissements pour vos biens et immobilisations",
        description:
          "Les données de votre amortissement doivent être renseignées pour vos biens",
        learnMore:
          "<p>Vous devez paramétrer l'amortissement de chaque Bien ainsi que des autres immobilisations que vous avez déjà enregistrés dans votre comptabilité. Cela est une des obligations légales d'une SCI à l'IS. Ownily vous guidera dans ce paramétrage </p>",
        isEnableCustomAction:
          taskActivityStore.startWellTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccounting
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return taskActivity.code === TaskCode.AssetUpdate;
            })?.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Amortisations },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title:
          "Paramétrage des amortissements pour vos biens et immobilisations",
        description:
          "Les données de votre amortissement doivent être renseignées pour vos biens",
        learnMore:
          "<p>Vous devez paramétrer l'amortissement de chaque Bien ainsi que des autres immobilisations que vous avez déjà enregistrés dans votre comptabilité. Ownily vous guidera dans ce paramétrage </p>",
        isEnableCustomAction:
          taskActivityStore.startWellTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return taskActivity.code === TaskCode.AssetUpdate;
            })?.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Amortisations },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BankAccountLink]: {
      [TaxRegime.IR_2072]: {
        title: "Associer un compte bancaire à la SCI",
        description: "Le compte bancaire de votre SCI n'a pas encore été lié",
        learnMore:
          "<p>La synchronisation d'un ou plusieurs comptes bancaires vous évite de saisir manuellement vos recettes et dépenses. Les relevés de comptes de la SCI sont chargés <b>automatiquement et quotidiennement</b> sur Ownily. Vous <b>gagnez du temps</b> et <b>évitez les oublis et les erreurs</b> pour gagner en sérénité ! </p><p>Plus d'infos sur la fonctionnalité de synchronisation bancaire ? Rendez-vous sur le centre d'aide rubrique <a href='https://ownily.crisp.help/fr/category/connexion-bancaire-134j95e/' target='_blank'>Connexion Bancaire </a> </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
            hash: "#banks-section",
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Associer un compte bancaire à la SCI",
        description: "Le compte bancaire de votre SCI n'a pas encore été lié",
        learnMore:
          "<p>La synchronisation d'un ou plusieurs comptes bancaires vous évite de saisir manuellement vos recettes et dépenses. Les relevés de comptes de la SCI sont chargés <b>automatiquement et quotidiennement</b> sur Ownily. Vous <b>gagnez du temps</b> et <b>évitez les oublis et les erreurs</b> pour gagner en sérénité ! </p><p>Plus d'infos sur la fonctionnalité de synchronisation bancaire ? Rendez-vous sur le centre d'aide rubrique <a href='https://ownily.crisp.help/fr/category/connexion-bancaire-134j95e/' target='_blank'>Connexion Bancaire </a> </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.ActivityDetail,
            params: { productId: productId as string },
            hash: "#banks-section",
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BalanceSheetRecovery]: {
      [TaxRegime.IR_2072]: {
        title: "Reprise du dernier bilan clôturé",
        description: "",
        learnMore:
          "<p>Lorsque vous démarrez sur Ownily, vous avez besoin d'injecter le Solde de l'exercice précédent <a href='https://ownily.crisp.help/fr/article/comment-integrer-les-soldes-n-1-la-premiere-annee-dutilisation-downily-zee7k5/' target='_blank'>(plus d'infos ici) </a>. </p><p>👉 Dans le cas d'une SCI à l'IR, vous ajoutez simplement le solde de Banque au 31 décembre de l'année passée. </p><p>👉 Si vous avez calculé un Résultat Comptable en tant que tel, au 31 décembre, c'est ce montant que vous allez injecter au 1er janvier. </p> <br /> <p>⚠ Cette tâche est réalisée automatiquement par l'application lorsque vous clôturez au moins un exercice en utilisant Ownily. </p>",
        isDisabled:
          subscriptionsStore.getCurrentSubscription?.plan.type ===
          SubscriptionsModel.PlanType.Free,
        isEnableCustomAction:
          taskActivity.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Reprise du dernier bilan clôturé",
        description: "",
        learnMore:
          "<p>Vous devez saisir les soldes de votre dernier bilan clôturé.<br /> Votre comptable ou votre ancien logiciel de comptabilité doit pouvoir vous fournir ce document. </p><p>Ownily a dejà ajouté certains comptes grâce aux informations que vous complétées lors du paramétrage. Il est donc très important d'avoir compléter entièrement tout son paramétrage dans les tâches précédentes. </p><p>Ownily repartira des soldes que vous avez indiqués pour comptabiliser les opérations de l'exercice suivant. Veillez donc à bien ajouter chaque compte présent dans votre dernier bilan comptable. </p>",
        isEnableCustomAction:
          taskActivity.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  (taskActivityLocal.code === TaskCode.PartnerCreate ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.BankAccountLink ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
                );
              })
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Reprise de votre dernier bilan",
        description: "",
        learnMore:
          "<p>Ownily a besoin que vous indiquiez certains éléments pour la reprise de votre comptabilité.</p><p>Vous trouverez les informations sur votre dernière déclaration 2033 au niveau de la page Bilan</p>",
        isEnableCustomAction:
          taskActivity.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode ===
                    TaskGroupCode.StartWellAccountingLMNP ||
                  taskGroup.groupCode === TaskGroupCode.StartWellLMNP
              )
              ?.taskActivitiesLocal.find(
                (taskActivityLocal) =>
                  (taskActivityLocal.code ===
                    TaskCode.DeferrableAmortisationsAdd ||
                    taskActivityLocal.code === TaskCode.DeferrableDeficitsAdd ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate ||
                    taskActivityLocal.code === TaskCode.LoanAdd) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
              )
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
    },
    [TaskCode.InjectBalance]: {
      [TaxRegime.IR_2072]: {
        title:
          "Ajouter le solde de votre compte bancaire au 31/12 de l'année précédente",
        description: "",
        learnMore:
          "<p>Ajouter le solde du compte bancaire de votre SCI à la clôture de votre dernier exercice comptable (31/12).<br />Ce solde est disponible sur votre relevé de compte bancaire du dernier mois d'exercice.<br />Ce solde vous permettra d'initialiser le compte banque sur Ownily et de suivre l'évolution de votre solde bancaire depuis Ownily au fur et à mesure des catégorisation de vos opérations </p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction:
          taskActivity.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          return navigateToPage(
            {
              name: ROUTE_NAMES.AccountingDashboard,
              hash: "#inject-balance",
            },
            productId,
            accountingPeriodId
          );
        })(),
        event: () => {
          eventBus.$emit("inject-balance");
        },
      },
      [TaxRegime.IS_2065]: {
        title: "Injecter le solde N-1",
        description: "",
        learnMore:
          "<p>Vous devez saisir les soldes de votre dernier bilan clôturé.<br /> Votre comptable ou votre ancien logiciel de comptabilité doit pouvoir vous fournir ce document. </p><p>Ownily a dejà ajouté certains comptes grâce aux informations que vous complétées lors du paramétrage. Il est donc très important d'avoir compléter entièrement tout son paramétrage dans les tâches précédentes. </p><p>Ownily repartira des soldes que vous avez indiqués pour comptabiliser les opérations de l'exercice suivant. Veillez donc à bien ajouter chaque compte présent dans votre dernier bilan comptable. </p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: !!taskActivityStore.startWellTaskGroup
          .find(
            (taskGroup) =>
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting
          )
          ?.taskActivitiesLocal.find((taskActivityLocal) => {
            return (
              (taskActivityLocal.code === TaskCode.PartnerCreate ||
                taskActivityLocal.code === TaskCode.AssetUpdate ||
                taskActivityLocal.code === TaskCode.BankAccountLink ||
                taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
              taskActivityLocal.status === TaskActivityStatus.PENDING
            );
          }),
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  (taskActivityLocal.code === TaskCode.PartnerCreate ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.BankAccountLink ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
                );
              })
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
    },
    [TaskCode.DebitsCreditsRecorded]: {
      [TaxRegime.IR_2072]: {
        title: "Toutes les recettes et dépenses sont enregistrées",
        description:
          "Vérifiez dans l'espace Mes Transactions qu'il ne manque pas des transactions sur l'année d'exercice. Si l'historique affiché est insuffisant ou incomplet contactez nous. Utilisez 'Ajouter une transaction' dans cette rubrique pour injecter une recette ou dépense qui n'apparait pas sur le compte bancaire.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> qu'il ne manque pas des opérations (recettes et dépenses) sur l'année d'exercice. </p><p>👉 Si une recette ou dépense n'apparait pas sur le compte bancaire ou si l'historique affiché est incomplet, vous pouvez utiliser la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-completer-mon-historique-de-transactions-sur-ownily-2vqr3f/' target='_blank'>import de transaction </a> ou la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-injecter-une-transaction-manuellement-1dns1y6/' target='_blank'> ajout manuel</a> de transaction. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Toutes les recettes et dépenses sont enregistrées",
        description:
          "Vérifiez dans l'espace Mes Transactions qu'il ne manque pas des transactions sur l'année d'exercice. Si l'historique affiché est insuffisant ou incomplet contactez nous. Utilisez 'Ajouter une transaction' dans cette rubrique pour injecter une recette ou dépense qui n'apparait pas sur le compte bancaire.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> qu'il ne manque pas des opérations (recettes et dépenses) sur l'année d'exercice. </p><p>👉 Si une recette ou dépense n'apparait pas sur le compte bancaire ou si l'historique affiché est incomplet, vous pouvez utiliser la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-completer-mon-historique-de-transactions-sur-ownily-2vqr3f/' target='_blank'>import de transaction </a> ou la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-injecter-une-transaction-manuellement-1dns1y6/' target='_blank'> ajout manuel</a> de transaction. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Toutes les recettes et dépenses sont enregistrées",
        description:
          "Vérifiez dans l'espace Mes Transactions qu'il ne manque pas des transactions sur l'année d'exercice. Si l'historique affiché est insuffisant ou incomplet contactez nous. Utilisez 'Ajouter une transaction' dans cette rubrique pour injecter une recette ou dépense qui n'apparait pas sur le compte bancaire.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> qu'il ne manque pas des opérations (recettes et dépenses) sur l'année d'exercice. </p><p>👉 Si une recette ou dépense n'apparait pas sur le compte bancaire ou si l'historique affiché est incomplet, vous pouvez utiliser la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-completer-mon-historique-de-transactions-sur-ownily-2vqr3f/' target='_blank'>import de transaction </a> ou la fonction d'<a href='https://ownily.crisp.help/fr/article/comment-injecter-une-transaction-manuellement-1dns1y6/' target='_blank'> ajout manuel</a> de transaction. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BalanceSheetRecoveryVerify]: {
      [TaxRegime.IR_2072]: {
        title:
          "Vérifiez que vous avez bien complété votre reprise du dernier bilan clôturé",
        description: "",
        learnMore:
          "<p>Lors du paramétrage du compte de la SCI vous avez injecté un solde initial au 1er jour de l'exercice. <a href='https://ownily.crisp.help/fr/article/comment-integrer-les-soldes-n-1-la-premiere-annee-dutilisation-downily-zee7k5/' target='_blank'> Retrouvez les infos à ce sujet ici </a>. Si vous avez déjà clôturé un exercice précédent sur Ownily, le Solde s'est reporté automatiquement. </p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: subscriptionsStore.isPremium
          ? taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.BalanceSheetRecovery
                );
              })?.status === TaskActivityStatus.PENDING ||
            taskActivity.status === TaskActivityStatus.PENDING
          : taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.BalanceSheetRecovery
                );
              })?.status === TaskActivityStatus.PENDING,
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Reprise du bilan de l'année précédente",
        description: "",
        learnMore:
          "<p>Vous devez saisir les soldes de votre dernier bilan clôturé.</p><p>Votre comptable ou votre ancien logiciel de comptabilité doit pouvoir vous fournir ce document.</p><p>Ownily a dejà ajouté certains comptes grâce aux informations que vous complétées lors du paramétrage. Il est donc très important d'avoir compléter entièrement tout son paramétrage dans les tâches précédentes.</p><p>Ownily repartira des soldes que vous avez indiqués pour comptabiliser les opérations de l'exercice suivant. Veillez donc à bien ajouter chaque compte présent dans votre dernier bilan comptable.</p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: subscriptionsStore.isPremium
          ? taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.BalanceSheetRecovery
                );
              })?.status === TaskActivityStatus.PENDING
          : false,
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  (taskActivityLocal.code === TaskCode.PartnerCreate ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.BankAccountLink ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
                );
              })
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Reprise du bilan de l'année précédente",
        description: "",
        learnMore:
          "<p>Vous devez saisir les soldes de votre dernier bilan clôturé.</p><p>Votre comptable ou votre ancien logiciel de comptabilité doit pouvoir vous fournir ce document.</p><p>Ownily a dejà ajouté certains comptes grâce aux informations que vous complétées lors du paramétrage. Il est donc très important d'avoir compléter entièrement tout son paramétrage dans les tâches précédentes.</p><p>Ownily repartira des soldes que vous avez indiqués pour comptabiliser les opérations de l'exercice suivant. Veillez donc à bien ajouter chaque compte présent dans votre dernier bilan comptable.</p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: subscriptionsStore.isPremium
          ? taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.BalanceSheetRecovery
                );
              })?.status === TaskActivityStatus.PENDING
          : false,
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  (taskActivityLocal.code === TaskCode.PartnerCreate ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.BankAccountLink ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
                );
              })
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
    },
    [TaskCode.InjectBalanceVerify]: {
      [TaxRegime.IR_2072]: {
        title:
          "Vérifiez que vous avez bien injecté le Solde N-1 au premier jour de l'exercice",
        description: "",
        learnMore:
          "<p>Lors du paramétrage du compte de la SCI vous avez injecté un solde initial au 1er jour de l'exercice. <a href='https://ownily.crisp.help/fr/article/comment-integrer-les-soldes-n-1-la-premiere-annee-dutilisation-downily-zee7k5/' target='_blank'> Retrouvez les infos à ce sujet ici </a>. Si vous avez déjà clôturé un exercice précédent sur Ownily, le Solde s'est reporté automatiquement. </p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: subscriptionsStore.isPremium
          ? taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.InjectBalance
                );
              })?.status === TaskActivityStatus.PENDING ||
            taskActivity.status === TaskActivityStatus.PENDING
          : taskActivityStore.currentAccountingPeriodTaskGroups
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.AccountingPeriod
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === TaskCode.InjectBalance
                );
              })?.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          return navigateToPage(
            {
              name: ROUTE_NAMES.AccountingDashboard,
              hash: "#inject-balance",
            },
            productId,
            accountingPeriodId
          );
        })(),
        event: () => {
          eventBus.$emit("inject-balance");
        },
      },
      [TaxRegime.IS_2065]: {
        title:
          "Vérifiez que vous avez bien complété votre reprise du dernier bilan clôturé",
        description: "",
        learnMore:
          "<p>Lors du paramétrage du compte de la SCI vous avez injecté un solde initial au 1er jour de l'exercice. <a href='https://ownily.crisp.help/fr/article/comment-integrer-les-soldes-n-1-la-premiere-annee-dutilisation-downily-zee7k5/' target='_blank'> Retrouvez les infos à ce sujet ici </a>. Si vous avez déjà clôturé un exercice précédent sur Ownily, le Solde s'est reporté automatiquement. </p>",
        isDisabled: subscriptionsStore.isFree,
        isEnableCustomAction: subscriptionsStore.isPremium
          ? taskActivityStore.closureTaskGroup.find((taskGroup) => {
              if (taskGroup.groupCode !== TaskGroupCode.TransactionsYearEnd) {
                return false;
              }
              return taskGroup.taskActivitiesLocal.some((taskActivityLocal) => {
                return (
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.product
                  ) === productId &&
                  getReferredIdByTypeWithReferences(
                    taskActivityLocal.references,
                    TaskActivityTypeReference.accountingPeriod
                  ) === accountingPeriodId &&
                  taskActivityLocal.code === taskActivity.code
                );
              });
            })?.status === TaskActivityStatus.PENDING
          : false,
        navigateTo: (() => {
          if (
            taskActivityStore.startWellTaskGroup
              .find(
                (taskGroup) =>
                  taskGroup.groupCode === TaskGroupCode.StartWellAccounting
              )
              ?.taskActivitiesLocal.find((taskActivityLocal) => {
                return (
                  (taskActivityLocal.code === TaskCode.PartnerCreate ||
                    taskActivityLocal.code === TaskCode.AssetUpdate ||
                    taskActivityLocal.code === TaskCode.BankAccountLink ||
                    taskActivityLocal.code === TaskCode.AmortisationUpdate) &&
                  taskActivityLocal.status === TaskActivityStatus.PENDING
                );
              })
          ) {
            return navigateToPage(
              { name: ROUTE_NAMES.TaskActivity },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToPage(
              { name: ROUTE_NAMES.AccountingBalanceSheetRecovery },
              productId,
              accountingPeriodId
            );
          }
        })(),
      },
    },
    [TaskCode.TransactionsCategorized]: {
      [TaxRegime.IR_2072]: {
        title: "Toutes les transactions sont catégorisées",
        description:
          "Un compteur vous indique les transactions restant à traiter. Rendez-vous dans l'espace Mes Transactions pour les catégoriser ou cliquez sur le compteur affiché.",
        learnMore:
          "<p>Un compteur rouge vous indique les opérations restant à catégoriser. Rendez-vous dans l'espace <b>\"Mes Transactions\"</b> pour les traiter. </p><p>👉 Vous ne pourrez pas valider cette tâche tant que le compteur n'est pas à 0. </p>",
        isEnableCustomAction:
          transactionsStore.numberOfTransactionsToBeCategorized !== 0 ||
          transactionsStore.numberOfTransactionsToBeAnomalized !== 0,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Toutes les transactions sont catégorisées",
        description:
          "Un compteur vous indique les transactions restant à traiter. Rendez-vous dans l'espace Mes Transactions pour les catégoriser ou cliquez sur le compteur affiché.",
        learnMore:
          "<p>Un compteur rouge vous indique les opérations restant à catégoriser. Rendez-vous dans l'espace <b>\"Mes Transactions\"</b> pour les traiter. </p><p>👉 Vous ne pourrez pas valider cette tâche tant que le compteur n'est pas à 0. </p>",
        isEnableCustomAction:
          transactionsStore.numberOfTransactionsToBeCategorized !== 0 ||
          transactionsStore.numberOfTransactionsToBeAnomalized !== 0,
        navigateTo: navigateToTransactions(productId, accountingPeriodId, {
          code: FilterKeys.ONLY_UNCATEGORIZED,
        }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Toutes les transactions sont catégorisées",
        description:
          "Un compteur vous indique les transactions restant à traiter. Rendez-vous dans l'espace Mes Transactions pour les catégoriser ou cliquez sur le compteur affiché.",
        learnMore:
          "<p>Un compteur rouge vous indique les opérations restant à catégoriser. Rendez-vous dans l'espace <b>\"Mes Transactions\"</b> pour les traiter. </p><p>👉 Vous ne pourrez pas valider cette tâche tant que le compteur n'est pas à 0. </p>",
        isEnableCustomAction:
          transactionsStore.numberOfTransactionsToBeCategorized !== 0 ||
          transactionsStore.numberOfTransactionsToBeAnomalized !== 0,
        navigateTo: navigateToTransactions(productId, accountingPeriodId, {
          code: FilterKeys.ONLY_UNCATEGORIZED,
        }),
      },
    },
    [TaskCode.EndingBalanceVerify]: {
      [TaxRegime.IR_2072]: {
        title:
          "Vérifier les soldes des Emprunts bancaires à la fin de l'exercice",
        description:
          "Un compteur vous indique les transactions restant à traiter. Rendez-vous dans l'espace Mes Transactions pour les catégoriser ou cliquez sur le compteur affiché.",
        learnMore:
          "<p>Nous constatons fréquemment des erreurs sur le traitement des emprunts : déblocage des fonds, échéances d'emprunts qui nécessitent la plupart du temps un découpage entre capital remboursé, intérêts et/ou assurances. Or ces découpages impactent les charges déductibles et donc le Résultat Fiscal de la SCI. </p><p>C'est pourquoi nous vous invitons à vérifier avec la plus grande attention le traitement de ces transactions en utilisant les filtres associés sur l'espace Mes Transactions ou à partir de la page Résultat de la SCI de l'espace Mes Déclarations fiscales (R4 et Lignes 17). Pour vous y aider consultez aussi l'article <a href='https://ownily.crisp.help/fr/article/comment-gerer-mes-emprunts-sur-ownily-1umjk24/' target='_blank'> Comment gérer mes emprunts sur Ownily ?</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title:
          "Vérifier les soldes des Emprunts bancaires à la fin de l'exercice",
        description:
          "Un compteur vous indique les transactions restant à traiter. Rendez-vous dans l'espace Mes Transactions pour les catégoriser ou cliquez sur le compteur affiché.",
        learnMore:
          "<p>Nous constatons fréquemment des erreurs sur le traitement des emprunts : déblocage des fonds, échéances d'emprunts qui nécessitent la plupart du temps un découpage entre capital remboursé, intérêts et/ou assurances. Or ces découpages impactent les charges déductibles et donc le Résultat Fiscal de la SCI. </p><p>C'est pourquoi nous vous invitons à vérifier avec la plus grande attention le traitement de ces transactions en utilisant les filtres associés sur l'espace Mes Transactions ou à partir de la page Résultat de la SCI de l'espace Mes Déclarations fiscales (R4 et Lignes 17). Pour vous y aider consultez aussi l'article <a href='https://ownily.crisp.help/fr/article/comment-gerer-mes-emprunts-sur-ownily-1umjk24/' target='_blank'> Comment gérer mes emprunts sur Ownily ?</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.Transactions },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.SupportingDocuments]: {
      [TaxRegime.IR_2072]: {
        title: "Tous les justificatifs nécessaires sont chargés",
        description:
          "Rendez-vous dans l'espace Mes Transactions pour ajouter les justificatifs. Notamment ceux que vous devrez joindre à la liasse fiscale.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> que tous vos justificatifs sont chargés et bien attachés aux transactions.</p><p>👉 <b>Besoin d'aide</b> pour ajouter vos pièces-jointes ? <a href='https://ownily.crisp.help/fr/article/comment-ajouter-un-justificatif-a-une-recette-ou-a-une-depense-1fos04m/' target='_blank'>On vous aide</a></p>",

        navigateTo: navigateToTransactions(productId, accountingPeriodId, {
          code: FilterKeys.CATEGORIES,
          filterParams: {
            label: "",
            categories: [LedgerAccountEnum.N110001],
          },
        }),
      },
      [TaxRegime.IS_2065]: {
        title: "Tous les justificatifs nécessaires sont chargés",
        description:
          "Rendez-vous dans l'espace Mes Transactions pour ajouter les justificatifs. Notamment ceux que vous devrez joindre à la liasse fiscale.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> que tous vos justificatifs sont chargés et bien attachés aux transactions.</p><p>👉 <b>Besoin d'aide</b> pour ajouter vos pièces-jointes ? <a href='https://ownily.crisp.help/fr/article/is-mes-justificatifs-et-mes-factures-htaswa/' target='_blank'>On vous aide</a></p>",
        navigateTo: navigateToTransactions(productId, accountingPeriodId, {
          code: FilterKeys.CATEGORIES,
          filterParams: {
            label:
              "Transactions qui nécessitent un justificatif obligatoirement",
            categories: (() => {
              // get all categories that require a supporting document
              const res = flatMap(transactionsStore.categoriesList)
                .filter((category) => {
                  return category.fields?.includes(
                    TypeReference.supportingDocument
                  );
                })
                .map((category) => category.number)
                .concat([LedgerAccountEnum.N706000]); // add the rent paiement category as well (N706000)

              return res;
            })(),
            withSupportingDocument: true,
          },
        }),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Tous les justificatifs nécessaires sont chargés",
        description:
          "Rendez-vous dans l'espace Mes Transactions pour ajouter les justificatifs. Notamment ceux que vous devrez joindre à la liasse fiscale.",
        learnMore:
          "<p>Vérifiez dans l'espace <b>\"Mes Transactions\"</b> que tous vos justificatifs sont chargés et bien attachés aux transactions.</p><p>👉 <b>Besoin d'aide</b> pour ajouter vos pièces-jointes ? <a href='https://ownily.crisp.help/fr/article/is-mes-justificatifs-et-mes-factures-htaswa/' target='_blank'>On vous aide</a></p>",
        navigateTo: navigateToTransactions(productId, accountingPeriodId, {
          code: FilterKeys.CATEGORIES,
          filterParams: {
            label:
              "Transactions qui nécessitent un justificatif obligatoirement",
            categories: (() => {
              // get all categories that require a supporting document
              const res = flatMap(transactionsStore.categoriesList)
                .filter((category) => {
                  return category.fields?.includes(
                    TypeReference.supportingDocument
                  );
                })
                .map((category) => category.number)
                .concat([LedgerAccountEnum.N706000]); // add the rent paiement category as well (N706000)

              return res;
            })(),
            withSupportingDocument: true,
          },
        }),
      },
    },
    [TaskCode.BalanceSimple]: {
      [TaxRegime.IR_2072]: {
        title: "Obtenir le bilan simplifié",
        description: `Le bilan n'est **pas obligatoire** pour une SCI à l'IR. Cependant, si vous avez l'habitude d'obtenir un Bilan pour votre SCI, Ownily vous le permet.

  👉 Rendez-vous dans l'espace "Bilan simplifié" pour vérifier, et modifier s'il y a lieu, le montant des soldes du bilan de votre SCI.

  Attention : une fois cette tâche validée, vous ne pourrez plus modifier le montant des soldes du bilan sur Ownily.`,
        learnMore:
          "<p>Le bilan n'est <b>pas obligatoire</b> pour une SCI à l'IR. Cependant, si vous avez l'habitude d'obtenir un Bilan pour votre SCI, Ownily vous le permet. </p><p>👉 Rendez-vous dans l'espace \"Bilan simplifié\" pour vérifier, et modifier s'il y a lieu, le montant des soldes du bilan de votre SCI. </p><p>Attention : une fois cette tâche validée, vous ne pourrez plus modifier le montant des soldes du bilan sur Ownily. </p>",
        isDisabled: !!taskActivityStore.currentAccountingPeriodTaskGroups.find(
          (taskGroup) =>
            (taskGroup.groupCode === TaskGroupCode.TransactionsYearEnd ||
              taskGroup.groupCode === TaskGroupCode.TaxReturnPrepareClose) &&
            taskGroup.status !== TaskActivityStatus.COMPLETED
        ),
        isEnableCustomAction: !accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("closure")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "closure" &&
              accountingBalanceSheet.accountingPeriodId ===
                accountingPeriodsStore.currentId
          )?.isValidated,
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.AccountingBalanceSheet,
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Obtenir le bilan simplifié",
        description: `Le bilan n'est **pas obligatoire** pour une SCI à l'IR. Cependant, si vous avez l'habitude d'obtenir un Bilan pour votre SCI, Ownily vous le permet.

  👉 Rendez-vous dans l'espace "Bilan simplifié" pour vérifier, et modifier s'il y a lieu, le montant des soldes du bilan de votre SCI.

  Attention : une fois cette tâche validée, vous ne pourrez plus modifier le montant des soldes du bilan sur Ownily.`,
        learnMore:
          "<p>Le bilan n'est <b>pas obligatoire</b> pour une SCI à l'IR. Cependant, si vous avez l'habitude d'obtenir un Bilan pour votre SCI, Ownily vous le permet. </p><p>👉 Rendez-vous dans l'espace \"Bilan simplifié\" pour vérifier, et modifier s'il y a lieu, le montant des soldes du bilan de votre SCI. </p><p>Attention : une fois cette tâche validée, vous ne pourrez plus modifier le montant des soldes du bilan sur Ownily. </p>",
        isEnableCustomAction: !accountingBalanceSheetsStore
          .getAccountingBalanceSheetByType("closure")
          .find(
            (accountingBalanceSheet) =>
              accountingBalanceSheet.type === "closure" &&
              accountingBalanceSheet.accountingPeriodId ===
                accountingPeriodsStore.currentId
          )?.isValidated,
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.AccountingBalanceSheet,
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.BuildingConfigured]: {
      [TaxRegime.IR_2072]: {
        title: "Configurer les Immeubles dans la liasse fiscale",
        description: "",
        learnMore:
          "<p>Rendez-vous dans l'espace \"Mes déclarations fiscales\" et \"Résultat de la SCI\" pour vérifier, et modifier s'il y a lieu, la configuration des Immeubles sur la liasse fiscale CERFA 2072. </p><p>Pour en savoir plus, lire sur le centre d'aide l'article à ce sujet \"Comment configurer la liasse Cerfa de ma SCI à l'IR ?\" (lien à ajouter quand l'article sera créé) </p><p>Attention : une fois cette tache validée, vous ne pourrez plus modifier l'organisation de la remontée des recettes et charges déductibles dans la liasse sur Ownily. </p><p>💡 Bonne pratique : référez vous à la liasse déposée l'année précédente. Si le patrimoine de la SCI n'a pas changé en cours d'année, veillez à appliquer les mêmes regroupements si vous avez renseigné plusieurs Biens sur Ownily. </p>",
        isEnableCustomAction:
          !!rentalBuildingsStore.getRealEstateAssetsNotAssignedWithGoodDateByAccountingPeriodId(
            accountingPeriodsStore.currentId,
            ""
          ).length,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.RentalBuildings },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Configurer les Immeubles dans la liasse fiscale",
        description: "",
        learnMore:
          "<p>Rendez-vous dans l'espace \"Mes déclarations fiscales\" et \"Résultat de la SCI\" pour vérifier, et modifier s'il y a lieu, la configuration des Immeubles sur la liasse fiscale CERFA 2072. </p><p>Pour en savoir plus, lire sur le centre d'aide l'article à ce sujet \"Comment configurer la liasse Cerfa de ma SCI à l'IR ?\" (lien à ajouter quand l'article sera créé) </p><p>Attention : une fois cette tache validée, vous ne pourrez plus modifier l'organisation de la remontée des recettes et charges déductibles dans la liasse sur Ownily. </p><p>💡 Bonne pratique : référez vous à la liasse déposée l'année précédente. Si le patrimoine de la SCI n'a pas changé en cours d'année, veillez à appliquer les mêmes regroupements si vous avez renseigné plusieurs Biens sur Ownily. </p>",
        isEnableCustomAction:
          !!rentalBuildingsStore.getRealEstateAssetsNotAssignedWithGoodDateByAccountingPeriodId(
            accountingPeriodsStore.currentId,
            ""
          ).length,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.RentalBuildings },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FillCerfa2072]: {
      [TaxRegime.IR_2072]: {
        title:
          "Vérifier les montants de la Cerfa 2072 et ajouter le montant des travaux de rénovation énergétique (nouveauté 2024)",
        description:
          "Rendez-vous dans l'espace Mes déclarations fiscales > Résultat de la SCI pour obtenir les montants à reporter sur votre liasse CERFA 2072, Annexe 1. N'oubliez pas : certaines informations sont à renseigner par vos soins.",
        learnMore:
          "<p><strong>💡 Nouveauté fiscale suite à la loi finance de 2022 : le doublement du déficit foncier en cas de rénovation énergétique.</strong></p><p>Les travaux doivent permettre au logement de passer d'une classe énergétique E, F ou G à une classe énergétique A, B, C ou D, au plus tard le 31 décembre 2025.</p><p>Saisissez dans la ligne 9BIS de la CERFA 2072 le montant des travaux qui ont permis de faire passer vos biens d'un DPE E, F, G à un DPE A, B, C, D et profiter du doublement de déficit foncier dans votre déclaration de revenus par associé.</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.SciIncome, hash: "#line9b" },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Vérifier les montants de la Cerfa 2072 de la SCI",
        description:
          "Rendez-vous dans l'espace Mes déclarations fiscales > Résultat de la SCI pour obtenir les montants à reporter sur votre liasse CERFA 2072, Annexe 1. N'oubliez pas : certaines informations sont à renseigner par vos soins.",
        learnMore:
          '<p>Rendez-vous dans l\'espace <b>"Mes déclarations fiscales"</b> et <b>"Résultat de la SCI"</b> pour obtenir tous les montants à reporter ligne par ligne sur votre liasse CERFA 2072, Annexe 1. </p><p>💡 N\'oubliez pas : certaines informations sont à renseigner par vos soins. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.SciIncome },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.UploadCerfa2072]: {
      [TaxRegime.IR_2072]: {
        title: "Télétransmettre la liasse Cerfa 2072",
        description:
          "Vous avez validé les comptes en Assemblée générale, complété la liasse de la SCI : il vous reste à télétransmettre la liasse dans les temps par un prestataire agréé.",
        learnMore:
          "<p>Vous avez <b>validé les comptes</b> en assemblée générale et <b>complété la liasse</b> de la SCI sur Ownily : il vous reste à <b>télétransmettre</b> la liasse dans les temps (calendrier fiscal de référence et date butoir disponibles chaque année sur <a href='https://www.impots.gouv.fr/professionnel/calendrier-fiscal' target='_blank'> impots.gouv</a>) auprès de l'administration fiscale. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.SciIncome },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Télétransmettre la liasse Cerfa 2072",
        description:
          "Vous avez validé les comptes en Assemblée générale, complété la liasse de la SCI : il vous reste à télétransmettre la liasse dans les temps par un prestataire agréé.",
        learnMore:
          "<p>Vous avez <b>validé les comptes</b> en assemblée générale et <b>complété la liasse</b> de la SCI sur Ownily : il vous reste à <b>télétransmettre</b> la liasse dans les temps (calendrier fiscal de référence et date butoir disponibles chaque année sur <a href='https://www.impots.gouv.fr/professionnel/calendrier-fiscal' target='_blank'> impots.gouv</a>) auprès de l'administration fiscale. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.SciIncome },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FillCerfa2033]: {
      [TaxRegime.IS_2065]: {
        title: "Obtenir mon CERFA 2033 (bilan et compte de résultat fiscal)",
        learnMore:
          "<p>Votre CERFA 2033 reprend votre compte de résultat et votre bilan sour le format spécifique attendu par l'administration fiscale. Ce cerfa est à transmettre aussi à l'administration fiscale avec votre déclaration de l'année présenté par le CERFA 2065</p><p>Il vient expliquer les montants des revenus déclarer dans votre déclaration de revenus CERFA 2065.</p><p>Cette déclaraticiton est à télétransmettre avec votre CERFA 2033.</p><p>Il est généré automatiquement par Ownily et est disponible dans votre espace document dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes déclarations fiscales - Déclaration 2033\".</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2033 },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Obtenir mon CERFA 2033 (bilan et compte de résultat fiscal)",
        learnMore:
          "<p>Votre CERFA 2033 reprend votre compte de résultat et votre bilan sour le format spécifique attendu par l'administration fiscale. Ce cerfa est à transmettre aussi à l'administration fiscale avec votre déclaration de l'année présenté par le CERFA 2065</p><p>Il vient expliquer les montants des revenus déclarer dans votre déclaration de revenus CERFA 2065.</p><p>Cette déclaraticiton est à télétransmettre avec votre CERFA 2033.</p><p>Il est généré automatiquement par Ownily et est disponible dans votre espace document dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes déclarations fiscales - Déclaration 2033\".</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2033 },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FillCerfa2065]: {
      [TaxRegime.IS_2065]: {
        title: "Obtenir mon CERFA 2065 (déclaration de revenus IS)",
        isEnableCustomAction: true,
        learnMore:
          "<p>Votre CERFA 2065 est votre déclaration de revenus de votre SCI.</p><p>Cette déclaration permet à l'administration fiscale de calculer le montant de votre impôt sur les sociétés dû au titre de cet exercice comptable.</p><p>Cette déclaration est à télétransmettre avec votre CERFA 2033</p><p>Cette déclaration est à télétransmettre avec votre CERFA 2033.</p><p>Il est généré automatiquement par Ownily et est disponible dans votre espace document dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes déclarations fiscales - Résultat de la SCI\"</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2065 },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FillCerfa2031]: {
      [TaxRegime.LMNP_2031]: {
        title: "Obtenir mon CERFA 2031 (déclaration de revenus LMNP) ",
        learnMore:
          "<p>Votre CERFA 2031 est votre déclaration de revenus de votre activité.</p><p>Cette déclaration permet à l'administration fiscale de calculer le montant de votre impôt sur les scoéités dû au titre de cet exercice comptable.</p><p>Cette déclaration est à télétransmettre avec votre CERFA 2033</p><p>Cette déclaration est à télétransmettre avec votre CERFA 2033.</p><p>Il est générer automatiquement par Ownily et est disponible dans votre espace document dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes déclaration Fiscale - Déclaration 2031\"</p>",
        isEnableCustomAction: true,
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2031 },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FillFec]: {
      [TaxRegime.IS_2065]: {
        title: "Obtenir mon FEC normé",
        learnMore:
          "<p>Le FEC normé est un document qui retrace toutes vos opérations comptables. Il est structuré selon un format spécifique demandé par l'administration fiscale.</p><p>Il doit être transmis avec les deux autres cerfas 2033 et 2065 lors de votre dépot des déclarations fiscales.</p><p>L'administration s'en seert en cas de contrôle fiscal pour retracer vos opérations et controler l'exactitude de votre enregistrement comptable et donc des revenus déclarés.</p><p>Ownily vous fournit le document généré sous le bon format.</p><p>Il est généré automatiquement par Ownily et est disponible dans votre espace documentaire dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes documents comptables - Mon Fec\"</p>",
        navigateTo: () => Promise.resolve(),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Obtenir mon FEC normé",
        learnMore:
          "<p>Le FEC normé est un document qui retrace toutes vos opérations comptables. Il est structuré selon un format spécifique demandé par l'administration fiscale.</p><p>Il doit être transmis avec les deux autres cerfas 2033 et 2065 lors de votre dépot des déclarations fiscales.</p><p>L'administration s'en seert en cas de contrôle fiscal pour retracer vos opérations et controler l'exactitude de votre enregistrement comptable et donc des revenus déclarés.</p><p>Ownily vous fournit le document généré sous le bon format.</p><p>Il est généré automatiquement par Ownily et est disponible dans votre espace documentaire dans l'exercice de l'année en cours.</p><p>Vous pouvez aussi le télécharger depuis la page \"Mes documents comptables - Mon Fec\"</p>",
        navigateTo: () => Promise.resolve(),
      },
    },
    [TaskCode.CheckPartnersResults]: {
      [TaxRegime.IR_2072]: {
        title: "Vérifier les calculs par associé",
        description:
          "Retrouvez sur l'espace Mes déclarations fiscales > Revenus des associés les Parts Fiscales et les montants à reporter par chacun des associés sur sa propre déclaration de revenus. Vérifiez bien qu'Ownily dispose des informations à jour dans les paramétrages de la SCI.",
        learnMore:
          "<p>Retrouvez sur l'espace <b>\"Mes déclarations fiscales\"</b> et <b>\"Revenus des associés\"</b> les Parts Fiscales et les montants à reporter par chacun des associés sur sa propre déclaration de revenus (CERFA 2042/2044). </p><p>👉 Vérifiez bien que le paramétrage des associés et des parts sociales est à jour dans les <a href='https://ownily.crisp.help/fr/article/comment-retrouver-et-modifier-les-informations-de-ma-sci-sur-ownily-1of9mz9/' target='_blank'> paramétrages de la SCI</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.PartnersIncome },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Vérifier les calculs par associé",
        description:
          "Retrouvez sur l'espace Mes déclarations fiscales > Revenus des associés les Parts Fiscales et les montants à reporter par chacun des associés sur sa propre déclaration de revenus. Vérifiez bien qu'Ownily dispose des informations à jour dans les paramétrages de la SCI. ",
        learnMore:
          "<p>Retrouvez sur l'espace <b>\"Mes déclarations fiscales\"</b> et <b>\"Revenus des associés\"</b> les Parts Fiscales et les montants à reporter par chacun des associés sur sa propre déclaration de revenus (CERFA 2042/2044). </p><p>👉 Vérifiez bien que le paramétrage des associés et des parts sociales est à jour dans les <a href='https://ownily.crisp.help/fr/article/comment-retrouver-et-modifier-les-informations-de-ma-sci-sur-ownily-1of9mz9/' target='_blank'> paramétrages de la SCI</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.PartnersIncome },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.SendPartnersResults]: {
      [TaxRegime.IR_2072]: {
        title: "Transmettre les quotes-parts aux associés",
        description:
          "Rendez-vous sur l'espace Mes déclarations fiscales > Revenus des associés pour éditer les relevés de situation à transmettre à chacun en temps et en heure.",
        learnMore:
          '<p>Une fois la SCI bien paramétrée et les transactions bien catégorisées, rendez-vous sur l\'espace <b>"Mes déclarations fiscales"</b> et <b>"Revenus des associés"</b> pour éditer les relevés de situation à transmettre à chacun des associés en temps et en heure. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.PartnersIncome },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Transmettre les quotes-parts aux associés",
        description:
          "Rendez-vous sur l'espace Mes déclarations fiscales > Revenus des associés pour éditer les relevés de situation à transmettre à chacun en temps et en heure.",
        learnMore:
          '<p>Une fois la SCI bien paramétrée et les transactions bien catégorisées, rendez-vous sur l\'espace <b>"Mes déclarations fiscales"</b> et <b>"Revenus des associés"</b> pour éditer les relevés de situation à transmettre à chacun des associés en temps et en heure. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.PartnersIncome },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.Invite]: {
      [TaxRegime.IR_2072]: {
        title: "Envoyer la convocation",
        description:
          "Editez facilement sur Ownily les convocations de vos associés dans l'espace Mes Evénements > AG Ordinaire. Si toutefois vous n'utilisez pas notre service, pensez à charger la Coonvocation dans l'Espace Mes Documents de la SCI pour retrouver facilement ce document.",
        learnMore:
          '<p>Pour organiser votre Assemblée Générale en bon et due forme et respecter vos obligations, éditez et envoyez les convocations à vos associés. </p><p>👉 Sur Ownily, les convocations de vos associés sont éditables depuis l\'espace <b>"Mes événements" > "Assemblée Générale"</b>. </p><p>👉 Si toutefois vous n\'utilisez pas notre service, pensez à <b>charger les convocations</b> dans l\'Espace <b>"Mes Documents"</b> de la SCI pour les retrouver facilement. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.EventsGeneralAssemblyDetails },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Envoyer la convocation",
        description:
          "Editez facilement sur Ownily les convocations de vos associés dans l'espace Mes Evénements > AG Ordinaire. Si toutefois vous n'utilisez pas notre service, pensez à charger la Coonvocation dans l'Espace Mes Documents de la SCI pour retrouver facilement ce document.",
        learnMore:
          '<p>Pour organiser votre Assemblée Générale en bon et due forme et respecter vos obligations, éditez et envoyez les convocations à vos associés. </p><p>👉 Sur Ownily, les convocations de vos associés sont éditables depuis l\'espace <b>"Mes événements" > "Assemblée Générale"</b>. </p><p>👉 Si toutefois vous n\'utilisez pas notre service, pensez à <b>charger les convocations</b> dans l\'Espace <b>"Mes Documents"</b> de la SCI pour les retrouver facilement. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.EventsGeneralAssemblyDetails },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Envoyer la convocation",
        description:
          "Editez facilement sur Ownily les convocations de vos associés dans l'espace Mes Evénements > AG Ordinaire. Si toutefois vous n'utilisez pas notre service, pensez à charger la Coonvocation dans l'Espace Mes Documents de la SCI pour retrouver facilement ce document.",
        learnMore:
          '<p>Pour organiser votre Assemblée Générale en bon et due forme et respecter vos obligations, éditez et envoyez les convocations à vos associés. </p><p>👉 Sur Ownily, les convocations de vos associés sont éditables depuis l\'espace <b>"Mes événements" > "Assemblée Générale"</b>. </p><p>👉 Si toutefois vous n\'utilisez pas notre service, pensez à <b>charger les convocations</b> dans l\'Espace <b>"Mes Documents"</b> de la SCI pour les retrouver facilement. </p>',
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.EventsGeneralAssemblyDetails },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.Report]: {
      [TaxRegime.IR_2072]: {
        title: "Rédiger le Procès verbal et l'envoyer",
        description:
          "Vous devez envoyer et archiver le PV de l'AG. Rédigez simplement le PV sur Ownily. Il sera automatiquement archivé. Si toutefois vous n'utilisez pas notre service, pensez à charger le PV dans l'Espace Mes Documents de la SCI pour le retrouver facilement.",
        learnMore:
          "<p>Pour finaliser votre Assemblée Générale en bon et due forme et respecter vos obligations, vous devez <b>rédiger, envoyer et archiver</b> le Procès Verbal de l'Assemblée Générale. </p><p>👉 Rédigez et éditez rapidement le Procès Verbal sur Ownily en utilisant notre outil. Il sera automatiquement archivé. </p><p>👉 Si toutefois vous n'utilisez pas notre service, pensez à <b>charger le Procès Verbal</b> dans l'Espace <b>\"Mes Documents\"</b> de la SCI pour le retrouver facilement. </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
            params: {
              productId: productId as string,
              id: assemblyId as string,
            },
          },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Rédiger le Procès verbal et l'envoyer",
        description:
          "Vous devez envoyer et archiver le PV de l'AG. Rédigez simplement le PV sur Ownily. Il sera automatiquement archivé. Si toutefois vous n'utilisez pas notre service, pensez à charger le PV dans l'Espace Mes Documents de la SCI pour le retrouver facilement.",
        learnMore:
          "<p>Pour finaliser votre Assemblée Générale en bon et due forme et respecter vos obligations, vous devez <b>rédiger, envoyer et archiver</b> le Procès Verbal de l'Assemblée Générale. </p><p>👉 Rédigez et éditez rapidement le Procès Verbal sur Ownily en utilisant notre outil. Il sera automatiquement archivé. </p><p>👉 Si toutefois vous n'utilisez pas notre service, pensez à <b>charger le Procès Verbal</b> dans l'Espace <b>\"Mes Documents\"</b> de la SCI pour le retrouver facilement. </p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
            params: {
              productId: productId as string,
              id: assemblyId as string,
            },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.Create]: {
      [TaxRegime.IR_2072]: {
        title: "Programmer l'AG",
        description:
          "Avant de déclarer les revenus, vous avez l'obligation d'organiser une assemblée générale ordinaire avec vos associés afin d'approuver les comptes annuels. Programmez votre AG sur ownily dans l'Espace Mes Evenements et laissez vous guider.",
        learnMore:
          "<p>Avant de télé-déclarer les revenus de la SCI, pensez à organiser une <b>Assemblée Générale d'Approbation des comptes</b> avec vos associés afin d'approuver les comptes annuels. Cet évènement est une <b>obligation</b> à tenir chaque année. </p><p>👉 Programmez votre AG sur Ownily depuis l'Espace <b>\"Mes événements\" > \"Assemblée Générale\"</b> et laissez vous guider grâce à <a href='https://ownily.crisp.help/fr/article/comment-realiser-mon-ag-annuelle-sur-ownily-1mfhb2z/' target='_blank'> cet article</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.EventsGeneralAssembly },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Programmer l'AG",
        description:
          "Avant de déclarer les revenus, vous avez l'obligation d'organiser une assemblée générale ordinaire avec vos associés afin d'approuver les comptes annuels. Programmez votre AG sur ownily dans l'Espace Mes Evenements et laissez vous guider.",
        learnMore:
          "<p>Avant de télé-déclarer les revenus de la SCI, pensez à organiser une <b>Assemblée Générale d'Approbation des comptes</b> avec vos associés afin d'approuver les comptes annuels. Cet évènement est une <b>obligation</b> à tenir chaque année. </p><p>👉 Programmez votre AG sur Ownily depuis l'Espace <b>\"Mes événements\" > \"Assemblée Générale\"</b> et laissez vous guider grâce à <a href='https://ownily.crisp.help/fr/article/comment-realiser-mon-ag-annuelle-sur-ownily-1mfhb2z/' target='_blank'> cet article</a>. </p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.EventsGeneralAssembly },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.InviteYearEnd]: {
      [TaxRegime.IR_2072]: {
        title: "Envoyer la convocation",
        description:
          "Editez facilement sur Ownily les convocations de vos associés dans l'espace Mes Evénements > AG Ordinaire. Si toutefois vous n'utilisez pas notre service, pensez à charger la Coonvocation dans l'Espace Mes Documents de la SCI pour retrouver facilement ce document.",
        learnMore:
          '<p>Pour organiser votre Assemblée Générale en bon et due forme et respecter vos obligations, éditez et envoyez les convocations à vos associés. </p><p>👉 Sur Ownily, les convocations de vos associés sont éditables depuis l\'espace <b>"Mes événements" > "Assemblée Générale"</b>. </p><p>👉 Si toutefois vous n\'utilisez pas notre service, pensez à <b>charger les convocations</b> dans l\'Espace <b>"Mes Documents"</b> de la SCI pour les retrouver facilement. </p>',
        isEnableCustomAction:
          taskActivityStore.closureTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                TaskGroupCode.EventGeneralAssemblyYearEnd
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return (
                taskActivity?.code === TaskCode.Create &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.product &&
                      reference.referredId === productsStore.currentProduct?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type ===
                        TaskActivityTypeReference.accountingPeriod &&
                      reference.referredId ===
                        accountingPeriodsStore.currentAccountingPeriod?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.assembly
                    );
                  }
                ) &&
                router.currentRoute.name !==
                  ROUTE_NAMES.EventsGeneralAssemblyDetails
              );
            })?.status === TaskActivityStatus.COMPLETED,
        navigateTo:
          taskActivity.status === TaskActivityStatus.COMPLETED
            ? navigateToPage(
                {
                  name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                  params: {
                    productId: productId as string,
                    id: assemblyId as string,
                  },
                },
                productId,
                accountingPeriodId
              )
            : () => Promise.resolve(),
      },
      [TaxRegime.IS_2065]: {
        title: "Envoyer la convocation",
        description:
          "Editez facilement sur Ownily les convocations de vos associés dans l'espace Mes Evénements > AG Ordinaire. Si toutefois vous n'utilisez pas notre service, pensez à charger la Coonvocation dans l'Espace Mes Documents de la SCI pour retrouver facilement ce document.",
        learnMore:
          '<p>Pour organiser votre Assemblée Générale en bon et due forme et respecter vos obligations, éditez et envoyez les convocations à vos associés. </p><p>👉 Sur Ownily, les convocations de vos associés sont éditables depuis l\'espace <b>"Mes événements" > "Assemblée Générale"</b>. </p><p>👉 Si toutefois vous n\'utilisez pas notre service, pensez à <b>charger les convocations</b> dans l\'Espace <b>"Mes Documents"</b> de la SCI pour les retrouver facilement. </p>',
        isEnableCustomAction:
          taskActivityStore.closureTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                TaskGroupCode.EventGeneralAssemblyYearEnd
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return (
                taskActivity?.code === TaskCode.Create &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.product &&
                      reference.referredId === productsStore.currentId
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type ===
                        TaskActivityTypeReference.accountingPeriod &&
                      reference.referredId ===
                        accountingPeriodsStore.currentAccountingPeriod?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.assembly
                    );
                  }
                ) &&
                router.currentRoute.name !== "EventsGeneralAssemblyDetails"
              );
            })?.status === TaskActivityStatus.COMPLETED,
        navigateTo:
          taskActivity.status === TaskActivityStatus.COMPLETED
            ? navigateToPage(
                {
                  name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                  params: {
                    productId: productId as string,
                    id: assemblyId as string,
                  },
                },
                productId,
                accountingPeriodId
              )
            : () => Promise.resolve(),
      },
    },
    [TaskCode.ReportYearEnd]: {
      [TaxRegime.IR_2072]: {
        title: "Rédiger le Procès verbal et l'envoyer",
        description:
          "Vous devez envoyer et archiver le PV de l'AG. Rédigez simplement le PV sur Ownily. Il sera automatiquement archivé. Si toutefois vous n'utilisez pas notre service, pensez à charger le PV dans l'Espace Mes Documents de la SCI pour le retrouver facilement.",
        learnMore:
          "<p>Pour finaliser votre Assemblée Générale en bon et due forme et respecter vos obligations, vous devez <b>rédiger, envoyer et archiver</b> le Procès Verbal de l'Assemblée Générale. </p><p>👉 Rédigez et éditez rapidement le Procès Verbal sur Ownily en utilisant notre outil. Il sera automatiquement archivé. </p><p>👉 Si toutefois vous n'utilisez pas notre service, pensez à <b>charger le Procès Verbal</b> dans l'Espace <b>\"Mes Documents\"</b> de la SCI pour le retrouver facilement. </p>",
        isEnableCustomAction:
          taskActivityStore.closureTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                TaskGroupCode.EventGeneralAssemblyYearEnd
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return (
                taskActivity?.code === TaskCode.Create &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.product &&
                      reference.referredId === productsStore.currentProduct?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type ===
                        TaskActivityTypeReference.accountingPeriod &&
                      reference.referredId ===
                        accountingPeriodsStore.currentAccountingPeriod?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.assembly
                    );
                  }
                ) &&
                router.currentRoute.name !==
                  ROUTE_NAMES.EventsGeneralAssemblyDetails
              );
            })?.status === TaskActivityStatus.COMPLETED,
        navigateTo: !assemblyId
          ? () => Promise.resolve()
          : taskActivity.status === TaskActivityStatus.PENDING
          ? navigateToPage(
              {
                name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                params: {
                  productId: productId as string,
                  id: assemblyId as string,
                },
                query: {
                  openAgPVModal: "true",
                },
              },
              productId,
              accountingPeriodId
            )
          : taskActivity.status === TaskActivityStatus.COMPLETED
          ? navigateToPage(
              {
                name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                params: {
                  productId: productId as string,
                  id: assemblyId as string,
                },
              },
              productId,
              accountingPeriodId
            )
          : () => Promise.resolve(),
      },
      [TaxRegime.IS_2065]: {
        title: "Rédiger le Procès verbal et l'envoyer",
        description:
          "Vous devez envoyer et archiver le PV de l'AG. Rédigez simplement le PV sur Ownily. Il sera automatiquement archivé. Si toutefois vous n'utilisez pas notre service, pensez à charger le PV dans l'Espace Mes Documents de la SCI pour le retrouver facilement.",
        learnMore:
          "<p>Pour finaliser votre Assemblée Générale en bon et due forme et respecter vos obligations, vous devez <b>rédiger, envoyer et archiver</b> le Procès Verbal de l'Assemblée Générale. </p><p>👉 Rédigez et éditez rapidement le Procès Verbal sur Ownily en utilisant notre outil. Il sera automatiquement archivé. </p><p>👉 Si toutefois vous n'utilisez pas notre service, pensez à <b>charger le Procès Verbal</b> dans l'Espace <b>\"Mes Documents\"</b> de la SCI pour le retrouver facilement. </p>",
        isEnableCustomAction:
          taskActivityStore.closureTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode ===
                TaskGroupCode.EventGeneralAssemblyYearEnd
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return (
                taskActivity?.code === TaskCode.Create &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.product &&
                      reference.referredId === productsStore.currentId
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type ===
                        TaskActivityTypeReference.accountingPeriod &&
                      reference.referredId ===
                        accountingPeriodsStore.currentAccountingPeriod?.id
                    );
                  }
                ) &&
                taskActivity?.references?.some(
                  (reference: TaskActivityReference) => {
                    return (
                      reference.type === TaskActivityTypeReference.assembly
                    );
                  }
                ) &&
                router.currentRoute.name !== "EventsGeneralAssemblyDetails"
              );
            })?.status === TaskActivityStatus.COMPLETED,
        navigateTo: !assemblyId
          ? () => Promise.resolve()
          : taskActivity.status === TaskActivityStatus.PENDING
          ? navigateToPage(
              {
                name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                params: {
                  productId: productId as string,
                  id: assemblyId as string,
                },
                query: {
                  openAgPVModal: "true",
                },
              },
              productId,
              accountingPeriodId
            )
          : taskActivity.status === TaskActivityStatus.COMPLETED
          ? navigateToPage(
              {
                name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
                params: {
                  productId: productId as string,
                  id: assemblyId as string,
                },
              },
              productId,
              accountingPeriodId
            )
          : () => Promise.resolve(),
      },
    },
    [TaskCode.PreviousYearAmortisation]: {
      [TaxRegime.IR_2072]: {
        title: "",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaskActivity },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaskActivity },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.SubscriptionActive]: {
      [TaxRegime.IR_2072]: {
        title: "Activation de votre abonnement réussie",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "succeeded",
            },
          },
          productId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Activation de votre abonnement réussie",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "succeeded",
            },
          },
          productId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Activation de votre abonnement réussie",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "succeeded",
            },
          },
          productId
        ),
      },
    },
    [TaskCode.SubscriptionError]: {
      [TaxRegime.IR_2072]: {
        title: "Activation de votre abonnement en erreur",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "canceled",
            },
          },
          productId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Activation de votre abonnement en erreur",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "canceled",
            },
          },
          productId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Activation de votre abonnement en erreur",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.SubscriptionDetailsContinue,
            params: {
              subscriptionId: subscriptionId as string,
              stripeReturnStatus: "canceled",
            },
          },
          productId
        ),
      },
    },
    [TaskCode.ProductUpdate]: {
      [TaxRegime.IR_2072]: {
        title: "Il y a du nouveau sur Ownily !",
        navigateTo: async () => {
          const taskActivity =
            taskActivityStore.userNotificationsTaskActivities.filter(
              (taskActivity) => taskActivity.code === TaskCode.ProductUpdate
            );
          if (taskActivity[0].data?.taxRegime === TaxRegime.IR_2072) {
            useCrisp().openArticle(ArticleEnum.PRODUCT_UPDATE_IR);
          }

          taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity[0],
          });
          await navigateToPage("", productId);
        },
      },
      [TaxRegime.IS_2065]: {
        title: "Il y a du nouveau sur Ownily !",
        navigateTo: async () => {
          const taskActivity =
            taskActivityStore.userNotificationsTaskActivities.filter(
              (taskActivity) => taskActivity.code === TaskCode.ProductUpdate
            );
          if (taskActivity[0].data?.taxRegime === TaxRegime.IS_2065) {
            useCrisp().openArticle(ArticleEnum.PRODUCT_UPDATE_IS);
          }

          taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity[0],
          });
          await navigateToPage("", productId);
        },
      },
      [TaxRegime.LMNP_2031]: {
        title: "Il y a du nouveau sur Ownily !",
        navigateTo: async () => {
          const taskActivity =
            taskActivityStore.userNotificationsTaskActivities.filter(
              (taskActivity) => taskActivity.code === TaskCode.ProductUpdate
            );
          if (taskActivity[0].data?.taxRegime === TaxRegime.LMNP_2031) {
            useCrisp().openArticle(ArticleEnum.PRODUCT_UPDATE_LMNP);
          }

          taskActivityStore.validateTaskActivity({
            taskActivityLocal: taskActivity[0],
          });
          await navigateToPage("", productId);
        },
      },
    },
    [TaskCode.ActivityInformationCompleted]: {
      [TaxRegime.LMNP_2031]: {
        title: "Compléter les informations de votre activité LMNP",
        learnMore:
          "<p>Les informations de votre activité LMNP sont nécessaires pour que Ownily complète automatiquement votre déclaration fiscale.</p><p>Ce sont les informations que vous avez reportées dans votre déclaration d'activité</p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.MyBusiness,
            query: {
              tabIndex: "1",
            },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.LoanAdd]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajouter votre emprunt",
        learnMore:
          "<p>Veuillez compléter les informations de votre emprunt pour centraliser les informations de votre LMNP pour pouvoir Complétez reprise du bilan si ce n'est pas votre première année d'exercice LMNP.</p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.RealEstateAssets,
            params: { productId: productId as string },
            query: {
              tabIndex: "1",
            },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.ActivityEmailAdd]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajouter une adresse email à l'activité LMNP",
        learnMore:
          "<p>Veuillez indiquer l'adresse email de votre activité LMNP. Cette adresse email sera utilisée pour la facturation sur Ownily ainsi que pour vos déclarations fiscales auprès des impôts. Vous pouvez utilisez l'adresse email que vous avez utilisée pour obtenir votre SIRET</p>",
        navigateTo: navigateToPage(
          {
            name: ROUTE_NAMES.MyBusiness,
            query: {
              tabIndex: "1",
            },
          },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.FirstTransactionAdd]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajouter votre première transaction",
        learnMore:
          "<p><b>Comme vous n'avez pas de compte bancaire dédié à votre LMNP, veuillez ajouter vos transactions manuellement.</b></p><p>Les transactions à ajouter sont celles liées aux revenus et aux charges de votre activités LMNP</p><ul><li>• les loyers perçus</li><li>• les charges liées à l'activités LMNP</li><li>• les mensualités et intérets/assurances de votre emprunt</li><li>• les transactions liées au paiement ds taxes et impôts de votre activités LMNP</li></ul>",
        learnMoreOpened: true,
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.Transactions,
              query: {
                openManualTransaction: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.Transactions,
            });
          }
        })(),
      },
    },
    [TaskCode.DeferrableAmortisationsAdd]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajoutez vos amortissements reportables",
        learnMore:
          "<p>Les amortissements reportables sont les amortissements de vos biens des années précédentes que vous n'aviez pas pu déduire.</p><p>Vous devriez retrouver ce montant dans votre dernière déclaration 2033. Si vous ne la connaissez pas, vous pouvez saisir 0€</p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
              query: {
                openAmortisationEdit: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
            });
          }
        })(),
      },
    },
    [TaskCode.DeferrableDeficitsAdd]: {
      [TaxRegime.LMNP_2031]: {
        title: "Ajoutez vos déficits reportables",
        learnMore:
          "<p>Les déficits reportables sont les déficits accumulés sur les années précédentes que vous n'aviez pas pu déduire des résultats précédents.</p><p>Ce montant est disponible dans votre dernier CERFA 2033-B Page RELEVE DES PROVISIONS - AMORTISSEMENTS DEROGATOIRES - DEFICITS Section II : DEFICITS REPORTABLES</p><p>=> Case 870: Total des déficits restant à reporter</p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
              query: {
                openDeficitEdit: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
            });
          }
        })(),
      },
    },
    [TaskCode.DeferrableDeficitsAddVerify]: {
      [TaxRegime.LMNP_2031]: {
        title: "Vérifiez que vous avez bien ajouté vos déficits reportables",
        learnMore:
          "<p>Les déficits reportables sont les déficits accumulés sur les années précédentes que vous n'aviez pas pu déduire des résultats précédents.</p><p>Ce montant est disponible dans votre dernier CERFA 2033-B Page RELEVE DES PROVISIONS - AMORTISSEMENTS DEROGATOIRES - DEFICITS Section II : DEFICITS REPORTABLES</p><p>=> Case 870: Total des déficits restant à reporter</p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
              query: {
                openDeficitEdit: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
            });
          }
        })(),
      },
    },
    [TaskCode.DeferrableAmortisationsAddVerify]: {
      [TaxRegime.LMNP_2031]: {
        title:
          "Vérifiez que vous avez bien ajouté vos amortissements reportables",
        learnMore:
          "<p>Les amortissements reportables sont les amortissements de vos biens des années précédentes que vous n'aviez pas pu déduire.</p><p>Vous devriez retrouver ce montant dans votre dernière déclaration 2033. Si vous ne la connaissez pas, vous pouvez saisir 0€</p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
              query: {
                openAmortisationEdit: "true",
              },
            });
          } else {
            return navigateToPage({
              name: ROUTE_NAMES.AccountingCarryForward,
            });
          }
        })(),
      },
    },
    [TaskCode.CheckEligibleWorksAreImmobilized]: {
      [TaxRegime.IS_2065]: {
        title:
          "Vérifier que j'ai immobilisé tous les travaux éligibles cette année",
        learnMore:
          "<p>Vous avez le droit d'immobiliser les charges de travaux ou d'achat de meubles dont la valeur est supérieure à 500 euros durant l'année.</p><p>Ainsi, si vous avez réalisé des travaux de peinture ou de ravalement de façade où acheté des nouveaux meubles pour vos biens, vous devez les comptabiliser en tant qu'investissements (et non charges) et les amortir sur plusieurs années.</p><p>Sur Ownily, catégoriser ces transactions avec les catégories Investissements et Achats et suivez le parcours.</p>",
        navigateTo: (() =>
          navigateToTransactions(productId, accountingPeriodId, {
            code: FilterKeys.CATEGORIES,
            filterParams: {
              label: "Investissements & achats | travaux",
              categories: [
                // INVESTISSEMENTS ET ACHATS
                LedgerAccountEnum.N201000,
                LedgerAccountEnum.N201100,
                LedgerAccountEnum.N211100,
                LedgerAccountEnum.N213100,
                LedgerAccountEnum.N213500,
                LedgerAccountEnum.N215700,
                LedgerAccountEnum.N218400,
                LedgerAccountEnum.N271000,
                LedgerAccountEnum.N272000,
                LedgerAccountEnum.N280100,
                LedgerAccountEnum.N281310,
                LedgerAccountEnum.N281840,
                LedgerAccountEnum.N781100,
                LedgerAccountEnum.N681110,
                LedgerAccountEnum.N681120,
                LedgerAccountEnum.N622000,
                LedgerAccountEnum.N213000,

                // TRAVAUX :
                LedgerAccountEnum.N615200,
                LedgerAccountEnum.N615210,
                LedgerAccountEnum.N615310,
              ],
            },
          }))(),
      },
      [TaxRegime.LMNP_2031]: {
        title:
          "Vérifier que j'ai immobilisé tous les travaux éligibles cette année",
        learnMore:
          "<p>Vous avez le droit d'immobiliser les charges de travaux ou d'achat de meubles dont la valeur est supérieure à 500 euros durant l'année.</p><p>Ainsi, si vous avez réalisé des travaux de peinture ou de ravalement de façade où acheté des nouveaux meubles pour vos biens, vous devez les comptabiliser en tant qu'investissements (et non charges) et les amortir sur plusieurs années.</p><p>Sur Ownily, catégoriser ces transactions avec les catégories Investissements et Achats et suivez le parcours.</p>",
        navigateTo: (() =>
          navigateToTransactions(productId, accountingPeriodId, {
            code: FilterKeys.CATEGORIES,
            filterParams: {
              label: "Investissements & achats | travaux",
              categories: [
                // INVESTISSEMENTS ET ACHATS
                LedgerAccountEnum.N201000,
                LedgerAccountEnum.N201100,
                LedgerAccountEnum.N211100,
                LedgerAccountEnum.N213100,
                LedgerAccountEnum.N213500,
                LedgerAccountEnum.N215700,
                LedgerAccountEnum.N218400,
                LedgerAccountEnum.N271000,
                LedgerAccountEnum.N272000,
                LedgerAccountEnum.N280100,
                LedgerAccountEnum.N281310,
                LedgerAccountEnum.N281840,
                LedgerAccountEnum.N781100,
                LedgerAccountEnum.N681110,
                LedgerAccountEnum.N681120,
                LedgerAccountEnum.N622000,
                LedgerAccountEnum.N213000,

                // TRAVAUX :
                LedgerAccountEnum.N615200,
                LedgerAccountEnum.N615210,
                LedgerAccountEnum.N615310,
              ],
            },
          }))(),
      },
    },
    [TaskCode.YearEndAmortizationUpdate]: {
      [TaxRegime.IS_2065]: {
        title: "Vérifier que tous les amortissements sont bien paramétrés",
        description:
          "Les données de votre amortissement doivent être renseignées pour vos biens",
        learnMore:
          "<p>Vous devez paramétrer l'amortissement de vos biens et immobilisations afin de pouvoir prendre en compte leur montant dans la déclaration de revenus de votre SCI.</p><p>Pensez à bien indiquer la date de mise en service de chaque bien et immobilisation qui défini à partir de quand nous devons prendre en compte vos amortissements.</p><p>Utilisez le module d'Ownily qui propose les recommendations de l'administration fiscale pour paramétrer les amortissements.</p>",
        isEnableCustomAction:
          taskActivityStore.startWellTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccounting
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return taskActivity.code === TaskCode.AssetUpdate;
            })?.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          return navigateToPage(
            { name: ROUTE_NAMES.Amortisations },
            productId,
            accountingPeriodId
          );
        })(),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Vérifier que tous les amortissements sont bien paramétrés",
        description:
          "Les données de votre amortissement doivent être renseignées pour vos biens",
        learnMore:
          "<p>Vous devez paramétrer l'amortissement de vos biens et immobilisations afin de pouvoir prendre en compte leur montant dans la déclaration de revenus de votre SCI.</p><p>Pensez à bien indiquer la date de mise en service de chaque bien et immobilisation qui défini à partir de quand nous devons prendre en compte vos amortissements.</p><p>Utilisez le module d'Ownily qui propose les recommendations de l'administration fiscale pour paramétrer les amortissements.</p>",
        isEnableCustomAction:
          taskActivityStore.startWellTaskGroup
            .find(
              (taskGroup) =>
                taskGroup.groupCode === TaskGroupCode.StartWellAccounting
            )
            ?.taskActivitiesLocal.find((taskActivity) => {
              return taskActivity.code === TaskCode.AssetUpdate;
            })?.status === TaskActivityStatus.PENDING,
        navigateTo: (() => {
          return navigateToPage(
            { name: ROUTE_NAMES.Amortisations },
            productId,
            accountingPeriodId
          );
        })(),
      },
    },
    [TaskCode.AmortizationsResumptionPostAmortizationsUpdate]: {
      [TaxRegime.IS_2065]: {
        title: "Reprise des amortissements",
        learnMore:
          "<p>👉 Suite à votre paramétrage des règles d'amortissements de votre comptabilité sur Ownily, nous avons effectué la reprise des amortissements de vos biens et immobilisations.</p><p>Pour rester cohérent avec la comptabilité réalisée avant Ownily pour la SCI, nous devons augmenter ou réduire les amortissements pour l'année de reprise de la comptabilité.</p><p>💡 Pour en savoir plus sur cette opération, veuillez consulter l'article d'aide <a href = 'https://ownily.crisp.help/fr/article/is-reprise-de-mes-amortissements-sur-ownily-1wa59uo/' target='_blank'><b>Reprise de mes amortissements</b></a></p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage(
              {
                name: ROUTE_NAMES.Transactions,
                query: {
                  openModalAmortization: "true",
                },
              },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToTransactions(productId, accountingPeriodId, {
              code: FilterKeys.CATEGORIES,
              filterParams: {
                label: "Enregistrement des amortissements",
                categories: [
                  LedgerAccountEnum.N681120,
                  LedgerAccountEnum.N781100,
                ],
              },
            });
          }
        })(),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Reprise des amortissements",
        learnMore:
          "<p>👉 Suite à votre paramétrage des règles d'amortissements de votre comptabilité sur Ownily, nous avons effectué la reprise des amortissements de vos biens et immobilisations.</p><p>Pour rester cohérent avec la comptabilité réalisée avant Ownily pour la SCI, nous devons augmenter ou réduire les amortissements pour l'année de reprise de la comptabilité.</p><p>💡 Pour en savoir plus sur cette opération, veuillez consulter l'article d'aide <a href = 'https://ownily.crisp.help/fr/article/is-reprise-de-mes-amortissements-sur-ownily-1wa59uo/' target='_blank'><b>Reprise de mes amortissements</b></a></p>",
        navigateTo: (() => {
          if (taskActivity.status === TaskActivityStatus.PENDING) {
            return navigateToPage(
              {
                name: ROUTE_NAMES.Transactions,
                query: {
                  openModalAmortization: "true",
                },
              },
              productId,
              accountingPeriodId
            );
          } else {
            return navigateToTransactions(productId, accountingPeriodId, {
              code: FilterKeys.CATEGORIES,
              filterParams: {
                label: "Enregistrement des amortissements",
                categories: [
                  LedgerAccountEnum.N681120,
                  LedgerAccountEnum.N781100,
                ],
              },
            });
          }
        })(),
      },
    },

    [TaskCode.ViewAndControlResultsAccount]: {
      [TaxRegime.IS_2065]: {
        title: "Visualiser et contrôler le compte de résultat",
        learnMore:
          "<p>Suite à la vérification de toutes vos transactions et de vos opérations comptables faite dans les tâches précédentes, Ownily génère le compte de résultat de votre SCI.</p><p>Gardez bien ce document à titre indicatifs pour suivre la comptabilité de votre SCI année après année.</p><p>Vous le trouverez archivé dans vos documents où vous pouvez les télécharger à partir de la page Mes documents Comptables.</p><p>Ce compte de résultat vient alimenter votre CERFA 2033.</p> ",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.AccountingResult },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Visualiser et contrôler le compte de résultat",
        learnMore:
          "<p>Suite à la vérification de toutes vos transactions et de vos opérations comptables faite dans les tâches précédentes, Ownily génère le compte de résultat de votre SCI.</p><p>Gardez bien ce document à titre indicatifs pour suivre la comptabilité de votre SCI année après année.</p><p>Vous le trouverez archivé dans vos documents où vous pouvez les télécharger à partir de la page Mes documents Comptables.</p><p>Ce compte de résultat vient alimenter votre CERFA 2033.</p> ",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.AccountingResult },
          productId,
          accountingPeriodId
        ),
      },
    },
    [TaskCode.ValidateAndObtainBalanceSheet]: {
      [TaxRegime.IS_2065]: {
        title: "Valider et obtenir le bilan",
        learnMore:
          "<p>Suite à la vérification de toutes vos transactions et de vos opérations comptables faite dans les tâches précédentes, Ownily génère le bilan comptable de votre SCI.</p><p>Gardez bien ce document à titre indicatifs pour suivre la comptabilité de votre SCI année après année.</p><p>Vous le trouverez archivé dans vos documents où vous pouvez les télécharger à partir de la page Mes documents Comptables.</p><p>Ce bilan comptable vient alimenter votre CERFA 2033.</p> ",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.AccountingBalanceSheet },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Valider et obtenir le bilan",
        learnMore:
          "<p>Suite à la vérification de toutes vos transactions et de vos opérations comptables faite dans les tâches précédentes, Ownily génère le bilan comptable de votre activité LMNP.</p><p>Gardez bien ce document à titre indicatifs pour suivre la comptabilité de votre activité LMNP année après année.</p><p>Vous le trouverez archivé dans vos documents où vous pouvez les télécharger à partir de la page Mes documents Comptables.</p><p>Ce bilan comptable vient alimenter votre CERFA 2033.</p> ",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.AccountingBalanceSheet },
          productId,
          accountingPeriodId
        ),
      },
    },

    [TaskCode.TeletransmitMyTaxReturn]: {
      [TaxRegime.IR_2072]: {
        title: "Télétransmettre ma liasse fiscale",
        isDisabled: true,
        isComingSoon: true,
        learnMore:
          "<p>Vous pourrez bientôt télétransmettre votre liasse fiscale avec Ownily</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.SciIncome },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.IS_2065]: {
        title: "Télétransmettre ma liasse fiscale",
        isDisabled: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        isComingSoon: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        learnMore:
          "<p>Vous pourrez bientôt télétransmettre votre liasse fiscale (CERFA 2033, CERFA 2065 et FEC Normé) avec Ownily</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2065 },
          productId,
          accountingPeriodId
        ),
      },
      [TaxRegime.LMNP_2031]: {
        title: "Télétransmettre ma liasse Fiscale",
        isDisabled: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        isComingSoon: !coreStore.featureFlagsTest(
          "tasks.code.teletransmit-my-tax-return",
          usersStore.loggedInUser.id
        ),
        learnMore:
          "<p>Vous pourrez bientôt télétransmettre votre liasse fiscale (CERFA 2033, CERFA 2031 et FEC Normé) avec Ownily</p>",
        navigateTo: navigateToPage(
          { name: ROUTE_NAMES.TaxDeclaration2031 },
          productId,
          accountingPeriodId
        ),
      },
    },
  };
  const taskActivityExtends =
    taskActivityLocalExtends[taskActivity.code]?.[taxRegime];

  if (taskActivityExtends) {
    return { ...taskActivity, ...taskActivityExtends };
  } else {
    return undefined;
  }
};

export const tasksNeedingBalanceSheetFetch: TaskCode[] = [
  TaskCode.InjectBalance,
  TaskCode.InjectBalanceVerify,
  TaskCode.BalanceSheetRecovery,
  TaskCode.BalanceSheetRecoveryVerify,
  TaskCode.BalanceSimple,
];
