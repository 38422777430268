





















import { defineComponent } from "@vue/composition-api";
import Events from "./Events.vue";

export default defineComponent({
  name: "EventsOverview",
  components: { Events },
});
