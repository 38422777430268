























import { defineComponent } from "@vue/composition-api";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "TaxReturnLineHead2072",
  props: {
    code2072: String,
    libelle2072: String,
  },
  setup() {
    return {
      openArticle: () => useCrisp().openArticle(ArticleEnum.TAX_RETURN_2072),
    };
  },
});
