import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { fixedAssetsService } from "@/services";
import {
  FixedAssetAmortisation,
  FixedAssetAmortisationsService,
} from "@edmp/api";
import Vue from "vue";

export interface FixedAssetAmortisationsState {
  fixedAssetAmortisations: FixedAssetAmortisation[];
  loading: boolean;
}

@Module({
  name: "fixed-asset-amortisations-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class FixedAssetAmortisationsStore
  extends VuexModule
  implements FixedAssetAmortisationsState
{
  fixedAssetAmortisations: FixedAssetAmortisation[] = [];
  loading = false;

  @Mutation
  reset(): void {
    this.fixedAssetAmortisations = [];
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setFixedAssetAmortisation(fixedAssetAmortisation: FixedAssetAmortisation) {
    const index = this.fixedAssetAmortisations.findIndex(
      ({ id }) => id == fixedAssetAmortisation.id
    );
    if (index !== -1) {
      // Tips :  Vue cannot detect when you directly set a non-root state property with the index. For keeping reactivity use set() method
      Vue.set(this.fixedAssetAmortisations, index, fixedAssetAmortisation);
    } else {
      this.fixedAssetAmortisations.push(fixedAssetAmortisation);
    }
  }

  @Action
  async fetchFixedAssetAmortisations(productId: string) {
    this.setLoading(true);
    const fixedAssetAmortisations = await fixedAssetsService.amortisations.list(
      {
        productId,
      }
    );
    for (const fixedAssetAmortisation of fixedAssetAmortisations) {
      this.setFixedAssetAmortisation(fixedAssetAmortisation);
    }
    this.setLoading(false);
    return fixedAssetAmortisations;
  }

  get getFixedAssetAmortisationsByFixedAssetId() {
    return (fixedAssetAmortisationId?: string | null) => {
      if (!fixedAssetAmortisationId) return [];
      return this.fixedAssetAmortisations.filter(
        (fixedAssetAmortisation) =>
          fixedAssetAmortisation.fixedAssetId === fixedAssetAmortisationId
      );
    };
  }

  @Action
  async createFixedAssetAmortisation(
    fixedAssetAmortisationCreate: FixedAssetAmortisationsService.CreateIn
  ) {
    this.setLoading(true);
    const fixedAssetAmortisationCreated =
      await fixedAssetsService.amortisations.create(
        fixedAssetAmortisationCreate
      );
    this.setFixedAssetAmortisation(fixedAssetAmortisationCreated);
    this.setLoading(false);
    return fixedAssetAmortisationCreated;
  }

  @Action
  async fetchFixedAssetAmortisation(
    params: FixedAssetAmortisationsService.GetIn
  ) {
    this.setLoading(true);
    const fixedAssetAmortisation = await fixedAssetsService.amortisations.get(
      params
    );
    this.setFixedAssetAmortisation(fixedAssetAmortisation);
    this.setLoading(false);
    return fixedAssetAmortisation;
  }

  get getFixedAssetAmortisation() {
    return (fixedAssetAmortisationId?: string | null) =>
      this.fixedAssetAmortisations.find(
        ({ id }) => id === fixedAssetAmortisationId
      );
  }

  @Action
  async updateFixedAssetAmortisation(
    fixedAssetAmortisationUpdate: FixedAssetAmortisationsService.UpdateIn
  ) {
    this.setLoading(true);
    const fixedAssetAmortisationUpdated =
      await fixedAssetsService.amortisations.update(
        fixedAssetAmortisationUpdate
      );
    this.setFixedAssetAmortisation(fixedAssetAmortisationUpdated);
    this.setLoading(false);
    return fixedAssetAmortisationUpdated;
  }

  @Mutation
  delFixedAssetAmortisation(
    fixedAssetAmortisationId: FixedAssetAmortisation["id"]
  ): void {
    const index = this.fixedAssetAmortisations.findIndex(
      ({ id }) => id == fixedAssetAmortisationId
    );
    if (index !== -1) {
      this.fixedAssetAmortisations.splice(index, 1);
    }
  }

  @Action
  async deleteFixedAssetAmortisation(
    params: FixedAssetAmortisationsService.DeleteIn
  ) {
    this.setLoading(true);
    const fixedAssetAmortisationDeleted =
      await fixedAssetsService.amortisations.delete(params);
    if (fixedAssetAmortisationDeleted) {
      this.delFixedAssetAmortisation(params.id);
    }
    this.setLoading(false);
    return fixedAssetAmortisationDeleted;
  }
}
