


























import { defineComponent, computed, PropType } from "@vue/composition-api";
import { ProductsModel, TaxRegime } from "@edmp/api";

import RegisterActivityDetailsIR from "./RegisterActivityDetailsIR.vue";
import RegisterActivityDetailsIS from "./RegisterActivityDetailsIS.vue";
import RegisterActivityDetailsLMNP from "./RegisterActivityDetailsLMNP.vue";

export default defineComponent({
  name: "RegisterActivityDetails",
  components: {
    RegisterActivityDetailsIR,
    RegisterActivityDetailsIS,
    RegisterActivityDetailsLMNP,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: true,
    },
    activityRegistered: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup(props) {
    const isRegimeIR = computed(() => props.taxRegime === TaxRegime.IR_2072);
    const isRegimeIS = computed(() => props.taxRegime === TaxRegime.IS_2065);
    const isRegimeLMNP = computed(
      () => props.taxRegime === TaxRegime.LMNP_2031
    );

    return { isRegimeIR, isRegimeIS, isRegimeLMNP };
  },
});
