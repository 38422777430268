import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=51e147f7&scoped=true&"
import script from "./LoginForm.vue?vue&type=script&lang=ts&"
export * from "./LoginForm.vue?vue&type=script&lang=ts&"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=51e147f7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e147f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VForm,VScrollXTransition,VSlideYTransition,VTextField})
