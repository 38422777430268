











































































import { defineComponent, ref, Ref } from "@vue/composition-api";
import { Document, Transaction } from "@edmp/api";

import { accountingPeriodsStore, productsStore, documentsStore } from "@/store";

interface UploadError {
  file: File;
  retry: boolean;
}

export default defineComponent({
  name: "TransactionAttachmentCreate",
  props: {
    transaction: {
      type: Object as () => Transaction,
      required: true,
    },
  },
  setup(props, context) {
    const files: Ref<File[]> = ref([]);
    const uploadProgress: Ref<number> = ref(0);
    const filesInError: Ref<UploadError[]> = ref([]);

    async function uploadFile(file: File): Promise<Document> {
      const t: Transaction = props.transaction;
      try {
        return documentsStore.addTransactionAttachment({
          document: {
            product: {
              id: productsStore.currentId,
              accountingPeriod: { id: accountingPeriodsStore.currentId },
            },
            bankAccount: { id: t.bankAccountId, transaction: { id: t.id } },
          },
          file,
        });
      } catch (err) {
        throw new Error(`File upload error : ${file.name}`);
      }
    }

    async function close() {
      uploadProgress.value = 0;
      files.value = [];
      filesInError.value = [];

      context.emit("add");
      context.emit("finish");
    }

    function startUpload() {
      const nbFilesToUpload = files.value.length;
      let nbFilesUploaded = 0;
      uploadProgress.value = 1;
      files.value.forEach(async (file: File) => {
        try {
          await uploadFile(file);
        } finally {
          nbFilesUploaded++;
          uploadProgress.value = (nbFilesUploaded / nbFilesToUpload) * 100;
          uploadProgress.value = 0;
          if (
            nbFilesToUpload === nbFilesUploaded &&
            filesInError.value.length === 0
          ) {
            close();
          }
        }
      });
    }

    async function retry(error: UploadError): Promise<void> {
      error.retry = true;
      filesInError.value = filesInError.value.filter((err) => err !== error);
      await uploadFile(error.file);
    }

    return {
      files,
      filesInError,
      uploadProgress,
      startUpload,
      retry,
      close,
    };
  },
});
