





















































































import SignTemplate from "@/components/sign/SignTemplate.vue";
import { usePassword } from "@/composables";
import { VForm } from "@/models";
import { userAccountService } from "@/services";
import { coreStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
  SetupContext,
} from "@vue/composition-api";
import { get } from "lodash";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "ForgetPassword",
  components: {
    SignTemplate,
  },
  setup(props, context: SetupContext) {
    const password: Ref<string> = ref("");
    const showPassword: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);

    const token: ComputedRef<string> = computed(
      () => get(context.root.$route.query, "token", "") as string
    );

    async function resetPassword(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        try {
          await userAccountService.updatePassword({
            token: token.value,
            password: password.value,
          });
          coreStore.displayFeedback({
            message: "Votre mot de passe a bien été modifié",
          });
          context.root.$router.push({ name: ROUTE_NAMES.SignIn });
        } catch (err) {
          coreStore.displayFeedback({
            message:
              "Le token est invalide, veuillez refaire une demande de réinitialisation de votre mot de passe",
            type: FeedbackTypeEnum.ERROR,
          });
        }
      }
    }

    const { validPasswordRule, getPasswordRule } = usePassword();
    return {
      ROUTE_NAMES,
      firstPassword: computed(
        () => context.root.$route.name === "DefinePassword"
      ),
      password,
      showPassword,
      error,
      resetPassword,
      validPasswordRule,
      getPasswordRule,
    };
  },
});
