import { productsStore, transactionsStore } from "@/store";

import type {
  AnomalyErrorResult,
  CategorizationEntry,
  ProductsModel,
  JournalEntryLine,
  JournalEntryReference,
  Suggestion,
  Transaction,
} from "@edmp/api";
import { Direction, TaxRateTva, TypeReference } from "@edmp/api";
import { LedgerAccountEnum } from "@edmp/api";
import { computed, SetupContext } from "@vue/composition-api";
import Decimal from "decimal.js-light";
import { cloneDeep } from "lodash";

export interface TransactionState {
  transaction: Transaction;

  savedCategories: CategorizationEntry[]; // savedCategories displayed on transactionCard and updated after saving
  lines: CategorizationEntry[]; // lines, categories used in edition in Categorization modals
  suggestedLines: CategorizationEntry[]; // suggestedLines, categories suggested on transactionCard
  suggestedTransactionIds: string[];
  selectedCategory?: Suggestion;
  anomaliesErrors: AnomalyErrorResult[];

  isOnAddManualTransaction: boolean;
  isOnDuplicateTransaction: boolean;
  isOpenCategorizationList: boolean;
  isOpenCategorizationDetailStep: number | false;
  isOpenCategorizationDuplicate: boolean;
  isUpdatingCategorization: boolean; // boolean used when saving categorization to display loading bar
  isCategorized: boolean;
  isManualTransaction: boolean;
  isImportTransaction: boolean;
  isSystemTransaction: boolean;
  isAmortizationTransaction: boolean;
  isAcquisitionTransaction: boolean;
  isExpenseTransaction: boolean;
}

export const useTransaction = (
  transactionState: TransactionState,
  context: SetupContext
) => {
  /**
   * Data
   */
  const transactionAmountTaxDecomposition = computed(() => {
    const amountAlreadyCategorized = (onlyCharges: Boolean) => {
      let amount = 0;
      const lines = transactionState.lines.filter((line) => {
        if (!onlyCharges) {
          if (line.account !== LedgerAccountEnum.N708399) {
            return line;
          }
        } else {
          if (line.account === LedgerAccountEnum.N708399) {
            return line;
          }
        }
      });
      for (const line of lines) {
        amount = amount + line.amount;
      }
      return amount;
    };

    const transactionAmountTTC = new Decimal(
      transactionState.transaction.value.amount
    ).sub(amountAlreadyCategorized(false));

    const transactionAmountHT = transactionAmountTTC.div(1.2).toFixed(2);

    const transactionAmountTVA = new Decimal(transactionAmountHT)
      .mul(new Decimal(TaxRateTva.Normal_20).div(100))
      .toFixed(2);
    return {
      TTC: transactionAmountTTC.toNumber(),
      TVA: Number(transactionAmountTVA),
      HT: Number(transactionAmountHT) - amountAlreadyCategorized(true),
    };
  });

  const product = computed(
    () => productsStore.currentProduct as ProductsModel.Product
  );

  /**
   * * transactionState
   *
   * Use for get and update element in transactionState
   */
  const isManualTransaction = computed({
    get: () => transactionState.isManualTransaction,
    set: (isManual) =>
      context.emit(
        "update:transactionState",
        Object.assign(transactionState, { isManualTransaction: isManual })
      ),
  });

  /**
   * * Use
   */
  /**
   * It takes the transaction state and returns a transaction with the new categories
   * @returns A transaction object with the new categories.
   */
  const getTransactionWithNewCategories = () => {
    const transaction = Object.assign(cloneDeep(transactionState.transaction), {
      operations: Object.assign(
        cloneDeep(transactionState.transaction.operations) ?? {},
        {
          journalEntry: Object.assign(
            cloneDeep(
              transactionState.transaction.operations?.journalEntry
            ) ?? {
              date: cloneDeep(transactionState.transaction.date.operation),
            },
            {
              lines: cloneDeep(
                transactionState.lines.map((line) => {
                  const refs: JournalEntryReference[] = [];
                  if (line.realEstateAsset) {
                    refs.push({
                      type: TypeReference.realEstateAsset,
                      referredId: line.realEstateAsset,
                    });
                  }
                  if (line.rentalUnit) {
                    refs.push({
                      type: TypeReference.rentalUnit,
                      referredId: line.rentalUnit,
                    });
                  }
                  if (line.rentalAgreement) {
                    refs.push({
                      type: TypeReference.rentalAgreement,
                      referredId: line.rentalAgreement,
                    });
                  }
                  if (line.realEstateLoan) {
                    refs.push({
                      type: TypeReference.realEstateLoan,
                      referredId: line.realEstateLoan,
                    });
                  }
                  if (line.partner) {
                    refs.push({
                      type: TypeReference.partner,
                      referredId: line.partner,
                    });
                  }
                  if (line.supportingDocument) {
                    refs.push({
                      type: TypeReference.supportingDocument,
                      referredId: line.supportingDocument,
                    });
                  }
                  if (line.beneficiary) {
                    refs.push({
                      type: TypeReference.beneficiary,
                      referredId: line.beneficiary,
                    });
                  }

                  const amount = new Decimal(line.amount);

                  const journalEntryLine: JournalEntryLine = {
                    account: line.account,
                    accountName: line.accountName ?? "",
                    amount: line.amount,
                    direction:
                      amount.isPositive() || amount.isZero()
                        ? Direction.credit
                        : Direction.debit,
                    id: line.id,
                    refs,
                    anomalies: transactionState.anomaliesErrors,
                  };
                  return journalEntryLine;
                })
              ),
            }
          ),
        }
      ),
    });
    return transaction;
  };

  /**
   * It returns the summary of the transaction
   * @returns The summary of the transaction.
   */
  const getSummary = () => {
    let summary = "";
    const transaction = transactionState.transaction;
    if (transaction) {
      if (transaction.operations?.summary) {
        summary = transaction.operations.summary;
      } else {
        summary = transaction.summary;
      }
    }
    return summary;
  };

  const refreshTransaction = async (
    customTransactionId = transactionState.transaction.id
  ) => {
    await transactionsStore.fetchTransaction({
      id: customTransactionId,
    });
  };

  return {
    // Data
    transactionState,
    transactionAmountTaxDecomposition,
    product,
    // Store
    getTransactionWithNewCategories,
    isManualTransaction,
    // Use
    getSummary,
    refreshTransaction,
  };
};
