var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accountingBalanceSheet"}},[_c('PageHeader',{attrs:{"displayAccountingPeriod":"","displayHelperWithId":_vm.$router.currentRoute.name === _vm.ROUTE_NAMES.AccountingBalanceSheet
        ? false
        : _vm.ArticleEnum.ACCOUNTING_BALANCE_SHEET_RECOVERY},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.showExport)?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.getReporting()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Export ")],1):_vm._e()]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),(
      _vm.$router.currentRoute.name === _vm.ROUTE_NAMES.AccountingBalanceSheet &&
      _vm.currentAccountingPeriod.closed &&
      !_vm.balanceSheet
    )?_c('v-dialog',{attrs:{"scrollable":"","max-width":"750px","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('v-card',{staticClass:"elevation-0"},[_c('TitleNew',{staticClass:"ml-5 mt-5",attrs:{"hLevel":"h2"}},[_vm._v("Votre bilan a été déplacé")]),_c('v-card-text',[_vm._v("Votre bilan 2022 clôturé est désormais disponible en tant que reprise de bilan 2022, vous pouvez retrouver cette reprise de bilan à partir de la période comptable 2023.")]),_c('div',{staticClass:"mb-3 mt-1 d-flex align-self-center"},[_c('v-btn',{attrs:{"color":"primary","x-large":"","dark":""},on:{"click":_vm.goToRecoveryBalanceSheet}},[_vm._v(" Voir ma reprise de bilan ")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"section section-header"},[_c('router-link',{staticClass:"back d-flex align-center",attrs:{"to":{ name: _vm.ROUTE_NAMES.AccountingDocuments }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),(_vm.isIR)?[_vm._v(" Mon bilan simplifié ")]:_vm._e(),(_vm.isIS || _vm.isLMNP)?[_vm._v(" Mes documents comptables ")]:_vm._e()],2)],1),_c('HelpingMessage',{staticClass:"section",attrs:{"title":"En savoir plus"}},[(
        _vm.$router.currentRoute.name ===
        _vm.ROUTE_NAMES.AccountingBalanceSheetRecovery
      )?_c('p',[_vm._v(" Pour tenir votre comptabilité sur Ownily, vous devez reprendre votre dernier bilan clôturé hors Ownily. N'oubliez pas de valider les dates de votre exercice comptable pour partir sur de bonnes bases pour vos déclarations ")]):_vm._e(),_c('p',[_vm._v(" Les lignes de votre bilan dépendent en partie des informations fournies durant votre paramétrage. Veillez donc à bien paramétrer tous vos biens, amortissements, associés et comptes bancaires. ")]),_c('p',[_vm._v(" Vous pouvez également ajouter d'autres lignes en fonction de vos besoins. ")]),_c('p',[_vm._v(" Ce bilan est éditable jusqu'à la clôture de votre prochain exercice comptable. ")])]),_c('div',{staticClass:"section"},[(
        _vm.taskActivityGroup &&
        _vm.taskActivityGroup.status !== _vm.TaskActivityStatus.COMPLETED
      )?_c('GroupCard',{staticClass:"taskGroup",attrs:{"taskActivityGroupLocal":_vm.taskActivityGroup},nativeOn:{"click":function($event){_vm.taskActivityGroup ? _vm.taskActivityGroup.navigateTo() : undefined}}}):_vm._e(),(
        _vm.taskActivityGroupPending &&
        _vm.balanceSheet &&
        _vm.balanceSheet.type === 'recovery'
      )?_c('v-alert',{staticClass:"mt-4",style:({ cursor: 'pointer', 'margin-bottom': 'unset' }),attrs:{"type":"warning","outlined":"","text":""},on:{"click":function($event){return _vm.navigateToStartWellTasks()}}},[(_vm.isIR || _vm.isIS)?_c('div',[_vm._v(" Complétez les tâches de "),_c('v-btn',{staticClass:"px-0",style:({ height: 'unset', 'padding-bottom': '4px' }),attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.navigateToStartWellTasks()}}},[_vm._v("paramétrage de ma comptabilité ")]),_vm._v(" pour effectuer votre reprise de bilan ")],1):_c('div',[_vm._v(" Terminer le "),_c('v-btn',{staticClass:"px-0",style:({ height: 'unset', 'padding-bottom': '4px' }),attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.navigateToStartWellTasks()}}},[_vm._v("paramétrage de votre compte LMNP")]),_vm._v(" pour effectuer votre reprise de bilan ")],1)]):_vm._e()],1),(_vm.documents.length)?_c('div',{staticClass:"section"},[_vm._m(0),_c('div',{staticStyle:{"max-width":"600px","width":"100%"}},_vm._l((_vm.documents),function(document){return _c('div',{key:document.id},[_c('DocumentCard',{attrs:{"document":document}})],1)}),0)]):_vm._e(),(
      _vm.$router.currentRoute.name === _vm.ROUTE_NAMES.AccountingBalanceSheetRecovery
    )?_c('div',{staticClass:"section"},[_c('AccountingBalanceSheetRecoveryData',{attrs:{"balanceSheet":_vm.balanceSheet,"isReadOnly":_vm.isReadOnlyRecoveryData}})],1):_vm._e(),_c('v-form',{ref:"form"},[_c('div',{staticClass:"section"},[(!_vm.isReadOnlyPage ? _vm.isReadOnlyLines : false)?_c('v-alert',{attrs:{"type":"warning","outlined":"","text":""}},[_vm._v(" Valider les dates de votre dernier exercice comptable avant de renseigner les soldes du bilan ")]):_vm._e(),_c('div',{staticClass:"section-header"},[_c('h3',{staticClass:"section-header-title mb-2"},[_vm._v(" Solde du bilan ("+_vm._s(_vm.accountingPeriodYear)+") - "),_c('b',[_vm._v("ACTIF")])])]),_c('h4',{staticClass:"section-header-title mb-2"},[_vm._v("Actif immobilisé")]),_c('div',{staticClass:"section-body mb-6"},[_c('AccountingBalanceSheetTable',{attrs:{"balanceSheet":_vm.balanceSheetFixedAssetsLines,"accountBalanceType":"fixed_assets","viewType":_vm.isReadOnlyLines ||
            _vm.isReadOnlyPageValidated ||
            (_vm.balanceSheet && _vm.balanceSheet.type === 'closure')
              ? 'readOnly'
              : 'editable'}})],1),_c('h4',{staticClass:"section-header-title mb-2"},[_vm._v("Actif circulant")]),_c('div',{staticClass:"section-body mb-6"},[_c('AccountingBalanceSheetTable',{attrs:{"balanceSheet":_vm.balanceSheetCurrentAssetsLines,"accountBalanceType":"current_assets","viewType":_vm.isReadOnlyLines || _vm.isReadOnlyPageValidated
              ? 'readOnly'
              : 'editable'}})],1),_c('div',{staticClass:"section-body justify-end mb-6"},[_c('v-card',{attrs:{"outlined":"","width":"fit-content"}},[_c('v-card-text',[_c('h3',{staticClass:"section-header-title"},[_c('b',[_vm._v("Total ACTIF: "+_vm._s(_vm.totalActiveText))])])])],1)],1)],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-header"},[_c('h3',{staticClass:"section-header-title"},[_vm._v(" Solde du bilan ("+_vm._s(_vm.accountingPeriodYear)+") - "),_c('b',[_vm._v("PASSIF")])])]),_c('h4',{staticClass:"section-header-title mb-2"},[_vm._v("Capitaux propres")]),_c('div',{staticClass:"section-body mb-6"},[_c('AccountingBalanceSheetTable',{attrs:{"balanceSheet":_vm.balanceSheetShareholdersEquityLines,"accountBalanceType":"shareholders_equity","viewType":_vm.isReadOnlyLines ||
            _vm.isReadOnlyPageValidated ||
            (_vm.balanceSheet && _vm.balanceSheet.type === 'closure')
              ? 'readOnly'
              : 'editable'}})],1),_c('h4',{staticClass:"section-header-title mb-2"},[_vm._v("Dettes et assimilés")]),_c('div',{staticClass:"section-body mb-6"},[_c('AccountingBalanceSheetTable',{attrs:{"balanceSheet":_vm.balanceSheetDebtsLines,"accountBalanceType":"debts","viewType":_vm.isReadOnlyLines || _vm.isReadOnlyPageValidated
              ? 'readOnly'
              : 'editable'}})],1),_c('div',{staticClass:"section-body justify-end mb-6"},[_c('v-card',{attrs:{"outlined":"","width":"fit-content"}},[_c('v-card-text',[_c('h3',{staticClass:"section-header-title"},[_c('b',[_vm._v("Total PASSIF: "+_vm._s(_vm.totalPassiveText))])])])],1)],1)]),_c('div',{staticClass:"section section-body justify-end"},[_c('div',{staticClass:"d-flex flex-column align-end"},[_c('CustomLabelButton',{style:({ width: 'fit-content' }),attrs:{"loading":_vm.isLoadingValidate,"disabled":_vm.isReadOnlyLines || !_vm.rules.isEqualTotalActifTotalPasif,"outlined":_vm.balanceSheet && _vm.balanceSheet.isValidated},on:{"click":function($event){return _vm.validate()}}},[(_vm.balanceSheet && _vm.balanceSheet.isValidated)?_c('span',[_vm._v("Dévalider mon Bilan")]):_c('span',[_vm._v("Valider mon Bilan")])]),(!_vm.rules.isEqualTotalActifTotalPasif)?_c('p',{staticClass:"error--text text-right"},[_vm._v(" Aie ! Votre Bilan est déséquilibré."),_c('br'),_vm._v(" Vérifier les montants à l’Actif et au Passif. ")]):_vm._e()],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-header"},[_c('h3',{staticClass:"section-header-title mb-2"},[_vm._v("Documents")])])}]

export { render, staticRenderFns }