










































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";

import {
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  partnersStore,
  productsStore,
  taskActivityStore,
} from "@/store";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";

import PageHeader from "@/components/atom/PageHeader.vue";
import CustomLabelButton from "@/components/atom/button/CustomLabelButton.vue";
import DocumentCard from "@/components/core/documents/DocumentCard.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import DatePicker from "@/components/atom/DatePicker.vue";
import { SubmitButton } from "@/components/atom/button";

import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

import { downloadFileFromUrl } from "@/utils";
import {
  AccountingPeriod,
  getMoment,
  TaskActivityService,
  TaskCode,
  TaxDeclarationsService,
  TaxDeclarationStatus,
  TaxRegime,
} from "@edmp/api";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { TaskActivityLocal, VForm } from "@/models";
import { countryISOcode } from "@edmp/api/src";

export default defineComponent({
  name: "TaxDeclarationValidate",
  components: {
    PageHeader,
    PDFViewer,
    CustomLabelButton,
    DocumentCard,
    YesNoSwitch,
    DatePicker,
    SubmitButton,
  },
  props: {
    taskActivityLocal: {
      type: Object as PropType<TaskActivityLocal>,
      required: false,
    },
    resolveTaskActivity: {
      type: Function as PropType<
        (
          taskActivity: TaskActivityLocal,
          data: Pick<
            TaskActivityService.ValidateIn,
            TaskCode.FillCerfa2065 | TaskCode.FillCerfa2031
          >
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props, context) {
    const loading = ref(true);
    const loadingAdditionalInformations = ref(false);
    const loadingValidate = ref(false);
    const steps: Ref<1 | 2> = ref(1);
    const taxDeclaration = ref<TaxDeclarationsService.GetOut>();
    const pdf = ref<
      | {
          fileName: string;
          url: string;
        }
      | undefined
    >({ fileName: "", url: "" });
    const document = computed(() =>
      documentsStore.documents.find(
        (document) =>
          document.tags?.includes("tax") &&
          document.createdBy === "ownily" &&
          document.product?.accountingPeriod?.id ===
            accountingPeriodsStore.currentId &&
          document.id ===
            (
              taxDeclaration.value as unknown as
                | {
                    documentPdfCerfaId: string;
                  }
                | undefined
            )?.documentPdfCerfaId
      )
    );
    const currentAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );
    const partners = computed(() => partnersStore.partners);
    const defaultAccountingPeriod = computed(() => ({
      [TaxRegime.IR_2072]: {
        startAt: `${
          currentAccountingPeriod.value
            ? getMoment(currentAccountingPeriod.value.startAt)
                .subtract(1, "year")
                .format("YYYY")
            : getMoment().subtract(1, "year").format("YYYY")
        }-01-01`,
        endAt: `${
          currentAccountingPeriod.value
            ? getMoment(currentAccountingPeriod.value.endAt)
                .subtract(1, "year")
                .format("YYYY")
            : getMoment().subtract(1, "year").format("YYYY")
        }-12-31`,
      },
      [TaxRegime.IS_2065]: {
        startAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.startAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-01-01"),
        endAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.endAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-12-31"),
      },
      [TaxRegime.LMNP_2031]: {
        startAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.startAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-01-01"),
        endAt: currentAccountingPeriod.value
          ? getMoment(currentAccountingPeriod.value.endAt)
              .subtract(1, "year")
              .format("YYYY-MM-DD")
          : getMoment().subtract(1, "year").format("YYYY-12-31"),
      },
    }));
    const additionalInformations = ref({
      hasPreviousAccountingPeriod: false,
      hasPreviousAddress: false,
      countryISOcodes: Object.entries(countryISOcode).map(([key, value]) => ({
        text: value,
        value: key,
      })),
      rules: {
        exerciceStartDateMin: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] = getMoment(
              additionalInformations.value.accountingPeriodPrevious.startAt
            )
              .subtract(1, "year")
              .format("YYYY-MM-DD")
              .split("-") ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceStartDateMax: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.endAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceEndDateMin: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.startAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        exerciceEndDateMax: (): string | undefined => {
          if (currentAccountingPeriod.value) {
            const [year] =
              additionalInformations.value.accountingPeriodPrevious.endAt.split(
                "-"
              ) ?? [undefined];
            const [month, day] = defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
              .split("-")
              .slice(1);
            return `${year}-${month}-${day}`;
          }
        },
        addressPreviousStreet: [
          () =>
            !!additionalInformations.value.addressPrevious.street ||
            "Le champ ne peut pas être vide",
        ],
        addressPreviousCity: [
          () =>
            !!additionalInformations.value.addressPrevious.city ||
            "Le champ ne peut pas être vide",
        ],
        addressPreviousZip: [
          () =>
            !!additionalInformations.value.addressPrevious.zip ||
            "Le champ ne peut pas être vide",
        ],
        partnerBirthAddressPlace: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .place || "Le champ ne peut pas être vide",
        ],
        partnerBirthAddressDepartment: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .department || "Le champ ne peut pas être vide",
        ],
        partnerBirthAddressCountry: (partnerIndex: number) => [
          () =>
            !!additionalInformations.value.partners[partnerIndex].birthAddress
              .country || "Le champ ne peut pas être vide",
        ],
      },
      accountingPeriodPrevious: {
        startAt: currentAccountingPeriod.value
          ? defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].startAt
          : "",
        endAt: currentAccountingPeriod.value
          ? defaultAccountingPeriod.value[
              currentAccountingPeriod.value.taxRegime
            ].endAt
          : "",
      },
      addressPrevious: {
        street: "",
        city: "",
        zip: "",
      },
      partners: [] as (Pick<
        NonNullable<
          TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"]
        >[number],
        "id"
      > & {
        birthAddress: Partial<
          NonNullable<
            TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"]
          >[number]["birthAddress"]
        >;
      })[],
    });

    // Methods
    const getPdf = async () => {
      try {
        const taxDeclarationsPdf = await taxDeclarationsService.downloadReport({
          accountingPeriodId: accountingPeriodsStore.currentId,
          accountingPeriodPrevious:
            additionalInformations.value.accountingPeriodPrevious,
          ...(additionalInformations.value.addressPrevious.street &&
          additionalInformations.value.addressPrevious.city &&
          additionalInformations.value.addressPrevious.zip
            ? { addressPrevious: additionalInformations.value.addressPrevious }
            : {}),
          ...(currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ? {
                partners: additionalInformations.value.partners,
              }
            : {}),
        });
        if (taxDeclarationsPdf) {
          pdf.value = {
            ...taxDeclarationsPdf,
          };
        }
        loadingAdditionalInformations.value = false;
      } finally {
        loadingAdditionalInformations.value = false;
      }
    };
    const getPartnerName = (p) => {
      const partner = partners.value.find(({ id }) => id === p.id);
      if (partner) {
        return `${partner.firstName} ${partner.lastName}`;
      }
    };
    const setAccountingPeriodPrevious = () => {
      if (!currentAccountingPeriod.value?.firstYear) {
        const accountingPeriodsSort = [
          ...accountingPeriodsStore.accountingPeriods,
        ].sort((a, b) => getMoment(a.endAt).diff(b.endAt));
        const currentAccountingPeriodIndex = accountingPeriodsSort.findIndex(
          ({ id }) => id === accountingPeriodsStore.currentId
        );
        const previousAccountingPeriod = accountingPeriodsSort[
          currentAccountingPeriodIndex - 1
        ] as AccountingPeriod | undefined;
        if (previousAccountingPeriod) {
          additionalInformations.value.accountingPeriodPrevious = {
            startAt: previousAccountingPeriod.startAt,
            endAt: previousAccountingPeriod.endAt,
          };
          additionalInformations.value.hasPreviousAccountingPeriod = true;
        }
      }
    };
    const setPartnersBirthAddress = () => {
      const partnersCopy = [...partners.value];
      additionalInformations.value.partners = partnersCopy.map((p) => ({
        id: p.id,
        birthAddress: {
          // place: undefined,
          // department: undefined,
          country: "FR",
        },
      }));
    };

    const handleDownload = () => {
      if (pdf.value) {
        downloadFileFromUrl(pdf.value.url, pdf.value.fileName);
      }
    };

    const validateAdditionalInformations = async (e: Event) => {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        loadingAdditionalInformations.value = true;
        loading.value = true;
        try {
          await getPdf();
          steps.value = 2;
          loading.value = false;
        } catch (err) {
          const error = err as Error;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: `Une erreur est survenue lors de la validation de votre déclaration: ${error.message}`,
            timeout: 8000,
          });
        }
      }
    };

    const validate = async () => {
      loadingValidate.value = true;
      try {
        if (props.taskActivityLocal) {
          if (props.resolveTaskActivity) {
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ) {
              await props.resolveTaskActivity(props.taskActivityLocal, {
                [TaskCode.FillCerfa2065]: {
                  accountingPeriodPrevious:
                    additionalInformations.value.accountingPeriodPrevious,
                  ...(additionalInformations.value.addressPrevious.street &&
                  additionalInformations.value.addressPrevious.city &&
                  additionalInformations.value.addressPrevious.zip
                    ? {
                        addressPrevious:
                          additionalInformations.value.addressPrevious,
                      }
                    : {}),
                  partners: additionalInformations.value
                    .partners as TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"],
                },
              });
            }
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.LMNP_2031
            ) {
              await props.resolveTaskActivity(props.taskActivityLocal, {
                [TaskCode.FillCerfa2031]: {
                  accountingPeriodPrevious:
                    additionalInformations.value.accountingPeriodPrevious,
                  ...(additionalInformations.value.addressPrevious.street &&
                  additionalInformations.value.addressPrevious.city &&
                  additionalInformations.value.addressPrevious.zip
                    ? {
                        addressPrevious:
                          additionalInformations.value.addressPrevious,
                      }
                    : {}),
                },
              });
            }
          } else {
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.IS_2065
            ) {
              await taskActivityStore.validateTaskActivity({
                taskActivityLocal: props.taskActivityLocal,
                data: {
                  [TaskCode.FillCerfa2065]: {
                    accountingPeriodPrevious:
                      additionalInformations.value.accountingPeriodPrevious,
                    ...(additionalInformations.value.addressPrevious.street &&
                    additionalInformations.value.addressPrevious.city &&
                    additionalInformations.value.addressPrevious.zip
                      ? {
                          addressPrevious:
                            additionalInformations.value.addressPrevious,
                        }
                      : {}),
                    partners: additionalInformations.value
                      .partners as TaxDeclarationsService.ExportIn<TaxRegime.IS_2065>["partners"],
                  },
                },
              });
            }
            if (
              currentAccountingPeriod.value?.taxRegime === TaxRegime.LMNP_2031
            ) {
              await taskActivityStore.validateTaskActivity({
                taskActivityLocal: props.taskActivityLocal,
                data: {
                  [TaskCode.FillCerfa2031]: {
                    accountingPeriodPrevious:
                      additionalInformations.value.accountingPeriodPrevious,
                    ...(additionalInformations.value.addressPrevious.street &&
                    additionalInformations.value.addressPrevious.city &&
                    additionalInformations.value.addressPrevious.zip
                      ? {
                          addressPrevious:
                            additionalInformations.value.addressPrevious,
                        }
                      : {}),
                  },
                },
              });
            }
          }
        }
        handleDownload();
        context.emit("close");
      } catch (err) {
        const error = err as Error;
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.ERROR,
          message: `Une erreur est survenue lors de la validation de votre déclaration: ${error.message}`,
          timeout: 8000,
        });
      } finally {
        loadingValidate.value = false;
      }
      await init();
    };

    // Mutations
    watch(
      document,
      () => {
        if (document.value) {
          pdf.value = {
            fileName: document.value.name,
            url: document.value.href,
          };
        }
      },
      { deep: true }
    );
    const refPdf = ref(
      context.refs.refPdf as (Element | Element[]) & {
        source: string;
        filename: string;
        isLoading: boolean;
        reload: () => void;
      }
    );
    watch(
      () => [refPdf.value && refPdf.value.source, loading],
      async () => {
        refPdf.value.reload();
        if (pdf.value) {
          refPdf.value.filename = pdf.value.fileName;
        }
      },
      { deep: true }
    );

    // Init
    const init = async () => {
      loading.value = true;
      setAccountingPeriodPrevious();
      setPartnersBirthAddress();
      taxDeclaration.value = await taxDeclarationsService.get({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
      if (taxDeclaration.value?.status === TaxDeclarationStatus.NotSend) {
        if (steps.value === 2) {
          await getPdf();
        }
      } else {
        await documentsStore.fetchDocuments(productsStore.currentId);
      }
      loading.value = false;
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      loading,
      loadingAdditionalInformations,
      loadingValidate,
      steps,
      currentAccountingPeriod,
      TaxRegime,
      additionalInformations,
      taxDeclaration,
      TaxDeclarationStatus,
      document,
      pdf,
      refPdf,
      getPartnerName,
      getMoment,

      logErr: (err) => console.error(err),

      handleDownload,
      validateAdditionalInformations,
      validate,
    };
  },
});
