













































































import { computed, defineComponent, ref } from "@vue/composition-api";
import SectionTitle from "@/components/atom/SectionTitle.vue";
import { useRentalUnit } from "@/components/core/realEstate/rentalUnit.usable";

export default defineComponent({
  name: "RealEstateEquipment",
  components: { SectionTitle },
  props: {
    realEstateAssetId: {
      type: String,
      required: true,
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { initialEquipment } = useRentalUnit();
    const modalTitle = computed(
      () => `${props.realEstateAssetId ? "Éditer" : "Ajouter"} un bien`
    );

    const onBack = () => {
      context.emit("previous");
    };

    const validate = () => {
      if (props.realEstateAssetId === "new") {
        context.emit("emptyRealEstate");
        return;
      }
      context.emit("validate");
    };

    const equipments = ref(initialEquipment);

    return { validate, modalTitle, equipments, onBack };
  },
});
