










































































































































































































































































import Next from "@/components/core/modals/Next.vue";
import { VConfirmDialog } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  productsStore,
  subscriptionsStore,
} from "@/store";
import { AccountingPeriod, SubscriptionsModel, TaxRegime } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import moment from "moment";
import { useSubscription } from "../subscription.usable";

export default defineComponent({
  name: "Subscription",
  components: { Next },
  props: {
    productId: { type: String, required: false },
    subscriptionId: { type: String, required: false },
  },

  setup(props, context) {
    const isLoading: Ref<boolean> = ref(false);
    const subscription = computed(() =>
      props.subscriptionId
        ? subscriptionsStore.getSubscription(props.subscriptionId)
        : subscriptionsStore.getSubscriptionByProduct(productsStore.currentId)
    );
    const subscriptionEndAt = computed(() =>
      moment(subscription.value?.endAt).format("DD/MM/YYYY")
    );
    const isDateBeforeEndAt = computed(() =>
      moment().isBefore(moment(subscription.value?.endAt).startOf("day"))
    );
    const subscriptionCard = computed(() => {
      productsStore.currentId;
      let subscriptionTemp:
        | SubscriptionsModel.Subscription & {
            productName?: string;
            priceHT?: string;
            priceTTC?: string;
            beforeDateText?: string;
            dateText?: string;
            waiverPopup?: boolean;
          } = {
        ...subscription.value,
      } as SubscriptionsModel.Subscription;
      if (subscriptionTemp?.productId) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          productName: productsStore.getProduct({
            id: subscriptionTemp.productId,
          })?.name,
        });
      }
      if (subscriptionTemp?.cancelAtPeriodEnd) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: subscriptionTemp.cancelAt
            ? "Votre résiliation prendra effet"
            : "Demande de résiliation enregistrée. ",
          dateText: subscriptionTemp.cancelAt
            ? `le ${moment(subscriptionTemp.cancelAt).format("DD/MM/YYYY")}`
            : "",
        });
      } else if (
        subscriptionTemp?.status === SubscriptionsModel.SubscriptionStatus.end
      ) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: "Votre plan à été annulé",
          dateText: `le ${moment(subscriptionTemp.endAt).format(
            "DD/MM/YYYY"
          )}.`,
        });
      } else if (
        subscriptionTemp?.status ===
        SubscriptionsModel.SubscriptionStatus.suspend
      ) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: "Votre plan à été suspendu",
          dateText: `le ${moment(subscriptionTemp.suspendAt).format(
            "DD/MM/YYYY"
          )}.`,
        });
      } else if (
        subscriptionTemp?.plan?.type == SubscriptionsModel.PlanType.Free &&
        moment().isBefore(moment(subscriptionTemp.endAt).startOf("day"))
      ) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: "Votre essai se termine",
          dateText: `le ${moment(subscriptionTemp.endAt).format(
            "DD/MM/YYYY"
          )}.`,
        });
      } else if (
        subscriptionTemp?.plan?.type == SubscriptionsModel.PlanType.Free &&
        moment().isSameOrAfter(moment(subscriptionTemp.endAt).startOf("day"))
      ) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: "Votre essai est terminé",
          dateText: ``,
        });
      } else {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          beforeDateText: "Votre plan sera renouvelé",
          dateText: `le ${moment(subscriptionTemp?.endAt).format(
            "DD/MM/YYYY"
          )}.`,
        });
      }

      if (subscriptionTemp?.invoiceUpcoming?.amount === 0) {
        subscriptionTemp.priceHT = "Gratuit";
        subscriptionTemp.priceTTC = "Gratuit";
      } else {
        if (subscriptionTemp?.priceHT) {
          subscriptionTemp.priceHT = `${
            subscriptionTemp.invoiceUpcoming
              ? (
                  (subscriptionTemp.invoiceUpcoming.amount -
                    subscriptionTemp.invoiceUpcoming.amountDiscount) /
                  100
                ).toFixed(2)
              : "N/A"
          }€ HT par ${
            subscriptionTemp.plan.durationType ===
            SubscriptionsModel.DurationType.year
              ? "an"
              : subscriptionTemp.plan.durationType ===
                SubscriptionsModel.DurationType.month
              ? "mois"
              : "jour"
          }`;
          subscriptionTemp.priceTTC = `${
            subscriptionTemp.invoiceUpcoming
              ? (subscriptionTemp.invoiceUpcoming.amountTotal / 100).toFixed(2)
              : "N/A"
          }€ TTC`;
        }
      }

      // Manage Button to subscribe
      if (
        subscriptionTemp.plan?.type == SubscriptionsModel.PlanType.Free &&
        moment().isBefore(moment(subscriptionTemp.endAt).startOf("day")) &&
        subscriptionTemp.status !== SubscriptionsModel.SubscriptionStatus.end
      ) {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          waiverPopup: true,
        });
      } else {
        subscriptionTemp = Object.assign(subscriptionTemp, {
          waiverPopup: false,
        });
      }
      return subscriptionTemp;

      // if subscriptionTemp = undefined should not return subscriptionTemp
    });
    const { goSubscriptionUpdate, goDetail } = useSubscription(
      subscriptionCard.value.id
    );

    const currentRoute = computed(() => {
      if (router.currentRoute.name === ROUTE_NAMES.Subscriptions) return true;
    });

    // Init
    async function init() {
      isLoading.value = true;
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      isLoading.value = false;
    }
    async function openConfirmSubscription(productId) {
      let openConfirm = false;
      const currentSubscription =
        subscriptionsStore.getSubscriptionByProduct(productId);
      if (currentSubscription) {
        if (subscriptionCard.value.waiverPopup) {
          openConfirm = await (
            context.refs.confirmDialog as VConfirmDialog
          ).open(
            `<b>MERCI DE VOTRE CONFIANCE</b> ! </br></br> Vous êtes pressé de profiter de tous les services Ownily avant la fin de votre essai 😇</br></br>En poursuivant la souscription de l'abonnement vous renoncez à votre droit de rétractation, conformément aux dispositions précisées dans les <a href="https://www.ownily.fr/legal/conditions-generales-utilisation" target="_blank"
          >Conditions d'Utilisation</a> du service.
   `,
            { width: 700 }
          );
        } else {
          openConfirm = true;
        }
      }
      if (openConfirm) {
        await goSubscriptionUpdate();
      }
    }

    const accountingPeriods: Ref<AccountingPeriod[]> = ref([]);
    const initLMNP = async () => {
      accountingPeriods.value =
        await accountingPeriodsStore.getAccountingPeriods({
          productId:
            subscriptionCard.value.productId ?? productsStore.currentId,
        });
    };
    const isCurrentAccountingPeriodLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );

    watch(
      () => [
        subscriptionCard,
        productsStore.currentProduct,
        props.productId,
        props.subscriptionId,
      ],
      async () => {
        await init();
        await initLMNP();
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      await init();
      await initLMNP();
    });

    return {
      isLoading,
      subscriptionCard,
      subscriptionEndAt,
      PlanType: SubscriptionsModel.PlanType,
      SubscriptionStatus: SubscriptionsModel.SubscriptionStatus,
      PaymentStatus: SubscriptionsModel.PaymentStatus,
      goDetail,
      openConfirmSubscription,
      isCurrentAccountingPeriodLMNP,
      isDateBeforeEndAt,
      currentRoute,
    };
  },
});
