import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/root";
import { coreStore } from "@/store";
import { defaultUTM, TosVersion, Utm } from "@edmp/api";
import { coreService } from "@/services";
import * as config from "@/config";
import _, { cloneDeep } from "lodash";
import { ConfigAppFeatureFlags } from "@edmp/api/src/models/Config.model";
import { DotNotationTargetArray } from "@/models/DotNotation";

export interface Feedback {
  display: boolean;
  type?: FeedbackTypeEnum;
  message: string;
  timeout?: number;
}

export enum FeedbackTypeEnum {
  INFO = "INFO",
  FEEDBACK = "FEEDBACK",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export enum Env {
  dev = "dev",
  review = "review",
  staging = "staging",
  production = "production",
}

export interface CoreState {
  loadingApp: boolean;
  config: {
    versions: { back: string; front: string; api: string };
    env: Env;
    subscription: {
      publishableKey: string;
      retentionDatasDaysDefault: number;
    };
    user: { tosVersion: TosVersion };
    featureFlags: ConfigAppFeatureFlags;
    sirene: { sireneApiKey: string };
    services: {
      gtm: boolean;
      axios: boolean;
      crisp: boolean;
      webSocket: boolean;
    };
  };
  isOpenMobileMenu: boolean;

  feedback: {
    display: boolean;
    type?: FeedbackTypeEnum;
    message: string;
    timeout?: number;
  };

  utm: Utm;
}

@Module({
  name: "core-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class CoreStore extends VuexModule implements CoreState {
  loadingApp = false;
  config = {
    versions: { back: "", front: "", api: "" },
    env: Env.production,
    subscription: {
      publishableKey: "",
      retentionDatasDaysDefault: -1,
    },
    user: { tosVersion: TosVersion.VERSION_1 },
    featureFlags: {} as ConfigAppFeatureFlags,
    sirene: { sireneApiKey: "" },
    services: {
      gtm: true,
      axios: true,
      crisp: true,
      webSocket: true,
    },
  };
  isOpenMobileMenu = false;

  feedback = {
    display: false,
    message: "",
  } as Feedback;

  utm = defaultUTM;

  timeoutFeedback: NodeJS.Timeout | null = null;

  get isNotProduction() {
    return this.config.env !== Env.production;
  }

  get featureFlagsTest() {
    return (
      path: DotNotationTargetArray<CoreState["config"]["featureFlags"]>,
      userId: string
    ) => {
      const toTest: string[] | undefined = _.get(
        this.config.featureFlags,
        path
      );
      if (toTest) {
        return toTest.includes(userId);
      }
      return true;
    };
  }

  @Mutation
  reset(): void {
    this.loadingApp = false;
    this.config = {
      versions: { back: "", front: "", api: "" },
      env: Env.production,
      subscription: {
        publishableKey: "",
        retentionDatasDaysDefault: -1,
      },
      user: { tosVersion: TosVersion.VERSION_1 },
      featureFlags: {},
      sirene: { sireneApiKey: "" },
      services: {
        gtm: true,
        axios: true,
        crisp: true,
        webSocket: true,
      },
    };
    this.isOpenMobileMenu = false;

    this.feedback = {
      display: false,
      message: "",
      type: FeedbackTypeEnum.INFO,
    };

    this.utm = defaultUTM;
  }

  @Mutation
  setLoadingApp(isLoading: boolean): void {
    this.loadingApp = isLoading;
  }

  @Mutation
  setConfig(config: CoreState["config"]): void {
    this.config = config;
  }

  @Mutation
  setOpenMobileMenu(isOpen: boolean): void {
    this.isOpenMobileMenu = isOpen;
  }

  @Mutation
  setFeedback(feedback: Feedback): void {
    this.feedback = { ...feedback };
    if (feedback.timeout && feedback.timeout > 0) {
      if (this.timeoutFeedback) clearTimeout(this.timeoutFeedback);
      this.timeoutFeedback = setTimeout(
        () => coreStore.hideFeedback(),
        feedback.timeout
      );
    }
  }

  @Mutation
  setUtm(utm: Utm): void {
    this.utm = utm;
  }

  @Action
  async startLoadingApp(): Promise<void> {
    this.setLoadingApp(true);
  }

  @Action
  async getConfig(): Promise<CoreState["config"]> {
    const { env, version, webSocket, subscription, user, featureFlags } =
      await coreService.getVersion();
    this.setConfig(
      Object.assign(cloneDeep(config[env]), {
        versions: version,
        env,
        services: Object.assign(config[env].services, {
          webSocket,
        }),
        subscription: Object.assign(config[env].subscription, subscription),
        user,
        featureFlags,
      })
    );

    return this.config;
  }

  @Action
  async stopLoadingApp(): Promise<void> {
    this.setLoadingApp(false);
  }

  @Action
  async openMobileMenu(): Promise<void> {
    this.setOpenMobileMenu(true);
  }

  @Action
  async closeMobileMenu(): Promise<void> {
    this.setOpenMobileMenu(false);
  }

  @Action
  async hideFeedback(): Promise<void> {
    this.setFeedback({ display: false, type: undefined, message: "" });
  }

  @Action
  displayFeedback(feedback: {
    type?: FeedbackTypeEnum;
    message: string;
    timeout?: number;
  }): void {
    this.setFeedback({
      display: true,
      type: feedback.type ? feedback.type : FeedbackTypeEnum.FEEDBACK,
      message: feedback.message,
      timeout: feedback.timeout ? feedback.timeout : 5000,
    });
  }

  @Action
  createUtm(utm: Utm): void {
    this.setUtm(utm);
  }
}
