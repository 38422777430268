































import { defineComponent, computed } from "@vue/composition-api";
import { taskActivityStore } from "@/store";

export default defineComponent({
  name: "Notifications",
  setup() {
    return {
      userNotifications: computed(
        () => taskActivityStore.userNotificationsTaskActivities
      ),
    };
  },
});
