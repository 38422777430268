



























































































import { usePassword } from "@/composables";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { VForm } from "@/models";
import { userAccountService } from "@/services";
import { coreStore, taskActivityStore, usersStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { computed, defineComponent, ref, Ref } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "UserConnexion",
  setup(props, context) {
    const user = computed(() => usersStore.loggedInUser);
    const password: Ref<string> = ref("");
    const newPassword: Ref<string> = ref("");
    const showPassword: Ref<boolean> = ref(false);
    const showPasswordOld: Ref<boolean> = ref(false);
    // Gestion de l'edition / annulation
    const isEditingPassword: Ref<boolean> = ref(false);

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate()) {
        try {
          if (password.value === newPassword.value) {
            coreStore.displayFeedback({
              message: "Vous avez renseigné 2 fois le même mot de de passe",
            });
            return;
          }
          await userAccountService
            .update({
              username: user.value.email,
              password: password.value,
              userAccount: {
                password: newPassword.value,
              },
            })
            .then(() => {
              context.emit("validate");
              showPassword.value = false;
              showPasswordOld.value = false;
              newPassword.value = "";
              isEditingPassword.value = false;
            });
          coreStore.displayFeedback({
            message:
              "La mise à jour de votre mot de passe a bien été prise en compte",
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err?.response?.status === 403) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.ERROR,
              message: "Vérifier votre mot de passe",
            });
          } else {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.ERROR,
              message:
                "Une erreur est survenue lors de la mise à jour de votre profil",
            });
          }
        }
        await taskActivityStore.fetchTaskActivityGroups({});
        await usersStore.fetchLoggedInUser();
      }
    }

    const { validPasswordRule, getPasswordRule } = usePassword();

    function cancelUpdate() {
      password.value = "";
      newPassword.value = "";
      isEditingPassword.value = false;
    }

    return {
      password,
      newPassword,
      isEditingPassword,
      validPasswordRule,
      getPasswordRule,
      showPassword,
      showPasswordOld,
      validate,
      cancelUpdate,
      openArticlePassword: () => useCrisp().openArticle(ArticleEnum.PASSWORD),
    };
  },
});
