var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"id":"fixedAssets-list"}},_vm._l((_vm.fixedAssets),function(fixedAsset){return _c('v-col',{key:fixedAsset.id,attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
        ? '12'
        : _vm.$vuetify.breakpoint.smOnly
        ? '4'
        : _vm.$vuetify.breakpoint.mdOnly
        ? '6'
        : _vm.$vuetify.breakpoint.lgOnly
        ? '3'
        : '3'}},[_c('v-card',{staticClass:"container",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"section"},[_c('div',{staticClass:"section-img"},[_c('v-icon',[_vm._v("mdi-image-frame")])],1),_c('p',{attrs:{"id":"fixedAssets-card-name"}},[_c('b',[_vm._v(_vm._s(fixedAsset.name))])]),(fixedAsset.type)?_c('p',{attrs:{"id":"fixedAssets-card-type"}},[_vm._v(" Type d'immobilisation : "+_vm._s(_vm.getFixedAssetTypeText(fixedAsset.type))+" ")]):_vm._e(),(fixedAsset.commissioningAt)?_c('p',{attrs:{"id":"fixedAssets-card-address"}},[_vm._v(" Date de mise en service : "+_vm._s(_vm.getMoment(fixedAsset.commissioningAt).format("DD/MM/YYYY"))+" ")]):_vm._e(),(fixedAsset.realEstateAssetIds)?_c('p',{attrs:{"id":"fixedAssets-real-estate-asset"}},[_vm._v(" Biens liés : "+_vm._s(_vm.formatRealEstateAssets(fixedAsset.realEstateAssetIds))+" ")]):_vm._e(),_c('div',{staticClass:"section-action"},[_c('v-btn',{staticClass:"btn-link",attrs:{"id":"fixedAsset-card-detail","text":"","color":""},on:{"click":function () { return _vm.$emit('showDetails', fixedAsset); }}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1)])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }