












































































































































































































import {
  defineComponent,
  ref,
  Ref,
  computed,
  ComputedRef,
  onBeforeMount,
  watch,
} from "@vue/composition-api";
import { documentsService } from "@/services";
import {
  accountingPeriodsStore,
  productsStore,
  coreStore,
  partnersStore,
  taskActivityStore,
  subscriptionsStore,
} from "@/store";
import {
  getReferredIdByTypeWithReferences,
  Partner,
  SubscriptionsModel,
  SummaryTable,
  TaskActivityTypeReference,
  TaskCode,
} from "@edmp/api";
import { Dictionary, get } from "lodash";

import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import Title from "../../title/Title.vue";
import { HelpingMessage } from "@/components/atom/helping";
import DocumentCard from "@/components/core/documents/DocumentCard.vue";
import DialogSubscribe from "../DialogSubscribe.vue";

import { Document } from "@edmp/api";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "PartnersIncome",
  components: {
    AccountingPeriodChoice,
    Title,
    HelpingMessage,
    DocumentCard,
    DialogSubscribe,
  },
  setup() {
    const cerfa2072: Ref<Partial<SummaryTable>> = ref({});
    const cerfa2044Archive: Ref<Dictionary<Document>> = ref({});

    const partners: Ref<Partner[]> = ref([]);
    const isOpenDialogSubscribe = ref(false);

    const subscription = computed(() => {
      const subscription = subscriptionsStore.getCurrentSubscription;
      if (subscription?.plan.type === SubscriptionsModel.PlanType.Free) {
        isOpenDialogSubscribe.value = true;
      }
      return subscription;
    });

    const readonly: ComputedRef<boolean | undefined> = computed(
      () =>
        !!taskActivityStore.completedTaskActivities.find(
          (taskActivity) =>
            taskActivity.code === TaskCode.SendPartnersResults &&
            getReferredIdByTypeWithReferences(
              taskActivity.references,
              TaskActivityTypeReference.accountingPeriod
            ) === accountingPeriodsStore.currentId
        )
    );

    async function getTaxReturn2044(): Promise<void> {
      const product = productsStore.currentProduct;
      const accountingPeriod = accountingPeriodsStore.currentAccountingPeriod;

      partners.value = [];
      cerfa2044Archive.value = {};

      if (product && accountingPeriod && accountingPeriod.id) {
        if (!readonly.value) {
          partners.value = partnersStore.partners;
          await taxDeclarationsService
            .get2072({
              accountingPeriodId: accountingPeriod.id,
            })
            .then((res) => (cerfa2072.value = res));
        } else {
          let documents: Document[] = [];
          if (productsStore.currentId)
            documents = await documentsService.list({
              productId: productsStore.currentId,
            });
          for (const partner of partnersStore.partners) {
            const docName = `${product.name}_Revenus_A_Declarer_${
              partner?.lastName
            }_${partner?.firstName}_${new Date(
              accountingPeriod.endAt
            ).getFullYear()}.pdf`;
            const doc = documents.find(
              (doc: Document) =>
                doc.name === docName && doc.createdBy === "ownily"
            );
            if (doc) {
              const newPartnerDoc = {};
              newPartnerDoc[partner.id] = doc;
              cerfa2044Archive.value = Object.assign(
                {},
                cerfa2044Archive.value,
                newPartnerDoc
              );
              partners.value = [...partners.value, partner];
            }
          }
        }
      }
    }

    async function downloadPDF(partnerId: string, partnerType: string) {
      if (partnerType === "legal_person") {
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.WARNING,
          message:
            "Pour le moment Ownily calcule les montants à déclarer par les Associés uniquement pour les associés Personnes Physiques au titre de la Déclaration des Revenus fonciers (Cerfa 2044).",
        });
        return;
      }
      if (productsStore.currentId && accountingPeriodsStore.currentId)
        await taxDeclarationsService.download2044Report({
          accountingPeriodId: accountingPeriodsStore.currentId,
          partnerId,
        });
    }

    const init = async () => {
      await getTaxReturn2044();
    };
    onBeforeMount(async () => await init());

    watch(
      () => accountingPeriodsStore.currentAccountingPeriod,
      async () => await init(),
      { deep: true }
    );

    return {
      ROUTE_NAMES,
      get,
      readonly,
      cerfa2072,
      cerfa2044Archive,
      getTaxReturn2044,
      downloadPDF,
      cerfa2044Parts: computed(() =>
        partners.value.map((p) => {
          return {
            id: p.id,
            siret: p.siret,
            denomination: p.denomination,
            name: `${p.firstName} ${p.lastName}`,
            type: p.type ?? "natural_person",
            parts: get(
              cerfa2072.value.rows,
              `PartsFiscales.columns.Partner/${p.id}`,
              0
            ),
            value: get(
              cerfa2072.value.rows,
              `Revenus 2044.columns.Partner/${p.id}`,
              0
            ),
          };
        })
      ),
      subscription,
      SubscriptionPlanType: SubscriptionsModel.PlanType,
      isOpenDialogSubscribe,
    };
  },
});
