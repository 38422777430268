





















import { defineComponent, PropType } from "@vue/composition-api";
import BadgeNew from "./badge/BadgeNew.vue";

export default defineComponent({
  name: "SectionTitle",
  components: {
    BadgeNew,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icons: {
      type: Array as PropType<Array<string>>,
    },
    subtitle: {
      type: String,
      required: false,
    },
    badgeNew: {
      type: Boolean,
      required: false,
    },
    deletion: {
      type: Boolean,
      required: false,
    },
  },
});
