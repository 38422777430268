var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"disabled":_vm.fetchingInseeData},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('i',[_vm._v("Tous les champs sont obligatoires sauf indication contraire")]),_c('SectionTitle',{attrs:{"icons":['mdi-cash-edit', 'mdi-home'],"title":"Conditions financières"}}),_c('v-row',[_c('v-col',{staticClass:"gap mb-5 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"bold"},[_vm._v(" Le logement est-il en zone tendue où l'encadrement des loyers s'applique ? ")]),_c('YesNoSwitch',{model:{value:(_vm.hasTenseArea),callback:function ($$v) {_vm.hasTenseArea=$$v},expression:"hasTenseArea"}})],1)],1),(_vm.hasTenseArea)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Loyer mensuel de référence","outlined":"","value":_vm.rentalAgreement.financialConditions.tenseArea.monthlyRentReference,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.tenseArea
              .monthlyRentReference || 'Champ obligatoire'; },
          function () { return _vm.rentalAgreement.financialConditions.tenseArea
              .monthlyRentReference >= 0 || 'Valeur doit être positive'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.tenseArea.monthlyRentReference',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Loyer mensuel de référence majoré","outlined":"","value":_vm.rentalAgreement.financialConditions.tenseArea
            .monthlyRentReferenceIncreased,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.tenseArea
              .monthlyRentReferenceIncreased || 'Champ obligatoire'; },
          function () { return _vm.rentalAgreement.financialConditions.tenseArea
              .monthlyRentReferenceIncreased >= 0 ||
            'Valeur doit être positive'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.tenseArea.monthlyRentReferenceIncreased',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Complément de loyer (à remplir si votre loyer est supérieur au loyer de référence majoré)","outlined":"","value":_vm.rentalAgreement.financialConditions.tenseArea.supplementRent,"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.tenseArea.supplementRent',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Caractéristique de logement justifiant le complément de loyer","outlined":"","value":_vm.rentalAgreement.financialConditions.tenseArea
            .supplementRentJustification,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.tenseArea.supplementRent
              ? !!_vm.rentalAgreement.financialConditions.tenseArea
                  .supplementRentJustification || 'Champ obligatoire'
              : true; } ]},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.tenseArea.supplementRentJustification',
            }); }}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Loyer hors charges","outlined":"","value":_vm.rentalAgreement.financialConditions.rentCharge,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.rentCharge ||
            'Champ obligatoire'; },
          function () { return _vm.rentalAgreement.financialConditions.rentCharge >= 0 ||
            'Valeur doit être positive'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.rentCharge',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Montant du dépôt de garantie","outlined":"","value":_vm.rentalAgreement.financialConditions.rentGuaranteeDeposit,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.rentGuaranteeDeposit ||
            'Champ obligatoire'; },
          function () { return _vm.rentalAgreement.financialConditions.rentGuaranteeDeposit >= 0 ||
            'Valeur doit être positive'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.rentGuaranteeDeposit',
            }); }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"gap mb-5 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"bold"},[_vm._v("La location est-elle soumise à la TVA ?")]),_c('YesNoSwitch',{model:{value:(_vm.hasTVA),callback:function ($$v) {_vm.hasTVA=$$v},expression:"hasTVA"}})],1)],1),(_vm.hasTVA)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Montant de la TVA","outlined":"","value":_vm.rentalAgreement.financialConditions.amountOfTVA,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.amountOfTVA ||
            'Champ obligatoire'; },
          function () { return _vm.rentalAgreement.financialConditions.amountOfTVA >= 0 ||
            'Valeur doit être positive'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.amountOfTVA',
            }); }}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Montant des charges","outlined":"","value":_vm.rentalAgreement.financialConditions.amountOfCharges,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.amountOfCharges ||
            'Champ obligatoire'; } ],"type":"number","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.amountOfCharges',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","label":"Méthode de paiement des charges","value":_vm.rentalAgreement.financialConditions.chargePaymentMethod,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.chargePaymentMethod ||
            'Champ obligatoire'; } ],"items":_vm.paymentChargeMethodItems,"item-text":"text","item-value":"value","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.chargePaymentMethod',
            }); }}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Périodicité ou Fréquence de paiement du loyer","outlined":"","value":_vm.rentalAgreement.financialConditions.paymentTerms,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.paymentTerms ||
            'Champ obligatoire'; } ],"items":_vm.paymentTermsItems,"item-text":"text","item-value":"value","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.paymentTerms',
            }); }}}),_c('div',{staticClass:"note mb-4"},[_c('a',{attrs:{"href":"https://www.service-public.fr/simulateur/calcul/zones-tendues","target":"_blank"}},[_vm._v(" Savoir si le logement est en zone tendue ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","label":"Le loyer est à payer pour","value":_vm.rentalAgreement.financialConditions.rentPayedFor,"rules":[
          function () { return !!_vm.rentalAgreement.financialConditions.rentPayedFor ||
            'Champ obligatoire'; } ],"items":_vm.rentPayedForItems,"item-text":"text","item-value":"value","required":""},on:{"input":function (event) { return _vm.$emit('updateRentalAgreement', {
              value: event,
              path: 'financialConditions.rentPayedFor',
            }); }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"gap mb-5 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"bold"},[_vm._v(" Le bail comprend-il une clause de révision annuelle du loyer ? ")]),_c('YesNoSwitch',{model:{value:(_vm.hasIndexationClause),callback:function ($$v) {_vm.hasIndexationClause=$$v},expression:"hasIndexationClause"}})],1)],1),(
      _vm.hasIndexationClause &&
      _vm.rentalAgreement.financialConditions &&
      _vm.rentalAgreement.financialConditions.indexation
    )?[_c('SectionTitle',{attrs:{"icons":['mdi-finance'],"title":"Paramétrage de l'indexation du loyer"}}),_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"Type de l'indice de référence","value":_vm.rentalAgreement.financialConditions.indexation.type,"items":_vm.rentTypeItems,"item-text":"text","item-value":"value","hide-details":"auto","required":"","rules":[
            function () { return !!_vm.rentalAgreement.financialConditions.indexation.type ||
              'Champ obligatoire'; } ]},on:{"change":function($event){return _vm.$emit('getValueFromIndexation')},"input":function (event) {
              _vm.$emit('updateRentalAgreement', {
                value: event,
                path: 'financialConditions.indexation.type',
              });
              _vm.changeIrlType(event);
            }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","label":"IRL de référence","value":_vm.rentalAgreement.financialConditions.indexation.index,"items":_vm.indexData,"item-text":"text","item-value":"value","hide-details":"auto","required":"","rules":[
            function () { return !!_vm.rentalAgreement.financialConditions.indexation.index ||
              'Champ obligatoire'; } ]},on:{"change":function($event){return _vm.$emit('getValueFromIndexation')},"input":function (event) { return _vm.$emit('updateRentalAgreement', {
                value: event,
                path: 'financialConditions.indexation.index',
              }); }}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info","text":"","outlined":""}},[_c('ul',[_c('li',[_vm._v(" Si vous n'avez jamais indexé votre loyer, il s'agit de l'indice indiqué dans votre bail. ")]),_c('li',[_vm._v(" Sinon, il s'agit de celui utilisé lors de la dernière révision. ")])])])],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('a',{staticClass:"link-no-decoration",attrs:{"href":"https://www.insee.fr/fr/statistiques/serie/001515333","target":"_blank"}},[_vm._v(" Vérifier la valeur de l'indice de référence ")])])],1)]:_vm._e(),_c('v-row',[_c('v-col',{staticClass:"submit-btn"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"primary","type":"button"},on:{"click":_vm.goBack}},[_vm._v(" Retour ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","loading":_vm.isSubmitting,"type":"submit"}},[_vm._v(" Valider ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }