import { RequireField, addressSchema } from "./Common.model";
import { model, Schema, Document, ToObjectOptions } from "mongoose";
import { ulid } from "ulid";
import { Direction } from "./JournalComposedEntry";
import { TaxDeclarationHeaderData } from "..";
import { TaxDeclaration2033LineNumber } from "./TaxDeclaration2033.model";

// Types
export enum TaxDeclaration2031LineNumberSummaryOfTaxElements {
  "C_7_TAX_RESULT_BENEFIT" = "a - BÉNÉFICE", // C. Summary of tax elements - 1. Fiscal result - Benefit
  "C_7_TAX_RESULT_DEFICIT" = "b - DÉFICIT", // C. Summary of tax elements - 1. Fiscal result - Deficit
}
export const TaxDeclaration2031LineNumber = {
  ...TaxDeclaration2031LineNumberSummaryOfTaxElements,
};
export type TaxDeclaration2031LineNumber =
  typeof TaxDeclaration2031LineNumber[keyof typeof TaxDeclaration2031LineNumber];

export type TaxDeclaration2031LineAmount<WithTransactions extends boolean = false> = {
  amount: number;
  amountDirection: Direction;
} & (WithTransactions extends true
  ? { transactionIds: string[] }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    object);
export type TaxDeclaration2031LineText = {
  text: string;
};

export type TaxDeclaration2031LinesAmountWithTransactions = {
  [key in TaxDeclaration2031LineNumberSummaryOfTaxElements]: TaxDeclaration2031LineAmount<true>;
};
export type TaxDeclaration2031LinesAmountWithoutTransactions = {
  [key in ""]: TaxDeclaration2031LineAmount<false>;
};
export type TaxDeclaration2031LinesText = {
  [key in ""]: TaxDeclaration2031LineText;
};
export type TaxDeclaration2031Lines = TaxDeclaration2031LinesAmountWithTransactions &
  TaxDeclaration2031LinesAmountWithoutTransactions &
  TaxDeclaration2031LinesText;

// export type TaxDeclaration2031<IsArchived extends boolean = boolean> = {
//   productId: string;
//   accountingPeriodId: string;
//   isArchived: IsArchived;
//   lines: TaxDeclaration2031Lines;
// } & (IsArchived extends true ? { isArchivedByUserId: string } : Record<never, never>);

// export type TaxDeclaration2031Archive = Omit<TaxDeclaration2031, "isArchived" | "isArchivedByUserId"> & {
//   id: string;
//   user: Pick<User, "id" | "firstName" | "lastName" | "email" | "phone">;
//   product: Pick<ProductsModel.Product, "id" | "email"> & {
//     activity: RequireField<
//       Pick<
//         ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR>,
//         "id" | "type" | "address" | "activityAddress" | "siret" | "hasCga" | "cga"
//       >,
//       "siret" | "address" | "activityAddress"
//     > &
//       (
//         | Pick<
//             ActivitiesModel.Activity<
//               ActivitiesModel.ActivityTypes.OPERATOR,
//               ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
//             >,
//             "type" | "operatorType" | "firstName" | "lastName"
//           >
//         | Pick<
//             ActivitiesModel.Activity<
//               ActivitiesModel.ActivityTypes.OPERATOR,
//               ActivitiesModel.ActivityOperatorTypes.LEGAL_PERSON
//             >,
//             "type" | "operatorType" | "denomination" | "legalStatus"
//           >
//       );
//   };

//   accountingPeriod: Pick<AccountingPeriod, "id" | "startAt" | "endAt" | "taxRegime">;
//   createdAt: string;
//   updatedAt: string;
// };
// export type TaxDeclaration2031ArchiveCreate = Omit<TaxDeclaration2031Archive, "id" | "createdAt" | "updatedAt">;

export type TaxDeclaration2031DataArchive = TaxDeclarationHeaderData;
export type TaxDeclaration2031WithDataArchive<WithDataArchive extends boolean = true> = true extends WithDataArchive
  ? { dataArchived: TaxDeclaration2031DataArchive }
  : Record<never, never>;
export type TaxDeclaration2031<IsValidate extends boolean = boolean, WithDataArchive extends boolean = boolean> = {
  productId: string;
  accountingPeriodId: string;
  isValidate: IsValidate;
  lines: TaxDeclaration2031Lines;
} & (
  | { isValidate: false }
  | ({
      id: string;
      isValidate: true;
      isValidateByUserId: string;
      createdAt: string;
      updatedAt: string;
    } & TaxDeclaration2031WithDataArchive<WithDataArchive>)
);

export type TaxDeclaration2031Create = Omit<TaxDeclaration2031<true, true>, "id" | "createdAt" | "updatedAt">;

export type TaxDeclaration2031Update<> = Omit<
  RequireField<Partial<TaxDeclaration2031<true, false>>, "id">,
  "productId" | "accountingPeriodId" | "isValidateByUserId" | "createdAt" | "updatedAt"
>;

export type TaxDeclaration2031Generated = TaxDeclaration2031<false> & {
  dataArchive: TaxDeclaration2031DataArchive;
};

export type TaxDeclaration2031LinesMetadata = {
  name: string;
  description?: string;
  accounts: TaxDeclaration2033LineNumber[];
};

// Mongo
export type TaxDeclaration2031Document = TaxDeclaration2031<true, true> & Document<string>;

const TaxDeclaration2031Schema = new Schema<TaxDeclaration2031Document>(
  {
    _id: { type: String, default: (): string => ulid() },
    productId: { type: String, index: true, required: true },
    accountingPeriodId: { type: String, index: true, required: true },
    isValidate: { type: Boolean },
    isValidateByUserId: { type: String },
    lines: {
      type: Map,
      of: {
        amount: { type: Number, required: true },
        amountDirection: { type: String, enum: Object.values(Direction), required: true },
      },
      required: true,
    },
    dataArchived: {
      user: {
        id: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        email: { type: String },
      },
      product: {
        id: { type: String },
        email: { type: String },
      },
      activity: {
        id: { type: String },
        type: { type: String },
        address: addressSchema,
        addressPrevious: addressSchema,
        siret: { type: String },
        name: { type: String },
        operatorType: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        legalStatus: { type: String },
      },
      accountingPeriod: {
        id: { type: String },
        startAt: { type: String },
        endAt: { type: String },
        taxRegime: { type: String },
        firstYear: { type: Boolean },
      },
      accountingPeriodPrevious: {
        startAt: { type: String },
        endAt: { type: String },
      },
      partnerOwned: {
        id: { type: String },
        type: { type: String },
        firstName: { type: String },
        lastName: { type: String },
        denomination: { type: String },
        role: { type: String },
        createdAt: { type: String },
      },
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(
        doc: Omit<TaxDeclaration2031Document, "createdAt" | "updatedAt"> & {
          _id: string;
          createdAt: Date;
          updatedAt: Date;
        },
        ret: TaxDeclaration2031<true> & { _id?: string },
        options: ToObjectOptions
      ) {
        delete ret._id;
        ret.id = doc._id;

        ret.lines = (
          doc.lines as unknown as Map<TaxDeclaration2031LineNumber, typeof ret.lines[TaxDeclaration2031LineNumber]> &
            Document
        ).toJSON<typeof ret.lines>();
        for (const [lineName, line] of Object.entries(ret.lines)) {
          ret.lines[lineName as unknown as TaxDeclaration2031LineNumber] = (
            line as unknown as typeof doc.lines[TaxDeclaration2031LineNumber] & Document
          ).toJSON<typeof ret.lines[TaxDeclaration2031LineNumber]>();
        }

        return ret;
      },
    },
  }
);

export const TaxDeclaration2031Model = model<TaxDeclaration2031Document>(
  "TaxDeclaration2031",
  TaxDeclaration2031Schema,
  "TaxDeclarations2031"
);

// Function
export const isTaxDeclaration2031LineNumberSummaryOfTaxElements = (lineNumber: TaxDeclaration2031LineNumber) =>
  Object.values(TaxDeclaration2031LineNumberSummaryOfTaxElements).includes(lineNumber);

// Const
const taxDeclaration2031LinesMetadataLayer0: {
  [key in Extract<
    TaxDeclaration2031LineNumber,
    | TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_BENEFIT
    | TaxDeclaration2031LineNumberSummaryOfTaxElements.C_7_TAX_RESULT_DEFICIT
  >]: TaxDeclaration2031LinesMetadata;
} = {
  "a - BÉNÉFICE": {
    name: "Résultat fiscal - Bénéfice",
    accounts: [TaxDeclaration2033LineNumber.L352],
  },
  "b - DÉFICIT": {
    name: "Résultat fiscal - Deficit",
    accounts: [TaxDeclaration2033LineNumber.L354],
  },
};

export const taxDeclaration2031LinesMetadata: {
  [key in TaxDeclaration2031LineNumber]: TaxDeclaration2031LinesMetadata;
} = {
  ...taxDeclaration2031LinesMetadataLayer0,
};
