import { UserAccountsService } from "@edmp/api";
import axios from "axios";
import { permissionsService } from "@/services/Permissions.service";

const apiMeta = "/meta/v1";

class UserAccountsService {
  async create(userAccountCreate: UserAccountsService.CreateIn) {
    return axios
      .post<UserAccountsService.LoginOut>(
        `${apiMeta}/registers`,
        userAccountCreate
      )
      .then((res) => {
        return res.data;
      });
  }

  async get(params: UserAccountsService.GetIn) {
    return axios
      .get<UserAccountsService.GetOut>(
        `${apiMeta}/accounts/${params.username}`,
        { params }
      )
      .then((res) => {
        return res.data;
      });
  }

  async getMe(params: UserAccountsService.GetMeIn) {
    return axios
      .get<UserAccountsService.GetMeOut>(`${apiMeta}/login/me`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(userAccountUpdate: UserAccountsService.UpdateIn): Promise<void> {
    return axios
      .post<UserAccountsService.UpdateOut>(
        `${apiMeta}/accounts/${userAccountUpdate.username}`,
        userAccountUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async updatePassword(
    passwordUpdate: UserAccountsService.UpdatePasswordIn
  ): Promise<void> {
    return axios
      .post<UserAccountsService.UpdatePasswordOut>(
        `${apiMeta}/accounts/update-password`,
        passwordUpdate
      )
      .then((res) => {
        return res.data;
      });
  }

  async resetPassword(resetPassword: UserAccountsService.ResetPasswordIn) {
    return axios
      .post<UserAccountsService.ResetPasswordOut>(
        `${apiMeta}/accounts/reset-password`,
        resetPassword
      )
      .then((res) => {
        return res.data;
      });
  }

  async login(credentials: UserAccountsService.LoginIn) {
    return axios
      .post<UserAccountsService.LoginOut>(`${apiMeta}/login`, credentials)
      .then((res) => {
        return res.data;
      });
  }

  async logout(data?: UserAccountsService.LogoutIn) {
    return axios
      .post<UserAccountsService.LogoutOut>(`${apiMeta}/logout`, data)
      .then((res) => {
        permissionsService.removeAbility();
        return res.data;
      });
  }

  async renewToken(data: UserAccountsService.RenewTokenIn) {
    return axios
      .post<UserAccountsService.RenewTokenOut>(`${apiMeta}/logout`, data)
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const userAccountService = new UserAccountsService();
