export namespace TaxDeclarationProviderTeledecModel {
  export namespace API {
    export namespace Auth {
      export enum SCOPE {
        LIASSE = "liasse",
        MARQUE_BLANCHE = "marque-blanche",
        LISTE_CR = "liste-cr",
        ENVOI_LIASSE_EXISTANTE = "envoi-liasse-existante",
        CREATION_ENTREPRISE = "creation-entreprise",
        LISTE_DECLARATIONS = "liste-declarations",
        LIASSE_PDF = "liasse-pdf",
        EXTRAIT_LIASSE = "extrait-liasse",
        CHIFFRES_CLES = "chiffres-cles",
        IMPORT_TVA = "import-tva",
        RENVOI_ACCUSE = "renvoi-accuse",
        END_USER_ACCESS_TOKEN = "end-user-access-token",
      }

      export type TokenIn = {
        grant_type: "client_credentials";
        scope: string;
      };
      export type TokenOut = {
        access_token: string;
        expire_in: 3600;
        token_type: "Bearer";
      };
    }
  }

  export enum LEGAL_FROM {
    Association = "ASS",
    EARL = "ARL",
    Entreprise_Individuelle = "EIR",
    EIRL = "ERL",
    EURL = "GEC",
    GAEC = "SA",
    S_A = "SAS",
    S_A_Simplifiée = "SASU",
    SAS_Unipersonnelle = "SRL",
    SARL = "SEA",
    SCEA = "SCI",
    SCI = "SCM",
    SELARL = "SLR",
    SNC = "SNC",
    Autre = "ZZZ",
  }

  export enum ROF {
    IS_2065 = "IS1", // Déclaration 2065 normale ou simplifiée (IS)
    COM_2031 = "BIC1", // Déclaration 2031 normale ou simplifiée (commerçant)
    PRO_LIB_2035 = "BNC1", // Déclaration 2035 (profession libérale)
    TVA = "TVA1", // Déclaration de TVA
    CFE = "CFE1", // Cotisation foncière des entreprises (CFE)
    CVAE = "CVAE1", // Déclaration 1330-CVAE ou CVAE
    AGRI = "BA1", // Déclaration 2342, 2143 ou 2139 (agriculteurs)
    SCI_LOC = "RF1", // Déclaration normale ou simplifiée d'une SCI de location (revenus fonciers)
    CAP_MOB = "RCM1", // Déclarations 2777, 2561 et plus généralement, toutes les déclarations de revenus de capitaux mobiliers
    FPC = "FPC1", // Formation professionnelle continue
    TA = "TA1", // Taxe d'apprentissage
    SAL_TAX = "TPS1", // Taxe sur les salaires
    UNKNOWN = "1", // ! Validé par Thomas BRETHIOT<tbrethiot@teledec.fr>
  }

  // * Tax package - for `service.tax-declarations-provider-teledec.sendWhiteLabelDeclaration` params -> `/service/declaration-marque-blanche`
  export namespace TaxPackage {
    export type Auth = {
      email: string; // correspond à l'email du compte Partenaire
      timestamp: string; // correspond à l'instant génération de la chaîne JSON.  Il doit être au format "YYYY-MM-DDThh:mm:ss" ; sera interprété comme une heure française et dater de moins d’une heure que l’heure à laquelle Teledec reçoit la requête.
      url: string; // ! URL de callback; feature activable sur demande auprès de Teledec. Paramètrer par défaut par teledec sur prod: `https://app.ownily.fr/webhook/teledec`, stage: `https://ownily-sta.cleverapps.io/webhook/teledec`. Peut être modifié sur l'env de `stage` pour tester en review ou en dev (en redirigant l'url `https://dev.ownily.fr/webhook/teledec` vers le routeur du développeur).
      bloquerSiIncoherence?: boolean; // Paramètre optionnel permettant de ne pas envoyer la déclaration de TVA si elle comporte des erreurs ou des incohérences. Valeur par défaut: false. Applicable uniquement aux déclarations de TVA
      retournerLien?: boolean; // ! Add true for not send to DGFIP. Permet de récuperer un lien vers la webview de la liasse. Booléen optionnel. Si ce champ est spécifié à true pour un partenaire qui est configuré pour fonctionner normalement en envoi direct de déclarations sans affichage d'écrans Teledec, alors il est possible de récupérer dans la payload de retour un lien qui conduira directement à l'écran Teledec de la déclaration sans ce que celle-ci n'ait été envoyée.
      retournerPdf?: boolean; // ! Add true for not send to DGFIP. Boolean permettant d'avoir une URL dynamique et temporaire du PDF de la liasse envoyée. (Annexe D) Ne transmet pas la déclaration directement à la DGFIP; nécessite une action via le web pour la transmettre
      test?: boolean; // est à positionner à true pour un envoi en mode test; et à false pour un envoi en mode réel.
    };

    // Correspond aux éléments identifiants la société ou le professionnel déclaré.
    export type Identity<EdiRequest extends "subscribe" | undefined = undefined> = {
      rangROF: ROF;
      siret: string;
      name: string;
      yearEndMonth: number;
      yearEndDay: number;
      addressStreet: string;
      addressComplement: string;
      addressNeighborhood: string;
      addressPostalCode: string;
      addressCity: string;
      addressCountry: string;
      legalForm: LEGAL_FROM;
      legalRepresentative: string;
      legalRepresentativeAs: string;
      legalRepresentativeTitle: string;
      telephone: string;
      email: string;
      ediRequete?: EdiRequest; // Champ optionnel qui permet de souscrire à l'option EDI Requete (récupération des DECLOYER). Ne pas resneigner sinon.
      ogaName?: string; // Champ optionnel permettant d'associer un OGA; utiliser la clé (en relation avec le champ ogaNumeroAdherent ci dessous). Se référer à la page listant les OGA
      ogaNumeroAdherent?: string; // Champ optionnel permettant de renseigner le numéro d'adhérent pour un OGA (en relation avec le champ ogaName ci dessus)
      ogaNumeroAgrement?: string;
    } & (EdiRequest extends "subscribe"
      ? {
          fullRegimeFiscal: string; // A ne renseigner que si l'option EDI Requete est souscrite; sinon le régime fiscal est déterminé selon les formulaires envoyés // ? Je ne comprends pas quoi mettre ici
        }
      : Record<never, never>);

    export type Period<FirstYear extends boolean = boolean> = {
      begin: string; // YYYY-MM-DD
      end: string; // YYYY-MM-DD
      cessation: string; // YYYY-MM-DD
      millesime: number; // 2022; 2023; 2024; ...
      suspension: boolean;
      reference: string; // ! Taille (lenght) limité, Champ personalisable par le partenaire
      echeance: string; // YYYY-MM-DD
      firstYear: FirstYear;
      ogaEnvoi?: boolean; // Champ optionnel permettant de spécifier si la déclaration doit être envoyé à l'OGA décrit dans la partie identity
    } & (FirstYear extends false
      ? {
          firstYear: false;
          previousEnd: string; // YYYY-MM-DD
          previousDuration: number;
        }
      : Record<never, never>);

    export type TaxPackageIn = {
      auth: Auth;
      identity: Identity;
      period: Period;
      [Forms.TYPES.FORM_2065]?: Forms.FORM_2065;
      [Forms.TYPES.FORM_2033A]?: Forms.FORM_2033A;
      [Forms.TYPES.FORM_2033B]?: Forms.FORM_2033B;
      [Forms.TYPES.FORM_2033C]?: Forms.FORM_2033C;
      [Forms.TYPES.FORM_2033D]?: Forms.FORM_2033D;
      [Forms.TYPES.FORM_2033E]?: Forms.FORM_2033E;
      [Forms.TYPES.FORM_2033F]?: Forms.FORM_2033F;
      [Forms.TYPES.FORM_2033G]?: Forms.FORM_2033G;
      [Forms.TYPES.FORM_2069RCI]?: Forms.FORM_2069RCI;
      [Forms.TYPES.FORM_2031]?: Forms.FORM_2031;
      [Forms.TYPES.FORM_SUIV39C]?: Forms.FORM_SUIV39C;
    };
    export type TaxPackageOut = {
      // ! C’est bien la combinaison du code HTTP 200 plus le status ok dans la payload JSON qui attestent que la liasse a bien été envoyée. La prise en compte du code HTTP 200 OK seul ne suffit pas à confirmer que la liasse a été envoyée car certains messages d’erreur peuvent être renvoyés avec un code HTTP 200.
      status: "ok" | "ERREUR"; // comprend un message qui prend comme valeur `ok` si tout s’est bien passé; sinon un message d’erreur en français (champ message).
    } & (
      | {
          status: "ok";
          lien?: string; // Si `auth.retournerLien = true`. URL dynamique et temporaire de la webview de la liasse envoyée. Feature activable par Teledec sur demande par partenaire.
          lienPdf?: string; // Si `auth.retournerPdf = true`. URL dynamique et temporaire du PDF de la liasse envoyée. Feature activable par Teledec sur demande par partenaire. Téléchargez le PDF après consultation afin de garder un historique.
        }
      | { status: "ERREUR"; message: string }
    );
  }

  export namespace WebHook {
    export enum STATUS {
      OK = "OK",
      ERROR = "ERREUR",
    }

    export type ErrorDeclarationErreur<FORMULAIRE extends Forms.TYPES = Forms.TYPES> = {
      formulaire: FORMULAIRE;
      champ:
        | (FORMULAIRE extends Forms.TYPES.FORM_2065
            ? keyof Forms.FORM_2065
            : FORMULAIRE extends Forms.TYPES.FORM_2033A
            ? keyof Forms.FORM_2033A
            : FORMULAIRE extends Forms.TYPES.FORM_2033B
            ? keyof Forms.FORM_2033B
            : never)
        | string;
      champLibelle: string;
      champValeur: string;
      code: string;
      libelle: string;
    };

    export type Event<EventStatus extends STATUS = STATUS, FormType extends Forms.TYPES = Forms.TYPES> = {
      declarationId: number;
      status: EventStatus;
      declarationStatus: EventStatus;
      reference: string;
      siren: string;
      nom: string;
      dateHeureDGFiP: string; // Date TZ
      dateDebut: string; // YYYY-MM-DD
      dateFin: string; // YYYY-MM-DD
      statusLibelle: string;
      compte: string;
      referenceDGFiP: string;
      numeroTraitementDGFiP: string;
      rof: ROF;
    } & (
      | {
          status: STATUS.OK;
          pdf: string; // base64
        }
      | {
          status: STATUS.ERROR;
          dateEcheance: string; // YYYY-MM-DD
          libelle: string;
          declarationType: string;
          formulaire: FormType;
          declarationErreurs: ErrorDeclarationErreur<FormType>[];
        }
    );
  }

  export namespace Forms {
    // `ISRS` Impôts sur les sociétés Réel Simplifié
    export enum TYPES {
      FORM_2065 = "2065",
      FORM_2033A = "2033A",
      FORM_2033B = "2033B",
      FORM_2033C = "2033C",
      FORM_2033D = "2033D",
      FORM_2033E = "2033E",
      FORM_2033F = "2033F",
      FORM_2033G = "2033G",
      FORM_2069RCI = "2069RCI",
      FORM_2031 = "2031",
      FORM_SUIV39C = "SUIV39C",
    }

    export type FORM_2065 = {
      AA_2065?: boolean; // Si vous êtes l'entreprise; tête de groupe; soumise au dépôt de la déclaration n° 2258-SD (art. 223 quinquies C-I-1); cocher la case ci-contre
      AB_2065?: boolean; // Si vous êtes l'entreprise désignée au dépôt de la déclaration n° 2258-SD par la société tête de groupe (art. 223 quinquies C-I-2); cocher la case ci-contre
      AC_2065_3042_1?: string; // Adresse du siège social; numéro; type et nom voie
      AC_2065_3042_2?: string; // Adresse du siège social; complément de distribution
      AC_2065_3042_3?: string; // Adresse du siège social; lieu-dit hameau
      AC_2065_3164_1?: string; // Adresse du siège social; ville
      AC_2065_3251_1?: string; // Adresse du siège social; code postal
      AC_2065_3207_1?: string; // Adresse du siège social; pays // Code ISO (FR)
      AD_2065_3036_1?: string; // Si une autre entité située en France ou dans un pays ou territoire soumis au dépôt de la déclaration; désignée pour le dépôt de la déclaration pays par pays n°2258; indiquer le nom et la localisation; Dénomination
      AD_2065_3036_2?: string; // Complément de dénomination de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3036_3?: string; // Complément 2 de dénomination de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3039_1?: string; // Numéro d'identification fiscale de l'entité désignée
      AD_2065_3042_1?: string; // Numéro; type et nom voie de l'autre entreprise a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3042_2?: string; // Complément de distribution de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3042_3?: string; // Lieu-dit; hameau de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3164_1?: string; // Ville de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3207_1?: string; // Pays de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AD_2065_3251_1?: string; // Code postal de l'autre entreprise qui a été désignée pour le dépôt de la déclaration pays par pays n°2258
      AK_2065_3042_2?: string; // Ancienne adresse en cas de changement; complément de distribution
      AK_2065_3042_1?: string; // Ancienne adresse en cas de changement; numéro; type et nom voie
      AK_2065_3042_3?: string; // Ancienne adresse en cas de changement; lieu-dit; hameau
      AK_2065_3164_1?: string; // Ancienne adresse en cas de changement; ville
      AK_2065_3207_1?: string; // Ancienne adresse en cas de changement; pays // Code ISO (FR)
      AK_2065_3251_1?: string; // Ancienne adresse en cas de changement; code postal
      AP_2065?: boolean; // Si vous avez changé d'activité?
      AQ_2065_4440_1?: string; // Activités exercées; libellé 1
      AQ_2065_4440_2?: string; // Activités exercées; libellé 2
      HA_2065?: number; // Bénéfice imposable au taux normal
      AT_2065?: number; // Résultat fiscal; déficit
      LC_2065?: number; // Bénéfice imposable à 15%
      AU_2065?: number; // Plus-values à long terme imposables au taux de 15%
      AV_2065_3036_1?: string; // Désignation de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3036_2?: string; // Complément de désignation de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3036_3?: string; // Forme juridique de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3030_1?: string; // Identifiant numérique de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_1?: string; // Numéro; type et nom voie de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_2?: string; // Complément de distribution de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3042_3?: string; // Lieu-dit; hameau de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3164_1?: string; // Ville de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3207_1?: string; // État de résidence de l’entité du groupe qui souscrit la déclaration pays par pays
      AV_2065_3251_1?: string; // Code postal de l’entité du groupe qui souscrit la déclaration pays par pays
      AX_2065?: boolean; // Entreprises nouvelles CGI article 44 sexies
      AY_2065?: boolean; // Reprise d'entreprises en difficultés; article 44 septies
      AW_2065?: boolean; // Zone de revitalisation rurale; art. 44 quindecies
      BA_2065?: boolean; // Bassins d’emploi à redynamiser; art.44 duodecies
      BB_2065?: number; // Bénéfice ou déficit exonéré
      BE_2065?: number; // Au titre des revenus mobiliers de source française ou étrangères ayant donné lieu à un certificat de crédit d'impôt
      BF_2065?: number; // Imputation au titre des revenus étrangers auxquels est attaché un crédit d'impôt représentatif de l'impôt de cet état; territoire ou collectivité
      BN_2065?: string; // Comptable indépendant ou salarié?
      BQ_2065?: string; // Conseil indépendant ou salarié?
      CA_2065_3036_1?: string; // Nom et adresse du comptable; nom et prénom ou dénomination
      CA_2065_3036_2?: string; // Nom et adresse du comptable; qualité et fonction ou complément de dénomination
      CA_2065_3036_3?: string; // Nom et adresse du comptable; forme juridique ou titre
      CA_2065_3042_2?: string; // Nom et adresse du comptable; complément de distribution
      CA_2065_3042_1?: string; // Nom et adresse du comptable; numéro; type et nom voie
      CA_2065_3042_3?: string; // Nom et adresse du comptable; lieu-dit hameau
      CA_2065_3148_1_TE?: string; // Nom et adresse du comptable; numéro de téléphone
      CA_2065_3164_1?: string; // Nom et adresse du comptable; ville
      CA_2065_3207_1?: string; // Nom et adresse du comptable; pays // Code ISO (FR)
      CA_2065_3251_1?: string; // Nom et adresse du comptable; code postal
      DA_2065_3036_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; dénomination
      DA_2065_3036_2?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; complément de désignation
      DA_2065_3036_3?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; forme juridique
      DA_2065_3039_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; numéro d'agrément
      DA_2065_3042_2?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; complément de distribution
      DA_2065_3042_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; numéro; type et nom voie
      DA_2065_3042_3?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; lieu-dit hameau
      DA_2065_3164_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; ville
      DA_2065_3207_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; pays // Code ISO (FR)
      DA_2065_3251_1?: string; // Nom et adresse du CGA de l'OMGA ou du viseur conventionné; code postal
      EA_2065_3036_1?: string; // Nom et adresse du conseil; nom et prénom ou dénomination
      EA_2065_3036_2?: string; // Nom et adresse du conseil; qualité et profession ou complément de dénomination
      EA_2065_3036_3?: string; // Nom et adresse du conseil; forme juridique ou titre
      EA_2065_3042_2?: string; // Nom et adresse du conseil; complément de distribution
      EA_2065_3042_1?: string; // Nom et adresse du conseil; numéro; type et nom voie
      EA_2065_3042_3?: string; // Nom et adresse du conseil; lieu-dit; hameau
      EA_2065_3148_1_TE?: string; // Nom et adresse du conseil; téléphone
      EA_2065_3164_1?: string; // Nom et adresse du conseil; ville
      EA_2065_3207_1?: string; // Nom et adresse du conseil; pays // Code ISO (FR)
      EA_2065_3251_1?: string; // Nom et adresse du conseil; code postal
      HD_2065?: boolean; // Zone franche Urbaine – Territoire entrepreneur; art 44 octies A
      HJ_2065?: boolean; // Entreprises nouvelles ou zones franches ou zones d'entreprises; autres dispositifs ?
      JA_2065?: number; // Contribution représentative du droit au bail; recettes nettes soumises à la contribution de 2;5% ou CRL à compter de 2004
      LL_2065?: boolean; // Société d'investissement immobilier cotées?
      LN_2065?: number; // Plus-values à long terme imposables à 0%
      LQ_2065?: boolean; // Jeune entreprise innovante ?
      LT_2065?: number; // Plus-values exonérées art 238 quindecies
      LV_2065?: number; // Abattements et exonerations notamment en faveur des entreprises nouvelles ou implantées en zones d'entreprises ou zones franches - Plus values exonérées relevant du taux de 15%
      LW_2065?: number; // Plus value a long terme imposable a 19%
      LX_2065?: number; // Autres plus values imposables a 19%
      LY_2065?: boolean; // Zone franche d'activité ?
      MA_2065?: boolean; // Zone de restructuration de la défense art.44 terdecies ?
      ME_2065?: boolean; // Visa CGA/AGA
      MF_2065?: boolean; // Visa viseur conventionné
      PA_2065?: string; // Date d'entrée dans le groupe de la société déclarante
      PD_2065_3036_1?: string; // Désignation société mère
      PD_2065_3036_2?: string; // Complément de désignation société mère
      PD_2065_3036_3?: string; // Forme juridique société mère
      PD_2065_3039_1?: string; // SIRET société mère
      PD_2065_3042_1?: string; // Numéro; type et nom voie société mère
      PD_2065_3042_2?: string; // Complément de distribution société mère
      PD_2065_3042_3?: string; // Lieu-dit; hameau société mère
      PD_2065_3164_1?: string; // Ville société mère
      PD_2065_3207_1?: string; // Pays société mère // Code ISO (FR)
      PD_2065_3251_1?: string; // Code postal société mère
      PE_2065?: boolean; // Si option pour le régime optionnel de taxation au tonnage; art. 209-0 B (entreprises de transport maritime)
      PF_2065?: boolean; // Option pour le crédit d'impôt outre-mer dans le secteur productif; art. 244 quater W
      PH_2065?: boolean; // Si PME innovantes
      AF_2065?: boolean; // Bassins urbains à dynamiser (BUD); art.44 sexdecies
      AG_2065_3039_1?: string; // Identification fiscale de la société tête de groupe
      AG_2065_3036_1?: string; // Dénomination de la société tête de groupe
      AG_2065_3036_2?: string; // Complément de dénomination de la société tête de groupe
      AG_2065_3036_3?: string; // Complément 2 de dénomination de la société tête de groupe
      AG_2065_3042_1?: string; // Numéro; type et nom voie de la société tête de groupe
      AG_2065_3042_2?: string; // Complément de distribution de la société tête de groupe
      AG_2065_3042_3?: string; // Lieu-dit; hameau de la société tête de groupe
      AG_2065_3164_1?: string; // Ville de la société tête de groupe
      AG_2065_3207_1?: string; // Pays de la société tête de groupe // Code ISO (FR)
      AG_2065_3251_1?: string; // Code postal de la société tête de groupe
      AH_2065?: boolean; // L'entreprise dispose-t-elle d'une comptabilité informatisée ?
      AJ_2065?: string; // Si oui; indication du logiciel utilisé
      AM_2065?: boolean; // Zone de développement prioritaire; art. 44 septdecies
      AL_2065?: number; // Résultat net de cession; de concession ou de sous-concession des brevets et droits de propriété industrielle assimilés imposable au taux de 10 %
      AR_2065?: boolean; // ECF
      AS_2065_3036_1?: string; // Dénomination du prestataire
      AS_2065_3036_2?: string; // Complément de dénomination du prestataire
      AS_2065_3036_3?: string; // Complément 2 de dénomination du prestataire
      AS_2065_3039_1?: string; // Siren du prestataire
      AS_2065_3042_1?: string; // Numéro; type et nom voie du prestataire
      AS_2065_3042_2?: string; // Complément de distribution du prestataire
      AS_2065_3042_3?: string; // Lieu-dit; hameau du prestataire
      AS_2065_3164_1?: string; // Ville du prestataire
      AS_2065_3207_1?: string; // Pays du prestataire // Code ISO (FR)
      AS_2065_3251_1?: string; // Code postal du prestataire
      PJ_2065?: number; // Assiette de la contribution temporaire de solidarité au taux de 33%
    };

    export type FORM_2033A = {
      JD?: boolean; // Déposé néant?

      AA?: number; // Immobilisations incorporelles; fonds commercial; brut
      AB?: number; // Autres immobilisations incorporelles; brut
      AC?: number; // Immobilisations corporelles; brut
      AD?: number; // Immobilisations financières; brut
      AE?: number; // Total I de l'actif immobilisé; brut
      AF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; brut
      AG?: number; // Stocks de marchandises; brut
      AH?: number; // Avances et acomptes versés sur commandes; brut
      AJ?: number; // Créances clients et comptes rattachés; brut
      AK?: number; // Autres créances; brut
      AL?: number; // Valeurs mobilières de placement; brut
      AM?: number; // Disponibilités; brut
      AP?: number; // Charges constatées d'avance; brut
      AQ?: number; // Total II de l'actif circulant; brut
      AR?: number; // Total général de l'actif; brut
      BA?: number; // Immobilisations incorporelles; fonds commercial; amortissements et provisions
      BB?: number; // Autres immobilisations incorporelles; amortissements et provisions
      BC?: number; // Immobilisations corporelles; amortissements et provisions
      BD?: number; // Immobilisations financières; amortissements et provisions
      BE?: number; // Total I de l'actif immobilisé; amortissements et provisions
      BF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; amortissements et provisions
      BG?: number; // Stocks de marchandises; amortissements et provisions
      BH?: number; // Avances et acomptes versés sur commandes; amortissements et provisions
      BJ?: number; // Créances clients et comptes rattachés; amortissements et provisions
      BK?: number; // Autres créances; amortissements et provisions
      BL?: number; // Valeurs mobilières de placement; amortissements et provisions
      BM?: number; // Disponibilités; amortissements et provisions
      BP?: number; // Charges constatées d'avance; amortissements et provisions
      BQ?: number; // Total II de l'actif circulant; amortissements et provisions
      BR?: number; // Total général de l'actif; amortissements et provisions
      CA?: number; // Immobilisations incorporelles; fonds commercial; net N
      CB?: number; // Autres immobilisations incorporelles; net N
      CC?: number; // Immobilisations corporelles; net N
      CD?: number; // Immobilisations financières; net N
      CE?: number; // Total I de l'actif immobilisé; net N
      CF?: number; // Stocks de matières premières; d'approvisionnements et en cours de production; net N
      CG?: number; // Stocks de marchandises; net N
      CH?: number; // Avances et acomptes versés sur commandes; net N
      CJ?: number; // Créances clients et comptes rattachés; net N
      CK?: number; // Autres créances; net N
      CL?: number; // Valeurs mobilières de placement; net N
      CM?: number; // Disponibilités; net N
      CP?: number; // Charges constatées d'avance; net N
      CQ?: number; // Total II de l'actif circulant; net N
      CR?: number; // Total général de l'actif; net N
      EB?: number; // Réserve relative à l'achat d'œuvres d'art d'artistes vivants
      EE?: number; // Comptes courants d'associés
      AT?: number; // dont montant de la TVA
      FA?: number; // Capital social ou individuel
      FB?: number; // Écarts de réévaluation
      FC?: number; // Réserve légale
      FD?: number; // Réserves réglementées exercice N
      FE?: number; // Autres réserves
      FF?: number; // Report à nouveau
      FG?: number; // Résultat de l'exercice
      FH?: number; // Provisions réglementées
      FJ?: number; // Total I des capitaux propres
      FK?: number; // Provisions pour risques et charges
      FL?: number; // Emprunts et dettes assimilées
      FM?: number; // Avances et acomptes reçus sur commandes en cours
      FN?: number; // Fournisseurs et comptes rattachés
      FP?: number; // Autres dettes
      AU?: number; // Dettes fiscales et sociales
      FQ?: number; // Produits constatés d'avance
      FR?: number; // Total III des dettes
      FS?: number; // Total général du passif
      HA?: number; // Immobilisations financières à moins d'un an
      HB?: number; // Créances à plus d'un an
      HC?: number; // Comptes courants d'associés débiteurs
      JA?: number; // Dettes à plus d'un an
      JB?: number; // Coût de revient des immobilisations acquises ou créées lors de l'exercice
      JC?: number; // Prix de vente hors TVA des immobilisations cédées au cours de l'exercice
    };

    export type FORM_2033B = {
      JB?: boolean; // Déposé néant?

      AA?: number; // Ventes de marchandises; export et livraisons intra communautaires
      AB?: number; // Production vendue de biens; export et livraisons intra communautaires
      AC?: number; // Production vendue de services; export et livraisons intra communautaires
      AD?: number; // Part de loyers dispensés de réintégration
      AE?: number; // Fraction des loyers versés dans le cadre d'un crédit bail immobilier et de levée d'option
      AF?: number; // Charges afférentes à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AG?: number; // Produits afférents à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AH?: number; // Déduction exceptionnelle
      AJ?: number; // Résultat fiscal afférent à l'activité relevant du régime optionnel de taxation au tonnage des entreprises de transport maritime
      AK?: number; // Amortissement des souscriptions dans des PME innovantes (art 217 octies)
      AL?: number; // Amortissement exceptionnel de 25% des constructions nouvelles (art 39 quinquies D)
      AN?: number; // Crédit bail immobilier
      AP?: number; // Taxe professionnelle
      AQ?: number; // Bassins d'emploi à redynamiser (art. 44 duodecies)
      AU?: number; // Provisions fiscales pour implantations commerciales à l'étranger
      AV?: number; // Autres charges; cotisations versées aux organisations syndicales et professionnelles
      BA?: number; // Ventes de marchandises
      BB?: number; // Production vendue de biens
      BC?: number; // Production vendue de services
      BD?: number; // Production stockée
      BE?: number; // Production immobilisée
      BF?: number; // Subventions d'exploitation reçues
      BG?: number; // Autres produits
      BH?: number; // Total des produits d'exploitation hors TVA
      BJ?: number; // Achats de marchandises; y compris droits de douane
      BK?: number; // Variation de stock de marchandises
      BL?: number; // Achats de matières premières et autres approvisionnements y compris droits de douane
      BM?: number; // Variation de stock de matières premières et d'approvisionnements
      BN?: number; // Autres charges externes
      BP?: number; // Impôts; taxes et versements assimilés
      BQ?: number; // Rémunérations du personnel
      BR?: number; // Charges sociales
      BS?: number; // Dotations aux amortissements
      CK?: number; // Dont amortissements fonds de commerce
      BT?: number; // Dotations aux provisions
      BU?: number; // Autres charges
      BV?: number; // Total des charges d'exploitation
      BW?: number; // Résultat d'exploitation
      BX?: number; // Produits financiers
      BY?: number; // Produits exceptionnels
      BZ?: number; // Charges financières
      CA?: number; // Charges exceptionnelles
      CB?: number; // Impôt sur les bénéfices
      CC?: number; // Bénéfice ou perte N
      CD?: number; // Report du bénéfice
      CE?: number; // Rémunérations et avantages personnels non déductibles
      CF?: number; // Amortissements excédentaires et autres amortissements non déductibles
      CG?: number; // Provisions non déductibles
      CH?: number; // Impôts et taxes non déductibles
      CJ?: number; // Réintégrations diverses
      CM?: number; // Bénéfice fiscal avant imputation des déficits antérieurs
      CN?: number; // Déficit de l'exercice reporté en arrière pour entreprises IS
      CR?: number; // Bénéfice fiscal après imputation des déficits
      ED?: number; // Report du déficit
      EK?: number; // Exonérations ou abattements sur le bénéfice et exonération entreprises nouvelles ou zone franche Corse
      EL?: number; // Déductions diverses
      EM?: number; // Déficit fiscal avant imputation des déficits antérieurs
      EP?: number; // Déficits antérieurs reportables
      ER?: number; // Déficit fiscal après imputation des déficits
      FA?: number; // Zone restruct. Défense (44 terdecies)
      FB?: number; // Zone revitalisation rurales (44 quindecies)
      FJ?: number; // Écarts de valeurs liquidatives sur OPC
      FK?: number; // Intérêts excédentaires sur comptes courants d'associés
      FL?: number; // Créance due au report en arrière du déficit
      GN?: number; // Crédit bail mobilier
      HL?: number; // Investissements et souscriptions outre-mer
      HW?: number; // Exonération ou abattement sur le bénéfice pour reprise d 'entreprises en difficulté
      JA?: number; // Déficits antérieurs reportables pour les entreprises à l'IS
      repetition2033BReintegrations?: { LA: string; LB: number }[]; // Détail des divers à réintégrer au résultat fiscal; libellé/montant
      repetition2033BDeductions?: { MA: string; MB: number }[]; // Détail des divers à déduire du résultat fiscal; libellé/montant
      MC?: number; // Déduction pour entreprise nouvelle; art 44 seixes
      MD?: number; // Déduction pour zone franche urbaine; art 44 octies A
      MF?: number; // Déduction pour jeune entreprise innovante; art 44 sexies A
      MH?: number; // Déduction pour zone franche d'activité NG; art 44 quaterdecies
      AR?: number; // Bassins urbains à dynamiser – BUD (art. 44 sexdecies)
      AY?: number; // Zone de développement prioritaire (44 septdecies)
      AS?: number; // Déduction exceptionnelle (art 39 decies A)
      AZ?: number; // Déduction exceptionnelle (art 39 decies B)
      CX?: number; // Déduction exceptionnelle (art 39 decies C)
      CY?: number; // Déduction exceptionnelle (art 39 decies D)
      CZ?: number; // Déduction exceptionnelle simulateur de conduite (art. 39 decies E)
      AT?: number; // Déductions exceptionnelles (art. 39 decies F)
      AX?: number; // Déduction exceptionnelle (art 39 decies G)
    };

    export type FORM_2033C = {
      RQ?: boolean; // Déposé néant?

      AA?: number; // Immobilisations incorporelles, fonds commercial, valeur brute au début de l'exercice
      AB?: number; // Autres immobilisations incorporelles, valeur brute au début de l'exercice
      AC?: number; // Immobilisations corporelles, terrains, valeur brute au début de l'exercice
      AD?: number; // Immobilisations corporelles, constructions, valeur brute au début de l'exercice
      AE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, valeur brute au début de l'exercice
      AF?: number; // Immobilisations corporelles, installations générales et aménagements divers, valeur brute au début de l'exercice
      AG?: number; // Immobilisations corporelles, matériel de transport, valeur brute au début de l'exercice
      AH?: number; // Autres immobilisations corporelles valeur brute au début de l'exercice
      AJ?: number; // Immobilisations financières, valeur brute au début de l'exercice
      AK?: number; // Total des immobilisations, valeur brute au début de l'exercice
      BA?: number; // Immobilisations incorporelles, fonds commercial, augmentations
      BB?: number; // Autres immobilisations incorporelles, augmentations
      BC?: number; // Immobilisations corporelles, terrains, augmentations
      BD?: number; // Immobilisations corporelles, constructions, augmentations
      BE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, augmentations
      BF?: number; // Immobilisations corporelles, installations générales et aménagements divers, augmentations
      BG?: number; // Immobilisations corporelles, matériel de transport, augmentations
      BH?: number; // Autres immobilisations corporelles augmentations
      BJ?: number; // Immobilisations financières, augmentations
      BK?: number; // Total des immobilisations, augmentations
      CA?: number; // Immobilisations incorporelles, fonds commercial, diminutions
      CB?: number; // Autres immobilisations incorporelles, diminutions
      CC?: number; // Immobilisations corporelles, terrains, diminutions
      CD?: number; // Immobilisations corporelles, constructions, diminutions
      CE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, diminutions
      CF?: number; // Immobilisations corporelles, installations générales et aménagements divers, diminutions
      CG?: number; // Immobilisations corporelles, matériel de transport, diminutions
      CH?: number; // Autres immobilisations corporelles, diminutions
      CJ?: number; // Immobilisations financières, diminutions
      CK?: number; // Total des immobilisations, diminutions
      DA?: number; // Immobilisations incorporelles, fonds commercial, valeur brute à la fin de l'exercice
      DB?: number; // Autres immobilisations incorporelles, valeur brute à la fin de l'exercice
      DC?: number; // Immobilisations corporelles, terrains, valeur brute à la fin de l'exercice
      DD?: number; // Immobilisations corporelles, constructions, valeur brute en fin d'exercice
      DE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, valeur brute à la fin de l'exercice
      DF?: number; // Immobilisations corporelles, installations générales et aménagements divers, valeur brute à la fin de l'exercice
      DG?: number; // Immobilisations corporelles, matériel de transport, valeur brute à la fin de l'exercice
      DH?: number; // Autres immobilisations corporelles, valeur brute à la fin de l'exercice
      DJ?: number; // Immobilisations financières, valeur brute à la fin de l'exercice
      DK?: number; // Total des immobilisations, valeur brute à la fin de l'exercice
      EA?: number; // Immobilisations incorporelles, fonds commercial, réévaluation légale, valeur d'origine en fin exercice
      EB?: number; // Autres immobilisations incorporelles, réévaluation légale, valeur d'origine en fin exercice
      EC?: number; // Immobilisations corporelles, terrains, réévaluation légale, valeur d'origine en fin exercice
      ED?: number; // Immobilisations corporelles, constructions, réévaluation légale, valeur d'origine en fin exercice
      EE?: number; // Immobilisations corporelles, installations techniques matériel et outillages industriels, réévaluation légale, valeur d'origine en fin exercice
      EF?: number; // Immobilisations corporelles, installations générales et aménagements divers, réévaluation légale, valeur d'origine en fin exercice
      EG?: number; // Immobilisations corporelles, matériel de transport, réévaluation légale, valeur d'origine en fin exercice
      EH?: number; // Autres immobilisations corporelles, réévaluation légale, valeur d'origine en fin exercice
      EJ?: number; // Immobilisations financières, réévaluation légale, valeur d'origine en fin exercice
      EK?: number; // Total des immobilisations, réévaluation légale, valeur d'origine en fin exercice
      FA?: number; // Autres immobilisations incorporelles, Montant des amortissements au début de l'exercice
      FB?: number; // Amortissements sur immobilisations corporelles, terrains, Montant des amortissements au début de l'exercice
      FC?: number; // Amortissements sur immobilisations corporelles, constructions, Montant des amortissements au début de l'exercice
      FD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, Montant des amortissements au début de l'exercice
      FE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, valeur brute au début de l'exercice
      FF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, Montant des amortissements au début de l'exercice
      FG?: number; // Amortissements sur autres immobilisations corporelles, Montant des amortissements au début de l'exercice
      FH?: number; // Total des amortissements sur immobilisations, Montant des amortissements au début de l'exercice
      GA?: number; // Autres immobilisations incorporelles, augmentations : dotations de l'exercice
      GB?: number; // Amortissements sur immobilisations corporelles, terrains, augmentations : dotations de l'exercice
      GC?: number; // Amortissements sur immobilisations corporelles, constructions, augmentations : dotations de l'exercice
      GD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, augmentations : dotations de l'exercice
      GE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, augmentations : dotations de l'exercice
      GF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, augmentations : dotations de l'exercice
      GG?: number; // Amortissements sur autres immobilisations corporelles, augmentations : dotations de l'exercice
      GH?: number; // Total des amortissements sur immobilisations, augmentations : dotations de l'exercice
      HA?: number; // Autres immobilisations incorporelles, diminutions amortissements afférents aux éléments sortis de l'actif et reprises
      HB?: number; // Amortissements sur immobilisations corporelles, terrains, diminutions pour éléments sortis de l'actif et reprises
      HC?: number; // Amortissements sur immobilisations corporelles, constructions, diminutions pour éléments sortis de l'actif et reprises
      HD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, diminutions pour éléments sortis de l'actif et reprises
      HE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, diminutions pour éléments sortis de l'actif et reprises
      HF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, diminutions pour éléments sortis de l'actif et reprises
      HG?: number; // Amortissements sur autres immobilisations corporelles, diminutions pour éléments sortis de l'actif et reprises
      HH?: number; // Total des amortissements sur immobilisations, diminutions pour éléments sortis de l'actif et reprises
      JA?: number; // Autres immobilisations incorporelles, montant des amortissements à la fin de l'exercice
      JB?: number; // Amortissements sur immobilisations corporelles, terrains, montant à la fin de l'exercice
      JC?: number; // Amortissements sur immobilisations corporelles, constructions, montant à la fin de l'exercice
      JD?: number; // Amortissements sur immobilisations corporelles, installations techniques, matériel et outillage industriels, montant à la fin de l'exercice
      JE?: number; // Amortissements sur immobilisations corporelles, installations générales, agencements et aménagements divers, montant à la fin de l'exercice
      JF?: number; // Amortissements sur immobilisations corporelles, matériel de transport, montant à la fin de l'exercice
      JG?: number; // Amortissements sur autres immobilisations corporelles, montant à la fin de l'exercice
      JH?: number; // Total des amortissements sur immobilisations, montant à la fin de l'exercice
      KA?: string[]; // ! Repetitions, Nature de l'immobilisation
      LA?: number[]; // ! Repetitions, Valeur d'actif
      LL?: number; // Total des valeurs d'actif
      MA?: number[]; // ! Repetitions, Amortissements
      ML?: number; // Total des amortissements
      NA?: number[]; // ! Repetitions, Valeur résiduelle
      NL?: number; // Total des valeurs résiduelles
      PA?: number[]; // ! Repetitions, Prix de cession
      PL?: number; // Total des prix de cession
      QA?: number[]; // ! Repetitions, Plus ou moins value à court terme
      QL?: number; // Total des plus ou moins values à court terme
      QM?: number; // Régularisations sur les plus ou moins values à court terme
      QN?: number; // Total des plus ou moins values nettes à court terme
      QP?: number; // Plus values taxables a 16,5%
      SA?: number[]; // ! Repetitions, Détail plus ou moins value à LT à 15 ou 12,8 %
      SL?: number; // Total des plus ou moins values à LT à 15 ou 12,8 %
      SM?: number; // Régularisation sur plus ou moins value à LT à 15 ou 12,8 %
      SN?: number; // Total des PMVLT à 15 ou 1,28 %
      TA?: number[]; // ! Repetitions, Détail plus ou moins value à LT à 0%
      TL?: number; // Total des plus ou moins values à LT à 0%
      TM?: number; // Régularisation sur plus ou moins value à LT à 0 %
      TN?: number; // Total des PMVLT à 0 %
      UA?: number; // Plus ou moins value a long terme a 16,5%
      UL?: number; // Total des plus ou moins values a long terme a 16,5%
      UM?: number; // Regularisations sur les plus ou moins values a long terme a 16,5%
      UN?: number; // Total des plus ou moins values nettes a long terme a 16,5%
      AL?: number; // Fonds commercial - Montant des amortissements au début de l'exercice
      AM?: number; // Fonds commercial - Augmentations : dotations de l'exercice
      AN?: number; // Fonds commercial - Diminutions : amortissements afférents aux éléments sortis de l'actif et reprises
      AP?: number; // Fonds commercial - Montant des amortissements à la fin de l'exercice
    };

    export type FORM_2033D = {
      PF?: boolean; // Déposé néant?

      AA?: number; // Provisions réglementées, amortissements dérogatoires, montant au début de l'exercice
      AB?: number; // Autres provisions réglementées, montant au début de l'exercice
      AC?: number; // Provisions pour risques et charges, montant au début de l'exercice
      AD?: number; // Provisions pour dépréciation sur immobilisations, montant au début de l'exercice
      AE?: number; // Provisions pour dépréciation sur stocks et en cours, montant au début de l'exercice
      AF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, montant au début de l'exercice
      AG?: number; // Autres provisions pour dépréciation, montant au début de l'exercice
      AH?: number; // Total des provisions, montant au début de l'exercice
      AJ?: number; // Aides perçues ayant donné droit à la réduction d'impôt prévue au 4 de l'article 238 bis du CGI pour l'entreprise donatrice
      AK?: number; // Montant de l'investissement reçu qui a donné lieu à amortissement exceptionnel chez l'entreprise investisseur dans le cadre de l'article 217 octies du CGI
      AN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, montant au début de l'exercice
      AP?: number; // Primes et cotisations complémentaires facultatives
      AQ?: number; // Cotisations personnelles obligatoire de l'exploitant
      AR?: string; // N° du centre de gestion agréé
      AS?: number; // Montant de la TVA collectée
      AT?: number; // Montant de la TVA déductible sur biens et services (sauf immobilisations)
      AU?: number; // Montant des prélèvements personnels de l'exploitant
      BA?: number; // Provisions réglementées, amortissements dérogatoires, augmentations, dotations de l'exercice
      BB?: number; // Autres provisions réglementées, augmentations, dotations de l'exercice
      BC?: number; // Provisions pour risques et charges, augmentations, dotations de l'exercice
      BD?: number; // Provisions pour dépréciation sur immobilisations, augmentations, dotations de l'exercice
      BE?: number; // Provisions pour dépréciation sur stocks et en cours, augmentations, dotations de l'exercice
      BF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, augmentations, dotations de l'exercice
      BG?: number; // Autres provisions pour dépréciation, augmentations, dotations de l'exercice
      BH?: number; // Total des provisions, augmentations, dotations de l'exercice
      BN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, augmentations
      CA?: number; // Provisions réglementées, amortissements dérogatoires, diminutions, reprises de l'exercice
      CB?: number; // Autres provisions réglementées, diminutions, reprises de l'exercice
      CC?: number; // Provisions pour risques et charges, diminutions, reprises de l'exercice
      CD?: number; // Provisions pour dépréciation sur immobilisations, diminutions, reprises de l'exercice
      CE?: number; // Provisions pour dépréciation sur stocks et en cours, diminutions, reprises de l'exercice
      CF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, diminutions, reprises de l'exercice
      CG?: number; // Autres provisions pour dépréciation, diminutions, reprises de l'exercice
      CH?: number; // Total des provisions, diminutions, reprises de l'exercice
      CN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, diminutions
      DA?: number; // Provisions réglementées, amortissements dérogatoires, montant à la fin de l'exercice
      DB?: number; // Autres provisions réglementées, diminutions, montant à la fin de l'exercice
      DC?: number; // Provisions pour risques et charges, montant à la fin de l'exercice
      DD?: number; // Provisions pour dépréciation sur immobilisations, montant à la fin de l'exercice
      DE?: number; // Provisions pour dépréciation sur stocks et en cours, montant à la fin de l'exercice
      DF?: number; // Provisions pour dépréciation sur clients et comptes rattachés, montant à la fin de l'exercice
      DG?: number; // Autres provisions pour dépréciation, montant à la fin de l'exercice
      DH?: number; // Total des provisions, montant à la fin de l'exercice
      DN?: number; // Provisions réglementées, amortissements dérogatoires, majoration exceptionnelle de 30 %, montant à la fin de l'exercice
      GJ?: string[]; // ! Repetitions, Détail de la ventilation des dotations aux provisions, libellé
      HJ?: number[]; // ! Repetitions, Détail de la ventilation des dotations aux provisions, montant
      HA?: number; // Dotations aux provisions pour indemnités pour congés à payer, charges sociales et fiscales correspondantes
      HH?: number; // Total des ventilations des dotations aux provisions et charges à payer non déductibles pour l'assiette de l'impôt
      MG?: number; // Déficits de l'exercice
      MH?: number; // Total des déficits restant à reporter
      PG?: number; // Déficit restant à reporter au titre de l'exercice précédent
      PH?: number; // Déficits imputés
      PJ?: number; // Déficits reportables
      AW?: number; // Dont montant déductible des cotisations facultatives versées en application du I de l'article 154 bis du CGI dont cotisations facultatives Madelin
      AX?: number; // Dont cotisations facultatives aux nouveaux plans d’épargne retraite
      AV?: number; // Dont montant déductible des cotisations sociales obligatoires hors CSG-CRDS
      AL?: number; // Déficits transférés de plein droit (article 209-II-2 du CGI)
      AM?: number; // Nombre d’opérations sur l’exercice
      AY?: number; // Provision pour amortissements dérogatoires - Fonds commercial – Dotations
      AZ?: number; // Provision pour amortissements dérogatoires – Fonds commercial – Reprises
    };

    export type FORM_2033E = {
      DB?: boolean; // Déposé néant?
    };

    export type FORM_2033F = {
      GS?: boolean; // Déposé néant?

      repetition2033FPhysiques?: {
        BA_3036_1?: string; // Nom patronymique, prénoms de l'associé personne physique
        BA_3036_2?: string; // Nom marital de l'associé personne physique
        BA_3036_3?: string; // Titre de l'associé personne physique
        BA_3042_1?: string; // Adresse de l'associé personne physique, numéro, type et nom voie
        BA_3042_2?: string; // Adresse de l'associé personne physique, complément de distribution
        BA_3042_3?: string; // Adresse de l'associé personne physique, lieu-dit, hameau
        BA_3164_1?: string; // Adresse de l'associé personne physique, ville
        BA_3207_1?: string; // Adresse de l'associé personne physique, pays // Code ISO (FR)
        BA_3251_1?: string; // Adresse de l'associé personne physique, code postal
        BE?: string; // Date de naissance de l'associé personne physique
        BG_3164_1?: string; // Commune de naissance de l'associé personne physique
        BG_3207_1?: string; // Pays de naissance de l'associé personne physique // Code ISO (FR)
        BG_3251_1?: string; // Département de naissance de l'associé personne physique
        BD?: number; // Nombre de parts de l'associé personne physique
        BR?: number; // Pourcentage de détention de l'associé personne physique
      }[];
      repetition2033FMorales?: {
        GA_3036_1?: string; // Dénomination de l'associé personne morale
        GA_3036_2?: string; // Complément de désignation de l'associé personne morale
        GA_3036_3?: string; // Forme juridique de l'associé personne morale
        GA_3039_1?: string; // Numéro SIREN de l'associé personne morale
        GA_3042_1?: string; // Adresse de l'associé personne morale, numéro, type et nom voie
        GA_3042_2?: string; // Adresse de l'associé personne morale, complément de distribution
        GA_3042_3?: string; // Adresse de l'associé personne morale, lieu-dit, hameau
        GA_3164_1?: string; // Adresse de l'associé personne morale, ville
        GA_3207_1?: string; // Adresse de l'associé personne morale, pays // Code ISO (FR)
        GA_3251_1?: string; // Adresse de l'associé personne morale, code postal
        GD?: number; // Nombre de parts de l'associé personne morale
        GR?: number; // Pourcentage de détention de l'associé personne morale
      }[];
      GT?: number; // Nombre total de personnes morales associés ou actionnaires de l'entreprise
      GU?: number; // Nombre total de parts ou d'actions détenus par des associés ou actionnaires personnes morales de l'entreprise
      GV?: number; // Nombre total de personnes physiques associés ou actionnaires de l'entreprise
      GW?: number; // Nombre total de parts ou d'actions détenus par des associés ou actionnaires personnes physiques de l'entreprise
    };

    export type FORM_2033G = {
      GS?: boolean; // Déposé néant?
    };

    export type FORM_2069RCI = {
      AB?: boolean; // Déposé néant?
    };

    export type FORM_2031 = {
      AA_2031?: boolean; // Option pour la comptabilité super-simplifiée ?
      AB_2031_4440_1?: string; // Activité exercée, première ligne
      AB_2031_4440_2?: string; // Activité exercée, seconde ligne
      AD_2031?: boolean; // Soumission à la TVA?
      AE_2031?: number; // Quote-part de subvention d'équipement, d'indemnités d'assurance compensant la perte d'un élément de l'actif immobilisé et des plus-values à court terme définies à l'article 39 duodecies du CGI
      AF_2031?: number; // Charges ou dépenses ayant la nature de moins-values définies à l'article 39 duodecies
      BG_2031_3042_1?: string; // Adresse du domicile du déclarant, complément de distribution
      BG_2031_3042_2?: string; // Adresse du domicile du déclarant, numéro type nom voie
      BG_2031_3042_3?: string; // Adresse du domicile du déclarant, lieu-dit hameau
      BG_2031_3164_1?: string; // Adresse du domicile du déclarant, nom de la ville
      BG_2031_3207_1?: string; // Adresse du domicile de l'exploitant, pays (Code ISO)
      BG_2031_3251_1?: string; // Adresse du domicile du déclarant, code postal
      BM_2031_3042_1?: string; // Ancienne adresse du déclarant en cas de changement, numéro type nom voie
      BM_2031_3042_2?: string; // Ancienne adresse du déclarant en cas de changement, complément de distribution
      BM_2031_3042_3?: string; // Ancienne adresse du déclarant en cas de changement, lieu-dit, hameau
      BM_2031_3164_1?: string; // Ancienne adresse du déclarant en cas de changement, ville
      BM_2031_3207_1?: string; // Ancienne adresse du déclarant en cas de changement, pays
      BM_2031_3251_1?: string; // Ancienne adresse du déclarant en cas de changement, code postal
      BT_2031?: boolean; // Inscription à la chambre des métiers?
      CA_2031?: number; // Récapitulation des éléments d'imposition, résultat fiscal, bénéfice
      CB_2031?: number; // Récapitulation des éléments d'imposition, résultat fiscal, déficit
      CC_2031?: number; // Revenus de valeurs et capitaux mobiliers exonérés de l'impôt sur le revenu
      CD_2031?: number; // Quote part de frais et charges correspondants à déduire des revenus de valeurs et capitaux mobiliers
      CE_2031?: number; // Revenus de valeurs et capitaux mobiliers nets exonérés
      CF_2031?: number; // Revenus de valeurs et capitaux mobiliers soumis à l'impôt sur le revenu
      CH_2031?: number; // Total des revenus de valeurs et capitaux mobiliers à reporter
      CJ_2031?: number; // Total éléments d'imposition 3, bénéfice
      CK_2031?: number; // Total éléments d'imposition 3, déficit
      CL_2031?: number; // Bénéfice imposable
      CM_2031?: number; // Déficit déductible
      CN_2031?: number; // Plus values à long terme imposables à 12,8 %
      CP_2031?: number; // Plus values imposables selon les règles appliquées aux particuliers
      CQ_2031?: number; // Exonération ou abattement pratiqué sur les plus-values à long terme à 12,8 %
      CR_2031?: number; // Exonération ou abattement pratiqué sur le bénéfice professionnel
      CV_2031?: number; // Plus values à long terme imposables selon l'article 39 quindecies I.1
      CW_2031?: number; // Bic non professionnels, bénéfice
      CX_2031?: number; // Bic non professionnels, déficit
      DA_2031?: string; // Comptable salarié ou indépendant?
      DC_2031_3036_1?: string; // Nom et adresse du comptable ou du viseur conventionné: nom et prénom ou désignation
      DC_2031_3036_2?: string; // Nom et adresse du comptable ou du viseur conventionné: désignation personne, qualité et profession
      DC_2031_3036_3?: string; // Nom et adresse du comptable ou du viseur conventionné: forme juridique ou titre
      DC_2031_3042_1?: string; // Nom et adresse du comptable ou du viseur conventionné: numéro, type, nom voie
      DC_2031_3042_2?: string; // Nom et adresse du comptable ou du viseur conventionné: Complément de distribution
      DC_2031_3042_3?: string; // Nom et adresse du comptable ou du viseur conventionné: Lieu-dit hameau
      DC_2031_3148_1_TE?: string; // Nom et adresse du comptable ou du viseur conventionné: numéro de téléphone
      DC_2031_3164_1?: string; // Nom et adresse du comptable ou du viseur conventionné: ville
      DC_2031_3207_1?: string; // Nom et adresse du comptable ou du viseur conventionné: pays
      DC_2031_3251_1?: string; // Nom et adresse du comptable ou du viseur conventionné: code postal
      EA_2031_3036_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, désignation
      EA_2031_3036_2?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, complément de désignation
      EA_2031_3036_3?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, forme juridique
      EA_2031_3039_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, numéro d'agrément
      EA_2031_3042_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, numéro, type, nom voie
      EA_2031_3042_2?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, complément de distribution
      EA_2031_3042_3?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, lieu-dit, hameau
      EA_2031_3164_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, ville
      EA_2031_3207_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, pays
      EA_2031_3251_1?: string; // Nom et adresse du CGA, de l'OMGA, du viseur ou du certificateur conventionné, code postal
      FA_2031?: string; // Conseil salarié ou indépendant?
      FC_2031_3036_1?: string; // Nom et adresse du conseil, nom et prénom ou dénomination
      FC_2031_3036_2?: string; // Nom et adresse du conseil, qualité et profession ou complément de dénomination
      FC_2031_3036_3?: string; // Nom et adresse du conseil, forme juridique ou titre
      FC_2031_3042_1?: string; // Nom et adresse du conseil, numéro, type, nom voie
      FC_2031_3042_2?: string; // Nom et adresse du conseil, Complément de distribution
      FC_2031_3042_3?: string; // Nom et adresse du conseil, lieu-dit, hameau
      FC_2031_3148_1_TE?: string; // Nom et adresse du conseil, numéro de téléphone
      FC_2031_3164_1?: string; // Nom et adresse du conseil, ville
      FC_2031_3207_1?: string; // Nom et adresse du conseil, pays
      FC_2031_3251_1?: string; // Nom et adresse du conseil, code postal
      JA_2031?: boolean; // Entreprise nouvelle selon l'article 44 sexies
      JB_2031?: boolean; // Zone franche urbaine article 44 octies ou 44 octies A
      JK_2031?: number; // Résultat fiscal du mode de calcul de l'impôt sur les sociétés
      JL_2031?: boolean; // Enterprise nouvelle, autres dispositifs?
      KG_2031?: number; // Plus-values à court terme et à long terme exonérées
      KK_2031?: boolean; // Jeunes entreprises innovantes art 44 sexies OA?
      KM_2031?: number; // Plus values à long terme exonérées selon l'art 151 septies A
      KQ_2031?: boolean; // Zone franche d'activités art.44 quaterdecies
      KR_2031?: boolean; // Zone de restructuration de défense art.44 terdecies ?
      KV_2031?: boolean; // Visa : CGA/OMGA
      KW_2031?: boolean; // Visa : viseur ou certificateur conventionné
      KX_2031?: boolean; // ZRR art. 44 quindecies
      KY_2031?: boolean; // Si option pour le régime optionnel de taxation au tonnage (entreprises de transport maritime)
      KZ_2031?: boolean; // Option pour le crédit d'impôt outre-mer dans le secteur productif, art. 244 quater W
      AG_2031?: boolean; // Bassins urbains à dynamiser (BUD) art. 44 sexdecies
      AH_2031?: boolean; // L'entreprise dispose-t-elle d'une comptabilité informatisée ?
      AJ_2031?: string; // Si oui, indication du logiciel utilisé
      AQ_2031?: number; // 4bis Résultat net de cession, de concession ou de sous-concession des brevets et droits de propriété industrielle assimilés au taux de 10 %
      AP_2031?: number; // Dont plus-value à court terme exonérée (Art. 151 septies, 151 septies A et 238 quindecies)
      AL_2031?: number; // PV à court terme, subventions d'équipement et indemnités d'assurance pour perte d'un élément d'actif (Art. 39 duodecies)
      AM_2031?: number; // Moins-values à court terme (art 39 duodécies)
      AU_2031?: number; // Exonération ou abattement pratiqué sur le bénéfice non professionnel
      AR_2031?: boolean; // Zone de développement prioritaire art. 44 septdecies
      AV_2031?: number; // PV nettes à long terme imposable à 12,8 %
      AT_2031?: boolean; // ECF
      AW_2031_3036_1?: string; // Dénomination du prestataire
      AW_2031_3036_2?: string; // Complément de dénomination du prestataire
      AW_2031_3036_3?: string; // Complément 2 de dénomination du prestataire
      AW_2031_3039_1?: string; // Siren du prestataire
      AW_2031_3042_1?: string; // Numéro, type et nom voie du prestataire
      AW_2031_3042_2?: string; // Complément de distribution du prestataire
      AW_2031_3042_3?: string; // Lieu-dit, hameau du prestataire
      AW_2031_3164_1?: string; // Ville du prestataire
      AW_2031_3207_1?: string; // Pays du prestataire
      AW_2031_3251_1?: string; // Code postal du prestataire
      AC_2031?: number; // Assiette de la contribution temporaire de solidarité au taux de 33 %
      AX_2031?: boolean; // Reprise d’entreprise en difficulté art.44 septies
      SC_2031BIS?: number; // Résultat avant imputation des déficits antérieurs, bénéfice
      TC_2031BIS?: number; // Résultat avant imputation des déficits antérieurs, déficit
    };

    export type FORM_SUIV39C = {
      AH?: number; // Amortissements non déduits au titre des exercices antérieurs - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      AJ?: number; // Amortissements non déduits au titre des exercices antérieurs – Total
      AM?: number; // Amortissements non déduits au titre de l'exercice  - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      AN?: number; // Amortissements non déduits au titre de l'exercice – Total
      BH?: number; // Amortissements restant à déduire sur les exercices ultérieurs - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      BJ?: number; // Amortissements restant à déduire sur les exercices ultérieurs – Total
      BD?: number; // Imputation sur la quote-part de résultat de l'exercice des amortissements non déduits antérieurement - Parts de la structure bailleresse détenues directement ou indirectement par des personnes physiques ou exploitants individuels
      BE?: number; // Imputation sur la quote-part de résultat de l'exercice des amortissements non déduits antérieurement – Total
    };
  }
}
