import { downloadFile } from "@/utils";
import {
  RealEstateAssetsService,
  RealEstateAmortisationsService,
  RealEstateLoansService,
  DocumentFileType,
} from "@edmp/api";
import axios from "axios";

const api = "/api/v1/real-estates";

class RealEstatesService {
  amortisations = {
    async create(
      realEstateAmortisationCreate: RealEstateAmortisationsService.CreateIn
    ) {
      return axios
        .post<RealEstateAmortisationsService.CreateOut>(
          `${api}/amortisations`,
          realEstateAmortisationCreate
        )
        .then((res) => res.data);
    },

    async list(params: RealEstateAmortisationsService.ListIn) {
      return axios
        .get<RealEstateAmortisationsService.ListOut>(`${api}/amortisations`, {
          params,
        })
        .then((res) => res.data);
    },

    async get(params: RealEstateAmortisationsService.GetIn) {
      return axios
        .get<RealEstateAmortisationsService.GetOut>(
          `${api}/amortisations/${params.id}`,
          { params }
        )
        .then((res) => res.data);
    },

    async update(
      realEstateAmortisationUpdate: RealEstateAmortisationsService.UpdateIn
    ) {
      return axios
        .put<RealEstateAmortisationsService.UpdateOut>(
          `${api}/amortisations/${realEstateAmortisationUpdate.id}`,
          realEstateAmortisationUpdate
        )
        .then((res) => res.data);
    },

    async remove({ id }: RealEstateAmortisationsService.DeleteIn) {
      return axios
        .delete<RealEstateAmortisationsService.DeleteOut>(
          `${api}/amortisations/${id}`
        )
        .then((res) => res.data);
    },
  };

  assets = {
    async create(realEstateAssetCreate: RealEstateAssetsService.CreateIn) {
      return axios
        .post<RealEstateAssetsService.CreateOut>(
          `${api}/assets`,
          realEstateAssetCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RealEstateAssetsService.ListIn) {
      return axios
        .get<RealEstateAssetsService.ListOut>(`${api}/assets`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RealEstateAssetsService.GetIn) {
      return axios
        .get<RealEstateAssetsService.GetOut>(`${api}/assets/${params.id}`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async update(realEstateAssetUpdate: RealEstateAssetsService.UpdateIn) {
      return axios
        .put<RealEstateAssetsService.UpdateOut>(
          `${api}/assets/${realEstateAssetUpdate.id}`,
          realEstateAssetUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(realEstateAssetDelete: RealEstateAssetsService.DeleteIn) {
      return axios
        .delete<RealEstateAssetsService.DeleteOut>(
          `${api}/assets/${realEstateAssetDelete.id}`
        )
        .then((res) => {
          return res.data;
        });
    },

    async addDocuments(
      params: Omit<RealEstateAssetsService.AddDocumentsIn, "files"> & {
        files: Object;
      }
    ) {
      const blobInfos = new Blob(
        [
          JSON.stringify({
            id: params.id,
            productId: params.productId,
            asbestos: params.asbestos,
            dpe: params.dpe,
          }),
        ],
        {
          type: "application/json",
        }
      );

      // let files;

      const formData = new FormData();
      formData.append("infos", blobInfos);

      for (const [key, file] of Object.entries(params.files)) {
        formData.append(key, file.value);
      }

      return axios
        .post<RealEstateAssetsService.AddDocumentsOut>(
          `${api}/assets/${params.id}/addDocuments`,
          formData
        )
        .then((res) => {
          return res.data;
        });
    },
    async deleteDocument(params: RealEstateAssetsService.DeleteDocumentIn) {
      return axios
        .put<RealEstateAssetsService.DeleteDocumentOut>(
          `${api}/assets/${params.id}/deleteDocument`,
          params
        )
        .then((res) => {
          return res.data;
        });
    },
  };

  loans = {
    async create(realEstateLoanCreate: RealEstateLoansService.CreateIn) {
      return axios
        .post<RealEstateLoansService.CreateOut>(
          `${api}/loans`,
          realEstateLoanCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RealEstateLoansService.ListIn) {
      return axios
        .get<RealEstateLoansService.ListOut>(`${api}/loans`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RealEstateLoansService.GetIn) {
      return axios
        .get<RealEstateLoansService.GetOut>(`${api}/loans/${params.id}`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async update(realEstateLoanUpdate: RealEstateLoansService.UpdateIn) {
      return axios
        .put<RealEstateLoansService.UpdateOut>(
          `${api}/loans/${realEstateLoanUpdate.id}`,
          realEstateLoanUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async getAmortisationSchedule(params: RealEstateLoansService.GetPdfIn) {
      return axios
        .get<RealEstateLoansService.GetPdfOut>(
          `${api}/loans/${params.id}/amortisation-schedule`,
          {
            params,
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: "application/pdf",
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "").split(
            "filename="
          )[1];

          downloadFile(res.data, fileName, DocumentFileType.PDF);
        });
    },
  };
}

// Export a singleton instance in the global namespace
export const realEstatesService = new RealEstatesService();
