

























































import {
  defineComponent,
  ComputedRef,
  computed,
  onBeforeMount,
  PropType,
} from "@vue/composition-api";
import { Suggestion } from "@edmp/api";

import {
  accountingPeriodsStore,
  productsStore,
  transactionsStore,
} from "@/store";

import {
  TransactionState,
  useTransaction,
} from "../transaction/transaction.usable";
import { useCategorization } from "./categorization.usable";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";

export default defineComponent({
  name: "Categories",
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
  },
  setup(props, context) {
    const transactionUse = computed(() =>
      useTransaction(props.transactionState, context)
    );
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    const suggestions = computed(
      () => transactionsStore.getCategoriesListWithoutDisabled
    );
    const summary: ComputedRef = computed(() => {
      return transactionUse.value.getSummary();
    });

    const getSuggestions = async () => {
      if (productsStore.currentId && accountingPeriodsStore.currentId) {
        await categorizationUse.value.updateCategoriesList();
      }
    };
    const clickSuggestion = (category: Suggestion) => {
      categorizationUse.value.selectCategory(category);
    };
    const closeCategorization = () => {
      categorizationUse.value.resetCategories();
      categorizationUse.value.isOpenCategorizationList.value = false;
    };

    onBeforeMount(getSuggestions);

    return {
      summary,
      suggestions,
      clickSuggestion,
      closeCategorization,
      openArticleCategorizeAvailable: () =>
        useCrisp().openArticle(ArticleEnum.CATEGORIZE_AVAILABLE),
    };
  },
});
