var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.taxDeclarationsItems.length)?_c('div',{attrs:{"id":"teletransmitFollowUp"}},[_c('div',{staticClass:"section"},[_vm._m(0),_c('div',{staticClass:"section-body"},[_c('EditableTable',{ref:"editableTable",style:({ width: '-webkit-fill-available' }),attrs:{"headers":_vm.taxDeclarationsHeaders,"items":_vm.taxDeclarationsItems,"viewType":"readOnly","itemsPerPage":-1,"hideFooter":"","hideAddButton":"","isEnableEditItem":false,"isEnableDeleteItem":false,"isEnableValidateItem":false},scopedSlots:_vm._u([_vm._l((_vm.taxDeclarationsHeaders),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var value = ref.value;
var rowIndex = ref.rowIndex;
return [_c('div',{key:headerValue,staticClass:"d-flex"},[(headerValue === 'createdAt')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'sendAt')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'validation')?_c('div',{class:{ 'py-2': value.status === _vm.TaxDeclarationStatus.Error }},[(value.icon)?_c('v-icon',{attrs:{"left":"","color":value.iconColor}},[_vm._v(_vm._s(value.icon))]):_vm._e(),_vm._v(_vm._s(value.text)+" "),(value.status === _vm.TaxDeclarationStatus.Error)?_c('div',{style:({ 'text-align': 'right' })},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary ml-2"},on:{"click":function($event){return _vm.showTaxDeclarationError(rowIndex)}}},[_vm._v(" Voir la raison"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye")])],1)],1):_vm._e()],1):_vm._e(),(headerValue === 'document')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"document-type pa-3",attrs:{"depressed":"","color":"#757575","href":value.href,"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(value.type))+" ")])]}}],null,true)},[_c('span',[_vm._v("Voir le document")])])],1):_vm._e()])]}}})],null,true)})],1)]),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":_vm.$vuetify.breakpoint.lgAndUp ? '40%' : '70%'},model:{value:(_vm.taxDeclarationError.isOpen),callback:function ($$v) {_vm.$set(_vm.taxDeclarationError, "isOpen", $$v)},expression:"taxDeclarationError.isOpen"}},[_c('TeletransmitFollowUpError',{attrs:{"taxDeclaration":_vm.taxDeclarationError.taxDeclaration},on:{"close":function($event){_vm.taxDeclarationError.isOpen = false}}})],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-header"},[_c('h3',{staticClass:"section-header-title mb-2"},[_vm._v("Ma Télétransmission")])])}]

export { render, staticRenderFns }