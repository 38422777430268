import { ActivitiesService } from "@edmp/api";
import axios from "axios";

const api = "/api/v1/activities";

class ActivitiesService {
  async list(params: ActivitiesService.ListIn) {
    return axios
      .get<ActivitiesService.ListOut>(`${api}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async get(params: ActivitiesService.GetIn) {
    return axios
      .get<ActivitiesService.GetOut>(`${api}/${params.id}`, { params })
      .then((res) => {
        return res.data;
      });
  }

  async update(activityUpdate: ActivitiesService.UpdateIn) {
    return axios
      .put<ActivitiesService.UpdateOut>(
        `${api}/${activityUpdate.id}`,
        activityUpdate
      )
      .then((res) => {
        return res.data;
      });
  }
}

// Export a singleton instance in the global namespace
export const activitiesService = new ActivitiesService();
