







































import { ROUTE_NAMES } from "@/router/routes";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "EventsYearEndModal",
  setup() {
    return {
      ROUTE_NAMES,
      // yearEnd: computed(() => {
      //   return accountingPeriodsStore.currentAccountingPeriod?.endAt
      //     ? new Date(
      //         accountingPeriodsStore.currentAccountingPeriod.endAt
      //       ).getFullYear()
      //     : "";
      // }),
    };
  },
});
