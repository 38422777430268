
















































































































































































import { useEmail } from "@/composables";
import { VForm } from "@/models";
import { productsStore, coreStore, taskActivityStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ProductsModel } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  name: "ProductMail",
  props: {
    productId: { type: String },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    const isLoading: Ref<boolean> = ref(false);
    const product: Ref<ProductsModel.Product | undefined> = ref();

    // Gestion de l'edition / annulation
    const isEditing: Ref<boolean> = ref(false);

    const headerTitleText = computed(() => {
      return product.value?.type === ProductsModel.ProductTypes.LMNP
        ? "la LMNP"
        : "la SCI";
    });

    async function validate(e: Event): Promise<void> {
      e.preventDefault();

      if ((context.refs.form as VForm).validate() && product.value) {
        try {
          if (product.value.email === product.value.newEmail) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.INFO,
              timeout: 10000,
              message:
                "Veuillez modifier votre email avant de valider ou cliquez sur annuler.",
            });
            return;
          }

          await productsStore
            .updateProduct({
              id: product.value.id,
              newEmail: product.value?.newEmail,
            })
            .then(() => {
              context.emit("validate");
              isEditing.value = false;
            });
          coreStore.displayFeedback({
            timeout: 15000,
            type: FeedbackTypeEnum.INFO,
            message:
              "Une vérification vient d'être envoyée sur la nouvelle adresse email saisie : validez-la pour activer le nouvel identifiant.",
          });
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre adresse email.\n" +
              "L'adresse email est peut-être déjà associée à un compte. ",
          });
        }
        await taskActivityStore.fetchTaskActivityGroups({});
      }
    }

    const { validEmailRule, getEmailRule } = useEmail();

    function cancelUpdate() {
      if (
        // bizarrement tester mais seul solution pour éffectuer cette opération
        product.value &&
        !(
          (product.value?.emailStatus as string) === "invalid" ||
          (product.value?.emailStatus as string) === "pending"
        )
      ) {
        product.value.newEmail = null;
      }
      isEditing.value = false;
    }

    // Call backend to reset email status to pending in order to resend a mail
    const resetEmailStatus = () => {
      // We retrieve product to check newEmail is already validated or not
      productsStore
        .fetchProducts({})
        .then(() => {
          if (product.value?.newEmail) {
            productsStore
              .updateProduct({
                id: product.value?.id,
                newEmail: product.value?.newEmail,
              })
              .then(() =>
                coreStore.displayFeedback({
                  type: FeedbackTypeEnum.INFO,
                  timeout: 13000,
                  message:
                    "Une vérification vient d'être envoyée sur la nouvelle adresse email saisie : validez-la pour activer le nouvel identifiant.",
                })
              );
          }
        })
        .then(() => taskActivityStore.fetchTaskActivityGroups({}));
    };

    const cancelEmailStatus = async () => {
      // Revient à l'état initial en supprimant le nouvel email et en restituant l'état
      // DOit on accepter de passer à confirm ...
      if (product.value) {
        productsStore
          .updateProduct({
            id: product.value.id,
            newEmail: null,
          })
          .then(async () => {
            await taskActivityStore.fetchTaskActivityGroups({});
          });
      }
    };

    // Init
    async function init() {
      isLoading.value = true;
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      product.value = {
        ...productsStore.currentProduct,
      } as ProductsModel.Product;
      if (product.value?.emailStatus !== "confirmed") {
        isEditing.value = !!props.actions;
      }

      isLoading.value = false;
    }

    watch(
      () => [productsStore.currentProduct, props.actions],
      () => init(),
      { deep: true }
    );
    onBeforeMount(() => init());

    return {
      product,
      isEditing,
      validEmailRule,
      getEmailRule,
      validate,
      cancelUpdate,
      resetEmailStatus,
      cancelEmailStatus,
      headerTitleText,
    };
  },
});
