




import { defineComponent } from "@vue/composition-api";
import GenericBadge from "./GenericBadge.vue";

export default defineComponent({
  name: "BadgeFeatureSoon",
  components: {
    GenericBadge,
  },
});
