









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "GenericBadge",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
  },
});
