























































import { accountingPeriodsStore } from "@/store";
import { TaxRegime } from "@edmp/api";
import { computed, defineComponent, ref } from "@vue/composition-api";
import SubscriptionsTable from "./SubscriptionsTable.vue";

export default defineComponent({
  name: "SubscriptionsFeatures",
  components: { SubscriptionsTable },
  props: {
    persistent: { type: Boolean, default: false },
  },

  setup() {
    const subscriptionFeaturesIsOpen = ref(false);
    const isIR = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IR_2072
    );

    return {
      subscriptionFeaturesIsOpen,
      isIR,
    };
  },
});
