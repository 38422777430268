
























import PageHeader from "@/components/atom/PageHeader.vue";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import { accountingCarryForwardsStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
} from "@vue/composition-api";
import AccountingCarryForwardTable from "./AccountingCarryForwardTable.vue";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "AccountingCarryForward",
  components: {
    PageHeader,
    AccountingPeriodChoice,
    EditableTable,
    AccountingCarryForwardTable,
  },
  props: {
    openAmortisationEdit: { type: String as PropType<"true"> },
    openDeficitEdit: { type: String as PropType<"true"> },
  },

  setup(props) {
    const accountingCarryForwards = computed(
      () => accountingCarryForwardsStore.accountingCarryForwards
    );

    onBeforeMount(async () => {
      await accountingCarryForwardsStore.fetchAccountingCarryForwards();
    });

    return {
      accountingCarryForwards,
      ROUTE_NAMES,
      isOpenAmortisationEdit: props.openAmortisationEdit === "true",
      isOpenDeficitEdit: props.openDeficitEdit === "true",
    };
  },
});
