




































import { defineComponent } from "@vue/composition-api";

import { ForbiddenError, subject } from "@casl/ability";
import { ability } from "@/services";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "ActivityCreateInfosModal",
  components: {},
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const goCreate = () => {
      ForbiddenError.from(ability).throwUnlessCan(
        "add",
        subject("Product", {})
      );
      context.emit("update:openModal", false);
      context.root.$router.push({
        name: ROUTE_NAMES.ActivityCreate,
      });
    };

    return { goCreate };
  },
});
