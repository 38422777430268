import { ROUTE_NAMES } from "@/router/routes";

export const PROFILE_NAVIGATION_ITEMS = [
  { name: "Mon profil", routeName: ROUTE_NAMES.User, id: "track-profile_page" },
  {
    name: "Mes abonnements",
    routeName: ROUTE_NAMES.Subscriptions,
    id: "track-subscription_page",
  },
];
