

































import { defineComponent, PropType } from "@vue/composition-api";

import CustomLabelSwitch from "@/components/atom/switch/CustomLabelSwitch.vue";

export default defineComponent({
  name: "ActivityChoice",
  components: { CustomLabelSwitch },
  props: {
    activityType: { type: String as PropType<"SCI" | "LMNP"> },
  },
  model: {
    prop: "activityType",
    event: "click",
  },

  setup() {
    //
  },
});
