





















































import SignTemplate from "@/components/sign/SignTemplate.vue";
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
  SetupContext,
} from "@vue/composition-api";
import { get } from "lodash";
import { productsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "ValidateEmail",
  components: {
    SignTemplate,
  },
  setup(props, context: SetupContext) {
    const error: Ref<boolean> = ref(false);

    const token: ComputedRef<string> = computed(
      () => get(context.root.$route.query, "token", "") as string
    );
    const validateEmail = async () => {
      try {
        await productsStore
          .validateEmail({ emailVerifyToken: token.value })
          .then(() => {
            error.value = false;
          });
        // uncomment to bypass this page in case of success
        // await context.root.$router.push({ name: "User" });
      } catch (err) {
        error.value = true;
      }
    };

    validateEmail();

    return { error, ROUTE_NAMES };
  },
});
