import { Document, Schema, model } from "mongoose";
import { ulid } from "ulid";
import { AccountingPeriod } from "./AccountingPeriod.model";
import { accountingResultLinesMetadata } from "./AccountingResult.model";
import { PartialField, RequireField, decimal2JSON } from "./Common.model";
import { Direction, LedgerAccountEnum } from "./JournalComposedEntry";
import { TaxRegime } from "./TaxRegime.enum";

/**
 * * AccountingCarryForwardLine
 */
export enum AccountingCarryForwardLineType {
  TOTAL_DEPRECIATION = "total-depreciation",
  TOTAL_DEFICIT = "total-deficit",
}

export type AccountingCarryForwardLine<Regime extends TaxRegime = TaxRegime> = {
  id?: string;
  amount: number;
  direction: Direction;
  transactionIds: string[];
  createdAt: string;
  updatedAt: string;
} & (Regime extends TaxRegime.LMNP_2031
  ? { type: defaultLineTypeLMNP }
  : Regime extends TaxRegime.IS_2065
  ? { type: defaultLineTypeIS }
  : never);

type defaultLineTypeLMNP =
  | AccountingCarryForwardLineType.TOTAL_DEPRECIATION
  | AccountingCarryForwardLineType.TOTAL_DEFICIT;
type defaultLineTypeIS = AccountingCarryForwardLineType.TOTAL_DEFICIT;

const defaultLineTypeLMNPValues: defaultLineTypeLMNP[] = [
  AccountingCarryForwardLineType.TOTAL_DEPRECIATION,
  AccountingCarryForwardLineType.TOTAL_DEFICIT,
];
const defaultLineTypeISValues: defaultLineTypeIS[] = [AccountingCarryForwardLineType.TOTAL_DEFICIT];

export const accountingCarryForwardDefaultLineTypes: {
  [key in TaxRegime]: AccountingCarryForwardLineType[];
} = {
  [TaxRegime.IR_2072]: [],
  [TaxRegime.IS_2065]: defaultLineTypeISValues,
  [TaxRegime.LMNP_2031]: defaultLineTypeLMNPValues,
};

export type AccountingCarryForwardLineCreate<Regime extends TaxRegime = TaxRegime> = Omit<
  AccountingCarryForwardLine<Regime>,
  "id" | "createdAt" | "updatedAt"
>;
export type AccountingCarryForwardLineUpdate = Partial<Omit<AccountingCarryForwardLine, "createdAt" | "updatedAt">>;

// Mongo
export const accountingCarryForwardLineSchema = new Schema<AccountingCarryForwardLine>(
  {
    _id: { type: String, default: () => ulid() },
    amount: { type: Schema.Types.Decimal128, required: true, index: true },
    direction: { type: String, required: true },
    transactionIds: { type: Array, required: true },
    type: { type: String, enum: Object.values(AccountingCarryForwardLineType), required: true },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingCarryForwardLineDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);
export type AccountingCarryForwardLineDocument = AccountingCarryForwardLine & Document<string>;

/**
 * * AccountingCarryForward
 */
export type AccountingCarryForward<Type extends AccountingCarryForwardType = AccountingCarryForwardType> = {
  id: string;
  productId: string;
  type: Type;
  lines: AccountingCarryForwardLine[];
  createdAt: string;
  updatedAt: string;
} & (
  | {
      type: AccountingCarryForwardType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingCarryForwardType.CLOSURE;
      accountingPeriodId: string;
    }
);

export enum AccountingCarryForwardType {
  RECOVERY = "recovery",
  CLOSURE = "closure",
}

export type AccountingCarryForwardCreate<Type extends AccountingCarryForwardType = AccountingCarryForwardType> = {
  productId: string;
  type: Type;
  lines: AccountingCarryForwardLineCreate[];
} & (
  | {
      type: AccountingCarryForwardType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingCarryForwardType.CLOSURE;
      accountingPeriodId: string;
    }
);
export type AccountingCarryForwardUpdate<Type extends AccountingCarryForwardType = AccountingCarryForwardType> = {
  id: string;
  type: Type;
  lines: AccountingCarryForwardLineUpdate[];
} & (
  | {
      type: AccountingCarryForwardType.RECOVERY;
      startAt: AccountingPeriod["startAt"];
      endAt: AccountingPeriod["endAt"];
    }
  | {
      type: AccountingCarryForwardType.CLOSURE;
      accountingPeriodId: string;
    }
);

export type AccountingCarryForwardUpdateInternal<Type extends AccountingCarryForwardType = AccountingCarryForwardType> =
  {
    id: string;
    productId: string;
    type: Type;
    lines: AccountingCarryForwardLineUpdate[];
  } & (
    | {
        type: AccountingCarryForwardType.RECOVERY;
        startAt: AccountingPeriod["startAt"];
        endAt: AccountingPeriod["endAt"];
      }
    | {
        type: AccountingCarryForwardType.CLOSURE;
        accountingPeriodId: string;
      }
  );

// Mongo
const accountingCarryForwardSchema = new Schema<AccountingCarryForward>(
  {
    _id: { type: String, default: () => ulid() },
    productId: { type: String, required: true, index: true },
    lines: { type: [accountingCarryForwardLineSchema], required: true },
    type: { type: String, enum: Object.values(AccountingCarryForwardType), required: true },
    accountingPeriodId: { type: String, index: true },
    startAt: { type: String },
    endAt: { type: String },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: AccountingCarryForwardDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);
export type AccountingCarryForwardDocument = AccountingCarryForward & Document<string>;
export const AccountingCarryForwardModel = model<AccountingCarryForwardDocument>(
  "AccountingCarryForward",
  accountingCarryForwardSchema,
  "AccountingCarryForwards"
);

// API
export namespace AccountingCarryForwardsService {
  export type CreateIn = AccountingCarryForwardCreate;
  export type CreateOut = AccountingCarryForward;

  export type ListIn = Partial<Pick<AccountingCarryForward, "productId" | "type">>;
  export type ListOut = AccountingCarryForward[];

  export type GetIn = Pick<AccountingCarryForward, "id">;
  export type GetOut = AccountingCarryForward;

  export type UpdateIn = AccountingCarryForwardUpdate;
  export type UpdateOut = AccountingCarryForward;

  export type DeleteIn = Pick<AccountingCarryForward, "id">;
  export type DeleteOut = boolean;
}

// Metadata
export type AccountingCarryForwardLineMetadata = {
  name: string;
  description?: string;
  isTotal?: boolean;
  isResult?: boolean;
  accounts: LedgerAccountEnum[];
};

export const accountingCarryForwardLinesMetadata: {
  [key in AccountingCarryForwardLineType]: AccountingCarryForwardLineMetadata;
} = {
  "total-deficit": {
    name: "Déficit total",
    accounts: [...accountingResultLinesMetadata["tax-deficit"].accounts],
  },
  "total-depreciation": {
    name: "Amortissement total",
    accounts: [...accountingResultLinesMetadata["non-deductible-depreciation"].accounts],
  },
};
